<script setup lang="ts">
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import TextInput from "@/components/input/TextInput.vue";
import Select from "@/components/select/Select.vue";
import {ref} from "vue";
import {states} from "@/utils/statesFolder";

const emit = defineEmits<{
  (e: 'add'): void;
  (e: 'search', label: string): void;
}>();

const label = ref('');

const handleAdd = () => {
  emit('add');
};

const handleSearch = () => {
  emit('search', label.value);
}
</script>

<template>
  <div class="my-company-motive-search">
    <form @submit.prevent="handleSearch">
    <h1>{{ translate('MOTIVE.TITLE') }}</h1>
    <span>{{ translate('RESEARCH') }}</span>
    <div class="my-company-motive-search-input">
      <div class="my-company-motive-search-input-text">
        <TextInput v-model:model-value="label"  :label="`${translate('OPEN_BANKING.LABEL')} :`" :color="'light'" />
      </div>
      <div>
        <Button class="my-company-motive-search-add-button" :label="translate('BUTTONS.ADD')" type="button" @click="handleAdd"/>
        <div>
          <Button :label="translate('BUTTONS.RESET')" type="reset" outlined white/>
          <Button :label="translate('BUTTONS.SEARCH')" type="submit" outlined white/>
        </div>
      </div>
    </div>
    </form>
  </div>
</template>

<style scoped lang="scss">

</style>