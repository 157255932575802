import {AxiosError, AxiosResponse} from "axios";
import {useToast} from "vue-toast-notification";

import {translate} from "@/i18n";
import store from "@/store";

const handler = () => {
  const toast = useToast();

  const B2CResponseCheck = (response: AxiosResponse) =>
    response.status === 200 &&
    response.data.status &&
    response.data.status === "FAILED";

  const B2BResponseCheck1 = (response: AxiosResponse) => {
    const responseURL = response.request?.responseURL;
    const parsedUrl = new URL(responseURL);
    if (parsedUrl.pathname.startsWith("/v3/company-details/attachments/json")) {
      return false;
    }
    return (
      response.status === 200 &&
      response.data.status &&
      response.data.status === "FAILED" &&
      !response.data.result &&
      response.data.statusReason
    );
  };

  const B2BResponseCheck2 = (response: AxiosResponse) =>
    response.status === 200 &&
    response.data.status &&
    response.data.status === "SUCCESS" &&
    response.data.result.messages &&
    response.data.result.messages.length > 0;

  const handleServerError = (error: AxiosError) => {
    if (
      error.response?.status &&
      error.response?.status >= 500 &&
      error.response?.status >= 599
    ) {
      toast.error(translate("ERRORS.ERROR_OCCURRED"), {duration: 5 * 1000});
    }
  };

  const handleB2CResponseFailed = (response: AxiosResponse) => {
    if (B2CResponseCheck(response)) {
      toast.warning(translate("ERRORS.ERROR_OCCURRED"), {duration: 5 * 1000});
    }
  };

  const handleB2BResponseFailed = (response: AxiosResponse) => {
    if (B2BResponseCheck1(response)) {
      store.dispatch("updateResponseStatus", {
        ...response.data,
        companyCheckErrorMessage: translate("ERRORS.SIREN_NOT_FOUND"),
      });
      toast.error(translate("ERRORS.ERROR_OCCURRED"), {duration: 5 * 1000});
    } else if (B2BResponseCheck2(response)) {
      toast.warning(translate("ERRORS.CALL_FINISHED"), {duration: 5 * 1000});
    }
  };

  const handleB2BAndB2CResponseFailed = (response: AxiosResponse) => {
    if (
      B2CResponseCheck(response) ||
      B2BResponseCheck1(response) ||
      B2BResponseCheck2(response)
    ) {
      store.dispatch("updateResponseStatus", {
        ...response.data,
        hasAnalysisError: true,
      });
    }
  };

  return {
    handleB2CResponseFailed,
    handleB2BResponseFailed,
    handleB2BAndB2CResponseFailed,
    handleServerError,
  };
};

export default handler();
