import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8308d3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-information" }
const _hoisted_2 = { class: "table-information-body" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "table-information-body-result" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "table-information-body-result" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "table-information-body-result" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_label_value_printer = _resolveComponent("label-value-printer")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tbody", _hoisted_2, [
      (_ctx.resultLcbft)
        ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
            _createElementVNode("td", null, [
              _createVNode(_component_label_value_printer, {
                class: "table-information-body-input",
                label: _ctx.translate('AMLCFT.TITLE')
              }, null, 8, ["label"])
            ]),
            _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.resultLcbft), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.resultCompany && _ctx.market_type === 'b2b')
        ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
            _createElementVNode("td", null, [
              _createVNode(_component_label_value_printer, {
                class: "table-information-body-input",
                label: _ctx.translate('LINK_COMPANY'),
                value: _ctx.typeCompany.toString()
              }, null, 8, ["label", "value"])
            ]),
            _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.resultCompany), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.resultIdentityCheck !== '')
        ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
            _createElementVNode("td", null, [
              _createVNode(_component_label_value_printer, {
                class: "table-information-body-input",
                label: _ctx.translate('IDENTITY_CONSISTENCY')
              }, null, 8, ["label"])
            ]),
            _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.resultIdentityCheck), 1)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}