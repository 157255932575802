import {
  ConfigType,
  transformData,
} from "@/utils/dataTransformer/dataTransformer";

type ShareCapitalType = { value: number; currency: string };

const ESCompanyInfoConfig: ConfigType = {
  phone: {
    newLabel: "phone",
  },
  website: {
    newLabel: "website",
  },
  isActive: {
    newLabel: "isActive",
  },
  workforce: {
    newLabel: "workforce",
  },
  activities: {
    newLabel: "activities",
  },
  closureDate: {
    newLabel: "closureDate",
  },
  headquarter: {
    newLabel: "headquarter",
  },
  legalStatus: {
    newLabel: "legalStatus",
  },
  shareCapitalObject: {
    newLabel: "shareCapitalObject",
  },
  shareCapital: {
    newLabel: "shareCapital",
    defaultValue: ({ shareCapital }: { shareCapital: ShareCapitalType }) =>
      shareCapital.value,
  },
  corporateName: {
    newLabel: "corporateName",
  },
  domiciliation: {
    newLabel: "domiciliation",
  },
  registrationDate: {
    newLabel: "registrationDate",
  },
  companyRegistrationNumber: {
    newLabel: "registrationNumber",
  },
  vatRegistrationNumber: {
    newLabel: "vatRegistrationNumber",
  },
  companyCountry: {
    newLabel: "companyCountry",
  },
};

export const ESCompanyInfoTransformer = transformData(ESCompanyInfoConfig);
