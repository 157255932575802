import {useRoute} from 'vue-router';
import {Routes} from "@/utils/openBankingUtils";
import {computed} from "vue";
import store from "@/store";

export function usePermissions() {
  const route = useRoute();
  const permissionsSorted = computed(() => store.getters.permissionsSorted);

  const docPermissions = (permission: string) => {
    if (route.path === Routes.ANALYSIS_B2B) {
      return permissionsSorted.value?.includes(`KIT_B2B_${permission}`);
    } else if (route.path === Routes.ANALYSIS_B2C) {
      return permissionsSorted.value?.includes(`KIT_B2C_${permission}`);
    } else if (route.path === Routes.DOCUMENT_ANALYSIS) {
      return permissionsSorted.value?.includes(`KIT_DOC_${permission}`);
    } else if (route.path === Routes.SYNTHESIS) {
      return permissionsSorted?.value.some((elt: any) => elt.includes(`KIT_DOC_${permission}`) || elt.includes(`KIT_B2B_${permission}`) || elt.includes(`KIT_B2C_${permission}`));
    }
  }

  return {docPermissions};
}
