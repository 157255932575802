<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  position: {
    type: String,
    default: "bottom",
  },
  tooltipId: {
    type: String,
    default: "tooltip__text",
  },
});

const tooltipClasses = computed(() => ({
  tooltip__text: true,
  [`tooltip--${props.position}`]: true,
}));
</script>

<template>
  <div class="tooltip-wrapper">
    <slot />
    <span :class="tooltipClasses" role="tooltip" :id="tooltipId" inert>{{
      text
    }}</span>
  </div>
</template>
