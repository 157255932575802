import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {CustomerProfileV3} from "@/types/CustomerProfile";

export const getLinkedinProfile = (journeyId: string, id: string, name: string) =>
  api.get(`${envConfig.ENRICHMENT_ROUTE}/v1/profile/linkedin?journeyId=${journeyId}&id=${id}&name=${name}`)
    .then((response) => response.data);

export const getIdMobile = (journeyId: string, customerProfile: CustomerProfileV3) =>
  api.post(`${envConfig.ENRICHMENT_ROUTE}/v1/idmobile/search?journeyId=${journeyId}`,
    {
      customerProfile: customerProfile
    }).then((response) => response.data);