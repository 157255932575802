<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { translate } from "@/i18n";

import Button from "@/components/button/Button.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import Loader from "@/components/loader/Loader.vue";
import RatiosTable from "@/components/table/RatiosTable.vue";

const props = defineProps({
  title: { type: String, default: "COMPANY.FINANCES.TITLE" },
  taxReports: { type: Object as any | undefined, required: true },
  loader: { type: Boolean, default: false },
  isToPrint: { type: Boolean, default: false },
  canCompanyFinances: { type: Boolean, default: false },
});

const displayContent = ref(false);

const router = useRouter();

const taxReportData = computed(() => {
  const balanceSheet = extractValues(["balanceSheet"], [""]);
  return {
    intermediateOperationTotals: extractValues(
      ["intermediateBalance"],
      ["profitBeforeTax"]
    ),
    debts: {
      ...extractValues(["debts"], [""]),
      ...extractValues(
        ["summary", "balancePassive", "debtsPayable"],
        [
          "TOTAL",
          "convertibleDebentureLoans",
          "bankAdvancesAndCreditBalancesOwingToBanks",
        ]
      ),
    },
    balanceSheet,
    fixedAssets: extractValues(
      ["summary", "balanceAssets", "fixedAssets"],
      ["TOTAL"]
    ),
    currentAssets: extractValues(
      ["summary", "balanceAssets", "currentAssets"],
      ["TOTAL"]
    ),
    profitAndLossAccount: extractValues(
      ["summary", "profitAndLossAccount"],
      ["operatingCosts", "operatingIncome", "operatingResult"]
    ),
    balancePassive: extractValues(
      ["summary", "balancePassive"],
      ["TOTAL", "debtsPayable", "capitalAndReserves"]
    ),
    capitalAndReserves: extractValues(
      ["summary", "balancePassive", "capitalAndReserves"],
      ["TOTAL"]
    ),
    operatingIncome: extractValues(
      ["summary", "profitAndLossAccount", "operatingIncome"],
      ["TOTAL"]
    ),
    operatingCosts: extractValues(
      ["summary", "profitAndLossAccount", "operatingResult"],
      ["TOTAL", "lossIncurred", "allocatedProfit"]
    ),
    operatingResult: extractValues(
      ["summary", "profitAndLossAccount", "operatingCosts"],
      ["TOTAL", "wagesAndSalaries"]
    ),
    ratios: getRatios(),
    increase: getIncrease(balanceSheet),
  };
});

/**
 * Extract values from tax reports
 *
 * @returns {object} extracted values
 * @param fields
 * @param exclude
 */
const extractValues = (fields: string[], exclude: string[]): object => {
  let result = {} as { [key: string]: any };
  if (!props.taxReports) return;
  Object.keys(props.taxReports).forEach((taxReport) => {
    const res = fields.reduce((previousValue, currentValue) => {
      return previousValue[currentValue];
    }, props.taxReports[taxReport]);
    if (res)
      Object.keys(res)
        .filter((elt) => !exclude.includes(elt))
        .forEach((key) => {
          const year = new Date(
            props.taxReports[taxReport].context.closureDate
          ).getFullYear();
          if (!result[key]) result[key] = {};
          result[key][year] = res[key];
        });
  });
  return result;
};

const getComponent = () => {
  return router.currentRoute.value.name?.toString() === "DocumentAnalysis"
    ? ResultCard
    : ContentCard;
};

/**
 * Get ratios from tax reports
 * @returns {object} ratios
 */
const resultRatios = ref({
  fpTb: {},
  supplierDeadlines: {},
  customerDeadlines: {},
  caf: {},
  rnCa: {},
  etp: {},
  dividends: {},
  soldeFinancier: {},
} as any);

const getRatios = () => {
  if (props.taxReports) {
    const tax = props.taxReports;
    Object.keys(tax).forEach((taxReport) => {
      for (let i = 0; i < tax[taxReport].summary.ratios.length; i++) {
        resultRatios.value[tax[taxReport].summary.ratios[i].name][
          new Date(tax[taxReport].context.closureDate).getFullYear()
        ] = tax[taxReport].summary.ratios[i].value;
      }
    });
  }
  return resultRatios.value;
};

watch(resultRatios, getRatios);

/**
 * Get increase from tax reports
 * @returns {object} increase
 * @param balanceSheetData
 */
const getIncrease = (balanceSheetData?: { [key: string]: any }): object => {
  const result = {
    salesGrowthRate: {},
    grossMarginRate: {},
    ebitdaMarginRate: {},
    operatingMarginRate: {},
  } as any;

  if (props.taxReports && balanceSheetData?.turnover) {
    const tax = props.taxReports;
    const balanceSheet = Object.keys(balanceSheetData?.turnover);
    for (let i = 0; i < balanceSheet.length; i++) {
      if (balanceSheet[i] && balanceSheet[i - 1]) {
        result.salesGrowthRate[balanceSheet[i]] =
          (balanceSheetData?.turnover[balanceSheet[i]] /
            balanceSheetData?.turnover[balanceSheet[i - 1]]) *
          100;
      }
    }

    Object.keys(tax).forEach((taxReport) => {
      const turnover = tax[taxReport].balanceSheet.turnover;
      const closureDateYear = new Date(
        tax[taxReport].context.closureDate
      ).getFullYear();
      result.grossMarginRate[closureDateYear] =
        (tax[taxReport].intermediateBalance.grossMargin / turnover) * 100;
      result.ebitdaMarginRate[closureDateYear] =
        (tax[taxReport].intermediateBalance.ebitda / turnover) * 100;
      result.operatingMarginRate[closureDateYear] =
        tax[taxReport].intermediateBalance.ebit / turnover;
    });
  }

  return result;
};

const changeDisplayContent = () => {
  displayContent.value = !displayContent.value;
};

window.addEventListener("beforeprint", () => {
  displayContent.value = true;
});
</script>

<template>
  <ContentCard
    v-if="
      !canCompanyFinances &&
      router.currentRoute.value.name?.toString() !== 'DocumentAnalysis'
    "
    :checked-display-content="false"
    :is-padlock="true"
    class="company-finances"
    :title="translate('COMPANY.FINANCES.TITLE')"
  />
  <template v-else>
    <component
      :is="getComponent()"
      :title="translate(props.title)"
      :class="
        router.currentRoute.value.name?.toString() === 'DocumentAnalysis'
          ? ''
          : 'company-finances'
      "
    >
      <Loader v-if="loader" />
      <template v-else-if="!!props.taxReports && props.taxReports.length !== 0">
        <Button
          :label="displayContent ? translate('BUTTONS.REDUCE_EVERYTHING') : translate('BUTTONS.DEPLOY_EVERYTHING')"
          @click="changeDisplayContent"
          class="no-print"
        />
        <div class="company-finances-content">
          <ResultCard
            v-for="(check, key) in taxReportData"
            :title="translate(`COMPANY.FINANCES.BLOCKS.${key}`)"
            :checked-display-content="displayContent || props.isToPrint"
          >
            <RatiosTable :table-data="check" />
          </ResultCard>
        </div>
      </template>
      <div v-else class="company-finances-no-data">
        <p>{{ translate("COMPANY.FINANCES.NO_DATA") }}</p>
      </div>
    </component>
  </template>
</template>
