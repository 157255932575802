import {defineComponent} from 'vue';
import {allUploadedFilesOcrDone, DOCUMENTS_TIMEOUT, isAuthenticityDone} from "@/apiCalls/uploadDocumentsApi";
import {translate} from "@/i18n";
import {sendStepsToContext} from "@/apiCalls/contextApi";
// @ts-ignore
import {useToast} from 'vue-toast-notification';
import utilsMixin from "@/mixins/utilsMixin";
import {taxReportJson} from "@/apiCalls/companyCheck";
import {sendDocumentTaxReport, sendDocumentTaxReportToAnalysis} from "@/apiCalls/taxReport";
import {applyRule} from "@/apiCalls/rulesEngine";
// @ts-ignore
import {getLinkedinProfile} from "@/apiCalls/enrichmentApi";
// @ts-ignore
import {
  addJourneyIdStatus, addMotiveToJourney,
  createJourneyIdByTask,
  createJourneyIdResponse,
  getData,
  getLatestJourneyIdStatus, getLatestMotiveFromJourneyId,
  updateJourneyIdStatus
} from "@/apiCalls/taskApi";
// @ts-ignore
import {
  ALL_FIELDS,
  ANALYSIS_B2B_FIELDS,
  ANALYSIS_B2C_FIELDS,
  DOCUMENTS_FIELDS,
  OPEN_BANKING_FIELDS,
  RECOVERY_FIELDS,
  SYNTHESIS_FIELDS
} from "@/utils/dtoFields";
import {isArray, isEmpty, isNil, isNull, mergeWith} from "lodash";
import {mapGetters} from "vuex";
import {searchWarningList} from "@/apiCalls/warningListApi";
import {sleep} from '@/utils/sleep';
import {states} from "@/utils/statesFolder";
import {phoneIndicator} from "@/utils/phoneIndicator";
import {Motive} from "@/utils/journeyUtils";

export enum ScoreWarningKOColors {
  colorWarning = "#ffa543",
  colorKO = "#ff7676",
  defaultColorWarning = "#F5C99A",
  defaultColorKO = "#FFB2B2",
  undefinedColor = "#BFA1C4"
}

export enum ScoreOKNoneColors {
  colorOK = "#36ba75",
  colorNone = "#D9D9D9",
  defaultColorOK = "#89DEB2",
}

export default defineComponent({
  async created() {
    this.permissionsCompanyAlert.push(this.permissionsSorted.filter((element: string | string[]) => element.includes("COMPANY_ALERT")));
    this.permissionsOpenBankingDetails.push(this.permissionsSorted.filter((element: string | string[]) => element.includes("OB_DETAILS")));
    if (this.journeyId) {
      try {
        const journey = await getLatestJourneyIdStatus(this.journeyId);
        this.motive = await getLatestMotiveFromJourneyId(this.journeyId);
        this.journeyIdStatus = journey.status;
        if (states.filter(state => !['WAITING', 'IN_PROGRESS'].includes(state)).includes(this.journeyIdStatus) && !this.isDuplicateFolder()) {
          this.$router.push(`/synthesis?journeyId=${this.journeyId}`);
        }
      } catch (e) {
        if (this.$route.path === '/synthesis') {
          const toast = useToast();
          toast.error(translate('ERRORS.CANNOT_ACCESS_FOLDER'));
        }
        console.error(e);
      }

      if (this.$route.path === '/analysis-b2c') {
        getData(this.journeyId, ANALYSIS_B2C_FIELDS).then(async ({data: {data}}: any) => {
          this.contextData = data ? data : {};
          this.businessId = data ? data.businessId : '';
          if (this.scoreFraudData) this.colorB2C = await this.defineColor('score_b2c', this.scoreFraudData, this.journeyId);
        })
      } else if (this.$route.path === '/open-banking') {
        getData(this.journeyId, OPEN_BANKING_FIELDS).then(async ({data: {data}}: any) => {
          this.contextData = data ? data : {};
          this.businessId = data ? data.businessId : '';
        });
      } else if (this.$route.path === '/analysis-b2b') {
        getData(this.journeyId, ANALYSIS_B2B_FIELDS).then(async ({data}: any) => {
          this.contextData = data ? data.data : {};
          this.businessId = data ? data.businessId : '';
          if (Object.keys(this.contextData).length === 0) {
            const errorToast = useToast();
            errorToast.error(translate("ERRORS.COMPANY_NOT_FOUND"));
          }
          await this.defineColorForScore();
        });
      } else if (this.$route.path === '/recovery') {
        getData(this.journeyId, RECOVERY_FIELDS).then(async ({data: {data}}: any) => {
          this.contextData = data ? data : {};
        });
      } else if (this.$route.path === '/synthesis') {
        getData(this.journeyId, SYNTHESIS_FIELDS).then(async (response: any) => {
          this.contextData = response?.data?.data ? response.data.data : {};
          this.dataFolder = {
            analyst: response?.data?.createdBy?.username,
            createdAt: response?.data?.createdAt,
            state: response?.data?.status
          };
          this.businessId = response?.data?.businessId;
          await this.defineColorForScore();
        });
      }
    }
  },
  mixins: [utilsMixin],
  data: () => {
    return {
      contextData: {} as any,
      dataFolder: {
        analyst: '',
        createdAt: new Date(),
        state: ''
      },
      timer: '' as any,
      isLoading: false,
      documentsTaxReportsSentForAnalysis: [] as any[],
      documentsTaxNoticesSentForAnalysis: [] as any[],
      steps: {} as any,
      timeOut: '' as any,
      colorB2B: '',
      colorSMB: '',
      colorB2C: '',
      colorAltares: '',
      colorScoreSector: '',
      colorDefaultProbability: '',
      colorEReputation: '',
      taxReportLoading: false,
      disabledEnrichmentButton: false,
      isUploadEnrichment: false,
      valueEnrichment: {} as any,
      enrichmentLoader: false,
      colorCreditSafeLoc: '',
      colorCreditSafeInter: '',
      permissionsCompanyAlert: [] as string[],
      permissionsOpenBankingDetails: [] as string[],
      journeyIdStatus: '',
      journeyIdStatusToChange: '',
      modalDeleteFolder: false,
      journeyIdToDelete: '' as string,
      modalEditFolder: false,
      businessId: '',
      newJourneyIdStatus: '',
      motive: {} as Motive,
      newMotive: {} as Motive,
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    async createJourneyId(clientId: string, externalId?: string) {
      const response: createJourneyIdResponse = await createJourneyIdByTask('meelo-portal', clientId, externalId);
      this.businessId = response.businessId;
      const journey = await addJourneyIdStatus(response.id, 'IN_PROGRESS', 'string');
      this.journeyIdStatus = journey[0].status
      // Local use only
      // this.journeyIdStatus = "IN_PROGRESS"
      await this.$router.push({
        path: `${this.$route.path}`, query: {journeyId: response.id}
      });
    },
    isDuplicateFolder() {
      return this.$route.query.duplicate !== undefined;
    },
    sendStepsToContext,
    async fetchOCR() {
      const {data: res} = await getData(this.journeyId, this.routePath);
      this.contextData = res?.data ? res.data : {};

      if (allUploadedFilesOcrDone(this.contextData)) this.endLoading();

      if (!isAuthenticityDone(this.contextData)) {
        await sleep(10 * 1000);
        const {data: res} = await getData(this.journeyId, this.routePath);
        this.contextData = res?.data ? res.data : {};
      }
    },
    async startLoading(docType: string) {
      this.isLoading = true;
      this.timer = setInterval(async () => {
        await this.fetchOCR();
      }, 15000);
      this.timeOut = setTimeout(() => {
        clearInterval(this.timer);
        this.endLoading();
        const errorToast = useToast();
        errorToast.error(translate("ERRORS.DOCUMENT_PROCESS"));
      }, (Object.keys(DOCUMENTS_TIMEOUT).includes(docType) ? DOCUMENTS_TIMEOUT[docType] : 30) * 1000);
    },
    endLoading() {
      this.isLoading = false;
      clearTimeout(this.timeOut);
    },
    translate,
    async analyseTaxReport() {
      //Recuperation des liasses fiscales des 4 dernières années
      if (this.last3YearsTaxReports.length > 0) {
        this.taxReportLoading = true;
        for (let i = 0; i < this.last3YearsTaxReports.length; i++) {
          const taxReport = await taxReportJson(this.last3YearsTaxReports[i].registrationNumber, this.journeyId, this.last3YearsTaxReports[i].fileId, this.getFirstElement(this.contextData.company_country));
          if (!taxReport.data.result?.result) continue;
          const response = await sendDocumentTaxReport(this.journeyId, taxReport.data.result.result, `report-tax-${this.last3YearsTaxReports[i].fileId}.json`);
          if (!response.data.result.documents[0].id) continue;
          const documentId = response.data.result.documents[0].id;
          const res = await sendDocumentTaxReportToAnalysis(this.journeyId, documentId);
          this.contextData.tax_reports_result = this.contextData.tax_reports_result ? this.contextData.tax_reports_result : {};
          this.contextData.tax_reports_result[documentId] = res.data.result;
        }
        this.taxReportLoading = false;
      } else {
        this.contextData.tax_reports_result = []
      }
    },
    async defineColor(typeScore: string, score: any, journeyId: string) {
      if (typeScore == 'credit_safe_international') score = this.translateCreditSafeInternational(score);
      // Define color
      const colors = {...ScoreWarningKOColors, ...ScoreOKNoneColors};

      // If we can handle the result by the rule engine
      const rulesCollection: any = {
        'score_b2c': 'web-score-b2c',
        'score_b2b': 'web-score-b2b',
        'score_smb': 'smb-score',
      };

      if (typeof score === "undefined") {
        return colors.colorNone;
      }

      if (typeof rulesCollection[typeScore] !== "undefined") {
        // setTimeout(() => {
        // }, 200); // Wait to allow time for the context to fill...
        const applyRuleResponse = score.length > 0 ? await applyRule(rulesCollection[typeScore], journeyId) : null;
        if (!applyRuleResponse) {
          // default case
          if (score < 20) return colors.defaultColorKO;
          if ((score >= 20) && (score < 50)) return colors.defaultColorWarning;
          return colors.defaultColorOK; // Because score >= 50
        }

        if (typeof applyRuleResponse.result.warning === 'undefined' || typeof applyRuleResponse.result.decision === 'undefined') {
          return colors.undefinedColor;
        }
        if (applyRuleResponse.result.warning !== null) return colors.colorWarning;
        if (!applyRuleResponse.result.decision) return colors.colorKO;
        return colors.colorOK;
      }

      if (typeScore === "default_probability") {
        if (score < 1) return colors.defaultColorOK;
        if ((score >= 1) && (score < 5)) return colors.defaultColorWarning;
        return colors.defaultColorKO; // Because score >= 5
      }

      // default case
      if (score < 20) return colors.defaultColorKO;
      if ((score >= 20) && (score < 50)) return colors.defaultColorWarning;
      return colors.defaultColorOK; // Because score >= 50
    },
    translateCreditSafeInternational(note: string): number {
      switch (note) {
        case "A":
          return 100;
        case "B":
          return 70;
        case "C":
          return 50;
        case "D":
          return 20;
        case "E":
          return 1;
        default:
          return 0;
      }
    },
    getFirstElement(context: Array<any>, ignoreFailed = false) {
      if (isEmpty(context)) return;
      if (isArray(context) && context.length === 0) return;
      if (isArray(context) && context.length > 0) {
        if (ignoreFailed) return context[0]
        return context.filter(el => el?.data?.status !== 'FAILED')[0];
      }
    },
    customizer(objValue: any, srcValue: any) {
      if (srcValue === "" || srcValue === null) return objValue;
      else if (objValue === "" || objValue === null) return srcValue;
    },
    mergeObject(arrayToMerge: Array<any>) {
      let mergedObject = arrayToMerge[0];
      arrayToMerge.forEach(tab => mergeWith(mergedObject, tab, this.customizer));
      return mergedObject;
    },
    async getEnrichment(id: string) {
      this.disabledEnrichmentButton = true;
      this.enrichmentLoader = true;
      try {
        if (!this.journeyId) await this.createJourneyId('portal-b2c');
        const response = await getLinkedinProfile(this.journeyId, id, 'enrichment');
        if (response.result === null) {
          this.enrichmentLoader = false;
          this.isUploadEnrichment = false;
          this.disabledEnrichmentButton = false;
        }
        this.contextData.enrichment = response ? [response.result] : {};
        this.enrichmentLoader = false;
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          this.enrichmentLoader = false;
          this.isUploadEnrichment = false;
          this.disabledEnrichmentButton = false;
          const errorToast = useToast();
          errorToast.error(translate("ERRORS.ENRICHMENT_PROCESS"));
        }
      }
    },
    async defineColorForScore() {
      if (this.scoreB2BDataV) this.colorB2B = await this.defineColor('score_b2b', this.scoreB2BDataV, this.journeyId);
      if (this.scoreSMBData) this.colorSMB = await this.defineColor('score_smb', this.scoreSMBData, this.journeyId);
      if (this.scoreFraudData) this.colorB2C = await this.defineColor('score_b2c', this.scoreFraudData, this.journeyId);
      if (this.scoreAltaresData) this.colorAltares = await this.defineColor('', this.scoreAltaresData, this.journeyId);
      if (this.causesAltaresData && this.causesAltaresData.scoreSector) this.colorScoreSector = await this.defineColor('', this.causesAltaresData.scoreSector, this.journeyId);
      if (this.causesAltaresData && this.causesAltaresData.defaultProbability) this.colorDefaultProbability = await this.defineColor('default_probability', this.causesAltaresData.defaultProbability, this.journeyId);
      if (this.scoreEReputationData) this.colorEReputation = await this.defineColor('score_e-reputation', this.scoreEReputationData, this.journeyId);
      if (this.scoreCreditSafeData) this.colorCreditSafeLoc = await this.defineColor('credit_safe_local', this.scoreCreditSafeData, this.journeyId);
      if (this.causesCreditSafeData && this.causesCreditSafeData.internationalScore) this.colorCreditSafeInter = await this.defineColor('credit_safe_international', this.causesCreditSafeData.internationalScore, this.journeyId);
    },
    async getWarningList(journeyId: string, listName: string, operator: string, data: object, label: string) {
      const response = await searchWarningList(journeyId, listName, operator, data, label);
      this.contextData.warning_list = !isNull(response) && response?.data.length > 0 ? [response.data[0].matches] : [];
    },
    openModalDeleteFolder(item?: any) {
      this.modalDeleteFolder = !this.modalDeleteFolder;
      if (this.$route.path === '/historical') {
        this.journeyIdToDelete = item.journeyId;
        this.businessId = item.businessId;
      }
    },
    async deleteFolder() {
      this.modalDeleteFolder = !this.modalDeleteFolder;
      await addJourneyIdStatus(this.$route.path !== '/historical' ? this.journeyId : this.journeyIdToDelete, 'DELETED', 'string');
      this.journeyIdToDelete = '';
      this.businessId = '';
      if (this.$route.path !== '/historical') this.$router.push('/historical');
    },
    openModalEditFolder(state: string, caseState: string, motive: Motive) {
      this.modalEditFolder = !this.modalEditFolder;
      this.newMotive = motive;
      this.journeyIdStatus = state;
      this.journeyIdToDelete = caseState;
    },
    async updateState(state: any) {
      try {
        await addJourneyIdStatus(this.journeyId, state, 'string');
      } catch (error: any) {
        console.error(error);
      }
    },
    async updateMotif(motive: Motive) {
      try {
        await addMotiveToJourney(this.journeyId, motive);
      } catch (error: any) {
        console.error(error);
      }
    },
    async editFolder() {
      this.modalEditFolder = !this.modalEditFolder;
      /* Order of updateState and updateMotif is important */
      if (this.journeyIdStatus !== this.journeyIdToDelete) await this.updateState(this.journeyIdStatus);
      if (this.motive !== this.newMotive && !isEmpty(this.newMotive) && this.newMotive.id === '-1') {
        /* Adding a Motive with status -1 (means the user has selected the empty option)
        *  We need to remove the previous Motive */
        await this.updateState(this.journeyIdStatus);
      } else if (this.motive !== this.newMotive && !isEmpty(this.newMotive)) {
        await this.updateMotif(this.newMotive)
      }
      this.motive = await getLatestMotiveFromJourneyId(this.journeyId);
      const lastStatus = await getLatestJourneyIdStatus(this.journeyId);
      this.journeyIdStatus = lastStatus.status;
      const toast = useToast();
      toast.success(translate('FOLDER.UPDATED'));
      if (['ACCEPTED', 'REFUSED', 'CANCELED'].includes(this.journeyIdStatus)) {
        this.$router.push(`/synthesis?journeyId=${this.journeyId}&businessId=${this.businessId}`);
      }
    },
    undoFolder() {
      this.journeyIdStatus = this.journeyIdToDelete;
    },
    phoneNotValid(response: any) {
      let isError;
      if (response.detail) { //the response is different depending on the length of the phone number
        response.detail.forEach((ele: any) => {
          if (ele.msg.includes('phone')) {
            isError = true;
          }
        });
      } else if (response.error) {
        response.error.includes('phone') ? isError = true : null;
      }
      if (isError) {
        const errorToast = useToast();
        errorToast.error(translate('ERRORS.PHONE_NUMBER'));
      }
    },
    allowModificationTextInput(tab: any) {
      for (const prop in tab) {
        tab[prop].disabled = false;
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'kits', 'permissions', 'permissionsSorted', 'responseStatus']),
    checkIbanData() {
      return this.contextData ? this.getFirstElement(this.contextData?.check_iban_details, true) : {};
    },
    openBankingAccount() {
      return this.contextData ? this.getFirstElement(this.contextData?.open_banking_account) : {};
    },
    openBankingDataSummary() {
      return this.contextData ? this.getFirstElement(this.contextData?.open_banking_data_summary) : {};
    },
    openBankingScore() {
      return this.contextData ? this.getFirstElement(this.contextData?.open_banking_score) : {};
    },
    causesEReputationData() {
      return !isEmpty(this.causesEReputationDataV1) ? this.causesEReputationDataV1 : this.causesEReputationDataV2;
    },
    causesEReputationDataV1() {
      return this.contextData ? this.getFirstElement(this.contextData?.e_reputation_causes) : {};
    },
    causesEReputationDataV2() {
      return this.contextData ? this.getFirstElement(this.contextData?.e_reputation_causes_v2) : {};
    },
    scoreEReputationData() {
      return !isEmpty(this.scoreEReputationDataV1) ? this.scoreEReputationDataV1 : this.scoreEReputationDataV2;
    },
    scoreEReputationDataV1() {
      return this.contextData && this.contextData.e_reputation_score != null ? this.contextData?.e_reputation_score : null;
    },
    scoreEReputationDataV2() {
      return this.contextData && this.contextData.e_reputation_score_v2 != null ? this.contextData?.e_reputation_score_v2 : null;
    },
    amlCftMatchesData() {
      return this.contextData ? this.getFirstElement(this.contextData?.amlcft_matches) : {};
    },
    checkCompanyData() {
      return this.contextData ? this.contextData?.company_details?.task : {};
    },
    checkCompanyDataCompany(): any {
      return this.checkCompanyData?.company;
    },
    checkCompanyLegalUnitData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_legal_unit) : {};
    },
    checkCompanyCountryData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_country) : {};
    },
    checkCompanyRegistrationNumberData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_registration_number) : {};
    },
    checkCompanyAttachmentsData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_attachments) : {};
    },
    checkCompanyLegalObservationData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_legal_observations) : {};
    },
    checkCompanyEstablishmentsData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_establishments) : {};
    },
    checkCompanyRepresentativesData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_representatives) : {};
    },
    checkCompanyShareHoldersData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_share_holders) : {};
    },
    warningListData() {
      return this.contextData ? this.contextData?.warning_list : {};
    },
    scoreSMBData() {
      return this.contextData && this.contextData.smb_score != null ? this.contextData?.smb_score : null;
    },
    causesSMBData() {
      return this.contextData ? this.getFirstElement(this.contextData?.smb_causes) : {};
    },
    scoreB2BDataV() {
      return isArray(this.scoreB2BDataV1) && this.scoreB2BDataV1.length > 0 ? this.scoreB2BDataV1 : this.scoreB2BData;
    },
    causesB2BDataV() {
      return !isEmpty(this.causesB2BDataV1) && Object.keys(this.causesB2BDataV1).length > 0 ? this.causesB2BDataV1 : this.causesB2BData;
    },
    scoreB2BData() {
      return this.contextData && !isNull(this.contextData.web_score_b2b) ? this.contextData?.web_score_b2b : null;
    },
    causesB2BData() {
      return this.contextData ? this.getFirstElement(this.contextData?.causes_web_score_b2b) : {};
    },
    scoreB2BDataV1() {
      return this.contextData && !isNull(this.contextData.web_score_b2b_v1) ? this.contextData?.web_score_b2b_v1 : null;
    },
    causesB2BDataV1() {
      return this.contextData ? this.getFirstElement(this.contextData?.causes_web_score_b2b_v1) : {};
    },
    applicantData() {
      if (!this.contextData || !this.contextData.applicant) return;
      const uniqueApplicant = this.mergeObject(this.contextData.applicant);
      return this.contextData && this.contextData.applicant ? uniqueApplicant : {};
    },
    scoreAltaresData() {
      return this.contextData && this.contextData.score_altares != null ? this.contextData?.score_altares : {};
    },
    causesAltaresData() {
      return this.contextData ? this.getFirstElement(this.contextData?.altares_causes) : {};
    },
    searchImageProfilesData() {
      return this.contextData ? this.getFirstElement(this.contextData?.search_image) : {};
    },
    scoreFraudData() {
      return this.contextData && !isEmpty(this.contextData.web_score_b2c) ? this.contextData?.web_score_b2c : null;
    },
    causesFraudData() {
      return this.contextData ? this.getFirstElement(this.contextData?.causes_web_score_b2c) : {};
    },
    scoreEllisphereData() {
      return this.contextData ? this.contextData?.ellisphere_score : {};
    },
    causesEllisphereData() {
      return this.contextData ? this.contextData?.ellisphere_causes : {};
    },
    documentIdCheckData() {
      return this.contextData ? this.contextData?.ocr_id_card : {};
    },
    documentPassportData() {
      return this.contextData ? this.contextData?.ocr_passport : {};
    },
    documentDrivingLicenseData() {
      return this.contextData ? this.contextData?.ocr_driving_license : {};
    },
    documentResidencePermitData() {
      return this.contextData ? this.contextData?.ocr_residence_permit : {};
    },
    documentKbisData() {
      return this.contextData ? this.contextData?.ocr_kbis : {};
    },
    documentBankDetailsData() {
      return this.contextData ? this.contextData?.ocr_bank_details : {};
    },
    documentTaxReportData() {
      return this.contextData ? this.contextData?.ocr_tax_report : {};
    },
    last4YearsTaxReports() {
      return this.checkCompanyAttachmentsData.taxReports;
    },
    last3YearsTaxReports() {
      const taxReports = this.checkCompanyAttachmentsData?.taxReports;
      return taxReports ? taxReports.slice(0, 3) : [];
    },
    taxReportsSummaryData() {
      return this.contextData ? this.contextData?.tax_reports_result : {};
    },
    enrichmentData() {
      return this.contextData && this.contextData.enrichment != null ? this.getFirstElement(this.contextData.enrichment) : {};
    },
    routePath() {
      switch (this.$route.path) {
        case '/analysis-b2c':
          return ANALYSIS_B2C_FIELDS
        case '/analysis-b2b':
          return ANALYSIS_B2B_FIELDS
        case '/document-analysis':
          return DOCUMENTS_FIELDS
        default:
          return ALL_FIELDS
      }
    },
    isScoreSmb() {
      return (this.scoreSMBData != null && Object.keys(this.scoreSMBData).length > 0 && this.causesSMBData != null);
    },
    isScoreFraud() {
      return this.scoreFraudData != null && Object.keys(this.scoreFraudData).length > 0;
    },
    isScoreCompany() {
      return this.scoreB2BDataV && this.scoreB2BDataV.length > 0;
    },
    isScoreCompanyV1() {
      return this.scoreB2BDataV1 && this.scoreB2BDataV1.length > 0;
    },
    isScoreAltares() {
      return (this.scoreAltaresData != null && Object.keys(this.scoreAltaresData).length > 0 && this.causesAltaresData != null);
    },
    isEReputation() {
      return (this.scoreEReputationData !== null && Object.keys(this.scoreEReputationData).length > 0 && this.causesEReputationData !== null);
    },
    scoreCreditSafeData() {
      return this.contextData ? this.getFirstElement(this.contextData?.credit_safe_score) : {};
    },
    causesCreditSafeData() {
      return this.contextData?.credit_safe_causes ? this.getFirstElement(this.contextData.credit_safe_causes) : {};
    },
    companyInfoBlock() {
      return this.permissionsSorted?.some((elt: string | string[]) => elt.includes('KIT_B2B_COMPANY_INFO_LEGAL_UNIT') || elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE')
        || elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_REGISTRANT') || elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_CREATION_DATE')
        || elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_REGISTRAR'))
    },
    canWebScoreB2c() {
      if (this.$route.path === '/analysis-b2c') {
        return this.permissionsSorted?.some((elt: any) => elt.includes('KIT_B2C_WEB_SCORE_DETAILS_MAIL')
          || elt.includes('KIT_B2C_WEB_SCORE_DETAILS_PHONE') || elt.includes('KIT_B2C_WEB_SCORE_DETAILS_SEARCH_IMAGE')
          || elt.includes('KIT_B2C_WEB_SCORE_DETAILS_SOCIAL_NETWORKS') || elt.includes('KIT_B2C_WEB_SCORE_SCORE')
        );
      } else if (this.$route.path === '/analysis-b2b') {
        return this.scoreSMBBlock || this.companyScoreBlock;
      }
    },
    scoringDetailedBoxBlock() {
      return this.permissionsSorted.some((elt: any) => elt.includes('KIT_B2C_WEB_SCORE_DETAILS_MAIL')
        || elt.includes('KIT_B2C_WEB_SCORE_DETAILS_PHONE') || elt.includes('KIT_B2C_WEB_SCORE_DETAILS_SEARCH_IMAGE')
        || elt.includes('KIT_B2C_WEB_SCORE_DETAILS_SOCIAL_NETWORKS'))
    },
    checkIbanBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return this.permissionsSorted.includes('KIT_B2C_CHECK_IBAN_B2C_GLOBAL');
      } else if (this.$route.path === '/analysis-b2b') {
        return this.permissionsSorted.includes('KIT_B2B_CHECK_IBAN_B2B_GLOBAL');
      }
    },
    isCountryFR() {
      return this.checkCompanyCountryData ? this.checkCompanyCountryData === "FR" : false;
    },
    scoreSMBBlock() {
      return this.permissionsSorted.some((elt: string | string[]) => elt.includes('KIT_B2B_SMB_DETAILS_MAIL')
        || elt.includes('KIT_B2B_SMB_DETAILS_PHONE') || elt.includes('KIT_B2B_SMB_DETAILS_SEARCH_IMAGE')
        || elt.includes('KIT_B2B_SMB_DETAILS_SOCIAL_NETWORKS') || elt.includes('KIT_B2B_SMB_SCORE'))
    },
    companyScoreBlock() {
      return this.permissionsSorted?.some((elt: string | string[]) => elt.includes('KIT_B2B_COMPANY_SCORE_SCORE')) || this.permissionsCompanyAlert[0].length > 0;
    },
    amlcftBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return this.permissionsSorted.includes('KIT_B2C_AMLCFT_GLOBAL');
      } else if (this.$route.path === '/analysis-b2b') {
        return this.permissionsSorted.includes('KIT_B2B_AMLCFT_GLOBAL');
      }
    },
    companyFinancesBlock() {
      return this.permissionsSorted.includes('KIT_B2B_COMPANY_FINANCES_GLOBAL');
    },
    companyLegalDocBlock() {
      return this.permissionsSorted.includes('KIT_B2B_COMPANY_LEGAL_DOC_GLOBAL');
    },
    annualAccountsBlock() {
      return this.permissionsSorted.includes('KIT_B2B_COMPANY_ANNUAL_ACCOUNT_GLOBAL');
    },
    establishmentsBlock() {
      return this.permissionsSorted.includes('KIT_B2B_COMPANY_ESTABLISHMENTS_GLOBAL');
    },
    bodaccAnnouncementsBlock() {
      return this.permissionsSorted.includes('KIT_B2B_COMPANY_EVOLUTION_GLOBAL');
    },
    companyLitigationBlock() {
      return this.permissionsSorted.includes('KIT_B2B_LITIGATION_GLOBAL');
    },
    eReputationBlock() {
      return this.permissionsSorted?.some((elt: string | string[]) => elt.includes('KIT_B2B_E_REPUTATION_ADJECTIVES')
        || elt.includes('KIT_B2B_E_REPUTATION_RATE') || elt.includes('KIT_B2B_E_REPUTATION_REVIEWS')
        || elt.includes('KIT_B2B_E_REPUTATION_SCORE') || elt.includes('KIT_B2B_E_REPUTATION_TOP_SCAM')
        || elt.includes('KIT_B2B_E_REPUTATION_WORD_CLOUD'));
    },
    enrichmentInputBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return isEmpty(this.enrichmentData);
      } else if (this.$route.path === '/analysis-b2b') {
        return isEmpty(this.enrichmentData);
      }
    },
    enrichmentOutputBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return !isEmpty(this.enrichmentData);
      } else if (this.$route.path === '/analysis-b2b') {
        return !isEmpty(this.enrichmentData);
      }
    },
    uploadDocumentBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return this.permissionsSorted?.includes('KIT_B2C_UPLOAD_UPLOAD');
      } else if (this.$route.path === '/analysis-b2b') {
        return this.permissionsSorted.includes('KIT_B2B_UPLOAD_UPLOAD');
      }
    },
    restitutionDocumentBlock() {
      if (this.$route.path === '/analysis-b2c') {
        return this.permissionsSorted?.some((elt: any) => elt.includes('KIT_B2C_RESTITUTION_DETAILS') || elt.includes('KIT_B2C_RESTITUTION_SOFT'))
      } else if (this.$route.path === '/analysis-b2b') {
        return this.permissionsSorted?.some((elt: string | string[]) => elt.includes('KIT_B2B_RESTITUTION_DETAILS') || elt.includes('KIT_B2B_RESTITUTION_SOFT'));
      }
    },
    businessOwnersBlock() {
      return this.permissionsSorted.some((elt: string | string[]) => elt.includes('KIT_B2B_BUSINESS_OWNERS_CONSULT') || elt.includes('KIT_B2B_BUSINESS_OWNERS_SELECTION'));
    },
    representativesBlock() {
      return this.permissionsSorted.some((elt: string | string[]) => elt.includes('KIT_B2B_REPRESENTATIVES_CONSULT') || elt.includes('KIT_B2B_REPRESENTATIVES_SELECTION'));
    },
    cartographyBlock() {
      return this.permissionsSorted.includes('KIT_B2B_COMPANY_CARTOGRAPHY_GLOBAL')
    },
    checkEmployerCollectiveProceedingsData() {
      return this.contextData ? this.getFirstElement(this.contextData?.company_collective_proceedings) : {};
    },
    displayStateFolder() {
      return states.includes(this.journeyIdStatus) && !this.responseStatus.companyCheckErrorMessage && !this.isDuplicateFolder();
    },
    dqeControlData() {
      return this.contextData ? this.getFirstElement(this.contextData?.dqe_control) : {};
    }
  }
});