import {isEmpty, isNil} from "lodash";
import {CompanyInfo, CompanyInfoCauses} from "@/types/companyInfo";
import {formatLocaleDate, translate} from "@/i18n";
import {formatSiren, isES} from "@/utils/formatRegistrationNumber";
import {amountFormatter} from "@/utils/openBankingUtils";

export function useCompanyInfo() {
  const companyActivities = (checks: CompanyInfo) => {
    if (!(checks.activities && !isEmpty(checks.activities))) {
      return [];
    }
    return checks.activities.filter((activity: {
      code: string,
      name: string
    }) => activity.code && activity.name);
  };

  const getCompanyInfo = (checks: CompanyInfo) => {
    const companyInfo =  {
      corporateName: {
        label: translate('COMPANY.CORPORATE_NAME'),
        value: checks.corporateName ? checks.corporateName : '-',
        copy_to_clipboard: true
      },
      registrationNumber: {
        label: translate('COMPANY.SIREN'),
        value: checks.registrationNumber
          ? formatSiren(checks.companyCountry || 'FR', checks.registrationNumber)
          : '-',
        copy_to_clipboard: true
      },
      headquarter: {
        label: translate('ADDRESS.TITLE'),
        value: checks.headquarter ? checks.headquarter : '-',
        copy_to_clipboard: true,
        is_address: true
      },
      workforce: {
        label: translate('COMPANY.WORKFORCE'),
        value: checks.workforce ? checks.workforce : '-'
      }
      , registrationDate: {
        label: translate('COMPANY.CREATED_DATE'),
        value: checks.registrationDate ? formatLocaleDate(checks.registrationDate) : '-'
      },
      legalStatus: {
        label: translate('COMPANY.LEGAL_FORM'),
        value: checks.legalStatus ? checks.legalStatus : '-'
      },
      vatRegistrationNumber: {
        label: translate('COMPANY.VAT_REGISTRATION_NUMBER'),
        value: checks.vatRegistrationNumber ? checks.vatRegistrationNumber : '-',
        copy_to_clipboard: true
      },
      shareCapital: {
        label: translate('COMPANY.CAPITAL'),
        value: checks.shareCapital ? amountFormatter(checks.shareCapital) + ' EUR' : '-'
      }
    }
    if (isES(checks.registrationNumber)) {
      const { vatRegistrationNumber, ...others } = companyInfo
      return others
    }
    return companyInfo
  };

  const hasPermissionsWebsite = (permissions: Array<String>) => {
    return permissions?.some(elt => elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE') || elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_REGISTRANT')
      || elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_CREATION_DATE') || elt.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_REGISTRAR'));
  };

  const getCompanyInfoWebsite = (causes: CompanyInfoCauses, permissions: Array<String>) => {
    const data = {
      website: {
        label: translate('COMPANY.WEBSITE'),
        value: !isNil(causes.website) ? causes.website.toLowerCase() : '-',
        display: permissions?.includes('KIT_B2B_COMPANY_INFO_WEBSITE'),
        is_website: !!causes.website
      },
      website_domain_registrant: {
        label: translate('REGISTRANT'),
        value: causes.websiteDomainRegistrant ? causes.websiteDomainRegistrant : '-',
        display: permissions?.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_REGISTRANT')
      },
      website_domain_creation_date: {
        label: translate('COMPANY.CREATED_DATE'),
        value: causes.websiteDomainCreationDate ? formatLocaleDate(causes.websiteDomainCreationDate) : '-',
        display: permissions?.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_CREATION_DATE')
      },
      website_domain_registrar: {
        label: translate('REGISTRAR'),
        value: causes.websiteDomainRegistrar ? causes.websiteDomainRegistrar : '-',
        display: permissions?.includes('KIT_B2B_COMPANY_INFO_WEBSITE_DOMAIN_REGISTRAR')
      }
    }
    return Object.fromEntries(Object.entries(data).filter(([key, value]) => value.display));
  };

  const activityStatusLabel = (checks: CompanyInfo) => {
    if (checks.isActive) return translate('COMPANY.ACTIVE')
    if (checks.closureDate) return `${translate('COMPANY.INACTIVE')} (${translate('COMPANY.CLOSURE_DATE').toLowerCase()} : ${formatLocaleDate(checks.closureDate)})`
    return translate('COMPANY.INACTIVE')
  }

  const domiciliationValue = (checks: CompanyInfo) => {
    if (isNil(checks.domiciliation)) return {value: "null-icon.png", label: translate('COMPANY.UNVERIFIED')}
    if (checks.domiciliation) return {value: "red-circle.png", label: translate('YES')}
    return {value: "white-circle.png", label: translate('NO')}
  }

  return {
    companyActivities,
    getCompanyInfo,
    hasPermissionsWebsite,
    getCompanyInfoWebsite,
    activityStatusLabel,
    domiciliationValue
  }
}