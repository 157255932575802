import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22f98542"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-input" }
const _hoisted_2 = ["id", "type", "placeholder", "value", "name", "autocomplete", "readonly", "disabled"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.label), 1),
    _createElementVNode("input", {
      id: _ctx.id,
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      value: _ctx.value,
      name: _ctx.name,
      autocomplete: _ctx.autocomplete,
      class: "input-text__input input",
      readonly: _ctx.readonly,
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeValue('input', $event.target.value))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.blur($event))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus', $event.target.value))),
      onKeydown: [
        _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.enter($event)), ["enter"])),
        _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.down($event)), ["down"])),
        _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.up($event)), ["up"]))
      ],
      onChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.changeValue('no-click', $event.target.value)))
    }, null, 40, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        ref_for: true,
        ref: 'suggestion' + index,
        onClick: ($event: any) => (_ctx.suggestionClick($event, index)),
        class: "input-text__container"
      }, [
        _createElementVNode("span", null, _toDisplayString(option.label), 1)
      ], 8, _hoisted_3))
    }), 128))
  ]))
}