<script setup lang="ts">
import { ref } from "vue";
import TextInput from "@/components/input/TextInput.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import Button from "@/components/button/Button.vue";
import { translate } from "@/i18n";
import { getRegex, TextInputType } from "@/types/TextInputType";
import Loader from "../loader/Loader.vue";

export interface Props {
  disabledLinkedinButton: boolean;
  linkedinLink: string,
  canEnrichment: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabledLinkedinButton: false,
  linkedinLink: 'https://www.linkedin.com/',
  canEnrichment: false
});

const emit = defineEmits(["check-linkedin"]);

const textInputValue = ref<string>("");
const LINKEDIN = ref<TextInputType>(TextInputType.LINKEDIN);
const loader = ref(false);

function checkLinkedin(e: SubmitEvent) {
  e.preventDefault();
  loader.value = true;
  const id = textInputValue.value.split("/")[textInputValue.value.split("/").length - 2];
  emit("check-linkedin", id);
}

function validateDataCheckLinkedin(): boolean {
  return !getRegex(TextInputType.LINKEDIN).test(textInputValue.value);
}

</script>
<template>
  <ContentCard v-if="!canEnrichment" :title="translate('ENRICHMENT.TITLE')" class="enrichment-linkedin"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard
      :title="translate('ENRICHMENT.TITLE')"
      class="enrichment-linkedin"
    >
    <div v-if="loader">
      <Loader/>
    </div>
    <div v-else>
      <div class="enrichment-linkedin__span">
        <span>{{ translate("ENRICHMENT.MESSAGE") }}</span>
        <TextInput
          class="form-b2c-bloc-input-result"
          v-model="textInputValue"
          :type="LINKEDIN"
        />
      </div>
      <div class="enrichment-linkedin__button-container">
        <a :href="linkedinLink" target="_blank">
          <Button
            class="enrichment-linkedin__button-linkedin"
            type="submit"
            :label="translate('BUTTONS.LINKEDIN')"
            picture="linkedin-logo.png"
          />
        </a>
        <Button
          class="enrichment-linkedin__button"
          type="submit"
          :label="translate('BUTTONS.VALIDATE')"
          :disabled="disabledLinkedinButton || validateDataCheckLinkedin()"
          @click="checkLinkedin"
        />
      </div>
    </div>
    </ContentCard>
  </template>
</template>