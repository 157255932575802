<template>
  <div class="page-table__pagination">
    <div class="page-table__pagination__elements">
          <span>{{
              `${translate('PAGE')} ${currentPage + 1} ${translate('ON')} ${totalPages} - ${totalElements} ${translate('RESULTS_FOUND')}`
            }}</span>
    </div>
    <div class="page-table__pagination__navigation">
      <Button :disabled="!(currentPage <= totalPages && currentPage > 0)"
              @click="movePages(-1)"
              :label="translate('TABLE.BACK')" outlined white/>
      <div>{{ currentPage + 1 }} / {{ totalPages }}</div>
      <Button :disabled="!(currentPage < totalPages - 1 && currentPage >= 0)"
              @click="movePages(1)"
              :label="translate('TABLE.NEXT')" outlined white/>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {IPageResponse} from '@/types/Page';
import {translate} from '@/i18n';
import Button from '@/components/button/Button.vue';

export default defineComponent({
  name: 'PageTablePagination',
  components: {Button},
  props: {
    page: {type: Object as () => IPageResponse, required: true},
  },
  computed: {
    currentPage() {
      return this.page.number;
    },
    totalPages() {
      return this.page.totalPages;
    },
    totalElements() {
      return this.page.totalElements;
    }
  },
  methods: {
    translate,
    movePages(pages: number) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.currentPage + pages
        }
      })
    }
  }
});
</script>
