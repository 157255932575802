<script setup lang="ts">
import { computed } from "vue";

import {
  CheckType,
  formatDataChecks,
  formatKeyValuePairWithFunc,
  getStatusIcon,
  reduceStructureWithSameEntries,
  selectAndSortChecksByKey,
} from "@/composables/formatKeyValuePairWithFunc";
import { translate } from "@/i18n";
import {
  KeyValueType,
  getTranslationFromKey,
} from "@/utils/sortArrayObjectKeys";

import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import AuthenticityResults from "@/components/document-analysis/AuthenticityResults.vue";
import Loader from "@/components/loader/Loader.vue";

const props = defineProps({
  checks: { type: Object, default: {} },
  analysisChecks: { type: Object, default: {} },
});

const sortKeys: KeyValueType[] = [
  {
    PAYMENT_INFO: [
      "remainingAmountToPay",
      "mandateUniqueReference",
      "remainingAmountToRepay",
      "creditorName",
      "account",
      "bankId",
    ],
  },
  {
    INFO: [
      "registrantAndTaxNumber",
      "address",
      "taxAddress",
      "roleNumber",
      "ref",
      "ownerNumber",
      "fipNumber",
      "serviceId",
      "referenceYear",
      "issue",
      "assessment",
    ],
  },
];

const formatPaymentInfo = (paymentInfoData: KeyValueType) => {
  const _data = Object.assign({}, paymentInfoData);
  const remainingAmountToPay = _data.remainingAmountToPay as number;
  // Rules for remainingAmountToRepay
  if (remainingAmountToPay >= 0) {
    _data.remainingAmountToPay = `${remainingAmountToPay} EUR`;
    _data.remainingAmountToRepay = `-`;
  } else {
    _data.remainingAmountToRepay = `${remainingAmountToPay * -1} EUR`;
    _data.remainingAmountToPay = `-`;
  }
  return _data;
};

const formatInfoRegistrantAndTax = (infoData: KeyValueType) => {
  const _infoData = Object.assign({}, infoData);
  const { registrant, taxNumber } = _infoData;
  const _checksInfoRegistrantAndTaxNumber = reduceStructureWithSameEntries({
    input: { registrant: registrant, taxNumber: taxNumber },
    keys: ["registrant", "taxNumber"],
  });
  // @ts-ignore
  _infoData.registrantAndTaxNumber = _checksInfoRegistrantAndTaxNumber;
  return _infoData;
};

const sortedCheks = computed(() => {
  let _checks = formatKeyValuePairWithFunc({
    data: props.checks,
    key: "PAYMENT_INFO",
    funcs: [formatPaymentInfo],
  }) as CheckType;
  _checks = formatKeyValuePairWithFunc({
    data: _checks as KeyValueType,
    key: "INFO",
    funcs: [formatInfoRegistrantAndTax],
  }) as CheckType;
  return selectAndSortChecksByKey({
    checks: _checks,
    sortKeys,
  });
});
const formattedChecks = computed(() => formatDataChecks(sortedCheks.value));
</script>

<template>
  <div class="income-tax-results">
    <Loader v-if="JSON.stringify(analysisChecks) === '{}'" />
    <div v-else class="tax-notice-analysis">
      <span
        class="tax-notice-analysis-results"
        v-for="check in analysisChecks"
        :key="check"
      >
        <img
          :src="require(`/public/images/${getStatusIcon(check.status)}`)"
          :alt="getStatusIcon(check.status)"
          class="income-tax-results__icon"
        />
        {{
          translate(
            `DOCUMENT_CONTROL.TABLE.RESULT.TAX_NOTICE.CHECKS.${check.check}`
          )
        }}</span
      >
    </div>
    <ResultCard
      v-for="check in formattedChecks"
      :title="
        translate(`DOCUMENT_CONTROL.TABLE.RESULT.INCOME_TAX.${check.transKey}`)
      "
    >
      <div :class="`income-tax-results-${check.className}`">
        <template v-for="obj in check.value">
          <template v-if="!Array.isArray(obj)">
            <template v-if="typeof obj.value === 'object'">
              <LabelValuePrinter
                v-for="(value, key) in obj.value"
                :label="
                  translate(
                    `DOCUMENT_CONTROL.TABLE.RESULT.INCOME_TAX.${getTranslationFromKey(
                      key.toString()
                    )}`
                  )
                "
                :value="value"
              />
            </template>
            <template v-else>
              <LabelValuePrinter
                v-if="!!obj.key"
                :label="
                  translate(
                    `DOCUMENT_CONTROL.TABLE.RESULT.INCOME_TAX.${obj.transKey}`
                  )
                "
                :value="obj.value"
              />
              <div v-else></div>
            </template>
          </template>
          <template v-else>
            <template v-for="item in obj">
              <LabelValuePrinter
                v-if="!!item.key"
                :label="
                  translate(
                    `DOCUMENT_CONTROL.TABLE.RESULT.INCOME_TAX.${item.transKey}`
                  )
                "
                :value="item.value"
              />
              <div v-else></div>
            </template>
          </template>
        </template>
      </div>
    </ResultCard>
    <AuthenticityResults
      :title="translate('DOCUMENT_CONTROL.AUTHENTICITY_DETAILS')"
      :checks="checks.authenticityReasons"
    />
  </div>
</template>
