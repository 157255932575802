<template>
  <table class="table-verification">
    <thead>
    <tr>
      <th/>
      <th class="table-verification-header" v-if="emailInput !== ''">
        {{ translate('IDENTITY.EMAIL') }}
        <span v-if="emailDisposable" :title="translate('TOOLTIP.EMAIL_DISPOSABLE')"> ⚠️</span>
      </th>
      <th class="table-verification-header" v-if="phoneInput !== ''">
        {{ translate('IDENTITY.PHONE_NUMBER') }}
      </th>
      <th class="table-verification-header" v-if="addressInput.length > 2">
        {{ translate('ADDRESS.TITLE_POSTAL') }}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr class="email">
      <td>
        <label-value-printer class="results-page-b2c-body-leader-bloc-informations-mail"
                             v-for="header of headersVerification"
                             :label="header.title" :double-point="false">
        </label-value-printer>
      </td>
      <td v-if="emailInput !== ''" class="table-verification-result">{{ displayResult(emailData[0]) }}</td>
      <td v-if="phoneInput !== ''" class="table-verification-result">{{ displayResult(phoneData[0]) }}</td>
      <td v-if="addressInput.length > 2" class="table-verification-result">{{ displayResult(addressData[0]) }}</td>
    </tr>
    <tr class="phone">
      <td>
        <label-value-printer class="results-page-b2c-body-leader-bloc-informations-phone"
                             v-for="header of headersLie"
                             :label="header.title" :double-point="false"/>
      </td>
      <td v-if="emailInput !== ''" class="table-verification-result">{{ displayResult(emailData[1]) }}</td>
      <td v-if="phoneInput !== ''" class="table-verification-result">{{ displayResult(phoneData[1]) }}</td>
      <td v-if="addressInput.length > 2" class="table-verification-result">{{ displayResult(addressData[1]) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import labelValuePrinter from "@/components/LabelValuePrinter.vue";
import {translate} from '@/i18n';

export default defineComponent({
  name: "TableVerification",
  components: {
    labelValuePrinter
  },
  props: {
    headersVerification: {type: Array},
    headersLie: {type: Array, default: ''},
    emailData: {type: Array, default: ''},
    phoneData: {type: Array, default: ''},
    addressData: {type: Array, default: ''},
    emailInput: {type: String, default: ''},
    phoneInput: {type: String, default: ''},
    addressInput: {type: String, default: ''},
    emailDisposable: {type: Boolean, default: ''},
  },
  methods: {
    translate,
    displayResult(result:Boolean) {
      return result ? '🟢' : '🔴';
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.table-verification {

  border-collapse: separate;
  font-size: $fontSize;

  th {
    text-align: center;
  }

  &-header {
    color: $dataPrinterFontColor;
  }

  &-result {
    text-align: center;
    border: solid 0;
    border-radius: 10px;
    background-color: #FFFFFF;
  }
}

.results-page-b2c-body-leader-bloc-informations-address, .results-page-b2c-body-leader-bloc-informations-mail, .results-page-b2c-body-leader-bloc-informations-phone {
  margin: 0;
}
</style>