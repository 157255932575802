<script setup lang="ts">
import { uniqBy } from "lodash";
import { computed } from "vue";

import { translate } from "@/i18n";

const props = defineProps<{
  modelValue: string[];
  options: {
    value: string | number;
    label: string;
    isPermitted: boolean;
  }[];
  canSelect: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string[]];
}>();

const uniqOptions = computed(() => uniqBy(props.options, "value"));

const handleToggle = ($event: PointerEvent, optionIndex: number) => {
  if (!props.canSelect) return;

  const option = uniqOptions.value[optionIndex];

  if (!option.isPermitted) return;

  const optionValue = option.value as string;
  let selectedOption = Array.from(props.modelValue);

  if (selectedOption.includes(optionValue)) {
    selectedOption = selectedOption.filter((value) => value !== optionValue);
  } else {
    selectedOption.push(optionValue);
  }

  const element = $event.target as HTMLDivElement;
  element.classList.toggle("selected");
  emit("update:modelValue", selectedOption);
};
</script>

<template>
  <div class="document-type-selector-container">
    <span class="document-type-selector-title">{{
      translate("DOCUMENT_CONTROL.SELECT_DOCUMENT_TITLE")
    }}</span>
    <div class="document-type-selector-content">
      <div
        v-for="(option, idx) in uniqOptions"
        @click="($event) => handleToggle($event as PointerEvent, idx)"
        :key="idx"
        :class="{
          selected: props.modelValue.includes(option.value as string),
        }"
        class="document-type-selector-option"
      >
        {{ option.label }}
        <img
          v-if="!option.isPermitted"
          :src="require('/public/images/padlock-blue.png')"
          alt=""
        />
      </div>
      <slot name="options" />
    </div>
  </div>
</template>
