import {useRouter} from "vue-router";
import {isNull, isObject, upperCase} from "lodash";
import {TopButtonType} from "@/types/TopButtonType";
import {computed, ComputedRef} from "vue";
import store from "@/store";

export function useHeaderBar() {

  type topButton = {
    back_home: TopButtonType,
    new_analysis?: TopButtonType,
    duplicate_folder?: TopButtonType
  }

  type UserData = {
    id: number;
    username: string;
    company: {
      id: number;
      name: string;
      twoFactorRequired: boolean;
    };
    email: string,
    function: any,
    phone: string,
    realUser: {
      id: number,
      roles: string[],
      username: string
    },
    roles: string[],
    supervisor: {
      id: number,
      roles: string[],
      username: string
    },
    twoFactorStatus: 'SMS' | 'EMAIL' | 'DISABLED'
    group: string,
    place: string,
  }

  const router = useRouter();

  const taskData = computed(() => store.getters['taskModule/getTaskData'])  ;

  const menuItems = [
    {label: 'MENU.MY_ACCOUNT', imageSrc: "profil-icon.png", route: "/account"},
    {label: 'MENU.MY_CONSUPTION', imageSrc: "conso-icon.png", route: "/consumption"},
    {label: 'MENU.PORTAL_INFORMATION', imageSrc: "info-icon.png", route: router.currentRoute},
    {label: 'SIDEBAR.PORTAL', imageSrc: "logo_vertical_meelo_full_blue.png", route: "/historical"},
    {label: 'MENU.BACK_OFFICE', imageSrc: "bo-icon.png", route: "/back-office/home"},
    {label: 'MENU.LOG_AS', imageSrc: "connect-as-icon-blue.png", route: "/home"},
    {label: 'MENU.LOGOUT', imageSrc: "logout-icon-blue.png", route: "/logout"}
  ];
  const meeloRoles = ['MEELO_ADMIN', 'MEELO_MANAGER', 'MEELO_USER'];
  const backOfficeRoles = ['COMPANY_ADMIN', 'COMPANY_MANAGER'];
  const logoutItem = {
    label: 'MENU.LOGOUT_AS',
    imageSrc: "connect-as-icon-red.png",
    route: "/home"
  };

  const getInfoButtons = (route: string, journeyId?: ComputedRef<string>, details?: any) => {
    let buttons: topButton = {
      back_home: {
        route: route === '/recovery' ? "home" : "historical",
        title: 'BUTTONS.BACK_HOME',
        color: route === '/analysis-b2b' ? "#36ba75" : "#293354"
      }
    }
    if (route === '/synthesis' && isObject(journeyId) && journeyId.value) {
      const routeMapping: any = {
        'portal-b2b': '/analysis-b2b',
        'portal-b2c': '/analysis-b2c'
      };

      route = routeMapping[details?.clientId] || route;

      // Boutton dupliqué uniquement sur les dossiers du portal 
      if (taskData.value && (taskData.value?.clientId === "portal-b2b" || taskData.value?.clientId === "portal-b2c")) {
        buttons.duplicate_folder = {
          route: `${route}?journeyId=${journeyId.value}&duplicate=${true}`,
          title: "TOOLTIP.DUPLICATE",
          color: "#293354",
        };
      }

      return buttons; //only back home when on synthesis page
    }

    buttons.new_analysis = {
      route: route,
      title:
        route === "/recovery" ? "BUTTONS.NEW_REQUEST" : "BUTTONS.NEW_ANALYSIS",
      color: route === "/analysis-b2b" ? "#ffa543" : "#36ba75",
    };
    if (
      (route === "/analysis-b2c" || route === "/analysis-b2b") &&
      isObject(journeyId) &&
      journeyId.value
    ) {
      // Boutton dupliqué uniquement sur les dossiers du portal 
      if (taskData.value && (taskData.value?.clientId === "portal-b2b" || taskData.value?.clientId === "portal-b2c")) {
        buttons.duplicate_folder = {
          route: `${route}?journeyId=${journeyId.value}&duplicate=${true}`,
          title: "TOOLTIP.DUPLICATE",
          color: "#293354",
        };
      }
    }
    return buttons;
  };

  const actionNavigation = (route: string) => {
    if (route === "") return;
    switch (route) {
      case "/analysis-b2c":
        router.push(`/analysis-b2c`);
        window.dispatchEvent(new Event("resetAnalysisB2c"));
        break;
      case "/analysis-b2b":
        router.push(`/analysis-b2b`);
        window.dispatchEvent(new Event("resetAnalysisB2b"));
        break;
      case "/open-banking":
        router.push(`/open-banking`);
        window.dispatchEvent(new Event("resetOpenBanking"));
        break;
      case "/recovery":
        router.push(`/recovery`);
        window.dispatchEvent(new Event("resetRecoveryAnalysis"));
        break;
      default:
        router.push(route);
        break;
    }
  };

  const navigate = (currentRoute: string, route?: string) => {
    if (route) return currentRoute === '/synthesis' ? router.push(route) : actionNavigation(route);
  };

  const menuFiltered = (user: UserData, userSelected: string, companySelected: string, route: string) => {
    const canBackOffice = backOfficeRoles.some(item => user.roles.includes(item));
    const isMeeloUser = meeloRoles.some(item => user.roles.includes(item)) && isNull(user.realUser);
    const isUserInConnectAs = userSelected && companySelected && !isNull(user.realUser);
    const isUserInBackOffice = route.startsWith('/back-office');
    const isNotMeeloCompany = upperCase(user.company.name) !== 'MEELO';

    const conditionsMap: { [key: string]: boolean } = {
      'MENU.LOG_AS': isNotMeeloCompany || isUserInConnectAs || !isMeeloUser,
      'SIDEBAR.PORTAL': !isUserInBackOffice,
      'MENU.BACK_OFFICE': !isUserInBackOffice && !canBackOffice || isUserInBackOffice
    };

    const filteredItems = menuItems.filter(item => !conditionsMap[item.label]);
    if (!isNull(user.realUser)) filteredItems.push(logoutItem);
    return filteredItems;
  }

  return {getInfoButtons, actionNavigation, navigate, menuFiltered};
}