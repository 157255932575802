<template>
  <div class="manage-groups">
    <h1 class="manage-groups__title">{{ translate('BACKOFFICE.GROUPS.COMPANY_GROUPS') }}</h1>
    <span class="manage-groups__subtitle">{{ `${translate('RESEARCH')} :` }}</span>
    <form class="manage-groups__search" v-on:submit="refresh">
      <div>
        <TextInput :label="`${translate('BACKOFFICE.GROUPS.NAME')} :`" :model-value="search.name"
                   v-on:update:model-value="$router.push({query: {...$route.query, name: $event === '' ? undefined : $event}})"
                   color="light"/>
      </div>
      <div>
        <MeeloButton :label="translate('BUTTONS.RESET')" type="reset" outlined white/>
        <MeeloButton :label="translate('BUTTONS.SEARCH')" type="submit" outlined white/>
      </div>
    </form>
    <Button
      class="manage-groups__add"
      :label="translate('BUTTONS.ADD')"
      picture="plus.png"
      v-on:click="openEditModal = true"
    />
    <PageTable v-if="page" :page="page"
               :columns="[{
      title: translate('BACKOFFICE.GROUPS.NAME'),
      render: ({name}) => ({value: name})
    },{
      title: translate('BACKOFFICE.GROUPS.DESCRIPTION'),
      render: ({description}) => ({value: description})
    },{
      title: translate('BACKOFFICE.GROUPS.ACTIONS'),
      render: (item) => ({
        component: BackOfficeGroupActions,
        events: {
          edit: ()=> edit(item),
          delete: () => {
            selectedGroup = item;
            this.delete();
          }
        },
        props: {
          see: false
        }})
    }]" white/>
    <CustomModal v-model:open="openEditModal" class="manage-groups__edit-modal" :displayCloseButton="true">
      <form v-on:submit="send">
        <h1 v-if="!selectedGroup">{{ translate('BACKOFFICE.GROUPS.CREATE') }}</h1>
        <h1 v-else>{{ translate('BACKOFFICE.GROUPS.EDIT') }} {{ selectedGroup.name }}</h1>
        <TextInput :label="`${translate('BACKOFFICE.GROUPS.NAME')} :`"
                   v-model:model-value="editRequest.name"
                   :type="TextInputType.UPPER_CASE"
                   required
        />
        <TextInput :label="`${translate('BACKOFFICE.GROUPS.DESCRIPTION')} :`"
                   v-model:model-value="editRequest.description"
        />
        <div class="manage-groups__edit-modal__buttons">
          <Button :label="translate('BUTTONS.VALIDATE')" type="submit"/>
        </div>
      </form>
    </CustomModal>
    <CustomModal v-model:open="openDeleteModal" class="manage-groups__delete-modal">
      <div>
        {{ translate('BACKOFFICE.GROUPS.DELETE_WARN') }}
      </div>
      <div class="manage-groups__delete-modal__buttons">
        <Button :label="translate('BUTTONS.CANCEL')" type="button" v-on:click="()=>{
          openDeleteModal = false;
          selectedGroup = undefined;
        }"/>
        <Button :label="translate('BUTTONS.VALIDATE')" type="button"
                v-on:click="()=>this.delete(true)"/>
      </div>
    </CustomModal>
  </div>
</template>
<script lang="tsx">
import {defineComponent, h} from 'vue';
import {translate} from '@/i18n';
import {
  createGroup,
  deleteGroup,
  searchGroups,
  updateGroup
} from '@/apiCalls/backOffice/admin/groups';
import {GroupRequest, GroupResponse, SearchGroupsRequest} from '@/types/Groups';
import {IPageResponse} from '@/types/Page';
import PageTable from '@/components/table/PageTable.vue';
import MeeloButton from '@/components/button/Button.vue';
import Button from '@/components/button/Button.vue';
import TextInput from '@/components/input/TextInput.vue';
import BackOfficeGroupActions from '@/components/backoffice/BackOfficeGroupActions.vue';
import CustomModal from '@/components/modal/CustomModal.vue';
import {TextInputType} from '@/types/TextInputType';
import {AxiosError} from 'axios';

export default defineComponent({
  name: 'ManageGroups',
  components: {Button, CustomModal, TextInput, MeeloButton, PageTable},
  data: () => ({
    page: undefined as IPageResponse<GroupResponse> | undefined,
    search: {
      page: 0,
      size: 10,
      name: ''
    } as SearchGroupsRequest,

    openEditModal: false,
    selectedGroup: undefined as GroupResponse | undefined,
    editRequest: {
      name: '',
      description: ''
    } as GroupRequest,

    openDeleteModal: false
  }),
  computed: {
    TextInputType() {
      return TextInputType;
    },
    BackOfficeGroupActions() {
      return BackOfficeGroupActions;
    },
    pageQuery() {
      const {page} = this.$route.query;
      return page;
    },
    sizeQuery() {
      const {size} = this.$route.query;
      return size;
    },
    nameQuery() {
      const {name} = this.$route.query;
      return name;
    },
  },
  methods: {
    h, translate,
    async refresh(e?: Event) {
      e?.preventDefault();
      const {data} = await searchGroups(this.search);
      this.page = data;
    },
    edit(group: GroupResponse) {
      this.openEditModal = true;
      this.selectedGroup = group;
      this.editRequest = {
        name: group.name,
        description: group.description
      };
    },
    async delete(force?: boolean) {
      try {
        const {name} = this.selectedGroup!;
        await deleteGroup(name, force);
      } catch (e) {
        if (e instanceof AxiosError && e.response?.status === 409) {
          this.openDeleteModal = true;
          return;
        }
        throw e;
      } finally {
        await this.refresh();
      }
      this.openDeleteModal = false;
      this.selectedGroup = undefined;
    },
    async send(e?: Event) {
      e?.preventDefault();
      if (this.selectedGroup) {
        await updateGroup(this.selectedGroup.name, this.editRequest);
      } else {
        await createGroup(this.editRequest);
      }
      this.openEditModal = false;
      this.editRequest = {
        name: '',
        description: ''
      };
      await this.refresh();
    }
  },
  created() {
    if (this.pageQuery) this.search.page = Number(this.pageQuery);
    if (this.sizeQuery) this.search.size = Number(this.sizeQuery);
    if (this.nameQuery) this.search.name = this.nameQuery.toString();
    this.refresh();
  },
  watch: {
    openEditModal(value) {
      if (value) return;
      this.selectedGroup = undefined;
      this.editRequest = {
        name: '',
        description: ''
      };
    },
    pageQuery(value) {
      this.search.page = Number(value) || 0;
      this.refresh();
    },
    sizeQuery(value) {
      this.search.size = Number(value) || 10;
      this.refresh();
    },
    nameQuery(value) {
      this.search.name = value?.toString();
    }
  }
});
</script>



