<template>
  <input type="radio" :checked="checkedValue" :name="name" v-on:change="$emit('update:checkedValue', $event.target.checked)">
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    checkedValue: {type: Boolean, default: false},
    name: {type: String, required: true}
  }
});
</script>