<template>
  <content-card :title="translate('DETAILED_SCORE')" class="detailed-score">
    <div class="detailed-score_first-block">
      <result-card :title="translate('PHONE')">
        <verification-result :value="scoreSMB.contact.phoneExists" :label="translate('VERIFIED')"
                             :type="translate('PHONE')" :tooltip="translate('TOOLTIP.MOBILE_PHONE_VERIFICATION')"
                             class="verification"/>
        <verification-result :value="scoreSMB.contact.phoneName" :label="translate('LINKED')"
                             :type="translate('PHONE')" :tooltip="translate('TOOLTIP.MOBILE_PHONE_LINK')"
                             :is-warning="true"/>
        <label-value-printer :label="translate('FIRST_SEEN_DATE')"
                             :value="scoreSMB.contact && scoreSMB.contact.phoneFirstSeenDate ? new Date(scoreSMB.contact.phoneFirstSeenDate).toLocaleDateString() : '-'"/>
        <label-value-printer :label="translate('TELEPHONE.NUMBER')"
                             :value="customerProfile.identity.mobilePhoneNumber"/>
        <label-value-printer :label="translate('TELEPHONE.LINETYPE')"
                             :value="scoreSMB.contact.phoneLinetype"/>
        <label-value-printer :label="translate('TELEPHONE.GEOLOCATION')"
                             :value="scoreSMB.contact.phoneGeolcation"/>
        <label-value-printer :label="translate('TELEPHONE.CARRIER')" :value="scoreSMB.contact.phoneCarrier"/>
        <label-value-printer :label="translate('TELEPHONE.ORIGINAL_CARRIER')"
                             :value="scoreSMB.contact.phoneOriginalCarrier"/>
      </result-card>
      <result-card :title="translate('EMAIL')">
        <verification-result :value="scoreSMB.contact.emailExists" :label="translate('VERIFIED')"
                             :type="translate('EMAIL')" :tooltip="translate('TOOLTIP.EMAIL_VERIFICATION')"
                             class="verification"/>
        <verification-result :value="scoreSMB.contact.emailName" :label="translate('LINKED')"
                             :type="translate('EMAIL')" :tooltip="translate('TOOLTIP.EMAIL_LINK')" :is-warning="true"/>
        <label-value-printer :label="translate('FIRST_SEEN_DATE')"
                             :value="scoreSMB.contact && scoreSMB.contact.emailFirstSeenDate ? new Date(scoreSMB.contact.emailFirstSeenDate).toLocaleDateString() : '-'"/>
        <label-value-printer :label="translate('EMAIL')" :value="customerProfile.identity.email"/>
      </result-card>
      <result-card class="social-networks" :title="translate('SOCIAL_NETWORKS.TITLE')">
        <div class="detailed-score_social-networks">
          <div>
            <span class="detailed-score_social-networks-label">{{ translate('SOCIAL_NETWORKS.PHONE') }} : </span>
            <span>{{ scoreSMB.socialNetworks.phone }}</span>
          </div>
          <ul v-if="scoreSMB.socialNetworks.phoneList.length > 0">
            <li v-for="(phone_network, key) in scoreSMB.socialNetworks.phoneList" :key="key"
                class="detailed-score_social-networks-result">
              {{ phone_network }}
            </li>
          </ul>
          <div>
            <span class="detailed-score_social-networks-label">{{ translate('SOCIAL_NETWORKS.MAIL') }} : </span>
            <span>{{ scoreSMB.socialNetworks.email }}</span>
          </div>
          <ul v-if="scoreSMB.socialNetworks.emailList.length > 0">
            <li v-for="(mail_network, index) in scoreSMB.socialNetworks.emailList" :key="index"
                class="detailed-score_social-networks-result">
              {{ mail_network }}
            </li>
          </ul>
        </div>
      </result-card>
    </div>
    <div class="detailed-score_second-block">
      <div>
        <result-card v-if="address.length > 2"
                     class="detailed-score_second-block-address special-block"
                     :title="translate('ADDRESS.TITLE_POSTAL')">
          <label-value-printer :label="translate('ADDRESS.TITLE')" :value="address"/>
          <label-value-printer :label="translate('SOCIAL_NETWORKS.ADDRESS_LINKED')"
                               :value="displayResult(scoreSMB.contact.addressName)"/>
          <div class="detailed-score_second-block-address_research">
            <a
              :href="`https://www.google.fr/maps/search/${address.replaceAll(' ', '+')}`"
              target="_blank">
              <Button :label="translate('BUTTONS.VERIFY_ADDRESS')"/>
            </a>
          </div>
        </result-card>
        <result-card
          :title="translate('SOCIAL_FACE_MATCHING.TITLE')"
          v-if="searchImage.length > 0"
          class="special-block"
        >
          <label-value-printer
            class="facematching-images border"
            :label="translate('SOCIAL_FACE_MATCHING.CATCHED_IMAGES')"
            :value="searchImage.length"
            :tooltip-title="translate('TOOLTIP.SOCIAL_FACE_MATCHING')"
          />
          <Carousel :images="searchImage"/>
        </result-card>
      </div>
      <div class="block-amlcft">
        <result-card v-if="(customerProfile.identity.birthDate !== '') && (amlcft !== undefined)"
                     :title="translate('AMLCFT.TITLE')"
                     class="special-block">
          <div v-if="amlcft.amlTypes.length > 0" class="block-amlcft-content">
            <div>
              <span class="block-amlcft-title">{{ translate('AMLCFT.SANCTIONED') }} :</span>
              <div>
                <p class="block-amlcft-alert">{{ translate('AMLCFT.SANCTIONED_ALERT') }}</p>
                <p>{{ translate('AMLCFT.SANCTIONED_EXPLANATIONS') }}</p>
              </div>
            </div>
            <template v-for="(type, index) in amlcft.amlTypes" :key="index">
              <div v-if="type === 'pep'">
                <span class="block-amlcft-title">{{ translate('AMLCFT.PPE') }} :</span>
                <div>
                  <p class="block-amlcft-alert">{{ translate('AMLCFT.PPE_ALERT') }}</p>
                  <template v-for="(pep, key) in amlcft.amlTypesDetails.pep" :key="key">
                    <p v-if="pep === 'class-1'"><span class="block-amlcft-level">{{
                        `${translate('AMLCFT.LEVEL')} 1 - `
                      }}</span> {{ translate('AMLCFT.CLASS_1') }}</p>
                    <p v-if="pep === 'class-2'"><span class="block-amlcft-level">{{
                        `${translate('AMLCFT.LEVEL')} 2 - `
                      }}</span>{{ translate('AMLCFT.CLASS_2') }}</p>
                    <p v-if="pep === 'class-3'"><span class="block-amlcft-level">{{
                        `${translate('AMLCFT.LEVEL')} 3 - `
                      }}</span>{{ translate('AMLCFT.CLASS_3') }}</p>
                    <p v-if="pep === 'class-4'"><span class="block-amlcft-level">{{
                        `${translate('AMLCFT.LEVEL')} 4 - `
                      }}</span>{{ translate('AMLCFT.CLASS_4') }}</p>
                  </template>
                </div>
              </div>
              <div v-if="type === 'adverse_media'" class="block-amlcft-adverse_media">
                <span class="block-amlcft-title">{{ translate('AMLCFT.ADVERSE_MEDIA') }}</span>
                <div>
                  <p class="block-amlcft-alert">{{ translate('AMLCFT.ADVERSE_MEDIA_ALERT') }}</p>
                  <p class="block-amlcft-title">{{ translate('AMLCFT.ADVERSE_MEDIA_TYPE') }}</p>
                  <div class="block-amlcft-types">
                    <template v-for="(adverse_media, index) in amlcft.amlTypesDetails.adverse_media"
                              :key="index">
                    <span class="block-amlcft-adverse_details">{{
                        translate(`AMLCFT.ADVERSE_MEDIA_DETAILS.${adverse_media}`)
                      }}</span>
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="amlcft.links.length > 0">
              <span class="block-amlcft-title">{{ translate('AMLCFT.LINKS') }} :</span>
              <ul>
                <li><a :href="amlcft.links[0]" target="_blank">{{
                    amlcft.links[0]
                  }}</a></li>
                <li><a :href="amlcft.links[1]" target="_blank">{{
                    amlcft.links[1]
                  }}</a></li>
                <li><a :href="amlcft.links[2]" target="_blank">{{
                    amlcft.links[2]
                  }}</a></li>
                <li><a :href="amlcft.links[3]" target="_blank">{{
                    amlcft.links[3]
                  }}</a></li>
              </ul>
            </div>
          </div>
          <div v-else class="block-amlcft-message">
            <span>{{ translate('AMLCFT.NO_RESULT') }}</span>
          </div>
        </result-card>
      </div>
    </div>
  </content-card>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import Modal from "@/components/Modal.vue";
import IdentityCheckResultRatings from "@/components/IdentityCheckResultRatings.vue";
import VerificationDetails from "@/components/VerificationDetails.vue";
import Loader from "@/components/loader/Loader.vue";
import LittleBlockModal from "@/components/LittleBlockModal.vue";
import TableInformation from "@/components/TableInformation.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import MeeloPopOver from "@/components/MeeloPopOver.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import Carousel from "@/components/carousel/Carousel.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import VerificationResult from "@/components/VerificationResult.vue";

export default defineComponent({
  name: "ScoringDetailedBoxSMB",
  components: {
    VerificationResult,
    ResultCard,
    Carousel,
    Button,
    LabelValuePrinter,
    MeeloPopOver,
    ContentCard,
    TableInformation,
    LittleBlockModal,
    Loader,
    VerificationDetails,
    IdentityCheckResultRatings,
    Modal
  },
  props: {
    scoreSMB: {type: Object},
    searchImage: {type: Object},
    amlcft: {type: Object},
    customerProfile: {type: Object},
    address: {type: String, default: ''}
  },
  data() {
    return {
      verify_address: ''
    }
  },
  methods: {
    translate,
    displayResult(result: Boolean) {
      return result ? '🟢' : '🔴';
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.detailed-score {
  color: $pureBlack;
  grid-column: 1/3;
  margin: 1rem;

  &_research {
    &-facematching {
      width: 35rem;
      max-width: 35rem;
    }
  }

  &_networks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  &_research {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 1rem;
  }

  &_first-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  &_telephone {
    width: 35rem;
    max-width: 35rem;
  }

  &_amlcft {
    &-block {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
  }

  &_social-networks {
    margin: 1rem;

    &-result {
      font-family: $fontFamilyBold;
    }
  }

  &_second-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    &-address {
      margin-bottom: 1rem;

      &_research {
        text-align: center;
      }
    }
  }
}

.block-amlcft {
  &-title {
    text-decoration: underline;
  }

  &-level {
    font-family: $fontFamilyBold;
  }

  &-alert {
    font-family: $fontFamilyBold;
    color: $redMeelo;
  }

  &-adverse_details {
    border: 1px solid;
    border-radius: 10px;
    padding: 0.5rem;
    background-color: $cardBackgroundColor;
  }

  &-adverse_media {
    margin-bottom: 1rem;
  }

  &-content {
    padding: 1rem;
    max-height: 34.5rem;
    overflow-y: scroll;
  }

  &-types {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    text-align: center;
  }

  &-message {
    text-align: center;
  }
}

@media screen and (min-width: 1900px) {
  .detailed-score {
    &_research {
      &-address {
        &_link {
          bottom: 12.8rem;
        }
      }
    }
  }
}

.verification {
  margin-bottom: 0.5rem;
}
</style>