import { inject } from "vue";
import { VueCookies } from "vue-cookies";

export type BonhommeType = {
    value: number,
    name: string,
    hasBeenFound: boolean
}

export type BonhommeProps = {
    [key: number]: BonhommeType
}

export const setXMasCookie = () => {
    const $cookies = inject<VueCookies>("$cookies")
    const _bonhommes = {
        1: { value: 1, name: "Nicolas", hasBeenFound: false },
        2: { value: 2, name: "Sarah", hasBeenFound: false },
        3: { value: 3, name: "Thomas", hasBeenFound: false },
        4: { value: 4, name: "Benjamin", hasBeenFound: false },
        5: { value: 5, name: "Wendy", hasBeenFound: false },
        6: { value: 6, name: "Julien", hasBeenFound: false },
        7: { value: 7, name: "Anthony", hasBeenFound: false },
        8: { value: 8, name: "Clément", hasBeenFound: false },
        9: { value: 9, name: "Pierre", hasBeenFound: false }, //
        10: { value: 10, name: "Melvin", hasBeenFound: false },
        11: { value: 11, name: "Célina", hasBeenFound: false }, //
        12: { value: 12, name: "Mathilda", hasBeenFound: false },
        13: { value: 13, name: "Florent", hasBeenFound: false },
        14: { value: 14, name: "Grégory", hasBeenFound: false },
        15: { value: 15, name: "Hugo", hasBeenFound: false }
    }
    $cookies?.set("bonhommes", _bonhommes)
}

export const isAllBonhommeFound = () : boolean => {
    const $cookies = inject<VueCookies>("$cookies")
    const _bonhommes: BonhommeProps = $cookies?.get("bonhommes")
    return !Object.values(_bonhommes).some(_bonhomme => _bonhomme.hasBeenFound === false)
}