<script lang="ts" setup>
import { snakeCase } from "lodash";

import { translate } from "@/i18n";
import PrintCard from "@/components/print/shared/PrintCard.vue";

type Entries = {
  creationDate?: string;
  folderStatus?: string;
  analyst?: string;
  uniqId: string;
};

defineProps<{ entries: Entries }>();

const getTransKey = (key: string) => {
  return `PRINT.FOLDER_INFO.${snakeCase(key).toUpperCase()}`;
};
</script>

<template>
  <PrintCard title="PRINT.FOLDER_INFO.TITLE">
    <div class="print-folder-info-container">
      <div class="entry" v-for="(value, key) in entries" :key="key">
        <p>
          <span>{{ translate(getTransKey(key)) }} : </span>
          {{ value }}
        </p>
      </div>
    </div>
  </PrintCard>
</template>
