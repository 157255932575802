<script lang="ts" setup>
import {PropType, ref, watch} from "vue";
import {translate} from "@/i18n";
import {getRegex, TextInputType} from "@/types/TextInputType";
import { phoneIndicator } from "@/utils/phoneIndicator";

const props = defineProps({
  color: {default: "dark", type: String as PropType<"dark" | "light">},
  modelValue: {default: ""},
  label: {default: ""},
  placeholder: {default: ""},
  type: {
    type: Number as PropType<TextInputType>,
    default: TextInputType.TEXT,
  },
  required: {type: Boolean, default: false},
  readOnly: {type: Boolean, default: false},
  disabled: {type: Boolean, default: false},
  highlight: {type: Boolean, default: false},
});

const error = ref("");

const emits = defineEmits(["update:modelValue"]);

const input = (payload: Event) => {
  emits("update:modelValue", (payload.target as HTMLInputElement).value);
};

const isItErrors = () => {
  const regex = getRegex(props.type);
  if(props.modelValue === "" || phoneIndicator.filter(obj => obj.value === props.modelValue)[0]) {
    error.value = "";  //when we start a new analysis or change the flag for the number phone
  } else if (!regex.test(props.modelValue)) {
    const errorMessages = {
      [TextInputType.LINKEDIN]: "REGEX.LINKEDIN",
      [TextInputType.NOT_NULL]: "REGEX.NOT_NULL",
      [TextInputType.PHONE_NUMBER]: "REGEX.PHONE_NUMBER",
      [TextInputType.EMAIL]: "REGEX.EMAIL",
      [TextInputType.DATE]: "REGEX.DATE",
      [TextInputType.SIREN]: "REGEX.SIREN",
      [TextInputType.NATIONAL_REGISTRATION_NUMBER]: "REGEX.NATIONAL_REGISTRATION_NUMBER",
      [TextInputType.LETTERS]: "REGEX.LETTERS",
      [TextInputType.IBAN]: "REGEX.IBAN",
      [TextInputType.NAME]: "REGEX.NAME",
      [TextInputType.UPPER_CASE]: "REGEX.UPPER_CASE"
    } as any;
    error.value = translate(errorMessages[props.type]);
  } else if(props.type === TextInputType.PHONE_NUMBER){
    const phone = props.modelValue;
    const indicator = phone.substring(0, 3);
    const length = phoneIndicator.filter(obj => obj.value === indicator)[0]['length'];
    if(!length.includes(phone.length)) {
      error.value = translate("REGEX.PHONE_NUMBER");
    } else {
      error.value = ""; //need to clean the error tag if the length is good
    }
  } else {
    error.value = "";
  }
};

watch(
    () => props.modelValue,
    () => {
      isItErrors();
    }
);
</script>

<template>
  <div class="text-input-div">
    <label :class="color">{{ label }}</label>
    <input
        class="form-text-input input"
        :class="highlight ? 'highlight' : ''"
        v-on:input="input"
        :value="modelValue"
        :placeholder="placeholder"
        :required="required"
        :readonly="readOnly"
        :disabled="disabled"
    />
    <span v-if="error !== ''" class="error-tag">{{ error }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.text-input-div {
  display: flex;
  flex-direction: column;

  label {
    font-weight: lighter;
    padding: 0 0 0 0.2rem;
    font-size: $fontSize;

    &.light {
      color: white;
    }

    &.dark {
      color: $loginColor2;
    }
  }
}

.form-text-input {
  color: rgb(60, 66, 87);
}

.error-tag {
  border: solid 0;
  border-radius: 5px;
  font-size: $fontSizeError;
  font-weight: bold;
  background-color: $redMeelo;
  color: white;
  margin: 0.4vh 0;
  padding: 0 0.3vw;
}

.search-input-bar {
  input {
    font-size: $fontSizeSixteenSize;
  }
}

.zipcode {
  input {
    width: 10rem;
  }
}

.form-b2c-bloc-input-result {
  margin-bottom: 0.5rem;
}

.container-openbanking-form-input {
  .text-input-div {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.phone-number {
  margin-bottom: 0;
}

.container-openbanking-informations-form-email {
  width: 15rem;
  margin-left: 2.2rem;
}

.personal-info {
  .text-input-div {
    margin-bottom: 1rem;
  }
}

.highlight {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}
</style>