import {NavigationItem} from "@/components/sidebar/groups/NavigationItem";
import {translate} from "@/i18n";

const companyNavigation: NavigationItem[] = [
  {
    title: 'SIDEBAR.COMPANY.TITLE',
    colorBackground: '#D9D9D9',
    colorTitle: '#293354',
    elements: [{
      title: translate('SIDEBAR.COMPANY.TITLE'),
      route: '/back-office/my-company',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }, /*{
      title: translate('SIDEBAR.COMPANY.MANAGE_LIST'),
      route: '/back-office/list-management',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }*/]
  },
  {
    title: '',
    colorBackground: '#293354',
    colorTitle: 'white',
    elements: [{
      title: translate('SIDEBAR.HOME'),
      route: '/back-office/home',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }, {
      title: translate('SIDEBAR.PORTAL'),
      route: '/home',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }/*, {
      title: translate('SIDEBAR.MEELO_ACADEMY'),
      route: '/meelo-academy',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    } */]
  }
];

export default companyNavigation;