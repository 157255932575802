import {
  ConfigType,
  transformData,
} from "@/utils/dataTransformer/dataTransformer";

type PositionType = {
  positionName: string;
  dateAppointed: string;
};

const ESRepresentativeConfig: ConfigType = {
  type: {
    newLabel: "type",
  },
  companies: {
    newLabel: "companies",
  },
  firstNames: {
    newLabel: "firstNames",
    defaultValue: ({
      firstNames,
      name,
    }: {
      firstNames: string;
      name: string;
    }) => firstNames || name,
  },
  lastName: {
    newLabel: "lastName",
  },
  name: {
    newLabel: "name",
  },
  birthName: {
    newLabel: "birthName",
    defaultValue: ({ birthName }: { birthName?: string }) => birthName || "",
  },
  dateOfBirth: {
    newLabel: "dateOfBirth",
  },
  positions: {
    newLabel: "position",
    defaultValue: ({ positions }: { positions: PositionType[] }) => {
      const position = positions
        .filter((p) => p.dateAppointed)
        ?.sort(
          (a, b) =>
            new Date(a.dateAppointed).valueOf() -
            new Date(b.dateAppointed).valueOf()
        );
      return position.length > 0 ? position[0].positionName : "";
    },
  },
  placeOfBirth: {
    newLabel: "placeOfBirth",
  },
  registryDate: {
    newLabel: "registryDate",
  },
  isNotSelectable: {
    newLabel: "isNotSelectable",
    defaultValue: ({
      firstNames,
      lastName,
    }: {
      firstNames: string;
      lastName: string;
    }) => !(firstNames && lastName),
  },
};

export const ESRepresentativeTransformer = transformData(
  ESRepresentativeConfig
);
