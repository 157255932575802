<script setup lang="ts">
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import Select from "@/components/select/Select.vue";
import CalendarInput from '@/components/input/CalendarInput.vue';
import {ref, defineEmits} from "vue";
import {formatCategory} from "@/utils/openBankingUtils";
import TextInput from "@/components/input/TextInput.vue";
import _ from "lodash";

const props = defineProps({
  transactions: {type: Object, required: true},
});

// search group values
const emit = defineEmits(['submit-search']);

const dateValue = ref('');
const categoryValue = ref('');
const balanceValue = ref('');
const labelValue = ref('');

const trans = props.transactions.value;

const originalCategories = _.map(trans, 'meelo_category.label');
const categoryTemps = _.map(originalCategories, formatCategory);
//const labelTemps = _.map(trans, val => formatLabel(val.label));

// all possible categories
const category = _.sortBy(_.uniq(categoryTemps));
//const labelsValues = _.uniq(labelTemps);

// emit all the search values
const submitSearch = (e: SubmitEvent) => {
  e.preventDefault();
  emit('submit-search', {
    date: dateValue.value,
    category: categoryValue.value,
    balance: balanceValue.value,
    label: labelValue.value
  });
};

const handleReset = () => {
  dateValue.value = '';
  categoryValue.value = '';
  balanceValue.value = '';
  labelValue.value = '';
  emit('handle-reset');
}

</script>

<template>
  <div class="container-openbanking-bank-statement-searchbox">
    <span class="container-openbanking-bank-statement-searchbox-title">{{ `${translate('RESEARCH')} :` }}</span>
    <form class="container-openbanking-bank-statement-searchbox-form" @submit="submitSearch">
      <div class="container-openbanking-bank-statement-searchbox-search-group">
        <CalendarInput v-model="dateValue" :label="`${translate('OPEN_BANKING.ALERTS.DATE')} :`"/>
        <Select v-model:value="categoryValue" :title="`${translate('OPEN_BANKING.CATEGORY')} :`"
                :options="category" :values="category" :empty-option="true"/>
        <TextInput v-model="balanceValue" :label="`${translate('OPEN_BANKING.ACCOUNT_STATEMENT.AMOUNT')} :`"/>
        <TextInput v-model="labelValue" :label="`${translate('OPEN_BANKING.LABEL')} :`"/>
      </div>
      <div class="manage-users__search__action">
        <Button :label="translate('BUTTONS.RESET')" type="reset" @click="handleReset"/>
        <Button :label="translate('BUTTONS.SEARCH')" type="submit"/>
      </div>
    </form>
  </div>
</template>