<template>
  <table class="column-table">
    <tbody>
    <tr v-for="(i) in Math.ceil(datas.length / cols)">
      <template v-for="(j) in cols">
        <template v-if="(((i-1) * cols) + j - 1) < datas.length">
          <th :title="this.datas[(((i-1) * cols) + j - 1)].title">{{ this.datas[(((i - 1) * cols) + j - 1)].name }}</th>
          <td>{{ this.datas[(((i - 1) * cols) + j - 1)].value }}</td>
        </template>
      </template>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ColumnTable",
  props: {
    cols: {default: 2, type: Number},
    // rows: {default: 0},
    datas: {default: {}}
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.column-table {
  border: solid 0 $dataPrinterBackgroundColor;
  width: 100%;
  font-size: $fontSize;
  background-color: $dataPrinterBackgroundColor;
  color: $dataPrinterFontColor;
  border-radius: 15px;
  padding: 0.5vh 0.5vw;
  margin-top: 1rem;
  font-family: $fontFamily;

  td {
    min-width: 25px;
  }

  td, th {
    border: solid 1px $cardBackgroundColor;
    text-align: center;
  }

  th {
    font-family: $fontFamilyBold;
  }
}
</style>