<template>
  <div class="monitoring__my-usage">
    <MonitoringCharts :total-label-usage="totalLabelUsage" :total-usage="totalUsage"
                      :total-value-usage="totalValueUsage"
                      :color-usage="generateRandomColorsArray(totalValueUsage.length)" consumption-to-display="MY_CONSUMPTION"
                      :is-monitoring-data-loading="isMonitoringDataLoading"/>
    />
    <MonitoringValues :usage-without-all="usageWithoutAll" :scores-usages="scoresUsages"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import monitoringMixin from "@/mixins/monitoringMixin";
import MonitoringCharts from "@/components/monitoring/MonitoringCharts.vue";
import MonitoringValues from "@/components/monitoring/MonitoringValues.vue";

export default defineComponent({
  components: {MonitoringValues, MonitoringCharts},
  mixins: [monitoringMixin]
});
</script>