<script setup lang="ts">
import CustomModal from "@/components/modal/CustomModal.vue";
import {computed, ref, watch} from "vue";
import ResultCard from "@/components/card/ResultCard.vue"
import Button from "@/components/button/Button.vue"
import connectAsApi from "@/apiCalls/connectAsApi"
import Select from "@/components/select/Select.vue";
import {useStore} from "vuex";
import {useToast} from "vue-toast-notification";
import {translate} from "@/i18n";
import {useRouter} from "vue-router";
import {isObject} from "lodash";

const openModal = defineModel('openModal', {default: false, type: Boolean});

const isValidate = ref(false);
const allSelected = ref(false);
const reset = ref(false);

const companies = ref([]);
const companySelected = ref('');
const companySelectedId = ref('');

const userAvailable = ref([]);
const userSelected = ref('');
const userSelectedId = ref('');

const store = useStore();
const toast = useToast();
const router = useRouter();

const showConfirmation = () => {
  isValidate.value = true;
};

const clearSelection = () => {
  companySelected.value = '';
  companySelectedId.value = '';
  userSelected.value = '';
  userSelectedId.value = '';
  reset.value = true;
  isValidate.value = false;
  allSelected.value = false;
};

const comeBack = async () => {
  clearSelection();
  await loadCompanies();
};

const emit = defineEmits(['close-modal', 'confirm-selection']);

const loadCompanies = async () => {
  if (openModal.value) {
    companies.value = await connectAsApi.listCompanies(true, true);
  }
};

const updateCompanySelected = async () => {
  const selectedCompany = companies.value?.find(company => company.id === parseInt(companySelectedId.value));
  if (isObject(selectedCompany)) {
    companySelectedId.value = selectedCompany.id;
    companySelected.value = selectedCompany.name;
    await loadAvailableUsers();
  }
};

const loadAvailableUsers = async () => {
  userAvailable.value = await connectAsApi.listUsers(companySelectedId.value, true);
};

const updateUserSelected = async () => {
  const selectedUser = userAvailable.value.find(user => user.id === parseInt(userSelectedId.value));
  if (selectedUser) {
    userSelectedId.value = selectedUser.id;
    userSelected.value = selectedUser.username;
    reset.value = false;
  }
};

const companyOptions = computed(() => {
  return companies.value?.map(company => company.name);
});

const companyValues = computed(() => {
  return companies.value?.map(company => company.id);
});

const usersOptions = computed(() => {
  return userAvailable.value?.map(user => user?.username)
});

const usersValues = computed(() => {
  return userAvailable.value?.map(user => user?.id)
});

const confirmSelection = async () => {
  allSelected.value = false;
  await connectAsApi.goConnectAs(userSelectedId.value);
  await store.dispatch('fetchUser');
  isValidate.value = false;
  toast.info(`${translate('CONNECT_AS.TOAST.LOG')} ${userSelected.value} `);
  emit('confirm-selection', {company: companySelected.value, user: userSelected.value});
  await router.push('/home');
};

watch(openModal, async (newValue, oldValue) => {
  if (newValue && !oldValue) {
    await loadCompanies();
  } else if (!newValue && oldValue) {
    clearSelection();
  }
})

watch([userSelected, companySelected], () => {
  allSelected.value = userSelected.value !== '' && companySelected.value !== '';
});

</script>
<template>
  <CustomModal v-model:open="openModal" :display-close-button="true">
    <ResultCard v-if="!isValidate" :title="`${translate('CONNECT_AS.TITLE')}...`" class="card-connect-as">
      <div class="card-connect-as-container">
        <Select v-model:value="companySelectedId"
                :options="companyOptions"
                :values="companyValues"
                :title="`${translate('CONNECT_AS.MODAL.COMPANY')} :`"
                :empty-option="true"
                :reset-value="reset"
                @change="updateCompanySelected"
                class="card-connect-as-select"/>
        <Select v-model:value="userSelectedId"
                :options="usersOptions"
                :values="usersValues"
                :title="`${translate('CONNECT_AS.MODAL.USER')} :`"
                :empty-option="true"
                :reset-value="reset"
                @change="updateUserSelected"
                class="card-connect-as-select"/>
        <div class="card-connect-as-action">
          <Button :label="translate('BUTTONS.VALIDATE')" :disabled="!allSelected" @click="showConfirmation"
                  class="card-connect-as-action-button validate"/>
        </div>
      </div>
    </ResultCard>
    <ResultCard v-else :title="`${translate('CONNECT_AS.TITLE')}...`" class="card-connect-as">
      <div class="card-connect-as-container">
                <span class="card-connect-as-container-text">{{ translate('CONNECT_AS.PARAGRAPH.FIRST') }}
                    <span class="card-connect-as-container-text-bold">{{ userSelected }}</span>
                    {{ translate('CONNECT_AS.PARAGRAPH.SECOND') }}
                    <span class="card-connect-as-container-text-bold">{{ companySelected }} </span>
                </span>
        <span>{{ translate('CONNECT_AS.PARAGRAPH.THIRD') }}</span>
        <span class="card-connect-as-container-text-underline">{{ translate('CONNECT_AS.PARAGRAPH.FOURTH') }}
                </span>
        <span class="card-connect-as-container-text">{{ translate('CONNECT_AS.PARAGRAPH.FIFTH') }}
                    <span class="card-connect-as-container-text-bold">{{
                        translate('CONNECT_AS.PARAGRAPH.SIXTH')
                      }}</span>
                    {{ translate('CONNECT_AS.PARAGRAPH.SEVENTH') }}
                    <span class="card-connect-as-container-text-bold">{{
                        translate('CONNECT_AS.PARAGRAPH.EIGHTH')
                      }}</span>
                    {{ translate('CONNECT_AS.PARAGRAPH.NINTH') }}
                </span>
        <span class="card-connect-as-container-text">{{ translate('CONNECT_AS.PARAGRAPH.TENTH') }}
                        <span class="card-connect-as-container-text-bold"> {{
                            translate('CONNECT_AS.PARAGRAPH.ELEVENTH')
                          }}</span>
                        {{ translate('CONNECT_AS.PARAGRAPH.TWELFTH') }}
                </span>
        <div class="card-connect-as-action">
          <Button :label="translate('BUTTONS.RETURN')" @click="comeBack" class="card-connect-as-action-button"/>
          <Button :label="translate('BUTTONS.CONFIRM')" @click="confirmSelection"
                  class="card-connect-as-action-button validate"/>
        </div>
      </div>
    </ResultCard>
  </CustomModal>
</template>