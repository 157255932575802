<template>
  <section class="summary">
    <header class="header">
      <h2 class="summary__title">Journey Id : {{ data.journeyId }} </h2>
      <h3>{{ translate('USER') }} : {{ this.$cookies.get('user').username }}</h3>
    </header>
    <content-card v-if="market_type === 'b2b'" :title="translate('COMPANY.IDENTITY')">
      <div class="part-defs identity-company">
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.SIREN_NUMBER')" :value="data.company_identity.siren"/>
        <label-value-printer class="identity-company-label" :label="translate('IDENTITY.NAME')" :value="data.company_identity.name"/>
        <label-value-printer class="identity-company-label" :label="translate('ADDRESS.TITLE')" :value="data.company_identity.address"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.LEGAL_STATUS')" :value="data.company_identity.status"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.CREATED_DATE')" :value="data.company_identity.registration_date"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.CAPITAL')" :value="data.company_identity.capital"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.NUMBER_ESTABLISHMENTS')" :value="data.company_identity.nb_establishments"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.MOTHBALLED')" :value="dataCtrl.getMothballed(data)"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.CURRENT_PROCEDURES')" :value="dataCtrl.getCurrentProcedures(data)"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.GLOBAL_NOTE_E_REPUTATION')" :value="data.e_reputation.global_rank + ' / 5 ⭐️'"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.ADVICE_NOTE_E_REPUTATION')" :value="data.e_reputation.global_reviews_nb"/>
        <label-value-printer class="identity-company-label" :label="translate('COMPANY.WEBSITE')" :value="data.company_identity.website"/>
      </div>
      <div v-if="data.company_identity.business_owners.length > 0" class="business_owners">
        <b-row>
          <span class="business_owners-title">{{ translate('COMPANY.BUSINESS_OWNERS_LIST') }}</span>
        </b-row>
        <b-row>
          <ul class="business_owners-list">
            <li v-for="business_owner in data.company_identity.business_owners">{{ `${business_owner.lastName} ${business_owner.firstNames}` }}</li>
          </ul>
        </b-row>
      </div>
      <div v-if="data.company_identity.representatives.length > 0" class="business_owners">
        <b-row>
          <span class="business_owners-title">{{ translate('COMPANY.REPRESENTATIVES_LIST') }}</span>
        </b-row>
        <b-row>
          <ul class="business_owners-list">
            <template v-for="representative in data.company_identity.representatives">
              <li v-if="representative.type === 'Natural Person'">{{ `${representative.position} : ${representative.firstNames} ${representative.lastName} (${representative.type})` }}</li>
              <li v-if="representative.type === 'Legal Person'">{{ `${representative.position} : ${representative.corporateName} (${representative.type})` }}</li>
            </template>
          </ul>
        </b-row>
      </div>
    </content-card>
    <content-card v-if="market_type === 'b2b'" :title="translate('FINANCIAL_ANALYSIS')" class="html2pdf__page-break">
      <b-row>
        <b-col cols="4">
          <label-value-printer :label="translate('TAX_REPORTS.FISCAL_PERIOD_DURATION')" :value="data.tax_report.fiscal_period_duration + ' ' + translate('MONTH')"/>
          <label-value-printer :label="translate('TAX_REPORTS.CLOSURE_DATE')" :value="data.tax_report.closureDate"/>
          <label-value-printer :label="translate('COMPANY.RECOMMENDED_OUTSTANDING')" :value="data.company_identity.recommended_outstanding + ' €'"/>
        </b-col>
        <b-col>
          <div class="financial-analysis">
            <score-circle-chart
                class="circle-chart financial-analysis-score"
                :circle-value="data.company_identity.risk_score"
                :circle-width="'80%'"
                :circle-title="translate('SCORE.COMPANY_TITLE')"
            />
            <score-circle-chart
                class="circle-chart financial-analysis-score"
                :circle-value="data.company_identity.sector_score"
                :circle-title="translate('SCORE.SECTOR_TITLE')"
                :circle-width="'80%'"
            />
            <score-circle-chart
                class="circle-chart financial-analysis-score"
                :circle-value="data.company_identity.default_probability"
                :circle-width="'80%'"
                :circle-title="translate('SCORE.DEFAULT_TITLE')"
                type-score="default_probability"
            />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="data.ratios_array">
        <column-table v-if="data.ratios_array.length > 0" :datas="data.ratios_array" :cols="2"/>
      </b-row>
    </content-card>
    <content-card v-if="configuration.api_calls.fraud_score_v2_b2c || configuration.api_calls.fraud_score_v2_b2c_without_e_reputation_b2c || configuration.api_calls.fraud_score_v3_b2c
                || configuration.api_calls.fraud_score_v2_b2b || configuration.api_calls.fraud_score_v2_b2c_without_e_reputation_b2b || configuration.api_calls.fraud_score_v3_b2b"
                  :title="translate('APPLICANT')">
      <b-row>
        <b-col cols="9">
          <b-row class="applicant-information">
            <b-col>
              <label-value-printer :label="translate('IDENTITY.NAME')" :value="data.customer_identity.lastName"/>
            </b-col>
            <b-col>
              <label-value-printer :label="translate('IDENTITY.FIRSTNAME')" :value="data.customer_identity.firstName"/>
            </b-col>
          </b-row>
          <b-row class="info_lcbft">
            <label-value-printer v-if="market_type === 'b2b'" :label="translate('LINK_COMPANY')" :value="data.customer_identity.company_link_type + ' ' + data.customer_identity.company_link_result"/>
            <label-value-printer v-if="data.customer_identity.birthDate !== ''" :label="translate('AMLCFT.TITLE')" :value="booleanToString(data.customer_identity.LCBFT)"/>
            <label-value-printer v-if="data.identity_check_result.global_decision !== ''" :label="translate('IDENTITY_CONSISTENCY')" :value="data.identity_check_result.global_decision"/>
          </b-row>
        </b-col>
        <b-col align-self="stretch">
          <score-circle-chart
              class="circle-chart"
              :circle-value="data.customer_identity.fraud_score"
              :circle-width="'80%'"
              :circle-title="translate('SCORE.WEB_TITLE')"
          />
        </b-col>
      </b-row>
      <b-row>
        <table-verification
            :email-data="data.customer_identity.tableEmail"
            :phone-data="data.customer_identity.tablePhone"
            :address-data="data.customer_identity.tableAddress"
            :email-input="data.customer_identity.email"
            :phone-input="data.customer_identity.phone"
            :address-input="data.customer_identity.address"
            :headers-verification="data.customer_identity.tableVerification"
            :headers-lie="data.customer_identity.tableLie"
            :email-disposable="data.customer_identity.email_disposable"
        ></table-verification>
      </b-row>
      <b-row>
        <b-col>
          <label-value-printer :label="translate('SOCIAL_NETWORKS.MAIL_LINKED')" :value="data.customer_identity.email_nb_network"/>
          <ul class="social_networks">
            <template v-for="network in data.customer_identity.email_network">
              <li>{{network.name}}</li>
              <ol class="social_networks-list">
                <li v-if="network.id"><span>Id : </span><span>{{ network.id }}</span></li>
                <li v-if="network.url"><span>Url : </span><span>{{ network.url }}</span></li>
                <li v-if="network.age"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.AGE') }} : </span><span>{{ network.age }}</span></li>
                <li v-if="network.city"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.CITY') }} : </span><span>{{ network.city }}</span></li>
                <li v-if="network.pseudo"><span>Pseudo : </span><span>{{ network.pseudo }}</span></li>
                <li v-if="network.state"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.STATE') }} : </span><span>{{ network.state }}</span></li>
                <li v-if="network.gender"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.GENDER') }} : </span><span>{{ network.gender}}</span></li>
                <li v-if="network.country"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.COUNTRY') }} : </span><span>{{ network.country }}</span></li>
                <li v-if="network.language"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.LANGUAGE') }} : </span><span>{{ network.language }}</span></li>
              </ol>
            </template>
          </ul>
        </b-col>
        <b-col>
          <label-value-printer :label="translate('SOCIAL_NETWORKS.PHONE_LINKED')" :value="data.customer_identity.phone_nb_network"/>
          <ul class="social_networks">
            <template v-for="network in data.customer_identity.phone_network">
              <li>{{network.name}}</li>
              <ol class="social_networks-list">
                <li v-if="network.id"><span>Id : </span><span>{{ network.id }}</span></li>
                <li v-if="network.url"><span>Url : </span><span>{{ network.url }}</span></li>
                <li v-if="network.age"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.AGE') }} : </span><span>{{ network.age }}</span></li>
                <li v-if="network.city"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.CITY') }} : </span><span>{{ network.city }}</span></li>
                <li v-if="network.pseudo"><span>Pseudo : </span><span>{{ network.pseudo }}</span></li>
                <li v-if="network.state"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.STATE') }} : </span><span>{{ network.state }}</span></li>
                <li v-if="network.gender"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.GENDER') }} : </span><span>{{ network.gender}}</span></li>
                <li v-if="network.country"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.COUNTRY') }} : </span><span>{{ network.country }}</span></li>
                <li v-if="network.language"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.LANGUAGE') }} : </span><span>{{ network.language }}</span></li>
              </ol>
            </template>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="telephone-details telephone-block">
            <verification-details
                :phone_linetype="data.customer_identity.line_type"
                :phone_carrier="data.customer_identity.open_banking"
                :phone_original_carrier="data.customer_identity.original_operator"
                :phone_number="data.customer_identity.phone"
                :phone_geolocation="data.customer_identity.geo_localisation"
                :phone_owner="data.customer_identity.owner"
                :phone_timezone="data.customer_identity.region"
                :phone_exists="data.customer_identity.phone_exists"
                :label_result="translate('VERIFIED')"
                :type_result="translate('PHONE')"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col><label-value-printer :label="translate('FIRST_SEEN_DATE') + ' ' + translate('IDENTITY.EMAIL')" :value="data.customer_identity.email_first_seen_date"/></b-col>
        <b-col><label-value-printer :label="translate('FIRST_SEEN_DATE') + ' ' + translate('IDENTITY.PHONE_NUMBER')" :value="data.customer_identity.phone_first_seen_date"/></b-col>
      </b-row>
      <b-row v-if="data.customer_identity.birthDate !== ''">
        <b-col>
          <label-value-printer :label="translate('AMLCFT.FREEZING_ASSETS')" :value="data.customer_identity.sanction"/>
          <label-value-printer :label="translate('AMLCFT.PPE')" :value="data.customer_identity.pep"/>
        </b-col>
        <b-col>
          <label-value-printer :label="translate('AMLCFT.ADVERSE_MEDIA')" :value="data.customer_identity.adverse_media"/>
        </b-col>
      </b-row>
    </content-card>
    <content-card v-if="data.open_banking.bank_name" :title="translate('OPEN_BANKING.TITLE')" class="html2pdf__page-break">
      <b-row>
        <b-col cols="9">
          <b-row>
            <b-col>
              <label-value-printer :label="translate('OPEN_BANKING.BANK_NAME')" :value="data.open_banking.bank_name"/>
              <label-value-printer :label="translate('OPEN_BANKING.HOLDER')" :value="data.open_banking.holder"/>
              <label-value-printer :label="translate('OPEN_BANKING.ACCOUNT_NAME')" :value="data.open_banking.account_name"/>
              <label-value-printer :label="translate('OPEN_BANKING.MONTH_SALARIES')" :value="data.open_banking.month_salaries"/>
            </b-col>
            <b-col>
              <label-value-printer :label="translate('OPEN_BANKING.MONTH_RENTS')" :value="data.open_banking.month_rents"/>
              <label-value-printer :label="translate('OPEN_BANKING.MONTH_END_BALANCE')" :value="data.open_banking.month_end_balance"/>
              <label-value-printer :label="translate('OPEN_BANKING.REMAINS_TO_LIVE')" :value="data.open_banking.remains_to_live"/>
              <label-value-printer :label="translate('OPEN_BANKING.BALANCE')" :value="data.open_banking.balance"/>
              <label-value-printer :label="translate('OPEN_BANKING.TOP_BANKING_SUPERVISION')" :value="data.open_banking.top_banking_supervision"/>
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <score-circle-chart
              class="circle-chart"
              :circle-value="data.open_banking.score"
              :circle-width="'80%'"
          />
        </b-col>
      </b-row>
    </content-card>
    <content-card v-if="data.open_banking.bank_name" :title="translate('OPEN_BANKING.TITLE')">
      <b-row>
        <o-b-table :data="data.open_banking.details"/>
      </b-row>
    </content-card>
  </section>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import TableVerification from "@/components/TableVerification.vue";
import VerificationDetails from "@/components/VerificationDetails.vue";
import ColumnTable from "@/components/ColumnTable.vue";
import OBTable from "@/components/OBTable.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import ScoreCircleChart from "@/components/ScoreCircleChart.vue";
import {BIconEnvelopeFill} from "bootstrap-icons-vue";
import LittleBlockModal from "@/components/LittleBlockModal.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import {BCardImg, BCol, BRow} from "bootstrap-vue-3";
import {translate} from "@/i18n";


export default defineComponent({
  name: 'ResultsPdfExportContent',
  components: {
    BCardImg,
    BCol,
    BRow,
    ContentCard,
    ScoreCircleChart,
    OBTable, ColumnTable, TableVerification, VerificationDetails, LabelValuePrinter, BIconEnvelopeFill, LittleBlockModal},
  props: {
    data: {},
    dataCtrl: {},
    user: {type:String, default: ''},
    market_type: {type: String},
    configuration: {type: Object}
  },
  methods: {
    translate,
    booleanToString(boolean: boolean) {
      if (!boolean) return '🟢';
      else return  '🔴';
    },
  }
});

</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

.summary {
  font-family: 'arial';
  width: 100%;
  background: #fff;
  padding: 20px;
  &__title {
    font-size: 1.4rem;
  }
}

.part-defs {
  display: grid;
  grid-template-columns: 50% 50%;
}

.financial-analysis {
  display: flex;
  margin-left: 5rem;

  &-score {
    padding-right: 2rem;
  }
}

.business_owners {
  font-size: $fontSize;
  color: $headerColor;
  background-color: $pureWhite;
  padding: .8rem;
  border-radius: 12px;
  margin-top: .8rem;

  &-title {
    font-weight: bold;
  }

  &-list {
    list-style: none;
    margin-left: 1rem;
  }
}

.identity-company {
  &-label {
    margin-right: 1rem;
  }
}

.applicant-information {
  display: flex;
}

.info_lcbft {
  padding: 0rem 1rem;
}

.social_networks {
  font-size: $fontSize;
  color: $headerColor;

  &-list {
    list-style-type: none;
  }
}

.telephone-details {
  margin: auto;
}

.telephone-block {
  background-color: $pureWhite;
  border-radius: 12px;
  padding: .8rem;
}
</style>
