<template>
  <div>
    <b-modal :id="idModal" :title="titleModal" :hide-footer="true" size="xl">
      <slot/>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {BButton, BModal} from 'bootstrap-vue-3';
import ButtonMeelo from '@/components/button/Button.vue';

export default defineComponent({
  name: 'Modal',
  components: {ButtonMeelo, BModal, BButton},
  props: {
    idModal: {type: String, default: ''},
    titleModal: {type: String, default: ''}
  },
  emits: ['onHide']
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.modal-button {
  z-index: 100;
}
</style>
