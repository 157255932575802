<script setup lang="ts">

import {translate} from "@/i18n";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";

const openModal = defineModel('openModal', {default: false, type: Boolean});

const checkEnvironment = () => {
  const domain = window.location.host;
  if (domain.includes("127.0.0.1") || domain.includes("localhost") ||
      domain.startsWith("10.") || domain.substring(0, 5).match("172.(1[6-9]|2[0-9]|3[0-1])") ||
      domain.startsWith("192.168") || domain.startsWith("dev")) {
    return translate('PORTAL_INFORMATION.DEVELOPMENT');
  } else if (domain.startsWith("staging") || domain.startsWith("stg")) {
    return translate('PORTAL_INFORMATION.STAGING');
  } else {
    return translate('PORTAL_INFORMATION.PRODUCTION');
  }
};

const checkVersion = () => {
  const file = require('../../../package.json');
  return `${file["version"]}`;
};
</script>

<template>
  <CustomModal v-model:open="openModal" :display-close-button="true">
    <ResultCard class="custom-modal-portal-information" :title="translate('PORTAL_INFORMATION.TITLE')"
                :display-arrows="false">

      <div
          class="custom-modal-portal-information-container  custom-modal-portal-information-center custom-modal-portal-information-red-text">
                <span class="custom-modal-portal-information-red-text">
                  {{ `${translate('PORTAL_INFORMATION.SUBTITLE.FIRST')}` }}
                </span>
        <span class="custom-modal-portal-information-blue-text"> {{
            translate('PORTAL_INFORMATION.SUBTITLE.SECOND')
          }} </span>
      </div>

      <span class="custom-modal-portal-information-container"> {{
          translate('PORTAL_INFORMATION.DESCRIPTION.FIRST')
        }}
      </span>
      <span class="custom-modal-portal-information-container"> {{
          translate('PORTAL_INFORMATION.DESCRIPTION.SECOND')
        }}
      </span>
      <span class="custom-modal-portal-information-container"> {{
          translate('PORTAL_INFORMATION.DESCRIPTION.THIRD')
        }}
      </span>

      <div class="custom-modal-portal-information-info custom-modal-portal-information-container">
        <img class="custom-modal-portal-information-img_logo-meelo"
             src="../../../public/images/logo_vertical_meelo_blue.png"
             alt="Logo Meelo">
        <div>
          <span class="custom-modal-portal-information-blue-text custom-modal-portal-information-center">{{
              translate('PORTAL_INFORMATION.VERSION')
            }}
          </span>
          <span class="custom-modal-portal-information-grey custom-modal-portal-information-center">
            {{ checkVersion() }}
          </span>
          <span class="custom-modal-portal-information-blue-text custom-modal-portal-information-center">{{
              translate('PORTAL_INFORMATION.ENVIRONMENT')
            }}
          </span>
          <span class="custom-modal-portal-information-grey custom-modal-portal-information-center">
            {{ checkEnvironment() }}
          </span>
        </div>
        <a href="https://www.getmeelo.com/meelo-fintech-certifiee-b-corp/" target="_blank">
          <img class="custom-modal-portal-information-img_logo-bcorp" src="../../../public/images/bcorpLogo.png"
               alt="Logo BCorp">
        </a>
      </div>
      <hr>
      <div class="custom-modal-portal-information-container">
                <span class="custom-modal-portal-information-blue-text"> {{
                    translate('PORTAL_INFORMATION.GDPR.TITLE')
                  }} </span>
        <span class="custom-modal-portal-information-container">
          {{ translate('PORTAL_INFORMATION.GDPR.FIRST') }}
        </span>

        <span class="custom-modal-portal-information-container">
          {{ translate('PORTAL_INFORMATION.GDPR.SECOND') }}
          <a class="custom-modal-portal-information-underline"
             href="https://www.getmeelo.com/politique-de-confidentialite/">
            {{ translate('PORTAL_INFORMATION.LINK') }} </a>
          {{ translate('PORTAL_INFORMATION.GDPR.THIRD') }} <span class="custom-modal-portal-information-underline"><a
            href="mailto:dpo@getmeelo.com">dpo@getmeelo.com</a></span>.
        </span>
      </div>

    </ResultCard>
  </CustomModal>
</template>