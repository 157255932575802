import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5dd77f6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "recovery-result"
}
const _hoisted_2 = {
  key: 0,
  class: "recovery-request-information"
}
const _hoisted_3 = { class: "recovery-request-information-first-block" }
const _hoisted_4 = { class: "recovery-request-information-first-block-name" }
const _hoisted_5 = { class: "recovery-request-synthesis" }
const _hoisted_6 = {
  key: 0,
  class: "recovery-request-information-second-block"
}
const _hoisted_7 = { class: "recovery-request-information-second-block-verification" }
const _hoisted_8 = { class: "recovery-request-information-second-block-verification" }
const _hoisted_9 = {
  key: 0,
  class: "recovery-request-links"
}
const _hoisted_10 = {
  key: 0,
  class: "openbanking-bloc-link"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 2,
  class: "recovery-request-date"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["src", "alt"]
const _hoisted_15 = { class: "recovery-financial-pressure-content" }
const _hoisted_16 = { class: "recovery-financial-pressure-content-subtitle" }
const _hoisted_17 = { class: "recovery-financial-pressure-content" }
const _hoisted_18 = { class: "recovery-financial-pressure-content-subtitle" }
const _hoisted_19 = { class: "recovery-customer-reliability-content" }
const _hoisted_20 = { class: "recovery-customer-reliability-content-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxInputCustomerInfo = _resolveComponent("BoxInputCustomerInfo")!
  const _component_XMasBonhomme = _resolveComponent("XMasBonhomme")!
  const _component_ResultsPdfExport = _resolveComponent("ResultsPdfExport")!
  const _component_Button = _resolveComponent("Button")!
  const _component_VerificationResult = _resolveComponent("VerificationResult")!
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_ScoreCircleChart = _resolveComponent("ScoreCircleChart")!
  const _component_LineChart = _resolveComponent("LineChart")!
  const _component_BarChart = _resolveComponent("BarChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isNotCompleted)
      ? (_openBlock(), _createBlock(_component_BoxInputCustomerInfo, {
          key: 0,
          propPrefillClientForm: _ctx.prefillClientForm,
          onCustomerInformation: _ctx.customerInformation
        }, null, 8, ["propPrefillClientForm", "onCustomerInformation"]))
      : _createCommentVNode("", true),
    (!_ctx.isNotCompleted || _ctx.applicantData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_ResultCard, {
              title: _ctx.translate('RECOVERY.REQUEST'),
              class: "recovery-request"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_XMasBonhomme, {
                  style: {top: '14vh', left: '34vw'},
                  value: 6
                }),
                (_ctx.applicantData)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('IDENTITY.NAME')} ${_ctx.translate('IDENTITY.FIRSTNAME')} : ${_ctx.applicantData.lastname} ${_ctx.applicantData.firstname}`), 1),
                          _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.CLIENT_ID')} : ${_ctx.externalId}`), 1)
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          (Object.keys(_ctx.obFormattedFields).length>0)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createVNode(_component_ResultsPdfExport, { ref: "exportPdf" }, null, 512),
                                _createVNode(_component_Button, {
                                  label: _ctx.translate('BUTTONS.EXPORT_PDF'),
                                  onClick: _ctx.generateReport
                                }, null, 8, ["label", "onClick"])
                              ], 64))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      ((_ctx.causesFraudData != null && _ctx.applicantData))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('IDENTITY.EMAIL')} : ${_ctx.applicantData.email}`), 1),
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_VerificationResult, {
                                value: _ctx.causesFraudData.contact.email_exists,
                                label: _ctx.translate('VERIFIED'),
                                tooltip: _ctx.translate('TOOLTIP.EMAIL_VERIFICATION')
                              }, null, 8, ["value", "label", "tooltip"]),
                              _createVNode(_component_VerificationResult, {
                                value: _ctx.causesFraudData.contact.email_name,
                                label: _ctx.translate('LINKED'),
                                tooltip: _ctx.translate('TOOLTIP.EMAIL_LINK'),
                                "is-warning": true
                              }, null, 8, ["value", "label", "tooltip"])
                            ]),
                            _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('IDENTITY.PHONE_NUMBER')} : ${_ctx.applicantData.mobilePhone}`), 1),
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_VerificationResult, {
                                value: _ctx.causesFraudData.contact.phone_exists,
                                label: _ctx.translate('VERIFIED'),
                                tooltip: _ctx.translate('TOOLTIP.MOBILE_PHONE_VERIFICATION')
                              }, null, 8, ["value", "label", "tooltip"]),
                              _createVNode(_component_VerificationResult, {
                                value: _ctx.causesFraudData.contact.phone_name,
                                label: _ctx.translate('LINKED'),
                                tooltip: _ctx.translate('TOOLTIP.MOBILE_PHONE_LINK'),
                                "is-warning": true
                              }, null, 8, ["value", "label", "tooltip"])
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]),
            (Object.keys(_ctx.obFormattedFields).length>0)
              ? (_openBlock(), _createBlock(_component_ResultCard, {
                  key: 0,
                  title: _ctx.translate('OPEN_BANKING.RECOMMENDATIONS'),
                  class: "recovery-recommendations"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_DATE')} : ${_ctx.obFormattedFields.recommendedDebitDate}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_AMOUNT')} : ${_ctx.obFormattedFields.recommendedDebitAmount}`), 1)
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            (Object.keys(_ctx.obFormattedFields).length>0)
              ? (_openBlock(), _createBlock(_component_ResultCard, {
                  key: 1,
                  title: _ctx.translate('RECOVERY.FINANCIAL_SITUATION'),
                  class: "recovery-financial-situation"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.BALANCE')} : ${_ctx.obFormattedFields.balance}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.AVERAGE_DISPOSABLE_INCOME')} : ${_ctx.obFormattedFields.remainsToLive}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.AVERAGE_MONTHLY_INCOME')} : ${_ctx.obFormattedFields.monthIncomesAmountMean}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.LOWEST_INCOME')} : ${_ctx.obFormattedFields.monthIncomesAmountMin}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.LACK_OF_INCOME')} : ${_ctx.obFormattedFields?.monthIncomesAmountMean?.length > 0 ? _ctx.translate('RECOVERY.LACK_OF_INCOME_SOURCES_DETECTED') : _ctx.translate('RECOVERY.LACK_OF_INCOME_SOURCES_NOT_DETECTED')}`), 1)
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_ResultCard, {
              title: _ctx.translate('RECOVERY.FINANCIAL_ANALYSIS'),
              class: "recovery-financial-analysis"
            }, {
              default: _withCtx(() => [
                (Object.keys(_ctx.obFormattedFields).length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_Button, {
                        label: _ctx.translate('BUTTONS.LINK_TEXT_MESSAGE'),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.generateLink(_ctx.prefillClientForm.phone, ''))),
                        disabled: Object.keys(_ctx.obFormattedFields).length>0
                      }, null, 8, ["label", "disabled"]),
                      _createVNode(_component_Button, {
                        label: _ctx.translate('BUTTONS.LINK_EMAIL'),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.generateLink({disabled: false, value: '+33'}, _ctx.prefillClientForm.email))),
                        disabled: Object.keys(_ctx.obFormattedFields).length>0
                      }, null, 8, ["label", "disabled"]),
                      (_ctx.shouldShowButton)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            label: _ctx.translate('BUTTONS.LINK_BROWSER'),
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.generateLink(null, null))),
                            disabled: Object.keys(_ctx.obFormattedFields).length>0
                          }, null, 8, ["label", "disabled"]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.shouldShowButton && _ctx.openBankingLink != '')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.obStarted && !_ctx.obEnded)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("a", {
                              href: _ctx.openBankingLink,
                              target: "_blank"
                            }, [
                              _createVNode(_component_Button, {
                                class: "openbanking-bloc-link-button",
                                onClick: _ctx.goOpenBanking,
                                label: _ctx.translate('BUTTONS.JOURNEY_OPEN_BANKING')
                              }, null, 8, ["onClick", "label"])
                            ], 8, _hoisted_11)
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (Object.keys(_ctx.obFormattedFields).length>0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.ANALYSIS_DATE')} : ${new Date().toLocaleDateString()}`), 1),
                      (Object.keys(_ctx.obFormattedFields).length>0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(`${_ctx.translate('OPEN_BANKING.ANALYSIS_PERIOD')} ${_ctx.translate('FROM')} ${_ctx.obFormattedFields.startStudyPeriod} ${_ctx.translate('OPEN_BANKING.TO')} ${_ctx.obFormattedFields.endStudyPeriod}`), 1))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", null, [
                        _createTextVNode(_toDisplayString(`${_ctx.translate('OPEN_BANKING.BANK_NAME')} : ${_ctx.obFormattedFields.bankName} (${_ctx.translate('RECOVERY.RISK_LEVEL')} `) + " ", 1),
                        _createElementVNode("img", {
                          src: require(`/public/images/${_ctx.displayTopBankRiskLevel(_ctx.obFormattedFields.topBankRiskLevel)}`),
                          alt: _ctx.obFormattedFields.topBankRiskLevel,
                          class: "icon"
                        }, null, 8, _hoisted_14),
                        _createElementVNode("span", null, _toDisplayString(` ${_ctx.displayTopBankRiskDescription(_ctx.obFormattedFields.topBankRiskLevel)})`), 1)
                      ]),
                      _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.HOLDER')} : ${_ctx.obFormattedFields.holderName}`), 1),
                      _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.MAIN_ACCOUNT')} : ${_ctx.obFormattedFields.topSecondaryAccount}`), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]),
            (Object.keys(_ctx.obFormattedFields).length>0)
              ? (_openBlock(), _createBlock(_component_ResultCard, {
                  key: 0,
                  title: _ctx.translate('RECOVERY.FINANCIAL_PRESSURE'),
                  class: "recovery-financial-pressure"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", _hoisted_16, _toDisplayString(`${_ctx.translate('RECOVERY.CREDITS')} :`), 1),
                      _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.DEBT_RATIO')} : ${_ctx.obFormattedFields.debtRatio}`), 1),
                      _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.MONTH_OUTCOMES_CREDITS_COUNT_MEAN')} : ${_ctx.obFormattedFields.monthOutcomesCreditsCountMean}`), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("span", _hoisted_18, _toDisplayString(`${_ctx.translate('RECOVERY.DEBT_COLLECTION_AGENCIES')} :`), 1),
                      _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.ALERTS.AMOUNT')} : ${_ctx.obFormattedFields.monthOutcomesCashCollectionsAmountMean}`), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            (Object.keys(_ctx.obFormattedFields).length>0)
              ? (_openBlock(), _createBlock(_component_ResultCard, {
                  key: 1,
                  title: _ctx.translate('RECOVERY.CUSTOMER_RELIABILITY'),
                  class: "recovery-customer-reliability"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_ScoreCircleChart, {
                        class: "circle-chart score-open-banking-recovery",
                        "circle-value": Math.round(_ctx.obFormattedFields.score),
                        "circle-width": '40%',
                        tooltip: _ctx.translate('TOOLTIP.SCORE_OPEN_BANKING'),
                        "circle-color": _ctx.colorScore
                      }, null, 8, ["circle-value", "tooltip", "circle-color"]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('OPEN_BANKING.TOP_BANKING_SUPERVISION')} : ${_ctx.obFormattedFields.topBankingSupervision}`), 1),
                        _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.REJECTION')} : ${_ctx.obFormattedFields.monthOutcomesRejects}`), 1),
                        _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.INTERVENTION_FEES')} : ${_ctx.obFormattedFields.monthOutcomesInterventionFees}`), 1),
                        _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.THIRD_PARTY_NOTICE')} : ${_ctx.obFormattedFields.monthOutcomesSeizureFees}`), 1),
                        _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('RECOVERY.GAMBLING')} : ${_ctx.obFormattedFields.monthOutcomesGamblings}`), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          (!!_ctx.obFormattedFields?.balancePerDay)
            ? (_openBlock(), _createBlock(_component_ResultCard, {
                key: 0,
                title: _ctx.translate('OPEN_BANKING.DAILY_BALANCE')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_LineChart, {
                    "background-color-chart": "#4FA0FF",
                    "abscissa-chart": _ctx.formatBalance(_ctx.obFormattedFields?.balancePerDay, 'date'),
                    "data-chart": _ctx.formatBalance(_ctx.obFormattedFields?.balancePerDay, 'amount'),
                    "label-chart": _ctx.translate('OPEN_BANKING.DAILY_BALANCE'),
                    "border-color-chart": "#4FA0FF"
                  }, null, 8, ["abscissa-chart", "data-chart", "label-chart"])
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          (!!_ctx.obFormattedFields?.balancePerMonth)
            ? (_openBlock(), _createBlock(_component_ResultCard, {
                key: 1,
                title: _ctx.translate('OPEN_BANKING.MONTHLY_BALANCE')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BarChart, {
                    "background-color-chart": "#4FA0FF",
                    "abscissa-chart": _ctx.formatBalance(_ctx.obFormattedFields?.balancePerMonth, 'date'),
                    "data-chart": _ctx.formatBalance(_ctx.obFormattedFields?.balancePerMonth, 'amount'),
                    "label-chart": _ctx.translate('OPEN_BANKING.MONTHLY_BALANCE')
                  }, null, 8, ["abscissa-chart", "data-chart", "label-chart"])
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          _createVNode(_component_XMasBonhomme, {
            style: {top: '148vh', left: '75vw'},
            value: 9
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}