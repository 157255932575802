import {ApiUserDtoV2} from "@/apiCalls/login";
import {setCookie} from "@/utils/cookiesUtils";
import moment from "moment";
import store from "@/store";

export function useCookies() {

  const persistUserCookie = async ({user, token, expirationInstant}: {
    user: ApiUserDtoV2,
    token: string,
    expirationInstant: string
  }) => {
    const {USAGE} = user.companyParameters,
      config = JSON.parse(USAGE ? USAGE : '{}');
    setCookie('access_token', token);
    setCookie('expiration_instant', expirationInstant);
    setCookie('user', JSON.stringify(user));
    setCookie('configuration', config);
    setCookie('connexion_date', moment().format('LLL'));
    await store.dispatch('fetchUser');
  }

  return {persistUserCookie};
}