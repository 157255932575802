<script setup lang="ts">
import {computed} from "vue";

import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import Loader from "@/components/loader/Loader.vue";
import {translate} from '@/i18n';
import {CompanyInfo, CompanyInfoCauses} from "@/types/companyInfo";
import {useCompanyInfo} from "@/composables/useCompanyInfo";
import {isES} from "@/utils/formatRegistrationNumber";

const props = withDefaults(
    defineProps<{
      showInformationCompanyContent: boolean,
      checkCompany: CompanyInfo,
      companyCauses: CompanyInfoCauses,
      permissions: Array<String>,
      canCompanyInfo: boolean
    }>(), {
      showInformationCompanyContent: false,
      canCompanyInfo: false
    }
);

const companyInfo = useCompanyInfo();

const isESCompany = computed(() => {
  return isES(props.checkCompany.registrationNumber)
})
</script>

<template>
  <ContentCard v-if="!canCompanyInfo" class="company-info" :title="translate('INFORMATION_COMPANY')"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard class="company-info" :title="translate('INFORMATION_COMPANY')">
      <div v-if="showInformationCompanyContent" class="company-info-content">
        <div v-if="permissions?.includes('KIT_B2B_COMPANY_INFO_LEGAL_UNIT')"
             class="company-info-content-corporate-name">
          <span class="company-info-content__title">{{ `${translate('COMPANY.LEGAL_INFORMATION')} :` }}</span>
          <LabelValuePrinter v-for="data in companyInfo.getCompanyInfo(props.checkCompany)"
                             :label="data.label"
                             :value="data.value"
                             :copyToClipboard="data.copy_to_clipboard"
                             :is-address="data.is_address"/>
        </div>
        <div>
          <div v-if="permissions?.includes('KIT_B2B_COMPANY_INFO_LEGAL_UNIT')"
               class="company-info-content-activity">
            <span class="company-info-content__title">{{ `${translate('COMPANY.ACTIVITY')} :` }}</span>
            <LabelValuePrinter
                :label="`${translate('COMPANY.ACTIVITY_CODE')} - ${translate('COMPANY.FIELD_OF_ACTIVITY')}`">
              <ul class="company-info-content-activities">
                <li v-for="activity in companyInfo.companyActivities(props.checkCompany)">
                  {{ activity.code }} - {{ activity.name }}
                </li>
              </ul>
            </LabelValuePrinter>
            <LabelValuePrinter v-if="!isESCompany"
                               :is-picture="true" :picture-value="companyInfo.activityStatusLabel(checkCompany)"
                               :label="translate('COMPANY.ACTIVITY_STATUS')"
                               :value="checkCompany.isActive ? 'green-circle.png' : 'red-circle.png'"/>
            <LabelValuePrinter :is-picture="true" :label="translate('COMPANY.DOMICILIATION')"
                               :value="companyInfo.domiciliationValue(checkCompany).value"
                               :picture-value="companyInfo.domiciliationValue(checkCompany).label"
                               :copyToClipboard="!!checkCompany.domiciliation"
                               :value-to-copy="checkCompany.domiciliation ? checkCompany.domiciliation.registrationNumber : ''"/>
          </div>
          <div v-if="companyInfo.hasPermissionsWebsite(props.permissions)" class="company-info-content-website">
            <span class="company-info-content__title">{{ `${translate('COMPANY.WEBSITE')} :` }}</span>
            <div v-if="companyCauses">
              <LabelValuePrinter
                  v-for="data in companyInfo.getCompanyInfoWebsite(props.companyCauses, props.permissions)"
                  :label="data.label" :value="data.value" :is-website="data.is_website"/>
            </div>
            <div v-else class="company-info-content-website-message">
              <span>{{ translate('NO_WEBSITE') }}</span>
            </div>
          </div>
        </div>
      </div>
      <Loader v-else/>
    </ContentCard>
  </template>
</template>


