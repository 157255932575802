<script setup lang="ts">
import {computed, onMounted, reactive, ref, watch} from 'vue';
import ContentCard from '@/components/card/ContentCard.vue';
import Button from '@/components/button/Button.vue';
import InlineTextInput from '@/components/input/InlineTextInput.vue';
import {getRegex, TextInputType} from '@/types/TextInputType';
import {translate} from '@/i18n';
import {
  cancelScoringBatch,
  downloadBatchResult,
  getBatchTemplate,
  getScoringBatchProgress,
  runScoringBatch,
  getParameters
} from '@/apiCalls/scoringProxy';
import ProgressBar from '@/components/ProgressBar.vue';
import FormFile from '@/components/FormFile.vue';
import {useToast} from 'vue-toast-notification';
import {BreadCrumbsSteps} from '@/utils/breadCrumbs';
import {useStore} from 'vuex';
import {downloadPDF} from "@/utils/generatePdf";
import {isNull} from "lodash";
import {availableScoreBatchValues} from "@/utils/scoreBatch";

const store = useStore();

const props = withDefaults(defineProps<{
  userEmail: string
}>(), {
  userEmail: ''
});

const steps = reactive([
  {
    id: BreadCrumbsSteps.BatchScoreChoice,
    title: translate('BREADCRUMBS.SCORE_CHOICE'),
    icon: '1',
    enabled: false,
    checked: false
  },
  {
    id: BreadCrumbsSteps.BatchReceiverChoice,
    title: translate('BREADCRUMBS.RECEIVER_CHOICE'),
    icon: '2',
    enabled: false,
    checked: false
  },
  {
    id: BreadCrumbsSteps.BatchUpload,
    title: translate('BREADCRUMBS.UPLOAD'),
    icon: '3',
    enabled: false,
    checked: false
  },
  {
    id: BreadCrumbsSteps.BatchRun,
    title: translate('BREADCRUMBS.BATCH_RUN'),
    icon: '4',
    enabled: false,
    checked: false
  }
]);

const showDownloadBatchButton = ref(false);
const email = ref('');
const fileName = ref('');
const numberOfLines = ref(0);
const selectedRequestIds = ref([] as string[]);
const timer = ref(null as any);
const batchInfos = ref({
  progress: 0,
  current: 0,
  max: 20
});
const file = ref('');
const isBatchLoading = ref(false);
const disableCancelButton = ref(true);
const batchTaskId = ref(null as any);
const pushNotification = ref(false);
const resetUpload = ref(false);

const LETTERS = TextInputType.LETTERS;
const EMAIL = TextInputType.EMAIL;
const FILE_NAME = TextInputType.FILE_NAME;
const NOT_NULL = TextInputType.NOT_NULL;

// getters
const parameters = computed(() => store.getters.parameters);

const disableStartButton = computed(() => {
  if (steps.slice(0, 3).map((e) => e.checked).every((e) => e) && !isBatchLoading.value && file.value !== '') {
    return !(getRegex(FILE_NAME).test(fileName.value) || fileName.value === '');
  }
  return true;
});

const availableRequestIds = computed(() => {
  let usage = parameters.value['USAGE'];
  usage = usage ? JSON.parse(usage) : [];
  const companyAvailableScores = usage?.batch?.authorizedScores ? usage.batch.authorizedScores : [];
  return [...availableScoreBatchValues, ...companyAvailableScores];
})

const checkLines = async () => {
  const response = await getParameters();
  numberOfLines.value = response.data.MAX_LINES.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
}

const updateRequestIdsSelected = (requestId: string) => {
  clearForm();
  if (!selectedRequestIds.value.includes(requestId)) selectedRequestIds.value.push(requestId);
  else selectedRequestIds.value = selectedRequestIds.value.filter((e) => e != requestId);
  steps[0].checked = selectedRequestIds.value.length != 0;
}
const downloadTemplate = async () => {
  const template = await getBatchTemplate(selectedRequestIds.value);
  const href = URL.createObjectURL(template);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `scoring_batch_template.xlsx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

const uploadFile = (uploadedFile: any) => {
  if (isNull(file)) return;
  file.value = uploadedFile;
  steps[2].checked = true;
}

const downloadBatch = async () => {
  const result = await downloadBatchResult(batchTaskId.value);
  const file_name = (fileName.value !== '') ? fileName.value.concat('.', 'xlsx') : 'BatchResults.xlsx';
  downloadPDF(result, file_name);
}

const batchInterval = async () => {
  const batchTask = await getScoringBatchProgress(batchTaskId.value);
  const max = batchTask?.causes?.batchMemory?.max;
  let current = batchTask?.causes?.batchMemory?.tasksIds ? Object.keys(batchTask?.causes?.batchMemory?.tasksIds).filter((key: string) => key != 'description').length : 0;
  // add skipped tasks in current counter
  let skippedTasks = batchTask?.causes?.batchMemory?.skippedTasks;
  let skippedTasksCounter = 0;
  Object.keys(skippedTasks).filter((key: string) => key != 'description').map((lineNumber: any) => {
    Object.keys(skippedTasks[lineNumber]).map((requestId: any) => {
      skippedTasksCounter++;
    });
  });
  current += skippedTasksCounter;
  batchInfos.value = {
    max: max,
    current: current,
    progress: current / max * 100
  };
  if (!pushNotification.value && (max == current && isBatchLoading.value || ["SUCCESS", "FAILED", "CANCELLED"].includes(batchTask?.status))) {
    pushNotification.value = true;
    const toast = useToast();
    toast.success(translate('BATCH.RESULT'), {duration: 10000});
    showDownloadBatchButton.value = true;
    clearInterval(timer.value);
    isBatchLoading.value = false;
    batchInfos.value = {
      max: 1,
      current: 0,
      progress: 0
    };
    disableCancelButton.value = true;
  }
}

const startBatch = async () => {
  try {
    resetUpload.value = false;
    pushNotification.value = false;
    const toast = useToast();
    toast.info(translate('BATCH.STARTED'));
    isBatchLoading.value = true;
    disableCancelButton.value = false;
    showDownloadBatchButton.value = false;
    steps[3].checked = true;
    const res = await runScoringBatch(email.value, selectedRequestIds.value, file.value, fileName.value);
    batchTaskId.value = res.id;
    timer.value = setInterval(async () => await batchInterval(), 5000);
  } catch (error: any) {
    const toast = useToast();
    toast.error(`ERROR ${error?.request?.status}`);
  }
}

const cancelBatch = async () => {
  await cancelScoringBatch(batchTaskId.value);
  disableCancelButton.value = true;
  isBatchLoading.value = false;
  clearForm();
  batchInfos.value = {
    progress: 0,
    current: 0,
    max: 1
  };
  clearInterval(timer.value);
  const toast = useToast();
  toast.success(translate('BATCH.CANCELLED'));
}

const clearForm = () => {
  fileName.value = '';
  file.value = '';
  showDownloadBatchButton.value = false;
  resetUpload.value = true;
}

watch(email, () => {
  steps[1].checked = getRegex(TextInputType.EMAIL).test(email.value);
});

onMounted(() => {
  email.value = props.userEmail;
  checkLines();
});
</script>
<template>
  <ContentCard title="Batch">
    <div class="batch-content">
      <!--<AppWrapper :steps="steps" class="batch-content-breadcrumbs"/>-->
      <span class="click-on-scores-below">{{ translate('BATCH.CLICK_ON_SCORES_BELOW') }}</span>
      <div class="check-buttons-list">
        <div v-for="requestId in availableRequestIds"
             :class="{'check-button': true, 'checked': selectedRequestIds.includes(requestId)}"
             @click="updateRequestIdsSelected(requestId)">
          <img v-if="selectedRequestIds.includes(requestId)"
               :src="require('/public/images/success-icon.png')"
               alt="success-icon.png"
               class="icon">
          <span>{{
              translate(`BATCH.SCORE.REQUEST_ID.${requestId}`).includes('REQUEST_ID') ? requestId : translate(`BATCH.SCORE.REQUEST_ID.${requestId}`)
            }}</span>
        </div>
      </div>
      <div class="usage-information" v-if="selectedRequestIds.length > 0">
        <span class="usage-information-title">{{ translate('BATCH.USAGE_INFORMATION') }}</span>
        <div class="usage-information-button-line">
          <span class="title-small">{{ translate('BATCH.MANUAL_TITLE') }} :</span>
          <Button @click="downloadTemplate()" class="usage-information-button-object"
                  :label="translate('BATCH.DOWNLOAD_TEMPLATE')">
          </Button>
        </div>
        <span class="manual-description">{{ translate('BATCH.MANUAL_DESCRIPTION') }}</span>
        <span class="title-small">{{ translate('BATCH.DESCRIPTION') }} :</span>
        <div class="usage-information-request-ids">
          <div class="score-description" v-for="requestId in selectedRequestIds">
            <span class="title-bold">
              {{
                translate(`BATCH.SCORE.REQUEST_ID.${requestId}`).includes('REQUEST_ID') ? requestId : translate(`BATCH.SCORE.REQUEST_ID.${requestId}`)
              }} :
            </span>
            <span>
              {{ translate(`BATCH.SCORE.DESCRIPTION.${requestId}`) }}
            </span>
          </div>
        </div>
        <div class="text-red">
          <span class="title-small">
            {{ `${translate('IMPORTANT')} :` }}
          </span>
          <span>
            {{ ` ${numberOfLines} ${translate('BATCH.LIMIT_PER_DAY')}` }}
          </span>
        </div>
      </div>
      <div class="inputs">
        <InlineTextInput class="text-input" :label="translate('IDENTITY.EMAIL')+'*'"
                         v-model="email"
                         :type="EMAIL"
        />
        <InlineTextInput class="text-input_file-name" :label="translate('BATCH.FILE_NAME')"
                         v-model="fileName"
                         :type="FILE_NAME"/>
        <FormFile @update="uploadFile" :resetUpload="resetUpload"/>
      </div>
      <Button v-if="!showDownloadBatchButton" :label="translate('BATCH.START')" @click="startBatch"
              :disabled="disableStartButton"/>
      <Button v-if="!disableCancelButton" :label="translate('BATCH.CANCEL')" @click="cancelBatch"
              :disabled="disableCancelButton"/>
      <Button v-if="showDownloadBatchButton" :label="translate('BATCH.DOWNLOAD')"
              @click="downloadBatch"/>
      <ProgressBar v-if="isBatchLoading" :value="batchInfos.current" :max="batchInfos.max"/>
    </div>
  </ContentCard>
</template>
