//need to count the indicator (+33 for example) in the length 
export const phoneIndicator = [
  {code: "🇫🇷", value: "+33", length: [12]},
  {code: "🇧🇪", value: "+32", length: [11, 12, 13]},
  {code: "🇱🇺", value: "+352", length: [7, 11, 12, 14, 15]},
  {code: "🇳🇱", value: "+31", length: []},
  {code: "🇪🇸", value: "+34", length: [12]},
  {code: "🇮🇹", value: "+39", length: [11, 15]},
  {code: "☎️", value: "+", length: []},
  // Add more country options as needed
]