import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const sendEmailResetApiKeyAdmin = (userId: number) => {
  return api.post(`${envConfig.BACKEND_ROUTE}/iam/v5/client/admin/users/${userId}/api-key`);
};

export const sendEmailResetApiKeyManager = (userId: number) => {
  return api.post(`${envConfig.BACKEND_ROUTE}/iam/v5/client/manager/users/${userId}/api-key`);
};

export const resetMyApiKey = () => {
  return api.post(`${envConfig.BACKEND_ROUTE}/iam/v5/me/api-key`);
};

export const getApiKey = () => {
  return api.get(`${envConfig.BACKEND_ROUTE}/iam/v5/me/api-key`);
}