<script setup lang="ts">
import * as _ from "lodash";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import "vue-json-pretty/lib/styles.css";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

import {startLiveCheck} from "@/apiCalls/liveCheckApi";
import {getData} from "@/apiCalls/taskApi";
import {getStatusIcon} from "@/composables/formatKeyValuePairWithFunc";
import {locale, translate} from "@/i18n";
import {DOCUMENTS_FIELDS} from "@/utils/dtoFields";
import {KeyValueType} from "@/utils/sortArrayObjectKeys";

import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import PreviewDocument from "@/components/PreviewDocument.vue";
import BoxOutputCompanyFinances from "@/components/box-output/BoxOutputCompanyFinances.vue";
import Button from "@/components/button/Button.vue";
import AuthenticityResult from "@/components/document-analysis/AuthenticityResults.vue";
import BankDetailsResults from "@/components/document-analysis/BankDetailsResults.vue";
import {default as IdCheckResults} from "@/components/document-analysis/IdCheckResults.vue";
import IncomeTaxResults from "@/components/document-analysis/IncomeTaxResults.vue";
import KbisIndividualResults from "@/components/document-analysis/KbisIndividualResults.vue";
import KbisResults from "@/components/document-analysis/KbisResults.vue";
import PayslipResults from "@/components/document-analysis/PayslipResults.vue";
import PropertyTaxResults from "@/components/document-analysis/PropertyTaxResults.vue";
import TaxReportResults from "@/components/document-analysis/TaxReportResults.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import PrintDocumentAnalysisResult from "@/components/print/PrintDocumentAnalysisResult.vue";

const props = withDefaults(
    defineProps<{
      documentType: string;
      documentStatus: string;
      documentName: any;
      documentData: KeyValueType;
      journeyId: string;
      documentFileId: any;
      documentLink: string;
      contextData: {
        [key: string]: { [key: string]: { [key: string]: KeyValueType } };
      };
      webUploadStatus?: any,
      canShowDetails: boolean,
      taskId: string,
      creationDate: string,
      faceMatchingStatus: string
    }>(),
    {
      documentType: "",
      documentStatus: "",
      documentName: [],
      journeyId: "",
      documentFileId: [],
      documentLink: "",
      contextData: {},
      webUploadStatus: {},
      canShowDetails: false
    }
);
let timer: any;

const store = useStore();
const {t} = useI18n();
const route = useRoute();

const modalFlag = ref(false);
const modalContent = ref<'LIVE_CHECK' | 'DETAILS'>()
const liveChecked = ref(false)

const getDocumentStatus = (status: string) => {
  if (Object.keys(props.documentData || []).includes("AUTHENTICITY")) {
    return props.documentData?.AUTHENTICITY
  }

  if (
      props.documentData?.DOC_TYPE == "INCOME_TAX" &&
      !_.isEmpty(props.contextData["tax_notice_analysis"])
  ) {
    const taxNoticeAnalysis = props.contextData["tax_notice_analysis"];
    if (Array.isArray(taxNoticeAnalysis)) {
      const find = taxNoticeAnalysis.find(
          (a: { documentIds: any[] }) =>
              a.documentIds && a.documentIds[0] === props.documentFileId[0]
      );
      const checks = find.checks as any;
      const allStatusOK = checks?.every((checkItem: any) => {
        return checkItem.status == "OK";
      });
      status = allStatusOK ? "OK" : "KO";
    }
  }

  return getStatusIcon(status);
};

const getDocumentAuthenticityStatus = (documentData: Object) => {
  if (Object.keys(documentData).includes("authenticity")) {
    return documentData.authenticity;
  }
  return "null-icon.png";
};

const isIdCheck = (documentType: string): boolean => {
  return (
      documentType === "DRIVING_LICENSE" ||
      documentType === "ID_CARD" ||
      documentType === "PASSPORT" ||
      documentType === "RESIDENCE_PERMIT"
  );
};

const taxNoticeAnalysisCheck = computed(() => {
  // @ts-ignore
  if (props.contextData.tax_notice_analysis.length > 0) {
    // @ts-ignore
    const taxNoticeAnalysis = props.contextData.tax_notice_analysis.filter(
        // @ts-ignore
        (item) => {
          return item.documentIds[0] === props.documentFileId[0];
        }
    );
    return taxNoticeAnalysis[0].checks;
  }
  return {};
});

const getExtensionFile = (document: string) => {
  if (document?.split("."))
    return document?.split(".")[document?.split(".").length - 1];
};

const getProviderFromIdCheckData = (
    idCheckData: {
      [key: string]: { [key: string]: { [key: string]: KeyValueType } };
    }[],
    documentIds: string[]
) => {
  const checkData = idCheckData.filter((el) =>
      _.isEqual(el.documentIds, documentIds)
  );
  return checkData.length > 0 ? checkData[0].provider : "";
};

const isFaceMatching = computed(() =>
    ["CREATED", "IN_PROGRESS", "FAILED", "SUCCESS", "CANCELLED"].includes(props.faceMatchingStatus)
);

const isFaceMatchingInProgress = computed(() =>
    ["CREATED", "IN_PROGRESS", "CANCELLED"].includes(props.faceMatchingStatus)
);

const idCheckProvider = computed(() => {
  if (!isIdCheck(props.documentData.DOC_TYPE as string)) return "";
  return getProviderFromIdCheckData(
      // @ts-ignore
      props.contextData.id_check_data,
      props.documentFileId
  );
});

const isWithAuthenticity = computed(() => {
  if (props.documentData.documentCategory === 'ID') return false
  const docTypes = [
    "TAX_REPORT",
    "KBIS",
    "TAX_NOTICE",
    "PAYSLIP",
    "BANK_DETAILS",
    "UNKNOWN"
  ];
  return docTypes.includes(props.documentType as string);
});

const permissionsSorted = computed(() => store.getters.permissionsSorted);

const documentLive = computed(() => {
  let createdAt = props.creationDate?.toUpperCase().endsWith('Z')
      ? props.creationDate
      : props.creationDate + 'Z'
  let creationDate = new Date(createdAt)
  let now = new Date()
  const milliDiff = Math.abs(creationDate.valueOf() - now.valueOf());
  const minuteDiff = milliDiff / (1000 * 60);
  return minuteDiff;
})

const liveCheckStatus = computed(() => {
  return props.contextData?.live_check.find((el) => el.refTaskId === props.taskId)
})

const isLiveCheckInProgress = computed(() => {
  if (liveCheckStatus.value) {
    return ["CREATED", "IN_PROGRESS"].includes(liveCheckStatus.value?.status);
  }
  return false;
})

const isLiveCheckAvailable = computed(() => {
  if (liveCheckStatus.value) {
    return ["FAILED", "SUCCESS"].includes(liveCheckStatus.value?.status)
  }
  return false
})

const canLiveCheck = computed(() => {
  return !(documentLive.value > 10) && !liveChecked.value && !liveCheckStatus.value
})

const liveCheckMessage = computed(() => {
  const baseMessage = 'DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK'
  const className = "live-check-info-text"
  if (isLiveCheckInProgress.value)
    return {message: `${baseMessage}.IN_PROGRESS`, className}
  if (isLiveCheckAvailable.value)
    return {message: `${baseMessage}.AVAILABLE`, className}
  return {message: `${baseMessage}.INFO`, className: `${className}-grey`}
})

const hasLiveCheck = computed(() => {
  const hasPermission =
      route.name === "DocumentAnalysis"
          ? permissionsSorted.value.includes("KIT_DOC_LIVE_CHECK_GLOBAL")
          : route.name === "AnalysisB2B"
              ? permissionsSorted.value.includes("KIT_B2B_LIVE_CHECK_GLOBAL")
              : permissionsSorted.value.includes("KIT_B2C_LIVE_CHECK_GLOBAL");
  return isIdCheck(props.documentData.DOC_TYPE as string) && hasPermission && !isFaceMatching.value
})

const handleDetailsClick = () => {
  if (!props.canShowDetails) return;
  modalContent.value = 'DETAILS'
  modalFlag.value = true;
}

const handleLiveCheckClick = () => {
  modalContent.value = 'LIVE_CHECK'
  modalFlag.value = true;
}

const closeModal = () => {
  modalFlag.value = false
  modalContent.value = undefined
}

const confirmLiveCheck = async () => {
  await startLiveCheck(props.taskId, locale)
  await getData(props.journeyId, DOCUMENTS_FIELDS);
  liveChecked.value = true
  closeModal()
}

const startTimer = () => {
  const tenMinutes = 10 * 60 * 1000
  timer = setTimeout(() => {
    liveChecked.value = true
  }, tenMinutes)
}

onMounted(() => {
  startTimer()
})

onUnmounted(() => {
  clearTimeout(timer)
})
</script>

<template>
  <div class="table-document no-print">
    <div>
      <div class="flex">
        <PreviewDocument class="preview-document-view" :id="documentFileId[0]"
                         :document-id="documentFileId[0]"
                         :journey-id="journeyId" :document-path="documentLink"
                         :document-type="getExtensionFile(documentName[0])"
                         :is-modal-preview="false" :is-in-progress-face-matching="isFaceMatchingInProgress"/>
        <div class="table-document-information">
          <LabelValuePrinter :label="translate('DOCUMENT_CONTROL.TABLE.DOCUMENT')"
                             :value="translate(`DOCUMENT_CONTROL.${isFaceMatching ? 'FACE_MATCHING' : documentType}`)"/>
          <LabelValuePrinter v-if="!isFaceMatching" :label="translate('DOCUMENT_CONTROL.TABLE.NAME')"
                             :value="documentName[0]"/>
          <LabelValuePrinter v-if="props.webUploadStatus.status === 'DONE'"
                             :label="translate(`DOCUMENT_CONTROL.TABLE.${documentType === 'AUTHENTICITY'? 'RESULT.AUTHENTICITY.TITLE': 'COMPLIANCE'}`)"
                             :value="getDocumentStatus(documentStatus)" is-picture/>
          <LabelValuePrinter v-if="props.webUploadStatus.status === 'DONE' && isWithAuthenticity"
                             :label="translate('DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.TITLE')"
                             :value="getDocumentAuthenticityStatus(props.documentData)" is-picture/>
          <LabelValuePrinter v-if="props.webUploadStatus.status === 'IN_PROGRESS'"
                             :label="translate('DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.TITLE')"
                             value="open-banking/hourglass.png" is-picture>
            <span>{{ translate('DOCUMENT_CONTROL.TABLE.RESULT.WAITING_CONFIRMATION_ANALYSIS') }}</span>
          </LabelValuePrinter>
          <div class="table-document-detail">
            <template v-if="props.webUploadStatus.status === 'DONE'">
              <Button
                  :label="translate('BUTTONS.DETAILS_ANALYSIS')"
                  @click="handleDetailsClick"
                  class="table-document-detail-button"
                  :is-disabled="!props.canShowDetails"
                  :picture="props.canShowDetails ? '' : 'padlock-grey.png'"
              />
              <div v-if="isFaceMatchingInProgress" class="live-check-info">
                <img src="/images/open-banking/hourglass.png" alt="hourglass">
                <span class="live-check-info-text">{{
                    translate(
                        "DOCUMENT_CONTROL.TABLE.RESULT.FACE_MATCHING.IN_PROGRESS_TITLE"
                    )
                  }}</span>
              </div>
              <template v-if="hasLiveCheck">
                <Button
                    class="table-document-detail-button"
                    @click="handleLiveCheckClick"
                    :label="translate('BUTTONS.LIVE_CHECK')"
                    :disabled="!canLiveCheck"
                />
                <div class="live-check-info">
                  <img v-if="isLiveCheckInProgress" src="/images/open-banking/hourglass.png" alt="hourglass">
                  <span :class="liveCheckMessage.className">{{ translate(`${liveCheckMessage.message}`) }}</span>
                </div>
              </template>
            </template>
            <a
                v-else
                :href="props.webUploadStatus.link"
                target="_blank"
            >
              <Button
                  :label="translate('BUTTONS.COMPLETE_UPLOAD')"
              />
            </a>
            <CustomModal
                :custom-class="`document-result-modal ${modalContent === 'LIVE_CHECK' ? 'live-check' : ''}`"
                v-model:open="modalFlag"
            >
              <div v-if="modalContent === 'DETAILS'" class="preview-and-infos">
                <div class="titles">
                  <h4>{{ translate("DOCUMENT_CONTROL.TABLE.RESULT.TITLE") }}</h4>
                  <div>
                    <span class="table-document-detail-result-analysis-titles-label">{{
                        translate("DOCUMENT_CONTROL.TABLE.TYPE")
                      }} :</span>
                    <span class="table-document-detail-result-analysis-titles-value">{{
                        translate(`DOCUMENT_CONTROL.${(isFaceMatching ? 'FACE_MATCHING' : (documentType === "OCR" ? documentData.DOC_TYPE : documentType))}`)
                      }}</span>
                  </div>
                  <div v-if="!isFaceMatching">
                    <span class="table-document-detail-result-analysis-titles-label">{{
                        translate("DOCUMENT_CONTROL.TABLE.NAME")
                      }} :</span>
                    <span v-for="name in documentName" class="table-document-detail-result-analysis-titles-value">&nbsp;{{
                        name
                      }}</span>
                  </div>
                  <div v-if="!['TAX_REPORT_S', 'TAX_REPORT_C'].includes(documentData.DOC_TYPE as string)">
                    <span class="table-document-detail-result-analysis-titles-label">
                      {{
                        translate(`DOCUMENT_CONTROL.TABLE.${documentType === 'AUTHENTICITY' ? 'RESULT.AUTHENTICITY.TITLE' : 'COMPLIANCE'}`)
                      }} :</span>
                    <img class="table-document-detail-result-analysis-titles-value"
                         :src="require(`/public/images/${getDocumentStatus((documentData.DOC_TYPE === 'INDIVIDUAL_KBIS' ? documentData : documentStatus) as string)}`)"
                         :alt="(getDocumentStatus((documentData.DOC_TYPE === 'INDIVIDUAL_KBIS' ? documentData : documentStatus) as string) as string)"/>
                  </div>
                  <div v-if="isWithAuthenticity">
                    <span class="table-document-detail-result-analysis-titles-label">{{
                        translate("DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.TITLE")
                      }}:</span>
                    <img class="table-document-detail-result-analysis-titles-value"
                         :src="require(`/public/images/${getDocumentAuthenticityStatus(props.documentData)}`)"
                         :alt="(getDocumentAuthenticityStatus(props.documentData) as string)"/>
                  </div>
                  <div v-if="documentData.DOC_TYPE === 'INDIVIDUAL_KBIS' ||documentData.DOC_TYPE === 'COMPANY_KBIS'">
                    <span class="table-document-detail-result-analysis-titles-label">{{
                        translate("DOCUMENT_CONTROL.TABLE.RESULT.KBIS.PUBLICATION_DATE")
                      }}:</span>
                    <span>{{ new Date(documentData.DOCUMENT_KBIS.publicationDate).toLocaleDateString() }}</span>
                  </div>
                </div>
                <div :class="documentFileId?.length > 2 ? 'center-content-alt' :'center-content'">
                  <template v-if="isFaceMatchingInProgress">
                    <PreviewDocument class="preview-document-view" id="" document-id=""
                                     journey-id="" document-path="" document-type=""
                                     :is-in-progress-face-matching="true"/>
                  </template>
                  <template v-else>
                    <PreviewDocument v-for="(fileId, index) in documentFileId" :id="fileId" :document-id="fileId"
                                     :journey-id="journeyId" :document-path="documentLink"
                                     :document-type="getExtensionFile(documentName[0])" :scale-y="3" :scale-x="3"
                                     class="big-preview-document" :is-in-progress-face-matching="false"/>
                  </template>
                  <div class="table-document-detail-result-analysis-details">
                    <span v-if="documentType === 'UNKNOWN' && documentData.documentCategory === 'ID'">{{
                        translate('DOCUMENT_CONTROL.UNKNOWN_DOC_ID')
                      }}</span>
                    <template v-else>
                      <IdCheckResults
                          v-if="isIdCheck(documentType) || isFaceMatching"
                          :checks="documentData"
                          :type="documentType"
                          :provider="idCheckProvider"
                          :live-check-status="liveCheckStatus?.status"
                          :face-matching-status="faceMatchingStatus"
                      />
                      <KbisResults
                          v-if="documentData.DOC_TYPE === 'COMPANY_KBIS'"
                          :checks="documentData"
                      />
                      <KbisIndividualResults
                          v-if="documentData.DOC_TYPE === 'INDIVIDUAL_KBIS'"
                          :checks="documentData"
                      />
                      <PropertyTaxResults
                          v-if="documentData.DOC_TYPE === 'PROPERTY_TAX'"
                          :checks="documentData"
                      />
                      <IncomeTaxResults
                          v-if="documentData.DOC_TYPE === 'INCOME_TAX'"
                          :checks="documentData"
                          :analysis-checks="taxNoticeAnalysisCheck"
                      />
                      <AuthenticityResult
                          v-if="documentType === 'AUTHENTICITY' || documentType === 'UNKNOWN'"
                          :checks="documentData"
                      />
                      <BankDetailsResults
                          v-if="documentData.DOC_TYPE === 'BANK_DETAILS'"
                          :checks="documentData"
                      />
                      <TaxReportResults
                          v-if="['TAX_REPORT_S', 'TAX_REPORT_C'].includes(documentData.DOC_TYPE as string)"
                          :checks="documentData" :contextData="contextData" :documentId="documentFileId"/>
                      <PayslipResults v-if="documentData.DOC_TYPE === 'PAYSLIP'" :checks="documentData"/>
                      <span v-if="documentType === 'UNKNOWN'">{{ documentData.statusReason }}</span>
                    </template>
                  </div>
                  <BoxOutputCompanyFinances
                      v-if="['TAX_REPORT_S', 'TAX_REPORT_C'].includes(documentData.DOC_TYPE as string)"
                      :tax-reports="contextData.tax_reports_result"
                      :can-company-finances="permissionsSorted.includes('KIT_B2B_COMPANY_FINANCES_GLOBAL')"/>
                </div>
              </div>
              <div v-if="modalContent === 'LIVE_CHECK'" class="preview-and-infos">
                <div class="titles">
                  <h4>
                    {{ translate("DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK.TITLE") }}
                  </h4>
                  <br/><span class="live-check-desc">{{
                    t("DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK.DESC_1", {documentName: documentName[0]})
                  }}</span>
                  <br/><span class="live-check-desc">{{
                    translate("DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK.DESC_2")
                  }}</span>
                  <span class="live-check-desc">{{
                      translate("DOCUMENT_CONTROL.TABLE.RESULT.LIVE_CHECK.DESC_3")
                    }}</span>
                  <div class="live-check-modal-button-container">
                    <Button
                        @click="closeModal"
                        :label="translate('BUTTONS.CLOSE')"
                        class="live-check-modal-button"
                    />
                    <Button
                        @click="confirmLiveCheck"
                        :label="translate('BUTTONS.CONFIRM')"
                        class="live-check-modal-button confirm-button"
                    />
                  </div>
                </div>
              </div>
            </CustomModal>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PrintDocumentAnalysisResult
      :document-data="documentData"
      :document-file-id="documentFileId"
      :document-name="documentName"
      :document-type="documentType"
      :id-check-provider="idCheckProvider"
      :live-check-status="liveCheckStatus"
      :tax-notice-analysis-check="taxNoticeAnalysisCheck"
      :contextData="contextData"
      :can-company-finances="
      permissionsSorted.includes('KIT_B2B_COMPANY_FINANCES_GLOBAL')
    "
      :face-matching-status="faceMatchingStatus"
  />
</template>
