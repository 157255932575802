<script setup lang="ts">
import {defineProps, computed} from 'vue';
import Loader from "@/components/loader/Loader.vue";

/**
 * Skeleton is a component that represent a placeholder for a component that is loading.
 * Useful when you want to implement a ScrollIntoView feature for a component that is not yet rendered
 *
 * @param {string} width
 * @param {string} height
 *
 * Params for the skeleton style
 *
 * @param {string} display
 * @param {string} justify
 * @param {string} align
 */

const props = defineProps({
  width: {type: String, default: '80vw'},
  height: {type: String, default: '80vh'},
  display: {type: String, default: 'flex'},
  justify: {type: String, default: 'center'},
  align: {type: String, default: 'center'},
});

const divStyle = computed(() => ({
  width: props.width,
  height: props.height,
}));

const skeletonStyle = computed(()=>({
  display: props.display,
  justifyContent: props.justify,
  alignItems: props.align,
}));
</script>

<template>
  <div :style="divStyle">
    <Loader :style="skeletonStyle"/>
  </div>
</template>