<script setup lang="ts">
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { computed, onMounted, ref } from "vue";
import { Bar } from "vue-chartjs";

import { getPrintImgSrc } from "@/utils/printChartUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const props = withDefaults(
  defineProps<{
    backgroundColorChart: string;
    labelChart: string;
    dataChart: number[];
    abscissaChart: string[];
  }>(),
  {
    backgroundColorChart: "#99A4BF",
    labelChart: "",
  }
);

const delayed = ref(false);
const barChartElement = ref();
const imgElement = ref<HTMLImageElement>();

const barChartClassName = "ob-barchart-img-print-src";
const options = {
  responsive: true,
  maintainAspectRatio: false,
  // Animation was disabled because it was causing the chart to not be printed
  // animation: {
  //   onComplete: () => {
  //     delayed.value = true;
  //   },
  //   delay: (context: any) => {
  //     let delay = 0;
  //     if (
  //       context.type === "data" &&
  //       context.mode === "default" &&
  //       !delayed.value
  //     ) {
  //       delay = context.dataIndex * 300 + context.datasetIndex * 100;
  //     }
  //     return delay;
  //   },
  // },
};

const data = computed(() => {
  return {
    labels: props.abscissaChart,
    datasets: [
      {
        label: props.labelChart,
        backgroundColor: props.backgroundColorChart,
        data: props.dataChart,
      },
    ],
  };
});

onMounted(() => {
  const timer = setInterval(() => {
    if (barChartElement.value && barChartElement.value.chart) {
      const canvas = barChartElement.value.chart.canvas;
      canvas.classList.add(barChartClassName);
      if (imgElement.value) {
        const [imgSrc] = getPrintImgSrc(`.${barChartClassName}`);
        imgElement.value.src = imgSrc;
        clearInterval(timer);
      }
    }
  }, 250);
});
</script>

<template>
  <Bar ref="barChartElement" :data="data" :options="options" />
  <img ref="imgElement" class="ob-barchart-img-print to-print" />
</template>
