<template>
  <ContentCard v-if="!canUploadDocument" :checked-display-content="false" :is-padlock="true" class="applicant-b2c"
               :title="`${translate('VERIFICATION_DOCUMENT')}`"/>
  <template v-else>
    <ContentCard :title="translate('VERIFICATION_DOCUMENT')" class="verification-document">
      <Select v-if="type !== 'b2c'" v-model:value="documentType" :options="listDocumentType"
              :values="listDocumentTypeValue" :title="translate('DOCUMENT_CONTROL.CHOOSE')"
              @option-selected="(docType) => selectUploadMode('IFRAME', docType)"/>
      <div class="verification-document__buttons" v-if="documentType !== 'OTHER'">
        <Button v-if="uploadMode === 'PHONE'" :label="translate('BUTTONS.IFRAME')"
                v-on:click="selectUploadMode('IFRAME', this.documentType)"/>
        <Button v-if="uploadMode === 'IFRAME'" :label="translate('BUTTONS.SEND_TEXT_MESSAGE')"
                v-on:click="selectUploadMode('PHONE', this.documentType)"/>
      </div>
      <div v-if="messageError">
        <span>{{ translate('DOCUMENT_CONTROL.ERROR_MESSAGE_API') }}</span>
      </div>
      <template v-if="!!documentType && documentType !== 'OTHER'">
        <XMasBonhomme v-if="uploadMode == 'IFRAME'" :style="{top: '27vh', left: '28vw'}" :value="12"/>
        <div v-if="uploadMode == 'IFRAME'" class="verification-document__iframe">
          <iframe v-if="iframeLink" :src="iframeLink"/>
          <Loader v-else/>
        </div>
        <div v-if="uploadMode == 'PHONE'" class="verification-document__phone">
          <PhoneInput v-model:value="phoneNumber"/>
          <Button class="container-openbanking-informations-form-submit-button" type-button="submit"
                  :label="translate('BUTTONS.VALIDATE_AND_SEND_SMS')"
                  v-on:click="sendSms" :disabled="phoneValid"
          />
        </div>
      </template>
      <div v-if="documentType === 'OTHER'">
        <UploadAuthenticity @start-loading="startLoading" @end-loading="endLoading" :type="documentType"
                            :message="translate('DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.ANALYSIS_MESSAGE')"/>
      </div>
      <div
        v-if="documentType === 'INCOME_TAX_NOTICE' && permissions?.some(elt => elt.includes('GLOBAL') || elt.includes('TAX_NOTICE_INCOME'))">
        <upload-tax-notice @start-loading="startLoading" @end-loading="endLoading" :type="'TAX_NOTICE'"/>
      </div>
    </ContentCard>
  </template>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {translate} from '@/i18n';

import ContentCard from '@/components/card/ContentCard.vue';
import ButtonMeelo from '@/components/button/Button.vue';
import Button from '@/components/button/Button.vue';
import Loader from '@/components/loader/Loader.vue';
import UploadModal from '@/components/document-analysis/UploadAuthenticity.vue';
import Select from '@/components/select/Select.vue';
import {createIframeUploadDocument} from '@/apiCalls/uploadDocumentsApi';
import TextInput from '@/components/input/TextInput.vue';
import PhoneInput from '@/components/input/PhoneInput.vue';
import {getRegex, TextInputType} from '@/types/TextInputType';
import UploadAuthenticity from "@/components/document-analysis/UploadAuthenticity.vue";
import XMasBonhomme from "@/components/x-mas/XMasBonhomme.vue";
import {createJourneyId} from "@/apiCalls/contextApi"
import {createJourneyIdByTask} from '@/apiCalls/taskApi';

export default defineComponent({
  components: {
    UploadAuthenticity,
    PhoneInput,
    TextInput, Button,
    Select,
    ContentCard,
    ButtonMeelo,
    UploadModal,
    Loader,
    XMasBonhomme
  },
  data: () => ({
    journeyId: '',
    iframeLink: '',
    documentType: undefined as string | undefined,
    uploadMode: undefined as 'IFRAME' | 'PHONE' | undefined,
    phoneNumber: '',
    optionSelected: false,
    messageError: false
  }),
  props: {
    listDocumentType: {type: Array, default: []},
    listDocumentTypeValue: {type: Array, default: []},
    type: {type: String},
    permissions: {type: Array},
    canUploadDocument: {type: Boolean, default: false}
  },
  computed: {
    phoneValid() {
      return !(getRegex(TextInputType.PHONE_NUMBER).test(this.phoneNumber));
    }
  },
  watch: {
    '$route.query.journeyId': function (search) {
      this.journeyId = this.$route.query.journeyId ? this.$route.query.journeyId.toString() : '' as string;
    }
  },
  methods: {
    generateJourneyId() {
      return createJourneyIdByTask('meelo-portal', 'document-analysis').then(async (journeyId) => {
        this.journeyId = journeyId.id;
        this.$router.push(`${this.$route.path}?journeyId=${journeyId.id}`);
      });
    },
    handleIFrameEvent(e: any) {
      if (e.data === "OCR_DONE") {
        this.$emit("start-loading", this.documentType);
      }
    },
    startLoading() {
      this.$emit('start-loading', this.documentType);
    },
    endLoading() {
      this.$emit('end-loading');
    },
    translate,
    async selectUploadMode(uploadMode: 'IFRAME' | 'PHONE', docType: string) {
      this.documentType = docType
      if (!this.documentType) throw 'Unable to create Iframe without documentType'; // should never be there
      this.uploadMode = uploadMode;

      if (uploadMode === 'IFRAME') {
        if (this.journeyId === '') {
          await this.generateJourneyId();
        }
        const res = await createIframeUploadDocument(this.journeyId, this.documentType, uploadMode);
        this.iframeLink = res.data.link;
      }

    },
    async sendSms() {
      if (!this.documentType) throw 'Unable to create Iframe without documentType'; // should never be there
      await createIframeUploadDocument(this.journeyId, this.documentType, 'PHONE', this.phoneNumber);
      this.$emit("start-loading", this.documentType);
    }
  },
  created() {
    if (this.$route.query.journeyId && this.$route.query.journeyId.toString() !== '') {
      this.journeyId = this.$route.query.journeyId ? this.$route.query.journeyId.toString() : '' as string;
    }
    window.addEventListener("message", this.handleIFrameEvent)
  }
});
</script>
