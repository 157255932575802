import {
  ConfigType,
  transformData,
} from "@/utils/dataTransformer/dataTransformer";

const ESBusinessOwnerConfig: ConfigType = {
  birthName: {
    newLabel: "birthName",
    defaultValue: () => "",
  },
  lastName: {
    newLabel: "lastName",
    defaultValue: () => "",
  },
  name: {
    newLabel: "firstNames",
  },
  percentSharesHeld: {
    newLabel: "totalShareHolding",
  },
  directVotePower: {
    newLabel: "directVotePower",
  },
  registryDate: {
    newLabel: "registryDate",
  },
  shareHolderType : {
    newLabel: "directShareHolding",
    defaultValue : ({percentSharesHeld}: {percentSharesHeld: number}) => percentSharesHeld > 50
  }, 
  isNotSelectable: {
    newLabel: "isNotSelectable",
    defaultValue: () => true,
  },
};

export const ESBusinnessOwnerTransformer = transformData(ESBusinessOwnerConfig);
