<script setup lang="ts">
import { ref, onMounted } from 'vue';
import BackOfficeRulesTable from "@/components/backoffice/BackOfficeRulesTable.vue";
import BackOfficeSetsTable from "@/components/backoffice/BackOfficeSetsTable.vue";
import { translate } from '@/i18n';
import { getSets } from "@/apiCalls/backOffice/rulesEngine";

interface Set {
  id: number;
  name: string;
  rules: Rule[];
}

interface Rule {
  id: number;
  name: string;
  description: string;
}

const sets = ref<Set[]>([]);
const rules = ref<Rule[]>([]);

const updateSetSelected = async (set: Set) => {
  rules.value = set.rules;
};

const refreshSets = async () => {
  sets.value = await getSets(); // Assurez-vous que getSets() retourne le type approprié
};

onMounted(async () => {
  await refreshSets();
});

</script>

<template>
  <div class="sets">
    <div class="sets__explanation">
      {{ translate('BACKOFFICE.RULES_ENGINE.SETS.EXPLANATION') }}
    </div>

    <div class="sets__information">
      <img src="../../../public/images/information-white.png" class="sets__information__icon" alt="information"/>
      <div>{{ translate('BACKOFFICE.RULES_ENGINE.SETS.ADD_OR_DELETE_RULE_INFORMATION') }}</div>
    </div>
    <div class="sets__grid">
      
      <div>
        <span class="sets__grid__subtitle">{{ translate('BACKOFFICE.RULES_ENGINE.SETS.SETS_LIST') }}</span>
        <BackOfficeSetsTable v-if="sets.length!=0" :sets="sets"
          @update-set-selected="updateSetSelected"
          />
        <div class="sets__grid__empty-table" v-else>
          {{translate("BACKOFFICE.RULES_ENGINE.SETS.NO_SET")}}
        </div>
      </div>
      
      <div>
        <span class="sets__grid__subtitle">{{ translate('BACKOFFICE.RULES_ENGINE.SETS.SETS_RULES_LIST') }}</span>
        <BackOfficeRulesTable v-if="rules.length!=0" :detailed-information="false"  :rules="rules"
          @update-rule-selected="updateRuleSelected"
          />
        <div class="sets__grid__empty-table" v-else>
          {{translate("BACKOFFICE.RULES_ENGINE.SETS.SETS_RULES_EXPLANATION")}}
        </div>

      </div>
      
    </div>
    
    
  </div>
</template>
