<script lang="ts" setup>
import { snakeCase } from "lodash";

import { translate } from "@/i18n";

import ScoreCircleChartVue from "@/components/ScoreCircleChart.vue";
import PrintCard from "@/components/print/shared/PrintCard.vue";

defineProps<{
  title?: string;
  entries: Object;
  score: number;
  color: string;
}>();

const getTransKey = (key: string) => {
  return `PRINT.APPLICANT_OB.${snakeCase(key).toUpperCase()}`;
};
</script>

<template>
  <PrintCard :title="title || 'PRINT.APPLICANT_OB.TITLE'">
    <div class="print-applicant-ob-container">
      <div class="entries">
        <slot />
        <div class="entry" v-for="(value, key) in entries" :key="key">
          <p>
            <span>{{ translate(getTransKey(key)) }} : </span>
            {{ value }}
          </p>
        </div>
      </div>
      <ScoreCircleChartVue
        :circle-value="score"
        :circle-width="'75%'"
        :circleColor="color"
      />
    </div>
  </PrintCard>
</template>
