<template>
  <div class="LoginPage">
    <div class="LoginPage__left">
      <MeeloLogo class="LoginPage__left__logo"/>
      <a href="https://www.getmeelo.com/meelo-fintech-certifiee-b-corp/" target="_blank">
        <img class="logoB" :src="require('/public/images/bcorpLogo.png')" alt="LogoBCorp">
      </a>
      <GeneralCondition class="LoginPage__left__cgu"/>
    </div>
    <div class="LoginPage__right">
      <MeeloLogo class="LoginPage__right__logo" white/>
      <router-view class="LoginPage__right__form" white/>
      <GeneralCondition is-right class="LoginPage__right__cgu" white/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import GeneralCondition from '@/components/GeneralCondition.vue';
import {TextInputType} from '@/types/TextInputType';
import MeeloLogo from '@/components/Logo.vue';
import cookieMixin from '@/mixins/cookieMixin';
import TwoFactorAuthenticationSetupForm from "@/views/login/TwoFactorAuthenticationSetupForm.vue";

export default defineComponent({
  name: 'LoginPage',
  mixins: [cookieMixin],
  computed: {
    TwoFactorAuthenticationSetupForm() {
      return TwoFactorAuthenticationSetupForm
    },
    TextInputType() {
      return TextInputType;
    }
  },
  components: {
    MeeloLogo,
    GeneralCondition,
  },
  async mounted() {
    if (this.accessToken) {
      this.$router.push({name: 'HistoricalPage', replace: true});
    }
  }
});
</script>

