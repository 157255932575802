<template>
  <div class="meelo-carousel">
    <BIconCaretLeft v-on:click="prev"/>
      <img v-for="({score, url}, index) in images" :key="index" :src="url" :class="(index === currentIndex) ? 'active':''" :alt="url"/>
    <BIconCaretRight v-on:click="next"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {BIconCaretRight, BIconCaretLeft} from "bootstrap-icons-vue";

export default defineComponent({
  name: "Carousel",
  components: {BIconCaretRight, BIconCaretLeft},
  props: {
    images: {type: Object, required:true}
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  methods: {
    next(){
      this.currentIndex = Math.abs((this.currentIndex + 1) % this.images.length)
    },
    prev(){
      this.currentIndex = (this.currentIndex === 0) ? this.images.length - 1: this.currentIndex - 1;
    }
  },
  created() {

  }
});
</script>