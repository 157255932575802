<script setup lang="ts">
import {ref} from "vue";
import {useToast} from "vue-toast-notification";

import {requestPasswordResetEmail} from "@/apiCalls/passwordReset";
import {translate} from "@/i18n";
import {TextInputType} from "@/types/TextInputType";

import GeneralCondition from "@/components/GeneralCondition.vue";
import MeeloLogo from "@/components/Logo.vue";
import Button from "@/components/button/Button.vue";
import TextInput from "@/components/input/TextInput.vue";

const toast = useToast();

const email = ref("");
const inProgress = ref(false);

const submit = async () => {
  try {
    inProgress.value = true;
    await requestPasswordResetEmail(email.value);
    email.value = "";
    toast.clear();
    toast.success(translate("RESET_PASSWORD_PAGE.SUCCESS_MESSAGE"));
    inProgress.value = false;
  } catch (error) {
    toast.clear();
    toast.error(translate("RESET_PASSWORD_PAGE.EMAIL_NOT_SENT"));
    inProgress.value = false;
  }
};
</script>

<template>
  <div class="LoginPage">
    <div class="LoginPage__left">
      <a :href="'/login'" class="LoginPage__left__link">
        <MeeloLogo class="LoginPage__left__logo"/>
      </a>
      <a href="https://www.getmeelo.com/meelo-fintech-certifiee-b-corp/" target="_blank">
        <img class="logoB" src="/images/bcorpLogo.png" alt="LogoBCorp"/>
      </a>
      <GeneralCondition class="LoginPage__left__cgu"/>
    </div>
    <div class="LoginPage__right">
      <MeeloLogo class="LoginPage__right__logo" white/>
      <form @submit.prevent="submit" class="password-reset-form">
        <h2>{{ translate("RESET_PASSWORD_PAGE.FORGOT_PASSWORD") }}</h2>
        <TextInput
            :type="TextInputType.EMAIL"
            :label="translate('RESET_PASSWORD_PAGE.EMAIL')"
            v-model="email"
            required
            color="light"
        />
        <Button
            type="submit"
            class="submit-button"
            :label="translate('RESET_PASSWORD_PAGE.SEND_EMAIL')"
            :loading="inProgress"
        />
      </form>
      <GeneralCondition is-right class="LoginPage__right__cgu" white/>
    </div>
  </div>
</template>
