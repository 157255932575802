<script setup lang="ts">
import {computed, ref} from "vue";

import {translate} from "@/i18n";
import {ScoreWarningKOColors} from "@/mixins/templateMixin";

const props = withDefaults(defineProps<{
  circleColor: string,
  circleTitle?: string,
  circleValue: number,
  circleValueWithoutPercentage: string,
  circleWidth: string,
  tooltip?: string,
  typeScore?: string,
  helperTextDisplay: boolean,
  marginCircle: string
}>(), {
  marginCircle: 'auto',
  circleValueWithoutPercentage: '',
  circleTitle: '',
  typeScore: ''
});

const emit = defineEmits(['asText']);

const margin = ref(props.marginCircle);

const strokeDashArray = (typeScore: string) => {
  if (typeScore === "default_probability") {
    return `${props.circleValue * 15},100`;
  }
  return `${props.circleValue},100`;
};

const showHelperText = computed(() => {
  const _asText = Object.values(ScoreWarningKOColors).includes(props.circleColor as any);
  _asText ? emit('asText', true) : emit('asText', false);
  // Si une couleur de warning correspond à la couleur du props.circleColor, on affiche le helper text
  return props.helperTextDisplay ? _asText : false;
});
</script>

<template>
  <div class="score-circle-chart">
    <div class="single-chart" :style="'width: ' + circleWidth" :title="tooltip">
      <svg class="circle-chart" viewBox="0 0 36 36">
        <path
          class="circle-bg"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          :stroke="circleColor"
          :stroke-dasharray="strokeDashArray(typeScore)"
          d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text v-if="circleValueWithoutPercentage!==''" x="18" y="20.35" class="withoutPercentage">
          {{ circleValueWithoutPercentage }}
        </text>
        <text v-else x="18" y="20.35" class="percentage">{{ circleValue }}%</text>
        <text x="18" y="25" class="circle-title">{{ circleTitle }}</text>
      </svg>
    </div>
    <span v-if="showHelperText" class="score-helper-text no-print">{{ translate("SCORE.HELPER_TEXT") }}</span>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.single-chart {
  width: 100%;
  margin: 0.5rem v-bind(margin) 1rem;
}

.score-helper-text {
  font-size: $fontSizeTwelveSize;
  color: $grey;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: $dataPrinterBackgroundColor;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  //animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.percentage, .withoutPercentage {
  font-weight: 400;
  fill: #666;
  font-family: sans-serif;
  font-size: $fontSizeNineSize;
  text-anchor: middle;
}

.withoutPercentage {
  font-size: small;
}

.circle-title {
  font-size: 0.25em;
  text-anchor: middle;
}

.circle-chart {
  width: 100%;
  justify-self: center;
}

.score-b2b, .score-open-banking-recovery {
  margin: auto;
}

.score-circle-chart {
  text-align: center;
}
</style>
