import { CompanyAlert, GlobalAlert, PersonalAlert } from "@/types/Alert";
import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const getMaintenanceAlerts = async () => {
  return await api
    .get<GlobalAlert[] | PersonalAlert[] | CompanyAlert[]>(
      `${envConfig.ALERTS_ROUTE}/v1/alerts/maintenance`
    )
    .then((response) => response);
};
