import {ReasonCode} from "@/utils/checkIbanB2B";

export const detailedIbanCheck = [
  {
    result: '001',
    icon: 'success-icon.png',
    translationKey: 'IBAN_EXISTS'
  },
  {
    result: '010',
    icon: 'failed-icon.png',
    translationKey: 'IBAN_NOT_EXISTS_ANYMORE'
  },
  {
    result: '030',
    icon: 'failed-icon.png',
    translationKey: 'IBAN_NOT_EXISTS'
  },
  {
    result: '040',
    icon: 'warning-icon.png',
    translationKey: 'IBAN_CAN_BE_VERIFIED'
  }
];

export const detailedTypeCheck = [
  {
    result: '001',
    icon: 'success-icon.png',
    translationKey: 'HOLDER_TYPE_CORRECT'
  },
  {
    result: '000',
    icon: 'failed-icon.png',
    translationKey: 'HOLDER_TYPE_INCORRECT'
  }
];

export const detailedRegistrationNumberCheck = [
  {
    result: '001',
    icon: 'success-icon.png',
    translationKey: 'SIREN_MATCH'
  },
  {
    result: '000',
    icon: 'failed-icon.png',
    translationKey: 'SIREN_NOT_MATCH'
  },
  {
    result: '020',
    icon: 'warning-icon.png',
    translationKey: 'SIREN_CANNOT_CONTROL'
  }
];

export const detailedNameCheck = [
  {
    condition: (result: number) => result >= 70,
    icon: 'success-icon.png',
    translationKey: 'MATCH'
  },
  {
    condition: (result: number) => result < 40,
    icon: 'failed-icon.png',
    translationKey: 'MATCH'
  },
  {
    condition: (result: number) => result >= 40 && result < 70,
    icon: 'warning-icon.png',
    translationKey: 'MATCH'
  }
];

export const detailedCheckReasonCode = [
  {
    code: ReasonCode.AC01,
    icon: 'warning-icon.png',
    translationKey: 'IncorrectAccountNumber'
  },
  {
    code: ReasonCode.BE05,
    icon: 'warning-icon.png',
    translationKey: 'UnrecognisedInitiatingParty'
  },
  {
    code: ReasonCode.BE15,
    icon: 'warning-icon.png',
    translationKey: 'InvalidIdentificationCode'
  },
  {
    code: ReasonCode.BE21,
    icon: 'warning-icon.png',
    translationKey: 'MissingName'
  },
  {
    code: ReasonCode.FF01,
    icon: 'warning-icon.png',
    translationKey: 'InvalidData'
  },
  {
    code: ReasonCode.RC11,
    icon: 'warning-icon.png',
    translationKey: 'BICInvalid'
  }
];