<script setup lang="ts">
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { computed, onMounted, ref } from "vue";
import { Line } from "vue-chartjs";

import { getPrintImgSrc } from "@/utils/printChartUtils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const props = withDefaults(
  defineProps<{
    backgroundColorChart: string;
    borderColorChart: string;
    labelChart: string;
    dataChart: number[];
    abscissaChart: string[];
  }>(),
  {
    backgroundColorChart: "#99A4BF",
    borderColorChart: "#99A4BF",
    labelChart: "",
  }
);

const lineChartClassName = "ob-linechart-img-print-src";
const options = {
  responsive: true,
  // Animation was disabled because it was causing the chart to not be printed
  animation: false,
};

const lineChartElement = ref();
const imgElement = ref<HTMLImageElement>();

const data = computed(() => {
  return {
    labels: props.abscissaChart,
    datasets: [
      {
        label: props.labelChart,
        backgroundColor: props.backgroundColorChart,
        borderColor: props.borderColorChart,
        borderWidth: 1,
        data: props.dataChart,
      },
    ],
  };
});

onMounted(() => {
  const timer = setInterval(() => {
    if (lineChartElement.value && lineChartElement.value.chart) {
      const canvas = lineChartElement.value.chart.canvas;
      canvas.classList.add(lineChartClassName);
      if (imgElement.value) {
        const [imgSrc] = getPrintImgSrc(`.${lineChartClassName}`);
        imgElement.value.src = imgSrc;
        clearInterval(timer);
      }
    }
  }, 250);
});
</script>

<template>
  <Line :data="data" ref="lineChartElement" :options="options" />
  <img ref="imgElement" class="ob-linechart-img-print to-print" />
</template>

<style scoped lang="scss"></style>
