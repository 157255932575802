<script setup lang="ts">
import {Pie} from "vue-chartjs";
import {computed, ref} from "vue";
import {ArcElement, CategoryScale, Chart as ChartJS, Legend, Title, Tooltip} from "chart.js";

ChartJS.register(
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const props = withDefaults(defineProps<{
  backgroundColorChart: string;
  labelChart: string;
  dataChart: number[];
  abscissaChart: string[];
}>(), {
  backgroundColorChart: "#99A4BF",
  labelChart: "",
});

const delayed = ref(false);
const barChartElement = ref();
const imgElement = ref<HTMLImageElement>();

const data = computed(() => {
  return {
    labels: props.abscissaChart,
    datasets: [
      {
        label: props.labelChart,
        backgroundColor: props.backgroundColorChart,
        data: props.dataChart,
      },
    ],
  }
});

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom'
    }
  }
};
</script>

<template>
  <Pie :data="data" :options="options"/>
</template>
