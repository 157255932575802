<script setup lang="ts">
import MapChart from "@/components/chart/MapChart.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import {computed, ref} from "vue";
import {translate} from "@/i18n";
import * as echarts from "echarts";
import Button from "@/components/button/Button.vue";

const props = withDefaults(
    defineProps<{
      option: object,
      formatterFunction: Function //need to pass the function in props
    }>(), {}
);

const emit = defineEmits(['send-data-type']);

const open = defineModel('open', {default: false, type: Boolean});
const zoom = ref(2);
const mapId = ref(0);

let options = ref(JSON.parse(JSON.stringify(props.option)));

const option = computed(() => {
  mapId.value = new Date().getTime();
  zoom.value = options.value.series[0].data.length > 50 ? 0.5 : 2;
  options.value.series[0].zoom = options.value.series[0].data.length > 50 ? '0.5' : '2'; //minimal zoom when lot of data
  options.value.series[0].width = '80rem';
  options.value.series[0].height = '45rem';
  return options.value;
});

const sendSiren = (params: echarts.ECElementEvent) => {
  emit('send-data-type', params);
}

//if the user zoomed with mouse -> value of zoom can be 2.337545147
//so when zooming with buttons, take the rounded value and add 0.5 => zoom will be at 2.5 in this case
const getValue = (value: number) => {
  const decimalPart = value - Math.trunc(value);
  if (decimalPart === 0.5 || decimalPart === 0.0) {
    return value; //if the zoom is already at X.0 or X.5, do not round
  }
  return Math.round(value); //round up/down the value when clicking on button (if the user zoomed with mouse before)
}

const zoomMore = () => {
  if (zoom.value < 3) zoom.value = getValue(zoom.value) + 0.5;
};

const zoomLess = () => {
  if (zoom.value > 0.5) zoom.value = getValue(zoom.value) - 0.5;
}

//if the user zoomed with mouse, it is MapChart that will know, so need to update the zoom of the box
const zoomUpdated = (value: number) => {
  zoom.value = value;
}
</script>

<template>
  <CustomModal v-model:open="open" :display-close-button="true" class="custom-modal-cartography"
               id="custom-modal-cartography" custom-class="custom-modal-cartography-max-width">
    <ResultCard :title="translate('CARTOGRAPHY.COMPANY_CARTOGRAPHY')">
      <div class="custom-modal-cartography__content">
        <div class="custom-modal-cartography__content__chart">
          <MapChart :key="mapId" v-model:zoom="zoom" :option="option"
                    @send-data-type="sendSiren" class="cartography-chart"
                    :formatter-function="formatterFunction" @zoom-update="zoomUpdated"/>
        </div>
        <div class="custom-modal-cartography__content__block-buttons">
          <Button class="button" @click="zoomLess" :label="'-'"/>
          <Button class="button" @click="zoomMore" :label="'+'"/>
        </div>
      </div>
    </ResultCard>
  </CustomModal>
</template>