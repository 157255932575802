<script setup lang="ts">
const props = defineProps({
    hasBeenFound: {
        type: Boolean,
        default: false
    },
    bonhommeName: String
})
</script>

<template>
    <div class="x-mas-welcome-card" :class="props.hasBeenFound ? 'is-active' : ''">
        <img v-if="props.hasBeenFound" src="../../../public/images/x-mas/bonhomme-1.png" alt="">
        <img v-else src="../../../public/images/x-mas/bonhomme-2.png" alt="">
        <span>{{ props.bonhommeName }}</span>
        <div></div>
    </div>
</template>

