import {values} from "lodash";

export enum AvailableScoreBatch {
  WEB_SCORE_B2C_V3 = 'WEB_SCORE_B2C_V3',
  WEB_SCORE_B2B_V2 = 'WEB_SCORE_B2B_V2',
  SMB_SCORE_V1 = 'SMB_SCORE_V1',
  E_PAYMENT_RISK_SCORE_V3 = 'E_PAYMENT_RISK_SCORE_V3',
  ENERGY_SCORE_V2 = 'ENERGY_SCORE_V2',
  E_REPUTATION_SCORE_V1 = 'E_REPUTATION_SCORE_V1',
  ALTARES_SCORE = "ALTARES_SCORE",
  ELLISPHERE_SCORE = "ELLISPHERE_SCORE",
  CREDIT_SAFE_SCORE = "CREDIT_SAFE_SCORE"
}

export const availableScoreBatchValues = values(AvailableScoreBatch);