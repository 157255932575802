<template>
  <table class="table-information">
    <tbody class="table-information-body">
      <tr v-if="resultLcbft">
        <td><label-value-printer class="table-information-body-input" :label="translate('AMLCFT.TITLE')"/></td>
        <td class="table-information-body-result">{{ resultLcbft }}</td>
      </tr>
      <tr v-if="resultCompany && market_type === 'b2b'">
        <td><label-value-printer class="table-information-body-input" :label="translate('LINK_COMPANY')" :value="typeCompany.toString()"/></td>
        <td class="table-information-body-result">{{ resultCompany }}</td>
      </tr>
      <tr v-if="resultIdentityCheck !== ''">
        <td><label-value-printer class="table-information-body-input" :label="translate('IDENTITY_CONSISTENCY')"/></td>
        <td class="table-information-body-result">{{ resultIdentityCheck }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import {translate} from '@/i18n';

export default defineComponent({
  name: "TableInformation",
  methods: {translate},
  components: {LabelValuePrinter},
  props: {
    resultLcbft: {type: String, default: ''},
    resultCompany: {type: String, default: ''},
    typeCompany: {type: Array, default: {}},
    resultIdentityCheck: {type: String, default: ''},
    market_type: {type: String}
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.table-information{

  border-collapse: separate;
  font-size: $fontSize;

  &-body {
    &-result {
      text-align: center;
      border: solid 0;
      border-radius: 10px;
      background-color: #FFFFFF;
    }

    &-input {
      margin: 0;
    }
  }
}
</style>
