import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bfe0780a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detailed-score_first-block" }
const _hoisted_2 = { class: "detailed-score_social-networks" }
const _hoisted_3 = { class: "detailed-score_social-networks-label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "detailed-score_social-networks-label" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "detailed-score_second-block" }
const _hoisted_8 = { class: "detailed-score_second-block-address_research" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "block-amlcft" }
const _hoisted_11 = {
  key: 0,
  class: "block-amlcft-content"
}
const _hoisted_12 = { class: "block-amlcft-title" }
const _hoisted_13 = { class: "block-amlcft-alert" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "block-amlcft-title" }
const _hoisted_16 = { class: "block-amlcft-alert" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { class: "block-amlcft-level" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "block-amlcft-level" }
const _hoisted_21 = { key: 2 }
const _hoisted_22 = { class: "block-amlcft-level" }
const _hoisted_23 = { key: 3 }
const _hoisted_24 = { class: "block-amlcft-level" }
const _hoisted_25 = {
  key: 1,
  class: "block-amlcft-adverse_media"
}
const _hoisted_26 = { class: "block-amlcft-title" }
const _hoisted_27 = { class: "block-amlcft-alert" }
const _hoisted_28 = { class: "block-amlcft-title" }
const _hoisted_29 = { class: "block-amlcft-types" }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { class: "block-amlcft-title" }
const _hoisted_32 = ["href"]
const _hoisted_33 = ["href"]
const _hoisted_34 = ["href"]
const _hoisted_35 = ["href"]
const _hoisted_36 = {
  key: 1,
  class: "block-amlcft-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_verification_result = _resolveComponent("verification-result")!
  const _component_label_value_printer = _resolveComponent("label-value-printer")!
  const _component_result_card = _resolveComponent("result-card")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Carousel = _resolveComponent("Carousel")!
  const _component_content_card = _resolveComponent("content-card")!

  return (_openBlock(), _createBlock(_component_content_card, {
    title: _ctx.translate('DETAILED_SCORE'),
    class: "detailed-score"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_result_card, {
          title: _ctx.translate('PHONE')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_verification_result, {
              value: _ctx.scoreSMB.contact.phoneExists,
              label: _ctx.translate('VERIFIED'),
              type: _ctx.translate('PHONE'),
              tooltip: _ctx.translate('TOOLTIP.MOBILE_PHONE_VERIFICATION'),
              class: "verification"
            }, null, 8, ["value", "label", "type", "tooltip"]),
            _createVNode(_component_verification_result, {
              value: _ctx.scoreSMB.contact.phoneName,
              label: _ctx.translate('LINKED'),
              type: _ctx.translate('PHONE'),
              tooltip: _ctx.translate('TOOLTIP.MOBILE_PHONE_LINK'),
              "is-warning": true
            }, null, 8, ["value", "label", "type", "tooltip"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('FIRST_SEEN_DATE'),
              value: _ctx.scoreSMB.contact && _ctx.scoreSMB.contact.phoneFirstSeenDate ? new Date(_ctx.scoreSMB.contact.phoneFirstSeenDate).toLocaleDateString() : '-'
            }, null, 8, ["label", "value"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('TELEPHONE.NUMBER'),
              value: _ctx.customerProfile.identity.mobilePhoneNumber
            }, null, 8, ["label", "value"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('TELEPHONE.LINETYPE'),
              value: _ctx.scoreSMB.contact.phoneLinetype
            }, null, 8, ["label", "value"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('TELEPHONE.GEOLOCATION'),
              value: _ctx.scoreSMB.contact.phoneGeolcation
            }, null, 8, ["label", "value"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('TELEPHONE.CARRIER'),
              value: _ctx.scoreSMB.contact.phoneCarrier
            }, null, 8, ["label", "value"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('TELEPHONE.ORIGINAL_CARRIER'),
              value: _ctx.scoreSMB.contact.phoneOriginalCarrier
            }, null, 8, ["label", "value"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_result_card, {
          title: _ctx.translate('EMAIL')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_verification_result, {
              value: _ctx.scoreSMB.contact.emailExists,
              label: _ctx.translate('VERIFIED'),
              type: _ctx.translate('EMAIL'),
              tooltip: _ctx.translate('TOOLTIP.EMAIL_VERIFICATION'),
              class: "verification"
            }, null, 8, ["value", "label", "type", "tooltip"]),
            _createVNode(_component_verification_result, {
              value: _ctx.scoreSMB.contact.emailName,
              label: _ctx.translate('LINKED'),
              type: _ctx.translate('EMAIL'),
              tooltip: _ctx.translate('TOOLTIP.EMAIL_LINK'),
              "is-warning": true
            }, null, 8, ["value", "label", "type", "tooltip"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('FIRST_SEEN_DATE'),
              value: _ctx.scoreSMB.contact && _ctx.scoreSMB.contact.emailFirstSeenDate ? new Date(_ctx.scoreSMB.contact.emailFirstSeenDate).toLocaleDateString() : '-'
            }, null, 8, ["label", "value"]),
            _createVNode(_component_label_value_printer, {
              label: _ctx.translate('EMAIL'),
              value: _ctx.customerProfile.identity.email
            }, null, 8, ["label", "value"])
          ]),
          _: 1
        }, 8, ["title"]),
        _createVNode(_component_result_card, {
          class: "social-networks",
          title: _ctx.translate('SOCIAL_NETWORKS.TITLE')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.translate('SOCIAL_NETWORKS.PHONE')) + " : ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.scoreSMB.socialNetworks.phone), 1)
              ]),
              (_ctx.scoreSMB.socialNetworks.phoneList.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreSMB.socialNetworks.phoneList, (phone_network, key) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: key,
                        class: "detailed-score_social-networks-result"
                      }, _toDisplayString(phone_network), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate('SOCIAL_NETWORKS.MAIL')) + " : ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.scoreSMB.socialNetworks.email), 1)
              ]),
              (_ctx.scoreSMB.socialNetworks.emailList.length > 0)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.scoreSMB.socialNetworks.emailList, (mail_network, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: "detailed-score_social-networks-result"
                      }, _toDisplayString(mail_network), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["title"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", null, [
          (_ctx.address.length > 2)
            ? (_openBlock(), _createBlock(_component_result_card, {
                key: 0,
                class: "detailed-score_second-block-address special-block",
                title: _ctx.translate('ADDRESS.TITLE_POSTAL')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_label_value_printer, {
                    label: _ctx.translate('ADDRESS.TITLE'),
                    value: _ctx.address
                  }, null, 8, ["label", "value"]),
                  _createVNode(_component_label_value_printer, {
                    label: _ctx.translate('SOCIAL_NETWORKS.ADDRESS_LINKED'),
                    value: _ctx.displayResult(_ctx.scoreSMB.contact.addressName)
                  }, null, 8, ["label", "value"]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("a", {
                      href: `https://www.google.fr/maps/search/${_ctx.address.replaceAll(' ', '+')}`,
                      target: "_blank"
                    }, [
                      _createVNode(_component_Button, {
                        label: _ctx.translate('BUTTONS.VERIFY_ADDRESS')
                      }, null, 8, ["label"])
                    ], 8, _hoisted_9)
                  ])
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true),
          (_ctx.searchImage.length > 0)
            ? (_openBlock(), _createBlock(_component_result_card, {
                key: 1,
                title: _ctx.translate('SOCIAL_FACE_MATCHING.TITLE'),
                class: "special-block"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_label_value_printer, {
                    class: "facematching-images border",
                    label: _ctx.translate('SOCIAL_FACE_MATCHING.CATCHED_IMAGES'),
                    value: _ctx.searchImage.length,
                    "tooltip-title": _ctx.translate('TOOLTIP.SOCIAL_FACE_MATCHING')
                  }, null, 8, ["label", "value", "tooltip-title"]),
                  _createVNode(_component_Carousel, { images: _ctx.searchImage }, null, 8, ["images"])
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          ((_ctx.customerProfile.identity.birthDate !== '') && (_ctx.amlcft !== undefined))
            ? (_openBlock(), _createBlock(_component_result_card, {
                key: 0,
                title: _ctx.translate('AMLCFT.TITLE'),
                class: "special-block"
              }, {
                default: _withCtx(() => [
                  (_ctx.amlcft.amlTypes.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.translate('AMLCFT.SANCTIONED')) + " :", 1),
                          _createElementVNode("div", null, [
                            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.translate('AMLCFT.SANCTIONED_ALERT')), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.translate('AMLCFT.SANCTIONED_EXPLANATIONS')), 1)
                          ])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amlcft.amlTypes, (type, index) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (type === 'pep')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.translate('AMLCFT.PPE')) + " :", 1),
                                  _createElementVNode("div", null, [
                                    _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.translate('AMLCFT.PPE_ALERT')), 1),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amlcft.amlTypesDetails.pep, (pep, key) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
                                        (pep === 'class-1')
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                                              _createElementVNode("span", _hoisted_18, _toDisplayString(`${_ctx.translate('AMLCFT.LEVEL')} 1 - `), 1),
                                              _createTextVNode(" " + _toDisplayString(_ctx.translate('AMLCFT.CLASS_1')), 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        (pep === 'class-2')
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_19, [
                                              _createElementVNode("span", _hoisted_20, _toDisplayString(`${_ctx.translate('AMLCFT.LEVEL')} 2 - `), 1),
                                              _createTextVNode(_toDisplayString(_ctx.translate('AMLCFT.CLASS_2')), 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        (pep === 'class-3')
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_21, [
                                              _createElementVNode("span", _hoisted_22, _toDisplayString(`${_ctx.translate('AMLCFT.LEVEL')} 3 - `), 1),
                                              _createTextVNode(_toDisplayString(_ctx.translate('AMLCFT.CLASS_3')), 1)
                                            ]))
                                          : _createCommentVNode("", true),
                                        (pep === 'class-4')
                                          ? (_openBlock(), _createElementBlock("p", _hoisted_23, [
                                              _createElementVNode("span", _hoisted_24, _toDisplayString(`${_ctx.translate('AMLCFT.LEVEL')} 4 - `), 1),
                                              _createTextVNode(_toDisplayString(_ctx.translate('AMLCFT.CLASS_4')), 1)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ], 64))
                                    }), 128))
                                  ])
                                ]))
                              : _createCommentVNode("", true),
                            (type === 'adverse_media')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                                  _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.translate('AMLCFT.ADVERSE_MEDIA')), 1),
                                  _createElementVNode("div", null, [
                                    _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.translate('AMLCFT.ADVERSE_MEDIA_ALERT')), 1),
                                    _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.translate('AMLCFT.ADVERSE_MEDIA_TYPE')), 1),
                                    _createElementVNode("div", _hoisted_29, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.amlcft.amlTypesDetails.adverse_media, (adverse_media, index) => {
                                        return (_openBlock(), _createElementBlock("span", {
                                          key: index,
                                          class: "block-amlcft-adverse_details"
                                        }, _toDisplayString(_ctx.translate(`AMLCFT.ADVERSE_MEDIA_DETAILS.${adverse_media}`)), 1))
                                      }), 128))
                                    ])
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ], 64))
                        }), 128)),
                        (_ctx.amlcft.links.length > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.translate('AMLCFT.LINKS')) + " :", 1),
                              _createElementVNode("ul", null, [
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: _ctx.amlcft.links[0],
                                    target: "_blank"
                                  }, _toDisplayString(_ctx.amlcft.links[0]), 9, _hoisted_32)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: _ctx.amlcft.links[1],
                                    target: "_blank"
                                  }, _toDisplayString(_ctx.amlcft.links[1]), 9, _hoisted_33)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: _ctx.amlcft.links[2],
                                    target: "_blank"
                                  }, _toDisplayString(_ctx.amlcft.links[2]), 9, _hoisted_34)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    href: _ctx.amlcft.links[3],
                                    target: "_blank"
                                  }, _toDisplayString(_ctx.amlcft.links[3]), 9, _hoisted_35)
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_36, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.translate('AMLCFT.NO_RESULT')), 1)
                      ]))
                ]),
                _: 1
              }, 8, ["title"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}