<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import BoxOutputWarningListRegistrationNumber from "@/components/box-output/BoxOutputWarningListRegistrationNumber.vue";
import {isArray, isEmpty, isNull, size} from "lodash";
import {computed, reactive} from "vue";
import {useRoute} from "vue-router";
import {formatSiren, getFormattedRegistrationNumber} from "@/utils/formatRegistrationNumber";

interface CheckEmployerData {
  isActive: boolean;
  registrationNumber: string;
  data: { status: string };
  corporateName: string;
}

const props = defineProps<{
  checkEmployerData: {
    data: boolean;
    registrationNumber: string;
    corporateName: string;
    isActive: boolean;
    type: CheckEmployerData, required: true
  },
  checkWarningList: { type: Array<Array<string>>, required: true },
  checkProcedure: { type: Array<{ submissionDate: string }>, required: true },
  noDataAvailable?: { type: boolean },
  isLoading?: { type: boolean }
}>();

const route = useRoute();

const hasSirenInWarningList = (): boolean => {
  return !!props.checkWarningList?.find((warning: Array<string>) => {
    return isArray(warning) && (warning.includes("SIREN") || warning.includes("REGISTRATION_NUMBER"));
  });

};

const getRadiationStatus = () => {
  if (isNull(props.checkEmployerData?.isActive)) {
    return {icon: 'null-icon.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.NO_INFO'};
  } else if (props.checkEmployerData?.isActive) {
    return {icon: 'empty-circle.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.FALSE_RADIATION'};
  } else {
    return {icon: 'failed-icon.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.TRUE_RADIATION'};
  }
};

const getProceduralStatus = () => {
  const threeYearsAgo = new Date();

  threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);
  if (isNull(props.checkProcedure) || props.checkProcedure === undefined) {
    return {icon: 'null-icon.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.NO_INFO'};
  } else {
    if (isEmpty(props.checkProcedure)) {
      return {icon: 'empty-circle.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.COLLECTIVE_PROCEEDING.NO'};
    }
    let recentProcedure;
    if (isArray(props.checkProcedure)) {
      recentProcedure = props.checkProcedure.find(procedure => {
        const submissionDate = new Date(procedure.submissionDate);
        return submissionDate > threeYearsAgo;
      });
    }
    if (recentProcedure) {
      return {icon: 'failed-icon.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.COLLECTIVE_PROCEEDING.RECENT'};
    } else if (size(props.checkProcedure) === 1) {
      return {icon: 'warning-icon.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.COLLECTIVE_PROCEEDING.MORE_THAN_ONE'};
    }
    return {icon: 'empty-circle.png', pictureValue: 'COMPANY.CHECK_EMPLOYER.COLLECTIVE_PROCEEDING.NO'};
  }

};

const formattedRegistrationNumber = computed(() => {
  if (props.checkEmployerData?.registrationNumber.length === 10) {
    return formatSiren('BE', props.checkEmployerData?.registrationNumber);
  } else {
    // then it's a SIREN
    return getFormattedRegistrationNumber(props.checkEmployerData?.registrationNumber);
  }
});

const isAvailableData = computed(() => {
  if (props.checkEmployerData === undefined && props.noDataAvailable === undefined) {
    return true;
  } else {
    if (props.checkEmployerData.data) {
      return props.checkEmployerData.data.status === 'FAILED';
    }
  }
  return !!props.noDataAvailable;
});

</script>

<template>
  <ContentCard class="check-employer-card" :title="translate('EMPLOYER_VERIFICATION')">
    <div v-if="isAvailableData" class="error-message">
      <span>{{ translate('ERRORS.NO_DATA') }}</span>
    </div>
    <div v-else class="check-employer-container">
      <LabelValuePrinter :label="translate('COMPANY.CORPORATE_NAME')"
                         :value="props.checkEmployerData?.corporateName"
                         :copyToClipboard="true" class="value-printer"
      />
      <LabelValuePrinter :label="translate('COMPANY.SIREN')"
                         :value="formattedRegistrationNumber"
                         :copyToClipboard="true" class="value-printer"
      />
      <LabelValuePrinter :label="translate('COMPANY.RADIATION')" :is-picture="true"
                         :picture-value="` ${translate(getRadiationStatus().pictureValue)}`"
                         class="value-printer" :value="getRadiationStatus().icon"
      />
      <LabelValuePrinter :label="translate('COMPANY.COLLECTIVE_PROCEDURE')" :is-picture="true"
                         :picture-value="` ${translate(getProceduralStatus().pictureValue)}`"
                         class="value-printer" :value="getProceduralStatus().icon"
      />
      <BoxOutputWarningListRegistrationNumber
          class="warning-list" :siren-warning-list="hasSirenInWarningList()" :is-loading="isLoading"/>
    </div>
  </ContentCard>
</template>