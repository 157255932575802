import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {CustomerProfile, CustomerProfileV3} from "@/types/CustomerProfile";
import {CompanyProfile} from "@/types/CompanyProfile";
import {Steps} from "@/types/Steps";
import {GreyList} from "@/types/GreyList";
import {ProductCgi} from "@/types/Product";

const FRAUD_B2C_FIELDS = [{
  "domain": "SCORE_FRAUD",
  "dtoName": "FRAUD_SCORE_B2C",
  "fieldName": "SCORE",
  "label": "score_fraud"
},
  {
    "domain": "SCORE_FRAUD",
    "dtoName": "FRAUD_SCORE_B2C",
    "fieldName": "CAUSES",
    "label": "causes_fraud"
  }] as const;

const SOCIAL_FM_FIELDS = [{
  "domain": "SOCIAL_FACE_MATCHING",
  "dtoName": "SOCIAL_FACE_MATCHING",
  "fieldName": "PROFILES",
  "label": "profiles"
}] as const;

const AMLCFT_FIELDS = [{"domain": "AMLCFT", "dtoName": "AMLCFT", "fieldName": "MATCHES", "label": "matches"}] as const;

const RISK_FIELDS = [{"domain": "SCORE_RISK", "dtoName": "RISK_SCORE_B2B", "fieldName": "SCORE", "label": "score_risk"},
  {
    "domain": "SCORE_RISK",
    "dtoName": "RISK_SCORE_B2B",
    "fieldName": "SCORE_SECTOR_PERCENT",
    "label": "score_sector"
  },
  {
    "domain": "SCORE_RISK",
    "dtoName": "RISK_SCORE_B2B",
    "fieldName": "DEFAULT_PROBABILITY",
    "label": "default_probability"
  },
  {
    "domain": "SCORE_RISK",
    "dtoName": "RISK_SCORE_B2B",
    "fieldName": "RECOMMENDED_OUTSTANDING",
    "label": "recommended_outstanding"
  },
  {
    "domain": "SCORE_RISK",
    "dtoName": "RISK_SCORE_B2B",
    "fieldName": "LAST_UPDATE",
    "label": "last_update"
  },
  {
    "domain": "SCORE",
    "dtoName": "ALTARES_SCORE",
    "fieldName": "SCORE",
    "label": "score_altares"
  },
  {
    "domain": "SCORE",
    "dtoName": "ALTARES_SCORE",
    "fieldName": "CAUSES",
    "label": "causes_altares"
  }
] as const;

const INPI_FIELDS = [{"domain": "INPI", "dtoName": "INPI", "fieldName": "SIREN", "label": "siren"},
  {"domain": "INPI", "dtoName": "INPI", "fieldName": "COMPANY_INFO", "label": "company_info"},
  {"domain": "INPI", "dtoName": "INPI", "fieldName": "BUSINESS_OWNERS", "label": "business_owners"},
  {"domain": "INPI", "dtoName": "INPI", "fieldName": "REPRESENTATIVES", "label": "representatives"},
  {"domain": "INPI", "dtoName": "INPI", "fieldName": "ESTABLISHEMENTS", "label": "establishments"},
  {"domain": "INPI", "dtoName": "INPI", "fieldName": "TAX_REPORTS", "label": "tax_reports"}] as const;

const TAX_REPORT_ANALYSES_FIELDS = [{
  "domain": "CHECK_COMPANY",
  "dtoName": "FINANCIAL_JSON",
  "fieldName": "GLOBAL",
  "label": "tax_report_analysis"
}] as const;

const FRAUD_B2B_FIELDS = [{
  "domain": "SCORE",
  "dtoName": "WEB_SCORE_B2B",
  "fieldName": "SCORE",
  "label": "score_b2b"
},
  {
    "domain": "SCORE",
    "dtoName": "WEB_SCORE_B2B",
    "fieldName": "CAUSES",
    "label": "causes_b2b"
  }] as const;

const CHECK_IBAN_FIELDS = [{
  "domain": "CHECK_IBAN",
  "dtoName": "PERSONAL_IBAN",
  "fieldName": "GLOBAL",
  "label": "check_iban_personal"
},
  {
    "domain": "CHECK_IBAN",
    "dtoName": "COMPANY_IBAN",
    "fieldName": "GLOBAL",
    "label": "check_iban_company"
  }] as const;

const SCORE_SMB_FIELDS = [{
  "domain": "SCORE",
  "dtoName": "SMB_SCORE",
  "fieldName": "SCORE",
  "label": "score_smb"
},
  {
    "domain": "SCORE",
    "dtoName": "SMB_SCORE",
    "fieldName": "CAUSES",
    "label": "causes_smb"
  }] as const;

const E_REPUTATION_FIELDS = [{
  "domain": "SCORE",
  "dtoName": "E_REPUTATION_SCORE",
  "fieldName": "CAUSES",
  "label": "e_reputation"
},
  {
    "domain": "SCORE",
    "dtoName": "E_REPUTATION_SCORE",
    "fieldName": "SCORE",
    "label": "e_reputation_score"
  }
] as const;

const DOCUMENT_FIELDS = [
  {"domain": "OCR_RESULT", "dtoName": "OCR_RESULT_PASSPORT", "fieldName": "GLOBAL", "label": "ocr_passport"},
  {
    "domain": "OCR_RESULT",
    "dtoName": "OCR_RESULT_DRIVING_LICENSE",
    "fieldName": "GLOBAL",
    "label": "ocr_driving_license"
  },
  {"domain": "OCR_RESULT", "dtoName": "OCR_RESULT_ID_CARD", "fieldName": "GLOBAL", "label": "ocr_id_card"},
  {
    "domain": "OCR_RESULT",
    "dtoName": "OCR_RESULT_RESIDENCE_PERMIT",
    "fieldName": "GLOBAL",
    "label": "ocr_residence_permit"
  },
  {"domain": "OCR_RESULT", "dtoName": "OCR_RESULT_BANK_DETAILS", "fieldName": "GLOBAL", "label": "ocr_bank_details"},
  {"domain": "OCR_RESULT", "dtoName": "OCR_RESULT_KBIS", "fieldName": "GLOBAL", "label": "ocr_kbis"},
  {"domain": "OCR_RESULT", "dtoName": "OCR_RESULT_TAX_REPORT", "fieldName": "GLOBAL", "label": "ocr_tax_report"},
  {"domain": "OCR_RESULT", "dtoName": "OCR_RESULT_TAX_NOTICE", "fieldName": "GLOBAL", "label": "ocr_tax_notice"},
  {
    "domain": "TAX_REPORT_ANALYSIS",
    "dtoName": "TAX_REPORT_ANALYSIS",
    "fieldName": "GLOBAL",
    "label": "tax_report_analysis_global"
  },
  {
    "domain": "TAX_NOTICE_ANALYSIS",
    "dtoName": "TAX_NOTICE_ANALYSIS",
    "fieldName": "GLOBAL",
    "label": "tax_notice_analysis_global"
  },
  {"domain": "AUTHENTICITY", "dtoName": "DOCUMENT_AUTHENTICITY", "fieldName": "GLOBAL", "label": "authenticity"},
  {"domain": "AUTHENTICITY", "dtoName": "DOCUMENT_AUTHENTICITY", "fieldName": "REASON", "label": "reason"},
  {"domain": "FILES", "dtoName": "FILE", "fieldName": "GLOBAL", "label": "file"},
  {"label": "ocr_payslip", "domain": "OCR_RESULT", "dtoName": "OCR_RESULT_PAYSLIP", "fieldName": "GLOBAL"}
] as const;

const TAX_REPORT_FIELDS = [
  {"domain": "OCR_RESULT", "dtoName": "OCR_RESULT_TAX_REPORT", "fieldName": "GLOBAL", "label": "ocr_tax_report"}
] as const;

const OPEN_BANKING_FIELDS = [
  {"domain": "OPEN_BANKING", "dtoName": "OPEN_BANKING_ANALYSIS", "fieldName": "GLOBAL", "label": "ob_analysis"}
] as const;

const APPLICANT_FIELDS = [
  {"domain": "PERSONAL", "dtoName": "PERSON", "fieldName": "GLOBAL", "label": "applicant"}
] as const;

const COMPANY_FIELDS = [
  {"domain": "COMPANY", "dtoName": "COMPANY", "fieldName": "GLOBAL", "label": "company"}
] as const;

const COMPANY_DETAILS_FIELDS = [
  {
    "domain": "CHECK_COMPANY",
    "dtoName": "COMPANY_DETAILS_INTERNATIONAL",
    "fieldName": "GLOBAL",
    "label": "company_details"
  }
] as const;

const STEPS_FIELDS = [
  {"domain": "PORTAL", "dtoName": "FRONT_INFORMATIONS", "fieldName": "STATE", "label": "state"},
  {"domain": "PORTAL", "dtoName": "FRONT_INFORMATIONS", "fieldName": "STEPS_B2C", "label": "steps_b2c"},
  {"domain": "PORTAL", "dtoName": "FRONT_INFORMATIONS", "fieldName": "STEPS_B2B", "label": "steps_b2b"},
  {"domain": "PORTAL", "dtoName": "FRONT_INFORMATIONS", "fieldName": "STEPS_OPENBANKING", "label": "steps_openbanking"},
  {"domain": "PORTAL", "dtoName": "FRONT_INFORMATIONS", "fieldName": "STEPS_DOCUMENT", "label": "steps_document"},
  {"domain": "PORTAL", "dtoName": "FRONT_INFORMATIONS", "fieldName": "STEPS_CHECK_IBAN", "label": "steps_check_iban"},
] as const;

const GREY_LIST_FIELDS = [
  {"domain": "CHECK_LIST", "dtoName": "CHECK_LIST_SEARCH", "fieldName": "GLOBAL", "label": "warning_list"},
] as const;

const FILE_FIELDS = [{
  "domain": "FILES", "dtoName": "FILE", "fieldName": "GLOBAL", "label": "file",
}] as const;

const SCORE_ELLISPHERE = [{
  "domain": "SCORE",
  "dtoName": "ELLISPHERE_SCORE",
  "fieldName": "CAUSES",
  "label": "score_ellisphere"
}] as const;

const ALL_FIELDS = [...FRAUD_B2C_FIELDS, ...SOCIAL_FM_FIELDS, ...RISK_FIELDS, ...INPI_FIELDS, ...TAX_REPORT_ANALYSES_FIELDS,
  ...FRAUD_B2B_FIELDS, ...APPLICANT_FIELDS, ...AMLCFT_FIELDS, ...COMPANY_FIELDS, ...OPEN_BANKING_FIELDS, ...COMPANY_DETAILS_FIELDS,
  ...E_REPUTATION_FIELDS, ...SCORE_SMB_FIELDS, ...CHECK_IBAN_FIELDS, ...GREY_LIST_FIELDS, ...DOCUMENT_FIELDS, ...SCORE_ELLISPHERE
];

export const createJourneyId = (clientId: string, externalId?: string): Promise<{
  id: string
}> => api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys`,
    JSON.stringify({
      clientId,
      externalId
    }))
  .then((response) => response.data);

export const getDomainsFromJourney = (journeyId: string) =>
  api.get(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/domains`)
    .then((response) => response.data);

export const contextResultSearch = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": ALL_FIELDS
    }).then((response) => response);

export const getScoreFraudB2BFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": FRAUD_B2B_FIELDS
    }).then((response) => response);

export const getScoreSMBFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": SCORE_SMB_FIELDS
    }).then((response) => response);

export const getApplicantFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": APPLICANT_FIELDS
    }).then((response) => response);

export const getCompanyDetailsFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": COMPANY_DETAILS_FIELDS
    }).then((response) => response);

export const getCompanyFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": COMPANY_FIELDS
    }).then((response) => response);

export const getScoreFraudB2CFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": FRAUD_B2C_FIELDS
    }).then((response) => response);

export const getSocialFaceMatchingFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": SOCIAL_FM_FIELDS
    }).then((response) => response);

export const getAMLCFTFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": AMLCFT_FIELDS
    }).then((response) => response);

export const getInpiInfoFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": INPI_FIELDS
    }).then((response) => response);

export const getTaxReportAnalysisFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": TAX_REPORT_ANALYSES_FIELDS
    }).then((response) => response);

export const getScoreRiskFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": RISK_FIELDS
    }).then((response) => response);

export const getDocumentsFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": DOCUMENT_FIELDS
    }).then((response) => response);

export const getTaxReportFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": TAX_REPORT_FIELDS
    }).then((response) => response);

export const getOpenBankingDatasFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": OPEN_BANKING_FIELDS
    }).then((response) => response.data);

export const getGreyListFromContext = (journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`,
    {
      "fields": GREY_LIST_FIELDS
    }).then((response) => response.data);

export const sendApplicantInfoToContext = (data: CustomerProfileV3, journeyId: string) => {
  return api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      "requestVersion": "V1",
      "requestDomain": "PERSONAL",
      "dtoName": "PERSON",
      "data": {
        "domain": "PERSONAL",
        "dtoName": "PERSON",
        "version": "V1",
        "strict": false,

        "externalId": "MEELO-PORTAL",
        "firstname": data.identity.firstName,
        "lastname": data.identity.lastName,
        "mobilePhone": data.identity.mobileNumber,
        "email": data.identity.email,
        "birthDate": data.identity.birthDate,
        "addresses": [
          {
            "domain": "ADDRESS",
            "dtoName": "ADDRESS",
            "version": "V1",
            "strict": false,

            "addressType": "MAIN",
            "street1": data.address.street,
            "zipcode": data.address.zipCode,
            "city": data.address.city,
            "country": data.address.country,
          }
        ]
      }
    }).then((response) => response.data)
};

export const sendApplicantInfoToContextV1 = (data: CustomerProfile, journeyId: string) => {
  return api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      "requestVersion": "V1",
      "requestDomain": "PERSONAL",
      "dtoName": "PERSON",
      "data": {
        "domain": "PERSONAL",
        "dtoName": "PERSON",
        "version": "V1",
        "strict": false,

        "externalId": "MEELO-PORTAL",
        "firstname": data.identity.firstName,
        "lastname": data.identity.lastName,
        "mobilePhone": data.identity.mobilePhoneNumber,
        "email": data.identity.email,
        "birthDate": data.identity.birthDate,
        "addresses": [
          {
            "domain": "ADDRESS",
            "dtoName": "ADDRESS",
            "version": "V1",
            "strict": false,

            "addressType": "MAIN",
            "street1": data.address.street,
            "zipcode": data.address.zipCode,
            "city": data.address.city,
            "country": data.address.country,
          }
        ]
      }
    }).then((response) => response.data)
};

export const sendCompanyInfoToContext = (dataCustomer: CustomerProfile, dataCompany: CompanyProfile, companyAddress: any, journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      "requestVersion": "V1",
      "requestDomain": "COMPANY",
      "dtoName": "COMPANY",
      "data": {
        "domain": "COMPANY",
        "dtoName": "COMPANY",
        "version": "V1",
        "strict": true,
        "externalId": "823332",
        "name": dataCompany.name,
        "siren": dataCompany.siren,
        "addresses": [
          {
            "domain": "ADDRESS",
            "dtoName": "ADDRESS",
            "version": "V1",
            "strict": false,

            "addressType": "MAIN",
            "street1": companyAddress.street,
            "zipcode": companyAddress.zipCode,
            "city": companyAddress.city,
            "country": companyAddress.country
          }
        ],
        "persons": [
          {
            "domain": "PERSONAL",
            "dtoName": "PERSON",
            "version": "V1",
            "firstname": dataCustomer.identity.firstName,
            "lastname": dataCustomer.identity.lastName,
            "mobilePhone": dataCustomer.identity.mobilePhoneNumber,
            "email": dataCustomer.identity.email,
            "birthDate": dataCustomer.identity.birthDate,
            "addresses": [
              {
                "domain": "ADDRESS",
                "dtoName": "ADDRESS",
                "version": "V1",
                "strict": false,

                "addressType": "MAIN",
                "street1": dataCustomer.address.street,
                "zipcode": dataCustomer.address.zipCode,
                "city": dataCustomer.address.city,
                "country": dataCustomer.address.country
              }
            ]
          }
        ]
      }
    }
  ).then((response) => response.data);

export const getByPages = (countByPage: number, pageId: number, marketType: String[], createdAfter: string, createdBefore: string) => {
  let whereCondition;
  if (marketType.includes("portal-b2b")) {
    whereCondition = {
      "operator": "NOT_EQUALS",
      "field": "company_details",
      "value": null
    }
  } else if (marketType.includes("document-analysis")) {
    whereCondition = null
  } else if (marketType.includes("open-banking")) {
    whereCondition = {
      "operator": "NOT_EQUALS",
      "field": "ob_analysis",
      "value": null
    }
  } else {
    whereCondition = {
      "operator": "NOT_EQUALS",
      "field": "lastname",
      "value": null
    }
  }
  return api.post(`${envConfig.CONTEXT_ROUTE}/v4/journeys/search`,
    {
      "pageId": pageId,
      "countByPage": countByPage,
      "where": whereCondition,
      "createdAfter": createdAfter,
      "sorts": [
        {
          "field": "JOURNEY_createdAt",
          "direction": "DESC"
        }
      ],
      "fields": [
        ...APPLICANT_FIELDS,
        ...FRAUD_B2C_FIELDS,
        {"domain": "SCORE_RISK", "dtoName": "RISK_SCORE_B2B", "fieldName": "SCORE", "label": "score_risk"},
        {
          "domain": "SCORE_RISK",
          "dtoName": "RISK_SCORE_B2B",
          "fieldName": "SCORE_SECTOR_PERCENT",
          "label": "score_sector"
        },
        {
          "domain": "SCORE_RISK",
          "dtoName": "RISK_SCORE_B2B",
          "fieldName": "DEFAULT_PROBABILITY",
          "label": "default_probability"
        },
        {"domain": "SCORE", "dtoName": "WEB_SCORE_B2B", "fieldName": "GLOBAL", "label": "web_score_b2b"},
        ...AMLCFT_FIELDS,
        {"domain": "TAX_REPORT_ANALYSIS", "dtoName": "TAX_REPORT_ANALYSIS", "fieldName": "RATIOS", "label": "ratios"},
        {"domain": "SCORE_FRAUD", "dtoName": "FRAUD_SCORE_B2C", "fieldName": "CONTACT", "label": "contact"},
        ...OPEN_BANKING_FIELDS,
        {
          "domain": "OPEN_BANKING",
          "dtoName": "OPEN_BANKING_ANALYSIS",
          "fieldName": "HOLDER_NAME",
          "label": "holder_name"
        },
        {
          "label": "lastname",
          "domain": "PERSONAL",
          "dtoName": "PERSON",
          "fieldName": "LASTNAME"
        },
        {
          "label": "firstname",
          "domain": "PERSONAL",
          "dtoName": "PERSON",
          "fieldName": "FIRSTNAME"
        },
        ...DOCUMENT_FIELDS,
        ...SCORE_SMB_FIELDS,
        ...COMPANY_DETAILS_FIELDS,
        ...FRAUD_B2B_FIELDS,
        ...GREY_LIST_FIELDS
      ],
      "clientIds": marketType,
      "onlyMe": true

    }).then(({data}) => data);
}

export const sendStepsToContext = (journeyId: string, data: Steps) =>
  api.put(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      "requestVersion": "V1",
      "requestDomain": "PORTAL",
      "dtoName": "FRONT_INFORMATIONS",
      "domain": "PORTAL",
      "version": "V1",
      "strict": false,
      data
    }).then((response) => response.data);

export const updateFrontInformation = (journeyId: string, steps: Steps, greyList: GreyList) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      "requestVersion": "V1",
      "requestDomain": "PORTAL",
      "dtoName": "FRONT_INFORMATIONS",
      "data": {
        "domain": "PORTAL",
        "dtoName": "FRONT_INFORMATIONS",
        "version": "V1",
        "strict": false,

        "externalId": "MEELO-PORTAL",
        ...steps,
        "greyList": greyList
      }
    }).then((response) => response.data);

export const getStepsFromContext = (journeyId: string, fields: {
  domain: string,
  dtoName: string,
  fieldName: string,
  label: string
}[]): Promise<any> => {
  return api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts/data/fields`, {fields})
    .then((response) => {
      const responseData = response.data;

      if (JSON.stringify(responseData.data) === '{}') {
        return new Promise((resolve) => setTimeout(resolve, 500))
          .then(() => getStepsFromContext(journeyId, fields));
      }

      return responseData;
    });
};

export const sendGreyListToContext = (greyList: GreyList, journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      "requestVersion": "V1",
      "requestDomain": "PORTAL",
      "dtoName": "FRONT_INFORMATIONS",
      "data": {
        "domain": "PORTAL",
        "dtoName": "FRONT_INFORMATIONS",
        "version": "V1",
        "strict": false,

        "externalId": "MEELO-PORTAL",
        "greyList": greyList
      }
    }).then((response) => response.data);

export const sendProductCGI = (productCgi: ProductCgi, journeyId: string) =>
  api.post(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}/contexts`,
    {
      "requestVersion": "V1",
      "requestDomain": "PRODUCT",
      "dtoName": "PRODUCT_CGI",
      "data": {
        "domain": "PRODUCT",
        "dtoName": "PRODUCT_CGI",
        "version": "V1",
        "strict": false,
        "pretedValue": productCgi.pretedValue,
        "contributionPercent": productCgi.contribution_percent,
      }
    }
  ).then((response) => response.data);

export const getJourneyId = (journeyId: string) =>
  api.get(`${envConfig.CONTEXT_ROUTE}/v3/journeys/${journeyId}`)
    .then((response) => response.data);

export default {
  createJourneyId,
  contextResultSearch,
  getApplicantFromContext,
  getCompanyFromContext,
  getDocumentsFromContext,
  getOpenBankingDatasFromContext,
  sendApplicantInfoToContext,
  getScoreFraudB2BFromContext,
  getScoreFraudB2CFromContext,
  getInpiInfoFromContext,
  getScoreRiskFromContext,
  getSocialFaceMatchingFromContext,
  getAMLCFTFromContext,
  getTaxReportAnalysisFromContext,
  getCompanyDetailsFromContext,
  sendStepsToContext,
  getStepsFromContext,
  sendGreyListToContext,
  sendProductCGI,
  getJourneyId
};
