<script setup lang="ts">
import {onMounted, ref} from 'vue';
import MotiveModal from "@/components/backoffice/company-motive/BackOfficeCompanyMotiveModal.vue";
import MotiveTable from "@/components/backoffice/company-motive/BackOfficeCompanyMotiveTable.vue";
import MotiveSearch from "@/components/backoffice/company-motive/BackOfficeCompanyMotiveSearch.vue";
import {createMotive, deleteMotive, getAllMotives, updateMotive} from "@/apiCalls/taskApi";
import {Motive} from "@/utils/journeyUtils";
import {useToast} from "vue-toast-notification";
import {translate} from "@/i18n";

const toast = useToast();

// Flags
const showModal = ref(false);
const modalMode = ref<'create' | 'edit' | 'delete'>('create');

// Data
const motives = ref([] as Motive[]);
const currentMotive = ref({} as Motive);
const labelToSearch = ref('');

// Pagination
const totalElements = ref(0);
const totalPages = ref(0);
const currentPage = ref(0);
const pageSize = ref(10);

const handleSearch = async (label: string) => {
  motives.value = [];
  await fetchMotives(currentPage.value, pageSize.value, label);
};

const handleReset = async () => {
  labelToSearch.value = '';
  motives.value = [];
  await fetchMotives(currentPage.value, pageSize.value, labelToSearch.value);
};

const fetchMotives = async (page: number, size: number, label: string) => {
  const response: any = await getAllMotives(page, size, label, '', true);
  motives.value = response.data.content.map((motive: Motive) => {
    return {
      id: motive.id,
      label: motive.label,
      status: motive.status,
    };
  })
  totalElements.value = response.data.totalElements;
  totalPages.value = response.data.totalPages;
};
const handlePageChange = async (page: number) => {
  currentPage.value = page;
  motives.value = [];
  await fetchMotives(page, pageSize.value, labelToSearch.value);
};

// Modal Actions
const openModal = (mode: 'create' | 'edit' | 'delete', motive: Motive) => {
  modalMode.value = mode;
  showModal.value = true;
  currentMotive.value = motive;
};
const closeModal = () => {
  showModal.value = false;
};
const handleEditMotive = (motive: Motive) => {
  openModal('edit', motive);
};
const handleDeleteMotive = (motive: Motive) => {
  openModal('delete', motive);
};
const handleAddMotive = () => {
  openModal('create', {} as Motive);
};

const handleCreateMotiveRequest = async (motive: Motive) => {
  try {
    await createMotive(motive);
    toast.success(translate('MOTIVE.TOAST.SUCCESS.CREATION'));
  } catch (e) {
    toast.error(translate('MOTIVE.TOAST.FAILED.CREATION'));
    console.error(e);
  }
};

const handleEditMotiveRequest = async (updatedMotive: Motive) => {
  try {
    if (!updatedMotive.id) return;
    updatedMotive.enabled = true;
    await updateMotive(updatedMotive.id, updatedMotive);
    toast.success(translate('MOTIVE.TOAST.SUCCESS.EDIT'));
  } catch (e) {
    toast.success(translate('MOTIVE.TOAST.FAILED.EDIT'));
    console.error(e);
  }
};

const handleDeleteMotiveRequest = async (updatedMotive: Motive) => {
  try {
    if (!updatedMotive.id) return;
    updatedMotive.enabled = false;
    await deleteMotive(updatedMotive.id);
    toast.success(translate('MOTIVE.TOAST.SUCCESS.DELETE'));
  } catch (e) {
    toast.success(translate('MOTIVE.TOAST.FAILED.DELETE'));
    console.error(e);
  }
};

// Crud Requests
const handleCrudMotiveRequest = async (createdMotive: Motive, updatedMotive: Motive, mode: string) => {
  switch (mode) {
    case 'create':
      await handleCreateMotiveRequest(createdMotive);
      break;
    case 'edit':
      await handleEditMotiveRequest(updatedMotive);
      break;
    case 'delete':
      await handleDeleteMotiveRequest(updatedMotive);
      break;
  }
  try {
    closeModal();
    await fetchMotives(currentPage.value, pageSize.value, labelToSearch.value);
  } catch (e) {
    console.error(e);
  }
}

onMounted(async () => {
  try {
    await fetchMotives(currentPage.value, pageSize.value, labelToSearch.value);
  } catch (e) {
    console.error(e);
  }
});
</script>

<template>
  <MotiveSearch @add="handleAddMotive" @search="handleSearch" @reset="handleReset"/>
  <MotiveTable :motives="motives" :total-elements="totalElements" :total-pages="totalPages" :current-page="currentPage"
               @page-change="handlePageChange" @edit-motive="handleEditMotive" @delete-motive="handleDeleteMotive"/>
  <MotiveModal v-model:open-modal="showModal" :mode="modalMode" :motive="currentMotive"
               @confirm-selection="handleCrudMotiveRequest"/>
</template>