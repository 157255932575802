<script setup lang="ts">
import Button from "@/components/button/Button.vue";
import {translate} from "@/i18n";

const props = withDefaults(defineProps<{
  title: string,
  content: string,
  srcEmoji: string,
  labelButton: string
}>(), {
  title: 'CGI.CONGRATULATIONS',
  content: 'CGI.END_MESSAGE',
  srcEmoji: 'final-step.png',
  labelButton: 'BUTTONS.FINISH_ANALYSIS'
});

const emit = defineEmits(['go-home']);

const goToHome = () => {
  emit('go-home');
}
</script>

<template>
  <div class="end-of-journey">
    <span class="end-of-journey-title">{{ `${translate(`${title}`)} !` }}</span>
    <img class="end-of-journey-image" :src="require(`/public/images/${srcEmoji}`)" alt="this.scrEmoji">
    <span class="end-of-journey-content"> {{translate(`${content}`)}} </span>
    <Button class="end-of-journey-action" :label="translate(`${labelButton}`)" @click="goToHome"/>
  </div>
</template>
