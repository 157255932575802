import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const computeScoreAltares = (siren: string, journeyId: string) =>
  api.put(`${envConfig.SCORING_ROUTE}/v2/task/score?storeInput=false&journeyId=${journeyId}`,
    {
      requestOptions: {
        requestId: "ALTARES_SCORE"
      },
      companyProfile: {
        siren: siren
      }
    }
  ).then((response) => response.data);

export const computeScoreCreditSafe = (siren: string, country: string, journeyId: string) =>
  api.put(`${envConfig.SCORING_ROUTE}/v2/task/score?storeInput=false&journeyId=${journeyId}`,
    {
      requestOptions: {
        requestId: "CREDIT_SAFE_SCORE"
      },
      companyProfile: {
        address: {
          country: country
        },
        registrationNumber: siren
      }
    }
  ).then((response) => response.data);


export const runScoringBatch = (email: string, requestIds: string[], file: any, fileName?: string) => {
  var formData = new FormData();
  formData.append("file", file);
  return api.post(`${envConfig.SCORING_ROUTE}/v1/batch/score/${requestIds.join(',')}/${email}${fileName !== '' ? `?fileName=${fileName}` : ''}`,
    formData, {
      headers: {'Content-Type': 'multipart/form-data'},
    }
  ).then((response) => response.data);

}

export const cancelScoringBatch = (id: string) => {
  return api.delete(`${envConfig.SCORING_ROUTE}/v1/batch/cancel/${id}`
  ).then((response) => response.data);
}

export const getScoringBatchProgress = (taskId: string) =>
  api.get(`${envConfig.SCORING_ROUTE}/task/${taskId}`,
  ).then((response) => response.data);

export const computeScoreEllisphere = (registrationNumber: string, journeyId: string) =>
  api.put(`${envConfig.SCORING_ROUTE}/v2/task/score?journeyId=${journeyId}`,
    {
      requestOptions: {
        "requestId": "ELLISPHERE_SCORE"
      },
      companyProfile: {
        "siren": registrationNumber
      }
    },
    {
      headers: {
        'accept': '*/*',
        'Content-Type': 'application/json'
      }
    }).then((response) => response);

export const getBatchTemplate = (requestIds: string[]) =>
  api.get(`${envConfig.SCORING_ROUTE}/v1/batch/template/${requestIds.join(",")}`,
    {
      responseType: "blob"
    }
  ).then((response) => {
    return response.data
  });

export const downloadBatchResult = (taskId: string) =>
  api.get(`${envConfig.SCORING_ROUTE}/v1/batch/download/${taskId}`,
    {
      responseType: "blob"
    }
  ).then((response) => response.data);

export const getParameters = async () => {
  return api.get(`${envConfig.SCORING_ROUTE}/company-parameters`);
};


export default {
  computeScoreAltares,
  runScoringBatch,
  getScoringBatchProgress,
  cancelScoringBatch,
  computeScoreEllisphere,
  computeScoreCreditSafe
};

