<template>
    <div class="end-of-journey-new-year">
        <div class="end-of-journey-new-year-top">
            <img class="end-of-journey-new-year-top-end" src="../../../public/images/x-mas/028-star.png" alt="">
            <img src="../../../public/images/x-mas/disco-ball.png" alt="">
            <img class="end-of-journey-new-year-top-end" src="../../../public/images/x-mas/balloons.png" alt="">
        </div>
        <div class="end-of-journey-new-year-text">
            <span>Meelo vous souhaite une excellente année 2024!</span>
        </div>
        <div class="end-of-journey-new-year-bottom">
            <img src="../../../public/images/x-mas/champagne.png" alt="">
        </div>
    </div>
</template>

<style scoped lang="scss">
.end-of-journey-new-year {
    color: #293254;

    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 10px;
    justify-content: center;
    border-radius: 10px;
    width: 25rem;
    // padding: 2rem;
    margin: auto;
    box-shadow: -3px 1px 20px 0px #bfc0c4;

    /* box-shadow: -3px 1px 20px 0px $greyLight; */
    &-top {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-end {
            align-self: flex-end;
        }
    }

    &-text {
        width: 55%;
        text-align: center;
        font-size: 19px;
    }

    &-bottom {
        padding: 1rem;
    }
}
</style>
