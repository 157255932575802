<template>
  <div>

  </div>
</template>
<script lang="js">
import {defineComponent} from "vue";
import cookieMixin from "@/mixins/cookieMixin";
import {mapGetters} from "vuex";
import connectAsApi from "@/apiCalls/connectAsApi";
import { isNil } from "lodash";

export default defineComponent({
  name: "LogoutPage",
  mixins: [cookieMixin],
  async created() {
    document.body.classList.remove(this.user?.companyName ? this.user.companyName.replaceAll(' ', '-') : 'meelo');
    if(!isNil(this.user) && !isNil(this.user.realUser)){
      await connectAsApi.logoutConnectAs();
    }
    this.clearUserCookiesAndPushToLogin();
    this.$router.push({name: 'LoginPage'});
  },
  computed: {
    ...mapGetters(['user'])
  }
})
</script>
