import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const sendFraudDeclaration = (data: any) =>{
  const blob = [new Blob([JSON.stringify(data)], {
    type: 'application/json'
  })]

  const file = new File(blob, 'data.json', {type: 'application/json'});

  const formData = new FormData();
  formData.append('mailRequest', file);

  return api.post(`${envConfig.TOOLBOX_ROUTE}/v1/mail/template/FRAUD_REPORT?language=FR&version=1`,
  formData, {
   headers: {
     'Content-Type': 'multipart/form-data'
   }
 } 
)};

