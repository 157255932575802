<script setup lang="ts">

import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import {IdMobileCauses} from "@/types/ScoreFraud";
import {onMounted, ref, watch} from "vue";
import Button from "@/components/button/Button.vue";
import {isNull, isObject} from "lodash";
import Loader from "@/components/loader/Loader.vue";

const props = withDefaults(defineProps<{
  data: IdMobileCauses,
  displayContent?: boolean,
  content: string,
  loader: boolean,
  permissions: Array<string>
}>(), {
  displayContent: false,
  content: '',
  loader: false
});

const display = ref();

const emit = defineEmits(['dqe-control']);

const displayColor = (value: string) => {
  return value === 'OK' ? '#36ba75' : '#ff7676';
}

const dqeControl = () => {
  emit('dqe-control');
}

const getDisplay = (data: IdMobileCauses) => {
  display.value = Object?.entries(data?.details)?.reduce((acc: any, [key, value]) => {
    if (isNull(value.decision)) {
      return acc;
    } else if (value.decision) {
      acc[key] = "OK";
    } else {
      acc[key] = "KO";
    }
    return acc;
  }, {});
}

watch(() => props.data, (newDisplay) => {
  if (!isNull(newDisplay.globalDecision)) {
    getDisplay(newDisplay);
  }
});

onMounted(() => {
  if (isObject(props.data) && !isNull(props.data.globalDecision)) {
    getDisplay(props?.data);
  }
});
</script>

<template>
  <ResultCard :title="translate('DQE_CONTROL.TITLE')" class="dqe-control">
    <template v-if="display">
      <div v-for="(value, key) in display" :key="key" class="dqe-control-content">
        <span>{{ translate(`DQE_CONTROL.KEY.${key}`) }} : </span>
        <span :style="`color: ${displayColor(value)}`">{{ `${translate('DQE_CONTROL.MATCHING')} ${value}` }}</span>
      </div>
    </template>
    <Loader v-else-if="loader"/>
    <div v-else-if="!display && !isNull(data?.globalDecision) && content === ''" class="dqe-control-action">
      <Button :label="translate('DQE_CONTROL.LAUNCH_MATCHING_SEARCH')" @click="dqeControl"
              :action-icon="!permissions.includes('KIT_B2C_DQE_CONTROL_GLOBAL') ? 'padlock-blue.png' : ''"/>
    </div>
    <span v-else-if="content !== ''">{{ content }}</span>
  </ResultCard>
</template>