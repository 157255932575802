import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "monitoring__my-usage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConsumptionSearch = _resolveComponent("ConsumptionSearch")!
  const _component_MonitoringCharts = _resolveComponent("MonitoringCharts")!
  const _component_MonitoringValues = _resolveComponent("MonitoringValues")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ConsumptionSearch, {
      users: _ctx.users,
      onSearch: _ctx.searchUsage,
      onReset: _ctx.resetUsage,
      onSelectAll: _ctx.allUsersSelected,
      onSelectCompany: _ctx.companySelected,
      onSelectMyTeam: _ctx.myTeamSelected
    }, null, 8, ["users", "onSearch", "onReset", "onSelectAll", "onSelectCompany", "onSelectMyTeam"]),
    _createVNode(_component_MonitoringCharts, {
      "total-label-usage": _ctx.totalLabelUsage,
      "total-usage": _ctx.totalUsage,
      "total-value-usage": _ctx.totalValueUsage,
      "color-usage": _ctx.generateRandomColorsArray(_ctx.totalValueUsage.length),
      "consumption-to-display": _ctx.titleToDisplay,
      "is-monitoring-data-loading": _ctx.isMonitoringDataLoading
    }, null, 8, ["total-label-usage", "total-usage", "total-value-usage", "color-usage", "consumption-to-display", "is-monitoring-data-loading"]),
    (_ctx.size(_ctx.totalValueUsage) > 0)
      ? (_openBlock(), _createBlock(_component_MonitoringValues, {
          key: 0,
          "scores-usages": _ctx.scoresUsages,
          "usage-without-all": _ctx.usageWithoutAll
        }, null, 8, ["scores-usages", "usage-without-all"]))
      : _createCommentVNode("", true)
  ]))
}