<script setup lang="ts">

import {ref} from "vue";
import BoxInputFraudDeclaration from "@/components/box-input/BoxInputFraudDeclaration.vue";
import {sendFraudDeclaration} from "@/apiCalls/fraudReport";
import BoxOutputEndOfJourney from "@/components/box-output/BoxOutputEndOfJourney.vue";
import {translate} from "@/i18n";
import {useRouter} from "vue-router";
import {useToast} from 'vue-toast-notification';

const router = useRouter()
const isDeclaration = ref(true)

const errorToast = useToast();

const sendMail = async (fraudDeclarationData: object) => {
  try {
    const send = await sendFraudDeclaration(fraudDeclarationData);
    isDeclaration.value = false
    document.formFraud.reset();
  } catch (error: any) {
    if (error.status === 404) {
      errorToast.error(translate("MAINTENANCE"));
    } else if (error.status === 400) {
      errorToast.error(translate("ERROR_OCCURRED"))
    }
  }
}
const comeBack = () => {
  router.push('/historical')
  isDeclaration.value = true
}
</script>
<template>
  <BoxInputFraudDeclaration v-if="isDeclaration" @send-fraud-declaration="sendMail"/>
  <BoxOutputEndOfJourney v-else :title="translate('THANKS')" :content="'CONFIRM_SEND'"
                         :button="'CGI.DESICION.FAILED.BUTTON'" :srcEmoji="'thumb.png'"
                         :labelButton="'CGI.DECISION.FAILED.BUTTON'" @go-home="comeBack"/>
</template>


