import {translate} from '@/i18n';
import {NavigationItem} from '@/components/sidebar/groups/NavigationItem';

const rulesNavigation: NavigationItem[] = [
  {
    title: 'SIDEBAR.RULES_ENGINE.TITLE',
    colorBackground: '#D9D9D9',
    colorTitle: '#293354',
    elements: [{
      title: translate('SIDEBAR.RULES_ENGINE.COMPANY_RULES'),
      route: '/back-office/rules-engine/rules',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }, {
      title: translate('SIDEBAR.RULES_ENGINE.SETS'),
      route: '/back-office/rules-engine/sets',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }]
  },
  {
    title: '',
    colorBackground: '#293354',
    colorTitle: 'white',
    elements: [{
      title: translate('SIDEBAR.HOME'),
      route: '/back-office/home',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }, {
      title: translate('SIDEBAR.PORTAL'),
      route: '/home',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }/*, {
      title: translate('SIDEBAR.MEELO_ACADEMY'),
      route: '/meelo-academy',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    } */]
  }
];
export default rulesNavigation;
