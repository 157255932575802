<script setup lang="ts">
import {downloadAttachmentsPdf} from "@/apiCalls/companyCheck";
import {formatLocaleDate, translate} from "@/i18n";
import ContentCard from "@/components/card/ContentCard.vue";
import {onBeforeUnmount, onMounted, reactive} from "vue";
import {isEmpty} from "lodash";
import {downloadedImage, downloadImage, loadingImage} from "@/utils/imagePath";
import {Document} from "@/utils/companyLegalDoc";

const props = withDefaults(defineProps<{
  legalDoc: Document[],
  registrationNumber: string,
  journeyId: string,
  canCompanyLegalDoc: boolean,
  toPrint: boolean,
  country: string
}>(), {
  registrationNumber: '',
  journeyId: '',
  canCompanyLegalDoc: false,
  toPrint: false,
  country: ''
});

const imageSources: { [key: string]: string } = reactive({});
let timeout: any;

const displayLegalActs = (acts: Array<any>) => {
  return acts.reduce((acc, act) => {
    let existingType = acc.find((r: { type: string }) => r.type === act.type);

    if (!existingType) {
      existingType = {type: act.type, decision: []};
      acc.push(existingType);
    }

    if (act.decision) {
      existingType.decision.push(act.decision);
    }

    return acc;
  }, []);
}

const downloadLegalAct = async (registrationNumber: string, journeyId: string, fileId: string, type: string, submissionDate: string) => {
  const image = document.getElementById(fileId);
  const urlRegex = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/[^/]*\.pdf$/;
  if (image) {
    imageSources[fileId] = loadingImage;
    await downloadAttachmentsPdf(registrationNumber, journeyId, fileId, type, submissionDate, props.country);
    if (props.country === 'BE') {
      window.open(fileId, '_blank');
    } else if (urlRegex.test(fileId)) {
      window.open(fileId, '_blank');
    } else {
      await downloadAttachmentsPdf(registrationNumber, journeyId, fileId, type, submissionDate, props.country);
    }
    imageSources[fileId] = downloadedImage;

    timeout = setInterval(() => {
      imageSources[fileId] = downloadImage;
    }, 750);
  }
}

onMounted(() => {
  if (!isEmpty(props.legalDoc)) {
    props.legalDoc.forEach((document: { fileId: string; }) => {
      imageSources[document.fileId] = downloadImage;
    });
  }
});
onBeforeUnmount(() => {
  if (timeout) clearTimeout(timeout)
});
</script>

<template>
  <ContentCard v-if="!canCompanyLegalDoc" :checked-display-content="false" :is-padlock="true"
               :title="translate('COMPANY.LEGAL_DOCUMENT.TITLE')" class="legal-doc"/>
  <template v-else>
    <ContentCard :title="translate('COMPANY.LEGAL_DOCUMENT.TITLE')" class="legal-doc">
      <template v-if="!isEmpty(legalDoc)">
        <div v-for="(document, index) in legalDoc" :key="index" class="legal-doc-content breakinside">
          <div class="legal-doc-content-act">
            <p v-if="displayLegalActs(document.acts).length === 0">{{ translate('COMPANY.LEGAL_DOCUMENT.NO_TYPE') }}</p>
            <template v-else v-for="(act, key) in displayLegalActs(document.acts)" :key="key">
              <template v-if="!isNaN(parseInt(act.type))">
                <template v-for="decision in act.decision">
                  <p class="legal-doc-content-act-type">{{ act.type }}{{ decision && ` - ${decision}` }}</p>
                  <ul>
                    <li>{{ decision }}</li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <p class="legal-doc-content-act-type">{{ act.type }}</p>
                <ul v-if="act.decision.length > 0">
                  <li v-for="decision in act.decision">{{ decision }}</li>
                </ul>
              </template>
            </template>
          </div>
          <div class="legal-doc-content-download">
            <img v-if="!toPrint" :src="imageSources[document.fileId]" alt="file-download"
                 class="legal-doc-content-image no-print" :id="document.fileId"
                 @click="downloadLegalAct(registrationNumber, journeyId, document.fileId, 'LEGAL_ACT', document.submissionDate)">
            <span>{{ formatLocaleDate(document.submissionDate) }}</span>
          </div>
        </div>
      </template>
      <div v-else class="legal-doc-no-data">
        {{ translate('COMPANY.LEGAL_DOCUMENT.NO_DATA') }}
      </div>
    </ContentCard>
  </template>
</template>