<script lang="ts" setup>
import { snakeCase } from "lodash";

import { translate } from "@/i18n";
import PrintCard from "@/components/print/shared/PrintCard.vue";

defineProps<{
  title: string;
  entries: Record<string, { [key: string]: any }>;
}>();

const getTransKey = (key: string) => {
  return `PRINT.ANALYSIS_B2B.${snakeCase(key).toUpperCase()}`;
};
</script>

<template>
  <PrintCard :title="title">
    <div class="print-folder-info-container">
      <template v-for="(value, key) in entries" :key="key">
        <span class="label">
          {{ translate(getTransKey(key as string)) }}
        </span>
        <div v-for="(subEntry, subEntryKey) in value" class="entry">
          <p>
            <span>{{ translate(getTransKey(subEntryKey as string)) }} : </span>
            {{ subEntry }}
          </p>
        </div>
      </template>
    </div>
  </PrintCard>
</template>
