import {translate} from '@/i18n';
import {NavigationItem} from '@/components/sidebar/groups/NavigationItem';

const monitoringNavigation: NavigationItem[] = [
  {
    title: 'SIDEBAR.MONITORING.TITLE',
    colorBackground: '#D9D9D9',
    colorTitle: '#293354',
    elements: [{
      title: translate('SIDEBAR.MONITORING.MY_USAGE'),
      route: '/back-office/my-usage',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }, {
      title: translate('SIDEBAR.MONITORING.COMPANY_USAGE'),
      route: '/back-office/usage-company',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }]
  },
  {
    title: '',
    colorBackground: '#293354',
    colorTitle: 'white',
    elements: [{
      title: translate('SIDEBAR.HOME'),
      route: '/back-office/home',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }, {
      title: translate('SIDEBAR.PORTAL'),
      route: '/home',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    }/*, {
      title: translate('SIDEBAR.MEELO_ACADEMY'),
      route: '/meelo-academy',
      roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
    } */]
  }
];
export default monitoringNavigation;
