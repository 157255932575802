<script setup lang="ts">
import { computed } from "vue"

import { isAllBonhommeFound } from "@/utils/x-mas/setXMasCookie"

import XMasEncart from "./XMasEncart.vue"
import XMasNewYearEncart from "./XMasNewYearEncart.vue"

const props = defineProps<{ content: "christmas" | "new-year" }>()
const emits = defineEmits(["showBonhomme"])

const allBonhommeHasBeenFound = computed(() => isAllBonhommeFound())
</script>

<template>
    <div class="x-mas-welcome">
        <XMasEncart v-if="props.content === 'christmas'" />
        <XMasNewYearEncart v-else />
        <div class="x-mas-welcome-info">
            <span>
                Notre équipe de petits bonhommes en pain d'épices se sont perdus dans le portail...
            </span>
            <span>
                Saurez-vous les retrouver?
            </span>
            <span class="x-mas-welcome-text-small">
                Dès que vous apercevez un bonhomme en pain d'épices, cliquez dessus pour qu'il retrouve son chemin jusqu'ici.
            </span>
            <img src="../../../public/images/x-mas/pere-noel.png" alt="">
        </div>
        <div class="x-mas-welcome-info-bottom">
            <span v-if="allBonhommeHasBeenFound">
                Félicitations! Vous les avez tous trouvé! ❤️
            </span>
            <span v-else class="x-mas-welcome-text-small">
                Tels de délicieux petits biscuits, ils sont craquants et ont un coeur fondant.<br>Au nombre de 15, ils
                symbolisent une équipe forte, soudée et dévouée.
            </span>
            <span class="x-mas-welcome-text-medium-underlined" @click="emits('showBonhomme')">
                Consulter mon avancée
            </span>
        </div>
    </div>
</template>
