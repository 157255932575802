<script setup lang="ts">
import moment from 'moment';
import {computed, inject, ref} from 'vue';
import {VueCookies} from 'vue-cookies';

import {BonhommeProps} from "@/utils/x-mas/setXMasCookie";

const props = defineProps<{ value: number, style: { top: string, left: string } }>()

const $cookies = inject<VueCookies>("$cookies")

const show = ref<boolean>()

const setChecked = () => {
  const _bonhommes: BonhommeProps = Object.assign({}, $cookies?.get("bonhommes"))
  _bonhommes[props.value].hasBeenFound = true
  $cookies?.set("bonhommes", _bonhommes)
  show.value = !show.value
}

const bonhomme = computed(() => {
  const _bonhommes: BonhommeProps = $cookies?.get("bonhommes")
  const _hasBeenFound = _bonhommes[props.value].hasBeenFound
  const _name = _bonhommes[props.value].name
  show.value = _hasBeenFound
  return {
    hasBeenFound: _hasBeenFound,
    name: _name
  }
})

const is15Dec = computed(() => {
  const start = moment("2023-12-15", "YYYY-MM-DD").format()
  const end = moment("2023-12-31", "YYYY-MM-DD").format()
  const now = moment()
  return moment(now).isBetween(start, end)
})

const isNewYear = computed(() => {
  const start = moment("2024-01-01", "YYYY-MM-DD").format()
  const end = moment("2024-01-14", "YYYY-MM-DD").format()
  const now = moment()
  return moment(now).isBetween(start, end)
})
</script>

<template>
  <template v-if="is15Dec || isNewYear">
    <div v-if="!bonhomme.hasBeenFound && !show" class="x-mas-welcome-tooltip"
         :title="`Salut! Je suis ${bonhomme.name}`">
      <img src="../../../public/images/x-mas/bonhomme-1.png" @click="setChecked">
    </div>
  </template>
</template>

<style scoped>
.x-mas-welcome-tooltip {
  cursor: pointer;
  position: absolute;
  top: v-bind(props.style.top);
  left: v-bind(props.style.left);
}

/* .x-mas-welcome-tooltip {
    position: absolute;
    top: v-bind(props.style.top);
    left: v-bind(props.style.left);
} */
</style>