import {CustomerProfile} from "@/types/CustomerProfile";
import envConfig from "@/utils/envConfig";
import api from "@/utils/api";

export const searchImage = (data: any, journeyId: string) => {
  const formData = makeJsonFile(data);

  let result = api.post(`${envConfig.FACE_MATCHING}/v1/social-facematching?journeyId=${journeyId}`,
    formData, {
      timeout: 30000,
      headers: {
        'Accept': '*/*',
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => response.data);
  return result;
}

export const makeJsonFile = (data: CustomerProfile) => {
  const blob = [new Blob([JSON.stringify({
    customerProfile: {
      identity: {
        lastName: data.identity.lastName,
        firstName: data.identity.firstName,
        birthDate: data.identity.birthDate,
        email: data.identity.email,
        mobilePhoneNumber: data.identity.mobilePhoneNumber
      }
    }
  })], {
    type: 'application/json'
  })]

  const file = new File(blob, 'data.json', {type: 'application/json'});

  const formData = new FormData();
  formData.append('data', file);
  return formData;
}

export default {searchImage};