<script setup lang="ts">
import {defineProps, toRef, ref, reactive, onMounted, nextTick, onBeforeMount, computed} from "vue";
import {translate} from "@/i18n";
import ContentCard from "@/components/card/ContentCard.vue";
import MapChart from "../chart/MapChart.vue";
import Loader from "../loader/Loader.vue";
import {isNull, uniqBy} from 'lodash';
import {formatSiren} from "@/utils/formatRegistrationNumber";
import CartographyModal from "@/components/modal/CartographyModal.vue";
import {ChartData, LinkChart, NodeChart} from "@/types/Cartography";
import {useCartography} from "@/composables/useCartography";
import Button from "@/components/button/Button.vue";

const props = defineProps<{
  dataChart: ChartData[],
  canCartography: Boolean,
  companyCountry?: string
}>();

const emit = defineEmits(['siren-chart']);
const cartography = useCartography();

const dataMapChart = toRef(props, 'dataChart');
const zoom = ref(2);
const modalFlag = ref(false);
const mapId = ref(0);
const legendItems = ref([
  {
    image: "green-circle.png",
    text: translate('CARTOGRAPHY.ACTIVE_COMPANY')
  },
  {
    image: "red-circle.png",
    text: translate('CARTOGRAPHY.COLLECTIVE_PROCEDURE')
  },
  {
    image: "orange-circle.png",
    text: translate('CARTOGRAPHY.DEREGISTERED_COMPANY')
  },
  {
    image: "white-circle.png",
    text: translate('CARTOGRAPHY.UNVERIFIED_COMPANY'),
    class: 'white-circle'
  },
  {
    image: "diamond.png",
    text: translate('CARTOGRAPHY.INDIVIDUAL')
  },
]);

const nodes: Array<NodeChart> = cartography.nodesData(dataMapChart.value, props.companyCountry);
const linksToAdd: Array<LinkChart> = cartography.linksData(dataMapChart.value, nodes);
const links = uniqBy(linksToAdd, link => `${link.source}-${link.target}`);

const translateKeyProperties = (key: string): string => {
  switch (key) {
    case 'companyName' :
      return translate('CARTOGRAPHY.BUSINESS_NAME');
    case 'companyIsInRadiation':
      return translate('CARTOGRAPHY.DEREGISTERED_COMPANY');
    case 'companyIsInCollectiveProceedings':
      return translate('CARTOGRAPHY.COMPANY_COLLECTIVE_PROCEDURE');
    case 'companyActivitiesCode':
      return translate('CARTOGRAPHY.COMPANY_ACTIVITIES_CODE');
    case 'companyActivitiesLabel':
      return translate('CARTOGRAPHY.COMPANY_ACTIVITIES_LABEL');
    default:
      return key;
  }
};

const translateValueProperties = (value: boolean | string): string => {
  return (typeof value !== 'boolean') ? value : (value ? translate('YES') : translate('NO'));
}

const formatCompanySiren = (siren: string) => {
  return formatSiren(props.companyCountry || 'FR', siren);
}

const fillTooltipContent = (tooltipContent: string, properties: object) => {
  const propertyOrder = ['companyName', 'companyActivitiesLabel', 'companyActivitiesCode', 'companyIsInCollectiveProceedings', 'companyIsInRadiation'];
  propertyOrder.forEach(key => {
    if (properties.hasOwnProperty(key)) {
      const translatedKey = translateKeyProperties(key);
      const translatedValue = translateValueProperties(properties[key]);
      switch (key) {
        case 'companyActivitiesCode' :
          tooltipContent += `<p style="margin: 0;">
                                <span style="text-decoration: underline;">${translatedKey}
                                </span> : ${!isNull(properties[key]) ? properties[key] : '-'}
                               </p>`;
          break;
        case 'companyIsInCollectiveProceedings' :
          tooltipContent += `<p style="margin: 0;">
                                <span style="text-decoration: underline;">${translatedKey}
                                </span> : ${!isNull(translatedValue) ? translatedValue : '-'} ${properties.companyIsInRecentCollectiveProceedings ? ` (${translate('CARTOGRAPHY.RECENTLY')})` : ''}
                               </p>`;
          break;
        default :
          tooltipContent += `<p style="margin: 0;"><span style="text-decoration: underline;">${translatedKey}</span> : ${!isNull(translatedValue) ? translatedValue : '-'}</p>`;
          break;
      }
    }
  });
  return tooltipContent;
}

const tooltips = (params: { data: NodeChart, dataType: string }) => {
  if (params.dataType === 'edge') { //no data for tooltip when it is a link
    return '';
  } else {
    const properties = dataMapChart.value?.find(item => item.label === params.data.originalName)?.properties || {};
    let tooltipContent = params.data.symbol === "diamond"
        ? `<p style="margin: 0;">
            <span style="text-decoration: underline;">${translate('IDENTITY.NAME')}
            </span>: ${params.data.originalName}
          </p>`
        : `<p style="margin: 0;">
           <span style="text-decoration: underline;">${translate('COMPANY.SIREN')}
           </span>: ${formatCompanySiren(params.data.originalName)}
          </p>`
    tooltipContent = fillTooltipContent(tooltipContent, properties);
    return tooltipContent;
  }
}

const adjustRepulsion = (nodes: {}[]) => {
  return nodes?.length > 10 ? 100 : 400;
}

const option = reactive({
  backgroundColor: 'white',
  tooltip: {
    trigger: 'item',
    formatter: function (params) {
      let tooltipContent = tooltips(params);
      if (tooltipContent === '') return; //do not display tooltip when mouse on a link
      return `<div style="max-width:500px; overflow-wrap: break-word; word-break: break-all; white-space: normal;">${tooltipContent}</div>`;
    }
  },
  series: [
    {
      type: 'graph',
      layout: 'force',
      force: {
        repulsion: adjustRepulsion(nodes)
      },
      label: {
        show: true,
        position: 'top',
      },
      data: nodes,
      links: links,
      lineStyle: {
        color: 'source',
        width: 2,
        type: 'solid',
        curveness: 0.1,
      },
      emphasis: {
        focus: 'series',
        lineStyle: {
          width: 5,
          color: 'blue',
          type: 'solid',
        }
      },
      zoom: `${zoom.value}`,
      //permit to move and zoom
      roam: true,
      // maw min to zoom
      scaleLimit: {
        min: 0.5,
        max: 3,
      }
    }
  ],
});

const sendSiren = (params: { data: { name: string, symbol: string } }): void => {
  if (params.data.symbol === "circle") {
    const siren = params.data.name;
    emit('siren-chart', siren, props.companyCountry);
  }
};

//if the user zoomed with mouse -> value of zoom can be 2.337545147
//so when zooming with buttons, take the rounded value and add 0.5 => zoom will be at 2.5 in this case
const getValue = (value: number) => {
  const decimalPart = value - Math.trunc(value);
  if (decimalPart === 0.5 || decimalPart === 0.0) {
    return value; //if the zoom is already at X.0 or X.5, do not round
  }
  mapId.value = new Date().getTime();
  return Math.round(value); //round up/down the value when clicking on button (if the user zoomed with mouse before)
}

const zoomMore = () => {
  if (zoom.value < 3) zoom.value = getValue(zoom.value) + 0.5;
};

const zoomLess = () => {
  if (zoom.value > 0.5) zoom.value = getValue(zoom.value) - 0.5;
}

const openModal = () => {
  modalFlag.value = !modalFlag.value;
}

const resetZoom = () => {
  zoom.value = 2; //need to reset the zoom when reducing the content card
}

//if the user zoomed with mouse, it is MapChart that will know, so need to update the zoom of the box
const zoomUpdated = (value: number) => {
  zoom.value = value;
}
</script>

<template>
  <ContentCard v-if="!canCartography" class="content-card-chart no-print" :title="translate('CARTOGRAPHY.COMPANY_CARTOGRAPHY')"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard class="content-card-chart" :title="translate('CARTOGRAPHY.COMPANY_CARTOGRAPHY')"
                 @content-card-closed="resetZoom">
      <div class="container-cartography-chart">
        <Loader v-if="!dataMapChart"/>
        <div v-else-if="dataMapChart.length > 0">
          <div class="legend-chart">
                <span class="legend-chart-item" v-for="(legendItem, index) in legendItems" :key="index">
                  <img :class="`${legendItem.class ? 'legend-chart-img-white-circle' : 'legend-chart-img'}`"
                       :src="`/images/${legendItem.image}`" :alt="legendItem.text">
                  {{ legendItem.text }}
                </span>
          </div>
          <div class="container-cartography-chart__graph">
            <div class="container-cartography-chart__graph__chart">
              <MapChart v-model:zoom="zoom" :key="mapId" :option="option" @send-data-type="sendSiren"
                        class="cartography-chart"
                        :formatter-function="option.tooltip.formatter" @zoom-update="zoomUpdated"/>
            </div>
            <div class="container-cartography-chart__graph__block-buttons">
              <Button class="button" @click="zoomLess" :label="'-'"/>
              <Button class="button" @click="zoomMore" :label="'+'"/>
              <Button class="button button-open" @click="openModal" :label="'[ ]'"/>
            </div>
          </div>
        </div>
        <div v-else class="container-chart-info">
          <span class="container-chart-info-no-info">{{ translate('ERRORS.NO_DATA') }}</span>
        </div>
      </div>
    </ContentCard>
    <template v-if="modalFlag">
      <CartographyModal v-model:open="modalFlag" :option="option" :formatter-function="option.tooltip.formatter"
                        @send-data-type="sendSiren"/>
    </template>
  </template>
</template>