import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0308fd09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "verification-details-result" }
const _hoisted_2 = { class: "verification-details-result-phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_verification_result = _resolveComponent("verification-result")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_verification_result, {
        value: $props.phoneExists === 'OK' || $props.phoneExists === 'true',
        label: $props.labelResult,
        type: $props.typeResult,
        tooltip: $props.tooltip
      }, null, 8, ["value", "label", "type", "tooltip"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_col, null, {
              default: _withCtx(() => [
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString($options.translate('TELEPHONE.LINETYPE')) + " : ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.phoneLinetype), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString($options.translate('TELEPHONE.TIMEZONE')) + " : ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.phoneTimezone), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString($options.translate('TELEPHONE.CARRIER')) + " : ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.phoneCarrier), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString($options.translate('TELEPHONE.ORIGINAL_CARRIER')) + " : ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.phoneOriginalCarrier), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_b_col, null, {
              default: _withCtx(() => [
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString($options.translate('TELEPHONE.GEOLOCATION')) + " : ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.phoneGeolcation), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString($options.translate('TELEPHONE.OWNER')) + " : ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.phoneOwner), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("span", null, _toDisplayString($options.translate('TELEPHONE.NUMBER')) + " : ", 1),
                  _createElementVNode("span", null, _toDisplayString($props.phoneNumber), 1)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}