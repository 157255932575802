<script setup lang="ts">
import {translate} from "@/i18n";
import ContentCard from "@/components/card/ContentCard.vue";
import Loader from "@/components/loader/Loader.vue";
import {computed} from "vue";
import {isObject} from "lodash";

const props = withDefaults(defineProps<{
  amlcft: Object,
  showContent: boolean,
  canAmlcft: boolean
}>(), {
  showContent: false,
  canAmlcft: false
});

const isAmlcft = computed(() => {
  return isObject(props.amlcft);
});

const getNumberClass = ((str: string) => {
  return str.slice(-1);
})
</script>
<template>
  <ContentCard v-if="!canAmlcft" :checked-display-content="false" :is-padlock="true" class="applicant-b2c"
               :title="translate('AMLCFT.TITLE')"/>
  <template v-else>
    <ContentCard :title="translate('AMLCFT.TITLE')" class="special-block">
      <div v-if="isAmlcft">
        <div v-if="Object.keys(amlcft).includes('amlTypes')" class="block-amlcft-content">
          <template v-for="(type, index) in amlcft.amlTypes" :key="index">
            <div v-if="type === 'sanction'">
              <span class="block-amlcft-title">{{ translate('AMLCFT.SANCTIONED') }} :</span>
              <div>
                <p class="block-amlcft-alert">{{ translate('AMLCFT.SANCTIONED_ALERT') }}</p>
                <p>{{ translate('AMLCFT.SANCTIONED_EXPLANATIONS') }}</p>
              </div>
            </div>
            <div v-if="type === 'pep'">
              <span class="block-amlcft-title">{{ translate('AMLCFT.PPE') }} :</span>
              <div>
                <p class="block-amlcft-alert">{{ translate('AMLCFT.PPE_ALERT') }}</p>
                <template v-for="(pep, key) in amlcft.amlTypesDetails.pep" :key="key">
                  <p>
                    <span class="block-amlcft-level">{{
                        `${translate('AMLCFT.LEVEL')} ${getNumberClass(pep)} - `
                      }}</span>
                    {{ translate(`AMLCFT.CLASS_${getNumberClass(pep)}`) }}
                  </p>
                </template>
              </div>
            </div>
            <div v-if="type === 'adverse_media'" class="block-amlcft-adverse_media">
              <span class="block-amlcft-title">{{ translate('AMLCFT.ADVERSE_MEDIA') }}</span>
              <div>
                <p class="block-amlcft-alert">{{ translate('AMLCFT.ADVERSE_MEDIA_ALERT') }}</p>
                <p class="block-amlcft-title">{{ translate('AMLCFT.ADVERSE_MEDIA_TYPE') }}</p>
                <div class="block-amlcft-types">
                  <template v-for="(adverse_media, index) in amlcft.amlTypesDetails.adverse_media"
                            :key="index">
                    <span class="block-amlcft-adverse_details">{{
                        translate(`AMLCFT.ADVERSE_MEDIA_DETAILS.${adverse_media}`)
                      }}</span>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <div v-if="amlcft.links.length > 0">
            <span class="block-amlcft-title">{{ translate('AMLCFT.LINKS') }} :</span>
            <ul>
              <li v-for="link in amlcft.links.slice(0,4)" :key="link">
                <a :href="link" target="_blank">
                  {{ link }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="showContent && !amlcft" class="block-amlcft-message">
        <span>{{ translate('AMLCFT.NO_RESULT') }}</span>
      </div>
      <Loader v-if="!showContent"/>
    </ContentCard>
  </template>
</template>