<template>
  <footer :class="`Footer ${white && 'Footer__white' || ''}`">
    <template v-if="isRight">
      <span>{{ `${translate('SUPPORT_MESSAGE')} : ` }}</span>
      <a href="mailto:support@getmeelo.com">support@getmeelo.com</a>
      <span>{{ ` - ` }}</span>
      <a href="tel:+33392252730">+33 3 92 25 27 30</a>
    </template>
    <template v-else>
      <span>© Meelo - </span>
      <span>
      {{ translate('CGS.BEGINNING') }}
      <a href="https://www.getmeelo.com/politique-de-confidentialite/"
         target="_blank">{{ translate('CGS.LINK') }}</a>
      {{ translate('CGS.END') }}
    </span>
    </template>
  </footer>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {translate} from '@/i18n';

export default defineComponent({
  name: 'GeneralCondition',
  methods: {
    translate
  },
  props: {
    white: {
      type: Boolean,
      default: false
    },
    isRight: {type: Boolean, default: false}
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.Footer {
  text-align: center;
  font-size: 0.8rem;
  padding: 1rem;
  color: $blueMeelo;

  &__white {
    color: white;
  }
}
</style>
