<script setup lang="ts">
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import Select from "@/components/select/Select.vue";
import InlineTextInput from "@/components/input/InlineTextInput.vue";
import {computed, onBeforeUnmount, onMounted, onUpdated, ref, watch} from "vue";
import {states} from "@/utils/statesFolder";
import {Motive} from "@/utils/journeyUtils";
import {isEmpty} from "lodash";

const props = withDefaults(
    defineProps<{
      mode: 'create' | 'delete' | 'edit';
      motive: Motive;
    }>(), {}
);

// Flag
const shouldResetSelect = ref(false);

const createdMotive = ref({} as Motive);
const updatedMotive = ref({} as Motive);
const oldUpdatedMotive = ref({} as Motive);

const openModal = defineModel('openModal', {default: false, type: Boolean});
const emit = defineEmits(['update-motive-label', 'close-modal', 'confirm-selection']);

const isCreateMotiveButtonDisabled = computed(() => {
  return isEmpty(createdMotive.value) || createdMotive.value.label?.length === 0;
});

const isUpdateMotiveButtonDisabled = computed(() => {
  return updatedMotive.value.label?.length === 0 || (oldUpdatedMotive.value.label === updatedMotive.value.label);
});

const closeModal = () => {
  resetCreatedMotive();
  shouldResetSelect.value = true;
  openModal.value = !openModal.value;
  emit('close-modal');
}

const confirmModal = () => {
  const confirmCreate = createdMotive.value;
  resetCreatedMotive();
  emit('confirm-selection', confirmCreate, updatedMotive.value, props.mode);
}

const handleModalClose = () => {
  resetCreatedMotive();
}

const computeNewState = (value: string) => {
  createdMotive.value.status = value;
}

const resetCreatedMotive = () => {
  shouldResetSelect.value = true;
  createdMotive.value = {} as Motive;
}

watch(() => props.motive, () => {
  oldUpdatedMotive.value = {
    label: props.motive.label
  };
}, {deep: true});

onUpdated(() => {
  updatedMotive.value = {
    id: props.motive.id,
    label: props.motive.label
  };
});
</script>
<template>
  <CustomModal v-model:open="openModal" @close-modal="handleModalClose">
    <template v-if="props.mode === 'create' ">
      <ResultCard :title="translate('MOTIVE.MODAL.CREATE_TITLE')" :display-arrows="false">
        <div class="create-form-grid">
          <InlineTextInput class="create-form-grid__text" :label="`${translate('MOTIVE.TABLE.LABEL')} *`"
                           v-model="createdMotive.label"/>
          <Select class="create-form-grid__select" :title="`${translate('MOTIVE.TABLE.STATUS')}`" :empty-option="true"
                  v-model="createdMotive.status"
                  :values="states"
                  @option-selected="computeNewState"
                  :resetValue="shouldResetSelect"
                  :options="states.map(state => translate(`FOLDER.STATE.${state}`))"/>
        </div>
        <div class="actions">
          <Button class="cancel" :label="translate('BUTTONS.CANCEL')" @click="closeModal"/>
          <Button class="confirm" :disabled="isCreateMotiveButtonDisabled" :label="translate('BUTTONS.CONFIRM')"
                  @click="confirmModal"/>
        </div>
      </ResultCard>
    </template>
    <template v-else-if="props.mode === 'edit' ">
      <ResultCard :title="translate('MOTIVE.MODAL.UPDATE_TITLE')" :display-arrows="false">
        <div class="create-form-grid">
          <InlineTextInput class="create-form-grid__text" :label="translate('MOTIVE.TABLE.LABEL')"
                           v-model="updatedMotive.label"/>
        </div>
        <div class="actions">
          <Button class="cancel" :label="translate('BUTTONS.CANCEL')" @click="closeModal"/>
          <Button class="confirm" :disabled="isUpdateMotiveButtonDisabled" :label="translate('BUTTONS.CONFIRM')"
                  @click="confirmModal"/>
        </div>
      </ResultCard>
    </template>
    <template v-else-if="props.mode === 'delete' ">
      <ResultCard :title="translate('MOTIVE.MODAL.DELETE_TITLE')" :display-arrows="false">
        <p class="delete-folder-modal__first-paragraph">
          <span>{{ translate('FOLDER.DELETION.CONTENT.FIRST') }}</span>
          <span>{{ ` ${translate('MOTIVE.THIS')} : ` }}</span>
          <span class="delete-folder-modal__first-paragraph__underline">{{ updatedMotive.label }}</span>
        </p>
        <p class="delete-folder-modal__second-paragraph delete-folder-modal__first-paragraph__underline delete-folder-modal__first-paragraph__bold">
          {{
            translate('BACKOFFICE.USERS.DELETE.ACTION_IRREVERSIBLE')
          }}</p>
        <p class="delete-folder-modal__third-paragraph">{{ translate('FOLDER.DELETION.CONTENT.QUESTION_MOTIVE') }}</p>
        <div class="actions">
          <Button class="cancel" :label="translate('BUTTONS.CANCEL')" @click="closeModal"/>
          <Button class="confirm" :label="translate('BUTTONS.CONFIRM')" @click="confirmModal"/>
        </div>
      </ResultCard>
    </template>
  </CustomModal>
</template>