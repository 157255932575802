import {log} from "echarts/types/src/util/log";

export let isLocalhost = window.location.hostname.includes("localhost");
let isDev = window.location.hostname.includes("dev") || isLocalhost;
let isStg = window.location.hostname.includes("stg");
let isTest = window.location.hostname.includes("tst");

let envName = (isDev ? "dev." : (isStg ? ("stg.") : (isTest ? ("tst.") : "")));

const getEnv = (name: string) => process.env[name];

export default {
  BACKEND_ROUTE: getEnv("VUE_APP_BACKEND_ROUTE") || "/api",
  FRISK_ROUTE: getEnv("VUE_APP_FRISK_ROUTE") || "https://" + envName + "frisk.getmeelo.com",
  OPENBANKING_ROUTE: getEnv("VUE_APP_OPENBANKING_ROUTE") || "https://" + envName + "openbanking.api.getmeelo.com",
  CONTEXT_ROUTE: getEnv("VUE_APP_CONTEXT_ROUTE") || "https://" + envName + "context.api.getmeelo.com",
  RULES_ENGINE_ROUTE: getEnv("VUE_APP_RULES_ENGINE_ROUTE") || "https://" + envName + "rules-engine.api.getmeelo.com",
  TAX_REPORT_ANALYSIS_ROUTE: getEnv("VUE_APP_TAX_REPORT_ANALYSIS_ROUTE") || "https://" + envName + "tax-report-analysis.api.getmeelo.com",
  UPLOAD_ROUTE: "https://" + envName + "upload.getmeelo.com",
  ALERTS_ROUTE: getEnv("VUE_APP_ALERT") || "https://" + envName + "alert.api.getmeelo.com",
  MARKETING_TYPE: {
    B2B: 'B2B',
    B2C: 'B2C',
    BOTH: 'BOTH'
  },
  OPENBANKING_CALLBACK_URL: window.location.origin + "/open-banking-end",
  CHECK_COMPANY_ROUTE: getEnv("VUE_APP_CHECK_COMPANY_ROUTE") || "https://" + envName + "check-company.api.getmeelo.com",
  SCORING_ROUTE: getEnv("VUE_APP_SCORING_ROUTE") || "https://" + envName + "scoring.api.getmeelo.com",
  DOCUMENT_ROUTE: getEnv("VUE_APP_DOCUMENT") || "https://" + envName + "document.api.getmeelo.com",
  AMLCFT_ROUTE: getEnv("VUE_APP_AML_CFT") || "https://" + envName + "aml-cft.api.getmeelo.com",
  TAX_NOTICE_ANALYSIS_ROUTE: getEnv("VUE_APP_TAX_NOTICE_ANALYSIS") || "https://" + envName + "tax-notice-analysis.api.getmeelo.com",
  CHECK_VALUE_ROUTE: getEnv("VUE_APP_CHECK_VALUE") || "https://" + envName + "check-value.api.getmeelo.com",
  TOOLBOX_ROUTE: getEnv("VUE_APP_TOOLBOX") || "https://" + envName + "toolbox.api.getmeelo.com",
  TRANSLATION_ROUTE: getEnv("VUE_APP_TRANSLATE") || "https://" + envName + "translate.api.getmeelo.com",
  TRACKING_HOST: getEnv("VUE_APP_TRACKING_HOST") || "https://" + envName + "tracking.getmeelo.com",
  TRACKING_FINGERPRINT_SCRIPT_URL: getEnv("VUE_APP_TRACKING_FINGERPRINT_SCRIPT_URL") || 'https://s3.fr-par.scw.cloud/public.bucket.getmeelo.com/tracking/fingerprint.min.js',
  TRACKING_BEHAVIOUR_SCRIPT_URL: getEnv("VUE_APP_TRACKING_BEHAVIOUR_SCRIPT_URL") || 'https://s3.fr-par.scw.cloud/public.bucket.getmeelo.com/tracking/behavior-analytics.min.js',
  ENRICHMENT_ROUTE: getEnv("VUE_APP_ENRICHMENT") || "https://" + envName + "enrichment.api.getmeelo.com",
  MAIL_FRAUD_REPORT: getEnv("MAIL_FRAUD_REPORT") || 'fraud-report@getmeelo.com',
  FACE_MATCHING: getEnv("VUE_APP_FACE_MATCHING") || "https://" + envName + "face-matching.api.getmeelo.com",
  
  DOPPLER_ENVIRONMENT: getEnv("VUE_APP_DOPPLER_ENVIRONMENT") || 'SNAPSHOT',
  APM: {
    ENABLED: getEnv("VUE_APP_APM_ENABLED") ? getEnv("VUE_APP_APM_ENABLED") === 'true' : false,
    SERVER_URL: getEnv("VUE_APP_APM_SERVER_URLS") || 'https://apm.getmeelo.com',
    SERVICE_NAME: getEnv("VUE_APP_APM_SERVICE_NAME") || 'PORTAL_FRONTEND',
  },
  CHECK_IBAN_ROUTE: getEnv("VUE_APP_CHECK_IBAN") || "https://" + envName + "check-iban.api.getmeelo.com",
}