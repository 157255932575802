export type ConfigType = {
  [key: string]: {
    newLabel: string;
    children?: ConfigType;
    defaultValue?: Function;
  };
};

export const transformData =
  (change: ConfigType): any =>
  (data: any) => {
    return Object.entries(change).reduce((acc, [key, trans]) => {
      if (!trans.children) {
        return {
          ...acc,
          [trans.newLabel]: trans.defaultValue
            ? trans.defaultValue(data)
            : data[key],
        };
      }
      return {
        ...acc,
        [trans.newLabel]: !Array.isArray(data[key])
          ? transformData(trans.children)(data[key])
          : data[key].map((el: any) => transformData(trans.children!)(el)),
      };
    }, {});
  };

const data = {
  type: "Natural Person",
  address: {
    city: "Wattignies",
    address: "2 rue du Trianon",
    country: "FRANCE",
    zipcode: "59139",
  },
  lastName: "Martin Coppola",
  position: "Directeur général",
  companies: [
    {
      checks: [
        {
          label: "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY",
          value: true,
          details: [2018, 2019, 2020, 2022, 2023],
          description:
            "Période d’inactivité supérieure à 3 ans au cours des 10 dernières années",
        },
      ],
    },
  ],
  firstNames: "Barbara",
  dateOfBirth: "1976-11-27",
  nationality: "Espagnole",
  placeOfBirth: "madrid Espagne",
};

const change: ConfigType = {
  type: {
    newLabel: "typus",
  },
  lastName: {
    newLabel: "lastNamus",
  },
  firstNames: {
    newLabel: "firstNamus",
  },
  dateOfBirth: {
    newLabel: "dateOfBirthus",
  },
  namus: {
    newLabel: "namus",
    defaultValue: (changeData: any): string =>
      changeData["firstNames"] + " " + changeData["lastName"],
  },
  companies: {
    newLabel: "companus",
    children: {
      checks: {
        newLabel: "checkus",
        children: {
          label: {
            newLabel: "labelus",
          },
          value: {
            newLabel: "valus",
          },
          details: {
            newLabel: "detailus",
          },
          resume: {
            newLabel: "resumus",
            defaultValue: (checksData: any): string =>
              checksData["label"] + " " + checksData["description"],
          },
        },
      },
    },
  },
  address: {
    newLabel: "addressus",
    children: {
      city: {
        newLabel: "citus",
      },
      address: {
        newLabel: "addressus",
      },
      country: {
        newLabel: "countrus",
      },
      zipcode: {
        newLabel: "zipcodus",
      },
    },
  },
};
