import {createApp} from 'vue';

import router from "./router";
import VueCookies from 'vue-cookies';
import App from './App.vue';
import store from './store';
import {BootstrapVue3} from "bootstrap-vue-3";
import {BootstrapIconsPlugin} from 'bootstrap-icons-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import VueAxios from "vue-axios";
import axios from "axios";
import VueJsonPretty from "vue-json-pretty";
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import {VueZo} from './plugins/vue-zo';
import {ApmVuePlugin} from '@elastic/apm-rum-vue';
import envConfig from "@/utils/envConfig";

// @ts-ignore
import i18n from './i18n';

createApp(App)
  .use(i18n)
  .use(router)
  .use(VueCookies)
  .use(store)
  .use(BootstrapVue3)
  .use(ApmVuePlugin, {
      router,
      config: {
          serviceName: envConfig.APM.SERVICE_NAME,
          serverUrl: envConfig.APM.SERVER_URL,
          active: envConfig.APM.ENABLED,
          environment: envConfig.DOPPLER_ENVIRONMENT
      }
  })
  .use(BootstrapIconsPlugin)
  .use(VueAxios, axios)
  .use(VueJsonPretty)
  .use(ToastPlugin)
  .use(VueZo)
  .mount('#app');
	