import {translate} from "@/i18n";
import {Checks} from "@/utils/kbisResults";

export function useKbis() {
  const mainActivities = (checks: Checks) => {
    return [{
      label: translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.ACTIVITY'),
      value: checks.MAIN_ACTIVITY.activity
    }, {
      label: translate('DOCUMENT_CONTROL.TABLE.RESULT.KBIS.ADDRESS'),
      value: checks.MAIN_ACTIVITY.address
    }];
  }

  const identificationResults = (checks: Checks) => {
    const identification = checks.IDENTIFICATION as any;
    const keys = [
      'siren',
      'registrationDate',
      'companyName',
      'headQuarterAddress'
    ] as string[];
    const labels: any = {
      siren: 'DOCUMENT_CONTROL.TABLE.RESULT.KBIS.SIREN',
      registrationDate: 'DOCUMENT_CONTROL.TABLE.RESULT.KBIS.REGISTRATION_DATE',
      companyName: 'DOCUMENT_CONTROL.TABLE.RESULT.KBIS.COMPANY_NAME',
      headQuarterAddress: 'DOCUMENT_CONTROL.TABLE.RESULT.KBIS.HEAD_QUARTER_ADDRESS'
    };

    return keys.map(key => ({
      label: translate(labels[key]),
      value: identification[key]
    }));
  }

  const getMembersResults = (checks: Checks) => {
    return checks.MEMBERS.map((item) => {
      const result: Array<Object> = [];

      if (item.type === 'Natural person') {
        const fields = [
          {key: 'IDENTITY', value: item.identity},
          {key: 'POSITION', value: item.title},
          {key: 'BIRTHDATE', value: item.birthDate},
          {key: 'ADDRESS', value: item.address}
        ];

        fields.forEach(field => {
          result.push({
            label: translate(`DOCUMENT_CONTROL.TABLE.RESULT.KBIS.${field.key}`),
            value: field.value
          });
        });
      } else if (item.type === 'Legal person') {
        const fields = [
          {key: 'COMPANY_NAME', value: item.companyName},
          {key: 'SIREN', value: item.siren},
          {key: 'POSITION', value: item.title},
          {key: 'HEAD_QUARTER_ADDRESS', value: item.headQuarterAddress}
        ];

        fields.forEach(field => {
          result.push({
            label: translate(`DOCUMENT_CONTROL.TABLE.RESULT.KBIS.${field.key}`),
            value: field.value
          });
        });
      }

      return result;
    });
  }


  return {mainActivities, identificationResults, getMembersResults};
}