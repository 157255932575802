const mergeCompanyInfoDivElements = () => {
  const companyInfoCard = document.querySelector(".content-card.company-info");
  const content = companyInfoCard?.querySelector(".content-card-content");
  const companyInfoContent = content?.querySelector(".company-info-content");
  Array.from(companyInfoContent?.children[1].children || []).forEach(
    (activityOrWebsite) => {
      activityOrWebsite?.classList.add('breakinside')
      companyInfoContent?.children[0].appendChild(activityOrWebsite);
    }
  );
};

const mergeOpenBankingAnalysis = () => {
  const ob = document.querySelector(
    ".content-card.container-openbanking-lite-result"
  );
  const obContent = ob?.querySelector(".container-openbanking-content");
  Array.from(
    obContent?.children[1].querySelectorAll(".data-printer") || []
  ).forEach((dataPrinter) => {
    obContent?.children[0].appendChild(dataPrinter);
  });
};

const teleportScoreToClientInfo = () => {
  const scoreContainer = document.querySelector(".content-card.score-smb");
  const score = scoreContainer?.querySelector(".single-chart");
  const clientInfoContainer = document.querySelector(
    ".content-card.synthesis-applicant"
  );
  const clientInfoContent = clientInfoContainer?.querySelector(
    ".synthesis-applicant-left-block"
  );
  if (score) clientInfoContent?.appendChild(score);
};

export const formatDocumentBeforePrint = () => {
  mergeCompanyInfoDivElements();
  mergeOpenBankingAnalysis();
  teleportScoreToClientInfo();
};
