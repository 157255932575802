<template>
  <div>
    <div v-if="hasPagination" class="page-table__size-select">
      {{ translate('TABLE.PER_PAGE') }}
      <select :value="page.size.toString()"
              v-on:change="updateSize(parseInt($event.target.value))"
              class="page-table__select">
        <option v-for="size in [5, 10, 20, 50, 100]" :key="size" :value="size">
          {{ size }}
        </option>
      </select>
    </div>
    <ListTable :content="page.content"
               :columns="columns"
               :white="white"
    />
    <PageTablePagination v-if="hasPagination" :page="page"/>
  </div>
</template>
<script lang="ts">
import {defineComponent, h} from 'vue';
import {IPageResponse} from '@/types/Page';
import PageTablePagination from '@/components/table/Pagination.vue';
import {translate} from '@/i18n';
import Select from '@/components/select/Select.vue';
import ListTable, {Column} from '@/components/table/ListTable.vue';

export default defineComponent({
  name: 'PageTable',
  components: {ListTable, Select, PageTablePagination},
  props: {
    page: {type: Object as () => IPageResponse, required: true},
    columns: {type: Array as () => Column[], required: true},
    white: {type: Boolean, default: false},
    _renderedItem: {type: Object, default: null},
    hasPagination: {type: Boolean, default: false},
  },
  methods: {
    translate, h,
    updateSize(size: number) {
      this.$router.push({
        query: {
          ...this.$route.query,
          size: size.toString()
        }
      })
    }
  }
});
</script>
