import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "manage-places" }
const _hoisted_2 = { class: "manage-places__title" }
const _hoisted_3 = { class: "manage-places__subtitle" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "manage-places__edit-modal__buttons" }
const _hoisted_7 = { class: "manage-places__delete-modal__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_MeeloButton = _resolveComponent("MeeloButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PageTable = _resolveComponent("PageTable")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.translate('BACKOFFICE.PLACES.COMPANY_PLACES')), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(`${_ctx.translate('RESEARCH')} :`), 1),
    _createElementVNode("form", {
      class: "manage-places__search",
      onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.refresh && _ctx.refresh(...args)))
    }, [
      _createElementVNode("div", null, [
        _createVNode(_component_TextInput, {
          label: `${_ctx.translate('BACKOFFICE.PLACES.NAME')} :`,
          "model-value": _ctx.search.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({query: {..._ctx.$route.query, name: $event === '' ? undefined : $event}}))),
          color: "light"
        }, null, 8, ["label", "model-value"])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_MeeloButton, {
          label: _ctx.translate('BUTTONS.RESET'),
          type: "reset",
          outlined: "",
          white: ""
        }, null, 8, ["label"]),
        _createVNode(_component_MeeloButton, {
          label: _ctx.translate('BUTTONS.SEARCH'),
          type: "submit",
          outlined: "",
          white: ""
        }, null, 8, ["label"])
      ])
    ], 32),
    _createVNode(_component_Button, {
      class: "manage-places__add",
      label: _ctx.translate('BUTTONS.ADD'),
      picture: "plus.png",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openEditModal = true))
    }, null, 8, ["label"]),
    (_ctx.page)
      ? (_openBlock(), _createBlock(_component_PageTable, {
          key: 0,
          page: _ctx.page,
          columns: [{
      title: _ctx.translate('BACKOFFICE.PLACES.NAME'),
      render: ({name}) => ({value: name})
    },{
      title: _ctx.translate('BACKOFFICE.PLACES.DESCRIPTION'),
      render: ({description}) => ({value: description})
    },{
      title: _ctx.translate('BACKOFFICE.PLACES.ACTIONS'),
      render: (item) => ({
        component: _ctx.BackOfficeGroupActions,
        events: {
          edit: ()=> _ctx.edit(item),
          delete: () => {
            _ctx.selectedPlace = item;
            this.delete();
          }
        },
        props: {
          see: false
        }})
    }],
          white: ""
        }, null, 8, ["page", "columns"]))
      : _createCommentVNode("", true),
    _createVNode(_component_CustomModal, {
      open: _ctx.openEditModal,
      "onUpdate:open": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.openEditModal) = $event)),
      class: "manage-places__edit-modal",
      displayCloseButton: true
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)))
        }, [
          (!_ctx.selectedPlace)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_4, _toDisplayString(_ctx.translate('BACKOFFICE.PLACES.CREATE')), 1))
            : (_openBlock(), _createElementBlock("h1", _hoisted_5, _toDisplayString(_ctx.translate('BACKOFFICE.PLACES.EDIT')) + " " + _toDisplayString(_ctx.selectedPlace.name), 1)),
          _createVNode(_component_TextInput, {
            label: `${_ctx.translate('BACKOFFICE.PLACES.NAME')} :`,
            "model-value": _ctx.editRequest.name,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editRequest.name) = $event)),
            type: _ctx.TextInputType.UPPER_CASE,
            required: ""
          }, null, 8, ["label", "model-value", "type"]),
          _createVNode(_component_TextInput, {
            label: `${_ctx.translate('BACKOFFICE.PLACES.DESCRIPTION')} :`,
            "model-value": _ctx.editRequest.description,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editRequest.description) = $event))
          }, null, 8, ["label", "model-value"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Button, {
              label: _ctx.translate('BUTTONS.VALIDATE'),
              type: "submit"
            }, null, 8, ["label"])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["open"]),
    _createVNode(_component_CustomModal, {
      open: _ctx.openDeleteModal,
      "onUpdate:open": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.openDeleteModal) = $event)),
      class: "manage-places__delete-modal"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, _toDisplayString(_ctx.translate('BACKOFFICE.PLACES.DELETE_WARN')), 1),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Button, {
            label: _ctx.translate('BUTTONS.CANCEL'),
            type: "button",
            onClick: _cache[7] || (_cache[7] = ()=>{
          _ctx.openDeleteModal = false;
          _ctx.selectedPlace = undefined;
        })
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.translate('BUTTONS.VALIDATE'),
            type: "button",
            onClick: _cache[8] || (_cache[8] = ()=>this.delete(true))
          }, null, 8, ["label"])
        ])
      ]),
      _: 1
    }, 8, ["open"])
  ]))
}