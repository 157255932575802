import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: require(`../../../public/images/${_ctx.copyToClipboardSrc}`),
    class: "copy_img no-print",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.copyValueToClipboard && _ctx.copyValueToClipboard(...args))),
    alt: "copy_img"
  }, null, 8, _hoisted_1))
}