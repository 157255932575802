<template>
  <div class="verification-details-result">
    <verification-result :value="phoneExists === 'OK' || phoneExists === 'true'" :label="labelResult" :type="typeResult" :tooltip="tooltip"/>
  </div>
  <div class="verification-details-result-phone">
    <ul>
      <b-row>
        <b-col>
          <li><span>{{ translate('TELEPHONE.LINETYPE') }} : </span><span>{{ phoneLinetype }}</span></li>
          <li><span>{{ translate('TELEPHONE.TIMEZONE') }} : </span><span>{{ phoneTimezone }}</span></li>
          <li><span>{{ translate('TELEPHONE.CARRIER') }} : </span><span>{{ phoneCarrier }}</span></li>
          <li><span>{{ translate('TELEPHONE.ORIGINAL_CARRIER') }} : </span><span>{{ phoneOriginalCarrier }}</span></li>
        </b-col>
        <b-col>
          <li><span>{{ translate('TELEPHONE.GEOLOCATION') }} : </span><span>{{ phoneGeolcation }}</span></li>
          <li><span>{{ translate('TELEPHONE.OWNER') }} : </span><span>{{ phoneOwner }}</span></li>
          <li><span>{{ translate('TELEPHONE.NUMBER') }} : </span><span>{{ phoneNumber }}</span></li>
        </b-col>
      </b-row>
    </ul>
  </div>
</template>

<script lang="ts">
import VerificationResult from "@/components/VerificationResult.vue";
import {translate} from '@/i18n';

export default {
  name: "VerificationDetails",
  methods: {translate},
  components: {VerificationResult},
  props: {
    labelResult: {type: String, default: ''},
    typeResult: {type: String, default: ''},
    phoneLinetype: {type: String, default: ''},
    phoneTimezone: {type: String, default: ''},
    phoneCarrier: {type: String, default: ''},
    phoneOriginalCarrier: {type: String, default: ''},
    phoneGeolcation: {type: String, default: ''},
    phoneOwner: {type: String, default: ''},
    phoneNumber: {type: String, default: ''},
    phoneExists: {type: String, default: ''},
    tooltip: {type: String, default: ''},
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.verification-details-result{
  margin-bottom: 1rem;

  &-phone {
    li {
      font-size: $fontSize;
    }
  }
}
</style>
