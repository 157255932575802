<script setup lang="ts">
import PieChart from "@/components/chart/PieChart.vue";
import Loader from "@/components/loader/Loader.vue";
import {translate} from "@/i18n";
import {size} from "lodash";
import {computed} from "vue";

const props = withDefaults(defineProps<{
  totalUsage: Object,
  totalValueUsage: any[],
  totalLabelUsage: any[],
  colorUsage: string[],
  consumptionToDisplay: string
  isMonitoringDataLoading: boolean
}>(), {
  isMonitoringDataLoading: false
});

const isConsumptionData = computed(() => {
  return size(props.totalUsage) > 0 && size(props.totalLabelUsage) > 0 && size(props.totalValueUsage) > 0;
});

const moreThanOneConsumptionData = computed(() => {
  return size(props.totalUsage) > 1 || size(props.totalLabelUsage) > 1 || size(props.totalValueUsage) > 1;
});
</script>

<template>
  <div class="monitoring__my-usage__charts">
    <div class="monitoring__my-usage__charts__total-usage">
      <template v-if="isMonitoringDataLoading">
        <Loader/>
        <span>{{ translate('BACKOFFICE.MONITORING.DATA_ON_THE_WAY') }}</span>
      </template>
      <template v-else>
        <span v-if="moreThanOneConsumptionData">{{ translate(`BACKOFFICE.MONITORING.CONSUMPTION.${consumptionToDisplay}`) }}</span>
        <div :class=" isConsumptionData ? 'monitoring__my-usage__charts__total-usage__chart': 'monitoring__my-usage__charts__total-usage__chart__no-data'">
          <PieChart v-if="isConsumptionData" :data-chart="totalValueUsage"
                    :abscissa-chart="totalLabelUsage"
                    :label-chart="translate('BACKOFFICE.MONITORING.TOTAL_CONSUMPTION')"
                    :background-color-chart="colorUsage"/>
          <div v-else>
            <span>
              {{ translate('BACKOFFICE.MONITORING.NO_DATA') }}
            </span>
          </div>
        </div>
      </template>
    </div>
    <!--      <div class="monitoring__my-usage__charts__evolution-curve">
            &lt;!&ndash;        <span>{{ translate('BACKOFFICE.MONITORING.MONTHLY_EVOLUTION_CURVE') }}</span>&ndash;&gt;
            &lt;!&ndash;      TODO: add chart&ndash;&gt;
          </div>-->
  </div>
</template>