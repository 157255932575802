<script setup lang="ts">
import {defineProps, ref} from "vue";
import {translate} from "@/i18n";
import ContentCard from "@/components/card/ContentCard.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import ScoreCircleChart from "@/components/ScoreCircleChart.vue";
import {CausesAltares} from "@/utils/companyScoreAltares";

const props = withDefaults(defineProps<{
  scoreAltares: number,
  causesAltares: CausesAltares,
  colorAltares: string,
  colorScoreSector: string,
  colorDefaultProbability: string,
  canScoreAltares: boolean
}>(), {
  colorAltares: '#D9D9D9',
  colorScoreSector: '#D9D9D9',
  colorDefaultProbability: '#D9D9D9',
  canScoreAltares: false
});

const decisionFinal = ref(false);

const displayHelperText = (decision: boolean) => {
  if (decision) decisionFinal.value = true;
}

const scoreCirclecharts = [
  {
    class: 'circle-chart score-risk',
    title: translate('SCORE.COMPANY_TITLE'),
    tooltip: translate('TOOLTIP.SCORE_COMPANY'),
    value: Math.round(<number>props.scoreAltares),
    color: props.colorAltares
  },
  {
    class: 'circle-chart score-sector',
    title: translate('SCORE.SECTOR_TITLE'),
    tooltip: translate('TOOLTIP.SCORE_SECTOR'),
    value: Math.round(props.causesAltares.scoreSector),
    color: props.colorScoreSector
  },
  {
    class: 'circle-chart default-probability',
    title: translate('SCORE.DEFAULT_TITLE'),
    tooltip: translate('TOOLTIP.DEFAULT_PROBABILITY'),
    value: props.causesAltares.defaultProbability,
    color: props.colorDefaultProbability
  }
]
</script>

<template>
  <ContentCard v-if="!canScoreAltares" :title="translate('SCORING_ALTARES')" class="scoring-altares"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard :title="translate('SCORING')" class="scoring-altares">
      <div v-if="causesAltares && Object.keys(causesAltares).length > 0">
        <div class="scoring-altares-input">
          <LabelValuePrinter :label="translate('LAST_UPDATE_DATE')" :value="causesAltares.lastUpdate"/>
          <LabelValuePrinter class="financial-analysis-bloc-recommended-outstanding"
                             :label="translate('COMPANY.RECOMMENDED_OUTSTANDING')"
                             :value="causesAltares.recommendedOutstanding + ' €'"/>
        </div>
        <div class="scoring-altares-score">
          <template v-for="score in scoreCirclecharts">
            <ScoreCircleChart
                :class="score.class"
                :circle-value="score.value"
                :circle-width="'60%'"
                :circle-title="score.title"
                :tooltip="score.tooltip"
                :circle-color="score.color"
                :helper-text-display="false"
                @as-text="displayHelperText"/>
          </template>
        </div>
      </div>
      <div v-if="decisionFinal" class="score-helper-text no-print">
        <span>{{ translate("SCORE.HELPER_TEXT") }}</span>
      </div>
      <slot/>
    </ContentCard>
  </template>
</template>
