<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import {computed} from "vue";
import Loader from "@/components/loader/Loader.vue";
import {isObject} from "lodash";

const props = withDefaults(defineProps<{
  warningList: Object,
  isLoading: boolean
}>(), {
  isLoading: false
});

const warningListRegistrationNumber = computed(() => {
  return isObject(props.warningList) && props.warningList.hasOwnProperty('registrationNumber') ? props.warningList.registrationNumber === 0 : props.warningList.siren === 0;
});

const hasData = computed(() => {
  return isObject(props.warningList) && !(props.warningList.email === 0 && props.warningList.phone === 0 && warningListRegistrationNumber);
});
</script>
<template>
  <ContentCard :title="translate('WARNING_LIST.TITLE')"
               :class="isLoading ? 'warning-list-loading' : !hasData ? 'warning-list-no-data' : 'warning-list'">
    <Loader v-if="isLoading"/>
    <span v-else-if="!hasData">{{ translate('WARNING_LIST.NO_DATA') }}</span>
    <div v-else-if="hasData" class="multi-requests-content">
      <span>{{ `${translate('WARNING_LIST.MESSAGE_ANALYSIS')} :` }}</span>
      <ul class="warning-list-matches">
        <li v-if="Object.keys(warningList).includes('registrationNumber') && warningList?.registrationNumber !== 0"
            class="warning-list-matches-warningList">
          {{ translate('WARNING_LIST.REGISTRATION_NUMBER') }}
        </li>
        <li v-if="Object.keys(warningList).includes('email') && warningList?.email !== 0"
            class="warning-list-matches-warningList">
          {{ translate('WARNING_LIST.EMAIL') }}
        </li>
        <li v-if="Object.keys(warningList).includes('phone') && warningList?.phone !== 0"
            class="warning-list-matches-warningList">
          {{ translate('WARNING_LIST.PHONE') }}
        </li>
      </ul>
    </div>
  </ContentCard>
</template>