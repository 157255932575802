<script setup lang="ts">
import {useStore} from "vuex";
import {computed, ComputedRef, onMounted, ref, unref} from "vue";
import {ApiUserResponseDto} from "@/apiCalls/login";
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import {getUserByUserId, getUsersByRole} from "@/apiCalls/backOfficeApi";
import Loader from "@/components/loader/Loader.vue";

const store = useStore();
const kits: ComputedRef<ApiUserResponseDto | undefined> = computed(() => store.getters.kits);
const permissionsSorted: ComputedRef<any> = computed(() => store.getters.permissionsSorted);

const emailSupport = ref("support@getmeelo.com");
const phoneSupport = ref("+33 3 92 25 27 30");
const companyAdmins: string[] = ref([]);

const kit = ref(kits.value);

/*const isAvailable = (moduleName: string) => {
  let icon = 'padlock-grey.png';
  const permission = permissionsSorted.value;
  for (let i = 0; i < permission.length; i++) {
    if (permission[i]?.includes(moduleName)) {
      icon = 'success-icon.png';
      break;
    }
  }
  return icon;
}*/

onMounted(async () => {
  const users = await getUsersByRole(['COMPANY_ADMIN']);
  let tempAdmins = [];
  for (const user of users) {
    const admin = await getUserByUserId(user.id);
    tempAdmins.push(admin);
  }
  companyAdmins.value = tempAdmins;
});

</script>

<template>
  <div class="my-company">
    <span class="my-company__title">{{ `${translate('BACKOFFICE.MY_COMPANY.USEFUL_CONTACTS')} :` }}</span>
    <div class="my-company__first-block">
      <ResultCard :title="translate('BACKOFFICE.MY_COMPANY.ADMINISTRATOR')">
        <div class="my-company__first-block__adminlist">
          <ul v-if="companyAdmins.length > 0">
            <li v-for="companyAdmin in companyAdmins">
              <span>{{ `${companyAdmin.username} - ` }}</span>
              <a :href="`mailto:${companyAdmin.email}`">{{ `${companyAdmin.email}` }}</a>
            </li>
          </ul>
          <Loader v-else/>
        </div>
      </ResultCard>
      <ResultCard :title="translate('BACKOFFICE.MY_COMPANY.SUPPORT_MEELO')">
        <div class="my-company__first-block__support">
          <a :href="`mailto:${emailSupport}`">{{ emailSupport }}</a>
          <a :href="`tel:${phoneSupport}`">{{ phoneSupport }}</a>
        </div>
      </ResultCard>
    </div>
    <!--    <span class="my-company__title">{{ `${translate('BACKOFFICE.MY_COMPANY.KITS_AND_MODULES_AVAILABLE')} :` }}</span>
        <div class="my-company__second-block">
          <template v-for="kit in kits">
            <ResultCard class="my-company__second-block__kit" :title="translate(`SIDEBAR.${kit.name}`)">
              <ul>
                <li v-for="module in kit.modules">
                  <img :src="require(`/public/images/${isAvailable(module.name)}`)" alt="test" style="height: 1.2rem"/>{{
                    translate(`MODULE.${module.name}`)
                  }}
                </li>
              </ul>
            </ResultCard>
          </template>
        </div>-->
  </div>
</template>