<template>
  <ContentCard :title="translate('RECOVERY.TITLE')" class="recovery">
    <TextInput/>
    <TextInput/>
    <Button :label="translate('BUTTONS.VALIDATE')"/>
  </ContentCard>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import TextInput from "@/components/input/TextInput.vue";
import Button from "@/components/button/Button.vue";

export default defineComponent({
  methods: {translate},
  components: {Button, TextInput, ContentCard}
})
</script>

<style scoped lang="scss">
.recovery {
  margin: 1rem;
}
</style>