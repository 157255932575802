<template>
  <div class="manage-lists">
    <h1 class="manage-lists__title">{{ translate('BACKOFFICE.COMPANY_LISTS') }}</h1>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import {translate} from '@/i18n';

export default defineComponent({
  methods: {translate},
  components: {},
  props: {},
});
</script>
