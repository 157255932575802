import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked", "name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    type: "radio",
    checked: _ctx.checkedValue,
    name: _ctx.name,
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:checkedValue', $event.target.checked)))
  }, null, 40, _hoisted_1))
}