import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_InfoBar = _resolveComponent("InfoBar")!
  const _component_HeaderBar = _resolveComponent("HeaderBar")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_tracked_component = _resolveComponent("tracked-component")!

  return (_openBlock(), _createBlock(_component_tracked_component, null, {
    default: _withCtx(() => [
      (!_ctx.isAuthUrl)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "App",
            style: _normalizeStyle({
        '--sidebar-width': _ctx.sidebarWidth + 'px',
      })
          }, [
            (!!_ctx.kits)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_Sidebar, { class: "no-print" }),
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.rightContentClassName)
                  }, [
                    _createVNode(_component_InfoBar, {
                      class: "no-print",
                      "is-maintenance": _ctx.isMaintenance,
                      "was-maintenance": _ctx.wasMaintenance,
                      data: _ctx.downServices
                    }, null, 8, ["is-maintenance", "was-maintenance", "data"]),
                    _createVNode(_component_HeaderBar, {
                      route: _ctx.$route.path,
                      class: "logout no-print"
                    }, null, 8, ["route"]),
                    _createVNode(_component_RouterView)
                  ], 2)
                ], 64))
              : _createCommentVNode("", true)
          ], 4))
        : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
    ]),
    _: 1
  }))
}