<script setup lang="ts">
import Button from "@/components/button/Button.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import {translate} from "@/i18n";

const props = withDefaults(
    defineProps<{
      isChecked: boolean
    }>(), {
      isChecked : false
    }
);

const emit = defineEmits(["confirm-action"]);

const openModal = defineModel('openModal', {default: false, type: Boolean});

const actionModal = () => {
  openModal.value ? openModal.value = false : null;
}

const submit = (event: SubmitEvent) => {
  emit("confirm-action", event);
}
</script>

<template>
  <CustomModal  v-model:open="openModal" class="modal-action">
    <ResultCard class="modal-double-authentification"
                :title="translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.TITLE_MODAL')">
      <div class="modal-action-text">
        <slot></slot>
        <div class="modal-action-buttons">
          <Button :label="translate('BUTTONS.CANCEL')" @click="actionModal"/>
          <Button :label="translate('BUTTONS.CONFIRM')" @click="submit($event)"/>
        </div>
      </div>
    </ResultCard>
  </CustomModal>
</template>