<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import ScoreCircleChart from "@/components/ScoreCircleChart.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import {translate} from "@/i18n";
import {ObData, Routes} from "@/utils/openBankingUtils";
import {useRoute} from "vue-router";
import {computed} from "vue";
import BoxOutputMultiRequests from "@/components/box-output/BoxOutputMultiRequests.vue";

const route = useRoute();

const props = withDefaults(defineProps<{
  obData: ObData,
  isRecovery: boolean,
  colorScore: string,
  canOpenBanking: boolean,
  company: string
}>(), {
  isRecovery: false,
  colorScore: '#D9D9D9',
  canOpenBanking: false
});

const routesAndProAccountCond = (routes: string[]) => {
  return routes.includes(route.path) && props.obData.topProAccount;
};
const routesAndPerAccountCond = (routes: string[]) => {
  return routes.includes(route.path) && !props.obData.topProAccount;
};

const checkDataAvailability = computed(() => {
  return !(props.obData.iban === '-');
});
</script>

<template>
  <ContentCard v-if="!canOpenBanking" :checked-display-content="false" :is-padlock="true"
               :title="translate('OPEN_BANKING.RESULT_ANALYSIS')"
               :class="isRecovery ? 'container-openbanking-recovery container-openbanking-lite-result' : 'container-openbanking-lite-result'"/>
  <template v-else>
    <ContentCard
        :class="isRecovery ? 'container-openbanking-recovery container-openbanking-lite-result' : 'container-openbanking-lite-result'"
        :title="translate('OPEN_BANKING.RESULT_ANALYSIS')">
      <template v-if="checkDataAvailability">
        <div class="container-openbanking-content">
          <div class="container-openbanking-content__left-block">
            <div class="financial-period">
              <span>{{
                  `${translate('OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD')}: ${obData.startStudyPeriod} ${translate('OPEN_BANKING.TO')} ${obData.endStudyPeriod} `
                }}</span>
              <span
                  v-if="routesAndProAccountCond([Routes.ANALYSIS_B2C])"
                  class="financial-period__wrong-analysis no-print">{{
                  `⚠️ ${translate('OPEN_BANKING.WRONG_ANALYSIS_B2B')}`
                }}</span>
              <span v-else-if="routesAndPerAccountCond([Routes.ANALYSIS_B2B])"
                    class="financial-period__wrong-analysis no-print">{{
                  `⚠️ ${translate('OPEN_BANKING.WRONG_ANALYSIS_B2C')}`
                }}</span>
            </div>
            <div class="container-openbanking-content__account">
              <LabelValuePrinter :label="translate('OPEN_BANKING.BANK_NAME')" :value="obData.bankName"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.HOLDER')" :value="obData.holderName"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.ACCOUNT_NAME')" :value="obData.accountName"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.IBAN')"
                                 :value="obData.iban.replace(/(.{4})/g, '$1 ')"/>
              <LabelValuePrinter
                  v-if="routesAndPerAccountCond([Routes.OPEN_BANKING, Routes.ANALYSIS_B2C, Routes.SYNTHESIS])"
                  :label="translate('OPEN_BANKING.MAIN_ACCOUNT')" :value="obData.topSecondaryAccount"/>
              <LabelValuePrinter
                  v-if="routesAndPerAccountCond([Routes.OPEN_BANKING, Routes.ANALYSIS_B2C, Routes.SYNTHESIS])"
                  :label="translate('OPEN_BANKING.MONTH_SALARIES')" :value="obData.monthIncomesSalariesAmountMean"/>
              <LabelValuePrinter v-if="route.path === '/analysis-b2b' || obData.topProAccount"
                                 :label="translate('OPEN_BANKING.CATEGORIES.AVERAGE_MONTHLY_PAYROLL')"
                                 :value="obData.monthOutcomesSalariesAmountMean"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.MONTH_RENTS')" :value="obData.monthRents"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_END_RATIO')"
                                 :value="obData.monthEndRatio"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.BALANCE')" :value="obData.balance"/>
            </div>
          </div>
          <div>
            <ScoreCircleChart
                class="circle-chart score-open-banking"
                :circle-value="Math.round(obData.score)" :circle-width="'30%'"
                :tooltip="translate('TOOLTIP.SCORE_OPEN_BANKING')"
                :circle-color="colorScore"
                :helper-text-display="false"
            />
            <LabelValuePrinter
                v-if="routesAndPerAccountCond([Routes.OPEN_BANKING, Routes.SYNTHESIS])"
                :label="translate('OPEN_BANKING.TOP_BANKING_SUPERVISION')"
                :value="obData.topBankingSupervision"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.DEFERRED_DEBIT')"
                               :value="obData.topDeferredDebit"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.MONTH_END_BALANCE')"
                               :value="obData.monthEndBalance" :tooltip-title="translate('TOOLTIP.MONTH_END_BALANCE')"
            />
            <LabelValuePrinter :label="translate('OPEN_BANKING.GLOBAL_TRANSACTION_COUNT')"
                               :value="obData.globalTransactionsCount"/>
          </div>
        </div>
        <BoxOutputMultiRequests v-if="obData?.multiRequests?.iban !== 0" :result="obData.multiRequests" :title="translate('MULTI_REQUESTS.TITLE')"
                                :company="company" market-type="open-banking"/>
      </template>
      <div v-else>
        <div class="container-openbanking-content-no-data">
          <span>{{ translate('OPEN_BANKING.PROVIDER_ERROR') }}</span>
        </div>
      </div>
    </ContentCard>
  </template>
</template>
