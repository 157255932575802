import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "meelo-carousel" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIconCaretLeft = _resolveComponent("BIconCaretLeft")!
  const _component_BIconCaretRight = _resolveComponent("BIconCaretRight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BIconCaretLeft, { onClick: _ctx.prev }, null, 8, ["onClick"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, ({score, url}, index) => {
      return (_openBlock(), _createElementBlock("img", {
        key: index,
        src: url,
        class: _normalizeClass((index === _ctx.currentIndex) ? 'active':''),
        alt: url
      }, null, 10, _hoisted_2))
    }), 128)),
    _createVNode(_component_BIconCaretRight, { onClick: _ctx.next }, null, 8, ["onClick"])
  ]))
}