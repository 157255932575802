import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "account-page" }
const _hoisted_2 = { class: "account-page__monitoring" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MonitoringCharts = _resolveComponent("MonitoringCharts")!
  const _component_MonitoringValues = _resolveComponent("MonitoringValues")!
  const _component_ContentCard = _resolveComponent("ContentCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ContentCard, {
      class: "account-page-content",
      title: _ctx.translate('SIDEBAR.MONITORING.MY_USAGE')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_MonitoringCharts, {
            "total-label-usage": _ctx.totalLabelUsage,
            "total-usage": _ctx.totalUsage,
            "total-value-usage": _ctx.totalValueUsage,
            "color-usage": _ctx.generateRandomColorsArray(_ctx.totalValueUsage.length),
            "consumption-to-display": "MY_CONSUMPTION",
            "is-monitoring-data-loading": _ctx.isMonitoringDataLoading
          }, null, 8, ["total-label-usage", "total-usage", "total-value-usage", "color-usage", "is-monitoring-data-loading"]),
          _createVNode(_component_MonitoringValues, {
            "usage-without-all": _ctx.usageWithoutAll,
            "scores-usages": _ctx.scoresUsages
          }, null, 8, ["usage-without-all", "scores-usages"])
        ])
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}