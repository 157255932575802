<script setup lang="ts">
import XMasBonhommeWithLabel from './XMasBonhommeWithLabel.vue';

import { BonhommeProps } from "@/utils/x-mas/setXMasCookie";

const props = defineProps<{ bonhommes: BonhommeProps }>()
</script>
<template>
    <div class="x-mas-welcome-card-container">
        <XMasBonhommeWithLabel v-for="bonhomme in props.bonhommes" :bonhomme-name="bonhomme.name"
            :has-been-found="bonhomme.hasBeenFound" />
    </div>
</template>
