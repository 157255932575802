import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const startLiveCheck = (
  taskId: string,
  lang: string,
  callbackUrl?: string
) =>
  api
    .post(`${envConfig.DOCUMENT_ROUTE}/v2/live-check/${taskId}?language=${lang}`)
    .then((response) => response.data);

export default { startLiveCheck };
