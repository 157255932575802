<script setup lang="ts">
import {AxiosError} from "axios";
import {ref} from "vue";
import login, {ApiUserDtoV2} from "@/apiCalls/login";
import {translate} from "@/i18n";
import {TextInputType} from "@/types/TextInputType";
import PasswordInput from "@/components/PasswordInput.vue";
import Button from "@/components/button/Button.vue";
import TextInput from "@/components/input/TextInput.vue";
import {useRouter} from "vue-router";
import {useCookies} from "@/composables/useCookies";

const router = useRouter();
const emit = defineEmits(['login', '2fa-setup', '2fa-authentication']);

const cookies = useCookies();

const inProgress = ref(false);
const error = ref<"ERROR_MESSAGE" | "LOCKED_MESSAGE" | undefined>(undefined);
const form = ref({username: '', password: ''});

const MAX_ATTEMPTS = 5;

const submit = async(e: SubmitEvent) => {
  e.preventDefault();
  inProgress.value = true;
  let authStatus, authData, authHeaders;
  try {
    const {status, data, headers} = await login.authenticateBasic(form.value.username, form.value.password);
    authStatus = status;
    authData = data;
    authHeaders = headers;
  } catch (e: any) {
    if (!(e instanceof AxiosError)) {
      console.error("Unhandled error on login", e);
      throw e;
    }
    if (e.response?.status === 401) {
      error.value = "ERROR_MESSAGE";
      console.error("Invalid credentials");
      return;
    }
    if (e.response?.status === 423) {
      error.value = "LOCKED_MESSAGE";
      console.error("Account locked");
      return;
    }
    throw e;
  } finally {
    inProgress.value = false;
  }
  if (authStatus === 200) {
    const {user, token, expirationInstant} = authData;
    logIn({user, token, expirationInstant});
    return;
  }
  if (authStatus === 206) {
    if (authHeaders["x-2fa-required"] === "true") {
      router.push({
        name: "Login2FAuthenticationSetupPage",
        query: {token: authHeaders["x-2fa-token"]},
      });
      return;
    }
    if (authHeaders["x-2fa-enabled"] !== null) {
      router.push({
        name: "Login2FAuthenticationPage",
        query: {
          token: authHeaders["x-2fa-token"],
          status: authHeaders["x-2fa-enabled"],
          contact: authHeaders["x-2fa-contact"],
        },
      });
      return;
    }
    console.error("Received 206 without 2FA data");
  }
}

const logIn = (data: { user: ApiUserDtoV2, token: string, expirationInstant: string }) => {
  cookies.persistUserCookie(data);
  router.push({name: "Home"});
}
</script>

<template>
  <form v-on:submit="submit" class="login-form">
    <h2>{{ translate("LOGIN_PAGE.TITLE") }}</h2>
    <TextInput
        :type="TextInputType.EMAIL"
        color="light"
        :label="translate('LOGIN_PAGE.EMAIL')"
        v-model="form.username"
        required
    />
    <PasswordInput
        :label="translate('LOGIN_PAGE.PASSWORD')"
        v-model:password="form.password"
        required
    />
    <div>
      <div v-if="error" class="login-error">
        {{ translate(`LOGIN_PAGE.${error}`) }}
      </div>
      <div v-if="error === 'ERROR_MESSAGE'" class="login-error">
        <img src="/images/warning-icon.png" alt="Warning"/>{{
          `${MAX_ATTEMPTS}
                ${translate("LOGIN_PAGE.ATTEMPTS_MESSAGE")}`
        }}
      </div>
    </div>
    <Button
        type="submit"
        class="submit-button"
        :label="translate('BUTTONS.CONNEXION')"
        :loading="inProgress"
    />
    <div class="forgot-password-link">
      <router-link to="/forgot-password">
        {{ translate("LOGIN_PAGE.FORGOT_PASSWORD") }}
      </router-link>
    </div>
  </form>
</template>