import {
  ConfigType,
  transformData,
} from "@/utils/dataTransformer/dataTransformer";

const ESBodaccAnnouncementConfig: ConfigType = {
  id: {
    newLabel: "id",
  },
  text: {
    newLabel: "text",
    defaultValue: ({ text }: { text?: string }) =>
      text || " ",
  },
  status: {
    newLabel: "status",
    defaultValue: ({ description }: { description: string[] }) =>
      description?.join(", "),
  },
  registryDate: {
    newLabel: "registryDate",
  },
  submissionDate: {
    newLabel: "submissionDate",
    defaultValue: ({ publicationDate }: { publicationDate: string }) =>
      publicationDate,
  },
};

export const ESBodaccAnnouncementTransformer = transformData(
  ESBodaccAnnouncementConfig
);
