<script setup lang="ts">
import { concat } from "lodash";
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import { locale, translate } from "@/i18n";
import { TextInputType, getRegex } from "@/types/TextInputType";
import { formatSiren } from "@/utils/formatRegistrationNumber";
import { isDataAvalaible } from "@/utils/utils";

import Button from "@/components/button/Button.vue";
import TextInput from "@/components/input/TextInput.vue";
import CustomSelect from "@/components/select/CustomSelect.vue";

const props = withDefaults(
  defineProps<{
    showButton?: boolean;
    sirenWarningList?: object[];
    showSiren?: string;
    permissions?: string[];
    default?: string;
    isDuplicate?: boolean;
    selectedCountry?: string;
  }>(),
  {
    showButton: true,
    showSiren: "",
    default: "",
    isDuplicate: false,
  }
);

const emit = defineEmits(["registration-number-search"]);

const siren = ref(" ");
const disabled = ref(true);
const selectedOptionValue = ref("FR");

const store = useStore();

const SIREN_REGEX = computed(() => {
  if (selectedOptionValue.value === "BE")
    return TextInputType.NATIONAL_REGISTRATION_NUMBER;
  if (selectedOptionValue.value === "ES")
    return TextInputType.TAX_IDENTIFICATION_CODE;
  return TextInputType.SIREN;
});

const permissionsSorted = computed(() => store.getters.permissionsSorted);

const isButtonDisabled = computed(() => {
  return !getRegex(SIREN_REGEX.value).test(siren.value.replaceAll(" ", ""));
});

const options = computed(() => {
  return [
    {
      name: translate("COMPANY.ORIGIN.FR"),
      imageSrc: "/images/custom-select/france.png",
      value: "FR",
      disabled: !permissionsSorted.value.includes(
        "KIT_B2B_INTERNATIONALIZATION_FR"
      ),
    },
    {
      name: translate("COMPANY.ORIGIN.BE"),
      imageSrc: "/images/custom-select/belgium.png",
      value: "BE",
      disabled: !permissionsSorted.value.includes(
        "KIT_B2B_INTERNATIONALIZATION_BE"
      ),
    },
    {
      name: translate("COMPANY.ORIGIN.ES"),
      imageSrc: "/images/custom-select/spain.png",
      value: "ES",
      disabled: false,
    },
  ].filter((el) => !el.disabled);
});

const companySearch = (e: SubmitEvent) => {
  e.preventDefault();
  store.dispatch("clearResponseStatus");
  emit("registration-number-search", siren.value, selectedOptionValue.value);
};

const applySirenFormat = async (event: any) => {
  if (siren.value === null) {
    return;
  }
  const keyCode = event.which || event.keyCode;
  if (keyCode === 8 && siren.value?.charAt(siren.value.length - 1) === " ") {
    event.preventDefault();
  }
  siren.value = formatSiren(selectedOptionValue.value, siren.value);
  if (siren.value?.charAt(siren.value?.length - 1) === " ") {
    siren.value = siren.value?.slice(0, -1);
  }
};

const computeSelectedCountry = () => {
  if (
    ["es", "be"].includes(locale) &&
    (permissionsSorted.value.includes("KIT_B2B_INTERNATIONALIZATION_BE") ||
      permissionsSorted.value.includes("KIT_B2B_INTERNATIONALIZATION_ES"))
  ) {
    return locale.toUpperCase();
  }

  if (permissionsSorted.value.includes("KIT_B2B_INTERNATIONALIZATION_FR"))
    return "FR";

  return options.value.find((option) => !option.disabled)?.value!;
};

onMounted(() => {
  selectedOptionValue.value = computeSelectedCountry();
});

watch(
  () => selectedOptionValue.value,
  (newVal) => {
    siren.value = formatSiren(newVal, siren.value);
  }
);

watch(
  () => props.selectedCountry,
  (country) => {
    if (country && isDataAvalaible(country)) {
      selectedOptionValue.value = country;
    } else {
      selectedOptionValue.value = computeSelectedCountry();
    }
  }
);

watch(
  () => props.showSiren,
  (showSiren) => {
    if (showSiren && isDataAvalaible(showSiren)) {
      siren.value = formatSiren(selectedOptionValue.value, showSiren);
    } else {
      siren.value = "";
    }
  },
  { immediate: true }
);

watch(siren, (newVal) => {
  if (
    selectedOptionValue.value === "BE" &&
    !(newVal.startsWith("0") || newVal.startsWith("1")) &&
    newVal.replaceAll(" ", "").length === 9
  ) {
    // Ajouter un 0 au début si le pays séléctionné est BE
    // et siren ne commence pas par 0/1
    // et longueur est égale à 9
    siren.value = concat("0", newVal).join("");
  }
});
</script>

<template>
  <form class="box-input-siren-form" @submit.prevent="companySearch">
    <div class="box-input-siren-container">
      <div class="box-input-siren-input">
        <CustomSelect
          v-model="selectedOptionValue"
          :label="translate('COMPANY.COUNTRY')"
          :options="options"
          :disabled="showSiren !== '' && !isDuplicate"
        />
        <TextInput
          v-if="showSiren === ''"
          class="box-input-siren-form-input"
          v-model="siren"
          :type="SIREN_REGEX"
          :label="
            translate(`COMPANY.REGISTRATION_NUMBER.${selectedOptionValue}`)
          "
          @input="applySirenFormat"
          required
        />
        <TextInput
          v-if="showSiren !== ''"
          class="box-input-siren-form-input"
          v-model="siren"
          :disabled="!isDuplicate"
          :label="
            translate(`COMPANY.REGISTRATION_NUMBER.${selectedOptionValue}`)
          "
        />
      </div>
      <Button
        v-if="
          !getRegex(SIREN_REGEX).test(siren.replaceAll(' ', '')) ||
          showButton ||
          isDuplicate
        "
        class="box-input-siren-form-button"
        type-button="submit"
        :label="translate('BUTTONS.VALIDATE')"
        :disabled="isButtonDisabled"
      />
    </div>
  </form>
</template>
