import * as _ from "lodash";

import { locale } from "@/i18n";

export type KeyValueType = {
  [key: string]: string | number | string[];
};

/**
 *
 * @param str 'yyyy-mm-dd' | 'yyyy/mm/dd'
 *
 * @returns true
 */
export const isDate = (str: string) => {
  const regex = /^\d{4}-|\/\d{2}-|\/\d{2}$/;
  return regex.test(str) && new Date(str).toString() !== "Invalid Date";
};

/**
 * 
 * @param key: 'PROPERTIES-AND_BUILDINGS'
 
 * @returns 'PROPERTIES_AND_BUILDINGS'
 */
export const getTranslationFromKey = (key: string) => {
  return _.kebabCase(key).replaceAll("-", "_").toUpperCase();
};

/**
 * 
 * @param key: 'PROPERTIES_AND_BUILDINGS'
 
 * @returns 'properties-and-buildings'
 */
export const getClassNameFromKey = (key: string): string => {
  return _.kebabCase(key);
};

/**
 * 
 * @param obj: {"bankId": null, "account": null, "creditorName": null, "paymentDeadline": "2022-10-17", "remainingAmountToPay": 1486},
 
 * @returns [{key: 'bankId', transKey: 'BANK_ID', value: null}, ...]
 */
export const formatObjectDataToKeyValue = (obj: KeyValueType) => {
  const _obj: KeyValueType[] = [];
  Object.entries(obj).forEach((arr) => {
    if (Array.isArray(arr[1])) {
      arr[1].forEach((el) => {
        _obj.push({
          key: arr[0],
          transKey: getTranslationFromKey(arr[0]),
          value:
            typeof el === "string" && isDate(el)
              ? new Date(el).toLocaleDateString(locale)
              : el,
        });
      });
    } else {
      _obj.push({
        key: arr[0],
        transKey: getTranslationFromKey(arr[0]),
        value:
          typeof arr[1] === "string" && isDate(arr[1])
            ? new Date(arr[1]).toLocaleDateString(locale)
            : arr[1],
      });
    }
  });
  return _obj;
};

/**
 * 
 * @param arr: [ {"right": "PROP/INDIVIS", "identifier": "MD242W", "designationAndAddress": "RENARD FREDERIC GEORGES"},
                  {"right": "PROP/INDIVIS", "identifier": "MD242X", "designationAndAddress": "SOLARIESTELLE PASCALE"} ]
 
 * @returns [ {"right": "PROP/INDIVIS", "identifier": "MD242W", "designationAndAddress": "RENARD FREDERIC GEORGES"},
              {"right": "PROP/INDIVIS", "identifier": "MD242X", "designationAndAddress": "SOLARIESTELLE PASCALE"} ]
 */
export const formatArrayObjectToKeyValue = (arr: []) => {
  return arr.map((obj) => formatObjectDataToKeyValue(obj));
};

/**
 * 
 * @param obj: {"bankId": null, "account": null, "creditorName": null, "paymentDeadline": "2022-10-17", "remainingAmountToPay": 1486}
 * @param sortKeys: ["remainingAmountToPay", "paymentDeadline"]
 
 * @returns {remainingAmountToPay: 1486, paymentDeadline: '2022-10-17'}
 */
export const sortObjectKeys = ({
  obj,
  sortKeys,
}: {
  obj: KeyValueType;
  sortKeys: string[];
}) => {
  const _obj: KeyValueType = {};
  sortKeys.forEach((key) => {
    _obj[key] = obj[key];
  });
  return _obj;
};

/**
 * 
 * @param arr: [ {"right": "PROP/INDIVIS", "identifier": "MD242W", "designationAndAddress": "RENARD FREDERIC GEORGES"},
                 {"right": "PROP/INDIVIS", "identifier": "MD242X", "designationAndAddress": "SOLARIESTELLE PASCALE"} ]
 * @param sortKeys: ["identifier", "right"]
 
 * @returns [ { identifier: 'MD242W', right: 'PROP/INDIVIS' }, { identifier: 'MD242X', right: 'PROP/INDIVIS' } ]
 */
export const sortArrayObjectKeys = ({
  arr,
  sortKeys,
}: {
  arr: KeyValueType[];
  sortKeys: string[];
}) => {
  return arr.map((el) => {
    return sortObjectKeys({ obj: el, sortKeys });
  });
};

/**
 * @param0 key: string
 * @param1 filteredCauses = [
  {
    id: '5fb65241-e884-49e9-93d4-196df6de6927',
    type: 'WEB_UPLOAD',
    label: null,
    data: {
      link: 'https://dev.link.getmeelo.com/v1/oYH4ZNKEdD',
      blocking: true,
      documents: [
        {
          documentType: 'BANK_DETAILS',
          withAnalysis: true,
          withAuthenticity: true,
        },
      ],
      uploadMode: 'IFRAME',
      callbackUrl: null,
      documentTypes: ['BANK_DETAILS'],
    },
    status: 'IN_PROGRESS',
    version: 'V1',
    sub_type: 'LINK_GENERATION',
    status_reason: null,
  },
];

  @param2 filteredResults = [
  {
    id: '5fb65241-e884-49e9-93d4-196df6de6927',
    type: 'WEB_UPLOAD',
    label: null,
    value: {
      ocrs: {},
      uploads: {
        BANK_DETAILS: [
          {
            filename: 'rib mobilite 56.pdf',
            documentId: '352bce94-546a-41f5-8a7e-9634bf6abd72',
            contentType: 'application/pdf',
            documentType: 'BANK_DETAILS',
          },
        ],
      },
      analyses: {},
      authenticities: {
        BANK_DETAILS: [
          {
            taskId: {
              id: 'd3b56f88-84bf-4678-a37e-9c117c4e9640',
              type: 'AUTHENTICITY',
              status: 'IN_PROGRESS',
              createdAt: [2024, 6, 13, 14, 20, 29, 953814000],
              journeyId: 'b66a4704-0459-4205-b796-eba02a1b54b0',
              updatedAt: [2024, 6, 13, 14, 20, 29, 942162000],
            },
            document: {
              filename: 'rib mobilite 56.pdf',
              documentId: '352bce94-546a-41f5-8a7e-9634bf6abd72',
              contentType: 'application/pdf',
              documentType: 'BANK_DETAILS',
            },
          },
        ],
      },
    },
    status: 'IN_PROGRESS',
    version: 'V1',
    sub_type: 'LINK_GENERATION',
    status_reason: null,
  },
  @example filterAndMergeMultiList('id')(filteredCauses, filteredResults)
  @returns [
  {
    id: '5fb65241-e884-49e9-93d4-196df6de6927',
    type: 'WEB_UPLOAD',
    label: null,
    data: {
      link: 'https://dev.link.getmeelo.com/v1/oYH4ZNKEdD',
      blocking: true,
      documents: [
        {
          documentType: 'BANK_DETAILS',
          withAnalysis: true,
          withAuthenticity: true
        }
      ],
      uploadMode: 'IFRAME',
      callbackUrl: null,
      documentTypes: [ 'BANK_DETAILS' ]
    },
    status: 'IN_PROGRESS',
    version: 'V1',
    sub_type: 'LINK_GENERATION',
    status_reason: null,
    value: {
      ocrs: {},
      uploads: {
        BANK_DETAILS: [
          {
            filename: 'rib mobilite 56.pdf',
            documentId: '352bce94-546a-41f5-8a7e-9634bf6abd72',
            contentType: 'application/pdf',
            documentType: 'BANK_DETAILS'
          }
        ]
      },
      analyses: {},
      authenticities: {
        BANK_DETAILS: [
          {
            taskId: {
              id: 'd3b56f88-84bf-4678-a37e-9c117c4e9640',
              type: 'AUTHENTICITY',
              status: 'IN_PROGRESS',
              createdAt: [
                2024,
                6,
                13,
                14,
                20,
                29,
                953814000
              ],
              journeyId: 'b66a4704-0459-4205-b796-eba02a1b54b0',
              updatedAt: [
                2024,
                6,
                13,
                14,
                20,
                29,
                942162000
              ]
            },
            document: {
              filename: 'rib mobilite 56.pdf',
              documentId: '352bce94-546a-41f5-8a7e-9634bf6abd72',
              contentType: 'application/pdf',
              documentType: 'BANK_DETAILS'
            }
          }
        ]
      }
    }
  }
]
];
 */

export const filterAndMergeMultiList = (key: string) => {
  return function (...arrays: any[]) {
    const combinedData = [];
    const allItems = arrays.flat();

    while (allItems.length > 0) {
      const currentItem = allItems.shift(); // Prend le premier élément et le supprime de la liste
      if (!currentItem.hasOwnProperty(key)) continue;

      const matchingItems = allItems.filter(
        (item) => item[key] === currentItem[key]
      );

      if (matchingItems.length > 0) {
        combinedData.push(Object.assign({}, currentItem, ...matchingItems));

        // Supprimer les éléments fusionnés de allItems
        matchingItems.forEach((matchedItem) => {
          const index = allItems.indexOf(matchedItem);
          if (index !== -1) {
            allItems.splice(index, 1);
          }
        });
      }
    }

    return combinedData;
  };
};
