<template>
  <div class="upload-authenticity">
    <form-file :file="file_upload" @update="storeFileTemp"/>
    <div v-if="message">
      <span>{{ message }}</span>
    </div>
    <Button @click="validateDocument(file)" :label="translate('BUTTONS.VALIDATE')" :disabled="isButtonDisabled"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {translate} from "@/i18n";
import FormFile from "@/components/FormFile.vue";
import Button from "@/components/button/Button.vue";
import {authenticityDocument, uploadDocument} from "@/apiCalls/uploadDocumentsApi";

export default defineComponent({
  name: "UploadAuthenticity",
  components: {Button, FormFile},
  props: {
    message: {type: String, default: ''},
    type: {type: String, default: ''},
  },
  data() {
    return {
      file_upload: [],
      file: {} as File,
      journeyId: '',
      isButtonDisabled: true
    }
  },
  methods: {
    translate,
    validateDocument(file: any) {
      this.journeyId = this.$route.query.journeyId ? this.$route.query.journeyId.toString() : '';
      this.isButtonDisabled = true;
      this.$emit("start-upload", "OTHER");
      uploadDocument(this.journeyId, this.type, file).then((result: any) => {
        this.$emit("end-upload", "OTHER");
        this.$emit("start-loading", "OTHER");
        const authenticityDocumentPromise = authenticityDocument(this.journeyId, result.data.documentId);
        Promise.all([authenticityDocumentPromise]).then(() => {
          this.$emit("end-loading");
        });
      });
    },
    storeFileTemp(file: any) {
      this.isButtonDisabled = false
      this.file = file;
    },
  },
});
</script>