import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

type CheckIbanRequest = {
  lastName: string;
  firstName: string;
  birthDate: string;
  country: string;
  iban: string;
};

interface CheckIbanCompanyRequest {
  iban: string;
  provider: string;
  country: string;
  registrationNumber: string;
}

export const checkIbanNaturalPerson = (
  journeyId: string,
  data: CheckIbanRequest
) =>
  api
    .put(
      `${envConfig.CHECK_IBAN_ROUTE}/v1/check/iban?journeyId=${journeyId}`,
      {
        checkIbanType: "PERSONAL_IBAN",
        customerProfile: {
          lastName: data.lastName,
          firstName: data.firstName,
          birthDate: data.birthDate,
          country: data.country,
        },
        iban: data.iban.replaceAll(" ", "")
      }
    )
    .then((response) => response);

export const checkIbanCompany = (
  journeyId: string,
  data: CheckIbanCompanyRequest
) =>
  api
    .put(
      `${envConfig.CHECK_IBAN_ROUTE}/v1/check/iban?journeyId=${journeyId}`,
      {
        checkIbanType: "COMPANY_IBAN",
        companyProfile: {
          address: {
            country: data.country,
          },
          registrationNumber: data.registrationNumber,
        },
        iban: data.iban.replaceAll(" ", "")
      }
    )
    .then((response) => response);

export const getBankName = async (iban: string, journeyId: string) =>
  api
    .put(
      `${
        envConfig.CHECK_IBAN_ROUTE
      }/v1/bank/search?journeyId=${journeyId}&iban=${iban.replaceAll(" ", "")}`
    )
    .then((response) => response);
