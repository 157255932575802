<script setup lang="ts">
import { computed } from "vue";

import { formatLocaleDate, translate } from "@/i18n";

import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import ContentCard from "@/components/card/ContentCard.vue";

const props = defineProps({
  announcements: { type: Object },
  canBodaccAnnouncements: { type: Boolean, default: false },
});

const sortedAnnouncements = computed(() => {
  return props.announcements?.sort(
    (a1: { submissionDate: string }, a2: { submissionDate: string }) => {
      const t1 = Date.parse(a1.submissionDate);
      const t2 = Date.parse(a2.submissionDate);
      if (!isNaN(t1) && !isNaN(t2)) return t2 - t1;
      return 0;
    }
  );
});

const isLongText = (text: string | null) => {
  if (text) return text.length > 250;
  return false;
};
</script>

<template>
  <ContentCard
    v-if="!canBodaccAnnouncements"
    :checked-display-content="false"
    :is-padlock="true"
    :title="translate('COMPANY.BODACC_ANNOUNCEMENT.TITLE')"
    class="bodacc-announcements"
  />
  <template v-else>
    <ContentCard
      :title="translate('COMPANY.BODACC_ANNOUNCEMENT.TITLE')"
      class="bodacc-announcements"
    >
      <div
        v-for="(announcement, index) in sortedAnnouncements"
        :key="index"
        class="bodacc-announcements-content breakinside"
      >
        <template v-if="isLongText(announcement.text)">
          <div class="bodacc-announcements-content-first-block">
            <span>{{
              translate("COMPANY.BODACC_ANNOUNCEMENT.INFORMATION")
            }}</span>
            <span>{{
              `${
                announcement.registryDate
                  ? formatLocaleDate(announcement.registryDate)
                  : "-"
              }`
            }}</span>
          </div>
          <div>
            <LabelValuePrinter
              :label="translate('COMPANY.BODACC_ANNOUNCEMENT.DETAIL')"
              :value="`${announcement.text ? announcement.text : '-'}`"
            />
          </div>
        </template>
        <template v-else>
          <div class="bodacc-announcements-content-first-block">
            <span>{{ `${announcement.text ? announcement.text : "-"}` }}</span>
            <span>{{
              `${
                announcement.registryDate
                  ? formatLocaleDate(announcement.registryDate)
                  : "-"
              }`
            }}</span>
          </div>
          <div>
            <LabelValuePrinter
              :label="translate('COMPANY.BODACC_ANNOUNCEMENT.NATURE')"
              :value="`${announcement.status ? announcement.status : '-'}`"
            />
          </div>
        </template>
      </div>
    </ContentCard>
  </template>
</template>
