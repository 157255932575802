<script setup lang="ts">
import * as _ from "lodash";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { translate } from "@/i18n";
import { addAuthenticityToCheck } from "@/utils/boxOutputDoc";
import {
  getAuthenticityCheck,
  getBankDetailsCheck,
  getKbisCheck,
  getPayslipCheck,
  getPersonalDocCheck,
  getTaxNoticeCheck,
  getTaxReportCheck,
} from "@/utils/createCheckFromData";

import ContentCard from "@/components/card/ContentCard.vue";
import TableDocumentsResult from "@/components/document-analysis/TableDocumentsResult.vue";
import Loader from "@/components/loader/Loader.vue";
import PrintDocumentAnalysisResult from "@/components/print/PrintDocumentAnalysisResult.vue";
import PrintFolderInfo from "@/components/print/shared/PrintFolderInfo.vue";

const props = withDefaults(
  defineProps<{
    isLoading: boolean;
    contextData: any;
    canRestitutionDocument: boolean;
    showFolderInfoOnPrint: boolean;
  }>(),
  {
    isLoading: false,
    contextData: {},
    canRestitutionDocument: false,
    showFolderInfoOnPrint: true,
  }
);

const route = useRoute();

const journeyId = ref("");
const isLoadingChecks = ref(false);
const docs = ref<Object[]>([]);
const documentChecks = ref<Object[]>([]);

const localContextData = computed(() => {
  return addAuthenticityToCheck(props.contextData, "authenticity_result");
});

const getOcrDocs = () => {
  const contextsToCheck = [
    "id_check_data",
    "tax_report_data",
    "kbis_data",
    "authenticity_result",
    "tax_notice_data",
    "payslip_data",
    "bank_details",
  ];
  const documents: Object[] = [];
  const aut = mergeAuthenticityData(localContextData.value);

  contextsToCheck.forEach((e: string) => {
    if (
      localContextData.value &&
      Object.keys(localContextData.value).includes(e)
    ) {
      const ocrType = e;
      !_.isEmpty(localContextData.value[e]) &&  Object.keys(localContextData.value[e])?.forEach((index: string) => {
        const find = aut?.find(
          (a: any) => {
            const ids = localContextData.value[ocrType][index]?.documentIds
            if (ids)
              return a.documentIds[0] === ids[0]
          }
        );

        const authenticityOnly = {
          authenticity: find?.authenticity,
          pdfReport: find?.pdfReport,
          reasons: find?.reasons,
          reasonsDetails: find?.reasonsDetails,
          redirectUrl: find?.redirectUrl,
        };

        const document = {
          analysisStatus:
            localContextData.value[ocrType][index]?.analysisStatus,
          checks:
            ocrType === "authenticity_result"
              ? find?.reasons
              : localContextData.value[ocrType][index]?.checks,
          data: localContextData.value[e][index]?.data,
          documentIds: localContextData.value[ocrType][index]?.documentIds,
          documentSide: localContextData.value[ocrType][index]?.documentSide,
          documentsFilenames:
            ocrType === "authenticity_result"
              ? [find?.fileName]
              : localContextData.value[ocrType][index]?.documentsFilenames,
          documentType:
            ocrType === "authenticity_result"
              ? "AUTHENTICITY"
              : localContextData.value[ocrType][index]?.data?.docType,
        };

        const documentFinal =
          ocrType === "authenticity_result"
            ? Object.assign({}, document, authenticityOnly)
            : document;
        documents.push(documentFinal);
      });
    }
  });
  return documents;
};

const isIdCheck = (documentType: string): boolean => {
  return (
    documentType === "DRIVING_LICENSE" ||
    documentType === "ID_CARD" ||
    documentType === "PASSPORT" ||
    documentType === "RESIDENCE_PERMIT"
  );
};

const getDocumentChecks = async ({
  data,
  documentType,
  documentId,
}: {
  data: any;
  documentType: string;
  documentId?: string;
}) => {
  let checks: any = {};
  const personalDocTypes = [
    "DRIVING_LICENSE",
    "ID_CARD",
    "PASSPORT",
    "RESIDENCE_PERMIT",
  ];

  if (documentType === "KBIS") {
    checks = await getKbisCheck({
      initialChecks: checks,
      data,
      journeyId: journeyId.value,
      documentId: documentId || "",
    });
  } else if (personalDocTypes.includes(documentType)) {
    checks = getPersonalDocCheck(checks, data);
  } else if (documentType === "TAX_REPORT") {
    checks = getTaxReportCheck(checks, data);
  } else if (documentType === "TAX_NOTICE") {
    checks = getTaxNoticeCheck(checks, data);
  } else if (documentType === "BANK_DETAILS") {
    checks = getBankDetailsCheck(checks, data);
  } else if (documentType === "PAYSLIP") {
    checks = getPayslipCheck(checks, data);
  } else if (documentType === "AUTHENTICITY") {
    checks = getAuthenticityCheck(checks, data);
  } else if (documentType === "UNKNOWN") {
    checks = data.data;
  }
  return checks;
};

function mergeAuthenticityData(data: {
  [x: string]: any[];
  authenticity_result: any[];
}) {
  if (!_.isEmpty(data["authenticity_result"])) {
    const _result = data.authenticity_result
      .map((authenticity) => {
        return authenticity?.documentIds?.map((docId: any) => {
          const file = data["file"].find((f) => f.uploadedFileUUID === docId);
          return Object.assign({}, authenticity, file);
        });
      })
      .filter((doc) => doc)
      .flat(1);
    return _.uniqBy(_result, "uploadedFileUUID");
  }
}

const createChecks = (checks: Array<any>) => {
  const newChecks = checks.map((result: any) => {
    return { [result.check]: `${result.status}` };
  });

  return newChecks.reduce((acc, current) => {
    return Object.assign(acc, current);
  }, {});
};

const createDocumentCheck = (): Promise<Object[]> => {
  return new Promise((resolve) => {
    isLoadingChecks.value = true;
    resolve(
      Promise.all(
        docs.value.map(async (doc: any) => {
          const _checks = await getDocumentChecks({
            data: doc,
            documentType: doc.documentType,
            documentId: doc?.documentIds ? doc?.documentIds[0] : "",
          });
          const docCheck = doc.checks ? createChecks(doc.checks) : [];
          const documentChecks = _.merge(_checks, docCheck);
          return {
            documentId: doc.documentIds,
            documentName: doc.documentsFilenames,
            documentType: doc.documentType,
            documentChecks: documentChecks,
            documentStatus: doc.analysisStatus,
            contextData: props.contextData,
            journeyId: journeyId.value,
          };
        })
      )
    );
    isLoadingChecks.value = false;
  });
};

const getDocumentCheck = () => {
  docs.value = getOcrDocs();
  createDocumentCheck().then((res) => {
    documentChecks.value = res;
  });
};

watch(
  () => props.contextData,
  () => {
    getDocumentCheck();
  }
);

watch(
  () => route.query.journeyId,
  () => {
    journeyId.value = route.query.journeyId
      ? route.query.journeyId.toString()
      : ("" as string);
  }
);

onMounted(() => {
  journeyId.value = route.query.journeyId
    ? route.query.journeyId.toString()
    : "";

  if (journeyId.value) getDocumentCheck();
});
</script>

<template>
  <div class="print-document-analysis-result">
    <PrintFolderInfo
      v-if="showFolderInfoOnPrint"
      :entries="{
        uniqId: journeyId,
      }"
    />
    <PrintDocumentAnalysisResult
      v-if="documentChecks.length > 0"
      :document-checks="documentChecks"
    />
    <ContentCard
      v-if="!canRestitutionDocument"
      :checked-display-content="false"
      :is-padlock="true"
      class="applicant-b2c"
      :title="`${translate('DOCUMENT_ANALYSIS')}`"
    />
    <template v-else>
      <ContentCard
        :title="translate('DOCUMENT_ANALYSIS')"
        class="form-b2c no-print"
      >
        <div v-if="$route.path !== '/synthesis'" class="message-information">
          <span>{{ translate("DOCUMENT_CONTROL.MESSAGE") }}</span>
        </div>
        <hr v-if="$route.path !== '/synthesis'" class="block-divider" />
        <div
          v-if="isLoading || isLoadingChecks"
          class="document-analysis-loader"
        >
          <Loader />
          <span>{{ translate("ANALYSIS_RESULT_RETRIEVAL") }}</span>
        </div>
        <TableDocumentsResult
          v-if="documentChecks.length > 0"
          v-for="doc in documentChecks"
          :key="doc.documentId ? doc.documentId[0] : ''"
          :document-name="doc.documentName"
          :document-type="doc.documentType"
          :document-data="doc.documentChecks"
          :document-file-id="doc.documentId"
          :document-status="doc.documentStatus"
          :context-data="contextData"
          :journey-id="journeyId"
        />
      </ContentCard>
    </template>
  </div>
</template>
