import axios from "axios";

import {
  portalResponseErrorInterceptor,
  portalResponseInterceptor,
  requestInterceptor,
  responseStatusErrorInterceptor,
  responseStatusInterceptor,
} from "@/utils/apiInterceptor";

// axios.defaults.withCredentials = true;

const axiosConfig = {
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  // withCredentials: true,
};

const apiPortal = axios.create({ ...axiosConfig });
const apiBackOffice = axios.create({ ...axiosConfig });

apiPortal.interceptors.request.use(requestInterceptor({ isBackOffice: false }));

apiPortal.interceptors.response.use(
  responseStatusInterceptor,
  responseStatusErrorInterceptor
);

apiPortal.interceptors.response.use(
  portalResponseInterceptor,
  portalResponseErrorInterceptor
);

apiBackOffice.interceptors.request.use(
  requestInterceptor({ isBackOffice: true })
);

apiBackOffice.interceptors.response.use(
  responseStatusInterceptor,
  responseStatusErrorInterceptor
);

export default apiPortal;
export { apiBackOffice };
