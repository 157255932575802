import {formatLocaleDate, locale} from "@/i18n";

export const computeCompanyInfoEntries = (
  checkCompany?: Record<string, any>,
  causesB2B?: Record<string, any>
) => {
  return {
    legalInformation: {
      corporateName: checkCompany?.corporateName || "",
      registrationNumber: checkCompany?.registrationNumber || "",
      address: checkCompany?.headquarter || "",
      workforce: checkCompany?.workforce || "",
      createdDate: checkCompany?.registrationDate || "",
      legalForm: checkCompany?.legalStatus || "",
      vatRegistrationNumber: checkCompany?.vatRegistrationNumber || "",
      capital: checkCompany?.shareCapital || "",
    },
    activity: {
      activityCode: checkCompany?.activityCode || "",
      fieldOfActivity: checkCompany?.activity || "",
      closureDate: checkCompany?.closureDate || "",
      domiciliation: checkCompany?.domiciliation || "",
    },
    website: {
      website: causesB2B?.company?.website || "",
      registrant: causesB2B?.company?.websiteDomainRegistrant || "",
      createdDate: causesB2B?.company?.websiteDomainCreationDate || "",
      registrar: causesB2B?.company?.websiteDomainRegistrar || "",
    },
  };
};

export const computeClientInfoEntries = (
  prefillClientForm: Record<string, any>
) => {
  return {
    lastName: prefillClientForm.lastName?.value,
    firstName: prefillClientForm.firstNames?.value,
    birthDate: prefillClientForm.birthDate?.value
      ? formatLocaleDate(prefillClientForm.birthDate?.value)
      : "",
    email: prefillClientForm.email?.value,
    phone: prefillClientForm.phone?.value,
    address: `${prefillClientForm.street?.value} ${prefillClientForm.city?.value} ${prefillClientForm.zipcode?.value}`,
    iban: prefillClientForm.iban?.value,
  };
};
