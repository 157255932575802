import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container-b2c" }
const _hoisted_2 = { class: "print-b2c-score-detail-container" }
const _hoisted_3 = { class: "print-b2c-check-iban-container" }
const _hoisted_4 = { class: "print-b2c-aml-cft-container" }
const _hoisted_5 = { class: "container-b2c__analysis no-print" }
const _hoisted_6 = { class: "container-b2c__analysis-block" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "container-b2c__analysis-block" }
const _hoisted_9 = {
  key: 0,
  class: "openbanking-bloc-link"
}
const _hoisted_10 = ["href"]
const _hoisted_11 = { class: "no-print" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrintFolderInfo = _resolveComponent("PrintFolderInfo")!
  const _component_PrintApplicantOB = _resolveComponent("PrintApplicantOB")!
  const _component_ScoringDetailedBox = _resolveComponent("ScoringDetailedBox")!
  const _component_PrintCard = _resolveComponent("PrintCard")!
  const _component_CheckIbanResult = _resolveComponent("CheckIbanResult")!
  const _component_CheckIbanBox = _resolveComponent("CheckIbanBox")!
  const _component_BoxOutputAmlcft = _resolveComponent("BoxOutputAmlcft")!
  const _component_BoxInputStateFolder = _resolveComponent("BoxInputStateFolder")!
  const _component_EditFolderModal = _resolveComponent("EditFolderModal")!
  const _component_DeleteFolderModal = _resolveComponent("DeleteFolderModal")!
  const _component_BoxInputPersonalInfo = _resolveComponent("BoxInputPersonalInfo")!
  const _component_Applicant = _resolveComponent("Applicant")!
  const _component_BoxOutputWarningList = _resolveComponent("BoxOutputWarningList")!
  const _component_DocumentAnalysis = _resolveComponent("DocumentAnalysis")!
  const _component_BoxInputEnrichment = _resolveComponent("BoxInputEnrichment")!
  const _component_BoxOutputEnrichment = _resolveComponent("BoxOutputEnrichment")!
  const _component_BoxInputCheckEmployer = _resolveComponent("BoxInputCheckEmployer")!
  const _component_BoxOutputCheckEmployer = _resolveComponent("BoxOutputCheckEmployer")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BoxInputOpenBanking = _resolveComponent("BoxInputOpenBanking")!
  const _component_BoxOutputOpenBanking = _resolveComponent("BoxOutputOpenBanking")!
  const _component_BoxOutputOpenBankingDetail = _resolveComponent("BoxOutputOpenBankingDetail")!
  const _component_BoxOutputOpenBankingAlert = _resolveComponent("BoxOutputOpenBankingAlert")!
  const _component_BoxOutputOpenBankingBankStatement = _resolveComponent("BoxOutputOpenBankingBankStatement")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PrintFolderInfo, {
      entries: {
        uniqId: _ctx.journeyId,
      }
    }, null, 8, ["entries"]),
    _createVNode(_component_PrintApplicantOB, {
      entries: {
        lastName: _ctx.prefillClientForm.lastName.value,
        firstName: _ctx.prefillClientForm.firstNames.value,
        birthDate: _ctx.prefillClientForm.birthDate.value,
        email: _ctx.prefillClientForm.email.value,
        phone: _ctx.prefillClientForm.phone.value,
        address: `${_ctx.prefillClientForm.street.value} ${_ctx.prefillClientForm.city.value} ${_ctx.prefillClientForm.zipcode.value} ${_ctx.prefillClientForm.country.value}`,
        iban: _ctx.prefillClientForm.iban.value
      },
      score: Math.round(_ctx.scoreFraudData),
      color: _ctx.colorB2C
    }, null, 8, ["entries", "score", "color"]),
    ((_ctx.isScoreFraud && !_ctx.scoringDetailedBoxBlock) || ((_ctx.causesFraudData != null || _ctx.isScoreFraudLoading) && _ctx.scoringDetailedBoxBlock))
      ? (_openBlock(), _createBlock(_component_PrintCard, {
          key: 0,
          title: "PRINT.SCORE_DETAILS"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ScoringDetailedBox, {
                "search-image": _ctx.searchImageProfilesData,
                "customer-profile": _ctx.prefillClientForm,
                "causes-fraud": _ctx.causesFraudData,
                company: _ctx.user?.company?.name,
                permissions: _ctx.permissionsSorted,
                "can-scoring": _ctx.scoringDetailedBoxBlock
              }, null, 8, ["search-image", "customer-profile", "causes-fraud", "company", "permissions", "can-scoring"])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ((_ctx.showCheckIbanLoader || _ctx.messageCheckIban || _ctx.checkIbanData) && _ctx.checkIbanBlock)
      ? (_openBlock(), _createBlock(_component_PrintCard, {
          key: 1,
          title: "PRINT.CHECK_IBAN",
          class: "pagebreak"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.isCheckIbanV1)
                ? (_openBlock(), _createBlock(_component_CheckIbanResult, {
                    key: 0,
                    "check-iban": _ctx.checkIbanData,
                    loader: _ctx.showCheckIbanLoader,
                    birthdate: _ctx.birthdate,
                    "message-error": _ctx.messageCheckIban,
                    "can-check-iban": _ctx.checkIbanBlock
                  }, null, 8, ["check-iban", "loader", "birthdate", "message-error", "can-check-iban"]))
                : (_openBlock(), _createBlock(_component_CheckIbanBox, {
                    key: 1,
                    "check-iban": _ctx.checkIbanData,
                    loader: _ctx.showCheckIbanLoader,
                    birthdate: _ctx.birthdate,
                    "message-error": _ctx.messageCheckIban,
                    "can-check-iban": _ctx.checkIbanBlock
                  }, null, 8, ["check-iban", "loader", "birthdate", "message-error", "can-check-iban"]))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.amlCftMatchesData && _ctx.amlcftBlock)
      ? (_openBlock(), _createBlock(_component_PrintCard, {
          key: 2,
          amlcft: _ctx.amlCftMatchesData[0],
          title: "PRINT.AMLCFT"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BoxOutputAmlcft, {
                "show-content": _ctx.amlCftMatchesData,
                "can-amlcft": _ctx.amlcftBlock,
                amlcft: _ctx.amlCftMatchesData ? _ctx.amlCftMatchesData[0] : []
              }, null, 8, ["show-content", "can-amlcft", "amlcft"])
            ])
          ]),
          _: 1
        }, 8, ["amlcft"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.displayStateFolder)
        ? (_openBlock(), _createBlock(_component_BoxInputStateFolder, {
            key: 0,
            "case-number": _ctx.businessId,
            "case-state": _ctx.journeyIdStatus,
            onDeleteFolder: _ctx.openModalDeleteFolder,
            states: _ctx.states(),
            motive: _ctx.motive,
            onSaveFolder: _ctx.openModalEditFolder
          }, null, 8, ["case-number", "case-state", "onDeleteFolder", "states", "motive", "onSaveFolder"]))
        : _createCommentVNode("", true),
      _createVNode(_component_EditFolderModal, {
        "open-modal": _ctx.modalEditFolder,
        "onUpdate:openModal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalEditFolder) = $event)),
        "case-number": _ctx.businessId,
        "case-state": _ctx.journeyIdStatus,
        onConfirmSelection: _ctx.editFolder
      }, null, 8, ["open-modal", "case-number", "case-state", "onConfirmSelection"]),
      _createVNode(_component_DeleteFolderModal, {
        "open-modal": _ctx.modalDeleteFolder,
        "onUpdate:openModal": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modalDeleteFolder) = $event)),
        onConfirmSelection: _ctx.deleteFolder,
        "case-number": _ctx.businessId
      }, null, 8, ["open-modal", "onConfirmSelection", "case-number"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BoxInputPersonalInfo, {
          onShowScore: _ctx.computeScoreFraud,
          onCheckAmlcft: _ctx.checkAmlcft,
          onCheckIban: _ctx.checkIbanMethod,
          "market-type": "b2c",
          propPrefillClientForm: _ctx.prefillClientForm,
          disableFraudScoreButton: _ctx.isDisableFraudScoreButton,
          disableIbanButton: _ctx.isDisableIbanButton,
          disableAmlcftButton: _ctx.isDisableAmlcftButton,
          permissions: _ctx.permissionsSorted,
          "permissions-company-alert": _ctx.permissionsCompanyAlert,
          "amlcft-block": _ctx.amlcftBlock,
          "company-score-block": _ctx.companyScoreBlock,
          "score-smb-block": _ctx.scoreSMBBlock,
          "check-iban-block": _ctx.checkIbanBlock,
          "can-web-score-b2c": _ctx.canWebScoreB2c
        }, null, 8, ["onShowScore", "onCheckAmlcft", "onCheckIban", "propPrefillClientForm", "disableFraudScoreButton", "disableIbanButton", "disableAmlcftButton", "permissions", "permissions-company-alert", "amlcft-block", "company-score-block", "score-smb-block", "check-iban-block", "can-web-score-b2c"]),
        (_ctx.canShowApplicant)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.canShowApplicant)
                ? (_openBlock(), _createBlock(_component_Applicant, {
                    key: 0,
                    score: _ctx.scoreFraudData,
                    color: _ctx.colorB2C,
                    permissions: _ctx.permissionsSorted
                  }, null, 8, ["score", "color", "permissions"]))
                : _createCommentVNode("", true),
              (_ctx.canShowBoxOutputWarningList)
                ? (_openBlock(), _createBlock(_component_BoxOutputWarningList, {
                    key: 1,
                    "warning-list": _ctx.causesFraudData?.warning_list,
                    "is-loading": _ctx.isNull(_ctx.causesFraudData)
                  }, null, 8, ["warning-list", "is-loading"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputAmlcft)
          ? (_openBlock(), _createBlock(_component_BoxOutputAmlcft, {
              key: 1,
              amlcft: _ctx.amlCftMatchesData ? _ctx.amlCftMatchesData[0] : [],
              "show-content": _ctx.amlCftMatchesData,
              "can-amlcft": _ctx.amlcftBlock
            }, null, 8, ["amlcft", "show-content", "can-amlcft"]))
          : _createCommentVNode("", true),
        (_ctx.canShowCheckIbanResult)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (_ctx.isCheckIbanV1)
                ? (_openBlock(), _createBlock(_component_CheckIbanResult, {
                    key: 0,
                    "check-iban": _ctx.checkIbanData,
                    loader: _ctx.showCheckIbanLoader,
                    birthdate: _ctx.contextData?.applicant?.birthDate,
                    "message-error": _ctx.messageCheckIban,
                    "can-check-iban": _ctx.checkIbanBlock
                  }, null, 8, ["check-iban", "loader", "birthdate", "message-error", "can-check-iban"]))
                : (_openBlock(), _createBlock(_component_CheckIbanBox, {
                    key: 1,
                    "check-iban": _ctx.checkIbanData,
                    loader: _ctx.showCheckIbanLoader,
                    birthdate: _ctx.contextData?.applicant?.birthDate,
                    "message-error": _ctx.messageCheckIban,
                    "can-check-iban": _ctx.checkIbanBlock
                  }, null, 8, ["check-iban", "loader", "birthdate", "message-error", "can-check-iban"]))
            ], 64))
          : _createCommentVNode("", true)
      ]),
      (_ctx.canShowScoringDetailedBox)
        ? (_openBlock(), _createBlock(_component_ScoringDetailedBox, {
            key: 1,
            "search-image": _ctx.searchImageProfilesData,
            "customer-profile": _ctx.prefillClientForm,
            "causes-fraud": _ctx.causesFraudData,
            company: _ctx.user?.company?.name,
            permissions: _ctx.permissionsSorted,
            "can-scoring": _ctx.scoringDetailedBoxBlock,
            onDqeControl: _ctx.getDqeControl,
            "rule-engine-decision": _ctx.ruleEngineDecisionForDqeControl,
            "dqe-control-result": _ctx.dqeControlData,
            "dqe-loader": _ctx.isDqeControlLoading
          }, null, 8, ["search-image", "customer-profile", "causes-fraud", "company", "permissions", "can-scoring", "onDqeControl", "rule-engine-decision", "dqe-control-result", "dqe-loader"]))
        : _createCommentVNode("", true),
      (_ctx.canShowDocumentAnalysis)
        ? (_openBlock(), _createBlock(_component_DocumentAnalysis, {
            key: 2,
            "show-title": false
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.canShowBoxInputEnrichment)
          ? (_openBlock(), _createBlock(_component_BoxInputEnrichment, {
              key: 0,
              onCheckLinkedin: _ctx.getEnrichment,
              "disabled-linkedin-button": _ctx.disabledEnrichmentButton,
              "can-enrichment": _ctx.permissionsSorted.includes('KIT_B2C_ENRICHMENT_GLOBAL')
            }, null, 8, ["onCheckLinkedin", "disabled-linkedin-button", "can-enrichment"]))
          : _createCommentVNode("", true),
        (_ctx.canShowBoxOutputEnrichment)
          ? (_openBlock(), _createBlock(_component_BoxOutputEnrichment, {
              key: 1,
              "client-form": _ctx.prefillClientForm,
              enrichment: _ctx.enrichmentData,
              loader: _ctx.enrichmentLoader,
              "can-enrichment": _ctx.permissionsSorted.includes('KIT_B2C_ENRICHMENT_GLOBAL'),
              "display-match": _ctx.displayEnrichmentMatch
            }, null, 8, ["client-form", "enrichment", "loader", "can-enrichment", "display-match"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.canShowBoxInputCheckEmployer)
        ? (_openBlock(), _createBlock(_component_BoxInputCheckEmployer, {
            key: 3,
            loader: _ctx.isCheckEmployer,
            onCheckEmployer: _ctx.checkEmployer,
            "can-check-employer": _ctx.permissionsSorted.includes('KIT_B2C_CHECK_EMPLOYER_GLOBAL')
          }, null, 8, ["loader", "onCheckEmployer", "can-check-employer"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputCheckEmployer)
        ? (_openBlock(), _createBlock(_component_BoxOutputCheckEmployer, {
            key: 4,
            "check-employer-data": _ctx.checkCompanyLegalUnitData,
            "check-warning-list": _ctx.warningListData,
            "check-procedure": _ctx.checkEmployerCollectiveProceedingsData,
            "no-data-available": _ctx.noDataAvailable,
            "is-loading": _ctx.warningListLoading
          }, null, 8, ["check-employer-data", "check-warning-list", "check-procedure", "no-data-available", "is-loading"]))
        : _createCommentVNode("", true),
      (!(Object.keys(_ctx.obFormattedFields).length > 0))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
            (_ctx.canShowBoxInputOpenBanking)
              ? (_openBlock(), _createBlock(_component_BoxInputOpenBanking, {
                  key: 0,
                  onGenerateLink: _ctx.generateLink,
                  "prop-prefill-client-form": {},
                  permissions: _ctx.permissionsSorted,
                  "can-open-baking": _ctx.openBankingInputPermissions
                }, {
                  default: _withCtx(() => [
                    (_ctx.openBankingLink != '')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (_ctx.obStarted && !_ctx.obEnded)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                _createElementVNode("a", {
                                  href: _ctx.openBankingLink,
                                  target: "_blank"
                                }, [
                                  _createVNode(_component_Button, {
                                    class: "openbanking-bloc-link-button",
                                    onClick: _ctx.goOpenBanking,
                                    label: _ctx.translate('BUTTONS.JOURNEY_OPEN_BANKING')
                                  }, null, 8, ["onClick", "label"])
                                ], 8, _hoisted_10)
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["onGenerateLink", "permissions", "can-open-baking"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputOpenBanking)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBanking, {
            key: 6,
            "ob-data": _ctx.obFormattedFields,
            "color-score": _ctx.colorScoreOB,
            "can-open-banking": _ctx.permissionsSorted.includes('KIT_B2C_OB_RESUME_GLOBAL'),
            company: _ctx.user?.company?.name
          }, null, 8, ["ob-data", "color-score", "can-open-banking", "company"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputOpenBankingDetail)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingDetail, {
            key: 7,
            onToAccountSummaryEvent: _ctx.handleAccountSummary,
            "ob-data": _ctx.obFormattedFields,
            permissions: _ctx.permissionsSorted,
            "can-open-banking": _ctx.permissionsSorted.includes('KIT_B2C_OB_DETAILS_GLOBAL'),
            company: _ctx.user?.company?.name
          }, null, 8, ["onToAccountSummaryEvent", "ob-data", "permissions", "can-open-banking", "company"]))
        : _createCommentVNode("", true),
      (_ctx.canShowBoxOutputOpenBankingAlert)
        ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingAlert, {
            key: 8,
            "ob-data": _ctx.obFormattedFields,
            "can-open-banking": _ctx.permissionsSorted.includes('KIT_B2C_OB_ALERTS_GLOBAL')
          }, null, 8, ["ob-data", "can-open-banking"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.isAccountSummaryButtonClicked)
          ? (_openBlock(), _createBlock(_component_BoxOutputOpenBankingBankStatement, {
              key: 0,
              "ob-data": _ctx.obFormattedFields
            }, null, 8, ["ob-data"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}