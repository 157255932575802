import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "verification-document__buttons"
}
const _hoisted_2 = { key: 2 }
const _hoisted_3 = {
  key: 1,
  class: "verification-document__iframe"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  class: "verification-document__phone"
}
const _hoisted_6 = { key: 4 }
const _hoisted_7 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentCard = _resolveComponent("ContentCard")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Button = _resolveComponent("Button")!
  const _component_XMasBonhomme = _resolveComponent("XMasBonhomme")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_PhoneInput = _resolveComponent("PhoneInput")!
  const _component_UploadAuthenticity = _resolveComponent("UploadAuthenticity")!
  const _component_upload_tax_notice = _resolveComponent("upload-tax-notice")!

  return (!_ctx.canUploadDocument)
    ? (_openBlock(), _createBlock(_component_ContentCard, {
        key: 0,
        "checked-display-content": false,
        "is-padlock": true,
        class: "applicant-b2c",
        title: `${_ctx.translate('VERIFICATION_DOCUMENT')}`
      }, null, 8, ["title"]))
    : (_openBlock(), _createBlock(_component_ContentCard, {
        key: 1,
        title: _ctx.translate('VERIFICATION_DOCUMENT'),
        class: "verification-document"
      }, {
        default: _withCtx(() => [
          (_ctx.type !== 'b2c')
            ? (_openBlock(), _createBlock(_component_Select, {
                key: 0,
                value: _ctx.documentType,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.documentType) = $event)),
                options: _ctx.listDocumentType,
                values: _ctx.listDocumentTypeValue,
                title: _ctx.translate('DOCUMENT_CONTROL.CHOOSE'),
                onOptionSelected: _cache[1] || (_cache[1] = (docType) => _ctx.selectUploadMode('IFRAME', docType))
              }, null, 8, ["value", "options", "values", "title"]))
            : _createCommentVNode("", true),
          (_ctx.documentType !== 'OTHER')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.uploadMode === 'PHONE')
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 0,
                      label: _ctx.translate('BUTTONS.IFRAME'),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectUploadMode('IFRAME', this.documentType)))
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_ctx.uploadMode === 'IFRAME')
                  ? (_openBlock(), _createBlock(_component_Button, {
                      key: 1,
                      label: _ctx.translate('BUTTONS.SEND_TEXT_MESSAGE'),
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectUploadMode('PHONE', this.documentType)))
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.messageError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("span", null, _toDisplayString(_ctx.translate('DOCUMENT_CONTROL.ERROR_MESSAGE_API')), 1)
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.documentType && _ctx.documentType !== 'OTHER')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (_ctx.uploadMode == 'IFRAME')
                  ? (_openBlock(), _createBlock(_component_XMasBonhomme, {
                      key: 0,
                      style: {top: '27vh', left: '28vw'},
                      value: 12
                    }))
                  : _createCommentVNode("", true),
                (_ctx.uploadMode == 'IFRAME')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      (_ctx.iframeLink)
                        ? (_openBlock(), _createElementBlock("iframe", {
                            key: 0,
                            src: _ctx.iframeLink
                          }, null, 8, _hoisted_4))
                        : (_openBlock(), _createBlock(_component_Loader, { key: 1 }))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.uploadMode == 'PHONE')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_PhoneInput, {
                        value: _ctx.phoneNumber,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.phoneNumber) = $event))
                      }, null, 8, ["value"]),
                      _createVNode(_component_Button, {
                        class: "container-openbanking-informations-form-submit-button",
                        "type-button": "submit",
                        label: _ctx.translate('BUTTONS.VALIDATE_AND_SEND_SMS'),
                        onClick: _ctx.sendSms,
                        disabled: _ctx.phoneValid
                      }, null, 8, ["label", "onClick", "disabled"])
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.documentType === 'OTHER')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_UploadAuthenticity, {
                  onStartLoading: _ctx.startLoading,
                  onEndLoading: _ctx.endLoading,
                  type: _ctx.documentType,
                  message: _ctx.translate('DOCUMENT_CONTROL.TABLE.RESULT.AUTHENTICITY.ANALYSIS_MESSAGE')
                }, null, 8, ["onStartLoading", "onEndLoading", "type", "message"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.documentType === 'INCOME_TAX_NOTICE' && _ctx.permissions?.some(elt => elt.includes('GLOBAL') || elt.includes('TAX_NOTICE_INCOME')))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_upload_tax_notice, {
                  onStartLoading: _ctx.startLoading,
                  onEndLoading: _ctx.endLoading,
                  type: 'TAX_NOTICE'
                }, null, 8, ["onStartLoading", "onEndLoading"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]))
}