<template>
  <div class="form-file">
    <label for="file" class="file-name-box input">{{ fileName }}</label>
    <input
        class="form-file-input"
        type="file"
        id="file"
        accept="image/png, image/jpeg, .pdf, .xlsx, .xls, .csv"
        @input="handleFileUpload"
    />
    <label for="file" class="file-selector-button">{{translate('FILE.CHOOSE')}}</label>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {translate} from '@/i18n';

export default defineComponent({
  name: "FormFile",
  props: ['file', 'resetUpload'],
  data: () => ({
    fileName: null as string|null
  }),
  emits: ['update'],
  methods: {
    translate,
    handleFileUpload(event: { target: { files: any[]; }; }) {
      this.fileName = event.target.files[0].name;
      this.$emit('update', event.target.files[0]);
    },
    handleResetUpload() {
      this.fileName = '';
      this.$emit('update', null);
    },
  },
  watch: {
    resetUpload: {
      handler(newVal) {
        if (newVal) this.handleResetUpload();
      },
      immediate: false,
      deep: true,
    }
  }
});
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.form-file {
  display: flex;
  gap: 1rem;
  justify-content: center;
}
.form-file-input {
  display: none;
}
.file-selector-button {
  background-color: $blueMeelo;
  color: $pureWhite;
  padding: 0.1rem 0.2rem;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  border-radius: 4px;
}
.file-name-box {
  width: 20rem;
  padding: 0.25rem 0.5rem;
  text-wrap: nowrap;
  text-align: center;
  align-items: center;
  display: flex;
  background-color: $pureWhite;

  color: rgb(60, 66, 87);
}
</style>