import {translate} from '@/i18n';
import { NavigationItem } from '@/components/sidebar/groups/NavigationItem';

const backOfficeNavigation: NavigationItem[] = [
  {
    title: '',
    colorBackground: '#D9D9D9',
    colorTitle: '#293354',
    elements: [
      /*{
        title: 'SIDEBAR.USERS.PROFILE_AND_ACCESS_MANAGEMENT',
        route: '/back-office/users/profiles',
        roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
      },*/
      /*{
        title: 'SIDEBAR.USERS.USER_MANAGEMENT',
        route: '/back-office/users',
        roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
      }*/
      /*,{
        title: 'SIDEBAR.USERS.GROUP_MANAGEMENT',
        route: '/back-office/users/groups',
        roles: ['COMPANY_ADMIN']
      },
      {
        title: 'SIDEBAR.USERS.LOCATION_MANAGEMENT',
        route: '/back-office/users/places',
        roles: ['COMPANY_ADMIN']
      },
      {
        title: translate('SIDEBAR.LIST_MANAGEMENT'),
        route: '/back-office/lists',
        roles: ['COMPANY_ADMIN']
      }*/{
        title: translate('SIDEBAR.MONITORING.MY_USAGE'),
        route: '/back-office/my-usage',
        roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
      }]
  },
  {
    title: '',
    colorBackground: '#293354',
    colorTitle: 'white',
    elements: [
      {
        title: 'SIDEBAR.HOME',
        route: '/back-office/home',
        roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
      },
      {
        title: 'SIDEBAR.PORTAL',
        route: '/home',
        roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
      },
      /*{
        title: 'SIDEBAR.MEELO_ACADEMY',
        route: '/meelo-academy',
        roles: ['COMPANY_ADMIN', 'COMPANY_MANAGER']
      }*/
    ]
  }
];
export default backOfficeNavigation;
