<script setup lang="ts">
import {defineComponent, onMounted, ref, watch} from "vue";
import Button from "@/components/button/Button.vue";
import TextInput from "@/components/input/TextInput.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import Select from "@/components/select/Select.vue";
import {getRegex, TextInputType} from "@/types/TextInputType";
import PhoneInput from "@/components/input/PhoneInput.vue";
import {ApiUserResponseDto} from "@/apiCalls/login";
import {isEmpty, isNil} from "lodash";

const props = withDefaults(defineProps<{
  step: boolean;
  checkBox: boolean;
  content: ApiUserResponseDto | any;
  modalFlag: boolean;
  supervisors: Array<any>;
  groups: Array<any>;
  places: Array<any>;
  user: Object;
  title: string;
  profiles: Array<any>;
  profilesChecked: Array<any>;
  checkBoxProfile: boolean
}>(), {
  step: true,
  checkBox: false,
  modalFlag: false,
  title: '',
  checkBoxProfile: false
});

const emit = defineEmits(['cancel-action', 'next-step', 'validate-action', 'add-modal', 'update:checkBox', 'update:profileChecked', 'back-first-page']);

const supervisorName = ref('');
const supervisorId = ref(0);
const phoneCountry = ref('+33');
const openModalFlag = ref(false);
const EMAIL = TextInputType.EMAIL;
const NAME = TextInputType.NAME;

const cancelAction = () => {
  emit('cancel-action');
}

const nextStep = () => {
  isNil(props.content.phone) ? props.content.phone = null : null;
  isNil(props.content.place) ? props.content.place = null : null;
  isNil(props.content.group) ? props.content.group = null : null;
  emit('next-step', props.content, props.checkBox);
}

const validateAction = () => {
  emit('validate-action');
}

const addModal = () => {
  emit('add-modal');
}

const stateCheckBox = (e: { target: { checked: boolean } }) => {
  emit('update:checkBox', e.target?.checked);
}

const stateCheckBoxProfile = (e: { target: { checked: boolean } }, index: number, name: string) => {
  const profiles = props.profilesChecked;
  const index_ = profiles.findIndex((profile: any) => profile.name === name);
  index_ !== -1 ? profiles.splice(index_, 1) : profiles.push({name: name, enabled: true});
  emit('update:profileChecked', profiles);
}

const backFirstPage = () => {
  emit('back-first-page');
}

const checkedProfiles = (index: number, name: string) => {
  return props.profilesChecked.some((item: any) => item.name === name);
}

const setSupervisorName = (value: string) => {
  if (props.content.supervisor === undefined || props.content.supervisor === null) {
    props.content.supervisor = {id: 0, username: value};
  } else {
    props.content.supervisor.username = value;
  }
}

const setSupervisorId = (value: number) => {
  if (props.content.supervisor === undefined || props.content.supervisor === null) {
    props.content.supervisor = {id: value, username: ''};
  } else {
    props.content.supervisor.id = value;
  }
}

watch(() => props.modalFlag, () => {
  openModalFlag.value = props.modalFlag;
});

watch(() => props.content, () => {
  supervisorId.value = props.content?.supervisor?.id;
  supervisorName.value = props.user?.roles?.includes('COMPANY_ADMIN') ? props.content?.supervisor?.username : props.user?.username;
});

onMounted(() => {
  supervisorId.value = props.content?.supervisor?.id;
  supervisorName.value = props.user?.roles?.includes('COMPANY_ADMIN') ? props.content?.supervisor?.username : props.user?.username;
  openModalFlag.value = props.modalFlag;
});
</script>
<template>
  <CustomModal v-model:open="openModalFlag" custom-class="modal-add-user" @close-modal="cancelAction">
    <ResultCard :title="title">
      <template v-if="step">
        <TextInput v-if="user" :label="translate('BACKOFFICE.COMPANY')"
                   v-model="user.company.name" :disabled="true"/>
        <TextInput :label="`${translate('IDENTITY.NAME')}*`"
                   v-model="content.username" :type="NAME"/>
        <TextInput :label="`${translate('EMAIL')}*`" v-model="content.email" :type="EMAIL"/>
        <TextInput :label="translate('PHONE')" v-model="content.phone"/>
        <TextInput :label="translate('BACKOFFICE.FUNCTION')" v-model="content.function"/>
        <Select v-model:value="content.place" :options="places?.map(({name}) => name)"
                :values="places?.map(({name}) => name)" :title="translate('BACKOFFICE.USERS.PLACE')"
                class="modal-add-user__select-supervisor" :value="content.place" :empty-option="true"/>
        <Select v-model:value="content.group" :options="groups?.map(({name}) => name)"
                :values="groups?.map(({name}) => name)" :title="translate('BACKOFFICE.USERS.GROUP')"
                class="modal-add-user__select-supervisor" :value="content.group" :empty-option="true"/>
        <TextInput v-if="user?.roles.includes('COMPANY_MANAGER')" :label="translate('BACKOFFICE.USERS.SUPERVISOR')"
                   v-model="supervisorName" :disabled="true"/>
        <Select v-else v-model:value="supervisorId" :options="supervisors?.map(({username}) => username)"
                :values="supervisors?.map(({id}) => id)" :title="translate('BACKOFFICE.USERS.SUPERVISOR')"
                class="modal-add-user__select-supervisor" :value="supervisorId" :empty-option="true"
                @value-selected="setSupervisorName" @option-selected="setSupervisorId"/>
        <div v-if="user?.roles.includes('COMPANY_ADMIN')" class="modal-add-user__advanced-user">
          <span>{{ translate('BACKOFFICE.ADVANCED_USER') }}</span>
          <div class="modal-add-user__advanced-user__checked">
            <input type="checkbox" :checked="checkBox" @input="stateCheckBox">
            <span>{{ translate('BACKOFFICE.ADVANCED_USER_MESSAGE') }}</span>
          </div>
        </div>
        <div class="modal-add-user__actions">
          <Button :label="translate('BUTTONS.CANCEL')" class="modal-add-user__actions__cancel" @click="cancelAction"/>
          <Button :label="translate('BUTTONS.CONFIRM_AND_CONTINUE')" class="modal-add-user__actions__validate"
                  @click="nextStep"/>
        </div>
      </template>
      <template v-else>
        <div class="modal-add-user__summary">
          <span class="modal-add-user__summary__title">{{ `${translate('SUMMARY')} :` }}</span>
          <div class="modal-add-user__summary__informations">
            <span>{{ `${translate('BACKOFFICE.COMPANY')} : ${user.company.name ? user.company.name : '-'}` }}</span>
            <span>{{ `${translate('IDENTITY.NAME')} : ${content.username ? content.username : '-'}` }}</span>
            <span>{{ `${translate('EMAIL')} : ${content.email ? content.email : '-'}` }}</span>
            <span>{{ `${translate('PHONE')} : ${content.phone ? content.phone : '-'}` }}</span>
            <span v-if="user?.roles.includes('COMPANY_ADMIN')">{{
                `${translate('BACKOFFICE.ADVANCED_USER')} : ${checkBox ? translate('YES') : translate('NO')}`
              }}</span>
          </div>
        </div>
        <div class="modal-add-user__profile">
          <span class="modal-add-user__profile__title">{{
              `${translate('BACKOFFICE.USERS.PROFILE_ASSIGNMENTS')} :`
            }}</span>
          <div class="modal-add-user__profile__content">
            <template v-for="(profile, index) in profiles" :key="index">
              <input class="modal-add-user__profile__content__checkbox" type="checkbox"
                     :checked="checkedProfiles(index, profile?.name)"
                     @input="(e) => stateCheckBoxProfile(e, index, profile?.name)">
              <span>{{ profile?.name }}</span>
              <span>{{ profile?.description }}</span>
            </template>
          </div>
        </div>
        <div class="modal-add-user__actions">
          <Button :label="translate('BUTTONS.RETURN')" class="modal-add-user__actions__return"
                  @click="backFirstPage"/>
          <Button :label="translate('BUTTONS.CANCEL')" class="modal-add-user__actions__cancel"
                  @click="cancelAction"/>
          <Button :label="translate('BUTTONS.CONFIRM_AND_CONTINUE')"
                  class="modal-add-user__actions__validate" @click="validateAction"/>
        </div>
      </template>
    </ResultCard>
  </CustomModal>
</template>