<script setup lang="ts">
import Loader from "@/components/loader/Loader.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import {reactive} from "vue";
import BoxInputRegistrationNumber from "@/components/box-input/BoxInputRegistrationNumber.vue";

const emit = defineEmits(["check-employer"]);

const props = withDefaults(defineProps<{
  loader: boolean,
  canCheckEmployer: boolean,
}>(), {
  loader: false,
  canCheckEmployer: false,
});

const companySearch = (siren: string, country: string) => {
  emit("check-employer", siren, country);
};
</script>

<template>
  <ContentCard
      class="check-employer-card" v-if="!canCheckEmployer" :title="translate('EMPLOYER_VERIFICATION')"
      :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard class="box-input-siren" :title="translate('EMPLOYER_VERIFICATION')">
      <BoxInputRegistrationNumber v-if="!loader" @registration-number-search="companySearch"/>
      <Loader v-else/>
    </ContentCard>
  </template>
</template>