import {
  ConfigType,
  transformData,
} from "@/utils/dataTransformer/dataTransformer";

const ESEstablishmentConfig: ConfigType = {
  siret: {
    newLabel: "siret",
    defaultValue: ({ registrationNumber }: { registrationNumber: string }) =>
      registrationNumber,
  },
  address: {
    newLabel: "address",
  },
  activities: {
    newLabel: "activities",
  },
  closureDate: {
    newLabel: "closureDate",
  },
  headquarter: {
    newLabel: "headquarter",
  },
  businessName: {
    newLabel: "businessName",
  },
  creationDate: {
    newLabel: "creationDate",
  },
  registrationNumber: {
    newLabel: "registrationNumber",
  },
  isActive: {
    newLabel: "isActive",
  },
  domiciliation: {
    newLabel: "domiciliation",
    defaultValue: () => null,
  },
};

export const ESEstablishmentTransformer = transformData(ESEstablishmentConfig);
