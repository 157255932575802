<script setup lang="ts">
import { ref, computed, onMounted, defineProps, defineEmits } from 'vue';
import Button from "@/components/button/Button.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import BackOfficeParameterEditModal from "@/components/backoffice/BackOfficeParameterEditModal.vue";
import { translate } from '@/i18n';
import { convertTypeToText } from "@/utils/backOffice";
import { getRule } from '@/apiCalls/backOffice/rulesEngine';

const props = defineProps({
  content: { type: Object as () => any | null, required: true },
  modalFlag: Boolean,
  title: String
});

const emits = defineEmits(['rule-refreshed', 'cancel-action', 'validate-action', 'action-modal', 'add-modal']);

const COLUMNS_PARAMETERS = ref(["NAME", "TYPE", "VALUE", "ACTION"]);
const COLUMNS_ARGUMENTS = ref(["NAME", "TYPE", "DEFAULT_VALUE"]);

const contentEditModal = ref<any>(null);
const editParameterModalFlag = ref(false);
const ruleInfo = ref(props.content ? props.content : null);

const refreshRule = async () => {
  if (ruleInfo.value) {
    ruleInfo.value = (await getRule(ruleInfo.value?.name, ruleInfo.value.global));
    emits('rule-refreshed');
  }
};

const editParameterModal = (index: number) => {
  editModal();
  const parameter = ruleParams.value[index];
  contentEditModal.value = parameter;
};

const editModal = () => {
  editParameterModalFlag.value = !editParameterModalFlag.value;
};

const cancelAction = () => {
  emits('cancel-action');
};

const ruleParams = computed(() => ruleInfo.value?.params?.map((param: any) => ({
  NAME: param.name,
  TYPE: param.type,
  VALUE: param.value
})) || []);

const ruleArgs = computed(() => ruleInfo.value?.args?.map((arg: any) => ({
  NAME: arg.path,
  TYPE: arg.type ? translate(convertTypeToText(arg.type)) : '',
  VALUE: arg.value
})) || []);

onMounted(async () => {
  if (props.content) {
    ruleInfo.value = props.content;
  }
});

</script>

<template>
  <CustomModal v-model:open="props.modalFlag" custom-class="modal-edit-rule" @close-modal="cancelAction">
    <ResultCard :title="title">
      
        <div class="modal-edit-rule__header">
          <span>
            <span class="modal-edit-rule__content__subtitle">{{ translate('BACKOFFICE.RULES_ENGINE.RULES.RULE_NAME') }} :</span>
            <span>&nbsp;{{ ruleInfo.name }}</span>
          </span>
          <span>
            <span class="rules__section__content__subtitle">{{ translate('BACKOFFICE.RULES_ENGINE.RULES.RULE_DESCRIPTION') }} :</span>
            <span>&nbsp;{{ ruleInfo.description ? ruleInfo.description : 'ø' }}</span>
          </span>
        </div>
        <div class="modal-edit-rule__content backoffice-rules-table">
          <div v-if="ruleParams.length > 0">
            <span class="modal-edit-rule__content__subtitle">{{ translate("BACKOFFICE.RULES_ENGINE.RULES.PARAMETERS") }}</span>
            <table class="modal-edit-rule__table">
              <tr>
                <th v-for="column in COLUMNS_PARAMETERS">
                  {{ translate(`BACKOFFICE.RULES_ENGINE.RULES.${column}`) }}
                </th>
              </tr>
              <tr v-for="(param, index) in ruleParams">
                <td v-for="key in Object.keys(param)">
                  <span v-if="key == 'TYPE'"> {{ translate(convertTypeToText(param[key])) }}</span>
                  <span v-else>{{ param[key] }}</span>
                </td>
                <td>
                  <div class="backoffice-rules-table__action-column">
                    <span @click="editParameterModal(index)" class="backoffice-rules-table__action-column__link backoffice-rules-table__action-column__link__edit">
                      <img src="/images/pen.png" alt="see"
                          class="rules__action-column__edit"/>
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          
          
          <BackOfficeParameterEditModal v-if="contentEditModal" :parameter-modal-flag="editParameterModalFlag" :content="contentEditModal"
                           :title="translate('BACKOFFICE.RULES_ENGINE.RULES.EDIT_PARAMETER')"
                           @action-modal="($event) => editParameterModalFlag = $event"
                           @parameter-edited="refreshRule"
                           />
          <div v-if="ruleArgs.length > 0">
            <span class="modal-edit-rule__content__subtitle">{{ translate("BACKOFFICE.RULES_ENGINE.RULES.ARGUMENTS") }}</span>
            <img class="modal-edit-rule__content__information__icon" src="../../../public/images/information.png"/>
            <span class="modal-edit-rule__content__information"> {{ translate("BACKOFFICE.RULES_ENGINE.RULES.FOR_API_IMPLEMENTATION") }}</span>
            <table class="modal-edit-rule__table">
              <tr>
                <th v-for="column in COLUMNS_ARGUMENTS">
                  {{ translate(`BACKOFFICE.RULES_ENGINE.RULES.${column}`) }}
                </th>
              </tr>
              <tr v-for="arg in ruleArgs">
                <td v-for="key in Object.keys(arg)">
                  {{ arg[key] }}
                </td>
              </tr>
            </table>
          </div>
         

        </div>

        <div class="modal-edit-rule__actions">
          <Button :label="translate('BUTTONS.CLOSE')" class="modal-edit-rule__actions__cancel"
                  @click="cancelAction"/>
        </div>
    </ResultCard>
  </CustomModal>
</template>