import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04913578"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "titles" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", _hoisted_1, [
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('OPEN_BANKING.ALERTS.TYPE')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('OPEN_BANKING.ALERTS.CAUSE')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('OPEN_BANKING.ALERTS.AMOUNT')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('OPEN_BANKING.ALERTS.DATE')), 1)
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.data), (item) => {
        return (_openBlock(), _createElementBlock("tr", null, [
          _createElementVNode("th", null, _toDisplayString(_ctx.translateAlertName(_ctx.data[item].name)), 1),
          _createElementVNode("td", null, _toDisplayString(_ctx.data[item].description), 1),
          _createElementVNode("td", null, _toDisplayString(`${_ctx.data[item].details?.transaction || '-'}`), 1),
          _createElementVNode("td", null, _toDisplayString(`${_ctx.data[item].details?.transaction.date || '-'}`), 1)
        ]))
      }), 256))
    ])
  ]))
}