<script setup lang="ts">
import { translate } from "@/i18n";
const props = withDefaults(
  defineProps<{
    title: string;
    marginTop?: string;
  }>(),
  {
    marginTop: "0px",
  }
);
</script>

<template>
  <div class="print-card-container to-print breakinside">
    <div class="card-title">
      <span>{{ translate(props.title) }}</span>
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<style scoped>
.print-card-container {
  margin-top: v-bind(props.marginTop) !important;
}
</style>
