import {translate} from "@/i18n";
import {formatLabel} from "@/utils/openBankingUtils";
import {isArray, isNil} from "lodash";

export interface LegalSituation {
  company: {
    legalSituation: {
      applicants: {
        name1: string,
        name2: string
      },
      judgmentDate: string,
      caseStatus: string,
      courtLocation: string
    }[];
  }
}

export interface LegalSituationType {
  applicants: {
    name1: string;
    name2: string;
  };
  judgmentDate: string;
  caseStatus: string;
  courtLocation: string;
}

/**
 * Format a field with a key and a value for legalSituation
 *
 * @params key: string
 * @params value: string[]
 *
 * if value is null
 *    @returns ''
 * else-if isArray(value)
 *    @returns value[0] -> value[1]
 * else
 *    @returns the key translated and the value formatted
 * */
const formatField = (key: string, value: string | string[]): string => {
  return isNil(value) ? '' : `${translate(key)} ${isArray(value) ? formatLabel(value.join(' -> ')) : formatLabel(value)}`;
}

export const formatCompanyNames = (name1: string, name2: string): string => {
  return formatField('', [
    isNil(name1) ? translate('MODULE.LITIGATION.UNKNOWN') : name1,
    isNil(name2) ? translate('MODULE.LITIGATION.UNKNOWN') : name2
  ]);
}

export const formatJudgedDate = (date: string): string => {
  return formatField('MODULE.LITIGATION.DATE', date);
}

export const formatCaseStatus = (status: string): string => {
  return formatField('MODULE.LITIGATION.DEMAND', translate('MODULE.LITIGATION.' + status));
}

export const formatCourtLocation = (location: string): string => {
  return formatField('MODULE.LITIGATION.LOCATION', location);
}