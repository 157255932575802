import {
  formatArrayObjectToKeyValue,
  formatObjectDataToKeyValue,
  getClassNameFromKey,
  KeyValueType,
  sortArrayObjectKeys,
  sortObjectKeys,
} from "@/utils/sortArrayObjectKeys";
import { ComputedRef } from "vue";

export type CheckType = {
  [key: string]: KeyValueType | [];
};

/**
 * 
 * @param checks : { "LEGAL_DEBTORS": [ {"right": "PROP/INDIVIS", "identifier": "MD242W", "designationAndAddress": "RENARD FREDERIC GEORGES"}, ],
                     "PAYMENT_INFO": {"paymentDeadline": "2022-10-17", "remainingAmountToPay": 1486},
                     "PROPERTIES_AND_BUILDINGS": {"addresses": ["113 AV MARCEAU", "113 AV"], "taxAmount": 1486},
                   }
 
 * @returns [ {key: 'LEGAL_DEBTORS', transKey: 'LEGAL_DEBTORS', className: 'legal-debtors', value: [[{key: 'right', transKey: 'RIGHT', value: 'PROP/INDIVIS'}, ...]]},
              {key: 'PAYMENT_INFO', transKey: 'PAYMENT_INFO', className: 'payment-info', value: [ { key: 'paymentDeadline', transKey: 'PAYMENT_DEADLINE', value: '2022-10-17'}, ...]},
              {key: 'PROPERTIES_AND_BUILDINGS',transKey: 'PROPERTIES_AND_BUILDINGS', className: 'properties-and-buildings', value: [{ key: 'addresses', value: '113 AV MARCEAU' },{ key: 'addresses', value: '113 AV' }, ...]}
            ]
 */
export const formatDataChecks = (checks: CheckType) => {
  return Object.keys(checks).map((key) => {
    const check = Array.isArray(checks[key])
      ? formatArrayObjectToKeyValue(checks[key] as [])
      : formatObjectDataToKeyValue(checks[key] as {});
    return {
      key,
      transKey: key,
      className: getClassNameFromKey(key),
      value: check,
    };
  });
};

/**
 * 
 * @param checks: {"PAYMENT_INFO": {"bankId": null, "account": null, "creditorName": null, "paymentDeadline": "2022-10-17", "remainingAmountToPay": 1486}}
 * @param sortKeys: [{"PAYMENT_INFO": ["remainingAmountToPay", "creditorName"]},]
 
 * @returns {PAYMENT_INFO:{remainingAmountToPay: 1486, creditorName: null}}
 */
export const selectAndSortChecksByKey = ({
  checks,
  sortKeys,
}: {
  checks: CheckType;
  sortKeys: KeyValueType[];
}) => {
  const _checks: CheckType = {};
  sortKeys.forEach((keyValue) => {
    const _key = Object.keys(keyValue)[0];
    const _sortKeys = Object.values(keyValue)[0] as string[];
    // @ts-ignore
    _checks[_key] = Array.isArray(checks[_key])
      ? sortArrayObjectKeys({
          arr: checks[_key] as KeyValueType[],
          sortKeys: _sortKeys,
        })
      : sortObjectKeys({
          obj: checks[_key] as KeyValueType,
          sortKeys: _sortKeys,
        });
  });
  return _checks;
};

/**
 *
 * @param data: {"PAYMENT_INFO": {"bankId": null, "account": null, "creditorName": null, "paymentDeadline": "2022-10-17", "remainingAmountToPay": 1486}},
 * @param key: "PAYMENT_INFO"
 * @param funcs: Function[]
 */
export const formatKeyValuePairWithFunc = ({
  data,
  key,
  funcs,
}: {
  data: KeyValueType;
  key: string;
  funcs: Function[];
}) => {
  const _data = Object.assign({}, data);
  funcs.forEach((func) => {
    _data[key] = func(_data[key]);
  });
  return _data;
};

/**
 * 
 * @param input: {"registrant": {"registrant1": "EYLAUD MORGAN", "registrant2": "EMPINET FABRICE", "registrant3": "EMPINET FABRICE"},
                   "taxNumber": {"registrant1": "0720116042023", "registrant2": "0130585595056", "registrant3": "0000000000000000"},
                   "rakoto": {"registrant1": "--------", "registrant2": "0130585595056", "registrant3": "**************"}
                  }
 * @param keys: ["registrant", "taxNumber", "rakoto"]
                  
 * @returns [{registrant: 'EYLAUD MORGAN', taxNumber: '0720116042023', rakoto: '--------'},
             {registrant: 'EMPINET FABRICE', taxNumber: '0130585595056', rakoto: '0130585595056'},
             {registrant: 'EMPINET FABRICE', taxNumber: '0000000000000000', rakoto: '**************'}
            ]
 */
export const reduceStructureWithSameEntries = ({
  input,
  keys,
}: {
  input: any;
  keys: string[];
}) => {
  const structKeys = Object.keys(input[keys[0]]);
  return structKeys.map((sKey) => {
    return keys
      .map((k) => ({ [k]: input[k][sKey] }))
      .reduce((acc, currentVal) => {
        return Object.assign({}, acc, currentVal);
      }, {});
  });
};

/**
 * 
 * @param checks: [{
    key: 'hasMoreThanOneTaxIn6Months',
    transKey: 'HAS_MORE_THAN_ONE_TAX_IN_6_MONTHS',
    value: true
  },
  {
    key: 'companyRegistrationNumberFound',
    transKey: 'COMPANY_REGISTRATION_NUMBER_FOUND',
    value: '507938892'
  },
  {
    key: 'websiteDomainCreationDateRisky',
    transKey: 'WEBSITE_DOMAIN_CREATION_DATE_RISKY',
    value: false
  }, ]
  *
  @param: ["hasShareCapitalChange","hasCessationOfActivity","hasMultiManagementChange","isLessThanThreeYears","hasMultiHeadquarterChange"]

 * @returns [
  {
    key: 'hasShareCapitalChange',
    transKey: 'HAS_SHARE_CAPITAL_CHANGE',
    value: true
  },
  {
    key: 'hasCessationOfActivity',
    transKey: 'HAS_CESSATION_OF_ACTIVITY',
    value: false
  },
  {
    key: 'hasMultiManagementChange',
    transKey: 'HAS_MULTI_MANAGEMENT_CHANGE',
    value: false
  },
  {
    key: 'isLessThanThreeYears',
    transKey: 'IS_LESS_THAN_THREE_YEARS',
    value: false
  },
  {
    key: 'hasMultiHeadquarterChange',
    transKey: 'HAS_MULTI_HEADQUARTER_CHANGE',
    value: false
  }
]
 */
export const sortChecksByKeyStringList = ({
  checks,
  sortKeys,
}: {
  checks: ComputedRef<any[]>;
  sortKeys: string[];
}) => {
  const _checks: any[] = [];
  sortKeys.forEach((key: string) => {
    const _find =
      Array.isArray(checks) && checks?.find((c) => c?.label === key);
    _find && _checks.push(_find);
  });
  return _checks;
};

export const getStatusIcon = (status: string) => {
  if (status === "OK") {
    return "success-icon.png";
  } else if (status === "KO") {
    return "failed-icon.png";
  } else if (status === "WARNING") {
    return "warning-icon.png";
  } else {
    return "null-icon.png";
  }
};
