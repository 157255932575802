<script setup lang="ts">

import {computed, reactive} from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import {translate} from "@/i18n";
import ResultCard from "@/components/card/ResultCard.vue";
import BarChart from "@/components/chart/BarChart.vue";
import LineChart from "@/components/chart/LineChart.vue";
import XMasBonhomme from "@/components/x-mas/XMasBonhomme.vue";
import {formatBalance, ObData, Routes} from "@/utils/openBankingUtils";
import Button from "@/components/button/Button.vue";
import {useRoute} from "vue-router";
import {isEqual} from "lodash";
import BoxOutputMultiRequests from "@/components/box-output/BoxOutputMultiRequests.vue";

const emit = defineEmits(['to-account-summary-event']);
const route = useRoute();

const props = withDefaults(defineProps<{
  obData: ObData,
  contextData: any,
  isRecovery: boolean,
  permissions: string[],
  canOpenBanking: boolean,
}>(), {
  contextData: {},
  isRecovery: false,
  canOpenBanking: false
});

const obData = reactive(props.obData);

const formatCardNumber = (number: string) => {
  let res = "X".repeat(16 - number.length) + number;
  return res.replace(/(.{4})/g, "$1 ");
}
const handleSummaryButton = () => {
  emit('to-account-summary-event');
}

const MonthlyIncomeCond = computed(() => {
  return [Routes.OPEN_BANKING, Routes.ANALYSIS_B2B, Routes.ANALYSIS_B2C, Routes.RECOVERY, Routes.SYNTHESIS].includes(route.path) && !props.obData.topProAccount
});

const MonthlyIncomeValues = computed(() => [
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_AMOUNT_MEAN'),
    value: obData.monthIncomesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_SALARIES_AMOUNT_MEAN'),
    value: obData.monthIncomesSalariesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN'),
    value: obData.monthIncomesAllowancesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.MONTH_INCOMES_PENSIONS_AMOUNT_MEAN'),
    value: obData.monthIncomesPensionsAmountMean
  }
]);

// Pro Account
const MonthlyIncomeCondProAccount = computed(() => {
  return [Routes.OPEN_BANKING, Routes.ANALYSIS_B2B, Routes.ANALYSIS_B2C, Routes.RECOVERY, Routes.SYNTHESIS].includes(route.path) && props.obData.topProAccount
});

const MonthlyIncomeValuesProAccount = computed(() => [
  {
    label: translate('OPEN_BANKING.CATEGORIES.AVERAGE_MONTHLY_ENTRIES'),
    value: obData.monthIncomesAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.TOTAL_FOR_THE_PERIOD'),
    value: obData.monthIncomesAmountSum
  }
]);

// aggregation bank card
const aggregationBankCardsValues = computed(() => [
  {
    cond: true,
    label: translate('OPEN_BANKING.CATEGORIES.BANK_CARD_PRESENCE'),
    value: obData.bankCardPresence
  },
  {
    cond: true,
    label: translate('OPEN_BANKING.CATEGORIES.DEBITS'),
    value: obData.bankDirectDebitPresence
  },
  {
    cond: true,
    label: translate('OPEN_BANKING.CATEGORIES.BANK_CARD_QUANTITY'),
    value: isEqual(obData.bankCardQuantity, 0) ? '-' : obData.bankCardQuantity
  },
  {
    cond: obData.bankCardPresence !== `${translate('NO')}`,
    label: translate('OPEN_BANKING.CATEGORIES.BANK_CARD_NUMBERS')
  }
]);

// aggregation other accounts
const aggregationOtherAccountsValues = computed(() => [
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_INCOMES_SAVINGS_AMOUNT_MEAN'),
    value: obData.monthIncomesSavingsAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN'),
    value: obData.monthOutcomesSavingsAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN'),
    value: obData.monthIncomesTransfersAmountMean
  },
  {
    label: translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN'),
    value: obData.monthOutcomesTransfersAmountMean
  }
]);


const checkPermission = (permission: string) => {
  if (route.path === Routes.OPEN_BANKING) {
    return props.permissions?.includes(`KIT_OB_${permission}`);
  } else if (route.path === Routes.ANALYSIS_B2B) {
    return props.permissions?.includes(`KIT_B2B_${permission}`);
  } else if (route.path === Routes.ANALYSIS_B2C) {
    return props.permissions?.includes(`KIT_B2C_${permission}`);
  } else if (route.path === Routes.SYNTHESIS) {
    return props.permissions?.some((elt: any) => elt.includes(`KIT_OB_${permission}`) || elt.includes(`KIT_B2B_${permission}`) || elt.includes(`KIT_B2C_${permission}`));
  } else if (route.path === Routes.RECOVERY) {
    return true;
  }
};
const movementsIncomes = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_INCOMES'));
const accountSummary = computed(() => checkPermission('OB_SUMMARY_GLOBAL'));
const movementsOutcomes = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_OUTCOMES'));
const movementsDailyBalance = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_DAILY_BALANCE'));
const movementsMonthlyBalance = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_MONTHLY_BALANCE'));
const movementsRecommendations = computed(() => checkPermission('OB_DETAILS_MOVEMENTS_RECOMMENDATIONS'));
const aggregationsRemainsToLive = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_REMAINS_TO_LIVE'));
const aggregationsIndebtedness = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_INDEBTEDNESS'));
const aggregationsExistingCreditAnalysis = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_EXISTING_CREDIT_ANALYSIS'));
const aggregationsHabitat = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_HABITAT'));
const aggregationsBankCard = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_BANK_CARD'));
const aggregationsOtherAccounts = computed(() => checkPermission('OB_DETAILS_AGGREGATIONS_OTHER_ACCOUNTS'));

const habitatString = computed(() => `*${translate("OPEN_BANKING.SERVICES")} = ${translate("OPEN_BANKING.WATER")}, ${translate("OPEN_BANKING.GAS")}, ${translate("OPEN_BANKING.ELECTRICITY")}...`);


</script>

<template>
  <ContentCard v-if="!canOpenBanking" :title="translate('OPEN_BANKING.DETAILS')" :checked-display-content="false"
               :is-padlock="true"
               :class="isRecovery ? 'container-openbanking-recovery container-openbanking-detailed-recovery  container-openbanking-analysis-detailed' : 'container-openbanking-analysis-detailed'"/>
  <template v-else>
    <ContentCard :title="translate('OPEN_BANKING.DETAILS')"
                 :class="isRecovery ? 'container-openbanking-recovery container-openbanking-detailed-recovery  container-openbanking-analysis-detailed' : 'container-openbanking-analysis-detailed'"
    >
      <div class="financial-period">
            <span>{{
                `${translate('OPEN_BANKING.FINANCIAL_ANALYSIS_PERIOD')}: ${obData.startStudyPeriod} ${translate('OPEN_BANKING.TO')} ${obData.endStudyPeriod} `
              }}</span>
      </div>
      <div>
        <div class="container-openbanking-analysis-detailed-container">
          <span class="container-openbanking-analysis-detailed-title">
            {{ translate('OPEN_BANKING.MOVEMENT_SUMMARY') }}
          </span>
          <Button
              v-if="$route.path !== Routes.SYNTHESIS"
              class="container-openbanking-analysis-detailed-container-button no-print"
              :label="translate('OPEN_BANKING.ACCOUNT_STATEMENT.BUTTON')"
              @click="handleSummaryButton"
              :action-icon="!accountSummary ? 'padlock-blue.png' : ''"
              :is-disabled="!accountSummary"
          />
        </div>
        <div
            :class="`${isRecovery ? 'recovery-divided container-openbanking-analysis-detailed-divided container-openbanking-analysis-incomes' : 'container-openbanking-analysis-detailed-divided container-openbanking-analysis-incomes'}`">
          <template v-if="movementsIncomes">
            <ResultCard v-if="!props.obData.topProAccount" :title="translate('OPEN_BANKING.INCOMES')">
              <template v-for="(monthlyIncomeValue, index) in MonthlyIncomeValues" :key="index">
                <LabelValuePrinter
                    v-if="MonthlyIncomeCond"
                    :label="monthlyIncomeValue.label"
                    :value="String(monthlyIncomeValue.value)"
                />
              </template>
            </ResultCard>
            <ResultCard v-else :title="translate('OPEN_BANKING.INCOMES')">
              <template v-for="(monthlyIncomeValue, index) in MonthlyIncomeValuesProAccount" :key="index">
                <LabelValuePrinter
                    v-if="MonthlyIncomeCondProAccount"
                    :label="monthlyIncomeValue.label"
                    :value="String(monthlyIncomeValue.value)"
                />
              </template>
            </ResultCard>
          </template>
          <ResultCard v-if="movementsOutcomes"
                      :title="translate('OPEN_BANKING.OUTCOMES')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_RENTS_MEAN')"
                               :value="obData.monthOutcomesRentsMean"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN')"
                               :value="obData.monthOutcomesCreditsMean"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_DATE_MEAN')"
                               :value="obData.monthOutcomesCreditsDateMean"/>
            <LabelValuePrinter
                v-if="[Routes.OPEN_BANKING, Routes.ANALYSIS_B2C, Routes.RECOVERY].includes($route.path) && !obData.topProAccount"
                :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_PENSIONS_DATE_MEAN')"
                :value="obData.monthOutcomesPensionsDateMean"/>
            <LabelValuePrinter v-if="$route.path === Routes.ANALYSIS_B2B || obData.topProAccount"
                               :label="translate('OPEN_BANKING.CATEGORIES.AVERAGE_MONTHLY_TAX_REVENUE')"
                               :value="obData.monthOutcomesTaxesAmountMean"/>
            <LabelValuePrinter v-if="$route.path === Routes.ANALYSIS_B2B || obData.topProAccount"
                               :label="translate('OPEN_BANKING.CATEGORIES.AVERAGE_MONTHLY_EXITS')"
                               :value="obData.monthOutcomesAmountMean"/>
            <LabelValuePrinter v-if="$route.path === Routes.ANALYSIS_B2B || obData.topProAccount"
                               :label="translate('OPEN_BANKING.CATEGORIES.TOTAL_FOR_THE_PERIOD')"
                               :value="obData.monthOutcomesAmountSum"/>
          </ResultCard>
        </div>
        <div v-if="!isRecovery"
             class="container-openbanking-analysis-detailed-divided container-openbanking-analysis-balance">
          <ResultCard :title="translate('OPEN_BANKING.DAILY_BALANCE')"
                      v-if="!!obData?.balancePerDay && movementsDailyBalance"
          >
            <LineChart background-color-chart="#4FA0FF"
                       :abscissa-chart="formatBalance(obData.balancePerDay, 'date')"
                       :data-chart="formatBalance(obData.balancePerDay, 'amount')"
                       :label-chart="translate('OPEN_BANKING.DAILY_BALANCE')" border-color-chart="#4FA0FF"/>
          </ResultCard>
          <ResultCard :title="translate('OPEN_BANKING.MONTHLY_BALANCE')"
                      v-if="!!obData?.balancePerMonth && movementsMonthlyBalance"
          >
            <BarChart background-color-chart="#4FA0FF"
                      :abscissa-chart="formatBalance(obData.balancePerMonth, 'date')"
                      :data-chart="formatBalance(obData.balancePerMonth, 'amount')"
                      :label-chart="translate('OPEN_BANKING.MONTHLY_BALANCE')"/>
          </ResultCard>
          <XMasBonhomme :style="{top: '148vh', left: '75vw'}" :value="9"/>
        </div>
        <div
            :class="`${isRecovery ? 'recovery-divided container-openbanking-analysis-detailed-unique' : 'container-openbanking-analysis-detailed-unique'}`">
          <ResultCard v-if="movementsRecommendations"
                      :title="translate('OPEN_BANKING.RECOMMENDATIONS')">
            <div class="recommendations-value">
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_DATE')"
                                 :value="obData.recommendedDebitDate"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.MONTH_NEGATIVE')"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_AMOUNT')"
                                 :value="obData.recommendedDebitAmount"/>
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_NEGATIVE_BALANCE_MIN')"
                                 :value="obData.monthNegativeBalanceMin"
                                 class="recommendations-value-month-negative"
              />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MAX_BALANCE_MIN')"
                                 :value="obData.maxBalance"
                                 class="recommendations-value-max-balance"
              />
              <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_NEGATIVE_BALANCE_MAX')"
                                 :value="obData.monthNegativeBalanceMax"
                                 class="recommendations-value-month-negative"
              />
            </div>
          </ResultCard>
        </div>
      </div>
      <div :class="`openbanking-approvals ${$route.path === Routes.SYNTHESIS ? '' : 'breakinside'}`">
        <span class="container-openbanking-analysis-detailed-title">{{ translate('OPEN_BANKING.APPROVALS') }}</span>
        <div v-if="props.obData.topProAccount" class="container-openbanking-analysis-detailed-divided">
          <ResultCard :title="translate('OPEN_BANKING.NET_INCOME_AND_ANNUAL_TURNOVER')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.ANNUAL_NET_INCOME_AMOUNT')"
                               :value="obData.netIncome"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.ANNUAL_REVENUE_AMOUNT')"
                               :value="obData.turnover"/>
          </ResultCard>
          <ResultCard :title="translate('OPEN_BANKING.TAXES_AND_PAYROLL')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTHLY_TAX_AMOUNT')"
                               :value="obData.monthOutcomesTaxesAmountMean"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.AVERAGE_MONTHLY_PAYROLL')"
                               :value="obData.monthOutcomesSalariesAmountMean"/>
          </ResultCard>
        </div>
        <div v-else
             :class="`${isRecovery ? 'recovery-divided container-openbanking-analysis-detailed-divided' : 'container-openbanking-analysis-detailed-divided'}`">
          <ResultCard v-if="aggregationsRemainsToLive"
                      :title="translate('OPEN_BANKING.REMAINS_TO_LIVE')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTHLY_AMOUNT')"
                               :value="obData.remainsToLive"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_NEGATIVE_BALANCE_MEAN')"
                               :value="obData.monthNegativeBalanceMean"/>
          </ResultCard>
          <ResultCard v-if="aggregationsIndebtedness"
                      :title="translate('OPEN_BANKING.INDEBTEDNESS')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.DEBT_RATIO')"
                               :value="obData.debtRatio"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.MONTH_OUTCOMES_CREDITS_COUNT_MEAN')"
                               :value="obData.monthOutcomesCreditsCountMean"/>
          </ResultCard>
        </div>
        <div
            :class="`${isRecovery ? 'recovery-divided container-openbanking-analysis-detailed-divided' : 'container-openbanking-analysis-detailed-divided'}`">
          <ResultCard v-if="aggregationsExistingCreditAnalysis"
                      :title="translate('OPEN_BANKING.EXISTING_CREDIT_ANALYSIS')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_MIN')"
                               :value="obData.monthOutcomesCreditsMin"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_MAX')"
                               :value="obData.monthOutcomesCreditsMax"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_COUNT_MIN')"
                               :value="obData.monthOutcomesCreditsCountMin"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_CREDITS_COUNT_MAX')"
                               :value="obData.monthOutcomesCreditsCountMax"/>
          </ResultCard>
          <ResultCard v-if="aggregationsHabitat"
                      :title="translate('OPEN_BANKING.HABITAT')">
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_RENTS_MIN')"
                               :value="obData.monthOutcomesRentsMin"/>
            <LabelValuePrinter :label="translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_RENTS_MAX')"
                               :value="obData.monthOutcomesRentsMax"/>
            <LabelValuePrinter :label="`${translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_UTILITIES_MIN')}*`"
                               :value="obData.monthOutcomesUtilitiesMin"/>
            <LabelValuePrinter :label="`${translate('OPEN_BANKING.CATEGORIES.MONTH_OUTCOMES_UTILITIES_MAX')}*`"
                               :value="obData.monthOutcomesUtilitiesMax"/>
            <span class="habitat-text">{{ habitatString }}</span>
            <XMasBonhomme :style="{top: '235vh', left: '75vw'}" :value="11"/>
          </ResultCard>
        </div>
        <div
            :class="`${isRecovery ? 'recovery-divided container-openbanking-analysis-detailed-divided' : 'container-openbanking-analysis-detailed-divided'}`">
          <ResultCard v-if="aggregationsBankCard" :title="translate('OPEN_BANKING.CATEGORIES.BANK_CARD_AND_DEBITS')">
            <template v-for="(aggregationBankCard, index) in aggregationBankCardsValues" :key="index">
              <LabelValuePrinter
                  v-if="aggregationBankCard.cond"
                  :label="aggregationBankCard.label"
                  :value="aggregationBankCard.value"
              />
            </template>
            <div v-if="obData.bankCardNumbers !== '-'"
                 class="container-openbanking-analysis-detailed-unique-block-scroll">
              <ul v-for="(number, index) in obData.bankCardNumbers" :key="index">
                <li>{{ formatCardNumber(number) }}</li>
              </ul>
            </div>
            <LabelValuePrinter v-if="obData.bankCardNumbers === '-'"
                               :label="translate('OPEN_BANKING.CATEGORIES.BANK_CARD_NUMBERS')"
                               :value="obData.bankCardNumbers"
            />
          </ResultCard>
          <ResultCard v-if="aggregationsOtherAccounts && !props.obData.topProAccount"
                      :title="translate('OPEN_BANKING.CATEGORIES.OTHER_ACCOUNTS.TITLE')">
            <template v-for="(aggregationOtherAccountsValue, index) in aggregationOtherAccountsValues" :key="index">
              <LabelValuePrinter
                  :label="aggregationOtherAccountsValue.label"
                  :value="aggregationOtherAccountsValue.value"
              />
            </template>
          </ResultCard>
        </div>
      </div>
    </ContentCard>
  </template>
</template>

<style scoped lang="scss">
.habitat-text {
  margin-left: 1rem;
  font-size: .8rem;
}
</style>