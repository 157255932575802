<script setup lang="ts">
import {translate} from "@/i18n";
import Button from "../button/Button.vue";

const props = withDefaults(
    defineProps<{
      customClass?: string,
      displayCloseButton?: boolean //the "close" button at the bottom-right
    }>(),
    {
      customClass: '',
      displayCloseButton: false
    }
);

const emit = defineEmits(["update:open", "close-modal"]);

const open = defineModel('open', {default: false, type: Boolean});

const doUpdate = ({target}: Event) => {
  if (target instanceof HTMLDivElement || target instanceof HTMLSpanElement) {
    if(target.className.includes('customer-modal-open')) { //to close the modal when clicking outside
      close();
    }
    if (target.className === 'customer-modal customer-modal-open customer-modal-part' || target.className === 'close-button') {
      emit('update:open', !open.value);
    }
  }
}

const close = () => {
  open.value = !open.value;
  emit('close-modal');
}

</script>

<template>
  <Teleport to="body">
    <div :class="`customer-modal ${customClass} ${open ? 'customer-modal-open' : 'customer-modal-close'}`"
         v-on:click="doUpdate">
      <div class="customer-modal-content">
        <Button class="close-button" @click="close" label="+"/>
        <slot></slot>
        <Button v-if="displayCloseButton" class="button" :label="translate('BUTTONS.CLOSE')"
                @click="close"/>
      </div>
    </div>
  </Teleport>
</template>
