import {CompanyProfileV2} from "@/types/CompanyProfile";
import {CustomerProfileV3} from "@/types/CustomerProfile";
import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {searchCountryCode} from "@/utils/countryCodes";
import {isEmpty} from "lodash";

export const getScoringB2B = (
  customerProfile: CustomerProfileV3,
  companyProfile: CompanyProfileV2,
  journeyId: string
) =>
  api
    .put(
      `${envConfig.SCORING_ROUTE}/v2/task/score?storeInput=false&journeyId=${journeyId}`,
      {
        requestOptions: {
          requestId: "WEB_SCORE_B2B_V2",
        },
        customerProfile: {
          identity: customerProfile.identity,
          address: {
            street: customerProfile.address.street,
            city: customerProfile.address.city,
            zipCode: customerProfile.address.zipCode,
            country: !isEmpty(customerProfile.address.country) && customerProfile.address.country !== '' ? searchCountryCode(customerProfile.address.country) : ''
          }
        },
        companyProfile: companyProfile,
      }
    )
    .then((response) => response.data);

export const getScoringSMB = (
  customerProfile: CustomerProfileV3,
  journeyId: string
) =>
  api
    .put(
      `${envConfig.SCORING_ROUTE}/v2/task/score?storeInput=false&journeyId=${journeyId}`,
      {
        requestOptions: {
          requestId: "SMB_SCORE_V1",
        },
        customerProfile: {
          identity: customerProfile.identity,
          address: {
            street: customerProfile.address.street,
            city: customerProfile.address.city,
            zipCode: customerProfile.address.zipCode,
            country: !isEmpty(customerProfile.address.country) && customerProfile.address.country !== '' ? searchCountryCode(customerProfile.address.country) : ''
          }
        },
      }
    )
    .then((response) => response.data);

export const getCustomerFraudDataV3 = (
  customerProfile: CustomerProfileV3,
  journeyId: string
) =>
  api
    .put(
      `${envConfig.SCORING_ROUTE}/v2/task/score?storeInput=false&journeyId=${journeyId}`,
      {
        requestOptions: {
          requestId: "WEB_SCORE_B2C_V3", //
        },
        customerProfile: customerProfile,
      }
    )
    .then((response) => response.data);

export const getEReputation = (
  companyProfile: {
    name: string,
    address: {
      street: string
      city: string
      zipCode: string
      country: string
    }
  },
  journeyId: string,
  width: number,
  height: number
) =>
  api
    .put(
      `${envConfig.SCORING_ROUTE}/v2/task/score?storeInput=false&journeyId=${journeyId}`,
      {
        companyProfile,
        requestOptions: {
          requestId: "E_REPUTATION_SCORE_V2",
        },
        additionalParameters: {
          width: width,
          height: height,
        }
      }
    )
    .then((response) => response.data);

export default {getScoringB2B, getEReputation};
