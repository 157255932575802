<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from "vue";

import { TextInputType, getRegex } from "@/types/TextInputType";
import { emailDomains } from "@/utils/emailDomains";
import { sleep } from "@/utils/sleep";

const props = defineProps<{
  countryCode?: string;
  label: string;
  disabled?: boolean;
}>();

const model = defineModel<string>();

const options = ref<string[]>([]);
const showOptions = ref(false);
const emailInput = ref<HTMLInputElement>();

const domains = computed<string[]>(() => {
  // @ts-ignore
  if (props.countryCode && emailDomains[props.countryCode])
    // @ts-ignore
    return emailDomains[props.countryCode];
  return emailDomains["FR"];
});

const selectOption = async (optionIndex: number) => {
  const splittedEmail = model.value?.trim().split("@");
  if (splittedEmail && splittedEmail[0]) {
    const domain = options.value[optionIndex];
    const emailAddress = splittedEmail[0] + "@" + domain;
    model.value = emailAddress;
    await emailInputFocusoutEvent();
  }
};

const emailInputFocusoutEvent = async () => {
  await sleep(400);
  showOptions.value = false;
};

const isEmail = (email: string) => {
  const emailRegex = getRegex(TextInputType.EMAIL);
  return emailRegex.test(email);
};

watch(
  () => model.value,
  (value) => {
    if (value) {
      if (value.includes("@")) {
        const splittedEmail = value.split("@");
        options.value = domains.value.filter(
          (d) => !d.indexOf(splittedEmail[1])
        );
        if (options.value.length > 0) {
          showOptions.value = !options.value.includes(splittedEmail[1]);
        } else {
          showOptions.value = false;
        }
      }
      if (isEmail(value)) {
        showOptions.value = false;
      }
    }
  }
);

onMounted(() => {
  emailInput.value?.addEventListener("focusout", emailInputFocusoutEvent);
});

onUnmounted(() => {
  emailInput.value?.removeEventListener("focusout", emailInputFocusoutEvent);
});
</script>

<template>
  <div class="email-input-div">
    <label class="dark">{{ label }}</label>
    <input
      type="email"
      ref="emailInput"
      v-model="model"
      :disabled="disabled"
      class="form-text-input input"
    />
    <div class="email-select-container" :class="{ show: showOptions }">
      <div
        v-for="(option, idx) in options"
        @click="() => selectOption(idx)"
        :key="idx"
        class="email-select-option"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/component/input/emailInput.scss";
</style>
