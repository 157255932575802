<script setup lang="ts">

import {onBeforeUnmount, onMounted, reactive, Ref, ref, watch} from 'vue';
import {translate} from "@/i18n";
import NotificationCard from "@/components/card/NotificationCard.vue";
import {
  getNotificationType,
  isLastChild,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  Notification,
  notificationBuilder,
  NotificationsSet,
  userNotificationRouter,
  markNotificationAsDeleted,
  markAllNotificationsAsDeleted,
  setNotifications, confirmAction, cancelAction, NotificationServices, INTERVAL
} from "@/utils/notifications";
import {size} from "lodash";
import {deleteNotifications, getAllNotifications, getNotification, setAlertsToViewed} from "@/apiCalls/notificationApi";
import {useRoute, useRouter} from "vue-router";
import Button from "@/components/button/Button.vue";
import {Routes} from "@/utils/openBankingUtils";

const props = withDefaults(defineProps<{
  openedComponent: string
}>(), {
  openedComponent: ''
});

const emit = defineEmits(['notificationsOpened']);

const route = useRoute();
const router = useRouter();

// reactive
const isNotification = ref(false);
const isNewNotification = ref(false);
const isOpen = ref(false);
const isBackOffice = ref(false);
const isDeleteAllActionTriggered = ref(false);
const activeTab = ref('new');
let newPopNotification: Notification[] = reactive([]);
let newNotifications: NotificationsSet = reactive({today: [], yesterday: [], older: []});
let readNotifications: NotificationsSet = reactive({today: [], yesterday: [], older: []});

const isMenuBurgerOpened = ref(false);

// constants
const header = translate('NOTIFICATIONS.TITLE');
const tabs = [
  {type: 'new', label: translate('NOTIFICATIONS.NEW'), notifications: newNotifications},
  {type: 'read', label: translate('NOTIFICATIONS.READ'), notifications: readNotifications}
];

// functions
const showNotifications = () => {
  isDeleteAllActionTriggered.value = false;
  isOpen.value = !isOpen.value;
  activeTab.value = 'new';
  emit('notificationsOpened', 'Notifications');
};
const sizeOfNotificationSet = (notifications: NotificationsSet) => size(notifications.today) > 0 || size(notifications.yesterday) > 0 || size(notifications.older) > 0;

// watchers
watch(newNotifications, () => isNotification.value = sizeOfNotificationSet(newNotifications));
watch(newPopNotification, () => isNewNotification.value = size(newPopNotification) > 0);
watch(() => route.path, (newPath) => isBackOffice.value = newPath.includes(Routes.BACK_OFFICE));

watch(() => props.openedComponent, (newVal) => {
  if (newVal !== 'Notifications') {
    isDeleteAllActionTriggered.value = false;
    isOpen.value = false;
    activeTab.value = 'new';
  }
});


// helper & utils functions
const fetchAndSetNotifications = async () => {
  await getAllNotifications((notificationsData: Notification[]) => {
    const notifications = notificationsData.map(({
                                                   id,
                                                   scope,
                                                   status,
                                                   type,
                                                   title,
                                                   message,
                                                   viewed,
                                                   service,
                                                   journeyId,
                                                   taskId,
                                                   businessId,
                                                   createdAt
                                                 }) => notificationBuilder(id, scope, status, type, title, message, viewed, service, journeyId, taskId, businessId, createdAt));
    setNotifications(notifications, newNotifications, readNotifications);
    isNotification.value = sizeOfNotificationSet(newNotifications);
  });
}

/**
 * @deprecated - this function is deprecated and will be removed in the future
 * */
const listenForNewNotifications = () => {
  getNotification((newNotification: Notification) => {
    const notification = notificationBuilder(newNotification.id, newNotification.scope, newNotification.status, newNotification.type, newNotification.title, newNotification.message, newNotification.viewed, newNotification.service, newNotification.journeyId, newNotification.taskId, newNotification.businessId, newNotification.createdAt);
    if (notification.service === NotificationServices.OPEN_BANKING && notification.scope === 'PERSONAL' && notification.type === 'NOTIFICATION') {
      notification.viewed = false;
      const type = getNotificationType(notification);
      newNotifications[type].unshift(notification);
      isNotification.value = sizeOfNotificationSet(newNotifications);
      // for popup notification (only render the last three)
      if (size(newPopNotification) === 3) return;
      notification.isNew = true;
      newPopNotification.push(notification);
      isNewNotification.value = true;
    }
  });
}

const handleNotificationButtonActions = (tabType: string) => {
  tabType === 'new' ? markAllAsRead() : beginDeleteAll();
}

const deleteAllNotifications = async () => {
  const ids: string[] = markAllNotificationsAsDeleted(readNotifications);
  if (ids.length === 0) return;
  await deleteNotifications(ids);
}

const deleteNotification = async (notification: Notification) => {
  const id: string = notification.id;
  if (!id) return;
  markNotificationAsDeleted(id, readNotifications);
  await deleteNotifications([id]);
}

const markAsRead = async (notification: Notification) => {
  if (notification.viewed) {
    await userNotificationRouter(notification, router);
    return;
  }
  notification.viewed = true;
  await userNotificationRouter(notification, router);
  const type = getNotificationType(notification);
  markNotificationAsRead(notification, newNotifications, readNotifications, type);
  await setAlertsToViewed([notification.id]);
}

const markAllAsRead = async () => {
  const ids: string[] = markAllNotificationsAsRead(newNotifications, readNotifications);
  if (ids.length === 0) return;
  await setAlertsToViewed(ids);
}

const beginDeleteAll = () => {
  isDeleteAllActionTriggered.value = true;
};

const confirmDelete = () => {
  isDeleteAllActionTriggered.value = false;
  deleteAllNotifications();
};

const cancelDelete = () => {
  isDeleteAllActionTriggered.value = false;
};

// lifecycle hooks
let timeoutId: any = ref(null);
let fetchTimeoutId: any = ref(null);
const isOnMounted = ref(false);

const fetchNotifications = async (isOnMounted: Ref<boolean>) => {
  if (isOnMounted.value) await fetchAndSetNotifications();
  fetchTimeoutId.value = setInterval(async () => {
    await fetchAndSetNotifications();
  }, INTERVAL);
}

onMounted(async () => {
  isOnMounted.value = true;
  await fetchNotifications(isOnMounted);
});
onBeforeUnmount(() => {
  if (fetchTimeoutId) clearTimeout(fetchTimeoutId);
  if (timeoutId) clearTimeout(timeoutId);
});

</script>

<template>
  <div class="notifications">
    <div class="bell-icon">
      <img :class="isNewNotification ? 'icon jiggle': 'icon'"
           :src="require(`/public/images/bell-${isBackOffice ? 'dark': 'grey' }.png`)"
           alt="Notification Bell" @click="showNotifications"/>
      <div v-if="isNotification" class="red-dot"></div>
    </div>
    <transition name="slide">
      <div v-show="isOpen" class="notification-box" v-on:mouseleave="showNotifications"
           :class="{ 'blur-content': isDeleteAllActionTriggered }">
        <h6 v-html="header"/>
        <div class="tabs">
          <button class="tab-button" v-for="tab in tabs" :class="{ active: activeTab === tab.type }"
                  @click="activeTab = tab.type">
            {{ tab.label }}
          </button>
        </div>
        <div v-for="tab in tabs" v-show="activeTab === tab.type" class="tab-content">
          <span :class=" tab.type === 'new' ? 'new-read-button' : 'new-read-button read-button' "
                v-if="sizeOfNotificationSet(tab.notifications)"
                @click="handleNotificationButtonActions(tab.type)">{{
              tab.type === 'new' ? translate("NOTIFICATIONS.MARK_ALL") : translate("NOTIFICATIONS.DELETE_ALL")
            }}</span>
          <div class="notification-set">
            <div class="notification" v-if="sizeOfNotificationSet(tab.notifications)">
              <div v-for="(notificationGroup, groupKey) in tab.notifications">
                <div class="sticky-title" v-if="size(notificationGroup) > 0">
                  {{ translate(`NOTIFICATIONS.DATE.${String(groupKey).toUpperCase()}`) }}
                </div>
                <NotificationCard
                    v-for="notification in notificationGroup"
                    :class="isLastChild(notification, notificationGroup) ? 'last-item' : 'child-item'"
                    :notification="notification" @click="markAsRead(notification)"
                    @delete-notification="deleteNotification"
                />
              </div>
            </div>
            <div v-else class="no-notification">
              <span>{{ translate('NOTIFICATIONS.NO') }}</span>
            </div>
          </div>
        </div>
        <div v-if="isDeleteAllActionTriggered" class="notification-box-confirm-delete">
          <div class="actions">
            <Button :label="confirmAction()" class="confirm-action" @click="confirmDelete"/>
            <Button :label="cancelAction()" class="cancel-action" @click="cancelDelete"/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>