<template>
  <table>
    <thead>
      <tr>
        <th></th>
        <th v-for="key in years" class="center">{{ key }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="key in sortedKeys" :key="key">
        <td>{{ translate(`COMPANY.FINANCES.RATIOS.${key}`) }}</td>
        <td v-for="year in years" class="center">
          {{
            !isNil(tableData[key][year]) && !isNaN(tableData[key][year])
              ? convertToInternationalCurrencySystem(tableData[key][year])
              : "XXXX"
          }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import { translate } from "@/i18n";
import { isEmpty, isNil, uniq } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    isNil,
    translate,
    convertToInternationalCurrencySystem(labelValue: string) {
      // Nine Zeroes for Billions
      return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Number(labelValue) / 1.0e9).toFixed(2) + "B"
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Number(labelValue) / 1.0e6).toFixed(2) + "M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Number(labelValue) / 1.0e3).toFixed(2) + "K"
        : Math.abs(Number(labelValue)).toFixed(2);
    },
  },
  props: {
    tableData: { type: Object, required: true },
  },
  computed: {
    years() {
      return uniq(
        Object.values(this.tableData)
          .filter((el) => !isEmpty(el))
          .map((y) => Object.keys(y))
          .flat(1)
      ).sort((a: any, b: any) => b - a);
    },
    sortedKeys() {
      return Object.keys(this.tableData).sort((a: string, b: string) =>
        a.localeCompare(b)
      );
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

table {
  width: 100%;

  tbody {
    tr {
      &:nth-child(2n + 1) {
        background-color: $cardBackgroundColor;
      }
    }
  }
}

.center {
  text-align: center;
}
</style>
