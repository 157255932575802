<script lang="ts" setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import {
  CheckType,
  formatDataChecks,
  formatKeyValuePairWithFunc,
  selectAndSortChecksByKey,
} from "@/composables/formatKeyValuePairWithFunc";
import {locale, translate} from "@/i18n";
import {KeyValueType} from "@/utils/sortArrayObjectKeys";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import AuthenticityResults from "@/components/document-analysis/AuthenticityResults.vue";
import {isNull} from "lodash";

const props = defineProps({
  checks: {type: Object, default: {}},
  documentId: {type: String, default: ""},
  contextData: {type: Object, default: {}},
  isToPrint: {type: Boolean, default: false},
});

const {t} = useI18n();

const sortKeys: KeyValueType[] = [
  {
    INFO: ["siren", "closureDate", "openingDate", "fiscalPeriodDuration"],
  },
];

const formatInfo = (infoData: KeyValueType) => {
  const _infoData = Object.assign({}, infoData);
  _infoData.closureDate = !isNull(_infoData.closureDate) ? new Date(_infoData.closureDate as string).toLocaleDateString(locale) : '-';
  _infoData.openingDate = !isNull(_infoData.openingDate) ? new Date(_infoData.openingDate as string).toLocaleDateString(locale) : '-';
  const fiscalPeriodDuration = _infoData.fiscalPeriodDuration;
  _infoData.fiscalPeriodDuration = t(
      "DOCUMENT_CONTROL.TABLE.RESULT.TAX_REPORT.MONTH",
      {n: fiscalPeriodDuration},
      parseInt(fiscalPeriodDuration as string)
  );
  return _infoData;
};

const sortedCheks = computed(() => {
  let _checks = formatKeyValuePairWithFunc({
    data: props.checks,
    key: "INFO",
    funcs: [formatInfo],
  }) as CheckType;
  return selectAndSortChecksByKey({
    checks: _checks,
    sortKeys,
  });
});

const formattedChecks = computed(() => formatDataChecks(sortedCheks.value));
</script>

<template>
  <div class="tax-report-results">
    <ResultCard v-for="check in formattedChecks"
                :title="translate(`DOCUMENT_CONTROL.TABLE.RESULT.TAX_REPORT.${check.transKey}`)">
      <template v-for="obj in check.value">
        <template v-if="!Array.isArray(obj)">
          <LabelValuePrinter class="kbis-results-label"
                             :label="translate(`DOCUMENT_CONTROL.TABLE.RESULT.TAX_REPORT.${obj.transKey}`)"
                             :value="obj.value"/>
        </template>
      </template>
    </ResultCard>
    <AuthenticityResults :title="translate('DOCUMENT_CONTROL.AUTHENTICITY_DETAILS')"
                         :checks="checks.authenticityReasons"
    />
  </div>
</template>
