import { TextInputType, getRegex } from "@/types/TextInputType";

export function getFormattedRegistrationNumber(siren: string) {
  if (siren == null) {
    return;
  }
  // Regex pour mettre un espace tous les 3 caractères
  siren = siren?.replaceAll(" ", "")?.replaceAll(/(.{3})/g, "$1 ");
  // Si le dernier caractère est un espace je le supprime
  if (siren.charAt(siren?.length - 1) === " ") {
    siren = siren.slice(0, -1);
  }
  return siren;
}

export const formatSiren = (selectedOptionValue: string, siren: string) => {
  let _siren = siren?.length > 3 ? siren?.replaceAll(" ", "") : siren;
  if (_siren?.length === 10)
    return _siren?.replaceAll(/(.{4})(.{3})/g, "$1 $2 ");
  return _siren?.replaceAll(/(.{3})/g, "$1 ");
};

export const isES = (registrationNumber: string) => {
  const ESRegex = getRegex(TextInputType.TAX_IDENTIFICATION_CODE);
  return ESRegex.test(registrationNumber);
};

export default { getFormattedRegistrationNumber };
