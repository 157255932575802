<template>
  <div class="monitoring__my-usage">
    <ConsumptionSearch :users="users" @search="searchUsage" @reset="resetUsage" @select-all="allUsersSelected"
                       @select-company="companySelected" @select-my-team="myTeamSelected"/>
    <MonitoringCharts :total-label-usage="totalLabelUsage" :total-usage="totalUsage"
                      :total-value-usage="totalValueUsage"
                      :color-usage="generateRandomColorsArray(totalValueUsage.length)"
                      :consumption-to-display="titleToDisplay"
                      :is-monitoring-data-loading="isMonitoringDataLoading"
    />
    <MonitoringValues v-if="size(totalValueUsage) > 0" :scores-usages="scoresUsages" :usage-without-all="usageWithoutAll"/>
  </div>
</template>

<script lang="ts">
import {translate} from "@/i18n";
import {defineComponent} from "vue";
import Button from "@/components/button/Button.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import {mapGetters} from "vuex";
import Loader from "@/components/loader/Loader.vue";
import PieChart from "@/components/chart/PieChart.vue";
import monitoringMixin from "@/mixins/monitoringMixin";
import MonitoringValues from "@/components/monitoring/MonitoringValues.vue";
import MonitoringCharts from "@/components/monitoring/MonitoringCharts.vue";
import {getMyTeam} from "@/apiCalls/login";
import {getUsersByRole} from "@/apiCalls/backOfficeApi";
import ConsumptionSearch from "@/components/search/consumption/ConsumptionSearch.vue";
import {size} from "lodash";

export default defineComponent({
  components: {MonitoringCharts, MonitoringValues, PieChart, Loader, ConsumptionSearch, ResultCard, Button},
  mixins: [monitoringMixin],
  methods: {
    size,
    translate,
    async searchUsage(usersSelected: number[]) {
      await this.displayConsumption('SELECTED_USERS', usersSelected);
    },
    async resetUsage() {
      await this.displayConsumption('OF_THE_COMPANY');
    },
    async allUsersSelected(usersSelected: number[]) {
      await this.displayConsumption('SELECTED_USERS', usersSelected);
    },
    async companySelected() {
      await this.displayConsumption('OF_THE_COMPANY');
    },
    async myTeamSelected() {
      await this.displayConsumption('OF_MY_TEAM');
    },
    async displayConsumption(title: string, usersSelected?: number[]) {
      this.titleToDisplay = title;
      this.totalUsage = {};
      let response: any = [];
      this.isMonitoringDataLoading = true;
      switch (title) {
        case 'OF_MY_TEAM':
          response = await this.getMyTeamConsumption(this.filteredObj);
          break;
        case 'OF_THE_COMPANY':
          response = await this.getMyCompanyConsumption(this.filteredObj);
          break;
        case 'SELECTED_USERS':
          response = await this.getUserConsumption(this.filteredObj, usersSelected);
          break;
      }
      this.isMonitoringDataLoading = false;
      this.consumption(response);
      this.totalUsages();
      const displayInChart = Object.fromEntries(Object.entries(this.totalUsage).filter(([key, value]) => value !== 0));
      this.totalLabelUsage = Object.keys(displayInChart);
      this.totalValueUsage = Object.values(displayInChart) as number[];
    }
  },
  data: () => ({
    users: [],
    titleToDisplay: 'OF_THE_COMPANY'
  }),
  computed: {
    ...mapGetters(['user'])
  },
  async created() {
    if (this.user?.roles?.includes('COMPANY_ADMIN')) {
      this.users = await getUsersByRole(['COMPANY_USER', 'COMPANY_ADMIN', 'COMPANY_MANAGER']);
    } else {
      this.users = await getMyTeam();
    }
  }
})
</script>

<style scoped lang="scss">

</style>