<script setup lang="ts">
import * as _ from "lodash";
import {computed, onMounted, ref} from "vue";

import {downloadImage, downloadPDF} from "@/apiCalls/storage";
import cookiesUtils from "@/utils/cookiesUtils";
import {getTokenFromUrlParams} from "@/utils/getTokenFromUrlParams";

const props = withDefaults(
    defineProps<{
      titleIcon: string;
      titleLabel: string;
      journeyId: string;
      documentId: string;
      documentType: string;
      documentPath: string;
      documentName: string;
      scaleX: number;
      scaleY: number;
      isModalPreview: boolean;
      isInProgressFaceMatching: boolean;
    }>(),
    {
      titleIcon: "",
      titleLabel: "",
      journeyId: "",
      documentId: "",
      documentType: "",
      documentPath: "",
      documentName: "",
      scaleX: 1,
      scaleY: 1,
      isModalPreview: true,
    }
);

const documentBin = ref("");
const documentImage = ref({});
const documentURL = ref("");

const modalPreviewStyle = computed(() => ({
  "max-height": `${props.scaleY * 12}rem`,
  "max-width": `${props.scaleX * 10}rem`,
}));

const normalPreviewStyle = computed(() => ({
  "max-height": `${props.scaleY * 12}rem`,
  width: "14rem",
}));

const beforeCreate = () => {
  const accessToken =
      getTokenFromUrlParams() || cookiesUtils.getCookie("access_token") || "";
  if (_.isEmpty(props.documentId)) return;
  if (_.isEmpty(props.journeyId)) return;
  if (props.documentType === "pdf") {
    downloadPDF(props.journeyId, props.documentId, accessToken).then(
        (response) => {
          documentBin.value = URL.createObjectURL(response.data);
        }
    );
  } else {
    downloadImage(props.journeyId, props.documentId, accessToken).then(
        (response) => {
          const fileR = new FileReader();
          fileR.onload = (event: any) => {
            documentURL.value = event.target.result;
          };

          fileR.readAsDataURL(response.data);
        }
    );
  }
};

onMounted(() => {
  beforeCreate();
});
</script>

<template>
  <div class="preview-document" :style="isModalPreview ? modalPreviewStyle : normalPreviewStyle">
    <div class="preview-document-pdf" :class="'preview-' + scaleY" v-if="documentType === 'pdf'">
      <iframe class="preview-document-iframe" :src="documentBin"></iframe>
    </div>
    <div class="preview-document-img" v-else>
      <img :src="isInProgressFaceMatching ? '/images/face-recognition.png' : documentURL" alt="image not found"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.preview-document {
  display: flex;
  flex-direction: column;
  background-color: $dataPrinterFontColor;
  border-radius: 10px;
  padding: 0.05rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
  height: fit-content;
  text-align: center;
  justify-content: center;
  align-items: center;

  &-img {
    flex: 1;
    margin: 5%;
    border-radius: 10px;
    // height: 10rem;

    img {
      max-width: 80%;
      height: 10rem;
      object-fit: contain;
      object-position: center;
      color: white;
      cursor: pointer;
      max-height: 15rem;
    }
  }

  &-pdf {
    // height: 100%;
    cursor: pointer;
    margin: 1rem;
    max-height: 85%;
  }

  &-iframe {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  &-iframe-popup {
    height: 100%;
  }
}

.preview {
  height: 30rem;
}

.preview-1 {
  height: 10rem;
}

.preview-3 {
  height: 30rem;
}

.preview-document-img {
  overflow: auto;
}

.big-preview-document {
  .preview-document-img {
    img {
      height: 24rem;
      max-height: 25rem;
    }
  }
}
</style>
