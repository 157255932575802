import { ESBodaccAnnouncementTransformer } from "@/utils/dataTransformer/config/bodaccAnnouncement/es";

export const bodaccAnnouncementTransformer = (
  country: "BE" | "ES" | "FR",
  datas: []
) => {
  if (country === "ES")
    return datas.map((data) => ESBodaccAnnouncementTransformer(data));
  return datas;
};
