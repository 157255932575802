<script setup lang="ts">
import { computed } from "vue";

import {
  CheckType,
  formatDataChecks,
  formatKeyValuePairWithFunc,
  selectAndSortChecksByKey,
} from "@/composables/formatKeyValuePairWithFunc";
import { translate } from "@/i18n";
import {
  KeyValueType,
  getTranslationFromKey,
} from "@/utils/sortArrayObjectKeys";

import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import AuthenticityResults from "@/components/document-analysis/AuthenticityResults.vue";

const props = defineProps({
  checks: { type: Object, default: {} },
});

const sortKeys: KeyValueType[] = [
  { PAYSLIP_INFO: ["hireDate", "name", "siret"] },
  {
    PAYMENT_INFO: ["iban", "netPayableBeforeIncomeTax", "netPayable", "period"],
  },
];

const formatPayslipInfo = (payslipInfo: CheckType) => {
  const _payslipInfo = Object.assign({}, payslipInfo);
  _payslipInfo.PAYSLIP_INFO = {
    ..._payslipInfo.IDENTITY,
    ..._payslipInfo.COMPANY_INFO,
  };
  return _payslipInfo;
};

const formatPayslipPaymentInfo = (payslipPaymentInfo: KeyValueType) => {
  const _payslipPaymentInfo = Object.assign({}, payslipPaymentInfo);
  _payslipPaymentInfo.netPayableBeforeIncomeTax = `${_payslipPaymentInfo.netPayableBeforeIncomeTax} EUR`;
  _payslipPaymentInfo.netPayable = `${_payslipPaymentInfo.netPayable} EUR`;
  return _payslipPaymentInfo;
};

const sortedCheks = computed(() => {
  let _checks = formatPayslipInfo(props.checks) as KeyValueType;
  _checks = formatKeyValuePairWithFunc({
    data: _checks,
    key: "PAYMENT_INFO",
    funcs: [formatPayslipPaymentInfo],
  }) as KeyValueType;
  return selectAndSortChecksByKey({
    checks: _checks as CheckType,
    sortKeys,
  });
});
const formattedChecks = computed(() => formatDataChecks(sortedCheks.value));
</script>

<template>
  <div class="payslip-results">
    <ResultCard
      v-for="check in formattedChecks"
      :title="
        translate(`DOCUMENT_CONTROL.TABLE.RESULT.PAYSLIP.${check.transKey}`)
      "
    >
      <div :class="`payslip-results-${check.className}`">
        <template v-for="obj in check.value">
          <template v-if="!Array.isArray(obj)">
            <template v-if="typeof obj.value === 'object'">
              <LabelValuePrinter
                v-for="(value, key) in obj.value"
                :label="
                  translate(
                    `DOCUMENT_CONTROL.TABLE.RESULT.PAYSLIP.${getTranslationFromKey(
                      key.toString()
                    )}`
                  )
                "
                :value="value"
              />
            </template>
            <template v-else>
              <LabelValuePrinter
                v-if="!!obj.key"
                :label="
                  translate(
                    `DOCUMENT_CONTROL.TABLE.RESULT.PAYSLIP.${obj.transKey}`
                  )
                "
                :value="obj.value"
              />
              <div v-else></div>
            </template>
          </template>
          <template v-else>
            <template v-for="item in obj">
              <LabelValuePrinter
                v-if="!!item.key"
                :label="
                  translate(
                    `DOCUMENT_CONTROL.TABLE.RESULT.PAYSLIP.${item.transKey}`
                  )
                "
                :value="item.value"
              />
              <div v-else></div>
            </template>
          </template>
        </template>
      </div>
    </ResultCard>
    <AuthenticityResults
      :title="translate('DOCUMENT_CONTROL.AUTHENTICITY_DETAILS')"
      :checks="checks.authenticityReasons"
    />
  </div>
</template>
