import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const searchAmlcft = (lastname: string, firstname: string, birthdate: string, journeyId: string) =>
  api.put(`${envConfig.AMLCFT_ROUTE}/v3/search/customer?journeyId=${journeyId}`,
    {
      customerProfile: {
        identity: {
          lastName: lastname,
          firstName: firstname,
          birthDate: birthdate
        }
      },
      options: {
        fuzziness: 0.9,
        exactMatch: true
      }
    }).then((response) => response);

export default {searchAmlcft};