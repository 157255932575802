<script setup lang="ts">
import {computed, reactive, ref} from "vue";
import VChart from "vue-echarts";
import {CanvasRenderer} from "echarts/renderers";
import {use} from "echarts/core";
import {GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {GaugeChart} from "echarts/charts";

const props = withDefaults(defineProps<{
  showTitle?: boolean,
  showValue?: boolean
  title?: string,
  value?: number
}>(), {
  showTitle: false,
  showValue: false,
  title: '',
  value: 0
});

use([
  CanvasRenderer,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent
]);

const colorValue = ref('inherit');

const sizeOfTitle = () => {
  return props.showTitle ? 16 : 0; //put the fontSize to 0 when not show
}

const sizeOfValue = () => {
  if(!props.showValue) {
    colorValue.value = 'white';
    return 0; //put the fontSize to 0 when not show
  }
  return 17;
}

const fontWeightTitle = () => {
  return navigator.userAgent.includes('Chrome') ? 'lighter' : 'normal';
}

const options = reactive({
  series: [
    {
      type: 'gauge',
      startAngle: 180,
      endAngle: 0,
      center: ['50%', '65%'],
      radius: '90%',
      min: 0,
      max: 1,
      width: '20rem',
      height: '14rem',
      splitNumber: 0,
      axisLine: {
        lineStyle: {
          width: 75,
          color: [
            [0.25, `#ff7676`],
            [0.75, '#ffa543'],
            [1, '#36ba75'],
          ]
        }
      },
      pointer: {
        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
        length: '20%',
        width: 15,
        offsetCenter: [0, '-0%'],
        itemStyle: {
          color: '#717173'
        }
      },
      splitLine: {
        length: 0,
      },
      axisLabel: {
        formatter: function () {
          return '';  //need to return '' because it will display "Nan" if nothing is returned
        }
      },
      title: {
        offsetCenter: [0, '25%'],
        fontSize: `${sizeOfTitle()}`,
        color: 'inherit',
        fontWeight: `${fontWeightTitle()}`,
      },
      detail: {
        fontSize: `${sizeOfValue()}`,
        offsetCenter: [0, '40%'],
        valueAnimation: true,
        formatter: function () {
          return Math.round(props.value * 100) + '%'; //change the value to %
        },
        color: `${colorValue.value}`
      },
      data: [
        {
          value: props.value,
          name: `${props.title}`
        }
      ]
    }
  ]
});

</script>

<template>
  <v-chart class="gauge-chart" :option="options"/>
</template>
