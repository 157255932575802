<script setup lang="ts">
import "vue-json-pretty/lib/styles.css";

import { translate } from "@/i18n";

import BoxOutputCompanyFinances from "@/components/box-output/BoxOutputCompanyFinances.vue";
import AuthenticityResult from "@/components/document-analysis/AuthenticityResults.vue";
import BankDetailsResults from "@/components/document-analysis/BankDetailsResults.vue";
import { default as IdCheckResults } from "@/components/document-analysis/IdCheckResults.vue";
import IncomeTaxResults from "@/components/document-analysis/IncomeTaxResults.vue";
import KbisIndividualResults from "@/components/document-analysis/KbisIndividualResults.vue";
import KbisResults from "@/components/document-analysis/KbisResults.vue";
import PayslipResults from "@/components/document-analysis/PayslipResults.vue";
import PropertyTaxResults from "@/components/document-analysis/PropertyTaxResults.vue";
import TaxReportResults from "@/components/document-analysis/TaxReportResults.vue";
import PrintCard from "@/components/print/shared/PrintCard.vue";

const props = defineProps<{
  documentType: string;
  documentData: any;
  documentName: string;
  documentFileId: string;
  canCompanyFinances: boolean;
  contextData: any;
  idCheckProvider: any;
  taxNoticeAnalysisCheck: any;
  liveCheckStatus: any;
  faceMatchingStatus: any;
}>();

const isIdCheck = (documentType: string): boolean => {
  return (
    documentType === "DRIVING_LICENSE" ||
    documentType === "ID_CARD" ||
    documentType === "PASSPORT" ||
    documentType === "RESIDENCE_PERMIT"
  );
};

const getComponent = (docType: string) => {
  switch (true) {
    case docType === "COMPANY_KBIS":
      return KbisResults;
    case docType === "INDIVIDUAL_KBIS":
      return KbisIndividualResults;
    case docType === "PROPERTY_TAX":
      return PropertyTaxResults;
    case docType === "BANK_DETAILS":
      return BankDetailsResults;
    case docType === "PAYSLIP":
      return PayslipResults;
    case docType === "INCOME_TAX":
      return IncomeTaxResults;
    case ["TAX_REPORT_S", "TAX_REPORT_C"].includes(docType):
      return TaxReportResults;
    case isIdCheck(docType):
      return IdCheckResults;
    default:
      return AuthenticityResult;
  }
};
</script>

<template>
  <PrintCard
    :title="`DOCUMENT_CONTROL.${
      isIdCheck(documentType) ? 'ID_ANALYSIS_TITLE' : 'DOCUMENT_ANALYSIS_TITLE'
    }`"
    class="print-card-document-analysis"
  >
    <div class="print-document-analysis-result-file-container">
      <div class="print-document-analysis-result-file-detail">
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.TYPE") }} :
        </span>
        <span class="table-document-detail-result-analysis-titles-value"
          >{{
            translate(
              `PRINT.DOCUMENT_ANALYSIS.DOCUMENT_CONTROL.${
                faceMatchingStatus !== 'NONE' ? 'FACE_MATCHING' : documentType === "OCR" ? documentData?.DOC_TYPE : documentType
              }`
            )
          }}
        </span>
      </div>
      <div v-if="faceMatchingStatus === 'NONE'" class="print-document-analysis-result-file-detail">
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.NAME") }} :
        </span>
        <span class="table-document-detail-result-analysis-titles-value">
          &nbsp;{{ documentName ? documentName[0] : "" }}
        </span>
      </div>
      <div
        v-if="documentType && documentType === 'AUTHENTICITY'"
        class="print-document-analysis-result-file-detail"
      >
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.COMPLIANCE") }} :
        </span>
        <img
          class="print-document-analysis-result-file-detail-image"
          :src="`/images/${documentData?.AUTHENTICITY}`"
          :alt="documentData?.AUTHENTICITY"
        />
      </div>
      <div class="print-document-analysis-result-file-detail-label">
        <span class="table-document-detail-result-analysis-titles-label">
          {{ translate("PRINT.DOCUMENT_ANALYSIS.DETAILS") }}
        </span>
      </div>
      <component
        :is="getComponent(documentType)"
        :checks="documentData"
        :type="documentType"
        :provider="idCheckProvider"
        :live-check-status="liveCheckStatus?.status"
        :contextData="contextData"
        :documentId="documentFileId"
        :analysis-checks="taxNoticeAnalysisCheck"
        :face-matching-status="faceMatchingStatus"
      />
      <span v-if="documentType === 'UNKNOWN'">{{
        documentData.statusReason
      }}</span>
      <BoxOutputCompanyFinances
        v-if="['TAX_REPORT_S', 'TAX_REPORT_C'].includes(documentData?.DOC_TYPE as string)"
        :tax-reports="contextData?.tax_reports_result"
        :can-company-finances="canCompanyFinances"
      />
    </div>
  </PrintCard>
</template>
