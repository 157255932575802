<script setup lang="ts">
import {onBeforeUnmount, onMounted, ref} from "vue";

const colors = ref(['#FBC02D', '#E53935', '#43A047']);
const tColors = ref(['#FBC02D55', '#E5393555', '#43A04755']);
const currentInterval = ref();
const currentColor = ref(0);

const changeColor = () => {
  currentColor.value = (currentColor.value + 1) % colors.value.length;
};

onMounted(() => {
  currentInterval.value = setInterval(() => {
    changeColor()
  }, 3000);
});

onBeforeUnmount(() => {
  clearInterval(currentInterval.value);
});
</script>
<template>
  <div class="MeeLoader no-print" :style="{'--bg-color': colors[currentColor],'--t-bg-color': tColors[currentColor],}">
    <span></span>
  </div>
</template>