<script setup lang="ts">
import {use} from 'echarts/core';
import {CanvasRenderer} from 'echarts/renderers';
import {GraphChart} from 'echarts/charts';
import {TitleComponent, TooltipComponent, LegendComponent, GridComponent} from 'echarts/components';
import {nextTick, onMounted, ref, watch} from "vue";
import * as echarts from 'echarts';
import {NodeChart} from "@/types/Cartography";

use([
  CanvasRenderer,
  GraphChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);

const props = withDefaults(
    defineProps<{
      option: object;
      formatterFunction: Function //need to pass the function in props,
      zoom: number
    }>(), {
      zoom: 2
    }
);

const emit = defineEmits(['send-data-type', 'zoom-update']);

const chartElement = ref<HTMLDivElement | null>(null);
let chart: echarts.ECharts | null = null;
const options = ref(JSON.parse(JSON.stringify(props.option)));  //options for the chart
const nodesFixed = ref(false);

const fixNodes = () => {
  options.value.series[0].data.forEach((node: NodeChart) => { //fixing all the nodes
    node.fixed = true;
  });
  nodesFixed.value = true;
}

const initChart = () => {
  if (chartElement.value) {
    options.value.tooltip = {
      trigger: 'item',
      formatter: props.formatterFunction
    };
    chart = echarts.init(chartElement.value); //initialize the graph
    chart.setOption(options.value); //set the options

    chart.on('click', (params: echarts.ECElementEvent) => { //when a user click on a node
      if(params?.data?.symbolSize !== 40) {
        emit('send-data-type', params);
      }
    });

    chart.on('finished', () => { //need to update the zoom in options when user is zooming with mouse
      fixNodes();
      const option = chart?.getOption();
      const actualZoom = option?.series[0]?.zoom;
      if (actualZoom && (options.value.series[0].zoom !== actualZoom)) {
        options.value.series[0].zoom = actualZoom;
        chart?.setOption(options.value);
        emit('zoom-update', options.value.series[0].zoom); //need to indicate to parent that the zoom has been changed
      }
    });
  }
};

watch(() => props.zoom, (newValue) => {
  if (!nodesFixed.value) { //fixing nodes if it's not already the case
    fixNodes();
  }
  options.value.series[0].zoom = newValue;
  chart?.setOption(options.value); //need to update the options
});

onMounted(async () => {
  await nextTick(); // make sure the DOM is ready
  initChart();
});
</script>

<template>
  <div class="container-chart">
    <div ref="chartElement" class="chart"></div>
  </div>
</template>