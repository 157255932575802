<script setup lang="ts">
import {computed, defineProps} from "vue";
import {translate} from "@/i18n";

/**
 * The Bar component is a simple component that sits at the top of the view,
 * and shows a scroll text animation.
 *
 * @prop {string} type
 * @prop {string} header
 * @prop {string} text
 * */

const props = defineProps({
  type: {
    type: String,
    default: "danger",
    validator: (value) => ["warning", "success", "danger"].includes(value),
  },
  header: {
    type: String,
    default: "MAINTENANCE.ONGOING",
    validator: (value) => ["MAINTENANCE.ONGOING, MAINTENANCE.FINISHED"].includes(value),
  },
  text: {type: String, required: true},
});

const uppercaseHeader = computed(() => translate(props.header).toUpperCase());

</script>

<template>
  <div class="info-bar" :class="props.type">
    <div class="header">
      <span>{{ uppercaseHeader }}</span>
    </div>
    <div class="marquee-container" >
      <div class="marquee">
        <div class="marquee-content">
          <span>{{ text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>