<template>
  <table>
    <thead>
    <tr class="titles">
      <th>{{ translate('OPEN_BANKING.ALERTS.TYPE') }}</th>
      <th>{{ translate('OPEN_BANKING.ALERTS.CAUSE') }}</th>
      <th>{{ translate('OPEN_BANKING.ALERTS.AMOUNT') }}</th>
      <th>{{ translate('OPEN_BANKING.ALERTS.DATE') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in Object.keys(data)">
      <th>
        {{ translateAlertName(data[item].name) }}
      </th>
      <td>
        {{ data[item].description }}
      </td>
      <td>
        {{ `${data[item].details?.transaction || '-'}` }}
      </td>
      <td>
        {{ `${data[item].details?.transaction.date || '-'}` }}
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script lang="ts">

import {defineComponent} from "vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import {translate} from "@/i18n";

export default defineComponent({
  name: "OBTable",
  components: {LabelValuePrinter},
  props: {
    data: {} as any,
    currency: {type: String, default: ''},
  },
  methods: {
    translate,
    translateAlertName(alert: string) {
      switch (alert) {
        case 'Bank with extreme risk level':
          return translate('OPEN_BANKING.ALERT.NAME.BANK_WITH_EXTREME_RISK_LEVEL');
        case 'Bank with high risk level':
          return translate('OPEN_BANKING.ALERT.NAME.BANK_WITH_HIGH_RISK_LEVEL');
        case 'Month negative balance':
          return translate('OPEN_BANKING.ALERT.NAME.MONTH_NEGATIVE_BALANCE');
        case 'Presence of allowances':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_ALLOWANCES');
        case 'Presence of cash collections':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_CASH_COLLECTIONS');
        case 'Presence of credits per month':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_CREDITS_PER_MONTH');
        case 'Presence of cryptocurrencies':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_CRYPTOCURRENCIES');
        case 'Presence of gamblings':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_GAMBLINGS');
        case 'Presence of internationals transfers':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_INTERNATIONALS_TRANSFERS');
        case 'Presence of intervention fees':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_INTERVENTION_FEES');
        case 'Presence of pensions':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_PENSIONS');
        case 'Presence of rejects':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_REJECTS');
        case 'Presence of seizure fees':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_SEIZURE_FEES');
        case 'Presence of unpaids':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_UNPAIDS');
        case 'Presence of withdrawals per month':
          return translate('OPEN_BANKING.ALERT.NAME.PRESENCE_OF_WITHDRAWALS_PER_MONTH');
        case 'Transaction history count':
          return translate('OPEN_BANKING.ALERT.NAME.TRANSACTION_HISTORY_COUNT');
        case 'Transaction history date':
          return translate('OPEN_BANKING.ALERT.NAME.TRANSACTION_HISTORY_DATE');
        case 'Transaction history for analysis.':
          return translate('OPEN_BANKING.ALERT.NAME.TRANSACTION_HISTORY_FOR_ANALYSIS');
      }
    }
  },
})
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.titles {
  font-size: 1em;

  .categories {
    text-align: center;
  }
}

table {
  border: solid 0;
  border-radius: 20px;
  background-color: $dataPrinterBackgroundColor;
  width: 100%;

  tbody {
    font-size: 0.7em;
  }

  td {
    text-align: center;
    border: solid 2px $cardBackgroundColor;
  }

  th {
    padding: 0.5vh 1vw;
    border: solid 2px $cardBackgroundColor;
  }
}
</style>