import moment from "moment/moment";
import {locale} from "@/i18n";

export const today = new Date();
export const thisWeekStart = new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() || 7) + 1));
export const thisWeekEnd = new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() || 7) + 7));
export const lastWeekStart = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 6));
export const lastWeekEnd = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()));
export const thisMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
export const lastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

//For historical
export const createdAfter = new Date(new Date().setDate(new Date().getDate() - 90)).toLocaleString('fr');

export const formatLocaleDateTime = (date: string): string => {
  return `${new Date(date).toLocaleString(locale)} (UTC+0)`;
}

export const formatLocaleDateTimeUTC = (date: string): string => {
  const localDate = moment.utc(date).local();
  const offsetInHours = moment().utcOffset() / 60;
  return `${moment(localDate).format(locale === "en" ? "MM/DD/YYYY HH:mm" : "DD/MM/YYYY HH:mm")} (UTC${offsetInHours >= 0 ? '+' : ''}${offsetInHours})`;
}