<template>
  <div class="manage-users">
    <h1 class="manage-users__title">{{ translate('BACKOFFICE.USERS.COMPANY_USERS') }}</h1>
    <span class="manage-users__subtitle">{{ `${translate('RESEARCH')} :` }}</span>
    <form class="manage-users__search" @submit="searchUser" @reset="resetSearch">
      <div class="manage-users__search__filter">
        <TextInput :label="`${translate('IDENTITY.NAME')} :`" v-model="contentSearch.userName"/>
        <TextInput :label="`${translate('IDENTITY.EMAIL')} :`" v-model="contentSearch.email"/>
        <TextInput :label="`${translate('IDENTITY.PHONE_NUMBER')} :`" v-model="contentSearch.phone"/>
        <Select v-model:value="contentSearch.group"
                :options="groups?.map(({name}) => name)"
                :values="groups?.map(({name}) => name)"
                :title="`${translate('BACKOFFICE.USERS.GROUP')} :`"
                class="manage-users__search__filter__select-group"
                :value="contentSearch.group" :empty-option="true"
                :resetValue="shouldReset"
        />
        <Select v-model:value="contentSearch.place"
                :options="places?.map(({name}) => name)"
                :values="places?.map(({name}) => name)"
                :title="`${translate('BACKOFFICE.USERS.PLACE')} :`"
                class="manage-users__search__filter__select-group"
                :value="contentSearch.place" :empty-option="true"
                :resetValue="shouldReset"
        />
        <Select v-model:value="contentSearch.profiles"
                :options="profiles?.map(({name}) => name)"
                :values="profiles?.map(({name}) => name)"
                :title="`${translate('BACKOFFICE.USERS.PROFILE')} :`"
                class="manage-users__search__filter__select-group"
                :value="contentSearch.profiles" :empty-option="true"
                :resetValue="shouldReset"
        />
      </div>
      <div class="manage-users__search__action">
        <Button :label="translate('BUTTONS.RESET')" type="reset"/>
        <Button :label="translate('BUTTONS.SEARCH')" type="submit"/>
      </div>
    </form>
    <div class="manage-users__add">
      <Button :label="translate('BUTTONS.ADD')" picture="plus.png" @click="addModal"/>
      <BackOfficeUserModal :modal-flag="addUserModalFlag" :content="contentAddModal" :step="firstStepAdd"
                           @validate-action="validateUser" @next-step="nextStep" @cancel-action="cancelAddUser"
                           :supervisors="supervisors" v-model:check-box="contentAddModal.isManager" :places="places"
                           :groups="groups" :user="user" :title="translate('BACKOFFICE.USERS.USER_ADD')"
                           :profiles="profiles" v-model:profiles-checked="profilesUser" @back-first-page="nextStep"/>
    </div>
    <div class="manage-users__table">
      <BackOfficeUserTable :titles="titleUsers" :content-columns="contentUsers" :filter-element="filterUsers"
                           @update-user="updateUser" :supervisors="supervisors" :places="places" :groups="groups"
                           :user="user" :profiles="profiles"/>
      <div class="manage-users__table__actions">
        <div class="manage-users__table__actions__elements">
          <span>{{
              `${translate('PAGE')} ${currentPage + 1} ${translate('ON')} ${totalPages} - ${totalElements} ${translate('RESULTS_FOUND')}`
            }}</span>
        </div>
        <div class="manage-users__table__actions__page-navigation">
          <Button v-if="currentPage <= totalPages && currentPage > 0" @click="movePages(-1)"
                  :label="translate('TABLE.BACK')"/>
          <p>{{ currentPage + 1 }} / {{ totalPages }}</p>
          <Button v-if="currentPage < totalPages - 1 && currentPage >= 0" @click="movePages(1)"
                  :label="translate('TABLE.NEXT')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ResultCard from "@/components/card/ResultCard.vue";
import TextInput from "@/components/input/TextInput.vue";
import Button from "@/components/button/Button.vue";
import BackOfficeUserTable from "@/components/backoffice/BackOfficeUserTable.vue";
import ButtonMeelo from "@/components/button/Button.vue";
import usersBackOfficeMixin from "@/mixins/usersBackOfficeMixin";
import Select from "@/components/select/Select.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import BackOfficeUserModal from "@/components/backoffice/BackOfficeUserModal.vue";
import {getGroups, getPlaces, getUsersByRole} from "@/apiCalls/backOfficeApi";
import {mapGetters} from "vuex";
import {getProfilesByCompanyAdmin} from "@/apiCalls/backOffice/admin/profiles";
import {getProfilesByCompanyManager} from "@/apiCalls/backOffice/manager/profiles";

export default defineComponent({
  components: {
    BackOfficeUserModal,
    ContentCard, CustomModal, Select, ButtonMeelo, BackOfficeUserTable, TextInput, ResultCard, Button
  },
  mixins: [usersBackOfficeMixin],
  data: () => ({
    addUserModalFlag: false,
    firstStepAdd: true,
    supervisors: [] as Array<Object>,
    groups: [],
    places: [],
    profiles: []
  }),
  methods: {
    addModal() {
      this.checkBoxRoleAddedUpdate = false;
      this.profilesUser = [];
      this.addUserModalFlag = !this.addUserModalFlag;
    },
    cancelAddUser() {
      this.firstStepAdd = !this.firstStepAdd;
      this.addUserModalFlag = !this.addUserModalFlag;
      this.firstStepAdd = true;
      this.checkBoxRoleAddedUpdate = false;
      this.contentAddModal = {
        companyName: '',
        username: '',
        email: '',
        enabled: undefined,
        phone: '',
        function: '',
        place: '',
        group: '',
        isManager: false,
        supervisor: {
          id: 0,
          username: ''
        },
        profiles: []
      };
    },
    nextStep() {
      this.firstStepAdd = !this.firstStepAdd;
    },
    validateUser() {
      this.validateAddUser();
      this.firstStepAdd = true;
      this.addUserModalFlag = !this.addUserModalFlag;
      this.checkBoxRoleAddedUpdate = false;
      this.contentAddModal = {
        companyName: '',
        username: '',
        email: '',
        enabled: undefined,
        phone: '',
        function: '',
        place: '',
        group: '',
        isManager: false,
        supervisor: {
          id: 0,
          username: ''
        },
        profiles: []
      };
    }
  },
  async created() {
    await this.usersForCompany({sortName: 'username', sortDirection: 'ASC'});
    this.supervisors = await getUsersByRole(['COMPANY_ADMIN', 'COMPANY_MANAGER']);
    this.groups = await getGroups();
    this.places = await getPlaces();
    const profilesApi = this.user.roles.includes('COMPANY_ADMIN') ? await getProfilesByCompanyAdmin() : await getProfilesByCompanyManager();
    this.profiles = profilesApi.filter((objet: any) => objet?.enabled).filter((obj: {
      name: string
    }) => obj.name !== 'ADMIN_ACCESS');
  },
  computed: {
    ...mapGetters(['user'])
  },
  watch: {
    shouldReset() { //to clean the text input
      this.contentSearch.userName = "";
      this.contentSearch.email = "";
      this.contentSearch.phone = "";
    }
  }
})
</script>
