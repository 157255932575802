import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ContentCard = _resolveComponent("ContentCard")!

  return (_openBlock(), _createBlock(_component_ContentCard, {
    title: _ctx.translate('RECOVERY.TITLE'),
    class: "recovery"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TextInput),
      _createVNode(_component_TextInput),
      _createVNode(_component_Button, {
        label: _ctx.translate('BUTTONS.VALIDATE')
      }, null, 8, ["label"])
    ]),
    _: 1
  }, 8, ["title"]))
}