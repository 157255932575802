<script setup lang="ts">
import {ref} from "vue";

const props = withDefaults(
    defineProps<{
      value: Array<string>
    }>(), {});

const emit = defineEmits(['update:value']);

const focus = ref(0);
const itemRefs = ref([]);

const paste = (e: ClipboardEvent) => {
  e.preventDefault();
  if (!e.clipboardData) return;
  const data = e.clipboardData.getData('text/plain');
  if (data.length !== props.value.length) return;
  const newValue = data.split('');
  if (newValue.some((elt) => isNaN(parseInt(elt)))) return;
  emit('update:value', newValue);
}

const inputValue = (e: any, idx: number) => {
  const {target: {value}} = e;
  if (isNaN(parseInt(value)) && value !== '') {
    itemRefs.value[idx].value = props.value[idx];
    return;
  }
  if (value === '') {
    emit('update:value', [...props.value.slice(0, idx), '', ...props.value.slice(idx + 1)]);
    focusInput(Math.max(idx - 1, 0));
    return;
  }
  emit('update:value', [...props.value.slice(0, idx), value[0], ...props.value.slice(idx + 1)]);
  focusInput(Math.min(idx + 1, props.value.length - 1));
}

const focusInput = (idx: number) => {
  const input: HTMLInputElement = itemRefs.value[idx];
  input.focus();
  input.select();
}

const setItemRef = (el, index) => {
  if (el) {
    itemRefs.value[index] = el
  } else {
    itemRefs.value.splice(index, 1)
  }
}

</script>

<template>
  <div class="two-factor-secret-input">
    <input v-for="(elt, idx) in value"
           :ref="ele => setItemRef(ele, idx)"
           :autofocus="focus === idx"
           :value="elt"
           v-on:input="inputValue($event, idx)"
           v-on:paste="paste"
    />
  </div>
</template>

