export function base64ToArrayBuffer(base64: any) {
  const binaryString = atob(base64);
  const length = binaryString.length;
  const bytes = new Uint8Array(length);
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export function downloadPDF(blob: Blob | MediaSource, filename: string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
}

export function generatePDF(base64: any, nameFile: string) {
  // Convertir la chaîne Base64 en ArrayBuffer
  const arrayBuffer = base64ToArrayBuffer(base64);

  // Créer un Blob à partir de l'ArrayBuffer
  const blob = new Blob([arrayBuffer], {type: 'application/pdf'});

  // Télécharger le PDF
  downloadPDF(blob, nameFile);
}