<script setup lang="ts">
import {translate} from '@/i18n';
import {phoneIndicator} from '@/utils/phoneIndicator';
import TextInput from '@/components/input/TextInput.vue';
import {phone} from 'phone';

const props = withDefaults(
    defineProps<{
      required?: boolean,
      highlight?: boolean,
      disablePhoneValue?: boolean
    }>(),
    {
      required: true,
      highlight: false,
      disablePhoneValue: false,
    }
);

const emit = defineEmits(["phone-format"]);

const phoneValue = defineModel('phoneValue', {
  default: "+33", type: String, set(value) {
    //to set the value in the format "+33612..." (remove the 0 after the indicator)
    if (!value) return;
    let formattedPhone = value.trim();
    const _phone = phone(formattedPhone);

    return _phone.isValid ? _phone.phoneNumber.trim() : value.trim()
  }
});

const phoneCountry = defineModel('phoneCountry', {default: "+33", type: String});

const onChange = (event: any) => {
  phoneCountry.value = event.target.value;
  phoneValue.value = event.target.value;
}

</script>

<template>
  <div class="phone-input">
    <select class="phone-input__select-country" v-model="phoneCountry" @change="onChange"
            :disabled="disablePhoneValue">
      <option v-for="(country, index) in phoneIndicator" :key="index + 1" :value="country.value">{{
          country.code
        }}
      </option>
    </select>
    <TextInput class="phone-input__text-input" :label="translate('IDENTITY.PHONE_NUMBER')" :highlight="highlight"
               v-model="phoneValue" :disabled="disablePhoneValue" :required="required"/>
  </div>
</template>


