<script setup lang="ts">
import { ref, watch, computed, defineProps, defineEmits } from 'vue';
import Button from "@/components/button/Button.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import InlineTextInput from "@/components/input/InlineTextInput.vue";
import { translate } from '@/i18n';
import { convertTypeToText } from "@/utils/backOffice";
import { changeParams } from "@/apiCalls/backOffice/rulesEngine";

const props = defineProps({
  content: Object,
  parameterModalFlag: Boolean,
  title: String
});

const emits = defineEmits(['parameter-edited', 'action-modal']);

const editParameterModalFlag = ref(false);
const parameterValue = ref<any|null>(null);

const editModal = () => {
  editParameterModalFlag.value = !editParameterModalFlag.value;
};

const cancelAction = () => {
  editModal();
};

const actionModal = () => {
  emits('action-modal', editParameterModalFlag.value);
};

const updateParameterValue = () => {
  if (Array.isArray(props.content?.VALUE)) {
    parameterValue.value = JSON.stringify(props.content?.VALUE);
  } else {
    parameterValue.value = props.content?.VALUE;
  }
};

const validateParameterEdit = async (value: any) => {
  editModal();
  let newValue = null;
  switch (props.content?.TYPE) {
    case "Float":
      newValue = parseFloat(value);
      break;
    case "Integer":
      newValue = parseInt(value);
      break;
    case "String":
      newValue = value;
      break;
    case "Boolean":
      newValue = (value === "true");
      break;
    case "List[String]":
      newValue = JSON.parse(value);
  }
  await changeParams({
    [props.content?.NAME]: newValue
  });
  emits('parameter-edited');
};

watch(() => props.parameterModalFlag, (newVal) => {
  editParameterModalFlag.value = newVal;
  updateParameterValue();
}, { immediate: true });

watch(editParameterModalFlag, (newVal) => {
  actionModal();
}, { immediate: true });

const parameterInfo = computed(() => props.content);

updateParameterValue();

</script>

<template>
  <CustomModal v-model:open="editParameterModalFlag" custom-class="modal-edit-parameter">
    <ResultCard :title="title">
        <div class="modal-edit-parameter__content">
          <span>
            <span class="modal-edit-parameter__content__subtitle">{{`${translate('BACKOFFICE.RULES_ENGINE.RULES.PARAMETER_NAME')} :`}}</span>
            <span>{{ ` ${parameterInfo?.NAME}` }}</span>
          </span>
          <span>
            <span class="modal-edit-parameter__content__subtitle">{{ translate('BACKOFFICE.RULES_ENGINE.RULES.PARAMETER_DESCRIPTION') }} :</span>
            <span>&nbsp;{{ parameterInfo?.DESCRIPTION ? parameterInfo?.DESCRIPTION : 'ø' }}</span>
          </span>
          <span>  
            <span class="modal-edit-parameter__content__subtitle">{{ translate('BACKOFFICE.RULES_ENGINE.RULES.TYPE') }} :</span>
            <span>&nbsp;{{ translate(convertTypeToText(parameterInfo?.TYPE)) }}</span>
          </span> 
          <span>
            <InlineTextInput v-model="parameterValue"  :label="`${translate('BACKOFFICE.RULES_ENGINE.RULES.VALUE')} :`" class="rules__section__content__header__subtitle" custom-class="rules__text-input"/>
          </span>
        </div>

        <div class="modal-edit-parameter__actions">
          <Button :label="translate('BUTTONS.CANCEL')" class="modal-edit-parameter__actions__cancel"
                  @click="cancelAction"/>
          <Button :label="translate('BUTTONS.VALIDATE')"
                  class="modal-edit-parameter__actions_validate"
                  @click="validateParameterEdit(parameterValue)"/>
        </div>
    </ResultCard>
  </CustomModal>
</template>
