<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import Button from "@/components/button/Button.vue";
import {translate} from "@/i18n";

const props = withDefaults(defineProps<{
  users: any[],
  shouldReset: boolean,
  user?: any
}>(), {
  shouldReset: false
});

const emit = defineEmits(['search', 'reset', 'selectAll', 'selectCompany', 'selectMyTeam']);

const checkedUser = ref([] as number[]);
const selectedAll = ref(false);
const selectedCompany = ref(false);
const selectedMyTeam = ref(false);

const search = (e: SubmitEvent) => {
  e.preventDefault();
  emit('search', checkedUser.value);
}

const reset = () => {
  emit('reset');
}

const selectAll = () => {
  selectedAll.value = true;
  props.users?.map((user: any) => checkedUser.value.push(user.id));
  emit('selectAll', checkedUser.value);
  setTimeout(() => {
    selectedAll.value = false;
  }, 3000);
}

const selectCompany = () => {
  selectedCompany.value = true;
  checkedUser.value = [];
  emit('selectCompany');
  setTimeout(() => {
    selectedCompany.value = false;
  }, 3000);
}

const selectMyTeam = () => {
  selectedMyTeam.value = true;
  checkedUser.value = [];
  emit('selectMyTeam');
  setTimeout(() => {
    selectedMyTeam.value = false;
  }, 3000);
}

watch(() => props.shouldReset, (newValue: boolean) => {
  if (newValue) checkedUser.value = [];
})
</script>
<template>
  <div class="search">
    <div class="search-input">
      <span class="search__subtitle">{{ `${translate('RESEARCH')} :` }}</span>
      <Button :label="translate('BUTTONS.SELECT_ALL')" @click="selectAll" :disabled="selectedAll"/>
      <Button v-if="user?.roles?.includes('COMPANY_ADMIN')" :label="translate('BACKOFFICE.COMPANY')"
              @click="selectCompany" :disabled="selectedCompany"/>
      <Button :label="translate('BUTTONS.MY_TEAM')" @click="selectMyTeam" :disabled="selectedMyTeam"/>
    </div>
    <form @submit.prevent="search" @reset="reset">
      <div class="search__filter">
        <fieldset class="search__filter__choice">
          <div v-for="user in users" class="search__filter__choice__check">
            <input type="checkbox" :id="user.id" :name="user.id" :value="user.id" v-model="checkedUser"/>
            <label :for="user.id">{{ user.username }}</label>
          </div>
        </fieldset>
      </div>
      <div class="search__action">
        <Button :label="translate('BUTTONS.RESET')" type="reset"/>
        <Button :label="translate('BUTTONS.SEARCH')" type="submit"/>
      </div>
    </form>
  </div>
</template>
