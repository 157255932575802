<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import CheckIbanBank from "@/components/check-iban/CheckIbanBank.vue";
import Loader from "@/components/loader/Loader.vue";
import { translate } from "@/i18n";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    checkIban: Object;
    loader: boolean;
    birthdate: string;
    messageError: boolean;
    canCheckIban: boolean;
  }>(),
  {
    loader: false,
    birthdate: "",
    messageError: false,
    canCheckIban: false,
  }
);

const bankDetails = computed(() => {
  if (props.checkIban?.bank && props.checkIban?.bank.hasOwnProperty("result")) {
    return Object.assign(
      {},
      props.checkIban?.bank,
      props.checkIban?.bank?.result
    );
  } else return props.checkIban?.bank;
});

const displayNameMatching = (detail: {
  check: string;
  message: string;
  status: string;
}) => {
  if (detail.check === "NAME_MATCH") {
    const percentage = detail.message.split(" ")?.reverse()[0];
    return `${translate(
      `CHECK_IBAN.RESULT.${detail.check}`
    )} (match ${percentage})`;
  } else return translate(`CHECK_IBAN.RESULT.${detail.check}`);
};
</script>

<template>
  <ContentCard
    v-if="!canCheckIban"
    :checked-display-content="false"
    :is-padlock="true"
    class="applicant-b2c"
    :title="`${translate('CHECK_IBAN.RESULT.TITLE')}`"
  />
  <template v-else>
    <ContentCard
      :title="`${translate('CHECK_IBAN.RESULT.TITLE')}`"
      class="check-iban-result"
    >
      <CheckIbanBank
        :bank-name="bankDetails?.name"
        :iban="bankDetails?.iban"
        :bic="bankDetails?.bic"
      />
      <Loader v-if="loader" />
      <div v-if="!loader">
        <template v-if="!messageError">
          <span class="check-iban-result-subtitle"
            >{{ translate("CHECK_IBAN.RESULT.VERIFICATION") }} :</span
          >
          <div v-for="(detail, index) in props.checkIban?.checks" :key="index">
            <span>
              <img
                :src="
                  require(`/public/images/${
                    detail.status === 'OK'
                      ? 'success-icon.png'
                      : detail.status === 'WARNING'
                      ? 'warning-icon.png'
                      : 'failed-icon.png'
                  }`)
                "
                :alt="`${detail.status.toLowerCase()}-icon`"
                class="check-iban-result__icon"
              />
              {{ displayNameMatching(detail) }}
            </span>
          </div>
        </template>
        <template v-else>
          <span>{{ translate("CHECK_IBAN.ERROR_MESSAGE") }}</span>
        </template>
      </div>
    </ContentCard>
  </template>
</template>
