import {phone} from "phone";

export function usePhone() {
  const phoneCountryCodes = {
    ES: "+34",
    BE: "+32",
  };

  const checkPhoneValue = (phoneNumber: string, phoneCountry: string): boolean => {
    const _phone = phone(phoneNumber);
    return _phone.isValid;
  };

  const computePhoneCountry = (country: string) => {
    // @ts-ignore
    const countryCode = phoneCountryCodes[country] as string | undefined;
    if (countryCode) {
      return countryCode;
    }
    return "+33";
  };

  const getPhoneValue = (phoneNumber: string): string => {
    const _phone = phone(phoneNumber);
    return _phone.isValid ? _phone.phoneNumber : '+';
  };

  return {checkPhoneValue, computePhoneCountry, getPhoneValue};
}
