<script setup lang="ts">
import {ref} from 'vue';
import TwoFactorRadio from '@/components/radio/TwoFactorRadio.vue';
import PhoneInput from '@/components/input/PhoneInput.vue';
import login from '@/apiCalls/login';
import {AxiosError} from 'axios';
import {translate} from '@/i18n';
import ContentCard from "@/components/card/ContentCard.vue";
import TwoFAModal from "@/components/modal/TwoFAModal.vue";
import {useRoute, useRouter} from "vue-router";

enum statusType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  DISABLED = 'DISABLED'
}

const props = withDefaults(
    defineProps<{
      withDisabled: boolean,
      isRequiredByCompany: boolean,
      email: string,
      isChecked: boolean,
      authenticationStatus: string,
    }>(), {
      withDisabled: false,
      isRequiredByCompany: true,
      email: '',
      isChecked: false,
      authenticationStatus: '',
    });

const emit = defineEmits(['success']);
const route = useRoute();
const router = useRouter();

const inProgress = ref(false);
const status = ref<statusType>(statusType.EMAIL);
const phoneValue = ref('+33');
const modalFlag = ref(false);
const modalDisableFlag = ref(false);

const token = () => {
  return route.query.token as string;
};

const submit = async (e: SubmitEvent) => {
  e.preventDefault();
  inProgress.value = true;
  try {
    const {headers} = status.value === statusType.SMS
        ? await login.setup2FA(status.value, token(), phoneValue.value)
        : await login.setup2FA(status.value, token());
    if (!!token())
      router.push({
        name: 'Login2FAuthenticationSetupValidationPage',
        replace: true,
        query: {
          token: headers['x-2fa-token'],
          status: status.value,
          contact: headers['x-2fa-contact']
        }
      });
    else {
      emit('success', {
        token: headers['x-2fa-token'],
        status: status.value,
        contact: headers['x-2fa-contact']
      });
    }
  } catch (e: any) {
    if (!(e instanceof AxiosError)) {
      console.error('Unhandled error on login', e);
      throw e;
    }
    console.error('Invalid 2FA setup');
    throw e;
  } finally {
    inProgress.value = false;
  }
};
</script>

<template>
  <ContentCard class="two-factor-authentication-setup-form"
               :title="translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.TITLE')">
    <div class="two-factor-authentication-setup-form__text">
      {{ translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.COMPANY_2FA_REQUIRED') }}
    </div>
    <div class="two-factor-authentication-setup-form__content">
      <TwoFactorRadio :title="translate('IDENTITY.EMAIL')"
                      :checked="status === 'EMAIL'"
                      v-on:select="status = statusType.EMAIL"
                      :text="`${translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.EMAIL_DESCRIPTION')} ${email}`"
                      :label-button="translate('BUTTONS.CHOOSE')"
                      v-model:modalFlag="modalFlag"
      >
      </TwoFactorRadio>
      <TwoFactorRadio :title="translate('IDENTITY.PHONE_NUMBER')" :checked="status === 'SMS'"
                      v-on:select="status = statusType.SMS"
                      :text="translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.SMS_DESCRIPTION')"
                      :label-button="translate('BUTTONS.CHOOSE')"
                      v-model:modalFlag="modalFlag"
      >
        <PhoneInput v-model:phoneValue="phoneValue" :required="status === statusType.SMS"
                    class="phone-two-authenticate"/>
      </TwoFactorRadio>
      <TwoFactorRadio v-if="withDisabled && authenticationStatus !== 'DISABLED'"
                      :title="translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.DISABLE')"
                      :checked="status === 'DISABLED'"
                      v-on:select="status = statusType.DISABLED"
                      :text="translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.DISABLE_DESCRIPTION')"
                      :label-button="translate('BUTTONS.DEACTIVATE')"
                      v-model:modalFlag="modalDisableFlag"
      >
      </TwoFactorRadio>
    </div>
    <TwoFAModal v-model:open-modal="modalFlag" :is-checked="isChecked"
                @confirm-action="submit($event)" v-model:open-disable-modal="modalDisableFlag">
      <span>{{
          translate(`LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.${isChecked ? 'ALERT_MESSAGE_CHECKED' : 'ALERT_MESSAGE_ACTIVE'}`)
        }}</span>
    </TwoFAModal>
    <TwoFAModal v-model:open-modal="modalDisableFlag" :is-checked="isChecked"
                @confirm-action="submit($event)">
      <span>{{ translate('LOGIN_PAGE.TWO_FACTOR_AUTHENTICATION.ALERT_MESSAGE_DISABLE') }}</span>
    </TwoFAModal>
  </ContentCard>
</template>