import {translate} from "@/i18n";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import type = _default.defaults.animations.numbers.type;
import {types} from "sass";
import String = types.String;
import {isNil} from "lodash";

export interface ObData {
  // for open banking data
  startStudyPeriod: string;
  endStudyPeriod: string;
  topProAccount: boolean;
  bankName: string;
  holderName: string;
  accountName: string;
  iban: string;
  topSecondaryAccount: string;
  monthSalaries: number;
  monthRents: number;
  monthEndRatio: number;
  balance: number;
  score: number;
  topBankingSupervision: string;
  topDeferredDebit: string;
  monthEndBalance: number;
  globalTransactionsCount: number;

  // for open banking detail
  monthIncomesSalariesAmountMean: number;
  monthIncomesAllowancesAmountMean: number;
  monthIncomesPensionsAmountMean: number;
  monthIncomesAmountMax: number;
  monthIncomesAmountMin: number;
  monthIncomesAmountMean: number;
  monthIncomesAmountSum: number;
  averageMonthlyEntries: number;
  monthOutcomesCreditsMean: number;
  monthOutcomesCreditsDateMean: number;
  monthOutcomesPensionsDateMean: number;
  monthOutcomesTaxesAmountMean: number;
  monthOutcomesAmountMean: number;
  monthOutcomesAmountSum: number;
  monthOutcomesRentsMean: number;
  balancePerDay: any;
  balancePerMonth: any;
  recommendedDebitDate: string;
  recommendedDebitAmount: number;
  monthNegativeBalanceMin: number;
  maxBalance: number;
  monthNegativeBalanceMax: number;
  remainsToLive: number;
  monthNegativeBalanceMean: number;
  debtRatio: number;
  monthOutcomesCreditsCountMean: number;
  netIncome: number;
  turnover: number;
  // monthSavings: number;
  monthOutcomesCreditsMin: number;
  monthOutcomesCreditsMax: number;
  monthOutcomesCreditsCountMin: number;
  monthOutcomesCreditsCountMax: number;
  monthOutcomesRentsMin: number;
  monthOutcomesRentsMax: number;
  monthOutcomesUtilitiesMin: number;
  monthOutcomesUtilitiesMax: number;
  bankCardPresence: string;
  bankCardQuantity: number;
  bankCardNumbers: string[] | '-';

  // for credit card & debits
  bankDirectDebitPresence: string;

  // for other accounts
  monthIncomesSavingsAmountMean: number;
  monthOutcomesSavingsAmountMean: number;
  monthIncomesTransfersAmountMean: number;
  monthOutcomesTransfersAmountMean: number;

  // for outcomes
  monthOutcomesSalariesAmountMean: number | '-';

  multiRequests: {
    iban: number
  };
}

export interface SearchData {
  date?: string;
  category?: string;
  balance?: string;
  label?: string;
}

export enum Routes {
  OPEN_BANKING = '/open-banking',
  ANALYSIS_B2B = '/analysis-b2b',
  ANALYSIS_B2C = '/analysis-b2c',
  SYNTHESIS = '/synthesis',
  RECOVERY = '/recovery',
  BACK_OFFICE = '/back-office',
  DOCUMENT_ANALYSIS = '/document-analysis',
}

export function amountFormatter(value: any) {
  if (isNil(value)) return '';
  return ("" + value)?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatBalance(data: Array<any>, type: string) {
  let formatted: any[] = [];
  if (type === "date") {
    data.forEach((element: any) => {
      formatted.push(new Date(element[type]).toLocaleDateString());
    });
  } else {
    data.forEach((element: any) => {
      formatted.push(element[type]);
    });
  }
  return formatted.reverse();
}

export const formatIcon: (str: string) => string = (str) => {
  // to match the icon file name ex: income-utility.png
  return str.toLowerCase().replaceAll(':', '-');
};

export const formatCategory = (str: string) => {
  const translateFormat = "OPEN_BANKING.ACCOUNT_STATEMENT.CATEGORY.";
  return translate(`${translateFormat}${str?.replaceAll(":", ".")}`);
};

export const formatDate = (str: string) => {
  return new Date(str).toLocaleDateString();
}

export const formatLabel = (str: string) => {
  // formatting label ex : "SALAIRE" => "Salaire"
  if (isNil(str)) return '';
  return str.toLowerCase().split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
}

export const formatNumber = (num: any) => {
  // formatting the amount ex: "200" => "200.00"
  return num.toFixed(2);
}

export const isBooked = (str: string) => {
  return str === 'BOOKED';
}

export const transColorCode = (str: string) => {
  // an outcome
  if (str.includes('OUTCOME')) {
    // for savings transactions
    if (str.includes('SAVING')) {
      return 'orangeFont';
    }
    return 'redFont';
  } else {
    return 'greenFont';
  }
}