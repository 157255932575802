<script lang="ts" setup>
import { computed, onUnmounted, ref } from "vue";

import { translate } from "@/i18n";
import { getRegex, TextInputType } from "@/types/TextInputType";

import Button from "@/components/button/Button.vue";
import PhoneInput from "@/components/input/PhoneInput.vue";
import Loader from "@/components/loader/Loader.vue";

const emit = defineEmits(["startFaceMatching", "closeModal"]);

const phoneNumber = ref("+33");
const disableButton = ref(false);
const isLoading = ref(false);

const phoneValid = computed(() => {
  return getRegex(TextInputType.PHONE_NUMBER).test(phoneNumber.value);
});

const startFaceMatching = () => {
  if (phoneValid.value) {
    disableButton.value = true;
    emit("startFaceMatching", phoneNumber.value);
    isLoading.value = true;
  }
};

onUnmounted(() => {
  phoneNumber.value = "";
});
</script>

<template>
  <h5>
    {{ translate("DOCUMENT_CONTROL.TABLE.FACE_MATCHING_TITLE") }}
  </h5>

  <div class="face-matching-desc">
    <span>{{ translate("DOCUMENT_CONTROL.FACE_MATCHING_DESC_1") }}</span>
    <span>{{ translate("DOCUMENT_CONTROL.FACE_MATCHING_DESC_2") }}</span>
  </div>

  <Loader v-if="isLoading" />

  <div class="face-matching-input">
    <PhoneInput :disablePhoneValue="disableButton" v-model:phoneValue="phoneNumber" />
  </div>

  <div class="face-matching-actions">
    <Button
      type="submit"
      :label="translate('BUTTONS.CLOSE')"
      @click="emit('closeModal')"
    />

    <Button
      type="submit"
      :label="translate('BUTTONS.CONFIRM')"
      class="confirm-btn"
      :disabled="!phoneValid || disableButton"
      @click="startFaceMatching"
    />
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/component/modal/faceMatchingModal.scss";
</style>
