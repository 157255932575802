<template>
  <CustomModal v-model:open="modalFlagOpen" custom-class="modal-add-user" @close-modal="cancelAction">
    <ResultCard :title="title">
      <template v-if="step">
        <TextInput :label="`${translate('IDENTITY.NAME')}*`" v-model="content.newName" :type="UPPER_CASE"/>
        <TextInput :label="`${translate('BATCH.DESCRIPTION')}*`" v-model="content.description"/>
        <div class="modal-add-user__advanced-user">
          <span>{{ translate('BACKOFFICE.USERS.ACTIVE') }}</span>
          <div class="modal-add-user__advanced-user__checked">
            <input type="checkbox" :checked="checkBox" @input="stateCheckBox">
          </div>
        </div>
        <div class="modal-add-user__actions">
          <Button :label="translate('BUTTONS.CANCEL')" class="modal-add-user__actions__cancel"
                  @click="cancelAction"/>
          <Button :label="translate('BUTTONS.CONFIRM_AND_CONTINUE')"
                  class="modal-add-user__actions__validate" @click="nextStep" :disabled="goToNextStep()"/>
        </div>
      </template>
      <template v-else>
        <div class="modal-add-user__summary">
          <span class="modal-add-user__summary__title">{{ `${translate('SUMMARY')} :` }}</span>
          <div class="modal-add-user__summary__informations">
            <span>{{ `${translate('IDENTITY.NAME')} : ${content.newName}` }}</span>
            <span>{{ `${translate('BATCH.DESCRIPTION')} : ${content.description}` }}</span>
            <span>{{
                `${translate('BACKOFFICE.USERS.ACTIVE')} : ${checkBox ? translate('YES') : translate('NO')}`
              }}</span>
          </div>
        </div>
        <div class="modal-add-user__actions">
          <Button :label="translate('BUTTONS.CANCEL')" class="modal-add-user__actions__cancel"
                  @click="cancelAction"/>
          <Button :label="translate('BUTTONS.CONFIRM_AND_CONTINUE')"
                  class="modal-add-user__actions__validate" @click="validateAction"/>
        </div>
      </template>
    </ResultCard>
  </CustomModal>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import TextInput from "@/components/input/TextInput.vue";
import Select from "@/components/select/Select.vue";
import Button from "@/components/button/Button.vue";
import {translate} from "@/i18n";
import {getRegex, TextInputType} from "@/types/TextInputType";
import {isEmpty} from "lodash";

export default defineComponent({
  methods: {
    translate,
    stateCheckBox(e: { target: { checked: boolean } }) {
      this.$emit('update:checkBox', e.target?.checked);
    },
    cancelAction() {
      this.$emit('cancel-action');
    },
    nextStep() {
      this.$emit('next-step', this.content, this.checkBox);
    },
    validateAction() {
      this.$emit('validate-action')
    },
    goToNextStep() {
      return !(Object.keys(this.content).includes('newName') && Object.keys(this.content).includes('description') && getRegex(this.UPPER_CASE).test(this.content.newName));
    }
  },
  components: {Button, Select, TextInput, ResultCard, CustomModal},
  props: {
    modalFlag: {type: Boolean, default: false},
    title: {type: String, default: ''},
    step: {type: Boolean, default: true},
    checkBox: {type: Boolean, default: false},
    content: {type: Object, required: true}
  },
  emits: ['update:checkBox', 'cancel-action', 'next-step', 'validate-action'],
  computed: {
    contentName() {
      return this.content ? this.content.newName : '-';
    },
    contentDescription() {
      return this.content ? this.content.description : '-';
    }
  },
  watch: {
    modalFlag() {
      this.modalFlagOpen = this.modalFlag;
    }
  },
  data: () => ({
    UPPER_CASE: TextInputType.UPPER_CASE,
    modalFlagOpen: false
  })
})
</script>

<style scoped lang="scss">

</style>