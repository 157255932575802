export default {
  "ACCOUNT_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])},
    "GENERAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Informationen"])},
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
    "PHONE_NUMBER_NOT_SPECIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht angegeben"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung einrichten"])},
      "REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erforderlich vom Unternehmen"])},
      "CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüft"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Informationen bearbeiten"])},
      "MODE": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungsmodus"])},
        "SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
        "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviert"])}
      }
    },
    "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Informationen"])}
  },
  "LOGIN_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail"])},
    "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldedaten stimmen mit keinem Konto überein"])},
    "ATTEMPTS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aufeinander folgende fehlerhafte Versuche werden Ihr Konto sperren."])},
    "LOCKED_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Konto wurde gesperrt."])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe mein Passwort vergessen"])},
    "TWO_FACTOR_AUTHENTICATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung"])},
      "ALERT_MESSAGE_CHECKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies wird Ihr altes Zwei-Faktor-Authentifizierungsmittel überschreiben. Sind Sie sicher, dass Sie fortfahren möchten?"])},
      "ALERT_MESSAGE_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies wird die Zwei-Faktor-Authentifizierung aktivieren."])},
      "ALERT_MESSAGE_DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dadurch wird die Zwei-Faktor-Authentifizierung deaktiviert."])},
      "DISABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierung deaktivieren"])},
      "DISABLE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Zwei-Faktor-Authentifizierung deaktivieren. Wir empfehlen jedoch, diese Funktion aktiviert zu lassen, um die Sicherheit Ihres Kontos zu erhöhen."])},
      "COMPANY_2FA_REQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte teilen Sie uns mit, welche Authentifizierungsmethode Sie verwenden möchten."])},
      "EMAIL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Überprüfungscode wird an Ihre E-Mail-Adresse gesendet."])},
      "SMS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Überprüfungscode wird per SMS an Sie gesendet. Bitte geben Sie Ihre Telefonnummer ein:"])},
      "TITLE_MODAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwei-Faktor-Authentifizierungsmodus"])},
      "MESSAGE_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben eine Nachricht erhalten"])},
      "FINALISATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie zur Abschluss der Zwei-Faktor-Authentifizierung den an Sie gesendeten Code ein."])}
    }
  },
  "RESET_PASSWORD_PAGE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "INIT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort initialisieren"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine E-Mail"])},
    "FORGOT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
    "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
    "CONFIRM_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort bestätigen"])},
    "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail zum Zurücksetzen senden"])},
    "SUCCESS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine E-Mail zum Zurücksetzen des Passworts wurde an dich gesendet."])},
    "EMAIL_NOT_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail konnte nicht gesendet werden."])},
    "INVALID_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten."])},
    "PASSWORD_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein."])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort wurde erfolgreich zurückgesetzt."])},
    "PASSWORD_RESET_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Zurücksetzung Ihres Passworts ist ein Fehler aufgetreten."])}
  },
  "CGS": {
    "BEGINNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Fortsetzung akzeptieren Sie die"])},
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
    "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von Meelo"])}
  },
  "IDENTITY": {
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "FIRSTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "PLACE_OF_BIRTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer"])},
    "LASTNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "USAGE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebrauchsname"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
  },
  "ADDRESS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "TITLE_POSTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postanschrift"])},
    "NUMBER_AND_STREET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hausnummer und Straße"])},
    "ZIP_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
  },
  "COMPANY": {
    "NO_REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine bekannten Führungskräfte und Vertreter"])},
    "NO_BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine bekannten Aktionäre und Eigentümer"])},
    "LIQUIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auflösung"])},
    "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschung"])},
    "SIREN_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
    "ORIGIN": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankreich"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgien"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch"])}
    },
    "REGISTRATION_NUMBER": {
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmens-Identifikationsnummer oder SIREN-Nummer"])},
      "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationale Registrierungsnummer oder Umsatzsteuer-Identifikationsnummer"])},
      "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steueridentifikationscode"])}
    },
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
    "SIRET_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET-Nummer"])},
    "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
    "MAIN_ACTIVITY_DECLARED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptdeklarierte Aktivität"])},
    "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAF- oder APE-Code"])},
    "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensinformationen"])},
    "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gründungsdatum"])},
    "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
    "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsform"])},
    "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammkapital"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensidentität"])},
    "BUSINESS_OWNERS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der tatsächlichen Eigentümer"])},
    "BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekannte tatsächliche Eigentümer"])},
    "DIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direkter Eigentümer"])},
    "INDIRECT_BUSINESS_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirekter Eigentümer über juristische Person"])},
    "IN_POSITION_SINCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Position seit"])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv gesetzt"])},
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Verfahren"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollektives Verfahren"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation"])},
    "GLOBAL_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globale Bewertung"])},
    "ADVICE_NOTE_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Bewertungen"])},
    "WORDING_CLOUD_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Häufigkeit der Wörter in den Bewertungen"])},
    "ADJECTIVES_E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Positive Bewertungen / Negative Bewertungen in den Bewertungen"])},
    "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Niederlassungen"])},
    "ESTABLISHMENTS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Niederlassungen"])},
    "REPRESENTATIVES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Vertreter"])},
    "REPRESENTATIVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertreter"])},
    "REPRESENTATIVES_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekannte Geschäftsführer und Vertreter"])},
    "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekannte Aktionäre und Eigentümer"])},
    "RECOMMENDED_OUTSTANDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlener Ausstand"])},
    "HEADQUARTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptsitz"])},
    "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "TOTAL_SHARE_HOLDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamte Beteiligung"])},
    "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herkunftsland des Unternehmens"])},
    "TYPE_PERSON": {
      "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natürliche Person"])},
      "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Person"])}
    },
    "FUNCTIONS": {
      "PRESIDENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präsident"])},
      "CHAIRMAN_OF_THE_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsitzender des Aufsichtsrats"])},
      "CHAIRMAN_AND_CHIEF_EXECUTIVE_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präsident und Geschäftsführer"])},
      "MANAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer"])},
      "NON_ASSOCIATE_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht assoziierter Geschäftsführer"])},
      "CO_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-Geschäftsführer"])},
      "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer"])},
      "GENERAL_MANAGER_NOT_A_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generaldirektor ohne Aufsichtsratsfunktion"])},
      "SOLE_MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alleiniger Geschäftsführer"])},
      "CHIEF_OPERATING_OFFICER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Operating Officer"])},
      "VICE_CHAIRMAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vizepräsident"])},
      "DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direktor"])},
      "DEPUTY_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellvertretender Direktor"])},
      "CHAIRMAN_OF_THE_EXECUTIVE_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsitzender des Vorstands"])},
      "CHAIRMAN_OF_THE_SUPERVISORY_BOARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsitzender des Aufsichtsrats"])},
      "MANAGING_PARTNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführender Gesellschafter"])},
      "CONTROLLER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controller"])},
      "STATUTORY_AUDITOR_TITULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetzlicher Prüfer - Inhaber"])},
      "STATUTORY_AUDITOR_DEPUTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetzlicher Prüfer - Stellvertreter"])},
      "STATUTORY_AUDITOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetzlicher Prüfer"])},
      "LIQUIDATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidator"])},
      "PROVISIONAL_ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorläufiger Administrator"])},
      "REPRESENTATIVE_IN_FRANCE_OF_A_FOREIGN_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertreter in Frankreich eines ausländischen Unternehmens"])},
      "MEMBER_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied des Vorstands"])},
      "CHAIRMAN_OF_THE_BOARD_OF_DIRECTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorsitzender des Vorstands"])}
    },
    "TYPE": {
      "SOCIETY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft"])},
      "COLLECTIVE_INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollektive Investition"])},
      "GIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftsinteressengemeinschaft"])}
    },
    "OF_SHARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktien"])},
    "OF_VOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmen"])},
    "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Informationen"])},
    "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
    "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer-Identifikationsnummer"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlussdatum"])},
    "ACTIVITY_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätsstatus"])},
    "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
    "UNVERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht ansässig"])},
    "RECOMMENDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlung"])},
    "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeitsbereich"])},
    "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederlassungen"])},
    "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptbüro"])},
    "IN_OPERATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Betrieb"])},
    "CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen"])},
    "FINANCES": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensfinanzen"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Finanzdaten verfügbar"])},
      "RATIOS": {
        "grossMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttomarge (€)"])},
        "netMargin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettomarge (€)"])},
        "valueAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertschöpfung (€)"])},
        "ebitda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBITDA (€)"])},
        "ebit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsergebnis (€)"])},
        "profiteBeforeTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnis vor Steuern (€)"])},
        "extraordinaryResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außerordentliches Ergebnis (€)"])},
        "netIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoergebnis (€)"])},
        "shortTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurzfristige Verbindlichkeiten (€)"])},
        "longMidTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lang- und mittelfristige Verbindlichkeiten (€)"])},
        "turnover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatz (€)"])},
        "balanceSheetTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtbilanzsumme (€)"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenkapital (€)"])},
        "workingCapital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitskapital (€)"])},
        "workingCapitalRequirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapitalbedarf für das Betriebskapital (€)"])},
        "financialLiquidity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielle Liquidität (€)"])},
        "intangibleAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immaterielle Vermögenswerte (€)"])},
        "tangibleFixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachanlagen (€)"])},
        "participatingInterestsAndRelatedDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beteiligungen und damit zusammenhängende Forderungen (€)"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagerbestand (€)"])},
        "othersDebtsReceivable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige Forderungen (€)"])},
        "tradeDebtorsAndRelatedAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsforderungen und damit zusammenhängende Konten (€)"])},
        "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzen (€)"])},
        "chargesCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kosten des Umsatzes (€)"])},
        "appropriationsToDepreciationAndProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückstellungen und Abschreibungen (€)"])},
        "totalExtraordinaryIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außerordentliche Erträge (€)"])},
        "totalExtraordinaryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außerordentliche Aufwendungen (€)"])},
        "corporateTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Körperschaftsteuer (€)"])},
        "operatingLiabilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsverbindlichkeiten (€)"])},
        "capital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapital (€)"])},
        "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rücklagen (€)"])},
        "profitOrLossForTheFinancialYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinn oder Verlust des Geschäftsjahres (€)"])},
        "investmentGrantsTaxRegulatedProvisions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investitionszulagen und steuerlich geregelte Rückstellungen (€)"])},
        "loansAndDebtsPayableToCreditInstitutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darlehen und Schulden gegenüber Kreditinstituten (€)"])},
        "loansAndSundryFinancialDebtsPayable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darlehen und sonstige finanzielle Schulden (€)"])},
        "changeInStocksIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkommensveränderung (€)"])},
        "purchasesOfGoodsForResale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käufe von Waren zur Wiederveräußerung (€)"])},
        "changeInStocksCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsveränderungskosten (€)"])},
        "otherExternalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere externe Dienstleistungen (€)"])},
        "taxesLeviesAndSimilarPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuern, Abgaben und ähnliche Zahlungen (€)"])},
        "personnelCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalkosten (€)"])},
        "otherCurrentOperatingCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere aktuelle Betriebskosten (€)"])},
        "financialIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzerträge (€)"])},
        "financialCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzaufwendungen (€)"])},
        "fpTb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenkapitalquote (Eigenkapital in % der Bilanzsumme)"])},
        "supplierDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferantenfristen (Tage)"])},
        "customerDeadlines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenfristen (Tage)"])},
        "caf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashflow aus betrieblicher Tätigkeit (€)"])},
        "rnCa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettogewinnmarge (Nettoergebnis in % des Umsatzes)"])},
        "etp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollzeitarbeitsäquivalente (ETP)"])},
        "dividends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dividenden an Aktionäre (€)"])},
        "soldeFinancier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzieller Saldo (€)"])},
        "salesGrowthRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzwachstumsrate (in %)"])},
        "grossMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttomargenrate (in %)"])},
        "ebitdaMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EBITDA-Margenrate (in %)"])},
        "operatingMarginRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsmargenrate (in %)"])}
      },
      "BLOCKS": {
        "intermediateOperationTotals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensalden der Geschäftstätigkeit"])},
        "debts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindlichkeiten"])},
        "balanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanz"])},
        "fixedAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sachanlagen"])},
        "currentAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umlaufvermögen"])},
        "profitAndLossAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinn- und Verlustrechnung"])},
        "balancePassive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passiva der Bilanz"])},
        "capitalAndReserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigenkapital"])},
        "operatingIncome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzerlöse"])},
        "operatingCosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebskosten"])},
        "operatingResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebsergebnis"])},
        "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzkennzahlen"])},
        "increase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachstum"])}
      }
    },
    "LEGAL_DOCUMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Dokumente"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine rechtlichen Dokumente verfügbar"])},
      "NO_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um das Dokument anzuzeigen, laden Sie es bitte herunter."])}
    },
    "BODACC_ANNOUNCEMENT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensentwicklung"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natur"])},
      "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
      "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])}
    },
    "ANNUAL_ACCOUNTS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresabschlüsse"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Jahresabschlüsse verfügbar"])},
      "COMPANY_FINANCIAL_STATEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaftsfinanzberichte"])}
    },
    "CHECKS": {
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als ein Bericht mit demselben Einreichungsdatum"])},
      "LESS_THAN_X_YEARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen weniger als 3 Jahre alt"])},
      "MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechsel der Geschäftsführung unter 3 Jahren"])},
      "SHARE_CAPITAL_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung des Kapitals in den letzten 3 Jahren"])},
      "HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umzug des Hauptsitzes in den letzten 3 Jahren"])},
      "ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung der Aktivität in den letzten 3 Jahren"])},
      "MULTI_MANAGEMENT_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als 2 Geschäftsführungswechsel in den letzten 10 Jahren"])},
      "HAS_CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsaufgabe"])},
      "HAS_ALL_ESTABLISHMENTS_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Betriebe sind geschlossen"])},
      "HAS_ACTIVITY_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als zwei Aktivitätswechsel in den letzten 10 Jahren"])},
      "HAS_HEADQUARTER_CHANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens zwei Umzüge des Hauptsitzes in den letzten 10 Jahren"])},
      "LOCATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikostandort"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domizilunternehmen"])},
      "WEBSITE_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Registrierung der Website-Domäne"])},
      "EMAIL_DOMAIN_REGISTRATION_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Registrierung der E-Mail-Domäne"])},
      "EMAIL_DOMAIN_EQUALS_WEBSITE_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung zwischen E-Mail-Domäne und Website-Domäne"])},
      "RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strahlung"])},
      "COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollektivverfahren"])},
      "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Unternehmen in kollektiven Verfahren"])},
      "OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Unternehmen bestrahlt"])},
      "ACTIVITIES_CODE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikoreicher Tätigkeitsbereich"])},
      "HEADQUARTER_DISTANCE_TOO_HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erheblicher Abstand zwischen der Adresse des Firmensitzes und dem Wohnort des Geschäftsführers"])},
      "LEGAL_SITUATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Rechtsstreitigkeiten"])},
      "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktive Zeit von mehr als 3 Jahren"])},
      "MULTI_HEADQUARTER_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als 2 Umzüge des Hauptsitzes in den letzten 10 Jahren"])},
      "MULTI_ACTIVITY_CHANGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als 2 Aktivitätsänderungen in den letzten 10 Jahren"])},
      "CESSATION_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beendigung der Tätigkeit"])},
      "ALL_ESTABLISHMENTS_ARE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Einrichtungen sind geschlossen"])},
      "CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenkontaktierbarkeit"])},
      "HEADQUARTER_ADDRESS_IN_WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrug an dieser Adresse entdeckt"])},
      "MULTI_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als eine Registrierung seit der Gründung"])},
      "MORE_THAN_ONE_ESTABLISHMENT_DOMICILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als eine Niederlassung ist ansässig"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SUBMISSION_DATE_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als ein Steuerbericht mit einem geschlossenen Einreichungsdatum"])},
      "MORE_THAN_ONE_TAX_REPORT_WITH_SAME_SUBMISSION_DATE_AND_YEAR_DIFFERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als ein Steuerbericht mit demselben Einreichungsdatum und einem anderen Jahr"])},
      "MORE_THAN_TEN_TAX_REPORTS_WITH_DIFFERENT_SUBMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr als zehn Steuerberichte mit unterschiedlichen Einreichungsdaten"])},
      "RECENT_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle kollektive Verfahren"])}
    },
    "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domizil"])},
    "BRANCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederlassung"])},
    "CHECK_EMPLOYER": {
      "NO_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Angaben konnten nicht überprüft werden"])},
      "FALSE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Unternehmen ist nicht von der Börse genommen"])},
      "TRUE_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Unternehmen ist dekotiert"])},
      "COLLECTIVE_PROCEEDING": {
        "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine anhängigen Sammelverfahren festgestellt"])},
        "MORE_THAN_ONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein oder mehrere anhängige Sammelverfahren festgestellt"])},
        "RECENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein oder mehrere laufende Kollektivverfahren festgestellt, darunter mindestens ein aktuelles (< 3 Jahre)"])}
      }
    }
  },
  "ERRORS": {
    "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops! Es scheint, dass es ein Problem gibt. Unsere Teams arbeiten bereits an der Lösung. Bitte versuchen Sie es später erneut."])},
    "NOT_FOUND_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene Identifikationsnummer existiert nicht."])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Informationen abgerufen"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Tool zur Bearbeitung von Steuererklärungen akzeptiert keine Dokumente vor 2018."])},
    "TAX_REPORT_TOO_OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuererklärung zu alt"])},
    "DOCUMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während des Lesens des Dokuments ist ein Fehler aufgetreten."])},
    "COMPANY_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die eingegebene Identifikationsnummer ist keiner aktiven Firma zugeordnet."])},
    "ENRICHMENT_PROCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Informationen gefunden."])},
    "ERROR_OCCURRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten, bitte versuchen Sie es später erneut"])},
    "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten gefunden, bitte starten Sie eine neue Analyse"])},
    "CALL_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anruf beendet, beachten Sie bitte, dass einige Daten nicht zugänglich sind. Für einen vollständigen Bericht starten Sie bitte eine neue Analyse"])},
    "SIREN_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse abgeschlossen, keine Daten mit der angegebenen Identifikationsnummer gefunden"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten abgerufen"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten verfügbar"])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der eingegebenen Telefonnummer ist ein Fehler aufgetreten. Bitte überprüfen Sie die eingegebenen Informationen"])},
    "UNPROCESSABLE_CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut in einem neuen Ordner oder kontaktieren Sie den Support"])},
    "CANNOT_ACCESS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können auf diesen Ordner nicht zugreifen."])}
  },
  "BUTTONS": {
    "VALIDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "VERIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
    "CONNEXION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung"])},
    "VALIDATE_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen validieren"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "VERIFY_IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenüberprüfung"])},
    "START_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking starten"])},
    "JOURNEY_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervollständigen Sie die Open Banking-Reise im Browser"])},
    "FINISH_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse abschließen"])},
    "NEW_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Analyse starten"])},
    "EXPORT_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung herunterladen"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "DETAILS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Details"])},
    "VERIFY_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse überprüfen"])},
    "SEND_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS senden"])},
    "SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail senden"])},
    "BACK_HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML/CFT"])},
    "VALIDATE_AND_SEND_SMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validieren und SMS senden"])},
    "VALIDATE_AND_SEND_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validieren und E-Mail senden"])},
    "IFRAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iframe"])},
    "RUN_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse starten"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Analyse"])},
    "FRAUD_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzahl"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrugspunktzahlen"])},
    "SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
    "SELECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt"])},
    "CONSULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsultieren"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
    "DEACTIVATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivieren"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "ENGLISH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englische Version"])},
    "FRENCH_VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französische Version"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "UPLOAD_HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier hochladen"])},
    "LINK_TEXT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link per SMS"])},
    "NEW_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Anfrage"])},
    "RESTART_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anfrage erneut starten"])},
    "LINK_BROWSER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browserlink"])},
    "LINK_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link per E-Mail"])},
    "MY_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Firma"])},
    "MY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Benutzer"])},
    "CONSUMPTION_MONITORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauchsüberwachung"])},
    "EXTRACT_MEMORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speicher extrahieren"])},
    "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel-Engine"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])},
    "CREATE_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erstellen"])},
    "UNBLOCK_A_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Benutzer freischalten"])},
    "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
    "ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "CONFIRM_AND_CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen und fortfahren"])},
    "RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "MODIFY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändern"])},
    "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "ACTIVATE_DEACTIVATE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto aktivieren / deaktivieren"])},
    "RESETTING_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Schlüssel zurücksetzen"])},
    "DELETE_THE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer das Konto"])},
    "RETURN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückkehr"])},
    "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichern"])},
    "CONSULT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen Sie die Finovox-Analyse"])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar LinkedIn"])},
    "DEPLOY_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles ausrollen"])},
    "REDUCE_EVERYTHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles einklappen"])},
    "SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsscore"])},
    "SCORE_COMPANY_FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensbetrugsscore"])},
    "CLOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "SELECT_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles auswählen"])},
    "MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Team"])},
    "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "COMPLETE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload abschließen"])},
    "LIVE_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klärung von Zweifeln"])}
  },
  "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antragsteller"])},
  "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreichbar"])},
  "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpft"])},
  "MAIL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der E-Mail verknüpfte Konten"])},
  "PHONE_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Telefon verknüpfte Konten"])},
  "APPLICANT_RESULTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse des Antragstellers"])},
  "CONTACT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinformationen"])},
  "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeninformationen"])},
  "VERIFICATION_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenprüfung"])},
  "VERIFICATION_TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbescheidprüfung"])},
  "CONFIDENCE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsbetrug-Score"])},
  "SMB_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsidentitätsbetrug-Score"])},
  "CONFIDENCE_INDEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsbetrug-Score"])},
  "DETAILED_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailbewertung"])},
  "CONFIDENCE_EXPLICATIONS": {
    "UNDER_TWENTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereitgestellte Daten sind nicht mit der Person verknüpft"])},
    "BETWEEN_TWENTY_AND_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifelhafte Daten, Identitätsüberprüfung empfohlen"])},
    "ABOVE_FIFTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandene und mit der Person verknüpfte Daten"])}
  },
  "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenanalyse"])},
  "ANALYSIS_RESULT_RETRIEVAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrufen der laufenden Analyseergebnisse ..."])},
  "UPLOAD_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenupload abgeschlossen, Sie können dieses Fenster schließen."])},
  "FACE_MATCHING_LINK_CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Link wurde Ihnen per SMS gesendet, überprüfen Sie Ihr Telefon"])},
  "LINK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zum Unternehmen"])},
  "IDENTITY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätskonsistenz"])},
  "COMPANY_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsistenz mit dem Unternehmen"])},
  "OTHER_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Person"])},
  "SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation"])},
  "NOT_FOUND": {
    "NO_BUSINESS_OWNERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine bekannten wirtschaftlichen Eigentümer."])},
    "NO_REPRESENTATIVES_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine bekannten Vertreter."])},
    "NO_LEADERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Führungskräfte gefunden..."])}
  },
  "OPEN_BANKING": {
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten wurden erfolgreich übermittelt. Sie können diese Seite schließen, um das Ergebnis der Analyse anzuzeigen."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "RESULT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des OpenBanking"])},
    "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte Analyse des OpenBanking"])},
    "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
    "INCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einnahmen"])},
    "OUTCOMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgaben"])},
    "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaber"])},
    "ACCOUNT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoname"])},
    "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches monatliches Gehalt"])},
    "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche Miete"])},
    "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Guthaben"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte bitten Sie Ihren Kunden, sein Smartphone und seine Bankdaten bereitzuhalten."])},
    "CATEGORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "FINANCIAL_ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzanalysezeitraum"])},
    "ANALYSIS_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysierter Zeitraum"])},
    "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
    "CATEGORIES": {
      "MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximalbetrag der Transaktionen"])},
      "MONTH_MAX_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der maximalen Transaktion"])},
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher Gesamtbetrag pro Monat"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Gehälter"])},
      "MONTH_SALARIES_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches monatliches Datum - Gehälter"])},
      "MONTH_INTERESTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Zinsen"])},
      "MONTH_TRANSFERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Überweisungen"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Mieten"])},
      "MONTH_WITHDRAWALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Bargeldabhebungen"])},
      "MONTH_INSURANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Versicherungen"])},
      "MONTH_UTILITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Versorgungsleistungen"])},
      "MONTH_GROCERIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Lebensmitteleinkäufe"])},
      "MONTH_FOOD_CONSUMPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Nahrungsmittelverbrauch"])},
      "MONTH_SHOPPINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Einkäufe"])},
      "MONTH_HEALTH_WELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Gesundheit/Wohlbefinden"])},
      "MONTH_TRANSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Transporte"])},
      "MONTH_TELECOMMUNICATION_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Telekommunikationsdienste"])},
      "MONTH_INCOMES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlicher Durchschnitt der Einkommen"])},
      "MONTH_INCOMES_SALARIES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlicher Durchschnitt der Gehälter"])},
      "MONTH_INCOMES_ALLOWANCES_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlicher Durchschnitt der Zulagen"])},
      "MONTH_INCOMES_PENSIONS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlicher Durchschnitt der Renten"])},
      "MONTH_AUDIO_VISUAL_SUBSCRIPTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Betrag - Audiovisuelle Dienste"])},
      "MONTH_INCOMES_AMOUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchster monatlicher Durchschnitt"])},
      "MONTH_INCOMES_AMOUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrigster monatlicher Durchschnitt"])},
      "MONTH_OUTCOMES_RENTS_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche Mieten"])},
      "MONTH_OUTCOMES_CREDITS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche Kredite"])},
      "MONTH_OUTCOMES_CREDITS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches monatliches Datum der Kreditzahlungen"])},
      "MONTH_OUTCOMES_PENSIONS_DATE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches monatliches Datum einer Rentenzahlung"])},
      "RECOMMENDED_DEBIT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlenes Abbuchungsdatum"])},
      "RECOMMENDED_DEBIT_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlener Abbuchungsbetrag"])},
      "MONTHLY_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlicher Betrag"])},
      "MONTH_OUTCOMES_RENTS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestbetrag für Mieten"])},
      "MONTH_OUTCOMES_RENTS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstbetrag für Mieten"])},
      "MONTH_OUTCOMES_UTILITIES_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestbetrag für Versorgungsleistungen"])},
      "MONTH_OUTCOMES_UTILITIES_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstbetrag für Versorgungsleistungen"])},
      "MONTH_OUTCOMES_CREDITS_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestbetrag"])},
      "MONTH_OUTCOMES_CREDITS_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstbetrag"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestanzahl der monatlichen Abbuchungen"])},
      "MONTH_OUTCOMES_CREDITS_COUNT_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchstanzahl der monatlichen Abbuchungen"])},
      "BANK_CARD_AND_DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkarte und Abbuchungen"])},
      "DEBITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbuchungen"])},
      "BANK_CARD_PRESENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte Bankkarte(n)"])},
      "BANK_CARD_QUANTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Bankkarten mit ausgehenden Transaktionen"])},
      "BANK_CARD_NUMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifizierte Kartenummern für ausgehende Transaktionen"])},
      "MONTH_NEGATIVE_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühestes Datum"])},
      "MONTH_NEGATIVE_BALANCE_MAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spätestes Datum"])},
      "MAX_BALANCE_MIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frühestes minimales monatliches Datum, an dem der Kontostand maximal ist"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhältnis der Ausgaben zu den Einnahmen"])},
      "MONTH_NEGATIVE_BALANCE_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches Datum für das Überziehen des Kontos"])},
      "AVERAGE_MONTHLY_ENTRIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche Einträge"])},
      "TOTAL_FOR_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtsumme für den Zeitraum"])},
      "AVERAGE_MONTHLY_TAX_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche Steuereinnahmen"])},
      "AVERAGE_MONTHLY_EXITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche Abgänge"])},
      "ANNUAL_NET_INCOME_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag des jährlichen Nettoeinkommens"])},
      "ANNUAL_REVENUE_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresumsatzbetrag"])},
      "MONTHLY_TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlicher Steuerbetrag"])},
      "AVERAGE_MONTHLY_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Lohnsumme"])},
      "OTHER_ACCOUNTS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Konten"])},
        "MONTH_INCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches monatliches Sparinkommen"])},
        "MONTH_OUTCOMES_SAVINGS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittlicher monatlicher Sparausgang"])},
        "MONTH_INCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche eingehende Überweisungen"])},
        "MONTH_OUTCOMES_TRANSFERS_AMOUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche ausgehende Überweisungen"])}
      }
    },
    "DEBT_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschuldungsquote"])},
    "MONTH_OUTCOMES_CREDITS_COUNT_MEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der laufenden Kredite"])},
    "REMAINS_TO_LIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibendes Einkommen"])},
    "START_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum des Analysezeitraums"])},
    "END_STUDY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enddatum des Analysezeitraums"])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliches Endguthaben"])},
    "TOP_JOINT_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Gemeinschaftskonto"])},
    "TOP_RECOMMENDED_DATE_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfohlenes Abbuchungsdatum"])},
    "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankaufsicht"])},
    "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Transaktionen"])},
    "MOVEMENT_SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungszusammenfassung"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etikett"])},
    "ACCOUNT_STATEMENT": {
      "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauszug anzeigen"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTO(S) AUSZÜGE(N)"])},
      "ACCOUNT": {
        "CHECKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GIROKONTO"])},
        "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPARKONTO"])}
      },
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhängige Transaktion"])},
      "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten gefunden, bitte eine neue Suche starten"])},
      "PRIVACY_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Sicherheits- und Datenschutzgründen ist der OpenBanking-Kontoauszug nach einer bestimmten Zeit nicht mehr verfügbar."])},
      "CATEGORY": {
        "INCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbungszahlung"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zulage"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familienzulage"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitslosenzulage"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Zulage"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisuelles Abonnement"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoinstandhaltung"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Parken"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Überweisung"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankgebühr"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bargeldsammlung"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheck"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbraucherkredit"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungskredit"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptowährung"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulturevent"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenkauf"])},
          "DEPOSIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzahlung"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spende und Wohltätigkeit"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrerlaubnis"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulbildung"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strafgebühr"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitnessabonnement"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittelkonsum"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrügerische Überweisung"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glücksspiel"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittelgeschäft"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesundheit und Wellness"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschädigung"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versicherung"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsen"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale Überweisung"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventionsgebühr"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investition"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebühr"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freizeit"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rente"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haustierpflege"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leseabonnement"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückerstattung"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditablehnung"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Ablehnung"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miete"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauchermiete"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsmiete"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehalt"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguläres Gehalt"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporäres Gehalt"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersparnis"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitsdienstabonnement"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschlagnahmegebühr"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufen"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwareabonnement"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktienmarkt"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndikat"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telekommunikationsabonnement"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbezahlt"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstprogramm"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energieversorgung"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserversorgung"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Dienstprogramme"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videospiel"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
        },
        "OUTCOME": {
          "ADVERTISINGPAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbungszahlung"])},
          "ALLOWANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zulage"])},
          "ALLOWANCE": {
            "FAMILY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familienzulage"])},
            "UNEMPLOYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitslosenzulage"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Zulage"])}
          },
          "AUDIOVISUALSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audiovisuelles Abonnement"])},
          "AUTOMOBILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])},
          "AUTOMOBILE": {
            "MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoinstandhaltung"])},
            "PARKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Parken"])}
          },
          "AUTOTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Überweisung"])},
          "BANKINGFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankgebühr"])},
          "CASHCOLLECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bargeldsammlung"])},
          "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scheck"])},
          "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit"])},
          "CREDIT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbraucherkredit"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungskredit"])}
          },
          "CRYPTOCURRENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kryptowährung"])},
          "CULTUREEVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulturevent"])},
          "DATAPURCHASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenkauf"])},
          "DONATIONANDCHARITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spende und Wohltätigkeit"])},
          "EDUCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildung"])},
          "EDUCATION": {
            "DRIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrerlaubnis"])},
            "SCHOOL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schulbildung"])}
          },
          "FINEFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strafgebühr"])},
          "FITNESSSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fitnessabonnement"])},
          "FOODCONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittelkonsum"])},
          "FRAUDULENTTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrügerische Überweisung"])},
          "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glücksspiel"])},
          "GROCERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebensmittelgeschäft"])},
          "HEALTHWELLNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesundheit und Wellness"])},
          "HOSTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hosting"])},
          "INDEMNITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschädigung"])},
          "INSURANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versicherung"])},
          "INTEREST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zinsen"])},
          "INTERNATIONALTRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationale Überweisung"])},
          "INTERVENTIONFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventionsgebühr"])},
          "INVESTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investition"])},
          "LEGALFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebühr"])},
          "LEISURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freizeit"])},
          "PENSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rente"])},
          "PETCARE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haustierpflege"])},
          "READINGSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leseabonnement"])},
          "REIMBURSEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rückerstattung"])},
          "REJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
          "REJECT": {
            "CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditablehnung"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Ablehnung"])}
          },
          "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miete"])},
          "RENT": {
            "CONSUMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauchermiete"])},
            "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungsmiete"])}
          },
          "SALARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehalt"])},
          "SALARY": {
            "REGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reguläres Gehalt"])},
            "TEMPORARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temporäres Gehalt"])}
          },
          "SAVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersparnis"])},
          "SECURITYSERVICESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitsdienstabonnement"])},
          "SEIZUREFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschlagnahmegebühr"])},
          "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
          "SHOPPING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufen"])},
          "SOFTWARESUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwareabonnement"])},
          "STOCKMARKET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktienmarkt"])},
          "SYNDICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndikat"])},
          "TAXE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer"])},
          "TELECOMSUB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telekommunikationsabonnement"])},
          "TRANSFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überweisung"])},
          "TRANSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport"])},
          "UNPAID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbezahlt"])},
          "UTILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstprogramm"])},
          "UTILITY": {
            "ENERGY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energieversorgung"])},
            "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserversorgung"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Dienstprogramme"])}
          },
          "VIDEOGAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videospiel"])},
          "WITHDRAWAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abheben"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
        }
      }
    },
    "RECOMMENDATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungen"])},
    "APPROVALS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genehmigungen"])},
    "INDEBTEDNESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschuldung"])},
    "HABITAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnraum"])},
    "EXISTING_CREDIT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse bestehender Kredite"])},
    "ALERTS": {
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "CAUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursache"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrag"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
      "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Warnungen gemeldet"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnungen"])}
    },
    "SERVICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstleistungen"])},
    "WATER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasser"])},
    "GAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
    "ELECTRICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strom"])},
    "BANK_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkarten"])},
    "DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkennung von verzögerten Lastschriften"])},
    "GLOBAL_TRANSACTION_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtzahl der Transaktionen"])},
    "MONTH_NEGATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überziehung im Laufe des Zeitraums"])},
    "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptkonto"])},
    "NO_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Einkommenstransaktionen erkannt."])},
    "ALERT": {
      "ABSENCE_OF_INCOME": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlen von Einkommen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Einkommenstransaktionen erkannt"])}
      },
      "PRESENCE_OF_REJECTS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Ablehnungen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit Ablehnungen wurde erkannt"])}
      },
      "PRESENCE_OF_UNPAIDS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von unbezahlten Beträgen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit unbezahlten Beträgen wurde erkannt"])}
      },
      "PRESENCE_OF_PENSIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Renten"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit Renten wurde erkannt"])}
      },
      "PRESENCE_OF_GAMBLINGS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Glücksspieltransaktionen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit Glücksspiel wurde erkannt"])}
      },
      "PRESENCE_OF_ALLOWANCES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Zuschüssen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit Zuschüssen wurde erkannt"])}
      },
      "PRESENCE_OF_SEIZURE_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Pfändungsgebühren"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit Pfändungsgebühren wurde erkannt"])}
      },
      "MONTH_NEGATIVE_BALANCE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativer Monatssaldo"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben einen oder mehrere Tage mit einem negativen Kontostand erkannt"])}
      },
      "BANK_WITH_EXTREME_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank mit extrem hohem Risiko"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben ein oder mehrere Konten bei einer als extrem riskant geltenden Bank erkannt"])}
      },
      "BANK_WITH_HIGH_RISK_LEVEL": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank mit hohem Risiko"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben ein oder mehrere Konten bei einer als sehr riskant geltenden Bank erkannt"])}
      },
      "TRANSACTION_HISTORY_DATE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionshistoriendatum"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden weniger als 2 Monate Transaktionshistorie für diese Analyse übermittelt"])}
      },
      "TRANSACTION_HISTORY_DATE_ONE_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionsverlaufdatum"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weniger als 1 Monat Transaktionshistorie wurde für diese Analyse übertragen"])}
      },
      "PRESENCE_OF_CASH_COLLECTIONS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Präsenz von Inkassounternehmen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelo menos 1 transação com uma agência de cobrança detectada"])}
      },
      "PRESENCE_OF_CRYPTOCURRENCIES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Kryptowährungen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit Kryptowährungen wurde erkannt"])}
      },
      "TRANSACTION_HISTORY_COUNT": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Transaktionen in der Transaktionshistorie"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden weniger als 10 Transaktionen für diese Analyse übermittelt"])}
      },
      "PRESENCE_OF_CREDITS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Krediten pro Monat"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden mindestens 3 Kredittransaktionen pro Monat erkannt"])}
      },
      "PRESENCE_OF_INTERVENTION_FEES": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Interventionsgebühren"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit Interventionsgebühren wurde erkannt"])}
      },
      "PRESENCE_OF_WITHDRAWALS_PER_MONTH": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Bargeldabhebungen pro Monat"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden mindestens 7 Bargeldabhebungen pro Monat erkannt"])}
      },
      "PRESENCE_OF_INTERNATIONALS_TRANSFERS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von internationalen Überweisungen"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 1 Transaktion mit internationalen Überweisungen wurde erkannt"])}
      },
      "TRANSACTION_HISTORY_FOR_ANALYSIS": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionshistorie für die Analyse"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl der übermittelten Transaktionen reicht nicht für eine zeitnahe Analyse aus"])}
      },
      "ABSENCE_OF_TAXE": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerfreiheit"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Steuertransaktion erkannt"])}
      },
      "LOSS_OF_SALARY": {
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlust des Gehalts"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde keine Gehaltstransaktion erkannt"])}
      }
    },
    "NET_INCOME_AND_ANNUAL_TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoeinkommen und Jahresumsatz"])},
    "TAXES_AND_PAYROLL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuern & Lohnsumme"])},
    "DAILY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagesbilanz"])},
    "MONTHLY_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatliche Bilanz"])},
    "DAILY_AND_MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täglicher und monatlicher Saldo"])},
    "WRONG_ANALYSIS_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, die Analyse wurde auf einem privaten Konto und nicht auf einem geschäftlichen Konto durchgeführt"])},
    "WRONG_ANALYSIS_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, die Analyse wurde auf einem geschäftlichen Konto und nicht auf einem privaten Konto durchgeführt"])},
    "PROVIDER_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Datenabfrage ist ein Fehler aufgetreten. Bitte starten Sie den Ordner erneut."])}
  },
  "SOCIAL_FACE_MATCHING": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildersuche"])},
    "CATCHED_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefundene Bilder"])},
    "NO_IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Bild gefunden"])}
  },
  "AMLCFT": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekämpfung von Geldwäsche und Terrorismus"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfrieren von Vermögenswerten"])},
    "PPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politisch exponierte Person"])},
    "PPE_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Person wurde als politisch exponiert identifiziert"])},
    "ADVERSE_MEDIA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medienberichterstattung"])},
    "ADVERSE_MEDIA_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt mehrere Presseartikel über den Antragsteller."])},
    "ADVERSE_MEDIA_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Medienberichterstattung"])},
    "COLUMN_TABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "SANCTIONED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanktioniert"])},
    "SANCTIONED_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Person wurde als sanktioniert identifiziert."])},
    "SANCTIONED_EXPLANATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies kann eine Sperrung ihrer Vermögenswerte, Verbindungen zum Terrorismus und/oder zur Geldwäsche bedeuten."])},
    "CLASS_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staatsoberhäupter, Mitglieder der nationalen Exekutive, Mitglieder nationaler Legislative, hohe Militär- und Polizeibeamte, leitende Beamte nationaler Agenturen, Vorstandsmitglieder von Zentralbanken, Justizbeamte auf nationaler Ebene, Top-Führungskräfte politischer Parteien"])},
    "CLASS_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorstandsmitglieder internationaler Organisationen und internationaler Sportverbände, Botschafter, Hohe Kommissare und andere hochrangige diplomatische Positionen, Mitglieder der regionalen Exekutive, Mitglieder regionaler Gesetzgebungsorgane, leitende Justizbeamte auf regionaler Ebene"])},
    "CLASS_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsleitung und Vorstand von Unternehmen und öffentlichen Organisationen"])},
    "CLASS_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bürgermeister und Mitglieder lokaler Behörden auf unterregionaler Ebene"])},
    "LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ebene"])},
    "ADVERSE_MEDIA_DETAILS": {
      "financial-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzkriminalität"])},
      "violent-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewaltverbrechen"])},
      "sexual-crime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexualverbrechen"])},
      "terrorism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrorismus"])},
      "fraud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrug"])},
      "narcotics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drogen"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemein"])},
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentum"])},
      "financial-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Finanzwesen"])},
      "fraud-linked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Betrug verbunden"])},
      "narcotics-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Drogen"])},
      "violence-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Gewalt"])},
      "cybercrime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cyberkriminalität"])},
      "general-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML CFT Allgemein"])},
      "regulatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regulatorisch"])},
      "financial-difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielle Schwierigkeiten"])},
      "violence-non-aml-cft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewalt ohne AML CFT"])},
      "other-financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Finanz"])},
      "other-serious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Ernsthaft"])},
      "other-minor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Minderjährige"])}
    },
    "LINKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindungen"])},
    "NO_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Person wurde weder als sanktioniert noch als politisch exponiert identifiziert."])}
  },
  "TOOLTIP": {
    "CURRENT_PROCEDURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen mit laufenden rechtlichen Verfahren."])},
    "MOTHBALLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktives Unternehmen, das reaktiviert wurde."])},
    "MOBILE_PHONE_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Mobiltelefonnummer."])},
    "SOCIAL_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Google gefundene Bilder in Verbindung mit den eingegebenen Informationen."])},
    "EMAIL_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Überprüfung."])},
    "AVAILABLES_PICTURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf sozialen Medien verfügbare Bilder im Vergleich zu den Informationen des Antragstellers."])},
    "NUMBER_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Unternehmenseinrichtungen im Besitz des Unternehmens"])},
    "FREEZING_ASSETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, wenn derzeit Vermögenswerte eingefroren sind."])},
    "SCORE_WEB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrugsbewertung für die Existenz und Zuverlässigkeit der Kontaktdaten des Antragstellers."])},
    "SCORE_SECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikobewertung für die finanzielle Analyse und die Solvenz des Unternehmens."])},
    "DEFAULT_PROBABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maß für das Ausfallrisiko der Struktur."])},
    "SCORE_LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen aktualisiert am"])},
    "SCORE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bewertung bewertet die finanzielle Stärke des Unternehmens auf der Grundlage seiner Branche und seiner finanziellen Bilanzen."])},
    "SCORE_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikobewertung für die finanzielle Analyse des Bankkontos."])},
    "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche monatliche Bilanz während des untersuchten Zeitraums."])},
    "EMAIL_DISPOSABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wegwerf-E-Mail"])},
    "EMAIL_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riskante E-mail"])},
    "MOBILE_PHONE_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte Mobiltelefonnummer"])},
    "EMAIL_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte E-Mail"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung des Dossiers fortsetzen"])},
    "VIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier anzeigen"])},
    "DUPLICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelter Ordner"])},
    "DELETE_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordner löschen"])},
    "BLOCK_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist unmöglich, eine abgeschlossene Akte wieder aufzunehmen."])}
  },
  "EMAIL_DISPOSABLE": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die bereitgestellte E-Mail-Adresse ist mit einer Wegwerf-Domain verknüpft."])}
  },
  "DOCUMENT_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenprüfung"])},
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumententyp auswählen"])},
    "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufenthaltstitel"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Unternehmen oder Situation"])},
    "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Einzelperson"])},
    "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis - Unternehmen"])},
    "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentizität - Analyse der digitalen Unveränderbarkeit"])},
    "AUTHENTICITY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentizitätsdetails"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbericht"])},
    "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfacher Steuerbericht"])},
    "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplexer Steuerbericht"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbescheid - Einkommen oder Grundstücke"])},
    "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkommensteuer"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Art"])},
    "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer"])},
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankdaten"])},
    "BLOC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenprüfung"])},
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Analyse des Dokuments ist fehlgeschlagen"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte schließen Sie den Upload Ihres Dokuments im linken Fenster ab, um die Analyse des Dokuments anzeigen zu können."])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltsabrechnung"])},
    "PAYSLIP_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltsabrechnung"])},
    "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbindung"])},
    "KBIS_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS"])},
    "TAX_NOTICE_SELECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbescheid"])},
    "OTHER_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes Dokument"])},
    "NEW_DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Dokument analysieren"])},
    "NEW_FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische Kontrolle"])},
    "FACE_MATCHING_DESC_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie eine neue Identitätsanalyse mit biometrischer Kontrolle durchführen?"])},
    "FACE_MATCHING_DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die Telefonnummer des Kunden ein, damit er per SMS den Link zur Durchführung seiner Analyse erhalten kann."])},
    "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsanalyse"])},
    "ID_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie eine Dokumentenanalyse für Ausweisdokumente wie Personalausweise, Pässe, Aufenthaltstitel und Führerscheine starten."])},
    "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenanalyse"])},
    "DOCUMENT_ANALYSIS_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie eine Dokumentenanalyse für eine Steuererklärung, KBIS, Gehaltsabrechnung, Steuerbescheid oder ein anderes PDF-Dokument starten."])},
    "SELECT_DOCUMENT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein oder mehrere Dokumententypen aus"])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])},
    "UNKNOWN_DOC_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das von Ihnen übergebene Dokument konnte nicht als Ausweis identifiziert werden. Denken Sie zur Überprüfung daran, die Vorder- und Rückseite des Dokuments hochzuladen oder den Dokumentanalysebereich zu verwenden."])},
    "NO_DOCUMENT_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Dokument hochgeladen."])},
    "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische Kontrolle"])},
    "SELFIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
    "TABLE": {
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung"])},
      "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentizität"])},
      "ID_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Identitätsanalyse"])},
      "DOCUMENT_ANALYSIS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Dokumentenanalyse"])},
      "RESULT": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse Details"])},
        "CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung"])},
        "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
        "WAITING_CONFIRMATION_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse wartet auf Ihre Bestätigung"])},
        "IN_PROGRESS_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse läuft"])},
        "LIVE_CHECK": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auslösung der Zweifelüberprüfung"])},
          "DESC_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie sind dabei, die Zweifelüberprüfung des Dokuments <", _interpolate(_named("documentName")), "> auszulösen."])},
          "DESC_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die menschliche Überprüfung ist nur in den ersten 10 Minuten nach dem Hochladen des Dokuments verfügbar."])},
          "DESC_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Analyse kann einige Zeit in Anspruch nehmen, Sie erhalten eine Benachrichtigung, sobald die Analyse abgeschlossen ist."])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zweifelüberprüfung ermöglicht es Ihnen, eine manuelle Überprüfung des Dokuments durch einen menschlichen Operator innerhalb von fünfzehn Minuten anzufordern. Achtung, sie ist nur während der ersten 10 Minuten nach dem Hochladen des Dokuments verfügbar."])},
          "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifelüberprüfung wird bearbeitet"])},
          "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifelüberprüfung verfügbar"])},
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifelüberprüfung wird bearbeitet."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die nachstehenden Kontrollen werden aktualisiert, sobald die manuelle Überprüfung abgeschlossen ist. Bitte warten Sie."])},
          "FAILED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse der Zweifelüberprüfung des Dokuments nicht verfügbar."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein technischer Fehler ist beim Auslösen der Zweifelüberprüfung des Dokuments aufgetreten."])},
          "SUCCESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse der Zweifelüberprüfung des Dokuments abgeschlossen."])},
          "ANALYSIS_RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseergebnis"])},
          "ANALYST_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht des Analysten"])}
        },
        "CHECKS": {
          "EMISSION_COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung Staatsangehörigkeit im Dokument"])},
          "EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung Dokumententyp / Ausstellungsdatum"])},
          "EXPIRATION_DATE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit des Ablaufdatums"])},
          "VALIDITY_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument ist gültig"])},
          "MRZ_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-Prüfung"])},
          "MRZ_CHECKSUMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-Prüfung"])},
          "MRZ_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-Prüfung"])},
          "MRZ_ALIGNEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-Prüfung"])},
          "MRZ_CLASSIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-Prüfung"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Personendaten / MRZ"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Personendaten / MRZ"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Personendaten / MRZ"])},
          "DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentennummer stimmt mit MRZ überein"])},
          "EXPIRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum stimmt mit MRZ überein"])},
          "OCR_EMISSION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstellungsdatum stimmt mit MRZ überein"])},
          "PERSONAL_NUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personenkennzahl stimmt mit MRZ überein"])},
          "PHOTO_CONFORMITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoanalyse"])},
          "PHOTO_LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoanalyse"])},
          "VISUAL_SECURITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Sicherheitsmerkmale des Dokuments"])},
          "PHOTOCOPY_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiererkennung"])},
          "SCREENSHOT_DETECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screenshot-Erkennung"])},
          "OLD_DRIVING_LICENSE_DOCUMENT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer des alten Führerscheins"])},
          "DRIVING_LICENSE_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeitsprüfung des Führerscheins"])},
          "DRIVING_LICENSE_DIGITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeitsprüfung des Führerscheins"])},
          "AGE_AT_CERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsistenz des Alters bei Erteilung"])},
          "MRZ_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MRZ-Prüfung"])},
          "MRZ_IDENTITY_COHERENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsistenz der Personendaten"])},
          "PICTURE_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotoanalyse"])},
          "BARCODE_EXPECTED_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcode gefunden"])},
          "BARCODE_FIELDS_SYNTAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten des Barcodes korrekt codiert"])},
          "BARCODE_SIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültige Barcode-Signatur"])},
          "TYPE_RECOGNIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Dokumenttyp wurde erkannt"])},
          "NOT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum"])},
          "MRZ_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maschinenlesbare Zone (MRZ) des Dokuments ist gültig"])},
          "VALIDITY_PERIOD_MODEL_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Gültigkeitszeitraum-Modell stimmt überein"])},
          "OCR_ANALYSIS_MRZ_FIRST_NAMES_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vornamen in der MRZ entsprechen der OCR-Analyse"])},
          "OCR_ANALYSIS_MRZ_DOCUMENT_NUMBER_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Dokumentnummer in der MRZ entspricht der OCR-Analyse"])},
          "DOCUMENT_ASPECT_GENERAL_APPEARANCE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das allgemeine Erscheinungsbild des Dokuments entspricht den Erwartungen"])},
          "SECURITY_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicherheitselemente im Dokument vorhanden"])},
          "QUALITY_ABOVE_MINIMUM_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Qualität des Dokuments liegt über dem Mindestschwellenwert"])},
          "NOT_SPECIMEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokument ist kein Muster"])},
          "FRONT_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vorderseite des Dokuments wurde gefunden"])},
          "OCR_ANALYSIS_MRZ_BIRTH_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Geburtsname in der MRZ entspricht der OCR-Analyse"])},
          "OCR_ANALYSIS_MRZ_BIRTH_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Geburtsdatum in der MRZ entspricht der OCR-Analyse"])},
          "OCR_ANALYSIS_FAITHFUL_FONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die OCR-Analyse zeigt die Verwendung einer treuen Schriftart im Dokument an"])},
          "DOCUMENT_ASPECT_PICTURE_PRESENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bild liegt dem Dokument bei"])},
          "OCR_ANALYSIS_EXPIRATION_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ablaufdatum wurde erfolgreich durch OCR-Analyse gelesen"])},
          "OCR_ANALYSIS_ISSUANCE_DATE_READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausstellungsdatum wurde erfolgreich durch OCR-Analyse gelesen"])},
          "ISSUANCE_AND_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ausstellungs- und Ablaufdaten stimmen überein"])},
          "NO_TAMPERING_SIGN_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kein Zeichen für Manipulation auf dem Dokument gefunden"])},
          "NO_ANNOTATION_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Anmerkungen auf dem Dokument gefunden"])},
          "BACK_SIDE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rückseite des Dokuments wurde gefunden"])},
          "OCR_ANALYSIS_MRZ_EXPIRATION_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ablaufdatum im MRZ entspricht der OCR-Analyse"])},
          "OCR_ANALYSIS_MRZ_ISSUANCE_DATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausstellungsdatum im MRZ entspricht der OCR-Analyse"])},
          "OCR_ANALYSIS_MRZ_LAST_NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Nachname im MRZ entspricht der OCR-Analyse"])},
          "DOC_2D_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-Dokument gültig"])},
          "DOC_2D_AND_DOCUMENT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-Dokument entspricht den Informationen im Dokument"])},
          "WORKABLE_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokument kann zur Überprüfung verarbeitet werden"])},
          "WORKABLE_ELEMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Element kann zur Überprüfung verarbeitet werden"])},
          "STATUS_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptiertes Dossier"])},
          "FRAUD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrügerisches Dokument"])},
          "DOCUMENT_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Qualität der Dokumentenerfassung erlaubt keine menschliche Überprüfung"])},
          "DOCUMENT_INCORRECTLY_FRAMED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokument ist nicht korrekt gerahmt im Bild"])},
          "DOCUMENT_SIDE_MISSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur eine Seite des Dokuments ist sichtbar (Vorder- und Rückseite sind erforderlich)"])},
          "DOCUMENT_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument abgelaufen"])},
          "DOCUMENT_UNSUPPORTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumententyp wird nicht unterstützt"])},
          "DOCUMENT_COUNTRY_UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Land der Dokumentausstellung wird nicht unterstützt"])},
          "DOCUMENT_UNSUPPORTED_RECEIPT_ACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumententyp wird nicht unterstützt"])},
          "BIOMETRY_INSUFFICIENT_QUALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Qualität der biometrischen Daten erlaubt keine menschliche Überprüfung"])},
          "LIVE_CHECK_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technischer Fehler beim Anbieter aufgetreten"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderer Grund, siehe Details unten"])},
          "ID_PHOTO_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung zwischen dem Selfie des Nutzers und dem Dokument"])},
          "PASSIVE_LIVENESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingungen für das Selfie erfüllt"])},
          "IS_ALIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegungen während der Selfie-Aufnahme erkannt"])},
          "SEQUENCE_CONSISTENCY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kohärente Bewegungen während der Aufnahme"])},
          "ORIGINAL_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorgelegtes Originaldokument"])},
          "PICTURE_NOT_TAMPERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverändertes Passfoto"])},
          "DOCUMENT_NUMBER_VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit der Dokumentennummer"])}
        },
        "KBIS": {
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsdatum"])},
          "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
          "HEAD_QUARTER_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptsitzadresse"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeit"])},
          "PUBLICATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veröffentlichungsdatum KBIS"])},
          "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identität des Mitglieds"])},
          "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
          "IDENTIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikation"])},
          "MAIN_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haupttätigkeit"])},
          "MEMBERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
          "NATURAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natürliche Person(en)"])},
          "LEGAL_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Person(en)"])},
          "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
          "BIRTH_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsname"])},
          "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
          "FIRST_NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
          "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsform"])},
          "COMPANY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
          "ESTABLISHMENT_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtung"])}
        },
        "TAX_REPORT": {
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensinformationen"])},
          "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
          "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlussdatum des Geschäftsjahres"])},
          "OPENING_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eröffnungsdatum des Geschäftsjahres"])},
          "CONFIDENTIALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrauliches Dokument"])},
          "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer der Steuerperiode"])},
          "RATIOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzkennzahlen"])},
          "MONTH": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " Monat"]), _normalize([_interpolate(_named("n")), " Monate"])])}
        },
        "TAX_NOTICE": {
          "TAX_ADRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steueradresse"])},
          "BANKID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkennung"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontonummer"])},
          "REMAINING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibender Betrag"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsname"])},
          "NUMBER_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Teile"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiäre Situation"])},
          "CHECKS": {
            "FOUND_2D_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2D-DOC identifiziert"])},
            "NAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Namen"])},
            "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung des Ausstellungsdatums"])},
            "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung des steuerpflichtigen Einkommens"])},
            "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Steuernummer"])},
            "REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Referenz"])},
            "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung des Referenzjahres"])},
            "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Teilanzahl"])},
            "NUMBER_OF_REGISTRANTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung der Anzahl der Melder"])}
          }
        },
        "AUTHENTICITY": {
          "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentizität"])},
          "DETAILED_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details der Analyse"])},
          "NO_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Warnungen aus der Dokumentenanalyse gemeldet."])},
          "BINARY_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument wurde seit seiner Erstellung geändert"])},
          "COHERENCE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokument ist hinsichtlich der von uns erfassten Daten nicht kohärent."])},
          "AUTOGENERATED_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Dokument stammt aus einer Software zur Generierung gefälschter Dokumente"])},
          "METADATA_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Analyse der Metadaten zeigt eine Änderung des Dokuments"])},
          "PIXEL_MODIFICATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Teil des Bildes wurde geändert"])},
          "SOFTWARE_RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die letzte Software ist eine Bildbearbeitungssoftware (wie Photoshop) und kann verwendet werden, um das Bild zu vergrößern (um es authentisch erscheinen zu lassen) oder zu ändern."])},
          "REPORT_FINOVOX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierter Finovox-Bericht (PDF)"])},
          "ANALYSIS_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Authentizitätsanalysen können einige Minuten in Anspruch nehmen."])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist während der OCR-Verarbeitung aufgetreten. Bitte überprüfen Sie die Dateierweiterung (pdf, jpg, jpeg, png erlaubt), bevor Sie es erneut versuchen. Bei anhaltenden Problemen wenden Sie sich bitte an den Support."])}
        },
        "PROPERTY_TAX": {
          "PAYMENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformationen"])},
          "LEGAL_DEBTORS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesetzliche Schuldner"])},
          "PROPERTIES_AND_BUILDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücke und Gebäude"])},
          "PAYMENT_DEADLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsfrist"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibender Betrag zu zahlen"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankkennung"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Gläubigers"])},
          "TAX_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbetrag"])},
          "IDENTIFIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikation"])},
          "RIGHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht"])},
          "DESIGNATION_AND_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung und Adresse"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformationen"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibender Betrag zur Rückzahlung"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zur Steuerbescheid"])},
          "ADDRESSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressen"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintragender"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-Nummer"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis Referenz"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollennummer"])},
          "TAX_DEPARTMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerabteilung"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümernummer"])},
          "TAX_COMMUNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuer-Gemeinde"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst-ID"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstellungsdatum"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])}
        },
        "INCOME_TAX": {
          "INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum Steuerbescheid"])},
          "ISSUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgabe"])},
          "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "FIP_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIP-Nummer"])},
          "SERVICE_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID des Dienstes"])},
          "TAX_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuernummer"])},
          "REFERENCE_TAX_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenzsteuereinkommen"])},
          "ASSESSMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
          "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelder"])},
          "ROLE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollennummer"])},
          "TAX_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steueradresse"])},
          "PAYMENT_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformationen"])},
          "FAMILY_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiendetails"])},
          "REMAINING_AMOUNT_TO_PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibender Betrag zu zahlen"])},
          "BIRTH_LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsname"])},
          "NUMBER_OF_PARTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Teile"])},
          "FAMILY_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Familiäre Situation"])},
          "INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum Steuerbescheid"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformationen"])},
          "MANDATE_UNIQUE_REFERENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzigartige Mandatsreferenz"])},
          "REMAINING_AMOUNT_TO_REPAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibender Rückzahlungsbetrag"])},
          "CREDITOR_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Gläubigers"])},
          "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
          "BANK_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankidentifikation"])},
          "REF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bescheidreferenz"])},
          "OWNER_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümernummer"])},
          "REFERENCE_YEAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenzsteuerjahr"])}
        },
        "BANK_DETAILS": {
          "BANK_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankleitzahl"])},
          "BRANCH_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filialnummer"])},
          "ACCOUNT_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontonummer"])},
          "KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB-Schlüssel"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "BIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankname"])},
          "BANK_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankadresse"])},
          "IBAN_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit der IBAN"])},
          "ACCOUNT_NUMBER_VALIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültigkeit der Kontonummer"])},
          "DOCUMENT_ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument analysiert"])},
          "DIRECT_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastschrift"])},
          "CHECKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen"])}
        },
        "PAYSLIP": {
          "PAYSLIP_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltsabrechnungsinformationen"])},
          "PAYMENT_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinformationen"])},
          "HIRE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungsdatum"])},
          "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
          "SIRET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "NET_PAYABLE_BEFORE_INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettozahlung vor Einkommensteuer"])},
          "NET_PAYABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettozahlung nach Einkommensteuer"])},
          "PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsperiode"])}
        },
        "FACE_MATCHING": {
          "IN_PROGRESS_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische Überprüfung wird durchgeführt."])},
          "IN_PROGRESS_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobald die Analyse durch den Kunden abgeschlossen ist, werden die untenstehenden Kontrollen angezeigt. Bitte haben Sie Geduld."])},
          "FAILED_OR_CANCELLED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische Überprüfung nicht verfügbar."])},
          "FAILED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein technischer Fehler ist bei der Durchführung der biometrischen Überprüfung aufgetreten."])},
          "CANCELLED_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer hat die biometrische Überprüfung abgebrochen."])}
        }
      }
    },
    "NO_PERSON_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Personen gefunden"])},
    "ERROR_MESSAGE_API": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Problem ist aufgetreten"])}
  },
  "SCORE": {
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzahl"])},
    "WEB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
    "SECTOR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sektor"])},
    "COMPANY_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "DEFAULT_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
    "REQUEST_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage-ID"])},
    "HELPER_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir empfehlen zusätzliche Überprüfungen durchzuführen."])},
    "LOCAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokaler Score"])},
    "INTERNATIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internationaler Score"])}
  },
  "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzanalyse des Unternehmens"])},
  "TAX_REPORTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerberichte"])},
    "FISCAL_PERIOD_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer des Geschäftsjahres"])},
    "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlussdatum"])},
    "TURNOVER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatz"])},
    "NET_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoeinkommen"])},
    "AUTO_FINANCE_CAPACITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selbstfinanzierungskapazität"])},
    "NET_MARGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettomarge"])},
    "FB_TB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzstruktur"])},
    "FINANCIAL_LIQUIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielle Liquidität"])},
    "WORKING_CAPITAL_REQUIREMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebskapitalbedarf"])},
    "WORKING_CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betriebskapital"])},
    "FINANCIAL_SOLDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzieller Saldo"])},
    "DEBTS_SHORT_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurzfristige Schulden / Bilanz"])},
    "DEBTS_MIDDLE_LONG_TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittelfristige und langfristige Schulden / Bilanz"])},
    "NOT_AVAILABLE_NEED_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerberichte nicht verfügbar. Sie können einen hochladen, um eine Analyse zu erhalten."])}
  },
  "FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzen"])},
  "AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verfügbar"])},
  "UNAVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht verfügbar"])},
  "NO_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht verfügbar"])},
  "ENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheit"])},
  "ENTITIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten"])},
  "YES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "OF_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des Monats"])},
  "EFFECTIVE_RECIPIENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftlich Berechtigter"])},
  "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
  "REPRESENTATIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertreter"])},
  "WELCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "LAST_UPDATE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzahlen aktualisiert am"])},
  "VERIFICATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existenz"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Existenz von Informationen beim Antragsteller"])}
  },
  "RELATED": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausreichende Verbindung"])},
    "TOOLTIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung der Verknüpfung von Informationen mit dem Antragsteller"])}
  },
  "FINISH": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier abschließen"])},
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die PDF-Zusammenfassung vor dem Abschließen der Seite abrufen?"])}
  },
  "FUNCTION": {
    "MANAGING_DIRECTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer"])}
  },
  "SOCIAL_NETWORKS": {
    "MAIL_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte E-Mail"])},
    "PHONE_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte Telefonnummer"])},
    "ADDRESS_LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfte Adresse"])},
    "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit E-Mail verknüpft"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Telefon verknüpft"])},
    "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soziale Netzwerke"])},
    "INFORMATIONS": {
      "AGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alter"])},
      "CITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
      "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundesland"])},
      "GENDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlecht"])},
      "COUNTRY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
      "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])}
    }
  },
  "TELEPHONE": {
    "LINETYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitzone"])},
    "CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktueller Anbieter"])},
    "ORIGINAL_CARRIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ursprünglicher Anbieter"])},
    "GEOLOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
    "OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsinhaber"])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])}
  },
  "HISTORIC_PAGE": {
    "HISTORIC_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlauf der Akten"])},
    "B2B_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensanalyse"])},
    "B2C_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelanalyse"])},
    "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
    "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte 7 Tage"])},
    "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])}
  },
  "TABLE": {
    "PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro Seite"])},
    "FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktenordner"])},
    "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächster"])},
    "BACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorheriger"])},
    "MARKET_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markttyp"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journey-ID"])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
    "NUMBER_OF_TAX_NOTICES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Steuerbescheide"])},
    "NUMBER_OF_TAX_REPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Steuerberichte"])},
    "NUMBER_OF_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Ausweisdokumente"])},
    "NUMBER_OF_PASSPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Reisepässe"])},
    "NUMBER_OF_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Kbis-Dokumente"])},
    "NUMBER_OF_BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Bankdaten"])},
    "NUMBER_OF_DRIVING_LICENSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Führerscheine"])},
    "NUMBER_OF_RESIDENCE_PERMITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Aufenthaltstitel"])},
    "NUMBER_OF_PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Gehaltsabrechnungen"])},
    "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunde"])},
    "MAIL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
    "POSTAL_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postanschrift"])},
    "TELEPHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung"])},
    "SCORE_SMB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrugsbewertung"])},
    "SCORE_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensbewertung"])},
    "TAG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "STATUS_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktenstatus"])},
    "DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
    "OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anbieter"])},
    "SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
    "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "FILE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktennummer"])},
    "DATE_AND_TIME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum und Uhrzeit"])},
    "DOCUMENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumententyp"])},
    "DOCUMENT_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentname"])}
  },
  "REGEX": {
    "NOT_NULL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf nicht leer sein."])},
    "PHONE_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Nummer ist ungültig."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die E-Mail-Adresse ist ungültig."])},
    "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Format sollte TT/MM/JJJJ sein."])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss 9 Ziffern haben."])},
    "NATIONAL_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muss 10 Ziffern haben."])},
    "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Datum."])},
    "LETTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte Buchstaben enthalten."])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte im IBAN-Format sein."])},
    "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte nur Buchstaben enthalten."])},
    "LINKEDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Link ist ungültig."])},
    "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf nur Zahlen enthalten"])},
    "UPPER_CASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf nur Großbuchstaben, Zahlen und Unterstriche enthalten."])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darf nur Buchstaben, Zahlen und die Symbole _+%- enthalten"])}
  },
  "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "FIRST_SEEN_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webnutzungsspuren"])},
  "NUMBER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmenskennnummer"])},
  "EMPLOYER_VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung durch den Arbeitgeber"])},
  "PROCESS_OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking Prozess"])},
  "INFORMATION_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensinformationen"])},
  "SCORING_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrauensbewertung Unternehmen"])},
  "EMAIL_DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Domäne"])},
  "EMAIL_DOMAIN_CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum der E-Mail-Domäne"])},
  "EMAIL_DOMAIN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung mit der E-Mail-Domäne"])},
  "EMAIL_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung mit der E-Mail-Adresse"])},
  "OTHER_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besitzt ein anderes Unternehmen"])},
  "IS_RECENTLY_ACQUIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kürzlich erworben"])},
  "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzbewertungen"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "EMAIL_CORRELATION_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung zwischen E-Mail-Domain und Website"])},
  "CHECK_IBAN": {
    "CONTAINER": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Überprüfung"])},
      "COMPANY_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensname"])}
    },
    "RESULT": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Analyse"])},
      "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfungen"])},
      "BANK_CONNECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbindung zur Bank"])},
      "typeTitulaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhabertyp"])},
      "dateNaissance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "autreNom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderer Name"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überprüfungsanfrage konnte nicht durchgeführt werden, da ein technischer Fehler aufgetreten ist"])},
      "IBAN_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die IBAN existiert"])},
      "IBAN_NOT_EXISTS_ANYMORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die IBAN existiert nicht mehr, und das Konto wurde vor weniger als 13 Monaten geschlossen"])},
      "IBAN_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die IBAN existiert nicht oder das Konto wurde vor mehr als 13 Monaten geschlossen"])},
      "IBAN_CAN_BE_VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die IBAN kann nicht überprüft werden: Der Benutzer hat beantragt, dass sein Konto nicht mit DIAMOND überprüfbar ist oder das Konto ist in Fremdwährung oder das Konto ist nicht für Lastschriften und Überweisungen geeignet und fällt daher aus dem Bereich heraus..."])},
      "HOLDER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angegebene Kontoinhabertyp ist korrekt"])},
      "HOLDER_TYPE_INCORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der angegebene Kontoinhabertyp ist inkorrekt"])},
      "SIREN_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene Identifikationsnummer stimmt mit der überprüften IBAN überein"])},
      "SIREN_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die angegebene Identifikationsnummer stimmt nicht mit der überprüften IBAN überein"])},
      "SIREN_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bank ist nicht in der Lage, die Identifikationsnummer zu überprüfen (z. B. weil sie sie nicht kennt)"])},
      "BIRTHDATE_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angegebene Geburtsdatum stimmt mit der überprüften IBAN überein"])},
      "BIRTHDATE_NOT_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angegebene Geburtsdatum stimmt nicht mit der überprüften IBAN überein"])},
      "BIRTHDATE_CANNOT_CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bank ist nicht in der Lage, das angegebene Geburtsdatum zu überprüfen (z. B. weil sie es nicht kennt)"])},
      "OWNER_TYPE_CORRECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eigentümertyp ist korrekt"])},
      "REGISTRATION_NUMBER_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Registrierungsnummer stimmt mit der IBAN überein"])},
      "BIRTHDATE_MATCHES_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Geburtsdatum stimmt mit der IBAN überein"])},
      "NAME_MATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name stimmt überein"])},
      "IBAN_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige IBAN"])},
      "ICQX_ORDERING_PARTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX des Auftraggebers"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsnummer"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "INVALID_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Datum"])},
      "SEPAMAIL_BANK_ELIGIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank für SEPAMAIL geeignet"])},
      "REGISTRATION_NUMBER_ENROLLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsnummer eingetragen"])},
      "ANALYSIS_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des Zahlungsverlaufs"])},
      "ANALYSIS_COMMUNITY_PAYMENT_HISTORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse des gemeinschaftlichen Zahlungsverlaufs"])},
      "FACTORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factoring"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])},
      "REASON_CODE": {
        "IncorrectAccountNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige IBAN"])},
        "UnrecognisedInitiatingParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ICQX des Auftraggebers"])},
        "InvalidIdentificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
        "MissingName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "InvalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Daten"])},
        "BICInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank nicht für SEPAMAIL-Überprüfung geeignet"])}
      }
    },
    "ERROR_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es scheint, als hätte die abgefragte Bank ein Verbindungsproblem mit SepaMail. Vielen Dank für Ihr Verständnis."])}
  },
  "INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen"])},
  "SYNTHESIS": {
    "FOLDER_STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordnerstatus"])},
    "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst"])},
    "LINKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpft"])},
    "VERIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüft"])},
    "JOURNEY_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutige Kennung"])},
    "PHONE_AND_SOCIAL_NETWORKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon und soziale Netzwerke"])},
    "LINKED_TO_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit E-Mail verknüpft"])},
    "LINKED_TO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Telefon verknüpft"])},
    "BUSINESS_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
    "VERIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung"])}
  },
  "WARNING_LIST": {
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
    "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Warnmeldung bei unserer Analyse"])},
    "MESSAGE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, die folgenden Parameter wurden bei einem früheren Betrugsfall identifiziert"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnliste Meelo"])},
    "MESSAGE_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung, der eingegebene Parameter wurde bei einem früheren Betrugsfall identifiziert."])},
    "MESSAGE_NO_ALERT_SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Warnmeldung bei unserer Analyse."])}
  },
  "SELECT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie die Analysen aus, die Sie starten möchten"])},
  "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse"])},
  "PHONE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie einen Indikator aus und geben Sie eine Nummer ohne Leerzeichen oder Zeichen zwischen den Zahlen ein."])},
  "BIRTHDATE_INDICATION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stark empfohlen, um das Kundenökosystem anzuzeigen."])},
  "EXAMPLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beispiel"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "DOMAIN_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zur Domäne"])},
  "DOMAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäne"])},
  "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melder"])},
  "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoster"])},
  "ADDITIONAL_CONTROLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Kontrollen"])},
  "COMPANY_DOMICILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domizilunternehmen"])},
  "EMAIL_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Erstellung der E-Mail-Domäne"])},
  "WEBSITE_DOMAIN_CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Erstellung der Website-Domäne"])},
  "MORE_THAN_THREE_YEARS_WITHOUT_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivitätsdauer von mehr als 3 Jahren"])},
  "LOCATION_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefährdeter Standort"])},
  "OPERATOR_AT_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikobetreiber"])},
  "INITIAL_RISKY_OPERATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikobehafteter Erstbetreiber"])},
  "NO_WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für weitere Informationen führen Sie bitte die Bewertungen aus"])},
  "HAS_OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Unternehmen in kollektiven Verfahren"])},
  "HAS_OTHER_COMPANIES_IN_RADIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Unternehmen gestrichen"])},
  "HAS_OTHER_COMPANIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes Unternehmen"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "SUPPORT_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Problem? Zögern Sie nicht, uns zu kontaktieren"])},
  "SIDEBAR": {
    "KIT_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenanalyse"])},
    "KIT_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensanalyse"])},
    "KIT_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking"])},
    "KIT_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenanalyse"])},
    "HISTORICAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historie"])},
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])},
    "BATCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch"])},
    "HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
    "MEELO_KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Kit"])},
    "KIT_RECOVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederherstellung"])},
    "BACKOFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backoffice"])},
    "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portal"])},
    "MEELO_ACADEMY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Akademie"])},
    "DOCUMENT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenanalyse"])},
    "USERS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Benutzer"])},
      "PROFILE_AND_ACCESS_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil- und Zugriffsverwaltung"])},
      "USER_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerverwaltung"])},
      "GROUP_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenverwaltung"])},
      "LOCATION_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzverwaltung"])}
    },
    "LIST_MANAGEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listenverwaltung"])},
    "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "MONITORING": {
      "MY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Verbräuche"])},
      "COMPANY_USAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmenverbrauch"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauchsüberwachung"])}
    },
    "RULES_ENGINE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel-Engine"])},
      "COMPANY_RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensregeln"])},
      "SETS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelsets"])}
    },
    "COMPANY": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Unternehmen"])},
      "MANAGE_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listenverwaltung"])}
    }
  },
  "BREADCRUMBS": {
    "COMPANY_INFORMATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensinformationen"])},
    "APPLICANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antragsteller"])},
    "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entscheidung"])},
    "JUSTIFICATORIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterlagen"])},
    "SCORE_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktwahl"])},
    "RECEIVER_CHOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfängerauswahl"])},
    "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
    "BATCH_RUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batchausführung"])}
  },
  "PROJECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "PRODUCT": {
    "VEHICLES_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugwert inkl. MwSt."])},
    "CONTRIBUTION_AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einlagebetrag"])}
  },
  "CGI": {
    "DECISION": {
      "SUCCESS": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Schritt!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechsel zur Dokumentensammlung"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern und Weiter"])}
      },
      "FAILED": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Analyse ist abgeschlossen!"])},
        "CONTENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungünstige Meinung"])},
        "BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beenden"])}
      }
    },
    "DOCUMENTS": {
      "BLOCK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente herunterladen"])}
      },
      "ID_CARD": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorder- und Rückseite Ihres Ausweises"])}
      },
      "BANK_DETAILS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbindung"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankverbindung Ihres Hauptkontos"])}
      },
      "KBIS": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsregisterauszug"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS von weniger als 3 Monaten"])}
      },
      "TAX_REPORT": {
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuererklärung"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Steuererklärung mit mindestens 2 Jahren Historie"])}
      }
    },
    "CONGRATULATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gut gemacht"])},
    "END_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die Bearbeitung des Dossiers auf dem Meelo-Portal abgeschlossen."])}
  },
  "TITLE_ACTION_DOCUMENT_BUTTON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwerbsmodus"])},
  "ONLY_PDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur PDF"])},
  "MULTI_REQUESTS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrfachanfragen"])},
    "DESCRIPTION_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den letzten 30 Tagen wurde eine oder mehrere Akten angelegt von"])},
    "DESCRIPTION_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit ähnlichen und/oder identischen Informationen."])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine ähnlichen oder identischen Akten wurden in den letzten 30 Tagen erfasst."])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Akten mit derselben E-Mail"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Akten mit derselben Telefonnummer"])},
    "REGISTRATIONNUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Akten mit derselben Handelsregisternummer"])},
    "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Akten mit derselben IBAN"])}
  },
  "RECOVERY": {
    "CLIENT_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-ID"])},
    "REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage"])},
    "FINANCIAL_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzanalyse"])},
    "ANALYSIS_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyseerstellungsdatum"])},
    "BANK_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankinformationen"])},
    "CUSTOMER_RELIABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenverlässlichkeit"])},
    "REJECTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnung"])},
    "ON_AVERAGE_PER_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im Durchschnitt pro Monat"])},
    "INTERVENTION_FEES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interventionskosten"])},
    "THIRD_PARTY_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drittempfängerbenachrichtigung"])},
    "GAMBLING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glücksspiele"])},
    "FINANCIAL_SITUATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielle Lage"])},
    "AVERAGE_DISPOSABLE_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches verfügbares Einkommen"])},
    "LOWEST_DISPOSABLE_INCOME_OVER_THE_PERIOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das niedrigste verfügbare Einkommen in diesem Zeitraum"])},
    "LOWEST_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrigstes Einkommen"])},
    "LACK_OF_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkommenslosigkeit"])},
    "AVERAGE_MONTHLY_INCOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches monatliches Einkommen"])},
    "FINANCIAL_PRESSURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzieller Druck"])},
    "CREDITS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredite"])},
    "DEBT_COLLECTION_AGENCIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inkassobüros"])},
    "RISK_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikostufe"])},
    "LOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niedrig"])},
    "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mäßig"])},
    "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikoreich"])},
    "LACK_OF_INCOME_SOURCES_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine oder mehrere Einkommensquellen wurden erkannt"])},
    "LACK_OF_INCOME_SOURCES_NOT_DETECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Einkommen erkannt"])}
  },
  "BATCH": {
    "CLICK_ON_SCORES_BELOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie auf einen oder mehrere Punkte unten, um die Punkte auszuwählen, die über die Stapelverarbeitung ausgeführt werden sollen."])},
    "MANUAL_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebrauchsanweisung"])},
    "MANUAL_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für eine optimale Stapelverarbeitung empfehlen wir Ihnen, die oben bereitgestellte Vorlage herunterzuladen. Sie besteht aus der idealen Struktur, die für alle zuvor ausgewählten Punkte geeignet ist. Sie können dann die Excel-Datei mit Ihren Daten ausfüllen und etwas weiter unten auf der Seite hochladen."])},
    "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch-Abbruch erfolgreich"])},
    "DOWNLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie das Ergebnis des Batchs herunter"])},
    "STARTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch-Start läuft..."])},
    "RESULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben gerade eine E-Mail mit dem Ergebnis des Batchs erhalten"])},
    "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starten Sie den Batch"])},
    "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batch abbrechen"])},
    "USAGE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsinformationen"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "DOWNLOAD_TEMPLATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorlage herunterladen"])},
    "LIMIT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeilen / Datei"])},
    "FILE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschter Name der Ausgabedatei"])},
    "SCORE": {
      "REQUEST_ID": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsbetrugswertung"])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertung für professionellen Identitätsbetrug"])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertrauenswertung Unternehmen"])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung des Risikos im Energiesektor"])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Réputation-Score"])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikobewertung-Score"])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altares-Score"])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellisphere-Score"])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CreditSafe-Score"])}
      },
      "DESCRIPTION": {
        "WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Identitätsbetrugswertung ist ein Modell, das das Risiko von Identitätsbetrug bei einem Kunden bewertet. Sie basiert auf der Bewertung verschiedener Kriterien: Name, Vorname, E-Mail, Telefon, aber auch die Postanschrift oder das Geburtsdatum."])},
        "SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Professionelle Identitätsbetrugswertung ist ein Modell, das das Risiko von Identitätsbetrug bei einem Geschäftskunden (im Rahmen einer B2B-Beziehung) bewertet. Sie basiert auf der Bewertung verschiedener Kriterien: Name, Vorname, E-Mail, Telefon, aber auch die Postanschrift oder das Geburtsdatum."])},
        "WEB_SCORE_B2B_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Service 'Score Confiance Entreprise' ist ein Bewertungswerkzeug für Unternehmen und die Beziehung zwischen dem Antragsteller (natürliche Person) und dem Unternehmen (juristische Person)."])},
        "ENERGY_SCORE_V2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der 'Energie Risiko Score' ist ein speziell für den Energiesektor entwickeltes Modell, das das Risiko von Zahlungsausfällen des Kunden bewertet. Es basiert auf der Bewertung verschiedener Kriterien: Telekommunikationsanbieter / Bank / IRIS-Daten zur Postadresse / monatlicher Betrag (genau oder geschätzt)."])},
        "E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der E-Réputation-Score ist ein Modell zur Bewertung der Zufriedenheit und des Vertrauens der Verbraucher in das Unternehmen, basierend auf den verschiedenen Bewertungen, die sie hinterlassen haben."])},
        "E_PAYMENT_RISK_SCORE_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Risikobewertung-Score ist ein Modell zur Bewertung der Solvenz einer Person, basierend auf ihren persönlichen Informationen sowie ihren Zahlungsinformationen."])},
        "ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Altares-Score ist ein Modell zur Bewertung des Insolvenzrisikos eines Unternehmens, das auf den Ergebnissen basiert, die von der Finanzbewertungsfirma Altares bereitgestellt werden."])},
        "ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ellisphere-Score ist ein Modell zur Bewertung des Insolvenzrisikos eines Unternehmens, das auf den Ergebnissen basiert, die von der Finanzbewertungsfirma Ellisphere bereitgestellt werden."])},
        "CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der CreditSafe-Score ist ein Modell zur Bewertung des Insolvenzrisikos eines Unternehmens, das auf den Ergebnissen basiert, die von der Finanzbewertungsfirma CreditSafe bereitgestellt werden."])}
      }
    }
  },
  "FILE": {
    "CHOOSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Datei auswählen"])}
  },
  "IMPORTANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig"])},
  "RISKY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risikoreich"])},
  "BACKOFFICE": {
    "GROUPS": {
      "COMPANY_GROUPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenverwaltung"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe erstellen"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Gruppe wird von Benutzern verwendet. Sind Sie sicher, dass Sie sie löschen möchten?"])}
    },
    "PLACES": {
      "COMPANY_PLACES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzverwaltung"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platz bearbeiten"])},
      "CREATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platz erstellen"])},
      "DELETE_WARN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Platz wird von Benutzern verwendet. Sind Sie sicher, dass Sie ihn löschen möchten?"])}
    },
    "USERS": {
      "SHORTCUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Abkürzungen"])},
      "COMPANY_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmennutzer"])},
      "GROUP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
      "PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "USER_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzeränderung"])},
      "PROFILE_ASSIGNMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilvergaben"])},
      "USER_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer löschen"])},
      "DELETE": {
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, den Benutzer zu löschen"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vom Unternehmen"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Benutzer wird nicht mehr auf sein Konto zugreifen können."])},
        "ACTION_IRREVERSIBLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion ist nicht rückgängig zu machen."])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, diesen Benutzer zu löschen?"])}
      },
      "USER_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerprofil"])},
      "TECHNICAL_ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technische Maßnahmen"])},
      "SUPERVISOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufsichtsperson"])},
      "ACTIVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktives Konto"])},
      "UPDATE_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kontostatus wurde erfolgreich geändert."])},
      "USER_ADD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
      "RESET_PASSWORD": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Passwort zurücksetzen"])},
        "MESSAGE_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, dem Benutzer eine E-Mail zum Zurücksetzen des Passworts zu senden"])},
        "MESSAGE_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an die E-Mail-Adresse"])},
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail zum Zurücksetzen des Passworts gesendet"])}
      },
      "RESET_API_KEY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen eines API-Schlüssels"])},
        "CONFIRM_RESET_USER": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, dem Benutzer eine E-Mail zum Zurücksetzen des API-Schlüssels zu senden"])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["an die E-Mail-Adresse"])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtig : Der Link zum Abrufen des API-Schlüssels ist nur 30 Minuten gültig."])}
        },
        "CONFIRM_RESET_US": {
          "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, Ihren API-Schlüssel zurückzusetzen."])},
          "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aus Sicherheitsgründen kann der Wert Ihres neuen Schlüssels nur einmal abgerufen werden."])},
          "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser neue Schlüssel wird direkt in Ihre digitale Zwischenablage kopiert."])},
          "FOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir empfehlen Ihnen, es sofort an einem sicheren Ort aufzukleben, damit Sie es später wiederverwenden können."])}
        },
        "SENDING_MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail zum Zurücksetzen des API-Schlüssels gesendet"])},
        "NEW_API_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr neuer API-Schlüssel wurde in Ihre Zwischenablage kopiert"])}
      }
    },
    "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft"])},
    "FUNCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktion"])},
    "ADVANCED_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortgeschrittener Benutzer"])},
    "ADVANCED_USER_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kann weitere fortgeschrittene und/oder einfache Benutzer erstellen und auf bestimmte Einstellungen zugreifen."])},
    "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
    "PROFILE_AND_ACCESS": {
      "COMPANY_PROFILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenprofile"])},
      "PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigungen"])},
      "MESSAGE_PERMISSIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie oben ein Profil aus, um die Liste der zugehörigen Berechtigungen anzuzeigen."])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
      "KIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit"])},
      "MODULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modul"])},
      "RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte"])},
      "ADDING_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil hinzufügen"])},
      "PROFILE_MODIFICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiländerung"])},
      "SAVE_PERMISSION_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungen wurden festgestellt, vergessen Sie nicht, sie über die obige Schaltfläche zu speichern."])},
      "DESCRIPTION_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbeschreibung"])},
      "NO_PROFILE_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Profil gefunden"])}
    },
    "MONITORING": {
      "DOWNLOAD_BALANCE_SHEET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilanz herunterladen"])},
      "TOTAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtverbrauch"])},
      "MONTHLY_EVOLUTION_CURVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurve der monatlichen Entwicklung"])},
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
      "THIS_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Woche"])},
      "LAST_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Woche"])},
      "THIS_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Monat"])},
      "LAST_MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzten Monat"])},
      "GENERAL_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeiner Verbrauch"])},
      "COMPANY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauch für das Unternehmen"])},
      "DATA_ON_THE_WAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Daten sind unterwegs..."])},
      "CONSUMPTION": {
        "OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweis-Dokumentenprüfung"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenprüfung"])},
        "CHECK_VALUE_COMPANY_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2B IBAN-Überprüfung"])},
        "CHECK_VALUE_PERSONAL_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B2C IBAN-Überprüfung"])},
        "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensanalyse"])},
        "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCB-FT-Analyse"])},
        "GET_LINKEDIN_PROFILE_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LinkedIn-Bereicherung"])},
        "OPENBANKING_MEELO_OPENBANKING_PERSONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2C"])},
        "OPENBANKING_MEELO_OPENBANKING_PROFESSIONAL_SCORING_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking B2B"])},
        "SCORING_ELLISPHERE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellisphère-Score"])},
        "SCORING_CREDIT_SAFE_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CreditSafe-Score"])},
        "SCORING_WEB_SCORE_B2B_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensvertrauens-Score"])},
        "SCORING_WEB_SCORE_B2C_V3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitäts-Score für Privatpersonen"])},
        "SCORING_E_REPUTATION_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputations-Score"])},
        "SCORING_ALTARES_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altarès-Score"])},
        "SCORING_SMB_SCORE_V1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitäts-Score für Fachleute"])},
        "SELECTED_USERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauch der ausgewählten Benutzer"])},
        "OF_THE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensverbrauch"])},
        "OF_MY_TEAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauch meines Teams"])},
        "MY_CONSUMPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Verbrauch"])}
      },
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Daten gefunden. Führen Sie einen ersten Scan durch, um Ihren Verbrauch zu sehen."])}
    },
    "RULES_ENGINE": {
      "RULES": {
        "FOR_API_IMPLEMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für API-Implementierung"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier finden Sie die Liste der zugelassenen Regeln für Ihr Unternehmen."])},
        "RULES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regeln"])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "CONTAINED_IN_A_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthalten in einem Set"])},
        "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
        "GENERIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generisch"])},
        "SPECIFIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifisch"])},
        "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
        "MODIFY_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel bearbeiten"])},
        "RULE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelname"])},
        "RULE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelbeschreibung"])},
        "VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wert"])},
        "DEFAULT_VALUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardwert"])},
        "PARAMETERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter"])},
        "ARGUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Argumente"])},
        "EDIT_PARAMETER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter bearbeiten"])},
        "PARAMETER_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametername"])},
        "PARAMETER_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameterbeschreibung"])},
        "FLOAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezimal"])},
        "INTEGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ganzzahl"])},
        "STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
        "BOOLEAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boolesch"])},
        "LIST_STRING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste von Texten"])},
        "RULE_PRESENT_IN_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regel enthalten in folgenden Sets"])},
        "RULE_V1_NOT_EDITABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Regel ist eine V1-Regel und kann nicht bearbeitet werden. Wenn Sie sie bearbeiten möchten, wenden Sie sich bitte an Meelo."])}
      },
      "SETS": {
        "SETS_RULES_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Regeln eines Sets einzusehen, wählen Sie bitte die entsprechende Zeile im linken Tabellenbereich aus."])},
        "NO_SET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt kein Regelset für Ihr Unternehmen. Bitte kontaktieren Sie Meelo, um eins zu erstellen."])},
        "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
        "SETS_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Sets"])},
        "SETS_RULES_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der Regeln des Sets"])},
        "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier finden Sie die Liste der erstellten Regelsets für Ihr Unternehmen."])},
        "ADD_OR_DELETE_RULE_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für jegliche Änderungen bezüglich Hinzufügens oder Entfernens einer Regel in einem Set kontaktieren Sie bitte Meelo."])}
      }
    },
    "MY_COMPANY": {
      "USEFUL_CONTACTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nützliche Kontakte"])},
      "KITS_AND_MODULES_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kits und Module verfügbar"])},
      "ADMINISTRATOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator"])},
      "SUPPORT_MEELO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützung Meelo"])}
    }
  },
  "RESEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite"])},
  "ON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auf"])},
  "RESULTS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse gefunden"])},
  "NUMBER_OF_ITEMS_PER_PAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Elemente pro Seite"])},
  "SUMMARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusammenfassung"])},
  "DATA_CONTACTABILITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenkontaktierbarkeit"])},
  "AND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
  "UNREACHABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht erreichbar"])},
  "LOCALE": {
    "DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanisch"])},
    "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Französisch"])},
    "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italienisch"])},
    "NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederländisch"])},
    "PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugiesisch"])},
    "BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgien"])}
  },
  "ADVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bewertung"])},
  "E_REPUTATION": {
    "AVERAGE_GRADE_NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittsnote nicht gefunden"])},
    "LIST_TEN_LAST_REVIEWS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste der letzten 10 Bewertungen"])},
    "REVIEW_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungskommentar"])},
    "SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo E-Reputation-Score"])},
    "NEGATIVE_REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negativ geprägte Meinung"])}
  },
  "NO_COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Kommentar"])},
  "PRINT": {
    "FOLDER_INFO": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier-Informationen"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
      "FOLDER_STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier-Status"])},
      "ANALYST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst"])},
      "UNIQ_ID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindeutige Kennung"])}
    },
    "APPLICANT_OB": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antragsteller"])},
      "OB_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking Analyse"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "BIRTH_PLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsort"])},
      "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postanschrift"])},
      "BANK_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank"])},
      "HOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhaber"])},
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoname"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "MAIN_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptkonto"])},
      "MONTH_SALARIES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliches Monatsgehalt"])},
      "MONTH_RENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittliche Monatsmiete"])},
      "MONTH_END_RATIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhältnis von Ausgaben zu Einkommen"])},
      "MONTH_END_BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlicher Saldo"])},
      "BALANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Guthaben"])},
      "TOP_BANKING_SUPERVISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankenaufsicht"])},
      "TOP_DEFERRED_DEBIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erkennung von gestreckten Abbuchungen"])},
      "GLOBAL_TRANSACTIONS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Transaktionen"])}
    },
    "DOCUMENT_ANALYSIS": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenanalyse"])},
      "NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
      "COMPLIANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung"])},
      "DOCUMENT_CONTROL": {
        "ID_CARD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis"])},
        "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis"])},
        "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerschein"])},
        "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepass"])},
        "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufenthaltserlaubnis"])},
        "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS - Unternehmen oder Situation"])},
        "INDIVIDUAL_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KBIS - Situationserklärung"])},
        "COMPANY_KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auszug aus dem Handelsregister (Kbis)"])},
        "AUTHENTICITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentizität - Analyse der digitalen Unversehrtheit"])},
        "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuererklärung"])},
        "TAX_REPORT_S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vereinfachte Steuererklärung"])},
        "TAX_REPORT_C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["komplexe Steuererklärung"])},
        "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerbescheid - Einkommen oder Grundsteuer"])},
        "INCOME_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkommensteuer"])},
        "PROPERTY_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundsteuer"])},
        "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankdaten"])},
        "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lohnabrechnung"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])},
        "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildsuch- und Matching-Dienst"])}
      }
    },
    "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score-Details"])},
    "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Analyse"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldwäschebekämpfung und Terrorismusfinanzierung"])},
    "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte OpenBanking-Analyse"])},
    "ANALYSIS_B2B": {
      "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensinformationen"])},
      "LEGAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtliche Informationen"])},
      "CLIENT_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundeninformationen"])},
      "CORPORATE_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
      "REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsnummer"])},
      "ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "WORKFORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitskräfte"])},
      "CREATED_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
      "VAT_REGISTRATION_NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umsatzsteuer-Identifikationsnummer"])},
      "ACTIVITY_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitätscode"])},
      "LEGAL_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsform"])},
      "CAPITAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kapital"])},
      "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
      "FIELD_OF_ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätigkeitsbereich"])},
      "CLOSURE_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlussdatum"])},
      "DOMICILIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domizilierung"])},
      "REGISTRANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrator"])},
      "WEBSITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
      "REGISTRAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
      "BUSINESS_OWNERS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekannte Aktionäre und Begünstigte"])},
      "BIRTHDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "NATIONALITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalität"])},
      "POSITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikationsnummer"])},
      "WARNING_LIST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnliste Meelo"])},
      "BUSINESS_REPRESENTS_KNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekannte Manager und Vertreter"])},
      "SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte Details"])},
      "COMPANY_SCORE_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensvertrauenspunktzahl"])},
      "COMPANY_FINANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensfinanzen"])},
      "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsdokumente"])},
      "ANNUAL_ACCOUNTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresabschlüsse"])},
      "ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederlassungen"])},
      "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensentwicklung"])},
      "SCORING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzbewertungen"])},
      "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation"])},
      "OB_ANALYSIS_DETAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaillierte OpenBanking-Analyse"])},
      "ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnungen"])},
      "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Analyse"])},
      "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekämpfung von Geldwäsche und Terrorismus"])}
    },
    "COVER_PAGE": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht über durchgeführte Kontrollen"])},
      "CREATED_BY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten erstellt von"])},
      "CREATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akten erstellt am"])},
      "DOC_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensanalyse"])},
      "DOC_B2C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privatanalyse"])},
      "DOC_OB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open-Banking-Analyse"])},
      "DOC_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenanalyse"])},
      "DOC_SYNTHESIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentsynthese"])}
    }
  },
  "ENRICHMENT": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um eine Koordinatensuche zu starten, fügen Sie bitte unten die URL des LinkedIn-Profils Ihres Kontakts ein."])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenanreicherung"])},
    "NOPROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein LinkedIn-Profil gefunden."])},
    "NOCORDINATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Keine Koordinaten gefunden."])},
    "TITLE_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinaten gefunden :"])},
    "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail(s):"])},
    "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftliche E-Mail(s) :"])},
    "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche E-Mail(s) :"])},
    "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon(e):"])},
    "NO_PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Telefon gefunden"])},
    "NO_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine E-Mail gefunden"])},
    "MATCH": {
      "EMAIL_PERSO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung mit eingegebener persönlicher E-Mail"])},
      "EMAIL_PRO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung mit der eingegebenen beruflichen E-Mail"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übereinstimmung mit der eingegebenen Telefonnummer"])}
    }
  },
  "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEUHEIT"])},
  "DECLARATION": {
    "FRAUD": {
      "BUTTON_SIDEBAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["betrug melden"])},
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrugserklärung"])},
      "EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie das unten stehende Formular für Fälle von Identitätsdiebstahl oder bestätigtem Betrug aus."])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Datum des Eingangs des betrügerischen Antrags"])},
      "TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Art des Falles"])},
      "MAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Angegebene E-Mail-Adresse"])},
      "PHONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Angegebene Telefonnummer"])},
      "AMOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschätzte Betrugssumme"])},
      "URL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fall-URL"])},
      "AVOID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wurde der Betrug vermieden ?"])},
      "TOOLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit welchem/ welchen Werkzeug(en)"])},
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Elemente lassen Sie vermuten, dass es sich um Betrug handelt ?"])},
      "CONDITION_FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" * Ich akzeptiere die"])},
      "CONDITION_SECOND_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allgemeinen Nutzungsbedingungen"])},
      "CONDITION_THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" von Meelo für die manuelle Meldung von Betrug"])},
      "PROFESIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beruflich"])},
      "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat"])}
    }
  },
  "RECOMMENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Information, aber dringend empfohlen "])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DANKE"])},
  "CONFIRM_SEND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Erklärung wurde erfolgreich gesendet"])},
  "MODULE": {
    "ALTARES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altares-Punktzahl"])},
    "AMLCFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCBFT"])},
    "BUSINESS_OWNERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wirtschaftliche Eigentümer"])},
    "CHECK_IBAN_B2B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung von IBAN B2B"])},
    "COMPANY_ALERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensalarme"])},
    "COMPANY_ANNUAL_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahresabschlüsse eines Unternehmens"])},
    "COMPANY_ESTABLISHMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederlassungen eines Unternehmens"])},
    "COMPANY_EVOLUTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung eines Unternehmens"])},
    "COMPANY_FINANCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzen eines Unternehmens"])},
    "COMPANY_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensinformationen"])},
    "COMPANY_LEGAL_DOC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Dokumente eines Unternehmens"])},
    "COMPANY_SCORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensvertrauensscore"])},
    "CREDIT_SAFE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit-Safe-Score"])},
    "E_REPUTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Reputation"])},
    "OB_ALERTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open-Banking-Warnungen"])},
    "LITIGATION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtslage - Streitigkeiten"])},
      "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verurteilt am "])},
      "DEMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachfrage: "])},
      "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort: "])},
      "MAIN_HEARING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main hearing"])},
      "SUMMARY_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary procedure"])},
      "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt"])}
    },
    "FINANCIAL_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschnitt zur Finanzrisikoanalyse"])}
  },
  "CARTOGRAPHY": {
    "COMPANY_COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen im Kollektivverfahren"])},
    "BUSINESS_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmenname"])},
    "CARTOGRAPHY_OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kartographie von"])},
    "STUDIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen analysiert"])},
    "ACTIVE_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktives Unternehmen"])},
    "COLLECTIVE_PROCEDURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen im kollektiven Verfahren"])},
    "COMPANY_CARTOGRAPHY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmenskartographie"])},
    "DEREGISTERED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiertes Unternehmen"])},
    "INDIVIDUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelunternehmer"])},
    "COMPANY_ACTIVITIES_CODE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensaktivitätscode"])},
    "COMPANY_ACTIVITIES_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Unternehmensaktivität"])},
    "RECENTLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kürzlich"])},
    "UNVERIFIED_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht verifiziertes Unternehmen"])},
    "OPEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergrößern"])}
  },
  "MENU": {
    "MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Konto"])},
    "MY_CONSUPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Verbrauch"])},
    "PORTAL_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portalinformationen"])},
    "LOG_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als einloggen"])},
    "BACK_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-Office"])},
    "LOGOUT_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Kundenkonto abmelden"])},
    "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])}
  },
  "CONNECT_AS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als einloggen "])},
    "PARAGRAPH": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, sich einzuloggen als"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["des Unternehmens"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daher sehen Sie das Portal entsprechend der diesem Benutzer gewährten Berechtigungen."])},
      "FOURTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Ihre Aktionen werden unter dem Namen des Kunden und Ihrem verfolgt."])},
      "FIFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte"])},
      "SIXTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seien Sie vorsichtig"])},
      "SEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bei der Nutzung des Portals als Kunde, und"])},
      "EIGHTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["achten Sie auf die Vertraulichkeit"])},
      "NINTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["der Daten unserer Kunden."])},
      "TENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergessen Sie nicht, sich"])},
      "ELEVENTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aus dem Kundenkonto auszuloggen"])},
      "TWELFTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nachdem Ihre Aktionen abgeschlossen sind."])}
    },
    "TOAST": {
      "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht mehr angemeldet als"])},
      "LOG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind jetzt angemeldet als"])}
    },
    "BADGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angemeldet als"])},
    "MODAL": {
      "COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
      "USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])}
    }
  },
  "PORTAL_INFORMATION": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zum MEELO-Portal"])},
    "SUBTITLE": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Meelo-Portal"])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" : Eine Empfehlung auf einen Blick"])}
    },
    "DESCRIPTION": {
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Meelo-Produkte stehen Ihnen zur Verfügung, damit Sie Ihre Entscheidung in nur wenigen Minuten treffen können."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Entscheidungsunterstützungsportal ermöglicht Ihnen die Nutzung der Meelo-Produkte, die für die ordnungsgemäße Verifizierung des Kunden, der mit Ihnen in Kontakt tritt, erforderlich sind."])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraud Score, Identitätsüberprüfung, Open Banking, Dokumentenüberprüfung … Meelo-Produkte stehen Ihnen mit minimaler technischer Integration zur Verfügung."])}
    },
    "VERSION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version Ihres Portals"])},
    "ENVIRONMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfeld"])},
    "PRODUCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
    "DEVELOPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung"])},
    "STAGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inszenierung"])},
    "GDPR": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSGVO"])},
      "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir legen großen Wert auf den Schutz Ihrer personenbezogenen Daten. Gemäß der Datenschutz-Grundverordnung (DSGVO) sammeln wir nur die erforderlichen Informationen, holen Ihre ausdrückliche Zustimmung ein, verwenden Ihre Daten und die Ihrer Kunden rechtmäßig, gewährleisten deren Sicherheit, begrenzen deren Aufbewahrung und respektieren Ihre Rechte."])},
      "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Fragen oder Bedenken bezüglich dieser Datenschutzrichtlinie haben, können Sie unsere konsultieren"])},
      "THIRD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" oder wenden Sie sich an unseren Datenschutzbeauftragten unter "])}
    },
    "LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eigene Seite"])}
  },
  "MAINTENANCE": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei den folgenden Diensten können Probleme auftreten: "])},
    "FINISHED_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denken Sie daran, Ihr Portal aufzufrischen, damit es optimal genutzt werden kann."])},
    "ONGOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufende Wartung"])},
    "FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beendete Wartung"])},
    "SERVICE": {
      "AML_CFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst zur Bekämpfung von Geldwäsche und Terrorismusfinanzierung"])},
      "CHECKOUT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronischer Signaturdienst"])},
      "DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenten-Upload-Dienst"])},
      "DOCUMENT_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenkontrolldienst"])},
      "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externer Dokumentationsdienst"])},
      "IAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungsdienst"])},
      "OPEN_BANKING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Banking Dienst"])},
      "PORTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Portal"])},
      "RULES_ENGINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regelmaschine"])},
      "SCORING_PROXY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoringdienst"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interne Aufgabenverwaltungsdienst"])},
      "TAX_NOTICE_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuermeldungsanalyse Dienst"])},
      "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuermeldungsanalyse Dienst"])},
      "TRANSLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Übersetzungsdienst"])},
      "SHORTENER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner URL-Kürzungsdienst"])},
      "CHECK_COMPANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensbereicherungsdienst"])},
      "UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Dokumenten-Upload-Dienst"])},
      "TRACKETIO_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meelo Trackingelement-Analyse Dienst"])},
      "STORAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Dokumentenspeicherdienst"])},
      "ALERTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnmeldedienst"])},
      "TRACKETIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzertrackingdienst im Browser"])},
      "OPEN_REVENUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offener Umsatzdienst"])},
      "ENRICHMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenanreicherungsdienst"])},
      "FACE_MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildsuch- und Matching-Dienst"])},
      "CHECK_IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Überprüfungsdienst"])}
    }
  },
  "FOLDER": {
    "WORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akte"])},
    "STATE": {
      "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptiert"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
      "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wartend"])},
      "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert"])},
      "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöscht"])}
    },
    "DELETION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen des Ordners"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei, den"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und die Daten, die es bilden"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diesen Ordner löschen möchten?"])},
        "QUESTION_MOTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie dieses Motiv löschen möchten?"])}
      }
    },
    "EDITION": {
      "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung des Dossierstatus"])},
      "CONTENT": {
        "FIRST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind dabei zu"])},
        "SECOND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Eingabe in diesem Dossier nicht mehr fortsetzen"])},
        "QUESTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie den Status des Dossiers ändern möchten?"])}
      },
      "STATE": {
        "IN_PROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Gang setzen"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akzeptieren"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ablehnen"])},
        "WAITING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warten"])},
        "CANCELED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stornieren"])},
        "DELETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["löschen"])}
      }
    },
    "UPDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status des Dossiers aktualisiert"])}
  },
  "NUMBER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr."])},
  "NOTIFICATIONS": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
    "NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benachrichtigungen."])},
    "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu "])},
    "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesen "])},
    "MARK_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLES ALS GELESEN MARKIEREN"])},
    "DELETE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALLE LÖSCHEN"])},
    "DATE": {
      "TODAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HEUTE"])},
      "YESTERDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GESTERN"])},
      "OLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALT"])}
    },
    "TIMESTAMP": {
      "NOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerade jetzt"])},
      "PREFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vor"])},
      "SUFFIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "MINUTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])},
      "MINUTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])},
      "HOUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunde"])},
      "HOURS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])}
    },
    "ACTIONS": {
      "CONFIRM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "SERVICES": {
      "OPEN_BANKING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking-Analyse abgeschlossen"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenBanking-Analyse"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Analyse des OpenBanking-Dienstes für das Unternehmen"])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen. Klicken Sie auf die Benachrichtigung, um das Ergebnis zu sehen."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der OpenBanking-Analyse ist ein Problem aufgetreten. Klicken Sie auf die Benachrichtigung, um den Fall einzusehen."])}
      },
      "LIVE_CHECK": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifel ausgeräumt - Analyse abgeschlossen"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zweifel ausgeräumt - Analyse fehlgeschlagen"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zweifel am Dossier "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sind ausgeräumt."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sind ausgeräumt. Klicken Sie auf die Benachrichtigung, um das Ergebnis anzusehen."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gab ein Problem bei der Ausräumung der Zweifel. Klicken Sie auf die Benachrichtigung, um das Dossier einzusehen."])}
      },
      "BATCH_CHECK_COMPANY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensanalyse - Batch abgeschlossen"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmensanalyse - Batch fehlgeschlagen"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Batch der Unternehmensanalyse "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Batch der Unternehmensanalyse hat ein Problem festgestellt."])}
      },
      "BATCH_CHECK_IBAN": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Analyse - Batch abgeschlossen"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN-Analyse - Batch fehlgeschlagen"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Batch der IBAN-Analyse "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Batch der IBAN-Analyse hat ein Problem festgestellt."])}
      },
      "BATCH_SCORING_PROXY": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch abgeschlossen"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoring - Batch fehlgeschlagen"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Batch der Scoring-Analyse "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Batch der Scoring-Analyse hat ein Problem festgestellt."])}
      },
      "FACE_MATCHING": {
        "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische Überprüfung - Analyse abgeschlossen"])},
        "ERROR_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biometrische Überprüfung - Analyse fehlgeschlagen"])},
        "PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die biometrische Überprüfung des Dossiers "])},
        "POP_SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen."])},
        "SUFFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist abgeschlossen. Klicken Sie auf die Benachrichtigung, um das Ergebnis einzusehen."])},
        "ERROR_PREFIX_MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die biometrische Überprüfung ist auf ein Problem gestoßen. Klicken Sie auf die Benachrichtigung, um das Dossier einzusehen."])}
      }
    }
  },
  "STATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staat"])},
  "OF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "THE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["der"])},
  "SCORE_CREDIT_SAFE": {
    "RISK_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzielles Risiko"])},
    "PAYMENT_TREND_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungstrend"])},
    "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten gefunden."])},
    "OTHER_INFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere zusätzliche Informationen"])},
    "PRESENCEOFJUDGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwesenheit des Urteils"])},
    "PRESENCEOFPRIVILEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein von Privilegien"])},
    "PRESENCEOFSANCTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorhandensein der DGCCRF-Sanktion"])},
    "HEADBAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um mehr über die finanzielle Lage dieses Unternehmens zu erfahren, aktivieren Sie bitte Ihr CreditSafe-Abonnement."])},
    "RISK": {
      "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geringes finanzielles Risiko"])},
      "MODERATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderates finanzielles Risiko"])},
      "HIGH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohes finanzielles Risiko"])},
      "NOT_SCORED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht nachweisbar"])}
    },
    "PAYMENT_TREND": {
      "WORSENING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschlechterung des Zahlungstrends"])},
      "IMPROVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungstrend verbessert sich"])},
      "STABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stabiler Zahlungstrend"])},
      "NOT_AVAILABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungstrend nicht verfügbar"])},
      "NO_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht nachweisbarer Zahlungstrend"])}
    }
  },
  "MALICIOUS_PAYLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Eingabedaten"])},
  "RESET_API_KEY": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr API-Schlüssel"])},
    "WARNING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihren neuen API-Schlüssel nur einmal ansehen"])},
    "CANNOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihren API-Schlüssel nicht mehr anzeigen. Bitte wenden Sie sich an Ihren Ansprechpartner oder den Meelo-Support"])},
    "COPY_YOUR_KEY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihren Schlüssel kopieren"])}
  },
  "DQE_CONTROL": {
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgleich der Telefonleitung - Mobile ID"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten, die Suche konnte nicht abgeschlossen werden."])},
    "NO_SEARCH_RULE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Telefonnummer kann derzeit vom Betreiber nicht überprüft werden."])},
    "MATCHING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgleich"])},
    "KEY": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postadresse"])}
    },
    "LAUNCH_MATCHING_SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching-Suche starten"])},
    "NO_GLOBAL_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Überprüfung wurde durchgeführt, konnte jedoch nicht abgeschlossen werden."])}
  },
  "MOTIVE": {
    "THIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundverwaltung"])},
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "TABLE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ACTIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
      "NODATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein bestehender Grund."])}
    },
    "MODAL": {
      "CREATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund hinzufügen"])},
      "UPDATE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund bearbeiten"])},
      "DELETE_TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grund löschen"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezeichnung"])},
      "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
      "SAVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
      "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "TOAST": {
      "SUCCESS": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muster erfolgreich erstellt"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muster erfolgreich bearbeitet"])},
        "DELETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muster erfolgreich gelöscht"])}
      },
      "FAILED": {
        "CREATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Erstellung des Musters"])},
        "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler bei der Bearbeitung des Musters"])}
      }
    }
  }
}