import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page-table__size-select"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListTable = _resolveComponent("ListTable")!
  const _component_PageTablePagination = _resolveComponent("PageTablePagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.hasPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createTextVNode(_toDisplayString(_ctx.translate('TABLE.PER_PAGE')) + " ", 1),
          _createElementVNode("select", {
            value: _ctx.page.size.toString(),
            onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateSize(parseInt($event.target.value)))),
            class: "page-table__select"
          }, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList([5, 10, 20, 50, 100], (size) => {
              return _createElementVNode("option", {
                key: size,
                value: size
              }, _toDisplayString(size), 9, _hoisted_3)
            }), 64))
          ], 40, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ListTable, {
      content: _ctx.page.content,
      columns: _ctx.columns,
      white: _ctx.white
    }, null, 8, ["content", "columns", "white"]),
    (_ctx.hasPagination)
      ? (_openBlock(), _createBlock(_component_PageTablePagination, {
          key: 1,
          page: _ctx.page
        }, null, 8, ["page"]))
      : _createCommentVNode("", true)
  ]))
}