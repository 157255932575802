/**@component BoxOutputScoreCompany **/

import {values} from "lodash";

export enum AlertDetailsEnum {
  OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS = "OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS",
  OTHER_COMPANIES_IN_RADIATION = "OTHER_COMPANIES_IN_RADIATION",
  HEADQUARTER_ADDRESS_IN_WARNING_LIST = "HEADQUARTER_ADDRESS_IN_WARNING_LIST"
}

export const AlertDetailsEnumList: AlertDetailsEnum[] = values(AlertDetailsEnum);

export interface AlertDetailsButton {
  [key: string]: boolean;
}

export interface AlertDetails {
  [key: string]: string[];
}

/**
 * Transforms a given string from UPPER_SNAKE_CASE to camelCase.
 *
 * @example
 * // returns 'otherCompaniesInCollectiveProceedings'
 * formatAlertObjectName('OTHER_COMPANIES_IN_COLLECTIVE_PROCEEDINGS');
 *
 * @deprecated This function is deprecated and will be no longer usable in the future, use lodash's camelCase() method instead.
 *
 * @param {string} str - The string to be transformed from UPPER_SNAKE_CASE to camelCase.
 * @returns {string} The transformed string in camelCase.
 */
export const formatAlertObjectName = (str: string) => {
  return str.toLowerCase().replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
}

