<script setup lang="ts">
import { ref, watch } from 'vue';
import {translate} from "../../i18n";

const props = withDefaults(
    defineProps<{
      color: 'dark' | 'light',
      value: string,
      label: string,
      placeholder: string,
      rows: number,
      required: boolean,
      readOnly: boolean,
      disabled: boolean,
      highlight: boolean,
      customClass: string,
      maxLength: number,
    }>(), {
      color: 'dark',
      value: '',
      label: '',
      placeholder: '',
      rows: 3,
      required: false,
      readOnly: false,
      disabled: false,
      highlight: false,
      customClass: '',
      maxLength: 250
    }
);

const emit = defineEmits(['update:value']);

const error = ref('');
const localValue = ref(props.value);

// Watch for value changes and update localValue
watch(() => props.value, (newValue) => {
  localValue.value = newValue;
});

// Emit changes to the parent component
watch(localValue, (newValue) => {
  emit('update:value', newValue);
});

// Validation for maxLength
const isMaxLengthReached = ref(false);
const handleInput = (event: Event) => {
  if (props.maxLength && localValue.value.length >= props.maxLength) {
    isMaxLengthReached.value = true;
  } else {
    isMaxLengthReached.value = false;
  }
};
</script>

<template>
  <div class="text-area-div">
    <label :class="[color, customClass, 'label']">{{ label }}{{ required ? '*' : '' }}</label>
    <div class="input-error">
      <textarea class="form-text-area text-area"
                v-model="localValue"
                :placeholder="placeholder"
                :required="required"
                :readonly="readOnly"
                :disabled="disabled"
                :rows="rows"
                :maxlength="props.maxLength"
      :class="highlight ? 'highlight' : ''"
      @input="handleInput">
      </textarea>
      <slot></slot>
      <span v-if="error !== ''" class="error-tag">{{ error }}</span>
    </div>
  </div>
</template>


<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.text-area-div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .label {
    font-weight: lighter;
    padding: 0 0 0 0.2rem;
    font-size: $fontSize;

    &.light {
      color: white;
    }

    &.dark {
      color: $loginColor2;
    }
  }
}

.input-error {
  display: flex;
  flex-direction: column;
}

.form-text-area {
  display: flex;
  color: rgb(60, 66, 87);
  flex-grow: 1;
  width: 15rem;
  padding: 4px 8px;
  min-height: 28px;
  max-height: 100px;
  margin-left: .2rem;
  border-radius: 5px;
  border: 1px solid #000000;
}

.error-tag {
  border: solid 0;
  border-radius: 5px;
  font-size: $fontSizeError;
  font-weight: bold;
  background-color: $redMeelo;
  color: white;
  margin: 0.4vh 0;
  padding: 0 0.3vw;
}

.text-area-div .input-error {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}


.highlight {
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(58 151 212 / 36%) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
  rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px;
}
</style>