<script setup lang="ts">
import {watch, ref} from "vue";

const props = withDefaults(
    defineProps<{
      title?: string,
      checkedDisplayContent?: boolean,
      displayArrows?: boolean
    }>(),
    {
      title: '',
      checkedDisplayContent: true,
      displayArrows: true
    }
);

const checkedDisplayCt = ref(props.checkedDisplayContent);

const switchDisplay = () => {
  checkedDisplayCt.value = !checkedDisplayCt.value;
}

watch(() => props.checkedDisplayContent, () => {
  checkedDisplayCt.value = props.checkedDisplayContent;
});

</script>

<template>
  <div class="result-card-block breakinside">
    <div class="result-card-block-header" @click="switchDisplay">
      <div class="result-card-block-header-title">
        <span :id="checkedDisplayCt" class="result-card-block-header-title__title">{{ title }}</span>
        <div v-if="displayArrows">
          <img v-if="checkedDisplayCt" class="arrow no-print"
               :src="require('@/../public/images/unfold-blue.png')" alt="unfold">
          <img v-else class="arrow no-print" :src="require('@/../public/images/fold-blue.png')" alt="fold">
        </div>
      </div>
      <hr class="result-card-block-header-divider">
    </div>
    <div v-if="checkedDisplayCt" class="result-card-block-content">
      <slot/>
    </div>
  </div>
</template>