<script setup lang="ts">
import { translate } from "@/i18n";
import {ref} from "vue";

const props = withDefaults(
    defineProps<{
      label: string;
      color: string;
      disabled: boolean
    }>(), {
      label: '',
      color: '',
      disabled: false
    }
);

const color = ref(props.color);
</script>

<template>
  <button :class="`top-button ${disabled ? 'top-button-disabled' : ''}`" :disabled="disabled">{{ translate(label) }}</button>
</template>

<style scoped lang="scss">
.top-button {
  border-bottom: 1px solid v-bind(color);
}
</style>