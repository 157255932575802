import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-card" }
const _hoisted_2 = { class: "user-card__title" }
const _hoisted_3 = {
  key: 0,
  class: "user-card__content"
}
const _hoisted_4 = { class: "user-card__content__general-information__user" }
const _hoisted_5 = {
  key: 0,
  class: "user-card__content__general-information__edit-action"
}
const _hoisted_6 = { class: "user-card__content__right-block" }
const _hoisted_7 = { class: "user-card__content__right-block__actions__reset-modal-message" }
const _hoisted_8 = { class: "user-card__content__right-block__actions__reset-modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BackOfficeUserModal = _resolveComponent("BackOfficeUserModal")!
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_CustomModal = _resolveComponent("CustomModal")!
  const _component_ResetApiKeyModal = _resolveComponent("ResetApiKeyModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      label: _ctx.translate('BUTTONS.RETURN'),
      class: "user-card__return",
      onClick: _ctx.goToManageUser
    }, null, 8, ["label", "onClick"]),
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.translate('BACKOFFICE.USERS.USER_PROFILE')), 1),
    (_ctx.contentUserCard)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_ResultCard, {
            title: _ctx.translate('ACCOUNT_PAGE.GENERAL_INFORMATION'),
            class: "user-card__content__general-information"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.COMPANY')} : ${_ctx.user?.company.name ? _ctx.user?.company.name : ''}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('IDENTITY.NAME')} : ${_ctx.contentUserCard?.username ? _ctx.contentUserCard?.username : ''}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('EMAIL')} : ${_ctx.contentUserCard?.email ? _ctx.contentUserCard?.email : ''}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('PHONE')} : ${_ctx.contentUserCard?.phone ? _ctx.contentUserCard?.phone : ''}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.FUNCTION')} : ${_ctx.contentUserCard?.function ? _ctx.contentUserCard?.function : ''}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.PLACE')} : ${_ctx.contentUserCard?.place ? _ctx.contentUserCard?.place : ''}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.GROUP')} : ${_ctx.contentUserCard?.group ? _ctx.contentUserCard?.group : ''}`), 1),
                _createElementVNode("span", null, [
                  _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.SUPERVISOR')} : `), 1),
                  _createVNode(_component_router_link, {
                    to: "",
                    onClick: _ctx.displaySupervisor
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(`${_ctx.contentUserCard?.supervisor ? _ctx.contentUserCard?.supervisor.username : ''}`), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.ADVANCED_USER')} : ${_ctx.contentUserCard?.roles && _ctx.contentUserCard?.roles.includes('COMPANY_USER') ? _ctx.translate('NO') : _ctx.translate('YES')}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.ACTIVE_ACCOUNT')} : ${_ctx.contentUserCard?.enabled ? _ctx.translate('YES') : _ctx.translate('NO')}`), 1),
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.PROFILE')} : `) + " ", 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.getRoles), 1)
                ])
              ]),
              (_ctx.compareRole(_ctx.user, _ctx.contentUserCard))
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_Button, {
                      label: _ctx.translate('BUTTONS.MODIFY'),
                      onClick: _ctx.openEditModal
                    }, null, 8, ["label", "onClick"]),
                    (_ctx.contentEditModal)
                      ? (_openBlock(), _createBlock(_component_BackOfficeUserModal, {
                          key: 0,
                          "modal-flag": _ctx.editUserModalFlag,
                          content: _ctx.contentEditModal,
                          step: _ctx.firstStepEdit,
                          onValidateAction: _ctx.validateUpdateUser,
                          onNextStep: _ctx.nextStep,
                          onCancelAction: _ctx.cancelUpdateUser,
                          supervisors: _ctx.supervisors,
                          "check-box": _ctx.checkBoxRoleUpdate,
                          "onUpdate:checkBox": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkBoxRoleUpdate) = $event)),
                          places: _ctx.places,
                          groups: _ctx.groups,
                          user: _ctx.user,
                          title: _ctx.translate('BACKOFFICE.USERS.USER_MODIFICATION'),
                          profiles: _ctx.profiles,
                          "profiles-checked": _ctx.profilesUser,
                          "onUpdate:profilesChecked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.profilesUser) = $event)),
                          onBackFirstPage: _ctx.nextStep
                        }, null, 8, ["modal-flag", "content", "step", "onValidateAction", "onNextStep", "onCancelAction", "supervisors", "check-box", "places", "groups", "user", "title", "profiles", "profiles-checked", "onBackFirstPage"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["title"]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ResultCard, {
              title: _ctx.translate('BACKOFFICE.USERS.TECHNICAL_ACTIONS'),
              class: "user-card__content__right-block__actions"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Button, {
                  label: _ctx.translate('BUTTONS.PASSWORD_RESET'),
                  class: "user-card__content__right-block__actions__reset",
                  onClick: _ctx.resetPasswordModal,
                  disabled: _ctx.canDoAction(_ctx.user)
                }, null, 8, ["label", "onClick", "disabled"]),
                _createVNode(_component_CustomModal, {
                  open: _ctx.resetPasswordModalFlag,
                  "onUpdate:open": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.resetPasswordModalFlag) = $event)),
                  class: "backoffice-user-table__delete-modal"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ResultCard, {
                      title: _ctx.translate('BACKOFFICE.USERS.RESET_PASSWORD.TITLE')
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.RESET_PASSWORD.MESSAGE_1')} ${_ctx.contentUserCard?.username ? _ctx.contentUserCard?.username : ''}
                      ${_ctx.translate('BACKOFFICE.USERS.RESET_PASSWORD.MESSAGE_2')} ${_ctx.contentUserCard?.email ? _ctx.contentUserCard?.email : ''}`), 1)
                        ]),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_Button, {
                            label: _ctx.translate('BUTTONS.CANCEL'),
                            class: "backoffice-user-table__delete-modal__actions__cancel",
                            onClick: _ctx.resetPasswordModal
                          }, null, 8, ["label", "onClick"]),
                          _createVNode(_component_Button, {
                            label: _ctx.translate('BUTTONS.CONFIRM'),
                            class: "user-card__content__right-block__actions__reset-modal-buttons__validate",
                            onClick: _ctx.validateResetPassword
                          }, null, 8, ["label", "onClick"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["title"])
                  ]),
                  _: 1
                }, 8, ["open"]),
                _createVNode(_component_Button, {
                  label: _ctx.translate('BUTTONS.ACTIVATE_DEACTIVATE_ACCOUNT'),
                  class: "user-card__content__right-block__actions__deactivate-account",
                  onClick: _ctx.activateDeactivateAccount
                }, null, 8, ["label", "onClick"]),
                _createVNode(_component_Button, {
                  label: _ctx.translate('BUTTONS.RESETTING_API_KEY'),
                  class: "user-card__content__right-block__actions__resetting-api",
                  disabled: _ctx.canDoAction(_ctx.user),
                  onClick: _ctx.resetApiKeyModal
                }, null, 8, ["label", "disabled", "onClick"]),
                _createVNode(_component_ResetApiKeyModal, {
                  "open-modal": _ctx.resetApiKeyModalFlag,
                  "onUpdate:openModal": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.resetApiKeyModalFlag) = $event)),
                  onValidateResetApiKey: _ctx.validateResetApiKey,
                  "connected-user-id": _ctx.user.id,
                  "content-user-card": _ctx.contentUserCard
                }, null, 8, ["open-modal", "onValidateResetApiKey", "connected-user-id", "content-user-card"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_ResultCard, {
              title: _ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.TITLE'),
              class: "user-card__content__right-block__two-authentication"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.REQUIRED')} : ${_ctx.contentUserCard?.isTwoFactorAuthenticationRequiredByCompany ? _ctx.translate('YES') : _ctx.translate('NO')}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.TITLE')} : ${_ctx.translate(_ctx.contentUserCard?.twoFactorAuthenticationStatus ? `ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.${_ctx.contentUserCard?.twoFactorAuthenticationStatus}` : `ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.MODE.DISABLED`)}`), 1),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('ACCOUNT_PAGE.TWO_FACTOR_AUTHENTICATION.CHECKED')} : ${_ctx.contentUserCard?.isTwoFactorAuthenticationChecked ? _ctx.translate('YES') : _ctx.translate('NO')}`), 1)
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}