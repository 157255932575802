import api from '@/utils/api';
import envConfig from '@/utils/envConfig';

export const searchWarningList = (journeyId: string, listName: string, operator: string, data: {
  COMPANY?: string,
  REGISTRATION_NUMBER?: string,
  EMAIL?: string,
  PHONE?: string,
  COUNTRY?: string
}, label: string) =>
    api.post(`${envConfig.CHECK_VALUE_ROUTE}/v1/search/${listName}?journeyId=${journeyId}&label=${label}`, {
      'operator': operator,
      'rowValues': data
    })
    .then(response => response);

export const getAllLists = () =>
    api.get(`${envConfig.CHECK_VALUE_ROUTE}/v1/lists`)
    .then(response => response.data);
