<script setup lang="ts">

import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import {ref} from "vue";

const props = withDefaults(
    defineProps<{
      caseNumber: string;
      caseState: string
    }>(), {
      caseNumber: '',
      caseState: ''
    }
);

const openModal = defineModel('openModal', {default: false, type: Boolean});
const emit = defineEmits(['confirm-selection', 'close-modal']);

const closeModal = () => {
  emit('close-modal');
  openModal.value = !openModal.value;
}

const confirmModal = () => {
  emit('confirm-selection');
}
</script>

<template>
  <CustomModal v-model:open="openModal">
    <ResultCard :title="translate('FOLDER.EDITION.TITLE')" :display-arrows="false"
                class="delete-folder-modal edit-folder-modal">
      <p class="delete-folder-modal__first-paragraph">
        <span>{{
            `${translate('FOLDER.EDITION.CONTENT.FIRST')} ${translate(`FOLDER.EDITION.STATE.${caseState}`)} ${translate('THE')}`
          }}</span>
        <span class="delete-folder-modal__first-paragraph__underline">{{
            ` ${translate('FOLDER.WORD')} ${translate('NUMBER')} ${caseNumber}`
          }}</span>
      </p>
      <p v-if="!['WAITING', 'IN_PROGRESS'].includes(caseState)" class="delete-folder-modal__first-paragraph__underline delete-folder-modal__first-paragraph__bold">
        {{ translate('FOLDER.EDITION.CONTENT.SECOND') }}</p>
      <p class="delete-folder-modal__third-paragraph">{{ translate('FOLDER.EDITION.CONTENT.QUESTION') }}</p>
      <div class="delete-folder-modal__actions edit-folder-modal__actions">
        <Button :label="translate('BUTTONS.CONFIRM')" @click="confirmModal"
                class="edit-folder-modal__actions__confirm"/>
        <Button :label="translate('BUTTONS.CLOSE')" @click="closeModal"/>
      </div>
    </ResultCard>
  </CustomModal>
</template>