<template>
    <div class="end-of-journey">
        <div class="x-mas-welcome-top">
            <img src="../../../public/images/x-mas/star-2.png" alt="">
            <img src="../../../public/images/x-mas/house.png" alt="">
            <img class="top-end" src="../../../public/images/x-mas/star-1.png" alt="">
        </div>
        <div class="x-mas-welcome-title">
            <span>Meelo vous souhaite de passer d’excellentes fêtes de fin d’année.</span>
        </div>
        <div class="x-mas-welcome-bottom">
            <img src="../../../public/images/x-mas/sapin.png" alt="">
            <img src="../../../public/images/x-mas/cerf.png" alt="">
        </div>
    </div>
</template>