import {OwnerRepresentative, OwnerRepresentativeConfig} from "@/types/OwnerRepresentative";
import {isEmpty, isNil} from "lodash";
import {formatLocaleDate, translate} from "@/i18n";
import {getFormattedRegistrationNumber} from "@/utils/formatRegistrationNumber";
import {Address} from "@/types/Address";

export function useOwnerRepresentative() {
  const displayButton = (person: OwnerRepresentativeConfig, path: string, permissions: boolean) => {
    return permissions && path !== '/synthesis' && (person?.type ? (person.type === 'Natural Person') : true) && !person.isNotSelectable;
  };

  const getAddress = (person: OwnerRepresentative) => {
    const address = person?.address?.address ? person?.address.address : '';
    const zipcode = person?.address?.zipcode ? person?.address.zipcode : '';
    const city = person?.address?.city ? person?.address.city : '';
    return `${address} ${zipcode} ${city}`;
  };

  const getDataPerson = (person: OwnerRepresentative, type: string) => {
    const lastName = person?.lastName
      ? person?.lastName
      : person?.birthName;
    const data = {
      names: {
        value: `${person?.firstNames ?? ''} ${lastName}`.trim(),
        class: 'owner-representative-content-information-names',
        display: !isNil(person?.firstNames) || !isEmpty(lastName)
      },
      corporateName: {
        value: person?.corporateName,
        class: 'owner-representative-content-information-names',
        display: !isNil(person?.corporateName)
      },
      position: {
        value: person?.position,
        class: '',
        display: !isNil(person?.position)
      },
      birth_date: {
        value: formatLocaleDate(person?.dateOfBirth),
        class: type === 'businessOwner' ? 'business-owners-content-information-further' : '',
        display: !isNil(person?.dateOfBirth)
      },
      nationality: {
        value: person?.nationality,
        class: type === 'businessOwner' ? 'business-owners-content-information-further' : '',
        display: !isNil(person?.nationality)
      },
      registrationNumber: {
        value: `${translate('COMPANY.SIREN')} : ${getFormattedRegistrationNumber(person?.registrationNumber as string)}`,
        class: '',
        display: !isNil(person?.registrationNumber),
        copy_to_clipboard: true,
      },
      address: {
        value: getAddress(person),
        class: '',
        display: person?.address && (person?.address?.address || person?.address?.zipcode || person?.address?.city),
        copy_to_clipboard: true,
        link: true
      },
      accession_date: {
        value: person?.accessionDate
          ? `${translate('COMPANY.IN_POSITION_SINCE')} ${formatLocaleDate(person?.accessionDate)}`
          : false,
        class: 'owner-representative-content-information-further',
        display: !isNil(person?.accessionDate)
      },
      direct_share_holding: {
        value: person?.directShareHolding
          ? translate('COMPANY.DIRECT_BUSINESS_OWNER')
          : false,
        class: 'owner-representative-content-information-further',
        display: !isNil(person?.directShareHolding) && person?.directShareHolding === true
      },
      indirect_share_holding: {
        value: person?.directShareHolding
          ? false
          : translate('COMPANY.INDIRECT_BUSINESS_OWNER'),
        class: 'owner-representative-content-information-further',
        display: !isNil(person?.directShareHolding) && person?.directShareHolding === false
      },
    };
    return Object.fromEntries(Object.entries(data).filter(([key, value]) => value.display));
  }

  //for representative
  const getUrlGoogleMaps = (address: Address) => {
    if (address) return `https://www.google.fr/maps/search/${`${address.address} ${address.zipcode} ${address.city}`.replaceAll(' ', '+')}`;
  }

  //for business owner
  const getShareVote = (person: OwnerRepresentative) => {
    const data = {
      share_holding: person.totalShareHolding
        ? `${person.totalShareHolding}% ${translate('COMPANY.OF_SHARES')}`
        : false,
      vote_power: person.totalVotePower
        ? `${person.totalVotePower}% ${translate('COMPANY.OF_VOTES')}`
        : false
    };
    return Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== false));
  }

  return {displayButton, getAddress, getDataPerson, getUrlGoogleMaps, getShareVote}
}