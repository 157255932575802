<script setup lang="ts">
import BoxOutputScoreCreditSafeV1 from "@/components/box-output/score-credit-safe/BoxOutputScoreCreditSafeV1.vue";
import BoxOutputScoreCreditSafeV2 from "@/components/box-output/score-credit-safe/BoxOutputScoreCreditSafeV2.vue";
import Loader from "@/components/loader/Loader.vue";
import {translate} from "@/i18n";
import {computed} from "vue";
import {isEmpty, isNumber, isObject} from "lodash";
import ContentCard from "@/components/card/ContentCard.vue";
import Button from "@/components/button/Button.vue";
import {causesCreditSafeType} from "@/types/creditSafe";

const props = withDefaults(
    defineProps<{
      causesCreditSafe: causesCreditSafeType,
      isCompanyFR: boolean,
      isDataLoading?: boolean,
      isError?: boolean,
      scoreCreditSafe: number;
      colorCreditSafeLoc: string; //V1
      colorCreditSafeInter: string; //V1
      canCreditSafe: boolean
    }>(), {
      isCompanyFR: false,
      isDataLoading: false,
      isError: false,
      colorCreditSafeLoc: "#D9D9D9",
      colorCreditSafeInter: "#D9D9D9",
      canCreditSafe: false
    });

const emit = defineEmits(['compute-credit-safe-score']);

const computeCreditSafeScore = () => {
  emit('compute-credit-safe-score');
}

const isV1 = computed(() => {
  return !props.causesCreditSafe.hasOwnProperty('presenceOfJudgment');
});

const isResult = computed(() => {
  if(props.isError || props.causesCreditSafe?.hasOwnProperty('data') && props.causesCreditSafe.data?.status === 'FAILED') {
    return false;
  }
  return !isEmpty(props.causesCreditSafe) || isNumber(props.scoreCreditSafe);
});

const canShowButton = computed(() => {
  return !(isObject(props.causesCreditSafe) && Object.keys(props.causesCreditSafe).length > 0);
});

</script>

<template>
  <ContentCard v-if="!canCreditSafe" :title="translate('MODULE.FINANCIAL_RISK')"
               class="box-output-score-credit-safe"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else-if="!isResult">
    <ContentCard v-if="canCreditSafe" class="box-output-score-credit-safe" :title="translate('MODULE.FINANCIAL_RISK')">
      <div v-if="isDataLoading">
        <Loader/>
      </div>
      <div v-else-if="canShowButton"
           class="box-output-score-credit-safe_button">
        <Button
            :label="translate('BUTTONS.RUN_ANALYSIS')"
            @click="computeCreditSafeScore"
            :disabled="isError"
        />
      </div>
      <span v-else-if="isError || !isDataLoading" class="box-output-score-credit-safe__error">{{ translate("ERRORS.CREDIT_SAFE") }}</span>
    </ContentCard>
  </template>
  <template v-else>
    <BoxOutputScoreCreditSafeV1
        v-if="isV1"
        :scoreCreditSafe="scoreCreditSafe"
        :causesCreditSafe="causesCreditSafe"
        :color-credit-safe-loc="colorCreditSafeLoc"
        :color-credit-safe-inter="colorCreditSafeInter"
        :can-credit-safe="canCreditSafe"/>
    <BoxOutputScoreCreditSafeV2
        v-else
        :causes-credit-safe="causesCreditSafe"
        :is-company-f-r="isCompanyFR"
        :is-data-loading="isDataLoading"
        :is-error="isError"
        @compute-credit-safe-score="computeCreditSafeScore"
        :can-credit-safe="canCreditSafe"/>
  </template>
</template>