<script setup lang="ts">
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import Button from "@/components/button/Button.vue";
import {translate} from "@/i18n";

const openModal = defineModel('openModal', {default: false, type: Boolean});
const props = withDefaults(
    defineProps<{
      connectedUserId?: number,
      contentUserCard?: any
    }>(),
    {}
);

const emit = defineEmits(["validateResetApiKey"]);

const resetApiKeyUser = () => {
  return props.contentUserCard?.userId !== props.connectedUserId;
}

const resetApiKeyModal = () => {
  openModal.value = !openModal.value;
}
</script>

<template>
  <CustomModal v-model:open="openModal">
    <ResultCard :title="translate('BACKOFFICE.USERS.RESET_API_KEY.TITLE')">
      <div v-if="resetApiKeyUser()" class="user-card__content__right-block__actions__reset-modal-message">
        <span>{{
            `${translate('BACKOFFICE.USERS.RESET_API_KEY.CONFIRM_RESET_USER.FIRST')} ${contentUserCard?.username ? contentUserCard?.username : ''}
                      ${translate('BACKOFFICE.USERS.RESET_API_KEY.CONFIRM_RESET_USER.SECOND')} ${contentUserCard?.email ? contentUserCard?.email : ''}`
          }}</span>
        <span class="user-card__content__right-block__actions__reset-modal-message__important">
          {{ translate('BACKOFFICE.USERS.RESET_API_KEY.CONFIRM_RESET_USER.THIRD') }}
        </span>
      </div>
      <div v-else class="user-card__content__right-block__actions__reset-modal-message">
                <span class="user-card__content__right-block__actions__reset-modal-message-text">{{
                    translate('BACKOFFICE.USERS.RESET_API_KEY.CONFIRM_RESET_US.FIRST')
                  }}</span>
        <span class="user-card__content__right-block__actions__reset-modal-message-text">{{
            translate('BACKOFFICE.USERS.RESET_API_KEY.CONFIRM_RESET_US.SECOND')
          }}</span>
        <span class="user-card__content__right-block__actions__reset-modal-message-text">{{
            translate('BACKOFFICE.USERS.RESET_API_KEY.CONFIRM_RESET_US.THIRD')
          }}</span>
        <span class="user-card__content__right-block__actions__reset-modal-message-text">{{
            translate('BACKOFFICE.USERS.RESET_API_KEY.CONFIRM_RESET_US.FOUR')
          }}</span>

      </div>
      <div class="user-card__content__right-block__actions__reset-modal-buttons">
        <Button :label="translate('BUTTONS.CANCEL')"
                class="backoffice-user-table__delete-modal__actions__cancel"
                @click="resetApiKeyModal"/>
        <Button :label="translate('BUTTONS.CONFIRM')"
                class="user-card__content__right-block__actions__reset-modal-buttons__validate"
                @click="emit('validateResetApiKey')"/>
      </div>
    </ResultCard>
  </CustomModal>

</template>