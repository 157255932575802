<template>
  <table :class="{'page-table': true, 'page-table__white':white}">
    <thead>
    <tr>
      <th v-for="{title} in columns" :key="title">{{ title }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(line, indexLine) in renderedContent" :key="indexLine">
      <td v-for="({html, value, component, events, props}, indexColumn) in line"
          :key="indexColumn"
      >
        <template v-if="html"
                  v-on="events"
                  v-html="html"
                  v-bind="props"
        />
        <template v-else-if="value"
                  v-on="events"
                  v-bind="props"
        >
          {{ value }}
        </template>
        <component v-else-if="component"
                   :is="component"
                   :item="content[indexLine]"
                   v-on="events"
                   v-bind="props"
        />
      </td>
    </tr>
    </tbody>
  </table>
</template>
<script lang="ts">
import {defineComponent} from 'vue';

interface RenderedItem {
  value?: any;
  html?: any;
  component?: any;

  events?: any;
  props?: any;
}

export interface Column<T = any> {
  title: string;
  render: (item: T) => RenderedItem;
}

export default defineComponent({
  name: 'ListTable',
  props: {
    content: {type: Array, required: true},
    columns: {type: Array as () => Column[], required: true},
    white: {type: Boolean, default: false}
  },
  data: () => ({
    renderedContent: undefined as RenderedItem[][] | undefined
  }),
  watch: {
    content: {
      immediate: true,
      handler() {
        this.renderedContent = this.content.map((item) => {
          return this.columns.map(({render}) => render(item));
        });
      }
    }
  }
});
</script>
