import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "manage-users" }
const _hoisted_2 = { class: "manage-users__title" }
const _hoisted_3 = { class: "manage-users__subtitle" }
const _hoisted_4 = { class: "manage-users__search__filter" }
const _hoisted_5 = { class: "manage-users__search__action" }
const _hoisted_6 = { class: "manage-users__add" }
const _hoisted_7 = { class: "manage-users__table" }
const _hoisted_8 = { class: "manage-users__table__actions" }
const _hoisted_9 = { class: "manage-users__table__actions__elements" }
const _hoisted_10 = { class: "manage-users__table__actions__page-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BackOfficeUserModal = _resolveComponent("BackOfficeUserModal")!
  const _component_BackOfficeUserTable = _resolveComponent("BackOfficeUserTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.translate('BACKOFFICE.USERS.COMPANY_USERS')), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(`${_ctx.translate('RESEARCH')} :`), 1),
    _createElementVNode("form", {
      class: "manage-users__search",
      onSubmit: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.searchUser && _ctx.searchUser(...args))),
      onReset: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.resetSearch && _ctx.resetSearch(...args)))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_TextInput, {
          label: `${_ctx.translate('IDENTITY.NAME')} :`,
          modelValue: _ctx.contentSearch.userName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contentSearch.userName) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_TextInput, {
          label: `${_ctx.translate('IDENTITY.EMAIL')} :`,
          modelValue: _ctx.contentSearch.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contentSearch.email) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_TextInput, {
          label: `${_ctx.translate('IDENTITY.PHONE_NUMBER')} :`,
          modelValue: _ctx.contentSearch.phone,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contentSearch.phone) = $event))
        }, null, 8, ["label", "modelValue"]),
        _createVNode(_component_Select, {
          value: _ctx.contentSearch.group,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contentSearch.group) = $event)),
          options: _ctx.groups?.map(({name}) => name),
          values: _ctx.groups?.map(({name}) => name),
          title: `${_ctx.translate('BACKOFFICE.USERS.GROUP')} :`,
          class: "manage-users__search__filter__select-group",
          "empty-option": true,
          resetValue: _ctx.shouldReset
        }, null, 8, ["value", "options", "values", "title", "resetValue"]),
        _createVNode(_component_Select, {
          value: _ctx.contentSearch.place,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.contentSearch.place) = $event)),
          options: _ctx.places?.map(({name}) => name),
          values: _ctx.places?.map(({name}) => name),
          title: `${_ctx.translate('BACKOFFICE.USERS.PLACE')} :`,
          class: "manage-users__search__filter__select-group",
          "empty-option": true,
          resetValue: _ctx.shouldReset
        }, null, 8, ["value", "options", "values", "title", "resetValue"]),
        _createVNode(_component_Select, {
          value: _ctx.contentSearch.profiles,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.contentSearch.profiles) = $event)),
          options: _ctx.profiles?.map(({name}) => name),
          values: _ctx.profiles?.map(({name}) => name),
          title: `${_ctx.translate('BACKOFFICE.USERS.PROFILE')} :`,
          class: "manage-users__search__filter__select-group",
          "empty-option": true,
          resetValue: _ctx.shouldReset
        }, null, 8, ["value", "options", "values", "title", "resetValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Button, {
          label: _ctx.translate('BUTTONS.RESET'),
          type: "reset"
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.translate('BUTTONS.SEARCH'),
          type: "submit"
        }, null, 8, ["label"])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        label: _ctx.translate('BUTTONS.ADD'),
        picture: "plus.png",
        onClick: _ctx.addModal
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_BackOfficeUserModal, {
        "modal-flag": _ctx.addUserModalFlag,
        content: _ctx.contentAddModal,
        step: _ctx.firstStepAdd,
        onValidateAction: _ctx.validateUser,
        onNextStep: _ctx.nextStep,
        onCancelAction: _ctx.cancelAddUser,
        supervisors: _ctx.supervisors,
        "check-box": _ctx.contentAddModal.isManager,
        "onUpdate:checkBox": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.contentAddModal.isManager) = $event)),
        places: _ctx.places,
        groups: _ctx.groups,
        user: _ctx.user,
        title: _ctx.translate('BACKOFFICE.USERS.USER_ADD'),
        profiles: _ctx.profiles,
        "profiles-checked": _ctx.profilesUser,
        "onUpdate:profilesChecked": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.profilesUser) = $event)),
        onBackFirstPage: _ctx.nextStep
      }, null, 8, ["modal-flag", "content", "step", "onValidateAction", "onNextStep", "onCancelAction", "supervisors", "check-box", "places", "groups", "user", "title", "profiles", "profiles-checked", "onBackFirstPage"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_BackOfficeUserTable, {
        titles: _ctx.titleUsers,
        "content-columns": _ctx.contentUsers,
        "filter-element": _ctx.filterUsers,
        onUpdateUser: _ctx.updateUser,
        supervisors: _ctx.supervisors,
        places: _ctx.places,
        groups: _ctx.groups,
        user: _ctx.user,
        profiles: _ctx.profiles
      }, null, 8, ["titles", "content-columns", "filter-element", "onUpdateUser", "supervisors", "places", "groups", "user", "profiles"]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('PAGE')} ${_ctx.currentPage + 1} ${_ctx.translate('ON')} ${_ctx.totalPages} - ${_ctx.totalElements} ${_ctx.translate('RESULTS_FOUND')}`), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.currentPage <= _ctx.totalPages && _ctx.currentPage > 0)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.movePages(-1))),
                label: _ctx.translate('TABLE.BACK')
              }, null, 8, ["label"]))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.currentPage + 1) + " / " + _toDisplayString(_ctx.totalPages), 1),
          (_ctx.currentPage < _ctx.totalPages - 1 && _ctx.currentPage >= 0)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.movePages(1))),
                label: _ctx.translate('TABLE.NEXT')
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}