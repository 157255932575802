import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "navigation-group" }
const _hoisted_2 = { class: "navigation-group__title" }
const _hoisted_3 = { class: "navigation-group__zoom-button" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.translate(_ctx.groupTitle)), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.titles, (item, index) => {
      return (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: _ctx.routes[index]
        }, {
          default: _withCtx(() => [
            (!_ctx.hiddenTitles.includes(_ctx.translate(item)))
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "navigation-group__box-route",
                  style: _normalizeStyle({ backgroundColor: _ctx.colorsBackground[index], color: _ctx.colorsTitle[index] }),
                  onClick: ($event: any) => (_ctx.redirect(item))
                }, _toDisplayString(_ctx.translate(item as string)), 13, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 256))
  ]))
}