import {ChartData, LinkChart, NodeChart} from "@/types/Cartography";
import {formatSiren} from "@/utils/formatRegistrationNumber";
import {isNull} from "lodash";

export function useCartography() {

  const getColor = (companyProperties: any) => {
    if (companyProperties.companyIsInCollectiveProceedings) {
      return '#FF7676';
    } else if (companyProperties.companyIsInRadiation) {
      return '#FFA543';
    } else if (companyProperties.nodeType === 'NATURAL PERSON') {
      return '#EBD178';
    } else if (isNull(companyProperties.companyActivitiesCode)) {
      return '#FFFFFF';
    } else {
      return '#36BA75';
    }
  }

  const getColorLinks = (nodeColor: string) => {
    if (nodeColor === '#FFFFFF') {
      return '#000000'; //if the node is white, the link need to be black (otherwise we can't see it)
    }
    return nodeColor;
  }

  const getNodeName = (chartData: ChartData,companyCountry?: string, ) => {
    if (companyCountry && companyCountry === 'ES')
      return chartData.label
    return formatSiren(companyCountry || 'FR', chartData.label)
  }

  const nodesData = (dataMapChart: ChartData[], companyCountry?: string) => {
    let nodes: NodeChart[];
    nodes = dataMapChart?.map((item: ChartData) => ({
      id: item.id,
      originalName: item.label,
      name: item.properties.nodeType === 'NATURAL PERSON' ? '' : getNodeName(item, companyCountry),
      symbolSize: item.properties.nodeType === 'IDENTIFIER' ? 40 : 15,
      itemStyle: {
        color: item.color === 'GOLD' ? '#EBD178' : getColor(item.properties),
        borderWidth: 1,
        borderColor: '#293354'
      },
      symbol: item.properties.nodeType === 'NATURAL PERSON' || item.color === 'GOLD' ? 'diamond' : 'circle'
    }));
    return nodes;
  }

  const linksData = (dataMapChart: ChartData[], nodes: NodeChart[]) => {
    let linksToAdd: LinkChart[] = [];
    dataMapChart?.forEach((item: ChartData) => {  //for each element (company or person)
      const sourceIndex = nodes.findIndex(node => node.originalName === item.label);
      item.links.forEach(linkLabel => {  //we check all their links
        const targetIndex = nodes.findIndex(node => node.originalName === linkLabel);
        const isLinkDoesntAlreadyExists = !linksToAdd.some(link =>
          (link.source === sourceIndex && link.target === targetIndex)
          || (link.source === targetIndex && link.target === sourceIndex));
        if (isLinkDoesntAlreadyExists) {  //and we had the link only if it doesn't exist (to not have two links for both elements)
          linksToAdd.push({
            source: sourceIndex,
            target: targetIndex,
            lineStyle: {
              color: getColorLinks(nodes[sourceIndex].itemStyle.color)
            }
          });
        }
      });
    });
    return linksToAdd;
  }

  return {nodesData, linksData};
}