<template>
  <img :src="require(`../../../public/images/${copyToClipboardSrc}`)" class="copy_img no-print"
       @click="copyValueToClipboard" alt="copy_img"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {copyMessage} from "@/utils/copyClipboard";

export default defineComponent({
  methods: {
    copyValueToClipboard() {
      copyMessage(this.value);
      this.copyToClipboardSrc = 'copied.png';
      setInterval(() => {
        this.copyToClipboardSrc = 'copy.png';
      }, 750);
    }
  },
  data() {
    return {
      copyToClipboardSrc: 'copy.png',
    }
  },
  props: {
    value: {type: String, default: ''}
  }
})
</script>