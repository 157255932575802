import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const sendDocumentTaxReportToAnalysisKit = (journeyId: string, info: Object, data: any) => {
  return api.put(`${envConfig.TAX_REPORT_ANALYSIS_ROUTE}/v2/analysis/json?journeyId=${journeyId}`,
    {
      "info": info,
      "data": data
    })
    .then((response) => response);
};

export const makeUploadFile = (file: any, documentType: string) => {
  const formData = new FormData();
  formData.append('documentType', documentType);
  formData.append('file', file);
  return formData;
};

export const makeTaxJsonFile = (data: any, name: string) => {
  const temp = JSON.stringify(data);

  const blob = [new Blob([temp], {
    type: 'application/json'
  })]

  const file = new File(blob, name, {type: 'application/json'});

  const formData = new FormData();
  formData.append('file', file);
  return formData;
}

export const sendDocumentTaxReport = (journeyId: string, data: any, name: string) => {
  const file = makeTaxJsonFile(data, name);

  return api.post(`${envConfig.TAX_REPORT_ANALYSIS_ROUTE}/upload-document?journeyId=${journeyId}&documentType=TAX_REPORT`, file, {
    timeout: 30000,
    headers: {
      'Accept': '*/*',
      'Content-Type': 'multipart/form-data'
    }
  })
    .then((response) => response);
};

export const sendDocumentTaxReportToAnalysis = (journeyId: string, documentId: string) => {
  return api.put(`${envConfig.TAX_REPORT_ANALYSIS_ROUTE}/v2/analysis/document/${documentId}?journeyId=${journeyId}`,)
    .then((response) => response);
};

export default {sendDocumentTaxReportToAnalysis};
