<template>
  <div class="batch-page">
    <BoxInputScoreBatch class="batch-input" :user-email="user.email" :user-company="user.company"/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BoxInputScoreBatch from '@/components/box-input/BoxInputScoreBatch.vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'Batch',
  components: {
    BoxInputScoreBatch
  },
  computed: { 
    ...mapGetters(['user'])
  }
});

</script>

<style scoped lang="scss">

.batch-page {
  padding: 1rem;
  display: flex;
  justify-content: center;
}

.batch-input {
  width: 80%;
}
</style>
