import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "end-of-journey" }
const _hoisted_2 = { class: "end-of-journey-title" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(`${_ctx.translate('WELCOME')}`), 1),
    _createElementVNode("img", {
      class: "end-of-journey-image",
      src: _ctx.companyLogo.src,
      alt: _ctx.companyLogo.alt
    }, null, 8, _hoisted_3)
  ]))
}