<script setup lang="ts">
import ContentCard from "@/components/card/ContentCard.vue";
import Loader from "@/components/loader/Loader.vue";
import {translate} from "@/i18n";
import {computed, reactive} from "vue";
import {size} from "lodash";
import {
  formatCaseStatus,
  formatCompanyNames,
  formatCourtLocation,
  formatJudgedDate,
  LegalSituation,
  LegalSituationType,
} from "@/utils/companyLitigation";


const props = withDefaults(defineProps<{
  canCompanyLitigation: boolean,
  isLoader: boolean
  data: LegalSituation,
}>(), {
  canCompanyLitigation: true,
  isLoader: false,
});

const displayLegalSituation = (legalSituation: LegalSituationType) => {
  return [
    {
      class: "header",
      value: formatCompanyNames(legalSituation.applicants.name1, legalSituation.applicants.name2)
    },
    {
      class: "info-date",
      value: formatJudgedDate(legalSituation.judgmentDate)

    },
    {
      class: "info-location",
      value: formatCaseStatus(legalSituation.caseStatus)
    },
    {
      class: "info-location",
      value: formatCourtLocation(legalSituation.courtLocation)
    }]
}

const isAvailableData = computed(() => {
  return size(props.data?.company.legalSituation) > 0;
})
</script>

<template>
  <ContentCard class="litigation-card" v-if="!canCompanyLitigation" :title="translate('MODULE.LITIGATION.TITLE')"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard class="litigation-card" :title="translate('MODULE.LITIGATION.TITLE')">
      <div class="content">
        <template v-if="isLoader">
          <Loader/>
        </template>
        <template v-else>
          <div v-if="!isAvailableData" class="error-message">
            <span>{{ translate('ERRORS.NO_DATA') }}</span>
          </div>
          <div v-else v-for="legalSituation in reactive(props.data.company.legalSituation)" class="litigation-info">
            <span v-for="legalSituationObj in displayLegalSituation(legalSituation)" :class="legalSituationObj.class">{{ legalSituationObj.value }}</span>
          </div>
        </template>
      </div>
    </ContentCard>
  </template>
</template>