import {
  DocumentTypeDto,
  UploadMode,
  createIframeUploadDocument,
  createIframeUploadDocumentWithAuthenticity,
} from "@/apiCalls/uploadDocumentsApi";

const handleUploadForID = async (
  uploadMode: "IFRAME" | "PHONE",
  journeyId: string
) => {
  const response = await createIframeUploadDocument(
    journeyId,
    "ID_CHECK",
    uploadMode
  );
  return response.data.link as string;
};

const handleUploadForOtherDocument = async ({
  uploadMode,
  journeyId,
  documentTypes,
}: {
  uploadMode: UploadMode;
  journeyId: string;
  documentTypes: DocumentTypeDto[];
}) => {
  const documents = documentTypes.map((documentType) => {
    return {
      documentType,
      withAuthenticity: true,
      withAnalysis: true,
    };
  });
  const response = await createIframeUploadDocumentWithAuthenticity(journeyId, {
    documents: documents,
    uploadMode: uploadMode,
  });
  return response.data.link as string;
};

export const handleUpload = async ({
  uploadMode,
  documentCategory,
  journeyId,
  documentTypes,
}: {
  uploadMode: "IFRAME" | "PHONE";
  documentCategory: "ID" | "OTHER";
  journeyId: string;
  documentTypes: DocumentTypeDto[];
}) => {
  if (documentCategory === "ID")
    return await handleUploadForID(uploadMode, journeyId);

  if (documentCategory === "OTHER" && documentTypes.length > 0)
    return await handleUploadForOtherDocument({
      uploadMode,
      journeyId,
      documentTypes,
    });

  return "";
};
