export enum ReasonCode {
  AC01 = 'AC01',
  BE05 = 'BE05',
  BE15 = 'BE15',
  BE21 = 'BE21',
  FF01 = 'FF01',
  RC11 = 'RC11',
}

export type CheckIban = {
  bank?: {
    name?: string;
    iban?: string;
    bic?: string;
  };
  detailedChecks?: {
    Check: {
      scope: string;
      result: string;
    }
  }[];
  acknowledgment?: {
    ack: string;
  };
  reasonCode?: {
    code: ReasonCode;
  };
};

export const calculateNote = (check: string) => {
  return (parseInt(check) / 400) * 100;
};