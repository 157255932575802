export function formatPhoneNumber(phone: string, phoneCountry: string) {
  phone = phone
    .replaceAll(".", "")
    .replaceAll(" ", "")
    .replaceAll("-", "")
    .replaceAll("_", "");

  const phoneStartsWithGoodPrefix =
    phone.substring(0, phoneCountry.length) == phoneCountry;
  let phoneWithoutPrefix = phone.substring(phoneCountry.length, phone.length);

  // Contient seulement des nombres de longueur 9 ou 10
  const validationRegex = /^\d{9,10}$/;

  if (!validationRegex.test(phoneWithoutPrefix)) return false;

  if (phoneWithoutPrefix.startsWith("0"))
    phoneWithoutPrefix = phoneWithoutPrefix.slice(1);

  if (phoneStartsWithGoodPrefix && phoneWithoutPrefix.length <= 9) {
    return phoneCountry + phone.substring(phone.length - 9, phone.length);
  }

  if (!phoneStartsWithGoodPrefix && phone.length == 10) {
    return phoneCountry + phone.substring(phone.length - 9, phone.length);
  }

  return false;
}
