<script setup lang="ts">
import ResultCard from "@/components/card/ResultCard.vue";
import AuthenticityResults from "@/components/document-analysis/AuthenticityResults.vue";
import {translate} from "@/i18n";
import {Checks} from "@/types/BankDetailsResults";
import {useBankDetails} from "@/composables/useBankDetails";

const props = withDefaults(
    defineProps<{
      checks: Checks
    }>(), {});

const bankDetails = useBankDetails();

const getCheckData = (value: string) => {
  return props.checks[value as keyof Checks];
};
</script>

<template>
  <div class="bank-details-results">
    <ResultCard :title="translate('INFORMATIONS')">
      <table class="bank-details-results-first-table">
        <thead>
        <tr>
          <th v-for="label in bankDetails.getChecksData">
            {{ translate(`DOCUMENT_CONTROL.TABLE.RESULT.BANK_DETAILS.${label}`) }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-for="value in bankDetails.getChecksData">
            {{ getCheckData(value) }}
          </td>
        </tr>
        </tbody>
      </table>
      <table class="bank-details-results-second-table">
        <thead>
        <tr></tr>
        </thead>
        <tbody>
        <tr v-for="value in bankDetails.getChecksDataIbanBic">
          <td>{{ translate(`DOCUMENT_CONTROL.TABLE.RESULT.BANK_DETAILS.${value}`) }}</td>
          <td>{{ getCheckData(value) }}</td>
        </tr>
        </tbody>
      </table>
      <div class="bank-details-results-informations">
        <div class="bank-details-results-informations-direct-debit">
          <span v-for="data in bankDetails.getBankData(props.checks)" :class="data.class">{{ data.value }}</span>
        </div>
        <div class="bank-details-results-informations-checks">
          <span class="bank-details-results__title">{{
              translate("DOCUMENT_CONTROL.TABLE.RESULT.BANK_DETAILS.CHECKS")
            }}</span>
          <div v-for="(item, index) in bankDetails.getBankDetailsChecks(props.checks)" :key="index">
            <img :src="require(`/public/images/${bankDetails.getChecks(item.status)}`)"
                 :alt="bankDetails.getChecks(item.status)"
                 class="bank-details-results__icon"/>
            <span>{{ translate(`DOCUMENT_CONTROL.TABLE.RESULT.BANK_DETAILS.${item.check}`) }}</span>
          </div>
        </div>
      </div>
    </ResultCard>
    <AuthenticityResults :title="translate('DOCUMENT_CONTROL.AUTHENTICITY_DETAILS')"
                         :checks="checks.authenticityReasons"/>
  </div>
</template>