<template>
  <div class="synthesis-recovery">
    <div class="synthesis-recovery-sidebar">
      <img class="synthesis-recovery-sidebar-logo" src="/images/logo_vertical_meelo.png" alt="Logo Meelo">
    </div>
    <section class="synthesis-recovery-summary">
      <ResultCard :title="translate('RECOVERY.REQUEST')" class="synthesis-recovery-summary-request">
        <div class="synthesis-recovery-summary-request-information">
          <div class="synthesis-recovery-summary-request-information-first-block">
            <div class="synthesis-recovery-summary-request-information-first-block-name">
            <span>{{
                `${translate('IDENTITY.NAME')} ${translate('IDENTITY.FIRSTNAME')} : ${applicant.lastname} ${applicant.firstname}`
              }}</span>
              <span>{{ `${translate('RECOVERY.CLIENT_ID')} : ${externalId}` }}</span>
            </div>
            <div class="synthesis-recovery-summary-request-synthesis">
            </div>
          </div>
          <div v-if="Object.keys(causesFraud).length > 0"
               class="synthesis-recovery-summary-request-information-second-block">
            <span>{{ `${translate('IDENTITY.EMAIL')} : ${applicant.email}` }}</span>
            <div class="synthesis-recovery-summary-request-information-second-block-verification">
              <VerificationResult :value="causesFraud.contact.email_exists" :label="translate('VERIFIED')"
                                  :tooltip="translate('TOOLTIP.EMAIL_VERIFICATION')"/>
              <VerificationResult :value="causesFraud.contact.email_name" :label="translate('LINKED')"
                                  :tooltip="translate('TOOLTIP.EMAIL_LINK')"
                                  :is-warning="true"/>
            </div>
            <span>{{ `${translate('IDENTITY.PHONE_NUMBER')} : ${applicant.mobilePhone}` }}</span>
            <div class="synthesis-recovery-summary-request-information-second-block-verification">
              <VerificationResult :value="causesFraud.contact.phone_exists" :label="translate('VERIFIED')"
                                  :tooltip="translate('TOOLTIP.MOBILE_PHONE_VERIFICATION')"/>
              <VerificationResult :value="causesFraud.contact.phone_name" :label="translate('LINKED')"
                                  :tooltip="translate('TOOLTIP.MOBILE_PHONE_LINK')"
                                  :is-warning="true"/>
            </div>
          </div>
        </div>
      </ResultCard>
      <ResultCard :title="translate('RECOVERY.FINANCIAL_ANALYSIS')"
                  class="synthesis-recovery-summary-financial-analysis">
        <div class="synthesis-recovery-summary-request-date">
          <span>{{ `${translate('RECOVERY.ANALYSIS_DATE')} : ${new Date().toLocaleDateString()}` }}</span>
          <span>{{
              `${translate('OPEN_BANKING.ANALYSIS_PERIOD')} ${translate('FROM')} ${obFormattedFields.startStudyPeriod} ${translate('OPEN_BANKING.TO')} ${obFormattedFields.endStudyPeriod}`
            }}</span>
          <span>{{
              `${translate('OPEN_BANKING.BANK_NAME')} : ${obFormattedFields.bankName} (${translate('RECOVERY.RISK_LEVEL')} `
            }}
          <img
            :src="require(`/public/images/${displayTopBankRiskLevel(obFormattedFields.topBankRiskLevel)}`)"
            :alt="obFormattedFields.topBankRiskLevel"
            class="icon"><span>{{ ` ${displayTopBankRiskDescription(obFormattedFields.topBankRiskLevel)})` }}
          </span>
        </span>
          <span>{{ `${translate('OPEN_BANKING.HOLDER')} : ${obFormattedFields.holderName}` }}</span>
          <span>{{ `${translate('OPEN_BANKING.MAIN_ACCOUNT')} : ${obFormattedFields.topSecondaryAccount}` }}</span>
        </div>
      </ResultCard>
      <ResultCard :title="translate('OPEN_BANKING.RECOMMENDATIONS')" class="synthesis-recovery-summary-recommendations">
        <span>{{
            `${translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_DATE')} : ${obFormattedFields.recommendedDebitDate}`
          }}</span>
        <span>{{
            `${translate('OPEN_BANKING.CATEGORIES.RECOMMENDED_DEBIT_AMOUNT')} : ${obFormattedFields.recommendedDebitAmount}`
          }}</span>
      </ResultCard>
      <ResultCard :title="translate('RECOVERY.FINANCIAL_PRESSURE')"
                  class="synthesis-recovery-summary-financial-pressure">
        <div class="synthesis-recovery-summary-financial-pressure-content">
          <span class="synthesis-recovery-summary-financial-pressure-content-subtitle">{{
              `${translate('RECOVERY.CREDITS')} :`
            }}</span>
          <span>{{ `${translate('OPEN_BANKING.DEBT_RATIO')} : ${obFormattedFields.debtRatio}` }}</span>
          <span>{{
              `${translate('OPEN_BANKING.MONTH_OUTCOMES_CREDITS_COUNT_MEAN')} : ${obFormattedFields.monthOutcomesCreditsCountMean}`
            }}</span>
        </div>
        <div class="synthesis-recovery-summary-financial-pressure-content">
          <span class="synthesis-recovery-summary-financial-pressure-content-subtitle">{{
              `${translate('RECOVERY.DEBT_COLLECTION_AGENCIES')} :`
            }}</span>
          <span>{{
              `${translate('OPEN_BANKING.ALERTS.AMOUNT')} : ${obFormattedFields.monthOutcomesCashCollectionsAmountMean}`
            }}</span>
        </div>
      </ResultCard>
      <ResultCard :title="translate('RECOVERY.FINANCIAL_SITUATION')" class="synthesis-recovery-summary-financial-situation">
        <span>{{ `${translate('OPEN_BANKING.BALANCE')} : ${obFormattedFields.balance}` }}</span>
        <span>{{ `${translate('RECOVERY.AVERAGE_DISPOSABLE_INCOME')} : ${obFormattedFields.remainsToLive}` }}</span>
        <span>{{
            `${translate('RECOVERY.AVERAGE_MONTHLY_INCOME')} : ${obFormattedFields.monthIncomesAmountMean}`
          }}</span>
        <span>{{ `${translate('RECOVERY.LOWEST_INCOME')} : ${obFormattedFields.monthIncomesAmountMin}` }}</span>
        <span>{{
            `${translate('RECOVERY.LACK_OF_INCOME')} : ${obFormattedFields.monthIncomesAmountMean > 0 ? translate('YES') : translate('NO')}`
          }}</span>
      </ResultCard>
      <ResultCard :title="translate('RECOVERY.CUSTOMER_RELIABILITY')"
                  class="synthesis-recovery-summary-customer-reliability html2pdf__page-break">
        <div class="synthesis-recovery-summary-customer-reliability-content">
          <ScoreCircleChart
            class="circle-chart score-open-banking-recovery"
            :circle-value="Math.round(obFormattedFields.score)" :circle-width="'40%'"
            :tooltip="translate('TOOLTIP.SCORE_OPEN_BANKING')" :circle-color="colorScore"
          />
          <div class="synthesis-recovery-summary-customer-reliability-content-right">
        <span>{{
            `${translate('OPEN_BANKING.TOP_BANKING_SUPERVISION')} : ${obFormattedFields.topBankingSupervision}`
          }}</span>
            <span>{{ `${translate('RECOVERY.REJECTION')} : ${obFormattedFields.monthOutcomesRejects}` }}</span>
            <span>{{
                `${translate('RECOVERY.INTERVENTION_FEES')} : ${obFormattedFields.monthOutcomesInterventionFees}`
              }}</span>
            <span>{{
                `${translate('RECOVERY.THIRD_PARTY_NOTICE')} : ${obFormattedFields.monthOutcomesSeizureFees}`
              }}</span>
            <span>{{ `${translate('RECOVERY.GAMBLING')} : ${obFormattedFields.monthOutcomesGamblings}` }}</span>
          </div>
        </div>
      </ResultCard>
      <h1>{{ translate('OPEN_BANKING.DETAILS') }}</h1>
      <BoxOutputOpenBanking v-if="Object.keys(obFormattedFields).length>0" :ob-data="obFormattedFields"
                            :is-recovery="true" class="html2pdf__page-break" :can-open-banking="true" :color-score="colorScore"/>
      <div class="chart-container">
        <h3>{{translate('OPEN_BANKING.DAILY_AND_MONTHLY')}}</h3>
      <ResultCard :title="translate('OPEN_BANKING.DAILY_BALANCE')" v-if="abscissaLineChart && abscissaLineChart.length" 
                  class="synthesis-recovery-summary-customer-reliability resultchart">
        <LineChart class="linechart" :abscissa-chart="abscissaLineChart || []" :data-chart="detailsLineChart || []"
                    :label-chart="translate('OPEN_BANKING.DAILY_BALANCE')" border-color-chart="#4FA0FF" background-color-chart="#4FA0FF"/>
      </ResultCard>
      <ResultCard :title="translate('OPEN_BANKING.MONTHLY_BALANCE')" v-if="abscissaBarChart && abscissaBarChart.length"   
      class="synthesis-recovery-summary-customer-reliability resultchart html2pdf__page-break">
        <BarChart class="barchart" v-if="abscissaBarChart && abscissaBarChart.length" :abscissa-chart="abscissaBarChart || []" :data-chart="detailsBarChart || []"
                  :label-chart="translate('OPEN_BANKING.MONTHLY_BALANCE')" background-color-chart="#4FA0FF"/>
      </ResultCard>
      </div>
      <BoxOutputOpenBankingDetail v-if="Object.keys(obFormattedFields).length>0" :ob-data="obFormattedFields"
                                  :is-recovery="true" class="html2pdf__page-break" :can-open-banking="true"/>
      <BoxOutputOpenBankingAlert v-if="Object.keys(obFormattedFields).length>0"
                                 :ob-data="obFormattedFields" :is-recovery="true" class="html2pdf__page-break" :can-open-banking="true"/>
    </section>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {translate} from "@/i18n";
import ResultCard from "@/components/card/ResultCard.vue";
import Button from "@/components/button/Button.vue";
import BoxOutputOpenBankingDetail from "@/components/box-output/BoxOutputOpenBankingDetail.vue";
import BoxOutputOpenBankingAlert from "@/components/box-output/BoxOutputOpenBankingAlert.vue";
import BoxOutputOpenBanking from "@/components/box-output/BoxOutputOpenBanking.vue";
import VerificationResult from "@/components/VerificationResult.vue";
import ScoreCircleChart from "@/components/ScoreCircleChart.vue";
import LineChart from "@/components/chart/LineChart.vue";
import BarChart from "@/components/chart/BarChart.vue";



export default defineComponent({
  methods: {
    translate,
    displayTopBankRiskLevel(riskLevel: number) {
      if (riskLevel === 0 || riskLevel === 1) {
        return 'success-icon.png';
      } else if (riskLevel === 2) {
        return 'warning-icon.png';
      } else if (riskLevel === 3) {
        return 'failed-icon.png'
      } else {
        return 'null-icon.png'
      }
    },
    displayTopBankRiskDescription(riskLevel: number) {
      if (riskLevel === 0 || riskLevel === 1) {
        return translate('RECOVERY.LOW');
      } else if (riskLevel === 2) {
        return translate('RECOVERY.MODERATE');
      } else if (riskLevel === 3) {
        return translate('RECOVERY.RISKY');
      } else {
        return '';
      }
    },
  },
  components: {
    ScoreCircleChart,
    VerificationResult,
    BoxOutputOpenBanking, BoxOutputOpenBankingAlert, BoxOutputOpenBankingDetail, Button, ResultCard, LineChart, BarChart
  },
  props: {
    applicant: {type: Object},
    externalId: {type: String, default: ''},
    obFormattedFields: {type: Object},
    causesFraud: {type: Object},
    colorScore:{type:String,default:'#D9D9D9'},
    abscissaLineChart:{ type: Array as () => string[] },
    detailsLineChart:{ type: Array as () => number[] },
    abscissaBarChart:{ type: Array as () => string[] },
    detailsBarChart:{ type: Array as () => number[] },
  }
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";

 .chart-container{
  display: grid;
  gap: 1rem;
  margin: 0 1rem;
}
.resultchart{
  display: grid;
  width: 100%;
  height: 25rem; 
} 
.barchart{
  width: 100%;
  height: 100%;
}
.synthesis-recovery {
  display: grid;
  grid-template-columns: 5rem 1fr;

  &-sidebar {
    background-color: $blueMeelo;
    text-align: center;
    height: 100%;

    &-logo {
      height: 5rem;
      margin-top: 2rem;
    }
  }
  &-summary {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 45rem;

    &-request {
      &-information {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        &-first-block {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          &-name {
            display: flex;
            flex-direction: column;
          }
        }

        &-second-block {
          display: grid;
          grid-template-columns: 340px 1fr;
          gap: 10px;
          align-items: center;

          &-verification {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }

      &-subtitle {
        text-decoration: underline;
      }

      &-links, &-synthesis {
        text-align: center;
      }

      &-date {
        display: flex;
        flex-direction: column;
      }

      &-right-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
    }

    &-result {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin: 0 1rem;
    }

    &-financial-pressure {
      &-content {
        display: flex;
        flex-direction: column;

        &-subtitle {
          font-weight: bold;
        }
      }
    }

    &-customer-reliability {
      &-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &-right {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.icon {
  height: 1.2rem;
}
</style>
