<template>
  <input type="checkbox" :checked="checkedValue" v-on:change="$emit('update:checkedValue', $event.target.checked)">
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  props: {
    checkedValue: {type: Boolean, default: false}
  }
});
</script>