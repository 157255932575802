<template>
    <div class="little-block-modal">
        <span v-if="emailDisposable"> ⚠️</span>
        <slot></slot>
        <span class="little-block-modal-body">{{ content }}</span>
        <span class="little-block-modal-result">{{ result }}</span>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";

export default defineComponent({
    name: "LittleBlockModal",
    components: {LabelValuePrinter},
    props: {
        content: {type: String, default: ''},
        result: {type: Number},
        emailDisposable: {type: Boolean, default: false}
    }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.little-block-modal {
  box-shadow: 1px 1px 11px lightgrey;
  border-radius: 10px;
  padding: 20px;
  font-size: $fontSizeSixteenSize;
  margin: 10px;

  &-image {
    width: 5%;
  }

  &-body {
    padding: 10px;
  }
}

.detailed-score_networks-span {
  background: $pureWhite;
}

.detailed-score_email-disposable {
  margin: 0 0 1rem 0;
  background: $orangeMeelo;
  text-align: center;
  font-weight: bold;
}
</style>