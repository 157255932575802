import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4443af47"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "little-block-modal" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "little-block-modal-body" }
const _hoisted_4 = { class: "little-block-modal-result" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.emailDisposable)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, " ⚠️"))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.content), 1),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.result), 1)
  ]))
}