import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "LoginPage" }
const _hoisted_2 = { class: "LoginPage__left" }
const _hoisted_3 = {
  href: "https://www.getmeelo.com/meelo-fintech-certifiee-b-corp/",
  target: "_blank"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "LoginPage__right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MeeloLogo = _resolveComponent("MeeloLogo")!
  const _component_GeneralCondition = _resolveComponent("GeneralCondition")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MeeloLogo, { class: "LoginPage__left__logo" }),
      _createElementVNode("a", _hoisted_3, [
        _createElementVNode("img", {
          class: "logoB",
          src: require('/public/images/bcorpLogo.png'),
          alt: "LogoBCorp"
        }, null, 8, _hoisted_4)
      ]),
      _createVNode(_component_GeneralCondition, { class: "LoginPage__left__cgu" })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_MeeloLogo, {
        class: "LoginPage__right__logo",
        white: ""
      }),
      _createVNode(_component_router_view, {
        class: "LoginPage__right__form",
        white: ""
      }),
      _createVNode(_component_GeneralCondition, {
        "is-right": "",
        class: "LoginPage__right__cgu",
        white: ""
      })
    ])
  ]))
}