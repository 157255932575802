import {defineComponent} from 'vue';
import {ApiUserDtoV2} from '@/apiCalls/login';
import moment from 'moment';
import {mapActions, mapMutations} from 'vuex';

export default defineComponent({
  computed: {
    accessToken(): string {
      return this.$cookies.get('access_token');
    }
  },
  methods: {
    ...mapActions(['fetchUser']),
    ...mapMutations(['setUser']),
    async persistUserCookie({user, token, expirationInstant}: {
      user: ApiUserDtoV2,
      token: string,
      expirationInstant: string
    }) {
      const expirationDate = new Date(expirationInstant);
      const {USAGE} = user.companyParameters,
        config = JSON.parse(USAGE ? USAGE : '{}');
      const setCookie = (name: string, value: any) =>
        this.$cookies.set(name, value, expirationDate, '/');
      setCookie('access_token', token);
      setCookie('expiration_instant', expirationInstant);
      setCookie('user', user);
      setCookie('configuration', config);
      setCookie('connexion_date', moment().format('LLL'));
      await this.fetchUser();
    },
    clearUserCookiesAndPushToLogin() {
      this.$cookies.remove('access_token');
      this.$cookies.remove('expiration_instant');
      this.$cookies.remove('user');
      this.$cookies.remove('configuration');
      this.$cookies.remove('connexion_date');
      this.setUser(undefined);
      this.$router.push({name: 'LoginPage', replace: false});
    }
  }
});
