<template>
  <table>
      <thead>
        <tr class="table-header">
          <th>{{ translate('TELEPHONE.LINETYPE') }}</th>
          <th>{{ translate('BUTTONS.DETAILS') }}</th>
          <th>{{ translate('SCORE.SCORE') }}</th>
          <th>{{ translate('DOCUMENT_CONTROL.TABLE.RESULT.CHECK') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="rating in ratings" class="table-content">
          <td>{{ rating.source }}</td>
          <td width="100%">{{ rating.full_name }}</td>
          <td>{{ rating.value }}</td>
          <td class="table-status">{{ getCheckStatus(rating.decision) }}</td>
        </tr>
      </tbody>
    </table>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import { translate } from "@/i18n";
import { IdentityCheckRating } from '@/types/IdentityCheckResult';

export default defineComponent({
  name: "IdentityCheckResultRatings",
  props: {
    ratings: {} as any[]
  },
  methods: {    
    translate,
    getCheckStatus(decision:boolean) {
      if (decision) return "🟢";
      return "🔴";
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.table-document {

  border-collapse: separate;
  border-spacing: 10px;
  font-size: $fontSize;

  td{
    text-align: center;
    padding: 1.3vh 2vw;
    width: 100%;
  }

  &-header{
    color: $dataPrinterFontColor;
    text-align: center;
  }

  &-result{
    border: solid 0;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #393939;
  }
}

.table-content {

  border-collapse: separate;
  border-spacing: 10px;
  font-size: $fontSize;

  td{
    text-align: center;
    padding: 1.3vh 2vw;
  }

  &-header{
    color: $dataPrinterFontColor;
    text-align: center;
  }

  &-result{
    border: solid 0;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #393939;
  }
}

.table-status, .table-header, .table-content {
  text-align: center;
}

</style>