<template>
  <div class="backoffice__group_actions">
    <SeeAction v-if="see" item="item" v-on:click="$emit('see')"/>
    <EditAction v-if="edit" item="item" v-on:click="$emit('edit')"/>
    <DeleteAction v-if="this.delete" item="item" v-on:click="$emit('delete')"/>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue';
import EditAction from '@/components/table/actions/EditAction.vue';
import DeleteAction from '@/components/table/actions/DeleteAction.vue';
import SeeAction from '@/components/table/actions/SeeAction.vue';

export default defineComponent({
  name: 'BackOfficeGroupActions',
  components: {DeleteAction, EditAction, SeeAction},
  props: {
    see: {type: Boolean, default: true},
    edit: {type: Boolean, default: true},
    delete: {type: Boolean, default: true}
  },
  emits: ['see', 'edit', 'delete']
});
</script>
