<template>
  <div class="navigation-group">
    <div class="navigation-group__title"> {{ translate(groupTitle) }}</div>
    <div v-for="(item, index) in titles" class="navigation-group__zoom-button">
      <router-link :to="routes[index]">
        <button v-if="!hiddenTitles.includes(translate(item))" class="navigation-group__box-route"
          :style="{ backgroundColor: colorsBackground[index], color: colorsTitle[index] }" @click="redirect(item)">
          {{ translate(item as string) }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { translate } from '@/i18n';

export default defineComponent({
  name: 'NavigationGroup',
  data: () => ({
    marketType: ''
  }),
  props: {
    titles: { type: Array, default: [] },
    colorsBackground: { type: Array, default: [] },
    colorsTitle: { type: Array, default: [] },
    groupTitle: { type: String, default: '' },
    routes: { type: Array, default: [] },
    hiddenTitles: { type: Array, default: [] },
  },
  methods: {
    translate,
    redirect(path: string) {
      this.$emit('redirect', path);
    }
  }
});
</script>
