<script setup lang="ts">
import {computed} from "vue";

import { translate } from "@/i18n";

import Bar from "@/components/bar/Bar.vue";

const props = defineProps({
  data: { type: Array, required: true },
  isMaintenance: { type: Boolean, required: true, default: false },
  wasMaintenance: { type: Boolean, required: true, default: false },
});

const data:any = props.data;

const type = computed(() => {
  if (props.isMaintenance) {
    return "danger";
  } else if (props.wasMaintenance) {
    return "success";
  }
});

const header = computed(() => {
  if (props.isMaintenance) {
    return translate("MAINTENANCE.ONGOING");
  } else if (props.wasMaintenance) {
    return translate("MAINTENANCE.FINISHED");
  }
});

const text = computed(() => {
  let text;
  if (props.isMaintenance) {
    text = data.map((item: any) => item.message).join(", ");
  } else if (props.wasMaintenance) {
    text = translate("MAINTENANCE.FINISHED_TITLE");
  } else {
    // we return an empty string if there is no maintenance
    return "";
  }
  return text;
});

</script>

<template>
  <Bar v-if="text" :type="type" :header="header" :text="text" />
</template>
