import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_modal, {
      id: _ctx.idModal,
      title: _ctx.titleModal,
      "hide-footer": true,
      size: "xl"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["id", "title"])
  ]))
}