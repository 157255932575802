import { uniq } from "lodash";

export const isCanvasBlank = (canvas: HTMLCanvasElement) => {
  try {
    const ctx = canvas.getContext("2d");
    if (ctx) {
      return !ctx
        .getImageData(0, 0, canvas.width, canvas.height)
        .data.some((channel) => channel !== 0);
    }
  } catch {
    return true;
  }
};

export const getPrintImgSrc = (chartClassSelector: string) => {
  let imgSrc: string[] = [];
  const chartElements = document.querySelectorAll(chartClassSelector);
  chartElements.forEach((chartElement) => {
    const canvas = chartElement as HTMLCanvasElement;
    if (!isCanvasBlank(canvas)) {
      imgSrc.push(canvas.toDataURL());
    }
  });
  return uniq(imgSrc);
};
