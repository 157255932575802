<template>
  <div class="meelo-popover">
    <b-button :id="popver_id" class="meelo-popover-button">{{ title }}</b-button>
    <b-popover
          :target="popver_id"
          triggers="hover"
          v-if="age || city || pseudo || state || gender || country || language"
    >
      <ul>
        <li v-if="id"><span>Id : </span><span>{{ id }}</span></li>
        <li v-if="url"><span>Url : </span><span>{{ url }}</span></li>
        <li v-if="age"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.AGE') }} : </span><span>{{ age }}</span></li>
        <li v-if="city"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.CITY') }} : </span><span>{{ city }}</span></li>
        <li v-if="pseudo"><span>Pseudo : </span><span>{{ pseudo }}</span></li>
        <li v-if="state"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.STATE') }} : </span><span>{{ state }}</span></li>
        <li v-if="gender"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.GENDER') }} : </span><span>{{ gender}}</span></li>
        <li v-if="country"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.COUNTRY') }} : </span><span>{{ country }}</span></li>
        <li v-if="language"><span>{{ translate('SOCIAL_NETWORKS.INFORMATIONS.LANGUAGE') }} : </span><span>{{ language }}</span></li>
      </ul>
    </b-popover>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {translate} from '@/i18n';
import {BButton, BPopover} from 'bootstrap-vue-3';

export default defineComponent({
  name: "MeeloPopOver",
  components: {BPopover, BButton},
  methods: {translate},
  props: {
    title: {type: String, default: ''},
    popver_id: {type: String, default: ''},
    id: {type: String, default: ''},
    url: {type: String, default: ''},
    age: {type: String, default: ''},
    city: {type: String, default: ''},
    pseudo: {type: String, default: ''},
    state: {type: String, default: ''},
    gender: {type: String, default: ''},
    country: {type: String, default: ''},
    language: {type: String, default: ''},
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.meelo-popover {
  &-button {
    background-color: $headerColor;
  }
}

</style>
