import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResultsPdfExportRecovery = _resolveComponent("ResultsPdfExportRecovery")!
  const _component_vue3_html2pdf = _resolveComponent("vue3-html2pdf")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_vue3_html2pdf, {
      "show-layout": false,
      "float-layout": true,
      "enable-download": true,
      "preview-modal": false,
      "paginate-elements-by-height": 1400,
      filename: "synthese-portal-meelo",
      "pdf-quality": 2,
      "manual-pagination": true,
      "pdf-format": "a4",
      "pdf-orientation": "portrait",
      "pdf-content-width": "800px",
      ref: "html2Pdf"
    }, {
      "pdf-content": _withCtx(() => [
        _createVNode(_component_ResultsPdfExportRecovery, {
          applicant: _ctx.dataApplicant,
          "external-id": _ctx.dataExternalId,
          "ob-formatted-fields": _ctx.dataOpenBanking,
          "causes-fraud": _ctx.dataCausesFraud,
          "color-score": _ctx.dataColorScore,
          "abscissa-line-chart": _ctx.dataAbscissaLineChart,
          "details-line-chart": _ctx.dataDetailsLineChart,
          "abscissa-bar-chart": _ctx.dataAbscissaBarChart,
          "details-bar-chart": _ctx.dataDetailsBarChart
        }, null, 8, ["applicant", "external-id", "ob-formatted-fields", "causes-fraud", "color-score", "abscissa-line-chart", "details-line-chart", "abscissa-bar-chart", "details-bar-chart"])
      ]),
      _: 1
    }, 512)
  ]))
}