<template>
  <div class="address-input">
    <label>{{ label }}</label>
    <input
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :name="name"
      :autocomplete="autocomplete"
      class="input-text__input input"
      :readonly="readonly"
      :disabled="disabled"
      @input="changeValue('input', $event.target.value)"
      @blur="blur($event)"
      @focus="$emit('focus', $event.target.value)"
      @keydown.enter='enter($event)'
      @keydown.down='down($event)'
      @keydown.up='up($event)'
      @change="changeValue('no-click', $event.target.value)"
    >
    <div
      v-for="(option, index) in options"
      v-bind:key="index"
      :ref="'suggestion' + index"
      @click="suggestionClick($event, index)"
      class="input-text__container"
    >
      <span>{{ option.label }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "AddressInput",
  props: {
    label: {type: String, default: ''},
    id: {type: String, default: ''},
    type: {type: String, default: 'text'},
    placeholder: {type: String, default: ''},
    value: {type: String, default: ''},
    autocomplete: {type: String, default: 'on'},
    icon: {
      type: Object, default: () => {
        return {}
      }
    },
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    options: {type: Array, default: () => []} as any,
  },
  data() {
    return {
      open: false,
      current: 0
    }
  },
  computed: {
    currentIndex() {
      if (this.current >= this.options.length) {
        this.current = 0;
      }
      return this.current;
    }
  },
  methods: {
    //When enter pressed on the input
    enter(event: any) {
      if (this.options.length > 0 && this.options[this.currentIndex].value !== null) {
        this.changeValue('input', this.options[this.currentIndex].label, 'select');
        this.changeValue('clicked', this.options[this.currentIndex], 'select');
        this.open = false;

        event.preventDefault();
      }
    },
    //When up pressed while suggestions are open
    up(event: any) {
      if (this.current <= 0 || this.current > this.options.length) {
        this.current = this.options.length - 1;
      } else {
        this.current--;
      }

      event.preventDefault();
    },
    //When up pressed while suggestions are open
    down(event: any) {
      if (this.current >= this.options.length - 1) {
        this.current = 0;
      } else {
        this.current++;
      }

      event.preventDefault();
    },
    //For highlighting element
    isActive(index: number) {
      return index === this.current;
    },
    //When the user changes input
    change() {
      if (!this.open) {
        this.open = true;
        this.current = 0;
      }
    },
    //When one of the suggestion is clicked
    suggestionClick(event: any, index: any) {
      this.changeValue('input', this.options[index].label, 'select');
      this.changeValue('clicked', this.options[index], 'select');
      this.open = false;

      event.stopPropagation();
      event.preventDefault();
    },
    changeValue(event: any, value: any, action?: string) {
      this.$emit(event, value, action);
    },
    blur(event: any) {
      setTimeout(() => {
        this.$emit('blur', event);
      }, 150);
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";

.address-input {
  display: grid;
  width: 100%;
  margin: auto auto 1rem auto;

  label {
    font-weight: lighter;
    color: $loginColor1;
    padding: 0 0 0 0.2rem;
    font-size: $fontSize;
  }
}

.input-text {
  font-size: $fontSize;
  width: 100%;

  &__input {
    color: rgb(60, 66, 87);
  }

  &__container {
    border: solid 0;
    background-color: white;
    font-size: $fontSize;
    font-weight: bold;
    list-style-type: none;
    color: $headerColor;
    border-bottom: solid 1px $headerColor;
    padding-left: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: $headerColor;
      color: white;
    }

    &--active {
      background-color: $headerColor;
      color: white;

      &:hover {
        background-color: $headerColorHover;
      }
    }
  }
}

.street {
  padding: 0 0.8rem;
}

@media screen and (max-width: 1310px) {
  .address-input {
    padding: 0;
  }
}
</style>