import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2dbb654a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-file" }
const _hoisted_2 = {
  for: "file",
  class: "file-name-box input"
}
const _hoisted_3 = {
  for: "file",
  class: "file-selector-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.fileName), 1),
    _createElementVNode("input", {
      class: "form-file-input",
      type: "file",
      id: "file",
      accept: "image/png, image/jpeg, .pdf, .xlsx, .xls, .csv",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFileUpload && _ctx.handleFileUpload(...args)))
    }, null, 32),
    _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.translate('FILE.CHOOSE')), 1)
  ]))
}