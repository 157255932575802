<script setup lang="ts">
import moment from 'moment';
import {computed, inject, ref} from "vue";
import {VueCookies} from "vue-cookies";

import BoxOutputWelcome from "@/components/box-output/BoxOutputWelcome.vue";
import XMasBonhommeContainer from "@/components/x-mas/XMasBonhommeContainer.vue";
import XMasFlake from "@/components/x-mas/XMasFlake.vue";
import XMasWelcome from "@/components/x-mas/XMasWelcome.vue";

const $cookies = inject<VueCookies>("$cookies")

const showBonhommeContainer = ref(false)

const bonhommes = computed(() => {
  return $cookies?.get("bonhommes")
})

const is15Dec = computed(() => {
  const start = moment("2023-12-15", "YYYY-MM-DD").format()
  const end = moment("2023-12-31", "YYYY-MM-DD").format()
  const now = moment()
  return moment(now).isBetween(start, end)
})

const isNewYear = computed(() => {
  const start = moment("2024-01-01", "YYYY-MM-DD").format()
  const end = moment("2024-01-14", "YYYY-MM-DD").format()
  const now = moment()
  return moment(now).isBetween(start, end)
})
</script>

<template>
  <div v-if="is15Dec || isNewYear" class="x-mas-welcome-homepage">
    <XMasWelcome :content="is15Dec ? 'christmas' : 'new-year'" @show-bonhomme="showBonhommeContainer = true"/>
    <XMasBonhommeContainer v-if="showBonhommeContainer" :bonhommes="bonhommes"/>
    <XMasFlake/>
  </div>
  <BoxOutputWelcome class="home-logo" v-else/>
</template>

<style scoped lang="scss"></style>