import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "backoffice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.homeButtonsDisplay, (button) => {
      return (_openBlock(), _createBlock(_component_Button, {
        class: "backoffice__action",
        label: _ctx.translate(`BUTTONS.${button.label}`),
        onClick: ($event: any) => (_ctx.redirect(button.action)),
        "action-icon": !_ctx.homeButtonsAvailable.includes(button.label)  ? 'padlock-blue.png' : '',
        disabled: !_ctx.homeButtonsAvailable.includes(button.label),
        title: `${!_ctx.homeButtonsAvailable.includes(button.label)  ?  'COMING SOON' : ''}`
      }, null, 8, ["label", "onClick", "action-icon", "disabled", "title"]))
    }), 256))
  ]))
}