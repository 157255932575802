import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "backoffice__group_actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SeeAction = _resolveComponent("SeeAction")!
  const _component_EditAction = _resolveComponent("EditAction")!
  const _component_DeleteAction = _resolveComponent("DeleteAction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.see)
      ? (_openBlock(), _createBlock(_component_SeeAction, {
          key: 0,
          item: "item",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('see')))
        }))
      : _createCommentVNode("", true),
    (_ctx.edit)
      ? (_openBlock(), _createBlock(_component_EditAction, {
          key: 1,
          item: "item",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit')))
        }))
      : _createCommentVNode("", true),
    (this.delete)
      ? (_openBlock(), _createBlock(_component_DeleteAction, {
          key: 2,
          item: "item",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('delete')))
        }))
      : _createCommentVNode("", true)
  ]))
}