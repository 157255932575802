<script setup lang="ts">
import {
  cancelAction,
  confirmAction,
  Notification,
  notificationMessagePrefix,
  notificationMessageSuffix,
  notificationTitle
} from "@/utils/notifications";
import {ref} from "vue";

const emit = defineEmits(['delete-notification']);
const isDeletedActionTriggered = ref(false);

const props = withDefaults(defineProps<{
  notification: Notification;
}>(), {});

// methods
const handleNotificationDelete = () => {
  isDeletedActionTriggered.value = true;
};

const confirmDelete = () => {
  isDeletedActionTriggered.value = false;
  emit('delete-notification', props.notification);
};

const cancelDelete = () => {
  isDeletedActionTriggered.value = false;
};

</script>

<template>
  <div class="notification-item" :class="{ 'blur-content': isDeletedActionTriggered }">
    <div class="notification-header">
      <span class="business-id">{{ notificationTitle(notification) }}</span>
      <span class="timestamp">{{ notification.timestamp }}</span>
    </div>
    <div class="notification-body">
      <span class="message" >{{ notificationMessagePrefix(notification) }}
        <span v-if="notification.businessId" class="id"> {{ notification.businessId }}</span>
        {{ notificationMessageSuffix(notification) }}</span>
      <div v-if="!notification.viewed" class="orange-dot"></div>
      <img v-else alt="Delete" :src="require('/public/images/close.png')" class="delete-notification"
           @click="handleNotificationDelete"/>
    </div>
    <div v-if="isDeletedActionTriggered" class="confirm-delete-notification" v-on:mouseleave="cancelDelete">
      <Button class="confirm-action" @click="confirmDelete">{{ confirmAction() }}</Button>
      <Button class="cancel-action" @click="cancelDelete">{{ cancelAction() }}</Button>
    </div>
  </div>
</template>