<script setup lang="ts">
import {ref, watch} from 'vue';

const showMenu = ref(false);
let timer: NodeJS.Timeout | null = null;

const emit = defineEmits(['menuBurgerOpened']);

const props = defineProps<{
  menuClass: string,
  showMenu: boolean,
  openedComponent: string
}>();

const startTimer = () => {
  timer = setTimeout(() => {
    showMenu.value = false;
  }, 5000);
};

const stopTimer = () => {
  if (timer !== null) {
    clearTimeout(timer);
    timer = null;
  }
};

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
  emit('menuBurgerOpened', 'MenuBurger');
};

watch(showMenu, (newValue) => {
  if (newValue) {
    startTimer();
  } else {
    stopTimer();
  }
});

watch(() => props.openedComponent, (newVal) => {
  if (newVal !== 'MenuBurger') {
    showMenu.value = false;
  }
});

</script>
<template>
  <div @click="toggleMenu" class="box" :class="{'active': showMenu, 'not-active': !showMenu}">
    <div class="btn-menu">
      <span class="span-burger"></span>
      <span class="span-burger"></span>
      <span class="span-burger"></span>
    </div>
    <transition name="slide">
      <div v-if="showMenu" :class="menuClass">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>