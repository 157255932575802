import api from "@/utils/api";
import envConfig from "@/utils/envConfig";

export const companyDetails = (registrationNumber: string, journeyId: string, country = 'FR' ) =>
  api.put(`${envConfig.CHECK_COMPANY_ROUTE}/v3/company-details?journeyId=${journeyId}`, {
    "registrationNumber": registrationNumber,
    "coverage": "FULL",
    "depth": 1,
    "country": country
  })
    .then((response) => response);

export const taxReportJson = (siren: string, journeyId: string, fileId: string, country: string) =>
  api.put(`${envConfig.CHECK_COMPANY_ROUTE}/v3/company-details/attachments/json?journeyId=${journeyId}`, {
    "registrationNumber": siren,
    "fileId": fileId,
    "coverage": "FULL",
    "type": "TAX_REPORT",
    "country": country
  })
    .then((response) => response);

export const downloadAttachmentsPdf = (registrationNumber: string, journeyId: string, fileId: string, type: string, submissionDate: string, country?: string) =>
  api.put(`${envConfig.CHECK_COMPANY_ROUTE}/v3/company-details/attachments/pdf?journeyId=${journeyId}`, {
    "registrationNumber": registrationNumber,
    "fileId": fileId,
    "type": type,
    "country": country || 'FR',
    "coverage": "FULL"
  }, {
    responseType: "blob"
  }).then(({data}) => data)
    .then(data => {
      const href = URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${registrationNumber}_${submissionDate.replaceAll('/', '-')}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  );
