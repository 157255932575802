<template>
  <div class="bar-background">
    <div class="bar-progress" :style="barProgressStyle">{{Math.round(progress * 100)}}%</div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
  props: {
    value: {type: Number, default: 0},
    max: {type: Number, default: 1},
  },
  computed: {
    progress(){
      return this.value/this.max;
    },
    barProgressStyle() {
      return {
        'width': (0.05 + this.progress * 0.95) * 100+'%'}
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables.scss";

.batch-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bar-background {
  background-color: $greyLight;
  width: 100%;
  text-align: center;
  border-radius: 10rem;
  border: 1px solid black;
  margin: 0.1rem;
}

.bar-progress {
  background-color: $greenMeelo;
  border-radius: 10rem;
  border-right: 1px solid black;
  transition: width 0.5s ease;
}
</style>
