<script setup lang="ts">
import {ref} from 'vue';
import {translate} from "@/i18n";
import BackOfficeUserModal from "@/components/backoffice/BackOfficeUserModal.vue";
import {compareRole} from "@/utils/backOffice";
import {ApiUserResponseDto} from "@/apiCalls/login";
import {useRouter} from "vue-router";

const props = withDefaults(defineProps<{
  contentColumns: any,
  titles: Object,
  actionColum: Object,
  filterElement: Array<any>,
  supervisors: Array<any>,
  groups: Array<any>,
  places: Array<any>,
  user: ApiUserResponseDto,
  profiles: Array<any>,
  profilesChecked: Array<any>,
}>(), {});

const emit = defineEmits(['update-user', 'delete-user']);
const router = useRouter();

const editUserModalFlag = ref(false);
const contentEditModal = ref(undefined as ApiUserResponseDto | undefined);
const firstStepEdit = ref(true);
const roleUpdate = ref('COMPANY_USER' as 'COMPANY_MANAGER' | 'COMPANY_USER');
const checkBoxRoleEditUpdate = ref(false);
const profilesUser = ref([] as any);

const editUserModal = (index: any) => {
  contentEditModal.value = {...props.contentColumns[index]};
  profilesUser.value = contentEditModal.value?.profiles?.filter((profile: any) => profile.name !== "ADMIN_ACCESS")?.map((item: any) => {
    return {
      name: item.name,
      enabled: item.enabled
    };
  });
  checkBoxRoleEditUpdate.value = false;
  roleUpdate.value = 'COMPANY_USER';
  editModal();
}

const validateUpdateUser = () => {
  changeStep();
  editModal();
  if (checkBoxRoleEditUpdate.value) roleUpdate.value = 'COMPANY_MANAGER';
  emit('update-user', contentEditModal.value, roleUpdate.value, profilesUser.value);
  router.push('/back-office/users');
}

const editModal = () => {
  editUserModalFlag.value = !editUserModalFlag.value;
}

const cancelUpdateUser = () => {
  firstStepEdit.value = true;
  editModal();
}

const changeStep = () => {
  firstStepEdit.value = !firstStepEdit.value;
}
</script>
<template>
  <div class="backoffice-user-table">
    <table>
      <thead>
      <tr>
        <th v-for="colsTitle in titles" scope="col" v-bind:key="colsTitle">{{ colsTitle }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in contentColumns" :key="index">
        <td v-for="(value, key) in Object.keys(item).filter(elt => !filterElement.includes(elt))"
            :data-label="key"
            :key="key + index">
          <template v-if="value === 'enabled'">{{ item[value] ? translate('YES') : translate('NO') }}</template>
          <template v-else>{{ item[value] }}</template>
        </td>
        <td>
          <div class="backoffice-user-table__action-column">
            <router-link :to="`/back-office/users/${item.userId}/card`"
                         class="backoffice-user-table__action-column__link backoffice-user-table__action-column__link__see">
              <img src="/images/see.png" alt="see"
                   class="backoffice-user-table__action-column__see"/>
            </router-link>
            <router-link v-if="compareRole(user, item) >= 1" :to="`/back-office/users`"
                         @click="editUserModal(index)"
                         class="backoffice-user-table__action-column__link backoffice-user-table__action-column__link__edit">
              <img src="/images/pen.png" alt="edit"
                   class="backoffice-user-table__action-column__edit"/>
            </router-link>
          </div>
        </td>
      </tr>
      <BackOfficeUserModal v-if="contentEditModal" :modal-flag="editUserModalFlag" :content="contentEditModal"
                           :step="firstStepEdit"
                           @validate-action="validateUpdateUser" @next-step="changeStep"
                           @cancel-action="cancelUpdateUser"
                           :supervisors="supervisors" v-model:check-box="checkBoxRoleEditUpdate" :places="places"
                           :groups="groups" :user="user" :title="translate('BACKOFFICE.USERS.USER_MODIFICATION')"
                           :profiles="profiles" v-model:profiles-checked="profilesUser"
                           @back-first-page="changeStep"/>
      </tbody>
    </table>
  </div>
</template>
