import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", {
    class: _normalizeClass({'page-table': true, 'page-table__white':_ctx.white})
  }, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, ({title}) => {
          return (_openBlock(), _createElementBlock("th", { key: title }, _toDisplayString(title), 1))
        }), 128))
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderedContent, (line, indexLine) => {
        return (_openBlock(), _createElementBlock("tr", { key: indexLine }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(line, ({html, value, component, events, props}, indexColumn) => {
            return (_openBlock(), _createElementBlock("td", { key: indexColumn }, [
              html
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
                : value
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(value), 1)
                    ], 64))
                  : component
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(component), _mergeProps({
                        key: 2,
                        item: _ctx.content[indexLine]
                      }, _toHandlers(events), props), null, 16, ["item"]))
                    : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      }), 128))
    ])
  ], 2))
}