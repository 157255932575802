<script setup lang="ts">
import {ref, reactive, computed} from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import {translate} from "@/i18n";
import ScoreCircleChart from "@/components/ScoreCircleChart.vue";
import LabelValuePrinter from "@/components/LabelValuePrinter.vue";
import {isObject, isString} from "lodash";
import {causesCreditSafeType} from "@/types/creditSafe";

const props = withDefaults(defineProps<{
  scoreCreditSafe: number;
  causesCreditSafe: causesCreditSafeType;
  colorCreditSafeLoc: string;
  colorCreditSafeInter: string;
}>(), {
  colorCreditSafeLoc: "#D9D9D9",
  colorCreditSafeInter: "#D9D9D9",
});

const decisionFinal = ref<boolean>(false);
const stickerValues = reactive({
  label: translate("NEW"),
});

const displayHelperText = (decision: boolean) => {
  if (decision) decisionFinal.value = true;
};

const translateScore = (note: string): number => {
  switch (note) {
    case "A":
      return 100;
    case "B":
      return 70;
    case "C":
      return 50;
    case "D":
      return 30;
    case "E":
      return 1;
    default:
      return 0;
  }
};

const showBox = computed(() => {
  return isObject(props.causesCreditSafe) && Object.keys(props.causesCreditSafe).length > 0 && props.scoreCreditSafe;
});

const dataCircle = computed(() => {
  return {
    localScore: {
      class: 'score-local',
      value: Math.round(props.scoreCreditSafe),
      value_without_percentage: '',
      width: '50%',
      title: translate('SCORE.LOCAL'),
      tooltip: translate('SCORE.LOCAL'),
      color: props.colorCreditSafeLoc,
      helper_text: false,
      type_score: '',
    }, internationalScore: {
      class: 'score-inter',
      value: translateScore(isString(props.causesCreditSafe.internationalScore) ? props.causesCreditSafe.internationalScore : '-'),
      value_without_percentage: props.causesCreditSafe.internationalScore,
      width: '50%',
      title: translate('SCORE.INTERNATIONAL'),
      tooltip: translate('SCORE.INTERNATIONAL'),
      color: props.colorCreditSafeInter,
      helper_text: false,
      type_score: '',
    }
  };
});
</script>

<template>
  <ContentCard
      :title="translate('SCORING')"
      class="scoring-credit-safe"
      :sticker="stickerValues">
    <div v-if="showBox" class="scoring-credit-safe-container">
      <div class="scoring-credit-safe-score">
        <ScoreCircleChart v-for="circle in dataCircle"
                          :class="`circle-chart ${circle.class}`"
                          :circle-value="circle.value"
                          :circle-width="circle.width"
                          :circle-title="circle.title"
                          :circle-value-without-percentage="isString(circle.value_without_percentage) ? circle.value_without_percentage : '-'"
                          :tooltip="circle.tooltip"
                          :circle-color="circle.color"
                          :helper-text-display="circle.helper_text"
                          @as-text="displayHelperText"
                          :type-score="circle.type_score"/>
        <div v-if="decisionFinal" class="score-helper-text-credit-safe">
          <span>{{ translate("SCORE.HELPER_TEXT") }}</span>
        </div>
      </div>
      <div class="scoring-credit-safe-input">
        <LabelValuePrinter class="financial-analysis-bloc-recommended-outstanding"
                           :label="translate('COMPANY.RECOMMENDED_OUTSTANDING')"
                           :value="causesCreditSafe.limitCredit ? causesCreditSafe.limitCredit?.value + ' €' : '-'"/>
        <img class="credit-safe-logo" src="../../../../public/images/creditsafe-logo.png" alt="credit-safe-logo"/>
      </div>
    </div>
    <div v-else class="scoring-credit-safe-container">
      <span class="scoring-credit-safe-container__no-data">{{translate('ERRORS.CREDIT_SAFE')}}</span>
    </div>
  </ContentCard>
</template>