import router from "@/router";

export function redirect(path: string) {
  return router.push(`${path}`);
}

export function isDataAvalaible(data: any) {
  return data.data
    ? data.data.status !== "FAILED" &&
        data.data.statusReason !== "Data not available with all our providers"
    : true;
}

export default { redirect };
