import { Module } from 'vuex';

interface TaskState {
  taskData: any;
}

const taskModule: Module<TaskState, any> = {
  namespaced: true,
  
  state: () => ({
    taskData: {},
  }),

  mutations: {
    setTaskData(state, data: any) {
      state.taskData = data;
    },
  },

  actions: {
    updateTaskData({ commit }, data: any) {
      commit('setTaskData', data);
    },
  },

  getters: {
    getTaskData: (state) => state.taskData,
  },
};

export default taskModule;