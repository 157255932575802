<script setup lang="ts">
import {useRoute} from "vue-router";
import {ref, watch} from "vue";
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import CopyToClipboard from "@/components/copy-to-clipboard/CopyToClipboard.vue";
import {OwnerRepresentative} from "@/types/OwnerRepresentative";
import {useOwnerRepresentative} from "@/composables/useOwnerRepresentative";

const props = withDefaults(
    defineProps<{
      ownerRepresentatives: Array<OwnerRepresentative>,
      clearPerson: number,
      disableButtons: boolean,
      marketType: string,
      permissionToSelect: boolean,
      canPerson: boolean,
      title: string,
      type: string,
      country: string
    }>(),
    {
      clearPerson: 0,
      disableButtons: false,
      marketType: '',
      permissionToSelect: false,
      canPerson: false
    }
);

const route = useRoute();
const ownerRepresentative = useOwnerRepresentative();
const emit = defineEmits(['person-data-emitted']);

const selectedPersonKey = ref();

const labelButton = (key: number) => {
  return selectedPersonKey.value !== key ? translate('BUTTONS.SELECT') : translate('BUTTONS.SELECTED');
};

const selectPerson = (key: number, person: OwnerRepresentative) => {
  ownerRepresentative.displayButton(person, route.path, props.permissionToSelect);
  labelButton(key);
  selectedPersonKey.value = key;
  let personInfo = {
    type: props.type,
    key,
    address: person.address ? person.address : null,
    lastName: person.lastName,
    firstNames: person.firstNames,
    birthDate: person.dateOfBirth
  };
  emit('person-data-emitted', personInfo);
}

watch(() => props.clearPerson, (newVal) => {
  if (newVal) selectedPersonKey.value = null;
});
</script>

<template>
  <ContentCard v-if="!canPerson" :title="title" class="owner-representative" :checked-display-content="false"
               :is-padlock="true"/>
  <ContentCard v-else :title="title" class="owner-representative">
    <div v-if="ownerRepresentatives?.length === 0" class="owner-representative-no-data">
      <span>{{
          `${type === 'businessOwner' ? translate('COMPANY.NO_BUSINESS_OWNERS_KNOWN') : translate('COMPANY.NO_REPRESENTATIVES_KNOWN')}`
        }}</span>
    </div>
    <div v-else v-for="(person, key) in ownerRepresentatives" :key="key" :id="`${type}_${key}`"
         :class="`owner-representative-content ${selectedPersonKey === key ? 'selectedPerson' : ''}`">
      <div class="owner-representative-content-information">
        <div v-for="data in ownerRepresentative.getDataPerson(person, type)" :class="`${data.class}`">
          <a v-if="data.link && person.address" :href="ownerRepresentative.getUrlGoogleMaps(person.address)"
             target="_blank">{{ `${data.value}` }}</a>
          <span v-else>{{ `${data.value}` }}</span>
          <CopyToClipboard v-if="data.copy_to_clipboard" class="owner-representative-content-information-copy"
                           :value="`${data.value}`"/>
        </div>
      </div>
      <div class="owner-representative-content-number">
        <span v-for="data in ownerRepresentative.getShareVote(person)"
              class="owner-representative-content-number-percentage">{{ data }}</span>
        <Button
            v-if="country !== 'BE' && ownerRepresentative.displayButton(person, route.path, props.permissionToSelect)"
            class="owner-representative-content-percentage-button" :label="labelButton(key)"
            @click="selectPerson(key, person)" :disabled="selectedPersonKey === key || disableButtons"/>
      </div>
    </div>
  </ContentCard>
</template>
