<script setup lang="ts">
import {computed} from "vue";
import ContentCard from "@/components/card/ContentCard.vue";
import ScoreCircleChart from "@/components/ScoreCircleChart.vue";
import Loader from "@/components/loader/Loader.vue";
import {translate} from "@/i18n";
import {isNull} from "lodash";

const props = withDefaults(defineProps<{
  score: number,
  color: string,
  permissions: Array<string>
}>(), {
  score: 0,
  color: '#D9D9D9'
});

const canScore = computed(() => {
  return props.permissions?.includes('KIT_B2C_WEB_SCORE_SCORE');
})
</script>

<template>
  <ContentCard v-if="!canScore" :checked-display-content="false" :is-padlock="true"
               :title="translate('CONFIDENCE_SCORE')" class="applicant-b2c"/>
  <ContentCard v-else class="applicant-b2c" :title="translate('CONFIDENCE_SCORE')">
    <Loader v-if="isNull(score)"/>
    <div v-else class="applicant-b2c__lite-result">
      <div class="applicant-b2c__lite-result-score">
        <span class="applicant-b2c__lite-result-score__title">{{ translate('CONFIDENCE_INDEX') }}</span>
        <ScoreCircleChart class="circle-chart score-fraud" :circle-value="Math.round(score)" :circle-width="'30%'"
                          :circleColor="color" :helper-text-display="true"/>
        <div>
        </div>
      </div>
    </div>
  </ContentCard>
</template>