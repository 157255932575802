<script setup lang="ts">
import ResultCard from "@/components/card/ResultCard.vue";
import {translate} from "@/i18n";
import {ref} from "vue";

const props = withDefaults(defineProps<{
  usageWithoutAll: Object,
  scoresUsages: any
}>(), {});

const calculateTotal = (period: string | number, usage: string | number) => {
  const data = props.scoresUsages[usage][period];
  return Object.values(data).reduce((total: any, value: any) => total + value, 0);
}
</script>
<template>
  <div class="monitoring__my-usage__scores-usages">
    <ResultCard v-for="(usage, index) in Object.keys(usageWithoutAll)" :key="index"
                :title="translate(`BACKOFFICE.MONITORING.CONSUMPTION.${usage}`)">
      <span>{{ `${translate('BACKOFFICE.MONITORING.TODAY')} : ${calculateTotal('today', usage)}` }}</span>
      <span>{{ `${translate('BACKOFFICE.MONITORING.THIS_WEEK')} : ${calculateTotal('thisWeek', usage)}` }}</span>
      <span>{{ `${translate('BACKOFFICE.MONITORING.LAST_WEEK')} : ${calculateTotal('lastWeek', usage)}` }}</span>
      <span>{{ `${translate('BACKOFFICE.MONITORING.THIS_MONTH')} : ${calculateTotal('thisMonth', usage)}` }}</span>
      <span>{{ `${translate('BACKOFFICE.MONITORING.LAST_MONTH')} : ${calculateTotal('lastMonth', usage)}` }}</span>
      <hr>
      <span>{{ `${translate('BACKOFFICE.MONITORING.GENERAL_CONSUMPTION')} : ${calculateTotal('total', usage)}` }}</span>
    </ResultCard>
  </div>
</template>