import {apiBackOffice as api} from '@/utils/api';
import envConfig from '@/utils/envConfig';
import {IPageResponse} from '@/types/Page';
import {GroupRequest, GroupResponse, SearchGroupsRequest} from '@/types/Groups';

export const searchGroups = (data: SearchGroupsRequest) =>
    api.post<IPageResponse<GroupResponse>>(`${envConfig.BACKEND_ROUTE}/iam/v5/client/admin/groups/search`, data);

export const createGroup = (data: GroupRequest) =>
    api.post(`${envConfig.BACKEND_ROUTE}/iam/v5/client/admin/groups`, data);

export const updateGroup = (groupName: string, data: GroupRequest) =>
    api.put(`${envConfig.BACKEND_ROUTE}/iam/v5/client/admin/groups/${groupName}`, data);

export const deleteGroup = (groupName: string, force?: boolean) => api.delete(`${envConfig.BACKEND_ROUTE}/iam/v5/client/admin/groups/${groupName}?${force ? 'force=true' : ''}`);
