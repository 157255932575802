<template>
  <div class="end-of-journey">
    <span class="end-of-journey-title">{{ `${translate('WELCOME')}` }}</span>
    <img class="end-of-journey-image" :src="companyLogo.src" :alt="companyLogo.alt">
  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import {mapGetters} from 'vuex';

import {translate} from "../../i18n";

import Loader from "@/components/loader/Loader.vue";
import Button from "@/components/button/Button.vue";

export default defineComponent({
  methods: {
    translate,
    goToHome() {
      this.$emit('go-home');
    }
  },
  components: {Button, Loader},
  props: {
    logo: {type: String, default: 'logo_vertical_meelo_blue.png'}
  },
  computed: {
    ...mapGetters(['companyLogo']),
  }
})
</script>