import api from "@/utils/api";
import envConfig from "@/utils/envConfig";
import {getData} from "@/apiCalls/taskApi";
import {OPEN_BANKING_FIELDS} from "@/utils/dtoFields";

export const callOpenbanking = (phoneNumber: string | null, journeyId: string, mail?: string | null) =>
    api.post(`${envConfig.OPENBANKING_ROUTE}/v1/generate-link?journeyId=${journeyId}`, {
        requestOptions: {
            phoneNumber: phoneNumber,
            email: mail,
            country: 'FR'
        }
    }).then((response) => response);

export const callOpenbankingTask = (journeyId: string, taskId: string, success: Function, failed: Function) => {
    return setInterval(function () {
        getData(journeyId, OPEN_BANKING_FIELDS)
            .then((response) => {
                if (response.data.data["open_banking_data_summary"].length > 0 && response.data.data["open_banking_data_summary"][0]) {
                    success(response);
                }
            })
    }, 7500);
}

export const downloadOpenBankingInfos = (taskId: string, type: "INPUT" | "OUTPUT") => {
    return api.get(`${envConfig.OPENBANKING_ROUTE}/v1/download-infos/${taskId}?type=${type}`)
        .then((response) => response).catch((error) => error.response);
}
