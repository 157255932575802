import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "upload-authenticity" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_file = _resolveComponent("form-file")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_file, {
      file: _ctx.file_upload,
      onUpdate: _ctx.storeFileTemp
    }, null, 8, ["file", "onUpdate"]),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.message), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validateDocument(_ctx.file))),
      label: _ctx.translate('BUTTONS.VALIDATE'),
      disabled: _ctx.isButtonDisabled
    }, null, 8, ["label", "disabled"])
  ]))
}