<template>
  <div class="historic_group">
    <XMasBonhomme :style="{top: '18vh', left: '76vw'}" :value="2"/>
    <p class="title"> {{ translate('HISTORIC_PAGE.HISTORIC_TITLE') }}</p>
    <ConsumptionSearch v-if="!user?.roles?.includes('COMPANY_USER')" :users="users" @search="searchJourneys"
                       @reset="resetJourneys" @select-all="allUsersSelected"
                       @select-company="companySelected" @select-my-team="myTeamSelected"
                       :should-reset="toReset" :user="user"/>
    <ul class="tabs">
      <template v-for="kit in kitNames">
        <li v-if="kit === 'KIT_B2C'" :class="{'tab': true, 'active': marketType === 'portal-b2c'}"
            @click="changeTab('portal-b2c')">B2C
        </li>
        <li v-if="kit === 'KIT_B2B'" :class="{'tab': true, 'active': marketType === 'portal-b2b'}"
            @click="changeTab('portal-b2b')">B2B
        </li>
        <li v-if="kit === 'KIT_OB'" :class="{'tab': true, 'active': marketType === 'open-banking'}"
            @click="changeTab('open-banking')">
          {{ translate("OPEN_BANKING.TITLE") }}
        </li>
        <li v-if="kit === 'KIT_DOC'" :class="{'tab': true, 'active': marketType === 'document-analysis'}"
            @click="changeTab('document-analysis')">
          {{ translate("DOCUMENT_ANALYSIS") }}
        </li>
      </template>
    </ul>
    <MeeloTable :titles="title" class="meelo-table" :content-columns="rows" @delete-folder="openModalDeleteFolder"
                :is-loading="loaderRows"/>
    <DeleteFolderModal v-model:openModal="modalDeleteFolder" @confirm-selection="deleteFolder"
                       :case-number="businessIdToDelete"/>
    <div class="page-navigation">
      <button-meelo v-if="pageId <= totalPage && pageId > 0" @click="movePages(-1)"
                    :label="translate('TABLE.BACK')"/>
      <p>{{ pageId + 1 }} / {{ totalPage }}</p>
      <button-meelo v-if="pageId < totalPage - 1 && pageId >= 0" @click="movePages(1)"
                    :label="translate('TABLE.NEXT')"/>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {isArray, isEmpty, isNil, isNull, isNumber} from "lodash";
import MeeloTable from "@/components/MeeloTable.vue";
import {translate} from "@/i18n";
import ButtonMeelo from "@/components/button/Button.vue";
import XMasBonhomme from "@/components/x-mas/XMasBonhomme.vue";
import {
  getHistorical,
  getHistoricalDocument,
  addJourneyIdStatus
} from "@/apiCalls/taskApi";
import {
  DOCUMENTS_FIELDS,
  HISTORICAL_B2B_FIELDS,
  HISTORICAL_B2C_FIELDS,
  HISTORICAL_OPEN_BANKING_FIELDS
} from "@/utils/dtoFields";
import {mapGetters} from "vuex";
import TemplateMixin from "@/mixins/templateMixin";
import utilsMixin from "@/mixins/utilsMixin";
import DeleteFolderModal from "@/components/modal/DeleteFolderModal.vue";
import {getUsersByRole} from "@/apiCalls/backOfficeApi";
import {getMyTeam} from "@/apiCalls/login";
import ConsumptionSearch from "@/components/search/consumption/ConsumptionSearch.vue";
import {displayAuthenticity} from "@/utils/createCheckFromData";
import {getStatusIcon} from "@/composables/formatKeyValuePairWithFunc";

const marketTypeFields = {
  'portal-b2c': HISTORICAL_B2C_FIELDS,
  'portal-b2b': HISTORICAL_B2B_FIELDS,
  'document-analysis': DOCUMENTS_FIELDS,
  'open-banking': HISTORICAL_OPEN_BANKING_FIELDS
} as any;

export default defineComponent({
  name: "HistoricalPage",
  components: {ConsumptionSearch, DeleteFolderModal, ButtonMeelo, MeeloTable, XMasBonhomme},
  mixins: [TemplateMixin, utilsMixin],
  computed: {
    title(): string[] {
      switch (this.marketType) {
        case 'portal-b2c': {
          return this.titleB2C;
        }
        case 'portal-b2b': {
          return this.titleB2B;
        }
        case 'open-banking': {
          return this.titleOB;
        }
        case 'document-analysis': {
          return this.title_document_analysis;
        }
      }
      return [];
    },
    ...mapGetters(['kits']),
    kitNames(): string {
      return this.kits.map((elt: { name: any; }) => elt.name).sort();
    },
    fieldsToDisplay(): Array<string> {
      return marketTypeFields[this.marketType] || [];
    },
    titleB2C(): Array<string> {
      return this.user?.roles.includes('COMPANY_USER') ? this.title_B2C.filter(elt => !elt.includes(translate('SYNTHESIS.ANALYST'))) : this.title_B2C;
    },
    titleB2B(): Array<string> {
      return this.user?.roles.includes('COMPANY_USER') ? this.title_B2B.filter(elt => !elt.includes(translate('SYNTHESIS.ANALYST'))) : this.title_B2B;
    },
    titleOB(): Array<string> {
      return this.user?.roles.includes('COMPANY_USER') ? this.title_openbanking.filter(elt => !elt.includes(translate('SYNTHESIS.ANALYST'))) : this.title_openbanking;
    }
  },
  data() {
    return {
      countByPage: 20,
      pageId: 0,
      totalPage: 1,
      rows: [] as any,
      journeyIds: [] as string[],
      title_B2B: [
        'TABLE.FILE_NUMBER',
        'STATE',
        'TABLE.DATE_AND_TIME',
        'SYNTHESIS.ANALYST',
        'TABLE.SIREN',
        'TABLE.COMPANY_NAME',
        'TABLE.MAIL_ADDRESS',
        'TABLE.TELEPHONE',
        'TABLE.OPERATOR',
        'TABLE.SCORE_B2B',
        'TABLE.SCORE_SMB',
        'WARNING_LIST.TITLE',
        'IDENTITY.IBAN',
        'TABLE.ACTIONS',
      ].map(translate),
      title_B2C: [
        'TABLE.FILE_NUMBER',
        'STATE',
        'TABLE.DATE_AND_TIME',
        'SYNTHESIS.ANALYST',
        'TABLE.LAST_NAME',
        'TABLE.FIRST_NAME',
        'TABLE.MAIL_ADDRESS',
        'TABLE.TELEPHONE',
        'TABLE.OPERATOR',
        'TABLE.SCORE',
        'WARNING_LIST.TITLE',
        'IDENTITY.IBAN',
        'TABLE.ACTIONS'
      ].map(translate),
      title_openbanking: [
        'TABLE.FILE_NUMBER',
        'STATE',
        'TABLE.DATE_AND_TIME',
        'SYNTHESIS.ANALYST',
        'OPEN_BANKING.BANK_NAME',
        'OPEN_BANKING.HOLDER',
        'OPEN_BANKING.ACCOUNT_NAME',
        'TABLE.SCORE',
        'TABLE.ACTIONS'
      ].map(translate),
      title_document_analysis: [
        'TABLE.FILE_NUMBER',
        'TABLE.DATE_AND_TIME',
        'SYNTHESIS.ANALYST',
        'TABLE.DOCUMENT_TYPE',
        'TABLE.DOCUMENT_NAME',
        'DOCUMENT_CONTROL.TABLE.COMPLIANCE',
        'DOCUMENT_CONTROL.TABLE.AUTHENTICITY',
        'TABLE.ACTIONS'
      ].map(translate),
      marketType: '' as 'portal-b2c' | 'portal-b2b' | 'open-banking' | 'document-analysis',
      B2B_card_title: ['HISTORIC_PAGE.TODAY', 'HISTORIC_PAGE.LAST_WEEK', 'HISTORIC_PAGE.ALL'].map(translate),
      fields: [] as any,
      modalDeleteFolder: false,
      journeyIdToDelete: '',
      businessIdToDelete: '',
      users: [],
      apiCall: '' as 'me' | 'users' | 'companies/me' | 'team',
      loaderRows: false,
      usersSearch: [] as number[],
      toReset: false
    }
  },
  methods: {
    translate,
    getStatusIcon,
    async getDataFromContext(marketType: string, res: any) {
      this.toReset = false;
      this.totalPage = res.pageCount;
      this.rows = res.journeys.map(({
                                      businessId,
                                      createdAt,
                                      id,
                                      apiUsername,
                                      status,
                                      data: {
                                        applicant,
                                        web_score_b2c,
                                        causes_web_score_b2c,
                                        company_legal_unit,
                                        smb_causes,
                                        smb_score,
                                        causes_web_score_b2b,
                                        web_score_b2b,
                                        web_score_b2b_v1,
                                        open_banking_account,
                                        open_banking_score,
                                        warning_list,
                                        check_iban_details,
                                      }
                                    }: any) => {
        if (marketType === "portal-b2c") {
          const uniqueApplicant = this.mergeObject(applicant);
          const response = {
            businessId: businessId,
            status: status,
            date: createdAt,
            analyst: apiUsername,
            name: uniqueApplicant?.lastname ? uniqueApplicant?.lastname : '-',
            firstname: uniqueApplicant?.firstname ? uniqueApplicant?.firstname : '-',
            email: uniqueApplicant?.email ? uniqueApplicant?.email : '-',
            phone: uniqueApplicant?.mobilePhone ? uniqueApplicant?.mobilePhone : '-',
            operator: causes_web_score_b2c && causes_web_score_b2c[0]?.contact?.phone_carrier ? causes_web_score_b2c[0]?.contact?.phone_carrier : '-',
            score: this.verifyScore(web_score_b2c),
            warning_list: (causes_web_score_b2c && causes_web_score_b2c[0])
                ? (causes_web_score_b2c[0].hasOwnProperty('warning_list') && (causes_web_score_b2c[0]?.warning_list?.phone !== 0 || causes_web_score_b2c[0]?.warning_list?.email !== 0)
                    ? translate('YES')
                    : '-')
                : '-',
            iban: this.getFirstElement(check_iban_details)?.analysisStatus || "-",          
            journeyId: id,
            path: 'analysis-b2c',
            synthesis: 'synthesis'
          };

          return this.deleteKeysInObjet(response);
        }

        if (marketType === "portal-b2b") {
          const uniqueApplicant = this.mergeObject(applicant);

          const response = {
            businessId: businessId,
            status: status,
            date: createdAt,
            analyst: apiUsername,
            SIREN: company_legal_unit ? company_legal_unit[0]?.registrationNumber : '-',
            company_name: company_legal_unit ? company_legal_unit[0]?.corporateName : '-',
            email: uniqueApplicant?.email ? uniqueApplicant.email : '-',
            phone: uniqueApplicant?.mobilePhone ? uniqueApplicant?.mobilePhone : '-',
            operator: smb_causes[0]?.contact?.phoneCarrier ? smb_causes[0]?.contact?.phoneCarrier : '-',
            web_score_b2b: isArray(web_score_b2b_v1) && web_score_b2b_v1.length > 0 ? this.verifyScore(web_score_b2b_v1) : this.verifyScore(web_score_b2b),
            score_smb: this.verifyScore(smb_score),
            warning_list: this.isWarningList(warning_list, causes_web_score_b2b, smb_causes),
            iban: this.getFirstElement(check_iban_details)?.analysisStatus || "-",
            journeyId: id,
            path: 'analysis-b2b',
            synthesis: 'synthesis'
          };

          return this.deleteKeysInObjet(response);
        }

        if (marketType === "open-banking") {
          if (open_banking_account[0]?.status === 'SUCCESS') {
            const response = {
              businessId: businessId,
              status: status,
              date: createdAt,
              analyst: apiUsername,
              bankName: !isNull(open_banking_account[0]?.bank_name) ? open_banking_account[0]?.bank_name : '-',
              holderName: !isNull(open_banking_account[0]?.holder_name) ? open_banking_account[0]?.holder_name : '-',
              accountName: !isNull(open_banking_account[0]?.name) ? open_banking_account[0]?.name : '-',
              score: open_banking_score && isNumber(open_banking_score[0].value) ? Math.round(open_banking_score[0].value) : null,
              journeyId: id,
              path: 'open-banking',
              synthesis: 'synthesis'
            }

            return this.deleteKeysInObjet(response);
          }
        }
      }).filter((elt: any) => !isEmpty(elt));
    },
    getDataForDocuments(res: any) {
      this.toReset = false;
      this.totalPage = res.pageCount;
      this.rows = res.documents.map(({
                                       businessId,
                                       createdAt,
                                       journeyId,
                                       apiUsername,
                                       filename,
                                       documentType,
                                       ocrAnalysisStatus,
                                       authenticityStatus
                                     }: any) => {
        return {
          businessId: businessId,
          date: createdAt,
          analyst: apiUsername,
          documentType: translate(`DOCUMENT_CONTROL.${documentType}`),
          fileName: filename,
          ocrAnalysisStatus: this.getCheckStatus(ocrAnalysisStatus),
          authenticityStatus: displayAuthenticity(authenticityStatus),
          journeyId: journeyId,
          path: 'document-analysis',
          synthesis: 'synthesis'
        }
      }).filter((elt: any) => !isEmpty(elt));
    },
    async movePages(amount: number) {
      this.loaderRows = true;
      const newStartRow = (this.pageId + amount);
      if (newStartRow >= 0) {
        this.pageId = newStartRow;
        this.fields = [];
        await this.switchSelection();
        this.loaderRows = false;
      }
    },
    async changeTab(marketType: 'portal-b2c' | 'portal-b2b' | 'open-banking' | 'document-analysis') {
      this.toReset = true;
      await this.resetJourneys(marketType);
    },
    displayScores(scores: Array<number>) {
      return scores.length > 1 ? scores.map(score => Math.round(score)).join('; ') : Math.round(scores[0])
    },
    async searchJourneys(usersSelected: number[]) {
      await this.historical('users', usersSelected);
    },
    async allUsersSelected(usersSelected: number[]) {
      this.usersSearch = usersSelected;
      this.pageId = 0;
      await this.searchJourneys(this.usersSearch);
    },
    async resetJourneys(marketType?: 'portal-b2c' | 'portal-b2b' | 'open-banking' | 'document-analysis') {
      if (marketType) this.marketType = marketType;
      this.pageId = 0;
      this.toReset = true;
      await this.historical('me');
    },
    async companySelected() {
      this.pageId = 0;
      await this.historical('companies/me');
    },
    async myTeamSelected() {
      this.pageId = 0;
      await this.historical('team');
    },
    async searchMyJourneys() {
      this.apiCall = 'me';
      await this.historical('me');
    },
    openModalDeleteFolder(item: any) {
      this.modalDeleteFolder = !this.modalDeleteFolder;
      this.journeyIdToDelete = item.journeyId;
      this.businessIdToDelete = item.businessId;
    },
    async deleteFolder() {
      this.modalDeleteFolder = !this.modalDeleteFolder;
      await addJourneyIdStatus(this.journeyIdToDelete, 'DELETED', 'string');
      await this.switchSelection();
      this.journeyIdToDelete = '';
      this.businessId = '';
    },
    getMarketType() {
      for (let i = 0; i < this.kits.length; i++) {
        if (this.kits[i].name === 'KIT_B2C') {
          this.marketType = 'portal-b2c';
          break;
        } else if (this.kits[i].name === 'KIT_B2B') {
          this.marketType = 'portal-b2b';
          break;
        } else if (this.kits[i].name === 'KIT_OB') {
          this.marketType = 'open-banking';
          break;
        } else if (this.kits[i].name === 'KIT_DOC') {
          this.marketType = 'document-analysis';
          break;
        }
      }
    },
    async getUsers() {
      if (this.user?.roles?.includes('COMPANY_ADMIN')) {
        this.users = await getUsersByRole(['COMPANY_USER', 'COMPANY_ADMIN', 'COMPANY_MANAGER']);
      } else if (this.user?.roles?.includes('COMPANY_MANAGER')) {
        this.users = await getMyTeam();
      }
    },
    deleteKeysInObjet(response: any) {
      if (this.user?.roles?.includes('COMPANY_USER')) delete response.analyst;
      return response;
    },
    verifyScore(scores: Array<number>) {
      return isArray(scores) && scores.length > 0 ? this.displayScores(scores) : '-'
    },
    async historical(callApi: 'me' | 'users' | 'companies/me' | 'team', usersSelected?: number[]) {
      this.usersSearch = [];
      if (isArray(usersSelected)) this.usersSearch = usersSelected;
      this.loaderRows = true;
      this.apiCall = callApi;
      if (this.marketType === 'document-analysis') {
        const res = await getHistoricalDocument({
          apiCall: this.apiCall,
          pageId: this.pageId,
          countByPage: this.countByPage,
          userIds: usersSelected
        });
        this.getDataForDocuments(res);
      } else {
        const res = await getHistorical({
          apiCall: this.apiCall,
          countByPage: this.countByPage,
          fields: this.fieldsToDisplay,
          marketType: [this.marketType],
          pageId: this.pageId,
          userIds: usersSelected,
        });
        if (!isEmpty(res)) await this.getDataFromContext(this.marketType, res);
      }
      this.loaderRows = false;
    },
    async switchSelection() {
      switch (this.apiCall) {
        case "me":
          await this.historical('me');
          break;
        case "team":
          await this.historical('team');
          break;
        case "companies/me":
          await this.historical('companies/me');
          break;
        case "users":
          await this.historical('users', this.usersSearch);
          break;
      }
    },
    isWarningList(
      warning_list: any,
      causes_web_score_b2b: any,
      smb_causes: any
    ) {
      const localWarningList = warning_list.filter((el: any) => !isNil(el));
      const isWarningInList =
        isArray(localWarningList) &&
        localWarningList.length > 0 &&
        !isNull(localWarningList[0]) &&
        localWarningList[0].length > 0;

      const localCausesWebScoreB2B = causes_web_score_b2b.filter(
        (el: any) => !isNil(el)
      );
      const isWarningInB2B =
        !isEmpty(localCausesWebScoreB2B) &&
        !isNil(localCausesWebScoreB2B) &&
        (localCausesWebScoreB2B[0]?.warningList.email !== 0 ||
          localCausesWebScoreB2B[0]?.warningList.phone !== 0 ||
          localCausesWebScoreB2B[0]?.warningList.registrationNumber !== 0);

      const localSmbCauses = smb_causes.filter((el: any) => !isNil(el));
      const isWarningInSMB =
        !isEmpty(localSmbCauses) &&
        !isNil(localSmbCauses) &&
        (localSmbCauses[0]?.warningList.email !== 0 ||
          localSmbCauses[0]?.warningList.phone !== 0);

      return isWarningInList || isWarningInB2B || isWarningInSMB
        ? translate("YES")
        : "-";
    },
    getCheckStatus(status: string) {
      return getStatusIcon(status);
    }
  },
  async created() {
    this.getMarketType();
    await this.getUsers();
    await this.searchMyJourneys();
  }
})
</script>
