<template>
  <div>
    <vue3-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="synthese-portal-meelo"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <ResultsPdfExportRecovery
          :applicant="dataApplicant"
          :external-id="dataExternalId"
          :ob-formatted-fields="dataOpenBanking"
          :causes-fraud="dataCausesFraud"
          :color-score="dataColorScore"
          :abscissa-line-chart="dataAbscissaLineChart"
          :details-line-chart="dataDetailsLineChart"
          :abscissa-bar-chart="dataAbscissaBarChart"
          :details-bar-chart="dataDetailsBarChart"
        />
      </template>
    </vue3-html2pdf>
  </div>
</template>

<script lang="ts">
import Vue3Html2pdf from 'vue3-html2pdf'
import {defineComponent, onMounted, Ref, ref} from 'vue';
import ResultsPdfExportContent from '@/components/pdf-export/ResultsPdfExportContent.vue';
import ButtonMeelo from "@/components/button/Button.vue";
import ResultsPdfExportRecovery from "@/components/pdf-export/ResultsPdfExportRecovery.vue";
import LineChart from "@/components/chart/LineChart.vue";

export default defineComponent({
  components: {
    ResultsPdfExportRecovery,
    Vue3Html2pdf,
    ResultsPdfExportContent,
    ButtonMeelo,
    LineChart
  },
  data: () => ({
    dataApplicant: {},
    dataExternalId: '',
    dataOpenBanking: {},
    dataCausesFraud: {},
    dataColorScore:'',
    dataAbscissaLineChart:[],
    dataDetailsLineChart:[],
    dataAbscissaBarChart:[],
    dataDetailsBarChart:[]

  }),
  methods: {
    generateReport(applicant: any, externalId: string, openBanking: any, causesFraud: any, colorScore: string, abscissaLineChart: any, detailsLineChart: any, abscissaBarChart: any, detailsBarChart: any) {
      this.dataApplicant = applicant;
      this.dataExternalId = externalId;
      this.dataOpenBanking = openBanking;
      this.dataCausesFraud = causesFraud;
      this.dataColorScore = colorScore;
      this.dataAbscissaLineChart = abscissaLineChart;
      this.dataDetailsLineChart = detailsLineChart;
      this.dataAbscissaBarChart = abscissaBarChart;
      this.dataDetailsBarChart = detailsBarChart;
      (this.$refs.html2Pdf as any).generatePdf(applicant, externalId, openBanking,causesFraud,colorScore,abscissaLineChart,detailsLineChart,abscissaBarChart,detailsBarChart);
    }
  }
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";

</style>
