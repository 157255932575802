<template>
  <div class="manage-profile-and-access">
    <div class="manage-profile-and-access__company">
      <span class="manage-profile-and-access__subtitle">{{
          `${translate('BACKOFFICE.PROFILE_AND_ACCESS.COMPANY_PROFILES')} ${user?.companyName ? user?.companyName : ''}`
        }}</span>
      <span class="manage-profile-and-access__company__research">{{ `${translate('RESEARCH')} :` }}</span>
      <form class="manage-profile-and-access__form" @submit="searchProfile" @reset="resetSearch">
        <TextInput :label="`${translate('IDENTITY.NAME')} :`" v-model="searchProfileName"/>
        <div class="manage-profile-and-access__form__action">
          <Button :label="translate('BUTTONS.RESET')" type="reset"/>
          <Button :label="translate('BUTTONS.SEARCH')" type="submit"/>
        </div>
      </form>
      <hr>
      <div class="manage-profile-and-access__action">
        <Button :label="translate('BUTTONS.ADD')" picture="plus.png" @click="addProfileModal"/>
        <BackOfficeProfileModal :modal-flag="addProfileModalFlag" :content="contentAddProfile" :step="firstStepAdd"
                                @validate-action="validateProfile" @next-step="nextStep"
                                @cancel-action="cancelAddProfile" v-model:check-box="contentAddProfile.enabled"
                                :title="translate('BACKOFFICE.PROFILE_AND_ACCESS.ADDING_PROFILE')"/>
      </div>
      <ListTable v-if="profiles" class="manage-profile-and-access__table"
                 :content="profilesToDisplay" :columns="[{
      title: '',
      render: (item) => ({
      component: Radio,
      props: {
        checkedValue: displayPermission,
        name: 'selectProfile'
      },
      events: {
        'update:checkedValue': (e) => updateChecked(e, item)
      }})
    },{
      title: translate('IDENTITY.NAME'),
      render: ({name}) => ({value: name})
    },
    {
      title: translate('BATCH.DESCRIPTION'),
      render: ({description}) => ({value: description})
    }
    ,{
      title: translate('BACKOFFICE.PROFILE_AND_ACCESS.CREATED_BY'),
      render: ({createdBy}) => ({value: createdBy?.username ? createdBy.username : '-'})
    },
    {
      title: translate('BACKOFFICE.USERS.ACTIVE'),
      render: ({enabled}) => ({value: enabled ? translate('YES') : translate('NO')})
    },
    {
      title: translate('TABLE.ACTIONS'),
      render: (item) => ({
        component: BackOfficeGroupActions,
        events: {
          edit: ()=> edit(item),
          delete: ()=> {
            this.delete(item)
          }
        },
        props: {
          see: false,
          edit: user.roles?.includes('COMPANY_ADMIN') || user.userId === item?.createdBy?.id,
          delete: user.roles?.includes('COMPANY_ADMIN') ||  user.userId === item?.createdBy?.id
        }})
    }]" :white="true"/>
      <BackOfficeProfileModal :modal-flag="editProfileModalFlag" :content="contentEditProfile" :step="firstStepAdd"
                              @validate-action="validateEditProfile" @next-step="nextStep"
                              @cancel-action="cancelEditProfile" v-model:check-box="contentEditProfile.enabled"
                              :title="translate('BACKOFFICE.PROFILE_AND_ACCESS.PROFILE_MODIFICATION')"/>
      <CustomModal v-model:open="deleteProfileModalFlag"
                   class="backoffice-user-table__delete-modal manage-profile-and-access__delete-modal"
                   custom-class="modal-add-user">
        <ResultCard :title="translate('BACKOFFICE.PROFILE_AND_ACCESS.PROFILE_DELETION')">
          <div class="backoffice-user-table__delete-modal__summary">
            <span class="backoffice-user-table__delete-modal__summary__title">{{ `${translate('SUMMARY')} :` }}</span>
            <div class="backoffice-user-table__delete-modal__summary__informations">
              <span>{{
                  `${translate('IDENTITY.NAME')} : ${contentDeleteProfile.name ? contentDeleteProfile.name : '-'}`
                }}</span>
              <span>{{
                  `${translate('BATCH.DESCRIPTION')} : ${contentDeleteProfile.description ? contentDeleteProfile.description : '-'}`
                }}</span>
              <span>{{
                  `${translate('BACKOFFICE.USERS.ACTIVE')} : ${contentDeleteProfile.enabled ? translate('YES') : translate('NO')}`
                }}</span>
            </div>
          </div>
          <hr>
          <div class="backoffice-user-table__delete-modal__messages">
            <p class="backoffice-user-table__delete-modal__action-irreversible">
              {{ `${translate('BACKOFFICE.USERS.DELETE.ACTION_IRREVERSIBLE')}` }}</p>
            <p>{{ `${translate('BACKOFFICE.PROFILE_AND_ACCESS.MESSAGE_DELETION')}` }}</p>
            <p class="backoffice-user-table__delete-modal__question">{{
                `${translate('BACKOFFICE.PROFILE_AND_ACCESS.QUESTION_DELETION')}`
              }}</p>
          </div>
          <div class="backoffice-user-table__delete-modal__actions">
            <Button :label="translate('BUTTONS.CANCEL')" class="backoffice-user-table__user-modal__actions__cancel"
                    @click="cancelDeleteProfileModal"/>
            <Button :label="translate('BUTTONS.VALIDATE')"
                    class="backoffice-user-table__delete-modal__actions__validate"
                    @click="validateDeletionProfile"/>
          </div>
        </ResultCard>
      </CustomModal>
    </div>
    <div class="manage-profile-and-access__permissions">
      <span class="manage-profile-and-access__subtitle">{{
          translate('BACKOFFICE.PROFILE_AND_ACCESS.PERMISSIONS')
        }}</span>
      <span v-if="permissions.length > 0">{{
          `${translate('BACKOFFICE.PROFILE_AND_ACCESS.DESCRIPTION_PROFILE')} : ${currentProfileDescription ? currentProfileDescription : ''}`
        }}</span>
      <template v-if="permissions.length === 0">
        <span class="manage-profile-and-access__permissions__message">{{
            translate('BACKOFFICE.PROFILE_AND_ACCESS.MESSAGE_PERMISSIONS')
          }}</span>
      </template>
      <template v-if="permissions.length > 0">
        <div class="manage-profile-and-access__permissions__actions">
          <Button v-if="user?.roles?.includes('COMPANY_ADMIN') || currentProfileCreatedBy === user.userId"
                  class="manage-profile-and-access__permissions__actions__save"
                  :label="translate('BUTTONS.SAVE')" @click="savePermissions" picture="save.png"
                  :disabled="nbModification === 0"/>
        </div>
        <span v-if="nbModification !== 0">{{
            translate('BACKOFFICE.PROFILE_AND_ACCESS.SAVE_PERMISSION_MESSAGE')
          }}</span>
        <ListTable class="manage-profile-and-access__permissions__table" :content="permissions" :columns="[{
      title: translate('BACKOFFICE.PROFILE_AND_ACCESS.KIT'),
      render: ({kitName}) => ({value: kitName})
    },{
      title: translate('BACKOFFICE.PROFILE_AND_ACCESS.MODULE'),
      render: ({moduleName}) => ({value: moduleName})
    },
    {
      title: translate('BACKOFFICE.PROFILE_AND_ACCESS.RIGHTS'),
      render: ({permissionName}) => ({value: permissionName})
    },{
      title: '',
      render: ({kitName, moduleName, permissionName, enabled}) => ({
      component: Checkbox,
      props: {
        checkedValue: enabled,
        disabled: !(user?.roles?.includes('COMPANY_ADMIN') || currentProfileCreatedBy === user.userId)
      },
      events: {
        'update:checkedValue': (value:any) => {
          nbModification++;
          permissions.filter((p: any)=> p.kitName === kitName && p.moduleName === moduleName && p.permissionName === permissionName)[0].enabled = value
        }
      }})
    }]" :white="true"/>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import {translate} from "@/i18n";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import TextInput from "@/components/input/TextInput.vue";
import Button from "@/components/button/Button.vue";
import ListTable from "@/components/table/ListTable.vue";
import {
  createProfileByCompanyAdmin, deleteProfileByCompanyAdmin,
  editPermissionByCompanyAdmin,
  editProfileByCompanyAdmin,
  getAllPermissionsForAProfileByCompanyAdmin,
  getProfilesByCompanyAdmin
} from "@/apiCalls/backOffice/admin/profiles";
import {
  createProfileByCompanyManager,
  deleteProfileByCompanyManager, editPermissionByCompanyManager, editProfileByCompanyManager,
  getAllPermissionsForAProfileByCompanyManager,
  getProfilesByCompanyManager
} from "@/apiCalls/backOffice/manager/profiles";
import Checkbox from "@/components/checkbox/Checkbox.vue";
import BackOfficeProfileModal from "@/components/backoffice/BackOfficeProfileModal.vue";
import Radio from "@/components/radio/Radio.vue";
import BackOfficeGroupActions from "@/components/backoffice/BackOfficeGroupActions.vue";
import CustomModal from "@/components/modal/CustomModal.vue";
import ResultCard from "@/components/card/ResultCard.vue";
import {compareRole} from "@/utils/backOffice";
import login from "@/apiCalls/login";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  components: {ResultCard, CustomModal, BackOfficeProfileModal, ListTable, Button, TextInput},
  methods: {
    compareRole,
    translate,
    async savePermissions() {
      if (this.user?.roles?.includes('COMPANY_ADMIN')) {
        this.permissions = await editPermissionByCompanyAdmin(this.currentProfileName!, this.permissions.filter(({enabled}) => enabled));
      } else {
        this.permissions = await editPermissionByCompanyManager(this.currentProfileName!, this.permissions.filter(({enabled}) => enabled));
      }
      this.nbModification = 0;
      await this.fetchUser;
    },
    async updateChecked(e: any, item: any) {
      if (e === false) {
        this.permissions = [];
      } else {
        this.currentProfileName = item.name;
        this.currentProfileDescription = item.description;
        this.currentProfileCreatedBy = item.createdBy ? item.createdBy.id : '-';
        await this.getAllPermissions();
      }
    },
    async validateProfile() {
      if (this.user?.roles?.includes('COMPANY_ADMIN')) {
        await createProfileByCompanyAdmin(this.contentAddProfile?.newName, this.contentAddProfile?.description, this.contentAddProfile?.enabled);
      } else {
        await createProfileByCompanyManager(this.contentAddProfile?.newName, this.contentAddProfile?.description, this.contentAddProfile?.enabled);
      }
      this.addProfileModalFlag = !this.addProfileModalFlag;
      this.firstStepAdd = true;
      await this.getAllProfiles();
    },
    nextStep() {
      this.firstStepAdd = !this.firstStepAdd;
    },
    cancelAddProfile() {
      this.firstStepAdd = true;
      this.addProfileModalFlag = !this.addProfileModalFlag;
      this.contentAddProfile = {
        newName: '',
        description: '',
        enabled: false
      };
    },
    addProfileModal() {
      this.addProfileModalFlag = !this.addProfileModalFlag;
    },
    async validateEditProfile() {
      if (this.user?.roles?.includes('COMPANY_ADMIN')) {
        await editProfileByCompanyAdmin(this.contentEditProfile?.oldName, this.contentEditProfile?.newName, this.contentEditProfile?.description, this.contentEditProfile?.enabled);
      } else {
        await editProfileByCompanyManager(this.contentEditProfile?.oldName, this.contentEditProfile?.newName, this.contentEditProfile?.description, this.contentEditProfile?.enabled);
      }
      this.editProfileModalFlag = !this.editProfileModalFlag;
      this.firstStepAdd = true;
      await this.getAllProfiles();
    },
    cancelEditProfile() {
      this.firstStepAdd = true;
      this.editProfileModalFlag = !this.editProfileModalFlag;
      this.contentEditProfile = {
        oldName: '',
        newName: '',
        description: '',
        enabled: false
      };
    },
    edit(item: any) {
      this.editProfileModalFlag = !this.editProfileModalFlag;
      this.contentEditProfile = {
        oldName: item.name,
        newName: item.name,
        description: item.description,
        enabled: item.enabled
      }
    },
    async getAllProfiles() {
      if (this.user?.roles?.includes('COMPANY_ADMIN')) {
        this.profiles = await getProfilesByCompanyAdmin();
      } else {
        this.profiles = await getProfilesByCompanyManager();
      }
    },
    async getAllPermissions() {
      if (this.user?.roles?.includes('COMPANY_ADMIN')) {
        this.permissions = await getAllPermissionsForAProfileByCompanyAdmin(this.currentProfileName!);
      } else {
        this.permissions = await getAllPermissionsForAProfileByCompanyManager(this.currentProfileName!);
      }
    },
    delete(item: any) {
      this.profileNameToDelete = item.name;
      this.contentDeleteProfile = {
        name: item.name,
        description: item.description,
        enabled: item.enabled
      }
      this.deleteProfileModalFlag = !this.deleteProfileModalFlag;
    },
    async validateDeletionProfile() {
      if (this.user?.roles?.includes('COMPANY_ADMIN')) {
        await deleteProfileByCompanyAdmin(this.profileNameToDelete!);
      } else {
        await deleteProfileByCompanyManager(this.profileNameToDelete!);
      }

      this.contentDeleteProfile = {
        name: '',
        description: '',
        enabled: false
      };
      this.profileNameToDelete = undefined;
      this.deleteProfileModalFlag = !this.deleteProfileModalFlag;
      this.permissions = [];
      await this.getAllProfiles();
    },
    cancelDeleteProfileModal() {
      this.deleteProfileModalFlag = !this.deleteProfileModalFlag;
      this.contentDeleteProfile = {
        name: '',
        description: '',
        enabled: false
      };
      this.profileNameToDelete = undefined;
    },
    async searchProfile(e: SubmitEvent) {
      e.preventDefault();
      this.profiles = await getProfilesByCompanyAdmin(this.searchProfileName);
      const profilesFind = this.profiles.find((elt: { name: string }) => elt.name === 'ADMIN_ACCESS');
      if ((this.profiles.length === 1 && profilesFind) || (this.profiles.length === 0)) {
        const toast = useToast();
        toast.warning(translate('BACKOFFICE.PROFILE_AND_ACCESS.NO_PROFILE_FOUND'));
        await this.resetSearch(e);
      }
      this.permissions = [];
    },
    async resetSearch(e: SubmitEvent) {
      e.preventDefault();
      this.searchProfileName = '';
      this.permissions = [];
      await this.getAllProfiles();
    }
  },
  computed: {
    BackOfficeGroupActions() {
      return BackOfficeGroupActions
    },
    Radio() {
      return Radio
    },
    Checkbox() {
      return Checkbox
    },
    ...mapGetters(['user']),
    ...mapActions(['fetchUser']),
    profilesToDisplay() {
      if (['Julien Marion', 'Wendy Norbert'].includes(this.user?.username)) return this.profiles;
      return this.profiles.filter((obj: { name: string }) => obj.name !== 'ADMIN_ACCESS');
    }
  },
  data: () => ({
    searchProfileName: '',
    profiles: [],
    permissions: [],
    isEditable: false,
    displayPermission: false,
    addProfileModalFlag: false,
    contentAddProfile: {} as {
      newName: string,
      description: string,
      enabled?: boolean
    },
    firstStepAdd: true,
    editProfileModalFlag: false,
    contentEditProfile: {} as {
      oldName: string,
      newName: string,
      description: string,
      enabled?: boolean
    },
    nbModification: 0,
    currentProfileName: undefined,
    currentProfileDescription: undefined,
    deleteProfileModalFlag: false,
    contentDeleteProfile: {} as {
      name: string,
      description: string,
      enabled?: boolean
    },
    profileNameToDelete: undefined,
    currentProfileCreatedBy: undefined
  }),
  async created() {
    if (!!this.user) await this.getAllProfiles();
  },
  watch: {
    async user(val) {
      if (!!val) await this.getAllProfiles();
    }
  }
})
</script>