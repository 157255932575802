<script setup lang="ts">
import {downloadAttachmentsPdf} from "@/apiCalls/companyCheck";
import {formatLocaleDate, translate} from "@/i18n";
import ContentCard from "@/components/card/ContentCard.vue";
import {isNil} from "lodash";
import {computed, onBeforeUnmount, onMounted, reactive} from "vue";
import {downloadedImage, downloadImage, loadingImage} from "@/utils/imagePath";
import {TaxReport} from "@/utils/companyAnnualAccounts";


const props = withDefaults(defineProps<{
  taxReports: TaxReport[],
  journeyId: string,
  canAnnualAccounts: boolean,
  country: string
}>(), {
  journeyId: '',
  canAnnualAccounts: false,
  country: ''
});

const imageSources: { [key: string]: string } = reactive({});
let timeout: NodeJS.Timeout | null = null;

const downloadTaxReport = async (registrationNumber: string, journeyId: string, fileId: string, type: string, closureDate: string) => {
  imageSources[fileId] = loadingImage;
  await downloadAttachmentsPdf(registrationNumber, journeyId, fileId, type, closureDate, props.country);
  imageSources[fileId] = downloadedImage;

  timeout = setTimeout(() => {
    imageSources[fileId] = downloadImage;
  }, 750);
}

const isTaxReportEmpty = computed(() => {
  return !isNil(props.taxReports) && props.taxReports?.length > 0;
})

onMounted(() => {
  if (isTaxReportEmpty.value) {
    props.taxReports.forEach((taxReport) => {
      imageSources[taxReport.fileId] = downloadImage;
    });
  }
});
onBeforeUnmount(() => {
  if (timeout) clearTimeout(timeout)
});
</script>

<template>
  <ContentCard v-if="!canAnnualAccounts" :title="translate('COMPANY.ANNUAL_ACCOUNTS.TITLE')" class="annual-accounts"
               :checked-display-content="false" :is-padlock="true"/>
  <template v-else>
    <ContentCard :title="translate('COMPANY.ANNUAL_ACCOUNTS.TITLE')" class="annual-accounts">
      <div v-if="isTaxReportEmpty" v-for="(taxReport, index) in taxReports"
           :key="index"
           class="annual-accounts-content">
      <span class="annual-accounts-content-title">{{
          `${translate('COMPANY.ANNUAL_ACCOUNTS.COMPANY_FINANCIAL_STATEMENTS')} ${new Date(taxReport.closureDate).getFullYear()}`
        }}</span>
        <div class="annual-accounts-content-download">
          <img :src="imageSources[taxReport.fileId]" alt="file-download" class="annual-accounts-content-image no-print"
               :id="taxReport.fileId"
               @click="downloadTaxReport(taxReport.registrationNumber, journeyId, taxReport.fileId, 'TAX_REPORT', taxReport.closureDate)">
          <span>{{ `${formatLocaleDate(taxReport.submissionDate)}` }}</span>
        </div>
      </div>
      <div class="annual-accounts-no-data" v-else>
        {{ translate('COMPANY.ANNUAL_ACCOUNTS.NO_DATA') }}
      </div>
    </ContentCard>
  </template>
</template>