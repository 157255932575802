<script lang="ts" setup>
import { onUnmounted, ref } from "vue";

import { DocumentTypeDto } from "@/apiCalls/uploadDocumentsApi";
import { translate } from "@/i18n";

import Button from "@/components/button/Button.vue";
import DocumentTypeSelector from "@/components/DocumentTypeSelector.vue";
import Loader from "@/components/loader/Loader.vue";

const props = defineProps<{
  uploadMode: "PHONE" | "IFRAME";
  isLoadingIframe: boolean;
  iframeLink: string;
  documentType: { label: string; value: string; isPermitted: boolean }[];
  canSelectDocsType: boolean;
  hasAuthenticityPerms: boolean;
}>();

const emit = defineEmits([
  "uploadDocument",
  "closeModal",
  "toggleAuthenticity",
]);

const selectedDocTypes = ref<DocumentTypeDto[]>([]);
const isAuthenticity = ref(false);

const addAuthenticityUploadMode = () => {
  if (props.canSelectDocsType) {
    if (!props.hasAuthenticityPerms) return;
    isAuthenticity.value = !isAuthenticity.value;
    if (isAuthenticity.value) {
      selectedDocTypes.value.push("OTHER");
    } else {
      selectedDocTypes.value = selectedDocTypes.value.filter(
        (docType) => docType !== "OTHER"
      );
    }
    emit("toggleAuthenticity", isAuthenticity.value);
  }
};

onUnmounted(() => {
  selectedDocTypes.value = [];
  isAuthenticity.value = false;
});
</script>

<template>
  <h5>
    {{ translate("DOCUMENT_CONTROL.TABLE.DOCUMENT_ANALYSIS_TITLE") }}
  </h5>
  <DocumentTypeSelector
    v-model="selectedDocTypes"
    :options="documentType"
    :can-select="canSelectDocsType"
  >
    <template #options>
      <div
        @click="addAuthenticityUploadMode"
        class="document-type-selector-option"
        :class="{ selected: isAuthenticity }"
      >
        {{ translate("DOCUMENT_CONTROL.OTHER_DOCUMENT") }}
        <img
          v-if="!hasAuthenticityPerms"
          :src="require('/public/images/padlock-blue.png')"
          alt=""
        />
      </div>
    </template>
  </DocumentTypeSelector>
  <Loader v-if="uploadMode === 'IFRAME' && isLoadingIframe" />
  <iframe
    v-if="uploadMode === 'IFRAME' && iframeLink"
    :src="iframeLink"
    id="upload-iframe"
  />
  <Button
    v-if="selectedDocTypes.length > 0 && !iframeLink"
    type="submit"
    :label="translate('BUTTONS.NEXT')"
    @click="emit('uploadDocument', selectedDocTypes)"
  />
  <Button
    v-else
    type="submit"
    :label="translate('BUTTONS.CLOSE')"
    @click="emit('closeModal')"
  />
</template>
