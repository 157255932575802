import {ApiUserResponseDto} from "@/apiCalls/login";

/*
If 1 role is higher than the other
If 0 roles are equivalent
If -1 role is lower than the other
*/
export function compareRole(user1?: ApiUserResponseDto, user2?: ApiUserResponseDto) {
  if (!user1 || !user2) {
    return 0;
  }
  if (!['COMPANY_MANAGER', 'COMPANY_ADMIN', 'COMPANY_USER'].some(elt => user1.roles.includes(elt))) {
    return -1;
  }

  if (!['COMPANY_MANAGER', 'COMPANY_ADMIN', 'COMPANY_USER'].some(elt => user2.roles.includes(elt))) {
    return 1;
  }

  if (user1.roles.includes('COMPANY_ADMIN')) {
    if (user2.roles.includes('COMPANY_ADMIN')) {
      return 0;
    }
    return 1;
  }

  if (user1.roles.includes('COMPANY_MANAGER')) {
    if (user2.roles.includes('COMPANY_ADMIN')) {
      return -1;
    }

    if (user2.roles.includes('COMPANY_MANAGER')) {
      return 0;
    }
    return 1;
  }

  if (user2.roles.includes('COMPANY_ADMIN')) {
    return -1;
  }

  if (user2.roles.includes('COMPANY_MANAGER')) {
    return -1;
  }

  return 0;
}


export function convertTypeToText(type: any) {
  const TYPE_TO_TEXT_OBJ : any = {
    "Float": "FLOAT",
    "Integer": "INTEGER",
    "String": "STRING",
    "Boolean": "BOOLEAN",
    "List[String]": "LIST_STRING",
    // TO REMOVE WHEN THERE WILL NOT BE RULES V1 ANYMORE
    "float": "FLOAT",
    "integer": "INTEGER",
    "boolean": "BOOLEAN",
    "string": "STRING",
    // END OF V1 TYPES
  }
  return `BACKOFFICE.RULES_ENGINE.RULES.${TYPE_TO_TEXT_OBJ[type]}`
}