<script setup lang="ts">
import { isEmpty, merge, uniqBy } from "lodash";
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { translate } from "@/i18n";
import { addAuthenticityToCheck } from "@/utils/boxOutputDoc";
import {
  displayAuthenticity,
  getAuthenticityCheck,
  getBankDetailsCheck,
  getKbisCheck,
  getPayslipCheck,
  getPersonalDocCheck,
  getTaxNoticeCheck,
  getTaxReportCheck,
} from "@/utils/createCheckFromData";
import { filterAndMergeMultiList } from "@/utils/sortArrayObjectKeys";

import Button from "@/components/button/Button.vue";
import ContentCard from "@/components/card/ContentCard.vue";
import TableDocumentsResult from "@/components/document-analysis/TableDocumentsResult.vue";
import Loader from "@/components/loader/Loader.vue";
import { usePermissions } from "@/composables/usePermissions";

const router = useRouter();
const permissions = usePermissions();

const props = defineProps<{
  title: string;
  desc: string;
  docCategory: "ID" | "OTHER";
  isLoading: boolean;
  contextData: any;
  canUploadDocument: boolean;
  canRestituteDocument: boolean;
  canShow: boolean;
  canFaceMatching?: boolean
}>();

const emit = defineEmits(["newAnalysis", "newFaceMatching"]);

const ocrDocs = ref<Object[]>([]);
const documentChecks = ref<Object[]>([]);

const journeyId = computed(() => {
  const query = router.currentRoute.value.query.journeyId;
  return query ? query.toString() : "";
});

const localContextData = computed(() => {
  return addAuthenticityToCheck(props.contextData, "authenticity_result");
});

const inProgressWebUploadCauses = computed(() => {
  return props.contextData.web_upload_link_causes
    .filter((el) => el.status === "IN_PROGRESS")
    .map((cause) => ({ ...cause, data: cause.value }));
});

const inProgressWebUploadResult = computed(() => {
  return props.contextData.web_upload_link_result?.filter(
    (el) => el.status === "IN_PROGRESS"
  );
});

const mergedInProgressWebUploadResultAndCauses = computed(() => {
  return filterAndMergeMultiList("id")(
    inProgressWebUploadCauses.value,
    inProgressWebUploadResult.value
  ).map((el) => {
    const mergedObject = {
      ...el,
      ...el.data,
      ...el.value,
      documentIds: Object.values(el.value.uploads)
        .flat(1)
        .map((val) => val.documentId),
    };
    return {
      id: mergedObject.id,
      link: mergedObject.link,
      documentIds: mergedObject.documentIds,
    };
  });
});

const mergeAuthenticityData = (data: {
  [x: string]: any[];
  authenticity_result: any[];
}) => {
  if (!isEmpty(data["authenticity_result"])) {
    const _result = data.authenticity_result
      .map((authenticity) => {
        return authenticity?.documentIds?.map((docId: any) => {
          const file = data["file"].find((f) => f?.document?.id === docId);
          return Object.assign({}, authenticity, file, {
            fineName: file?.document.fileName,
            uploadedFileUUID: file?.document.id,
          });
        });
      })
      .filter((doc) => doc)
      .flat(1);
    return uniqBy(_result, "uploadedFileUUID");
  }
};

const getOcrDocs = () => {
  if (isEmpty(localContextData.value)) return [];
  if (props.docCategory === "ID") {
    return ["id_check_data", "face_matching"]
      .map((contextKey) => {
        if (!isEmpty(localContextData.value[contextKey]))
          return localContextData.value[contextKey].map((el: any) => {
            return {
              analysisStatus: el.analysisStatus,
              checks: el.checks,
              data: el.data,
              documentIds: el.documentIds,
              documentSide: el.documentSide,
              documentsFilenames: el.documentsFilenames,
              documentType: el.data?.docType,
              taskId: el.taskId,
              creationDate: el.creationDate,
              faceMatchingStatus:
                contextKey === "face_matching" ? el.status : "NONE", // ['NONE', 'CREATED', 'IN_PROGRESS', 'SUCCESS', 'FAILED', 'CANCELLED']
            };
          });
        return [];
      })
      .flat(1)
      .filter((el) => !!el);
  } else {
    const contextsToCheck = [
      "tax_report_data",
      "kbis_data",
      "tax_notice_data",
      "payslip_data",
      "authenticity_result",
      "bank_details",
    ];
    const authenticityData = mergeAuthenticityData(localContextData.value);
    return contextsToCheck
      .map((contextKey) => {
        if (!isEmpty(localContextData.value[contextKey])) {
          return localContextData.value[contextKey].map((el: any) => {
            if (el && !isEmpty(el.documentIds)) {
              const authenticity = authenticityData?.find(
                (auth) => auth.uploadedFileUUID === el?.documentIds[0]
              );
              const authenticityOnly = {
                authenticity: authenticity?.authenticity,
                pdfReport: authenticity?.pdfReport,
                reasons: authenticity?.reasons,
                reasonsDetails: authenticity?.reasonsDetails,
                redirectUrl: authenticity?.redirectUrl,
              };
              const document = {
                analysisStatus: el?.analysisStatus,
                checks:
                  contextKey === "authenticity_result"
                    ? authenticity?.reasons
                    : el?.checks,
                data: el?.data,
                documentIds: el?.documentIds,
                documentSide: el?.documentSide,
                documentsFilenames:
                  contextKey === "authenticity_result"
                    ? [authenticity?.filename]
                    : el?.documentsFilenames,
                documentType:
                  contextKey === "authenticity_result"
                    ? "AUTHENTICITY"
                    : el?.data?.docType,
                taskId: el.taskId,
                creationDate: el.creationDate,
                faceMatchingStatus: "NONE",
              };
              return contextKey === "authenticity_result"
                ? Object.assign({}, document, authenticityOnly)
                : document;
            }
            return {};
          });
        }
        return [];
      })
      .flat(1);
  }
};

const getDocumentCheckData = async ({
  data,
  documentType,
  documentId,
}: {
  data: any;
  documentType: string;
  documentId?: string;
}) => {
  let checks: any = {};
  const personalDocTypes = [
    "DRIVING_LICENSE",
    "ID_CARD",
    "PASSPORT",
    "RESIDENCE_PERMIT",
  ];
  if (documentType === "KBIS") {
    checks = await getKbisCheck({
      initialChecks: checks,
      data,
      journeyId: journeyId.value,
      documentId: documentId || "",
    });
  } else if (personalDocTypes.includes(documentType)) {
    checks = getPersonalDocCheck(checks, data);
  } else if (documentType === "TAX_REPORT") {
    checks = getTaxReportCheck(checks, data);
  } else if (documentType === "TAX_NOTICE") {
    checks = getTaxNoticeCheck(checks, data);
  } else if (documentType === "BANK_DETAILS") {
    checks = getBankDetailsCheck(checks, data);
  } else if (documentType === "PAYSLIP") {
    checks = getPayslipCheck(checks, data);
  } else if (documentType === "AUTHENTICITY") {
    checks = getAuthenticityCheck(checks, data);
  }
  return checks;
};

const createChecks = (checks: Array<any>) => {
  const newChecks = checks.map((result: any) => {
    return { [result.check]: `${result.status}` };
  });
  return newChecks.reduce((acc, current) => {
    return Object.assign(acc, current);
  }, {});
};

const isIdCheck = (documentType: string): boolean => {
  return [
    "DRIVING_LICENSE",
    "ID_CARD",
    "PASSPORT",
    "RESIDENCE_PERMIT",
  ].includes(documentType);
};

const getWebUploadLinkResult = (documentId: string) => {
  const status = mergedInProgressWebUploadResultAndCauses.value.find((el) =>
    el.documentIds.includes(documentId)
  );
  if (!!status) {
    return { status: "IN_PROGRESS", link: status.link };
  }
  return { status: "DONE", link: "" };
};

const createDocumentCheck = (): Promise<Object[]> => {
  return new Promise((resolve) => {
    resolve(
      Promise.all(
        ocrDocs.value
          .filter((doc) => !isEmpty(doc))
          .map(async (doc: any) => {
            const _checks = await getDocumentCheckData({
              data: doc,
              documentType: doc.documentType,
              documentId: isEmpty(doc.documentIds) ? [] : doc.documentIds[0],
            });
            if (
              !isIdCheck(doc.documentType) &&
              doc.documentType !== "AUTHENTICITY" &&
              doc.data
            ) {
              _checks["authenticity"] = displayAuthenticity(
                doc.data.authenticity
              );
              _checks["authenticityReasons"] = doc.data.authenticityReasons;
            }
            const docCheck = doc.checks ? createChecks(doc.checks) : [];
            const documentChecks = merge(_checks, docCheck);
            return {
              documentId: doc.documentIds,
              documentName: doc.documentsFilenames,
              documentType: doc.documentType,
              documentChecks: {
                ...documentChecks,
                documentCategory: props.docCategory,
              },
              documentStatus: doc.analysisStatus,
              contextData: props.contextData,
              journeyId: journeyId.value,
              webUploadStatus: getWebUploadLinkResult(
                doc && doc.documentIds ? doc.documentIds[0] : ""
              ),
              taskId: doc.taskId,
              creationDate: doc.creationDate,
              faceMatchingStatus: doc.faceMatchingStatus,
            };
          })
      )
    );
  });
};

const getDocumentCheck = () => {
  ocrDocs.value = getOcrDocs();
  createDocumentCheck().then((checks: any) => {
    documentChecks.value = checks;
  });
};

const startNewAnalysis = () => {
  if (!props.canUploadDocument) return;
  emit("newAnalysis");
};

const startNewFaceMatching = () => {
  if (!props.canFaceMatching) return;
  emit("newFaceMatching");
};

watch(
  () => props.contextData,
  () => {
    getDocumentCheck();
  }
);

onMounted(() => {
  if (journeyId.value) getDocumentCheck();
});
</script>

<template>
  <!-- <PrintDocumentAnalysisResult
    v-if="documentChecks.length > 0"
    :title="translate(title)"
    :document-checks="documentChecks"
  /> -->
  <ContentCard
    :title="translate(title)"
    class="verification-document"
    :print-title="false"
  >
    <div class="verification-document-container">
      <template v-if="!router.currentRoute.value.path.startsWith('/synthesis')">
        <Button
          @click="startNewAnalysis"
          :label="translate('DOCUMENT_CONTROL.NEW_DOCUMENT_ANALYSIS')"
          :picture="canUploadDocument ? 'plus.png' : 'padlock-grey.png'"
          class="verification-document-add-btn"
        />
        <Button
          v-if="props.docCategory === 'ID'"
          @click="startNewFaceMatching"
          :label="translate('DOCUMENT_CONTROL.NEW_FACE_MATCHING')"
          :picture="canFaceMatching ? 'plus.png' : 'padlock-grey.png'"
          class="verification-document-add-btn"
        />
        <span>{{ desc }}</span>
        <div class="horizontal-divider"></div>
      </template>
      <template v-if="isLoading">
        <Loader />
        <span>{{ translate("ANALYSIS_RESULT_RETRIEVAL") }}</span>
      </template>
      <span v-if="!isLoading && documentChecks.length === 0" class="no-print">{{
        translate("DOCUMENT_CONTROL.NO_DOCUMENT_UPLOADED")
      }}</span>
      <template v-if="canRestituteDocument && canShow">
        <TableDocumentsResult
          v-for="doc in documentChecks"
          :key="isEmpty(doc.documentId) ? [] : doc.documentId[0]"
          :document-name="doc.documentName || []"
          :document-type="doc.documentType"
          :document-data="doc.documentChecks"
          :document-file-id="doc.documentId"
          :document-status="doc.documentStatus"
          :webUploadStatus="doc.webUploadStatus"
          :task-id="doc.taskId"
          :creation-date="doc.creationDate"
          :context-data="contextData"
          :journey-id="journeyId"
          :can-show-details="permissions.docPermissions('RESTITUTION_DETAILS')"
          :face-matching-status="doc.faceMatchingStatus"
        />
      </template>
    </div>
  </ContentCard>
</template>
