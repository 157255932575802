<template>
  <div class="meelo-tracking">
    <slot/>
  </div>
</template>
<script lang="ts" setup>
import {useStore} from "vuex";
import {computed, ComputedRef, onMounted, ref, watch, onBeforeUnmount} from "vue";
import {ApiUserResponseDto} from "@/apiCalls/login";
import env from "@/utils/envConfig";

const {TRACKING_HOST, TRACKING_FINGERPRINT_SCRIPT_URL, TRACKING_BEHAVIOUR_SCRIPT_URL} = env;

const store = useStore();
const user: ComputedRef<ApiUserResponseDto | undefined> = computed(() => store.getters.user);

interface ScriptRef {
  config: HTMLScriptElement;
  script: HTMLScriptElement;
}

const fingerprintScript = ref<ScriptRef>();
const behaviourScript = ref<ScriptRef>();

const trackingConfigScript = (configName: string, path: string, customerId: number): HTMLScriptElement => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = `
  window.tracking_getmeelo__${configName}Config = {
    url: '${TRACKING_HOST}${path}',
    customerId: ${customerId}
  }`
  return script;
}

const externalScript = (url: string): HTMLScriptElement => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  return script;
}

const clearTracking = () => {
  if (fingerprintScript.value) {
    document.head.removeChild(fingerprintScript.value.config);
    document.head.removeChild(fingerprintScript.value.script);
  }
  if (behaviourScript.value) {
    document.head.removeChild(behaviourScript.value.config);
    document.head.removeChild(behaviourScript.value.script);
  }
  behaviourScript.value = undefined;
  fingerprintScript.value = undefined;
}

const setTracking = (customerId?: number) => {
  if (!customerId) {
    return;
  }

  const fingerprintScriptConfig = trackingConfigScript('fingerprint', '/api/v2/fingerprints', customerId);
  const fingerprintScript = externalScript(TRACKING_FINGERPRINT_SCRIPT_URL);
  document.head.appendChild(fingerprintScriptConfig);
  document.head.appendChild(fingerprintScript);

  const behaviourScriptConfig = trackingConfigScript('behaviour', '/api/v2/behaviours', customerId);
  const behaviourScript = externalScript(TRACKING_BEHAVIOUR_SCRIPT_URL);
  document.head.appendChild(behaviourScriptConfig);
  document.head.appendChild(behaviourScript);
}


onMounted(() => setTracking(user.value?.userId))
onBeforeUnmount(clearTracking)
watch(user, (newUser) => {
  if (!newUser) {
    clearTracking();
    return;
  }
  setTracking(newUser.userId)
})
</script>
