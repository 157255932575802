import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-add-user__advanced-user" }
const _hoisted_2 = { class: "modal-add-user__advanced-user__checked" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = { class: "modal-add-user__actions" }
const _hoisted_5 = { class: "modal-add-user__summary" }
const _hoisted_6 = { class: "modal-add-user__summary__title" }
const _hoisted_7 = { class: "modal-add-user__summary__informations" }
const _hoisted_8 = { class: "modal-add-user__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ResultCard = _resolveComponent("ResultCard")!
  const _component_CustomModal = _resolveComponent("CustomModal")!

  return (_openBlock(), _createBlock(_component_CustomModal, {
    open: _ctx.modalFlagOpen,
    "onUpdate:open": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modalFlagOpen) = $event)),
    "custom-class": "modal-add-user",
    onCloseModal: _ctx.cancelAction
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ResultCard, { title: _ctx.title }, {
        default: _withCtx(() => [
          (_ctx.step)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_TextInput, {
                  label: `${_ctx.translate('IDENTITY.NAME')}*`,
                  modelValue: _ctx.content.newName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content.newName) = $event)),
                  type: _ctx.UPPER_CASE
                }, null, 8, ["label", "modelValue", "type"]),
                _createVNode(_component_TextInput, {
                  label: `${_ctx.translate('BATCH.DESCRIPTION')}*`,
                  modelValue: _ctx.content.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.content.description) = $event))
                }, null, 8, ["label", "modelValue"]),
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.translate('BACKOFFICE.USERS.ACTIVE')), 1),
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("input", {
                      type: "checkbox",
                      checked: _ctx.checkBox,
                      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stateCheckBox && _ctx.stateCheckBox(...args)))
                    }, null, 40, _hoisted_3)
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CANCEL'),
                    class: "modal-add-user__actions__cancel",
                    onClick: _ctx.cancelAction
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CONFIRM_AND_CONTINUE'),
                    class: "modal-add-user__actions__validate",
                    onClick: _ctx.nextStep,
                    disabled: _ctx.goToNextStep()
                  }, null, 8, ["label", "onClick", "disabled"])
                ])
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(`${_ctx.translate('SUMMARY')} :`), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('IDENTITY.NAME')} : ${_ctx.content.newName}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BATCH.DESCRIPTION')} : ${_ctx.content.description}`), 1),
                    _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('BACKOFFICE.USERS.ACTIVE')} : ${_ctx.checkBox ? _ctx.translate('YES') : _ctx.translate('NO')}`), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CANCEL'),
                    class: "modal-add-user__actions__cancel",
                    onClick: _ctx.cancelAction
                  }, null, 8, ["label", "onClick"]),
                  _createVNode(_component_Button, {
                    label: _ctx.translate('BUTTONS.CONFIRM_AND_CONTINUE'),
                    class: "modal-add-user__actions__validate",
                    onClick: _ctx.validateAction
                  }, null, 8, ["label", "onClick"])
                ])
              ], 64))
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }, 8, ["open", "onCloseModal"]))
}