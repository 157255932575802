import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-table__pagination" }
const _hoisted_2 = { class: "page-table__pagination__elements" }
const _hoisted_3 = { class: "page-table__pagination__navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(`${_ctx.translate('PAGE')} ${_ctx.currentPage + 1} ${_ctx.translate('ON')} ${_ctx.totalPages} - ${_ctx.totalElements} ${_ctx.translate('RESULTS_FOUND')}`), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Button, {
        disabled: !(_ctx.currentPage <= _ctx.totalPages && _ctx.currentPage > 0),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.movePages(-1))),
        label: _ctx.translate('TABLE.BACK'),
        outlined: "",
        white: ""
      }, null, 8, ["disabled", "label"]),
      _createElementVNode("div", null, _toDisplayString(_ctx.currentPage + 1) + " / " + _toDisplayString(_ctx.totalPages), 1),
      _createVNode(_component_Button, {
        disabled: !(_ctx.currentPage < _ctx.totalPages - 1 && _ctx.currentPage >= 0),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.movePages(1))),
        label: _ctx.translate('TABLE.NEXT'),
        outlined: "",
        white: ""
      }, null, 8, ["disabled", "label"])
    ])
  ]))
}