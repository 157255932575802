import { ESEstablishmentTransformer } from "@/utils/dataTransformer/config/establishment/es";

export const establishmentTransformer = (
  country: "BE" | "ES" | "FR",
  datas: []
) => {
  if (country === "ES")
    return datas.map((data) => ESEstablishmentTransformer(data));
  return datas;
};
