import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5f7fa14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-header" }
const _hoisted_2 = { class: "table-content" }
const _hoisted_3 = { width: "100%" }
const _hoisted_4 = { class: "table-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("table", null, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", _hoisted_1, [
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('TELEPHONE.LINETYPE')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('BUTTONS.DETAILS')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('SCORE.SCORE')), 1),
        _createElementVNode("th", null, _toDisplayString(_ctx.translate('DOCUMENT_CONTROL.TABLE.RESULT.CHECK')), 1)
      ])
    ]),
    _createElementVNode("tbody", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratings, (rating) => {
        return (_openBlock(), _createElementBlock("tr", _hoisted_2, [
          _createElementVNode("td", null, _toDisplayString(rating.source), 1),
          _createElementVNode("td", _hoisted_3, _toDisplayString(rating.full_name), 1),
          _createElementVNode("td", null, _toDisplayString(rating.value), 1),
          _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.getCheckStatus(rating.decision)), 1)
        ]))
      }), 256))
    ])
  ]))
}