<script setup lang="ts">
import {Motive} from "@/utils/journeyUtils";
import {translate} from "@/i18n";
import Button from "@/components/button/Button.vue";
import {isEmpty} from "lodash";

const props = defineProps<{
  motives: Motive[],
  totalElements: number,
  totalPages: number,
  currentPage: number
}>();

const emit = defineEmits<{
  (e: 'page-change', page: number): void;
  (e: 'edit-motive', motive: Motive): void;
  (e: 'delete-motive', motive: Motive): void;
}>();

const tableHeaders = [
  {label: 'LABEL'},
  {label: 'STATUS'},
  {label: 'ACTIONS'}
];

const handleNextPage = () => {
  emit('page-change', props.currentPage + 1);
};

const handlePreviousPage = () => {
  emit('page-change', props.currentPage - 1);
};

// Actions
const handleEdit = (motive: Motive) => {
  emit('edit-motive', motive);
};
const handleDelete = (motive: Motive) => {
  emit('delete-motive', motive);
};

</script>

<template>
  <div v-if="!isEmpty(props.motives)" class="motive-table">
    <table>
      <thead>
      <tr>
        <th v-for="header in tableHeaders" :key="header.label">{{ translate(`MOTIVE.TABLE.${header.label}`) }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="motive in props.motives" :key="motive.label">
        <td>{{ motive.label }}</td>
        <td>{{ motive.status ? translate(`FOLDER.STATE.${motive.status}`) : '-' }}</td>
        <td class="motive-actions">
          <img src="/images/pen.png" class="edit-icon" alt="Edit" @click="handleEdit(motive)" />
          <img src="/images/bin.png" class="delete-icon" alt="Delete" @click="handleDelete(motive)" />
        </td>
      </tr>
      </tbody>
    </table>
    <div class="pagination">
      <span>{{
          `${translate('PAGE')} ${currentPage+1} ${translate('ON')} ${totalPages} - ${totalElements} ${translate('RESULTS_FOUND')}`
        }}</span>
      <div class="buttons">
        <Button v-if="currentPage !== 0" type="button" :label="translate('BUTTONS.PREVIOUS')" @click="handlePreviousPage" outlined white />
        <Button v-if="currentPage !== totalPages - 1" type="button" :label="translate('BUTTONS.NEXT')" @click="handleNextPage" outlined white />
      </div>
    </div>
  </div>
  <div v-else class="no_data_wrapper">
    <span class="no_data_wrapper__text"> {{ translate("MOTIVE.TABLE.NODATA")}} </span>
  </div>
</template>