<script setup lang="ts">
import {computed, ref, watch} from 'vue';
import {select} from "bootstrap-vue-3/dist/utils/dom";

const props = withDefaults(
    defineProps<{
      title: string,
      options: Array<Object>,
      values: Array<Object>, //the id of the options
      value?: string | number, //default value
      emptyOption: boolean,
      resetValue?: boolean
    }>(),
    {
      title: '',
      emptyOption: false,
      resetValue: false,
      value: ''
    }
);

const emit = defineEmits(["option-selected", "update:value", "value-selected"]);

const selectedOption = ref(props.value);

const handleOptionSelected = (e: { target: { value: any } }) => {
  emit('option-selected', selectedOption.value);
  emit('update:value', e.target.value);
  const value = props.options[props.values.indexOf(selectedOption.value)];
  emit('value-selected', value); //to have the value of the option
}

const id = computed(() => {
  return `select_${props.title.replace(' ', '_')}`;
});

watch(() => props.resetValue, (newValue) => {
  newValue ? selectedOption.value = '' : undefined;
});

watch(() => props.value, (newValue) => {
  selectedOption.value = newValue;
});
</script>

<template>
  <div class="select-component">
    <label :for="id">{{ title }}</label>
    <select :id="id"
            v-model="selectedOption"
            v-on:change="handleOptionSelected">
      <option v-if="emptyOption" value=""></option>
      <option v-for="(option, index) in options"
              :key="index"
              :value="values[index]" :selected="index == 0">
        {{ option }}
      </option>
    </select>
  </div>
</template>


