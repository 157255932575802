<script setup lang="ts">
import { defineProps, ref, reactive, computed } from 'vue';
import { translate } from "@/i18n";
import BackOfficeRuleEditModal from "@/components/backoffice/BackOfficeRuleEditModal.vue";
import Tooltip from '@/components/Tooltip.vue';
import { getRule } from '@/apiCalls/backOffice/rulesEngine';

interface Rule {
  name: string;
  description: string;
  version: string;
  global: boolean;
  sets: any
}

const titles: any = ref(
  [
      'BACKOFFICE.RULES_ENGINE.RULES.NAME',
      'BACKOFFICE.RULES_ENGINE.RULES.DESCRIPTION',
      'BACKOFFICE.RULES_ENGINE.RULES.TYPE',
      'BACKOFFICE.RULES_ENGINE.RULES.ACTION'
  ]
);


const props = defineProps<{
  rules: Rule[];
  detailedInformation: boolean;
}>();

const titlesList = computed(() => {
  if (props.detailedInformation) {
    return ["LOGO", ...Object.values(titles.value)];
  }
  return Object.values(titles.value);
});


const editUserModalFlag = ref(false);
const contentEditModal = ref<any>(null);
const firstStepEdit = ref(true);

// Reactive state for storing the rule to be edited
const currentRule = reactive({ index: -1 });

// Method implementations
const editRuleModal = async (index: number) => {
  contentEditModal.value = null;
  editModal();
  const rule = props.rules[index];
  contentEditModal.value = await getRule(rule.name, rule.global);
};

const editModal = () => {
  editUserModalFlag.value = !editUserModalFlag.value;
};

const formatRule = (rule: Rule) => {
  const formattedRule = {
    name: rule.name,
    description: rule.description ? rule.description : 'ø',
    type: translate(`BACKOFFICE.RULES_ENGINE.RULES.${rule.global ? "GENERIC" : "SPECIFIC"}`),
  };
  return props.detailedInformation ? { isInSet: rule.sets.length !== 0, ...formattedRule } : formattedRule;
};

const getTooltipPhrase = (sets: string[]) => {
  const setsText = sets.join(", ");
  return `${translate("BACKOFFICE.RULES_ENGINE.RULES.RULE_PRESENT_IN_SET")} : ${setsText}`;
};

</script>


<template>
  <div class="backoffice-rules-table">
    <table>
      <thead>
      <tr>
        <th v-for="colsTitle in titlesList" scope="col" v-bind:key="colsTitle">
          <template v-if="colsTitle=='LOGO'">
            <img :src="require(`/public/images/intersection.png`)" alt="intersection.png" width="10%"/>
          </template>
          <template v-else>
            {{ translate(colsTitle) }}
          </template>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in rules" :key="index">
        <td v-for="(value, key) in Object.keys(formatRule(item))"
            :data-label="key"
            :key="key + index">
            <template v-if="value=='isInSet'">
              <Tooltip :text="getTooltipPhrase(item.sets)" position="top">
                <img :src="`${formatRule(item)[value] ?'/images/green-circle.png' : '/images/empty-circle.png'}`" :alt="`${formatRule(item)[value] ?'/images/green-circle.png' : '/images/empty-circle.png'}`" :class="`${formatRule(item)[value] ?'rules__circle__green' : 'rules__circle__empty'}`"/>
              </Tooltip>
            </template>
            <template v-else>{{ formatRule(item)[value] }}</template>
        </td>
        <td>
          <div class="backoffice-rules-table__action-column">
            <span @click="editRuleModal(index)" class="backoffice-rules-table__action-column__link backoffice-rules-table__action-column__link__edit">
              <img src="/images/pen.png" alt="see"
                   class="rules__action-column__edit"/>
            </span>
          </div>
        </td>
      </tr>
      <BackOfficeRuleEditModal v-if="contentEditModal" :modal-flag="editUserModalFlag" :content="contentEditModal"
                           :step="firstStepEdit"
                           :title="translate('BACKOFFICE.RULES_ENGINE.RULES.MODIFY_RULE')"
                           @cancel-action="editModal()"
                           @parameter-edited="$emit('parameter-edited')"
                           />
      </tbody>
    </table>
  </div>
</template>
