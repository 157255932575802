<script setup lang="ts">
import {ref} from 'vue';
import OpenedEyeIcon from '@/components/icons/OpenedEyeIcon.vue';
import ClosedEyeIcon from '@/components/icons/ClosedEyeIcon.vue';

const props = withDefaults(
    defineProps<{
      label: string,
      required: boolean,
    }>(), {
      label: '',
      required: false,
    });

const password = defineModel('password', {type: String, default: ''});

const showPassword = ref(false);

const toggleShow = () => {
  showPassword.value = !showPassword.value;
}

</script>

<template>
  <div class="password-input">
    <label>{{ label }}{{required && '*'}}</label>
    <div>
      <input :type="showPassword ? 'text' : 'password'"
             v-model="password"
             :required="required"
             class="input"
      />
      <OpenedEyeIcon v-if="showPassword" v-on:click="toggleShow"/>
      <ClosedEyeIcon v-else v-on:click="toggleShow"/>
    </div>
  </div>
</template>
